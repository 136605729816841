<template>
  <DefaultNav>
    <div class="big-container">
      <!-- header -->
      <form @submit.prevent="saveData()">
        <div class="form">
          <div class="headings">
            <h2>Create Wallet</h2>
            <div class="right-btns">
              <button class="green-link">Save</button>
            </div>
          </div>
          <div class="mb-3">
            <h6>
              <strong v-if="editing == false" class="text-danger"
                >* All fields required</strong
              >
            </h6>
          </div>
          <div class="w-100 mb-3">
            <label for="exampleInputEmail1" class="form-label mb-0"
              ><span v-if="editing == false" class="text-danger">*</span>
              BVN</label
            >
            <input
              v-model="bvn"
              type="text"
              class="form-control"
              required
              placeholder="bvn"
            />
          </div>
          <div class="d-flex gap-3">
            <!-- input -->
            <div class="w-100 mb-3">
              <label for="exampleInputEmail1" class="form-label mb-0"
                ><span v-if="editing == false" class="text-danger">*</span> Date
                of Birth</label
              >
              <input
                v-model="dateofbirth"
                type="date"
                class="form-control"
                required
                placeholder="What is your date of birth"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import Preloader from "@/layouts/shared/Preloader.vue";
import FarmerService from "@/services/farmer";
import LoanService from "@/services/loan";
import Alert from "@/utilities/alert";

export default {
  name: "CreateWallet",
  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      farmereditid: "",
      dateofbirth: "",
      bvn: "",
      editing: false,
      editData: [],
      usrid: JSON.stringify(
        JSON.parse(localStorage.getItem("Zowasel")).user.user_id,
      ),
      farmerid: localStorage.getItem("farmerID"),
    };
  },
  mounted() {
    this.isLoading = true;
    this.editing = false;
    FarmerService.getSingleFarmer(this.farmerid, (response) => {
      this.isLoading = false;
      console.log(response.data);
      if (response.data == null) {
        // window.location.href = "/dashboard/farmer/view";
      } else {
        this.dateofbirth = response.data.farmer.dateofbirth;
      }
    });
  },
  methods: {
    async saveData() {
      this.isLoading = true;
      const farmerData = JSON.parse(localStorage.getItem("activeFarmerData"));
      //22222222224
      let dt = {
        farmer_id: localStorage.getItem("farmerID"),
        first_name: farmerData.farmer.firstname,
        last_name: farmerData.farmer.lastname,
        phone: farmerData.farmer.mobile,
        dob: this.dateofbirth,
        bvn: this.bvn,
      };
      LoanService.createFarmerWallet(dt, (data) => {
        console.log(data);
        /* ------------------- check if it returned with an error ------------------- */
        if (data.error == false) {
          Alert.success({
            message: "Farmer wallet created successfully",
          });
          setTimeout(() => {
            window.location.assign(
              `/dashboard/farmer/view/${localStorage.getItem("farmerID")}`,
            );
          }, 2000);
        } else {
          Alert.error({
            message: "Account already exists",
          });
        }
        this.isLoading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 4% 5% 8% 3%;
  overflow-y: scroll;

  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}

.active-subscriptions {
  background-color: white;
  padding: 24px 65px 50px;
  margin-top: 40px;

  h2 {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #696671;
  }
}

/* The slider */
.sub-history {
  margin-top: 50px;
  width: 100%;
  margin-left: 10px;

  h2 {
    margin-bottom: 40px;
  }

  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #4a4754;
  }
}

.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 70px;

  a {
    text-decoration: none;
  }

  .right-btns {
    display: flex;
    gap: 20px;
  }

  .green-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 145px;
    height: 40px;
    background: #05b050;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
  }
}

.green-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 145px;
  height: 40px;
  background: #05b050;
  border-radius: 5px;
  letter-spacing: 0.02em;
}

.yellow-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  width: 145px;
  height: 40px;
  background: #fab900 !important;
  border-radius: 5px;
  letter-spacing: 0.02em;
}

a {
  text-decoration: none;
}

.small-font {
  font-size: 11px !important;
}
</style>
