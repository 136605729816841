<template>
  <div class="profile-body d-flex flex-row">
    <div
      id="user-conatiner-2"
      class="user-account-details d-flex flex-column w-100"
    >
      <!-- account setting -->
      <h1>Company Details</h1>
      <div class="form-wrapper">
        <!-- left hand  -->
        <form class="company-form" @submit.prevent="saveCompanyDetails()">
          <!-- column -->
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label"
              >Company name</label
            >
            <input
              id="exampleInputEmail1"
              v-model="company.company_name"
              type="text"
              class="form-control"
              aria-describedby="emailHelp"
              placeholder="KMovers INC."
              required
            />
          </div>
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label"
              >Company address</label
            >
            <input
              id="exampleInputEmail1"
              v-model="company.company_address"
              type="text"
              class="form-control"
              aria-describedby="emailHelp"
              placeholder="No 33 Itammy Lane Uyo"
              required
            />
          </div>
          <!-- row -->
          <div
            class="input-row-container d-flex flex-row justify-content-center align-items-center w-100"
          >
            <div class="mb-3 w-100">
              <label for="exampleInputEmail1" class="form-label">Country</label>
              <select
                id="exampleInputEmail1"
                v-model="company.country"
                class="form-control"
                aria-describedby="emailHelp"
                required
              >
                <option
                  v-for="(country, index) in countries"
                  :key="index"
                  :value="country.country"
                >
                  {{ country.country }}
                </option>
              </select>
            </div>

            <div class="mb-3 w-100">
              <label for="exampleInputPassword1" class="form-label"
                >RC Number</label
              >
              <input
                id="exampleInputPassword1"
                v-model="company.rc_number"
                type="text"
                class="form-control"
                placeholder="Enter company RC number"
                required
              />
            </div>
          </div>

          <!-- row -->
          <div
            class="input-row-container d-flex flex-row justify-content-center align-items-center w-100"
          >
            <div class="mb-3 w-100">
              <label for="exampleInputEmail1" class="form-label">State</label>
              <select
                id="exampleInputEmail1"
                v-model="company.state"
                class="form-control"
                aria-describedby="emailHelp"
                required
              >
                <option
                  v-for="(state, index) in selectCompanyStateByCountry"
                  :key="index"
                >
                  {{ state }}
                </option>
              </select>
            </div>
            <div class="mb-3 w-100">
              <label for="exampleInputPassword1" class="form-label"
                >Company Email</label
              >
              <input
                id="exampleInputPassword1"
                v-model="company.email"
                type="email"
                class="form-control"
                placeholder="Enter company email"
                required
              />
            </div>
          </div>

          <div class="input-row-container d-flex flex-row align-items-center">
            <div class="mb-3 w-100">
              <label for="exampleInputPassword1" class="form-label"
                >Company Phone</label
              >
              <input
                id="exampleInputPassword1"
                v-model="company.phone"
                type="tel"
                class="form-control"
                placeholder="0905 - 739 - 2842"
                required
              />
            </div>
          </div>
          <div
            class="input-row-container d-flex flex-row justify-content-center align-items-center w-100"
          >
            <div class="mb-3 w-100">
              <label for="exampleInputEmail1" class="form-label"
                >Export Experience</label
              >
              <select
                id="exampleInputEmail1"
                v-model="company.export_experience"
                class="form-control"
                aria-describedby="emailHelp"
                required
              >
                <option selected>yes</option>
                <option>no</option>
              </select>
            </div>
            <div class="mb-3 w-100">
              <label for="exampleInputPassword1" class="form-label"
                >Annual Sales Revenue</label
              >
              <input
                id="exampleInputPassword1"
                v-model="company.annual_sales_revenue"
                type="number"
                class="form-control"
                required
              />
            </div>
          </div>
          <div class="editor">
            <!-- Editor -->
            <div class="crop_details">
              Company description <span id="required">*</span>
            </div>
            <input
              v-model="company.company_description"
              class="hidden-input ms-5"
              type="text"
              name="description"
              required
            />
            <div class="d-flex flex-row mb-3 gap-4">
              <div class="w-100 editor-wrapper">
                <!-- using quill editor tool-->
                <div ref="editor" @text-change="handleContentChange" />
              </div>
            </div>
            <!-- editor ends -->
          </div>
          <button type="submit" class="btn upadte-btn w-100">
            Update Company Details
          </button>
        </form>
      </div>
    </div>
  </div>
  <Preloader v-if="isLoading" />
</template>

<script>
import Alert from "@/utilities/alert";
// importing the auth service
import AuthService from "@/services/auth";
// import preloader
import Preloader from "@/layouts/shared/Preloader.vue";
// import country object
import countriesObject from "@/data/countries";

import config from "@/config";
// imports of Quill editor
import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.snow.css";

export default {
  name: "Profile",
  components: {
    Preloader,
  },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      imageBlob: "",
      company: {
        company_name: "",
        company_address: "",
        email: "",
        phone: "",
        country: "",
        state: "",
        rc_number: "",
        export_experience: "",
        annual_sales_revenue: "",
        company_description: "",
        crop_focus: "",
      },
      countries: countriesObject.countries,
    };
  },
  computed: {
    selectCompanyStateByCountry: function () {
      return this.countries && this.company.country != ""
        ? this.countries.filter(
            (item) => item.country == this.company.country,
          )[0].states
        : [];
    },
  },
  mounted() {
    if (this.companyData) {
      this.company = {
        company_name: this.companyData.company_name ?? "",
        company_address: this.companyData.company_address ?? "",
        email: this.companyData.company_email ?? "",
        phone: this.companyData.company_phone ?? "",
        country: this.companyData.country ?? "",
        state: this.companyData.state ?? "",
        rc_number: this.companyData.rc_number ?? "",
        export_experience: this.companyData.export_experience ?? "",
        annual_sales_revenue: this.companyData.annual_sales_revenue ?? "",
        company_description: this.companyData.company_description ?? "",
        crop_focus: this.companyData.crop_focus ?? "",
      };
      // Extract only the text content of description
      var textContent = this.stripHTMLTags(this.company.company_description);
    }
    /* ------------------------------ Quill editor ------------------------------ */
    var _this = this;

    this.editor = new Quill(this.$refs.editor, {
      modules: {
        toolbar: [
          [
            {
              header: [1, 2, 3, 4, false],
            },
          ],
          ["bold", "italic", "underline", "link"],
        ],
      },
      //theme: 'bubble',
      theme: "snow",
      formats: ["bold", "underline", "header", "italic", "link"],
      placeholder: this.company.company_description
        ? textContent
        : "Type a description !",
    });
    this.editor.root.innerHTML = this.modelValue;
    this.editor.on("text-change", function () {
      _this.handleContentChange();
      return _this.update();
    });
  },
  methods: {
    update: function update() {
      this.$emit(
        "update:modelValue",
        this.editor.getText() ? this.editor.root.innerHTML : "",
      );
    },
    handleContentChange() {
      this.company.company_description = this.editor.root.innerHTML;
      // this.editor.root.placeholder = this.company.company_description;
    },
    saveCompanyDetails() {
      // send data to the end-point
      this.isLoading = true;
      let vm = this;
      AuthService.saveCompanyDetails(this.company, (response) => {
        if (response && response.error === false) {
          Alert.success({
            message: "Company Details Saved successfully",
          });
          setTimeout(() => {
            // reload the page
            window.location.reload();
          }, 2000);
        } else {
          // set the preloader to false
          vm.isLoading = false;
          Alert.error({
            message: "Error. Please try again later.",
          });
        }
        // set the preloader to false
        this.isLoading = false;
      });
    },

    //Extract the text content of description
    stripHTMLTags(str) {
      let tempDiv = document.createElement("div");
      tempDiv.innerHTML = str;
      return tempDiv.textContent.trim() || tempDiv.innerHTML || "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
@import "@/assets/scss/shared/profile.scss";
.edit {
  color: red;
}
.editor-wrapper {
  padding-bottom: 50px;

  .ql-container {
    min-height: 20vh;
  }
}

.hidden-input {
  height: 0px;
  width: 0px;
  margin: 0px;
  padding: 0px !important;
}

.warhouse_wrapper_carousel {
  padding: 33px 32px;
  border-radius: 6px;
  background: #fff;
  margin-top: 40px;
}

.warehouse_wrapper h2 {
  color: #696671;

  /* Headline/24px */
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.24px;
}

.warehouse_contents {
  border-radius: 4px;
  border: 1px solid #6ed19a;
  padding: 11px 14px;
  margin-bottom: 18px;
}

.warehouse_no {
  color: #047b38;
  font-family: Maven Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 116%;
}

.warehouse_contents p:not(:nth-of-type(1)) {
  color: #696671;
  font-family: Maven Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 116%;

  span {
    font-weight: 800;
  }
}
.warehouse-details-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.edit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #ffffff;
  box-shadow:
    0px 4px 8px 0px rgba(44, 39, 56, 0.08),
    0px 2px 4px 0px rgba(44, 39, 56, 0.08);
  height: 36px;
  width: 100px;
  border: 1px solid #05b050;
  cursor: default;
  &:hover {
    background: darken(white, 10%);
  }

  span {
    color: #05b050;
    text-align: center;
    font-family: Maven Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }
}
.add-warehouse {
  width: 306px;
  padding: 10px;
  text-align: center;
  border: 1px solid #30bd6e;
  background: #fff;
  color: #05b050;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  margin-top: 22px;
  margin-inline: auto;
  cursor: default;
  &:hover {
    background: darken(white, 10%);
  }
}
</style>
