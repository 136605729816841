import Notification from "@/layouts/partials/Notification.vue";
import AgentType from "@/pages/auth/AgentType.vue";
import CheckEmail from "@/pages/auth/CheckEmail.vue";
import CompanyDetails from "@/pages/auth/CompanyDetails.vue";
import CreatePassword from "@/pages/auth/CreatePassword.vue";
import ForgotPassword from "@/pages/auth/ForgotPassword.vue";
import PageNotFound from "@/pages/auth/PageNotFound.vue";
import RegisterPartner from "@/pages/auth/RegisterPartner.vue";
import ResetPassword from "@/pages/auth/ResetPassword.vue";
import ResetSuccess from "@/pages/auth/ResetSuccess.vue";
import SignUp from "@/pages/auth/SignUp.vue";
import UserType from "@/pages/auth/UserType.vue";
import VerifyAccount from "@/pages/auth/VerifyAccount.vue";
import VerifyEmail from "@/pages/auth/VerifyEmail.vue";
import Welcome from "@/pages/auth/Welcome.vue";
import WelcomeBack from "@/pages/auth/WelcomeBack.vue";
import AccountSettings from "@/pages/dashboard/AccountSettings.vue";
import AddMember from "@/pages/dashboard/AddMember.vue";
import Bank from "@/pages/dashboard/Bank.vue";
import Capital from "@/pages/dashboard/Capital.vue";
import CapitalList from "@/pages/dashboard/CapitalList.vue";
import Care from "@/pages/dashboard/Care.vue";
import ChanelLog from "@/pages/dashboard/ChanelLog.vue";
import Collateral from "@/pages/dashboard/Collateral.vue";
import CollateralList from "@/pages/dashboard/CollateralList.vue";
import Conditions from "@/pages/dashboard/Conditions.vue";
import CreditScoring from "@/pages/dashboard/CreditScoring.vue";
import CreditScoreAnalysis from "@/pages/dashboard/CreditScoringForm.vue";
import CreditScoringPointSystem from "@/pages/dashboard/CreditScoringPointSystem.vue";
import CreditWallet from "@/pages/dashboard/CreditWallet.vue";
import CropQuality from "@/pages/dashboard/Cropquality.vue";
import CropTraceability from "@/pages/dashboard/Croptraceability.vue";
import DashboardHome from "@/pages/dashboard/DashboardHome.vue";
import DoKyb from "@/pages/dashboard/DoKyb.vue";
import EachInvoice from "@/pages/dashboard/EachInvoice.vue";
import EachLoan from "@/pages/dashboard/EachLoan.vue";
import FinanceDashboard from "@/pages/dashboard/Finance.vue";
import Foodsafety from "@/pages/dashboard/Foodsafety.vue";
import FundWallet from "@/pages/dashboard/FundWallet.vue";
import Inputinformation from "@/pages/dashboard/Inputinformation.vue";
import Invoice from "@/pages/dashboard/Invoice.vue";
import {
  default as EditFarmer,
  default as Farmer,
} from "@/pages/dashboard/farmer/AddFarmer.vue";
import {
  default as FarmerBank,
  default as FarmerBankEdit,
} from "@/pages/dashboard/farmer/Bank.vue";
import BatchFarmer from "@/pages/dashboard/farmer/FarmerBatch.vue";
import FarmerList from "@/pages/dashboard/farmer/Farmers.vue";
import {
  default as Kyf,
  default as KyfEdit,
} from "@/pages/dashboard/farmer/Kyf.vue";
import SingleFarmerData from "@/pages/dashboard/farmer/SingleFarmer.vue";
import DvsScoreList from "@/pages/dashboard/farmer/dvs/AllScores.vue";
import DvsScore from "@/pages/dashboard/farmer/dvs/Score.vue";
import FarmerCreditScore from "@/pages/dashboard/farmer/dvs/SingleScore.vue";
import {
  default as EditFarmInformation,
  default as FarmInformation,
} from "@/pages/dashboard/farmer/farm/FarmInformation.vue";
import {
  default as EditFarmMechanization,
  default as FarmMechanization,
} from "@/pages/dashboard/farmer/farm/FarmMechanization.vue";
import {
  default as EditFarmPractice,
  default as FarmPractice,
} from "@/pages/dashboard/farmer/farm/FarmPractice.vue";
import {
  default as AddPitch,
  default as EditPitch,
} from "@/pages/dashboard/farmer/pitch/AddPitch.vue";
import Capacity from "@/pages/dashboard/kyf/Capacity.vue";
import Character from "@/pages/dashboard/kyf/Character.vue";
import AddLoan from "@/pages/dashboard/loan/AddLoan.vue";
import LoanRequest from "@/pages/dashboard/loan/LoanRequest.vue";
import UploadLoanDocument from "@/pages/dashboard/loan/UploadLoanDocument.vue";
import DvsScoreMerchant from "@/pages/dashboard/merchant/Score.vue";
// import LoanHistory from "@/p@/pages/dashboard/loan/AddLoanHistory.vue
import LoanDetails from "@/pages/dashboard/LoanDetails.vue";
import LogisticsDashboard from "@/pages/dashboard/Logistics.vue";
// import Messages from "@/pages/dashboard/Messages.vue";
import MyOrders from "@/pages/dashboard/MyOrders.vue";
import Negotiations from "@/pages/dashboard/Negotiations.vue";
import NewTicket from "@/pages/dashboard/NewTicket.vue";
import Notifications from "@/pages/dashboard/Notifications.vue";
import Plant from "@/pages/dashboard/Plant.vue";
import Recommendation from "@/pages/dashboard/Recommendation.vue";
import RenewalManager from "@/pages/dashboard/RenewalManager.vue";
import Sales from "@/pages/dashboard/Sales.vue";
import BatchScore from "@/pages/dashboard/ScoreBatch.vue";
import Shipment from "@/pages/dashboard/Shipment.vue";
import Subscription from "@/pages/dashboard/Subscription.vue";
import Subscriptions from "@/pages/dashboard/Subscriptions.vue";
import SubscriptionPayment from "@/pages/dashboard/SubscriptionPayment.vue";
import Sustainability from "@/pages/dashboard/Sustainability.vue";
import Team from "@/pages/dashboard/Team.vue";
import Tickets from "@/pages/dashboard/Tickets.vue";
import UpdateShipping from "@/pages/dashboard/UpdateShipping.vue";
import Wallet from "@/pages/dashboard/Wallet.vue";
import Withdrawal from "@/pages/dashboard/Withdrawal.vue";
import FarmerWallet from "@/pages/dashboard/farmer/FarmerWallet.vue";
import CreateWallet from "@/pages/dashboard/farmer/createwallet.vue";
import {
  default as AddLoanHistory,
  default as EditLoanHistory,
} from "@/pages/dashboard/loan/AddLoanHistory.vue";
import {
  default as AddLoanType,
  default as EditLoanType,
} from "@/pages/dashboard/loan/AddLoanType.vue";
import BulkDisburse from "@/pages/dashboard/loan/BulkDisburse.vue";
import CorporateLoan from "@/pages/dashboard/loan/CorporateLoan.vue";
import DisburseSuccesful from "@/pages/dashboard/loan/DisburseSuccesful.vue";
import Loan from "@/pages/dashboard/loan/Loan.vue";
import LoanHistory from "@/pages/dashboard/loan/LoanHistory.vue";
import LoanStatus from "@/pages/dashboard/loan/LoanStatus.vue";
import LoanType from "@/pages/dashboard/loan/LoanType.vue";
import LoansHistory from "@/pages/dashboard/loan/LoansHistory.vue";
import QualifiedFarmer from "@/pages/dashboard/loan/QualifiedFarmer.vue";
import SingleDisburse from "@/pages/dashboard/loan/SingleDisburse.vue";
import SelectLoanType from "@/pages/dashboard/loan/ViewLoanTypes.vue";
import AddCropInput from "@/pages/dashboard/marketPlace/AddCropInput.vue";
import AddCropSale from "@/pages/dashboard/marketPlace/AddCropSale.vue";
import AddNewCrop from "@/pages/dashboard/marketPlace/AddNewCrop.vue";
import Bid from "@/pages/dashboard/marketPlace/Bid.vue";
import CardPayment from "@/pages/dashboard/marketPlace/Cart.vue";
import CheckOut from "@/pages/dashboard/marketPlace/CheckOut.vue";
import CropAuction from "@/pages/dashboard/marketPlace/CropAuction.vue";
import InputOrders from "@/pages/dashboard/marketPlace/InputOrders.vue";
import MarketPlace from "@/pages/dashboard/marketPlace/Market.vue";
import MyProducts from "@/pages/dashboard/marketPlace/MyProducts.vue";
import CheckoutPayment from "@/pages/dashboard/marketPlace/PaymentPage.vue";
import ProductDescription from "@/pages/dashboard/marketPlace/ProductDescription.vue";
import ProductNegotiation from "@/pages/dashboard/marketPlace/ProductNegotiation.vue";
import Product from "@/pages/dashboard/marketPlace/ProductPage.vue";
import TrackingOrder from "@/pages/dashboard/marketPlace/TrackingOrder.vue";
import Transactions from "@/pages/dashboard/marketPlace/Transactions.vue";
import ViewInput from "@/pages/dashboard/marketPlace/ViewInput.vue";
import ViewProduct from "@/pages/dashboard/marketPlace/ViewProduct.vue";
import Confirm from "@/pages/dashboard/marketPlace/checkout/Confirm.vue";
import Dispute from "@/pages/dashboard/marketPlace/checkout/Dispute.vue";
import DisputeFailed from "@/pages/dashboard/marketPlace/checkout/DisputeFailed.vue";
import DisputeSuccess from "@/pages/dashboard/marketPlace/checkout/DisputeSuccess.vue";
import OrderSummary from "@/pages/dashboard/marketPlace/checkout/OrderSumary.vue";
import OrderTracking from "@/pages/dashboard/marketPlace/checkout/OrderTracking.vue";
import PaymentFailed from "@/pages/dashboard/marketPlace/checkout/PaymentFailed.vue";
import PaymentStatus from "@/pages/dashboard/marketPlace/checkout/PaymentStatus.vue";
import Payments from "@/pages/dashboard/marketPlace/checkout/Payments.vue";
import PaymentsConfirmed from "@/pages/dashboard/marketPlace/checkout/PaymentsConfirmed.vue";
import CompanyProfile from "@/pages/dashboard/settings/CompanyProfile.vue";
import KybRedirect from "@/pages/dashboard/settings/KybRedirect.vue";
import KycRedirect from "@/pages/dashboard/settings/KycRedirect.vue";
import KycVerification from "@/pages/dashboard/settings/KycVerification.vue";
import RedirectToKyc from "@/pages/dashboard/settings/RedirectToKyc.vue";
import Settings from "@/pages/dashboard/settings/Settings.vue";
import Warehouse from "@/pages/dashboard/warehouse.vue";
// MerchantDVS
import MerchantDVS from "@/pages/dashboard/merchant/MerchantDVS.vue";
// company profile
import CompanyProductCrops from "@/pages/dashboard/settings/CompanyProductCrops.vue";
import CompanyProductInputs from "@/pages/dashboard/settings/CompanyProductInputs.vue";
// FarmerCSR
// company profile

import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/:type/register",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/:type/register",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/companyprofile",
    name: "CompanyProfile",
    component: CompanyProfile,
  },
  {
    path: "/companyproduct",
    name: "CompanyProductCrops",
    component: CompanyProductCrops,
  },
  {
    path: "/companyproductinput",
    name: "CompanyProductInputs",
    component: CompanyProductInputs,
  },
  {
    path: "/:type/companydetails",
    name: "CompanyDetails",
    component: CompanyDetails,
  },
  {
    path: "/:type/verifyaccount",
    name: "VerifyAccount",
    component: VerifyAccount,
  },
  {
    path: "/:type/createpassword",
    name: "CreatePassword",
    component: CreatePassword,
  },
  {
    path: "/:type/welcome",
    name: "Welcome",
    component: Welcome,
  },
  {
    path: "/verifyemail",
    name: "VerifyEmail",
    component: VerifyEmail,
  },
  {
    path: "/login",
    name: "WelcomeBack",
    component: WelcomeBack,
  },
  {
    path: "/",
    name: "Index",
    component: WelcomeBack,
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/checkemail",
    name: "CheckEmail",
    component: CheckEmail,
  },
  {
    path: "/resetpassword/:token",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/resetsuccess",
    name: "ResetSuccess",
    component: ResetSuccess,
  },
  {
    path: "/agent-type",
    name: "AgentType",
    component: AgentType,
  },
  {
    path: "/getstarted",
    name: "UserType",
    component: UserType,
  },
  {
    path: "/becomeapartner",
    name: "RegisterPartner",
    component: RegisterPartner,
  },
  {
    path: "/dashboard",
    name: "DashboardHome",
    component: DashboardHome,
  },
  {
    path: "/dashboard/logistics",
    name: "LogisticsDashboard",
    component: LogisticsDashboard,
  },
  {
    path: "/dashboard/finance",
    name: "FinanceDashboard",
    component: FinanceDashboard,
  },
  {
    path: "/dashboard/do-kyb",
    name: "DoKyb",
    component: DoKyb,
  },
  {
    path: "/dashboard/account",
    name: "AccountSettings",
    component: AccountSettings,
  },
  {
    path: "/dashboard/notifications",
    name: "Notifications",
    component: Notifications,
  },
  // {
  //   path: "/dashboard/messages",
  //   name: "Messages",
  //   component: Messages,
  // },
  {
    path: "/dashboard/negotiations",
    name: "Negotiations",
    component: Negotiations,
  },
  {
    path: "/dashboard/subscription",
    name: "Subscription",
    component: Subscription,
  },
  {
    path: "/dashboard/subscriptions",
    name: "Subscriptions",
    component: Subscriptions,
  },
  {
    path: "/dashboard/subscriptionpayment",
    name: "SubscriptionPayment",
    component: SubscriptionPayment,
  },
  {
    path: "/dashboard/renewalmanager",
    name: "renewalmanager",
    component: RenewalManager,
  },
  {
    path: "/dashboard/chanellog",
    name: "chanellog",
    component: ChanelLog,
  },
  {
    path: "/dashboard/creditwallet",
    name: "creditwallet",
    component: CreditWallet,
  },
  {
    path: "/dashboard/withdrawal",
    name: "withdrawal",
    component: Withdrawal,
  },
  {
    path: "/dashboard/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/dashboard/invoice",
    name: "Invoice",
    component: Invoice,
  },
  {
    path: "/dashboard/each-invoice",
    name: "EachInvoice",
    component: EachInvoice,
  },
  {
    path: "/dashboard/loan/view",
    name: "Loan",
    component: Loan,
  },
  {
    path: "/dashboard/loan/loanrequest",
    name: "Loan",
    component: LoanRequest,
  },
  {
    path: "/dashboard/loan/uploadloandocument",
    name: "LoanDocument",
    component: UploadLoanDocument,
  },
  {
    path: "/dashboard/farmer/batch/add",
    name: "BatchFarmer",
    component: BatchFarmer,
  },
  {
    path: "/dashboard/farmer/batch/score",
    name: "BatchScore",
    component: BatchScore,
  },
  {
    path: "/dashboard/farmer/edit/:id",
    name: "EditFarmer",
    component: EditFarmer,
  },
  {
    path: "/dashboard/farmer/bank/:id/add",
    name: "FarmerBank",
    component: FarmerBank,
  },
  {
    path: "/dashboard/farmer/bank/:id/edit",
    name: "FarmerBankEdit",
    component: FarmerBankEdit,
  },
  {
    path: "/dashboard/farmer/view/:id",
    name: "SingleFarmerData",
    component: SingleFarmerData,
  },
  {
    path: "/dashboard/loan/add",
    name: "AddLoan",
    component: AddLoan,
  },
  {
    path: "/dashboard/cropquality",
    name: "Cropquality",
    component: CropQuality,
  },
  {
    path: "/dashboard/croptraceability",
    name: "Croptraceability",
    component: CropTraceability,
  },
  {
    path: "/dashboard/Inputinformation",
    name: "Inputinformation",
    component: Inputinformation,
  },
  {
    path: "/dashboard/warehouse",
    name: "Warehouse",
    component: Warehouse,
  },
  {
    path: "/dashboard/shipment",
    name: "Shipment",
    component: Shipment,
  },
  {
    path: "/dashboard/recommendation",
    name: "Recommendation",
    component: Recommendation,
  },
  {
    path: "/dashboard/care",
    name: "Care",
    component: Care,
  },
  {
    path: "/dashboard/plant",
    name: "Plant",
    component: Plant,
  },
  {
    path: "/dashboard/foodsafety",
    name: "Foodsafety",
    component: Foodsafety,
  },
  {
    path: "/dashboard/capital",
    name: "Capital",
    component: Capital,
  },
  {
    path: "/dashboard/capital/view",
    name: "CapitalList",
    component: CapitalList,
  },
  {
    path: "/dashboard/collateral",
    name: "Collateral",
    component: Collateral,
  },
  {
    path: "/dashboard/collateral/view",
    name: "CollateralList",
    component: CollateralList,
  },
  {
    path: "/dashboard/conditions",
    name: "Conditions",
    component: Conditions,
  },
  {
    path: "/dashboard/capacity",
    name: "Capacity",
    component: Capacity,
  },
  {
    path: "/dashboard/sustainability",
    name: "sustainability",
    component: Sustainability,
  },
  {
    path: "/dashboard/marketplace/:market",
    name: "MarketPlace",
    component: MarketPlace,
  },
  {
    path: "/dashboard/marketplace/order/:order",
    name: "OrderSummary",
    component: OrderSummary,
  },
  {
    path: "/dashboard/marketplace/:order/tracking",
    name: "OrderTracking",
    component: OrderTracking,
  },
  {
    path: "/dashboard/marketplace/:order/dispute",
    name: "Dispute",
    component: Dispute,
  },
  {
    path: "/dashboard/marketplace/disputefailed",
    name: "DisputeFailed",
    component: DisputeFailed,
  },
  {
    path: "/dashboard/marketplace/:order/disputesuccess",
    name: "DisputeSuccess",
    component: DisputeSuccess,
  },
  {
    path: "/dashboard/marketplace/crop/:id",
    name: "CropDetails",
    component: Product,
  },
  {
    path: "/dashboard/marketplace/crop/:id/negotiate",
    name: "CropNegotiation",
    component: ProductNegotiation,
  },
  {
    path: "/dashboard/marketplace/product/:id",
    name: "ProductDescription",
    component: ProductDescription,
  },
  {
    path: "/marketplace/crop/:id/bid",
    name: "Bid",
    component: Bid,
  },
  {
    path: "/dashboard/marketplace/crop/:id/view",
    name: "ViewProduct",
    component: ViewProduct,
  },
  {
    path: "/dashboard/marketplace/input/:id/view",
    name: "ViewInput",
    component: ViewInput,
  },
  {
    path: "/dashboard/marketplace/cart",
    name: "CardPayment",
    component: CardPayment,
  },
  {
    path: "/dashboard/marketplace/checkout",
    name: "Checkout",
    component: CheckOut,
  },
  {
    path: "/dashboard/transactions",
    name: "Transactions",
    component: Transactions,
  },
  {
    path: "/dashboard/marketplace/myproducts",
    name: "MyProducts",
    component: MyProducts,
  },
  {
    path: "/dashboard/marketplace/checkout/payment",
    name: "CheckoutPayment",
    component: CheckoutPayment,
  },

  {
    path: "/dashboard/marketplace/trackingorder",
    name: "TrackingOrder",
    component: TrackingOrder,
  },
  {
    path: "/marketplace/payments",
    name: "Payments",
    component: Payments,
  },
  {
    path: "/dashboard/marketplace/payments/:order",
    name: "Payments",
    component: Payments,
  },
  {
    path: "/dashboard/marketplace/confirmpayments/:order",
    name: "Confirm",
    component: Confirm,
  },
  {
    path: "/dashboard/marketplace/paymentsconfirmed/:order",
    name: "ConfirmPayment",
    component: PaymentsConfirmed,
  },
  {
    path: "/dashboard/marketplace/paymentsfailed/:order",
    name: "PaymentsConfirmed",
    component: PaymentFailed,
  },
  {
    path: "/dashboard/tickets",
    name: "Tickets",
    component: Tickets,
  },
  {
    path: "/dashboard/team",
    name: "Team",
    component: Team,
  },
  {
    path: "/dashboard/addmember",
    name: "AddMember",
    component: AddMember,
  },
  {
    path: "/dashboard/new-ticket",
    name: "NewTicket",
    component: NewTicket,
  },
  {
    path: "/marketplace/newcrop",
    name: "AddNewcrop",
    component: AddNewCrop,
  },
  {
    path: "/marketplace/crop/:id/edit",
    name: "EditNewcrop",
    component: AddNewCrop,
  },
  {
    path: "/marketplace/newsale",
    name: "AddCropSale",
    component: AddCropSale,
  },
  {
    path: "/marketplace/cropsale/:id/edit",
    name: "EditCropSale",
    component: AddCropSale,
  },
  {
    path: "/marketplace/cropauction",
    name: "CropAuction",
    component: CropAuction,
  },
  {
    path: "/marketplace/cropforauction/:id/edit",
    name: "EditCropAuction",
    component: CropAuction,
  },
  {
    path: "/dashboard/fundwallet",
    name: "FundWallet",
    component: FundWallet,
  },
  {
    path: "/dashboard/wallet",
    name: "Wallet",
    component: Wallet,
  },
  {
    path: "/dashboard/sales",
    name: "Sales",
    component: Sales,
  },
  {
    path: "/dashboard/myorders",
    name: "MyOrders",
    component: MyOrders,
  },
  {
    path: "/dashboard/creditscoring",
    name: "CreditScoring",
    component: CreditScoring,
  },
  {
    path: "/dashboard/creditscore/dvs/:id",
    name: "FarmerCreditScore",
    component: FarmerCreditScore,
  },
  {
    path: "/dashboard/creditscore/analyse",
    name: "CreditScoreAnalysis",
    component: CreditScoreAnalysis,
  },
  {
    path: "/dashboard/creditscoringpointsystem",
    name: "CreditScoringPointSystem",
    component: CreditScoringPointSystem,
  },
  {
    path: "/dashboard/marketplace/:order/updateshipping",
    name: "UpdateShipping",
    component: UpdateShipping,
  },
  {
    path: "/marketplace/input/:id/edit",
    name: "EditCropInput",
    component: AddCropInput,
  },
  {
    path: "/marketplace/addinput",
    name: "AddCropInput",
    component: AddCropInput,
  },
  {
    path: "/dashboard/farmer/kyf/:id",
    name: "Kyf",
    component: Kyf,
  },
  {
    path: "/dashboard/farmer/kyf/edit/:id",
    name: "KyfEdit",
    component: KyfEdit,
  },
  {
    path: "/dashboard/farmer/view",
    name: "FarmerList",
    component: FarmerList,
  },
  {
    path: "/dashboard/farmer/add",
    name: "Farmer",
    component: Farmer,
  },
  {
    path: "/dashboard/farmer/farm/information/add/:id",
    name: "FarmInformation",
    component: FarmInformation,
  },
  {
    path: "/dashboard/farmer/farm/information/edit/:id",
    name: "EditFarmInformation",
    component: EditFarmInformation,
  },
  {
    path: "/dashboard/farmer/farm/mechanization/add/:id",
    name: "FarmMechanization",
    component: FarmMechanization,
  },
  {
    path: "/dashboard/farmer/farm/mechanization/edit/:id",
    name: "EditFarmMechanization",
    component: EditFarmMechanization,
  },
  {
    path: "/dashboard/farmer/farmpractice/add/:id",
    name: "FarmPractice",
    component: FarmPractice,
  },
  {
    path: "/dashboard/farmer/farmpractice/edit/:id",
    name: "EditFarmPractice",
    component: EditFarmPractice,
  },
  {
    path: "/dashboard/each-loan",
    name: "EachLoan",
    component: EachLoan,
  },
  {
    path: "/dashboard/loanstatus",
    name: "LoanStatus",
    component: LoanStatus,
  },
  {
    path: "/dashboard/loanhistory/add/:id",
    name: "AddLoanHistory",
    component: AddLoanHistory,
  },
  {
    path: "/dashboard/loanhistory",
    name: "LoanHistory",
    component: LoanHistory,
  },
  {
    path: "/dashboard/loanhistory/edit/:id",
    name: "EditLoanHistory",
    component: EditLoanHistory,
  },
  {
    path: "/dashboard/loandetails",
    name: "LoanDetails",
    component: LoanDetails,
  },
  {
    path: "/dashboard/character",
    name: "Character",
    component: Character,
  },
  {
    path: "/dashboard/corporateloan",
    name: "CorporateLoan",
    component: CorporateLoan,
  },
  {
    path: "/dashboard/qualifiedfarmer",
    name: "QualifiedFarmer",
    component: QualifiedFarmer,
  },
  {
    path: "/dashboard/loanshistory",
    name: "LoansHistory",
    component: LoansHistory,
  },
  {
    path: "/dashboard/singledisburse",
    name: "SingleDisburse",
    component: SingleDisburse,
  },
  {
    path: "/dashboard/bulkdisburse",
    name: "BulkDisburse",
    component: BulkDisburse,
  },
  {
    path: "/dashboard/disbursesuccessful",
    name: "DisburseSuccessful",
    component: DisburseSuccesful,
  },
  {
    path: "/dashboard/loantype",
    name: "LoanType",
    component: LoanType,
  },
  {
    path: "/dashboard/loantype/add",
    name: "AddLoanType",
    component: AddLoanType,
  },
  {
    path: "/dashboard/loantype/:id/edit",
    name: "EditLoanType",
    component: EditLoanType,
  },
  {
    path: "/dashboard/loan/type/select",
    name: "SelectLoanType",
    component: SelectLoanType,
  },
  {
    path: "/marketplace/crop/:id/bid",
    name: "Bid",
    component: Bid,
  },
  {
    path: "/dashboard/farmer/pitch/add/:id",
    name: "AddPitch",
    component: AddPitch,
  },
  {
    path: "/dashboard/farmer/pitch/edit/:id",
    name: "EditPitch",
    component: EditPitch,
  },
  {
    path: "/errorpage",
    name: "PageNotFound",
    component: PageNotFound,
  },
  {
    path: "/kyc",
    name: "KycVerification",
    component: KycVerification,
  },
  {
    path: "/redirectKyc",
    name: "RedirectToKyc",
    component: RedirectToKyc,
  },
  {
    path: "/kycredirect",
    name: "KycRedirect",
    component: KycRedirect,
  },
  {
    path: "/kybredirect",
    name: "KybRedirect",
    component: KybRedirect,
  },
  {
    path: "/dashboard/bank",
    name: "Bank",
    component: Bank,
  },
  {
    path: "/dashboard/inputorders",
    name: "InputOrders",
    component: InputOrders,
  },
  {
    path: "/dashboard/farmer/score/dvs",
    name: "DvsScore",
    component: DvsScore,
  },
  {
    path: "/dashboard/merchant/score/dvs",
    name: "DvsScore",
    component: DvsScoreMerchant,
  },
  {
    path: "/dashboard/farmer/score/dvs/all",
    name: "DvsScoreList",
    component: DvsScoreList,
  },
  {
    path: "/dashboard/merchant/dvs/score",
    name: "MerchantDVS",
    component: MerchantDVS,
  },
  {
    path: "/notification",
    name: "Notification",
    component: Notification,
  },
  {
    path: "/dashboard/marketplace/paymentstatus/:order",
    name: "paymentstatus",
    component: PaymentStatus,
  },
  {
    path: "/dashboard/farmer/createwallet",
    name: "createwallet",
    component: CreateWallet,
  },
  {
    path: "/dashboard/farmer/wallet",
    name: "FarmerWallet",
    component: FarmerWallet,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

/* -------------------------------------------------------------------------- */
/*                This redirect user to kyc if kyc is not done                */
/* -------------------------------------------------------------------------- */

// // Define an array of exempt routes that don't require KYC
// const exemptRoutes = [
//   "/",
//   "/dashboard",
//   "/login",
//   "/forgotpassword",
//   "/getstarted",
//   "/dashboard/marketplace/cropwanted",
//   "/dashboard/marketplace/input",
//   "/dashboard/marketplace/cropsale",
//   "/dashboard/marketplace/cropauction",
//   "/kycredirect",
//   "/kybredirect",
//   "/errorpage",
//   "/redirectKyc",
// ];

// // Create a function to check if the route requires KYC verification
// function requiresKYC(route) {
//   return (
//     store.state.user &&
//     (store.state.kycstatus.toLowerCase() !== "verified" ||
//       (store.state.user.user.account_type === "company" &&
//         store.state.kybstatus.toLowerCase() !== "verified")) &&
//     !exemptRoutes.includes(route.path)
//   );
// }

// router.beforeEach((to, from, next) => {
//   // If user has not verified KYC and the route requires KYC, redirect to the KYC page
//   if (requiresKYC(to)) {
//     next({ name: "RedirectToKyc" });
//   } else {
//     // Otherwise, allow the user to proceed to the route
//     next();
//   }
// });

export default router;
