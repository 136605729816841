<template>
  <DefaultNav>
    <div class="big-container">
      <div class="welcome-text">
        <h2 v-if="userData.user.account_type == 'company'">
          Welcome, {{ userData.user.company.company_name }}
        </h2>
        <h2 v-if="userData.user.account_type == 'individual'">
          Welcome, {{ userData.user.first_name }}
        </h2>

        <p>
          Wallet Balance: <span>₦ {{ wallet.balance }}</span>
        </p>
      </div>
      <!-- imported the card components -->
      <Cards />
      <!-- <div class="chart">
        <div class="chart-text">
          <h2>Sales Analytics</h2>
        </div>
        <img src="@/assets/images/backgrounds/linechartmain.png" alt="" />
        <div class="dots">
          <div class="green-dot" />
          <p>Sales</p>
          <div class="red-dot" />
          <p>Revenue</p>
        </div>
      </div> -->

      <div class="chart-main">
        <canvas id="myChart"></canvas>
      </div>

      <div class="row table-area">
        <div class="big-table">
          <table class="table table-borderless">
            <thead>
              <th>Transaction ID</th>
              <th>Amount</th>
              <th>Type</th>
              <th>Status</th>
              <th>DATE</th>
            </thead>
            <tbody>
              <tr v-for="transaction in transactions" :key="transaction.id">
                <td>{{ transaction.transaction_id }}</td>
                <td>{{ transaction.amount_paid }}</td>
                <td>{{ transaction.type }}</td>
                <td v-if="transaction.status == 'completed'" scope="row">
                  <div class="colored-green">
                    <div class="green-dot" />
                    <p>Completed</p>
                  </div>
                </td>
                <td v-else scope="row">
                  <div class="colored-yellow">
                    <div class="yellow-dot" />
                    <p>Pending</p>
                  </div>
                </td>
                <td>{{ transaction.updated_at }}</td>
                <!-- <td><img src="@/assets/images/vectors/verticalDots.svg" /></td> -->
              </tr>
            </tbody>
          </table>
        </div>
        <div class="small-table">
          <div class="theading">
            <h4>Orders</h4>
            <a href="/dashboard/myorders">See all Orders</a>
          </div>
          <table class="table table-borderless">
            <thead>
              <th>Order ID</th>
              <th>Amount</th>
              <th>Payment Status</th>
            </thead>

            <tbody>
              <tr v-for="order in orders" :key="order.id">
                <td>{{ order.order_hash }}</td>
                <td>{{ order.total }}</td>
                <td>{{ order.payment_status }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </DefaultNav>
</template>

<script>
import Chart from "chart.js/auto";
import DefaultNav from "@/layouts/DefaultNav.vue";
import MarketplaceService from "@/services/marketplace";
import WalletService from "@/services/wallet";
import Cards from "./components/Cards.vue";

export default {
  name: "CorporateDashboard",
  components: {
    DefaultNav,
    Cards,
  },
  data() {
    return {
      userData: this.$store.state.user,
      wallet: [],
      transactions: [],
      orders: [],
    };
  },
  mounted() {
    this.getWalletBalance();
    this.getTransactions();
    this.getOrder();
    setTimeout(this.getCharts(), 3000);
  },

  methods: {
    getOrder(order) {
      MarketplaceService.getOrders((response) => {
        if (response && response.error == false) {
          var newOrder = response.data.slice(0, 10);
          this.orders = newOrder;
        }
      });
    },
    decodeTrackingData(order) {
      return order.tracking_details ? JSON.parse(order.tracking_details) : {};
    },
    getWalletBalance(id) {
      WalletService.getWalletBalance(this.userData.user_id, (response) => {
        if (response && response.error == false) {
          this.wallet = response.data;
        }
      });
    },
    getTransactions(transactions) {
      MarketplaceService.getTransactions((response) => {
        if (response && response.error == false) {
          var newTransactions = response.data.slice(0, 10);
          this.transactions = newTransactions;
        }
      });
    },
    getCharts() {
      const labels = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const data = {
        labels: labels,
        datasets: [
          {
            label: "Sales",
            data: [65, 59, 80, 81, 56, 55, 40, 100, 125, 89, 20, 65],
            fill: false,
            borderColor: "rgb(205, 70, 71, 1)",
            tension: 0.1,
          },
          {
            label: "Revenue",
            data: [5, 10, 100, 25, 57, 90, 115, 80, 60, 43, 20, 10],
            fill: false,
            borderColor: "rgb(5, 176, 80, 1)",
            tension: 0.1,
          },
        ],
      };
      const config = {
        type: "line",
        data: data,
      };
      const myChart = new Chart(document.getElementById("myChart"), config);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 2% 5% 2% 3%;
  overflow-y: scroll;

  .chart-main {
    margin-top: 40px;
  }
}

.welcome-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 47px;
  padding-top: 30px;

  h2 {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }

  p {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #4a4754;
  }

  span {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #05b050;
  }
}

.chart {
  width: 100%;
  margin-top: 50px;
  padding: 30px;
  background: white;

  .chart-text {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    h2 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      color: #4a4754;
    }
  }
}

.chart img {
  width: 100%;
}

.table-area {
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 0px 0px;
  gap: 8px;
}

.big-table {
  background-color: white;
  padding: 30px;
  box-sizing: border-box;
  width: 64%;

  td {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4754;
  }
}

.small-table {
  background-color: white;
  padding: 30px;
  box-sizing: border-box;
  width: 35%;
  overflow-x: scroll;

  p {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4754;
  }

  h4 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #4a4754;
  }
}

.theading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  h4 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #4a4754;
  }

  a {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #96949c;
  }
}

.dots {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .green-dot {
    width: 12px;
    height: 12px;
    background-color: #05b050;
    border-radius: 50%;
  }

  .red-dot {
    width: 12px;
    height: 12px;
    background-color: #cd4647;
    border-radius: 50%;
    margin-left: 20px;
  }

  p {
    margin-left: 10px;
    margin-top: 12px;
  }
}
tr {
  border-bottom: 1px solid $line-grey;
}

.colored-green {
  display: flex;
  height: 35px;
  width: 120px;
  align-items: center;
  justify-content: center;
  background: #e6f7ee;
  border-radius: 40px;

  .green-dot {
    width: 10px;
    height: 10px;
    background-color: #05b050;
    border-radius: 50%;
  }

  p {
    color: #05b050;
    margin-top: 15px;
    margin-left: 5px;
  }
}

.colored-yellow {
  display: flex;
  height: 35px;
  width: 120px;
  justify-content: center;
  align-items: center;
  background: #fff8e6;
  border-radius: 40px;

  .yellow-dot {
    width: 10px;
    height: 10px;
    background-color: #fab900;
    border-radius: 50%;
  }

  p {
    color: #fab900;
    margin-top: 15px;
    margin-left: 5px;
  }
}

.spaced {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: end;
  border-bottom: 0.2px solid #dee2e6;
}

.green-p {
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #05b050;
}

.red-p {
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #c32021;
}

.top-orders {
  display: flex;
  justify-content: space-between;
}
</style>
