<template>
  <!-- Side Bar -->
  <nav id="sidebarMenu" class="collapse d-md-block">
    <div
      v-if="userData.user.type != 'partner'"
      id="side-menu"
      class="list-group list-group-flush position-relative"
    >
      <img
        class="img-fluid collapse-icon"
        src="@/assets/images/vectors/col.svg"
        alt="collaspse-icon"
      />

      <a href="/dashboard" class="nav-item" aria-current="true">
        <div id="home" :class="['ripple', isRouteActive('/dashboard')]">
          <img
            class="img-fluid"
            src="@/assets/images/vectors/house.svg"
            alt="house"
          />
          <span>Home</span>
        </div>
      </a>

      <a href="javascript:void(0)" class="nav-item">
        <div
          id="marketplace"
          :class="[
            'ripple',
            'accordion-header',
            isRouteActive('/dashboard/marketplace'),
          ]"
        >
          <img
            class="img-fluid"
            src="@/assets/images/vectors/Storefront.svg"
            alt="marketplace"
          /><span>Marketplace</span>
          <img
            class="arrow-symbol carret-down"
            src="@/assets/images/vectors/arrowSymbol.svg"
            alt="Arrow-Symbol"
          />
          <img
            class="arrow-symbol carretUp"
            src="@/assets/images/vectors/arrowupsymbol.svg"
            alt="Arrow-Symbol"
          />
        </div>
        <!-- drop menu -->
        <div class="drop-menu marketplace-menu">
          <template v-if="userData.user.type == 'merchant'">
            <div @click="changeDir('/dashboard/marketplace/cropwanted')">
              Crops Wanted
            </div>
            <div @click="changeDir('/dashboard/marketplace/inputs')">
              Buy Inputs
            </div>
          </template>
          <template v-if="userData.user.type == 'corporate'">
            <div @click="changeDir('/dashboard/marketplace/cropsale')">
              Crops For Sale
            </div>
            <div @click="changeDir('/dashboard/marketplace/cropauction')">
              Crops For Auction
            </div>
          </template>
          <template v-if="userData.user.type == 'corporate'">
            <div @click="changeDir('/dashboard/marketplace/myproducts')">
              My Crops/Inputs
            </div>
          </template>
          <template v-if="userData.user.type == 'merchant'">
            <div @click="changeDir('/dashboard/marketplace/myproducts')">
              My Crops
            </div>
          </template>
          <div @click="changeDir('/dashboard/sales')">My Sales</div>
          <div @click="changeDir('/dashboard/myorders')">My Orders</div>
        </div>
      </a>

      <a href="javascript:void(0)" class="nav-item">
        <div
          :class="[
            'ripple',
            'accordion-header',
            isRouteActive('/dashboard/report'),
          ]"
        >
          <img
            class="side-bar-icon img-fluid"
            src="@/assets/images/vectors/marketPlace.svg"
            alt="report"
          /><span>Market Report</span>
          <img
            class="arrow-symbol carret-down"
            src="@/assets/images/vectors/arrowSymbol.svg"
            alt="Arrow-Symbol"
          />
          <img
            class="arrow-symbol carretUp"
            src="@/assets/images/vectors/arrowupsymbol.svg"
            alt="Arrow-Symbol"
          />
        </div>
        <!-- drop menu -->

        <!-- beginning of merchant -->

        <div
          v-if="userData.user.type == 'corporate'"
          class="drop-menu scoring-menu"
        >
          <!-- loan  -->
          <a
            target="_blank"
            href="https://share-eu1.hsforms.com/1R_aiVa-bTK6orq_7Dn-wZQf1d3v"
            class="active-menu give-padding"
          >
            Cash Crops
          </a>
          <a
            target="_blank"
            href="https://share-eu1.hsforms.com/1sihes4PjRdShbof_acQL2gf1d3v"
            class="active-menu give-padding"
          >
            Grains
          </a>
          <a
            target="_blank"
            href="https://share-eu1.hsforms.com/1Ggj1UTwKRRuZyXfeqt2zIQf1d3v?utm_source=hs_email&utm_medium=email&_hsenc=p2ANqtz-_edYQhhJFXVdSSH5sMwMc2zicOUQBeBSva4TWI2b_ohsozz-1hpBeozEgtidscfhIP5qxr"
            class="active-menu give-padding"
          >
            Price Offers
          </a>
          <a
            target="_blank"
            href="https://share-eu1.hsforms.com/1kyJhgHJTQWCdLWi8FmwbzAf1d3v"
            class="active-menu give-padding"
          >
            Benchmark
          </a>
          <!--Scoring and assessment -->
        </div>
        <div
          v-if="userData.user.type == 'merchant'"
          class="drop-menu scoring-menu"
        >
          <!-- loan  -->

          <a
            target="_blank"
            href="https://share-eu1.hsforms.com/1gvvrisAiRHKRxIGBVAAjsAf1d3v"
            class="active-menu give-padding"
          >
            Price Offers
          </a>
          <a
            target="_blank"
            href="https://share-eu1.hsforms.com/1N1i3P9VxQr-BfsMsIXtWTwf1d3v?utm_source=hs_email&utm_medium=email&_hsenc=p2ANqtz-8UU82-u_ZH4d85kIz0S-im5WRv3GdhUgyy0d2trqnGGIHr_xhWG9W-PxpLGQ4gv32x6mQr"
            class="active-menu give-padding"
          >
            Benchmark
          </a>
          <!--Scoring and assessment -->
        </div>

        <!-- end of merchant-->
      </a>

      <a href="javascript:void(0)" class="nav-item">
        <div
          :class="[
            'ripple',
            'accordion-header',
            isRouteActive('/dashboard/credit'),
          ]"
        >
          <img
            class="side-bar-icon img-fluid"
            src="@/assets/images/vectors/marketPlace.svg"
            alt="ACESS"
          /><span>ACESS</span>
          <img
            class="arrow-symbol carret-down"
            src="@/assets/images/vectors/arrowSymbol.svg"
            alt="Arrow-Symbol"
          />
          <img
            class="arrow-symbol carretUp"
            src="@/assets/images/vectors/arrowupsymbol.svg"
            alt="Arrow-Symbol"
          />
        </div>

        <div class="drop-menu scoring-menu">
          <!-- loan  -->
          <a id="loans-menu-1" class="nav-items">
            <div class="ripples">
              Merchant
              <!-- icons -->
              <img
                class="arrow-symbol carret-down"
                src="@/assets/images/vectors/arrowSymbol.svg"
                alt="Arrow-Symbol"
              />
              <img
                class="arrow-symbol carretUp"
                src="@/assets/images/vectors/arrowupsymbol.svg"
                alt="Arrow-Symbol"
              />
            </div>
            <!-- drop menu -->
            <div id="loans-sub-menu-1" class="drop-menu ms-0 show sub-menus">
              <div
                class="active-menu"
                @click="changeDir(' /dashboard/merchant/score/dvs ')"
              >
                Run DVS
              </div>
              <div
                class="active-menu"
                @click="changeDir(' /dashboard/merchant/dvs/score')"
              >
                View Score
              </div>
            </div>
          </a>

          <a id="loans-menu-1" class="nav-items">
            <div class="ripples">
              Farmer
              <!-- icons -->
              <img
                class="arrow-symbol carret-down"
                src="@/assets/images/vectors/arrowSymbol.svg"
                alt="Arrow-Symbol"
              />
              <img
                class="arrow-symbol carretUp"
                src="@/assets/images/vectors/arrowupsymbol.svg"
                alt="Arrow-Symbol"
              />
            </div>
            <!-- drop menu -->
            <div id="loans-sub-menu-1" class="drop-menu ms-0 show sub-menus">
              <div
                class="active-menu"
                @click="changeDir('/dashboard/farmer/batch/add')"
              >
                Add
              </div>
              <div
                class="active-menu"
                @click="changeDir('/dashboard/farmer/view')"
              >
                View
              </div>
              <a
                href="/dashboard/farmer/score/dvs/all"
                class="active-menu give-padding"
              >
                Run DVS
              </a>

              <a
                href="/dashboard/creditscoring"
                class="active-menu give-padding"
              >
                View PDS
              </a>
            </div>
          </a>
        </div>
        <!--Scoring and assessment -->

        <!-- end of merchant-->
      </a>

      <a href="/dashboard/negotiations" class="nav-item">
        <div :class="['ripple', isRouteActive('/dashboard/negotiations')]">
          <img
            class="img-fluid"
            src="@/assets/images/vectors/ChatText.svg"
            alt="ChatText"
          /><span>Negotiations</span>
        </div>
      </a>
      <!-- My accounts -->

      <a href="/dashboard/wallet" class="nav-item">
        <div :class="['ripple', isRouteActive('/dashboard/wallet')]">
          <img
            class="img-fluid"
            src="@/assets/images/vectors/Wallet.svg"
            alt="wallet"
          />
          <span>My Accounts</span>
        </div>
      </a>
      <a href="/dashboard/subscriptions" class="nav-item">
        <div :class="['ripple', isRouteActive('/dashboard/subscriptions')]">
          <img
            class="img-fluid"
            src="@/assets/images/vectors/Wallet.svg"
            alt="wallet"
          />
          <span>Subscriptions</span>
        </div>
      </a>

      <a href="javascript:void(0)" class="nav-item">
        <div
          :class="[
            'ripple',
            'accordion-header',
            isRouteActive('/dashboard/data'),
          ]"
        >
          <img
            class="img-fluid"
            src="@/assets/images/vectors/Data.svg"
            alt="Data"
          /><span>Coming Soon</span>
          <img
            class="arrow-symbol carret-down"
            src="@/assets/images/vectors/arrowSymbol.svg"
            alt="Arrow-Symbol"
          />
          <img
            class="arrow-symbol carretUp"
            src="@/assets/images/vectors/arrowupsymbol.svg"
            alt="Arrow-Symbol"
          />
        </div>
        <!-- drop menu -->
        <div class="drop-menu data-menu">
          <div class="active-menu">Precision Ag</div>
          <div class="active-menu">Sustainable Ag</div>
          <div class="active-menu">Traceabilty Ag</div>
          <div class="active-menu">Subscription</div>
          <div class="active-menu">API</div>
        </div>
      </a>

      <a href="javascript:void(0)" class="nav-item">
        <div
          :class="[
            'ripple',
            'accordion-header',
            isRouteActive('/dashboard/settings'),
          ]"
        >
          <img
            class="side-bar-icon img-fluid"
            src="@/assets/images/vectors/GearSixBold.svg"
            alt="settings"
          /><span>Settings</span>
          <img
            class="arrow-symbol carret-down"
            src="@/assets/images/vectors/arrowSymbol.svg"
            alt="Arrow-Symbol"
          />
          <img
            class="arrow-symbol carretUp"
            src="@/assets/images/vectors/arrowupsymbol.svg"
            alt="Arrow-Symbol"
          />
        </div>
        <!-- drop menu -->
        <div class="drop-menu scoring-menu">
          <!-- loan  -->
          <a id="loans-menu-1" class="nav-items">
            <div class="ripples">
              My Team Members
              <!-- icons -->
              <img
                class="arrow-symbol carret-down"
                src="@/assets/images/vectors/arrowSymbol.svg"
                alt="Arrow-Symbol"
              />
              <img
                class="arrow-symbol carretUp"
                src="@/assets/images/vectors/arrowupsymbol.svg"
                alt="Arrow-Symbol"
              />
            </div>
            <!-- drop menu -->
            <div id="loans-sub-menu-1" class="drop-menu ms-0 show sub-menus">
              <div class="active-menu" @click="changeDir(' /dashboard/team ')">
                View All Team Members
              </div>
              <div
                class="active-menu"
                @click="changeDir(' /dashboard/addmember')"
              >
                Add New Team Members
              </div>
            </div>
          </a>
          <!--Scoring and assessment -->
        </div>

        <!-- beginning of merchant -->

        <div class="drop-menu scoring-menu">
          <!-- loan  -->
          <div class="active-menu" @click="changeDir(' /dashboard/settings ')">
            Profile Settings
          </div>
          <!--Scoring and assessment -->
        </div>

        <!-- end of merchant-->
      </a>
      <!-- <a href="javascript:void(0)" class="nav-item">
        <div
          :class="[
            'ripple',
            'accordion-header',
            isRouteActive('/dashboard/credit'),
          ]"
        >
          <img
            class="side-bar-icon img-fluid"
            src="@/assets/images/vectors/GearSixBold.svg"
            alt="Credit Scoring"
          /><span>Settings</span>
          <img
            class="arrow-symbol carret-down"
            src="@/assets/images/vectors/arrowSymbol.svg"
            alt="Arrow-Symbol"
          />
          <img
            class="arrow-symbol carretUp"
            src="@/assets/images/vectors/arrowupsymbol.svg"
            alt="Arrow-Symbol"
          />
        </div>
        <div class="drop-menu scoring-menu"></div>

        <div class="drop-menu scoring-menu">
          <div class="active-menu" @click="changeDir(' /dashboard/settings ')">
            Profile Settings
          </div>
        </div>
      </a> -->

      <!-- <a href="javascript:void(0)" class="nav-item">
          <div :class="['ripple', isRouteActive('/dashboard/account')]">
            <img
              class="img-fluid"
              src="@/assets/images/vectors/User.svg"
              alt="user"
            /><span>My account</span>
          </div>
        </a> -->
      <!-- <a href="javascript:void(0)" class="nav-item">
        <div :class="['ripple']">
          <img
            class="img-fluid"
            src="@/assets/images/vectors/Group.svg"
            alt="user"
          /><span>Faq</span>
        </div>
      </a> -->
      <!-- <a href="javascript:void(0)" class="nav-item">
        <div class="ripple active-menu">
          <img
            class="img-fluid"
            src="@/assets/images/vectors/SignOut.svg"
            alt="SignOut"
          /><span>API</span>
        </div>
      </a> -->

      <a class="nav-item" @click="logOut()">
        <div class="ripple active-menu">
          <img
            class="img-fluid"
            src="@/assets/images/vectors/SignOut.svg"
            alt="SignOut"
          /><span>Logout</span>
        </div>
      </a>
    </div>
    <div
      v-if="userData.user.type == 'partner'"
      id="side-menu"
      class="list-group list-group-flush position-relative"
    >
      <img
        class="img-fluid collapse-icon"
        src="@/assets/images/vectors/col.svg"
        alt="collaspse-icon"
      />

      <a href="/dashboard" class="nav-item" aria-current="true">
        <div id="home" :class="['ripple', isRouteActive('/dashboard')]">
          <img
            class="img-fluid"
            src="@/assets/images/vectors/house.svg"
            alt="house"
          />
          <span>Home</span>
        </div>
      </a>
      <a href="/dashboard/wallet" class="nav-item">
        <div :class="['ripple', isRouteActive('/dashboard/wallet')]">
          <img
            class="img-fluid"
            src="@/assets/images/vectors/Wallet.svg"
            alt="wallet"
          />
          <span>My Account</span>
        </div>
      </a>
      <a href="/dashboard/subscriptions" class="nav-item">
        <div :class="['ripple', isRouteActive('/dashboard/subscriptions')]">
          <img
            class="img-fluid"
            src="@/assets/images/vectors/Wallet.svg"
            alt="wallet"
          />
          <span>Subscriptions</span>
        </div>
      </a>
      <a href="javascript:void(0)" class="nav-item">
        <div
          :class="[
            'ripple',
            'accordion-header',
            isRouteActive('/dashboard/farmer/view'),
          ]"
        >
          <img
            class="img-fluid"
            src="@/assets/images/vectors/Storefront.svg"
            alt="farmer"
          /><span>Farmer</span>
          <img
            class="arrow-symbol carret-down"
            src="@/assets/images/vectors/arrowSymbol.svg"
            alt="Arrow-Symbol"
          />
          <img
            class="arrow-symbol carretUp"
            src="@/assets/images/vectors/arrowupsymbol.svg"
            alt="Arrow-Symbol"
          />
        </div>
        <!-- drop menu -->
        <div class="drop-menu marketplace-menu">
          <div
            v-if="userData.user.type !== 'partner'"
            class="active-menu"
            @click="changeDir('/dashboard/farmer/batch/add')"
          >
            Add Farmer
          </div>
          <div class="active-menu" @click="changeDir('/dashboard/farmer/view')">
            View Farmers
          </div>
        </div>
      </a>

      <a href="javascript:void(0)" class="nav-item">
        <div
          :class="[
            'ripple',
            'accordion-header',
            isRouteActive('/dashboard/creditcoring'),
          ]"
        >
          <img
            class="img-fluid"
            src="@/assets/images/vectors/marketPlace.svg"
            alt="creditscore"
          /><span>Score</span>
          <img
            class="arrow-symbol carret-down"
            src="@/assets/images/vectors/arrowSymbol.svg"
            alt="Arrow-Symbol"
          />
          <img
            class="arrow-symbol carretUp"
            src="@/assets/images/vectors/arrowupsymbol.svg"
            alt="Arrow-Symbol"
          />
        </div>
        <!-- drop menu -->
        <div class="drop-menu marketplace-menu">
          <div
            class="active-menu"
            @click="changeDir('/dashboard/creditscoring')"
          >
            PDS
          </div>
          <div
            class="active-menu"
            @click="changeDir('/dashboard/farmer/score/dvs/all')"
          >
            DVS
          </div>
        </div>
      </a>

      <a href="javascript:void(0)" class="nav-item">
        <div
          :class="[
            'ripple',
            'accordion-header',
            isRouteActive('/dashboard/loan'),
          ]"
        >
          <img
            class="side-bar-icon img-fluid"
            src="@/assets/images/vectors/transactions.svg"
            alt="Loan"
          /><span>Loan</span>
          <img
            class="arrow-symbol carret-down"
            src="@/assets/images/vectors/arrowSymbol.svg"
            alt="Arrow-Symbol"
          />
          <img
            class="arrow-symbol carretUp"
            src="@/assets/images/vectors/arrowupsymbol.svg"
            alt="Arrow-Symbol"
          />
        </div>
        <!-- drop menu -->
        <div class="drop-menu marketplace-menu">
          <div
            class="active-menu"
            @click="changeDir(' /dashboard/loanhistory')"
          >
            History
          </div>
          <div class="active-menu" @click="changeDir('/dashboard/loanstatus')">
            Status
          </div>
        </div>
      </a>

      <a href="javascript:void(0)" class="nav-item">
        <div
          :class="[
            'ripple',
            'accordion-header',
            isRouteActive('/dashboard/type'),
          ]"
        >
          <img
            class="side-bar-icon img-fluid"
            src="@/assets/images/vectors/Data.svg"
            alt="type"
          /><span>Products</span>
          <img
            class="arrow-symbol carret-down"
            src="@/assets/images/vectors/arrowSymbol.svg"
            alt="Arrow-Symbol"
          />
          <img
            class="arrow-symbol carretUp"
            src="@/assets/images/vectors/arrowupsymbol.svg"
            alt="Arrow-Symbol"
          />
        </div>
        <!-- drop menu -->
        <div class="drop-menu marketplace-menu">
          <div
            class="active-menu"
            @click="changeDir('/dashboard/loantype/add')"
          >
            New
          </div>
          <div class="active-menu" @click="changeDir('/dashboard/loantype')">
            View
          </div>
        </div>
      </a>

      <a href="javascript:void(0)" class="nav-item">
        <div
          :class="[
            'ripple',
            'accordion-header',
            isRouteActive('/dashboard/settings'),
          ]"
        >
          <img
            class="side-bar-icon img-fluid"
            src="@/assets/images/vectors/GearSixBold.svg"
            alt="settings"
          /><span>Settings</span>
          <img
            class="arrow-symbol carret-down"
            src="@/assets/images/vectors/arrowSymbol.svg"
            alt="Arrow-Symbol"
          />
          <img
            class="arrow-symbol carretUp"
            src="@/assets/images/vectors/arrowupsymbol.svg"
            alt="Arrow-Symbol"
          />
        </div>
        <!-- drop menu -->
        <div class="drop-menu scoring-menu">
          <!-- loan  -->
          <a id="loans-menu-1" class="nav-items">
            <div class="ripples">
              My Team Members
              <!-- icons -->
              <img
                class="arrow-symbol carret-down"
                src="@/assets/images/vectors/arrowSymbol.svg"
                alt="Arrow-Symbol"
              />
              <img
                class="arrow-symbol carretUp"
                src="@/assets/images/vectors/arrowupsymbol.svg"
                alt="Arrow-Symbol"
              />
            </div>
            <!-- drop menu -->
            <div id="loans-sub-menu-1" class="drop-menu ms-0 show sub-menus">
              <div class="active-menu" @click="changeDir(' /dashboard/team ')">
                View All Team Members
              </div>
              <div
                class="active-menu"
                @click="changeDir(' /dashboard/addmember')"
              >
                Add New Team Members
              </div>
            </div>
          </a>
          <!--Scoring and assessment -->
        </div>

        <!-- beginning of merchant -->

        <div class="drop-menu scoring-menu">
          <!-- loan  -->
          <div class="active-menu" @click="changeDir(' /dashboard/settings ')">
            Profile Settings
          </div>
          <!--Scoring and assessment -->
        </div>

        <!-- end of merchant-->
      </a>

      <a class="nav-item" @click="logOut()">
        <div class="ripple active-menu">
          <img
            class="img-fluid"
            src="@/assets/images/vectors/SignOut.svg"
            alt="SignOut"
          /><span>Logout</span>
        </div>
      </a>
    </div>
  </nav>
</template>

<script>
export default {
  name: "SideBar",
  components: {
    // Modal,
  },
  data() {
    return {
      userData: this.$store.state.user,
      collapseMenu: true,
      accountTypeRoutes: this.$route.params.market,
    };
  },
  mounted() {
    this.accordion();
    this.toggleCollapsedMenu();
  },
  methods: {
    accordion() {
      const accordionHeaders = document.querySelectorAll(".accordion-header");
      // using native forEach for NodeList.
      accordionHeaders.forEach((header) => {
        header.addEventListener("click", () => {
          header.parentElement.classList.toggle("active");
        });
      });
      // target the marketplace menu based on the account type
      /* ------ make sure this feature does not run for partner account types ----- */
      if (this.userData.user.type != "partner") {
        const marketPlaceMenu = document.getElementById("marketplace");
        if (this.accountTypeRoutes == "cropwanted" || "cropsale") {
          marketPlaceMenu.parentElement.classList.toggle("active");
        }
        if (this.accountTypeRoutes == undefined) {
          marketPlaceMenu.parentElement.classList.remove("active");
        }
      }
    },
    changeDir(path) {
      window.location.assign(path);
    },
    toggleCollapsedMenu() {
      const sideMenu = document.getElementById("sidebarMenu");

      if (sideMenu) {
        sideMenu.addEventListener("click", (event) => {
          if (event.target.classList.contains("collapse-icon")) {
            sideMenu.classList.toggle("collapsed-menu");
          } else if (event.target.classList.contains("accordion-header")) {
            if (sideMenu.classList.contains("collapsed-menu")) {
              sideMenu.classList.remove("collapsed-menu");
            }
          } else if (event.target.classList.contains("active-item")) {
            event.target.classList.toggle("active-menu");
          }
        });

        const menus = document.querySelectorAll(".nav-items");

        menus.forEach((menu) => {
          menu.addEventListener("click", () => {
            menu.classList.toggle("active-submenu");
            const subMenu = menu.querySelector(".drop-menu");
            subMenu.classList.toggle("show");
          });
        });
      }
    },
    isRouteActive(path) {
      if (path == "/dashboard") {
        return this.$route.path == path ? "active-item" : "";
      }
      return this.$route.path.includes(path) ? "active-item" : "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/navigation";
.collapse-icon {
  position: absolute;
  width: 30px;
  height: 20px;
  top: -25px;
  right: 0%;

  &:hover {
    padding-inline: 5px;
  }
}

#sidebarMenu {
  overflow-y: scroll;
}

.collapsed-menu {
  width: 8% !important;

  #side-menu {
    align-items: center;

    .collapse-icon {
      transform: rotate(180deg);
    }

    .ripple {
      padding: 10px 20px;
      margin-top: 10px;

      span,
      .carret-down {
        display: none;
      }
    }

    .drop-menu {
      display: none !important;
    }
  }
}
// toggling the active menus by changing background color
.active-item {
  background: #05b050 !important;
}
.ripples {
  position: relative;
  img.arrow-symbol {
    float: right;
    margin: 0;
    position: absolute;
    top: 55%;
    right: 6%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  img.carretUp {
    display: none;
  }
}
a#loans-menu-1.active-submenu,
a#loans-menu-2.active-submenu {
  .ripples {
    img.carret-down {
      display: none;
    }

    img.carretUp {
      display: block;
    }
  }
}

.show {
  display: none !important;
}
.give-padding {
  padding: 9px 15px;
  display: block;
}
.give-padding:hover {
  background: rgba(0, 0, 0, 0.27);
  border-radius: 4px;
}
</style>
