<template>
  <!-- for dispactch Section -->
  <div class="dispatch-section">
    <h1 class="mb-4">Dispatch Section</h1>
    <form @submit.prevent="proceed(dispatch)">
      <!-- <p>Buyer's Preferred Location:</p>
      <h4>{{ trackingDetails.delivery_location }}</h4> -->
      <div class="inputs-group margin-btm-lg">
        <div class="">
          <label for="exampleInputEmail1" class="form-label margin-btm-sm mt-0"
            >From</label
          >
          <input
            id="exampleInputEmail1"
            v-model="dispatch.from"
            type="text"
            class="form-control mt-0"
            aria-describedby="emailHelp"
            placeholder="Seller Information"
            required
          />
        </div>
        <div class="text">
          <label for="exampleInputEmail1" class="form-label margin-btm-sm mt-0"
            >To</label
          >
          <input
            id="exampleInputEmail1"
            v-model="dispatch.to"
            type=""
            class="form-control mt-0"
            aria-describedby="emailHelp"
            placeholder="Buyer Information "
            required
          />
        </div>
      </div>
      <div class="inputs-group margin-btm-lg">
        <div class="">
          <label for="exampleInputEmail1" class="form-label margin-btm-sm mt-0"
            >Date</label
          >
          <input
            id="exampleInputEmail1"
            v-model="dispatch.date"
            type="date"
            class="form-control mt-0"
            aria-describedby="emailHelp"
            required
          />
        </div>
        <div class="">
          <label for="exampleInputEmail1" class="form-label margin-btm-sm mt-0"
            >Cosignee</label
          >
          <input
            id="exampleInputEmail1"
            v-model="dispatch.cosignee"
            type="text"
            class="form-control mt-0"
            aria-describedby="emailHelp"
            placeholder="Cosignee"
            required
          />
        </div>
      </div>
      <div class="inputs-group margin-btm-lg">
        <div class="">
          <label for="exampleInputEmail1" class="form-label margin-btm-sm mt-0"
            >Truck Number</label
          >
          <input
            id="exampleInputEmail1"
            v-model="dispatch.truck_number"
            type="text"
            class="form-control mt-0"
            aria-describedby="emailHelp"
            placeholder="Truck Number"
            required
          />
        </div>
      </div>
      <div class="inputs-group margin-btm-lg">
        <div class="w-100">
          <label for="exampleInputEmail1" class="form-label margin-btm-sm mt-0"
            >Remarks</label
          >
          <textarea
            id="exampleInputEmail1"
            v-model="dispatch.remarks"
            type="text"
            class="form-control mt-0"
            aria-describedby="emailHelp"
            placeholder="Remarks"
            height="600"
            required
          />
        </div>
      </div>
      <div class="inputs-group margin-btm-lg">
        <div class="">
          <label for="exampleInputEmail1" class="form-label margin-btm-sm mt-0"
            >Drivers Name</label
          >
          <input
            id="exampleInputEmail1"
            v-model="dispatch.drivers_data.drivers_name"
            type="text"
            class="form-control mt-0"
            aria-describedby="emailHelp"
            placeholder="Drivers Name"
            required
          />
        </div>
        <div class="">
          <label for="exampleInputEmail1" class="form-label margin-btm-sm mt-0"
            >Sellers Representative</label
          >
          <input
            id="exampleInputEmail1"
            v-model="dispatch.sellers_data.sellers_representative"
            type="text"
            class="form-control mt-0"
            aria-describedby="emailHelp"
            placeholder="Sellers Representative"
            required
          />
        </div>
      </div>
      <div class="inputs-group margin-btm-lg">
        <div class="">
          <label for="exampleInputEmail1" class="form-label margin-btm-sm mt-0"
            >Driver's Phone Number</label
          >
          <input
            id="exampleInputEmail1"
            v-model="dispatch.drivers_data.drivers_number"
            type="text"
            class="form-control mt-0"
            aria-describedby="emailHelp"
            placeholder="Number"
            required
          />
        </div>
        <div class="">
          <label for="exampleInputEmail1" class="form-label margin-btm-sm mt-0"
            >Representative's Phone Number</label
          >
          <input
            id="exampleInputEmail1"
            v-model="dispatch.sellers_data.sellers_representative_number"
            type="text"
            class="form-control mt-0"
            aria-describedby="emailHelp"
            placeholder="Representative's Phone number"
            required
          />
        </div>
      </div>
      <div class="inputs-group margin-btm-lg">
        <div class="">
          <label for="exampleInputEmail1" class="form-label margin-btm-sm mt-0"
            >Driving License/ Permit Number</label
          >
          <input
            id="exampleInputEmail1"
            v-model="dispatch.drivers_data.driving_license"
            type="text"
            class="form-control mt-0"
            aria-describedby="emailHelp"
            placeholder="Driving License/ Permit Number"
            required
          />
        </div>
        <div class="">
          <label for="exampleInputEmail1" class="form-label margin-btm-sm mt-0"
            >Alternate Phone</label
          >
          <input
            id="exampleInputEmail1"
            v-model="dispatch.sellers_data.title"
            type="text"
            class="form-control mt-0"
            aria-describedby="emailHelp"
            placeholder="Alternate Phone"
            required
          />
        </div>
      </div>
      <button
        :class="['btn', 'table-btn', 'w-100', 'mb-4', 'btn-hover-state']"
        type="submit"
      >
        Submit Waybill
      </button>
    </form>
  </div>
</template>

<script>
import MarketPlaceService from "@/services/marketplace";
export default {
  name: "DispatchSection",
  components: {},
  props: {
    proceed: Function,
    order: Object,
  },
  data() {
    return {
      dispatch: {
        from: "",
        to: "",
        date: "",
        cosignee: "",
        truck_number: "",
        description: "",
        quantity: "",
        remarks: "",
        drivers_data: {
          drivers_name: "",
          driving_license: "",
          drivers_number: "",
          date: new Date().toDateString(),
        },
        sellers_data: {
          sellers_representative: "",
          title: "",
          sellers_representative_number: "",
          date: new Date().toDateString(),
        },
      },
    };
  },
  computed: {
    trackingDetails() {
      return this.order ? JSON.parse(this.order.tracking_details) : null;
    },
  },
  mounted() {
    const user = this.userData.user;
    const order = this.order;
    const date = new Date();
    const formattedDate = date.toISOString().split("T")[0];
    const tracking = order.tracking_details
      ? JSON.parse(order.tracking_details)
      : null;
    const deliveryLocation = tracking ? tracking.delivery_location : "";
    this.dispatch = {
      ...this.dispatch,
      from: order.products[0].warehouse_address,
      to: deliveryLocation,
      date: formattedDate,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

h1 {
  text-align: center;
  text-transform: capitalize;
  color: #1d1d1d;
  @include textStyles(Poppins, 700, 18px, 28px);
}

form {
  .inputs-group {
    display: flex;
    flex-direction: row;
    gap: 45px;

    > div {
      width: 100%;
    }
  }

  div.margin-btm-lg {
    margin-bottom: 10px;
  }

  label {
    font-size: 12px;
  }

  label.margin-btm-sm {
    margin-bottom: 4px;
    color: rgba(45, 55, 72, 0.8);
  }

  input.form-control,
  textarea.form-control {
    font-size: 12px;
    background-color: #f5f5f5 !important;

    &::placeholder {
      @include textStyles(Poppins, 700, 12px, 28px);
    }
  }

  // button group
  button.table-btn {
    margin-top: 33px;
    padding: 15px 20px;
    background: #05b050;
    border-radius: 5px;
    @include textStyles(Inter, 600, 16px, 19px);
    color: #ffffff;
  }
}
</style>
