<template>
  <DefaultNav>
    <!-- preloader -->
    <Preloader v-if="loading" />
    <!-- content -->
    <div v-else class="big-container">
      <div class="big-content">
        <!-- header -->
        <div class="arrow-section">
          <a href="/dashboard/marketplace/myproducts"
            ><img src="@/assets/images/vectors/arrowleft.png" alt=""
          /></a>
          <h1>New Crop Wanted</h1>
        </div>

        <form v-show="step == 1" action="" @submit.prevent="changeTab()">
          <!-- Crop Details Components -->
          <CropDetails v-show="step == 1" ref="CD" />
          <div id="btn-group" class="btn-group gap-3 my-4">
            <button
              :id="['next_btn']"
              :class="['btn', 'btn-primary']"
              type="submit"
            >
              Next
            </button>
          </div>
        </form>
        <form v-show="step == 2" action="" @submit.prevent="changeTab()">
          <QualityProduct ref="QP" type="wanted" />
          <div id="btn-group" class="btn-group gap-3 my-4">
            <button
              type="button"
              class="btn btn-primary active"
              aria-current="page"
              @click="previouStep()"
            >
              Back
            </button>
            <button
              v-if="step != 3"
              :id="['next_btn']"
              :class="['btn', 'btn-primary']"
              type="submit"
            >
              Next
            </button>
          </div>
        </form>
        <form v-show="step == 3" action="" @submit.prevent="saveData()">
          <CropSpecification ref="CS" />
          <div id="btn-group" class="btn-group gap-3 my-4">
            <button
              type="button"
              class="btn btn-primary active"
              aria-current="page"
              @click="previouStep()"
            >
              Back
            </button>
            <button type="submit" :class="['btn', 'btn-primary']">
              {{ isEditing ? "Update" : "save" }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </DefaultNav>
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import CropDetails from "@/pages/dashboard/marketPlace/components/CropDetails.vue";
import QualityProduct from "@/pages/dashboard/marketPlace/components/QualityProduct.vue";
import CropSpecification from "@/pages/dashboard/marketPlace/components/CropSpecification.vue";
import Alert from "@/utilities/alert";
// importing the marketplace service
import MarketPlaceService from "@/services/marketplace";
// import preloader
import Preloader from "@/layouts/shared/Preloader.vue";

export default {
  name: "AddNewcrop",
  components: {
    DefaultNav,
    CropDetails,
    QualityProduct,
    CropSpecification,
    Preloader,
  },
  data() {
    return {
      step: 1,
      crop_details: {},
      quality_product: {},
      crop_specification: {},
      loading: false,
      isEditing: false,
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.isEditing = true;
    }
  },
  methods: {
    changeTab() {
      if (this.step == 1) {
        this.crop_details = this.$refs.CD.cropData;
      }
      if (this.step == 2) {
        this.quality_product = this.$refs.QP.newCropData;
      }
      this.step++;
    },

    previouStep() {
      this.step--;
    },

    async saveData() {
      let vm = this;
      // call the preloader
      vm.loading = true;
      // combine data from child components
      const addData = {
        ...this.crop_details,
        ...this.quality_product,
        ...this.$refs.CS.cropSpecificationData,
      };
      var data = new FormData();
      var index = 0;
      while (addData.files[index]) {
        data.append(`image_${index}`, addData.files[index]);
        index++;
      }
      for (var field in addData) {
        if (field != "files") {
          if (typeof addData[field] == "object") {
            addData[field] = JSON.stringify(addData[field]);
          }
          data.append(field, addData[field]);
        }
      }
      if (this.isEditing) {
        data.append("crop_id", this.$route.params.id);

        MarketPlaceService.updateCropWanted(data, (response) => {
          if (response && response.error == false) {
            Alert.success({
              message: "Crop Updated successfully",
            });
            setTimeout(() => {
              window.location.assign("/dashboard/marketplace/myproducts");
            }, 2000);
          } else {
            Alert.error({
              message: "Failed To Updated Crop",
            });
          }
          vm.loading = false;
        });
        // Else save the new product
      } else {
        // send data to the end-point
        MarketPlaceService.addCropWanted(data, (response) => {
          if (response && response.error == false) {
            Alert.success({
              message: "Crop added successfully",
            });
            setTimeout(() => {
              window.location.assign("/dashboard/marketplace/myproducts");
            }, 2000);
          }
          // set the preloader to false
          vm.loading = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;

  @include breakpoint-between(md, lg) {
    min-width: 60.5%;
  }

  @include breakpoint-between(lg, xl) {
    min-width: 69.5%;
  }

  @include breakpoint-between(xl, xxl) {
    min-width: 76%;
  }
}

.big-content {
  padding: 0px 4rem;
  background: #f5f5f5;

  h1 {
    @include textStyles(Poppins, 700, 24px, 120%);
    color: #4a4754;
    text-align: left;
    margin-block: 3rem;
  }
}

#btn-group {
  float: right;

  #next_btn {
    background: #05b050;
    color: #e6f7ee;
  }

  button {
    background: #ffffff;
    border: 1px solid #ededee;
    box-shadow:
      0px 2px 4px rgba(44, 39, 56, 0.0001),
      0px 4px 8px rgba(44, 39, 56, 0.08);
    border-radius: 4px;
    text-align: center;
    color: #6ed19a;
    padding-inline: 3rem;
    @include textStyles("Maven Pro", 700, 16px, 24px);

    ~ button {
      @extend #next_btn;
    }
  }
}
.arrow-section {
  display: flex;
  align-items: center;
  gap: 20px;

  img {
    width: 25px;
    height: 25px;
  }
}
</style>
