<template>
  <DefaultNav>
    <div class="big-container">
      <KycVerification></KycVerification>
    </div>
  </DefaultNav>
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import KycVerification from "./KycVerification";

export default {
  name: "KycRedirect",
  components: {
    DefaultNav,
    KycVerification,
  },
};
</script>
