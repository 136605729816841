<template>
  <div class="bg-white kyb-container">
    <!-- First form with KybDataOne component -->
    <form v-show="tab == 1" @submit.prevent="nextTab()">
      <KybDataOne ref="KO" :kyb-data-one="KybDataOne"></KybDataOne>
      <button v-if="tab === 1" class="me-4" type="submit">Next</button>
    </form>

    <!-- Second form with KybDataTwo component -->
    <form v-show="tab === 2" @submit.prevent="nextTab()">
      <KybDataTwo ref="KT"></KybDataTwo>
      <div class="btn-area">
        <button v-if="tab === 2" type="button" @click="backTab">Back</button>
        <button v-if="tab === 2" class="me-4" type="submit">Next</button>
      </div>
    </form>
    <!-- The prefilled components -->
    <KybPrefilled
      v-if="tab === 3"
      :preview-data="previewData"
      @submit.prevent="save"
    ></KybPrefilled>
    <div class="btn-area">
      <button
        v-if="tab === 3"
        v-show="formatStatus.toLowerCase() === 'unverified'"
        id="back-btn"
        type="button"
        @click="backTab"
      >
        Back
      </button>
      <button
        v-if="tab === 3"
        v-show="formatStatus.toLowerCase() === 'unverified'"
        id="save-btn"
        type="submit"
        @click="save"
      >
        Save
      </button>
    </div>
  </div>
  <!-- Preloader component to show loading animation -->
  <Preloader v-if="isLoading" />
</template>

<script>
import KybPrefilled from "./components/KybPrefilled.vue";
import KybDataOne from "./components/KybOne.vue";
import KybDataTwo from "./components/KybTwo.vue";
import MarketPlaceService from "@/services/marketplace";
import Alert from "@/utilities/alert";
import Preloader from "@/layouts/shared/Preloader.vue"; // Import Preloader component

export default {
  name: "KybVerification",
  components: {
    KybDataOne,
    KybDataTwo,
    Preloader,
    KybPrefilled,
  },
  data() {
    return {
      tab: 1, // Current tab
      KDOne: {}, // Data object to hold file information from KybDataOne component
      isLoading: false,
      previewData: {}, // Flag to show loading animation
    };
  },
  computed: {
    formatStatus() {
      return this.$store.state.kybstatus;
    },
  },
  mounted() {
    if (this.formatStatus.toLowerCase() != "unverified") {
      this.tab = 3;
    }
  },
  methods: {
    // Method to s witch to next tab and get data from KybDataOne component
    nextTab() {
      if (this.tab == 1) {
        // Check for current tab
        this.KDOne = this.$refs.KO.KybDataOne; // Get data from child component
      }
      if (this.tab == 2) {
        this.methodToSaveDataFromBothComponents();
      }
      this.tab++; // Switch to next tab
    },
    // Method to switch back to previous tab
    backTab() {
      this.tab--;
    },
    methodToSaveDataFromBothComponents() {
      var formData = new FormData();
      // Append file data from KybDataTwo component to FormData object
      Object.keys(this.$refs.KT.files).forEach((key) => {
        formData.append(
          key,
          this.$refs.KT.files[key],
          this.$refs.KT.files[key].name,
        );
      });
      // Append other data from KybDataOne component to FormData object
      formData.append("name", this.KDOne.name);
      formData.append("address", this.KDOne.address);
      formData.append("state", this.KDOne.state);
      formData.append("country", this.KDOne.country);
      formData.append("contact_person", this.KDOne.contact_person);
      formData.append("phone", this.KDOne.phone);
      formData.append("website", this.KDOne.website);
      formData.append("email", this.KDOne.email);
      formData.append("tax_id", this.KDOne.tax_id);

      this.previewData = formData;
    },
    // Method to save data from both components
    save() {
      this.isLoading = true;
      MarketPlaceService.sendKyb(this.previewData, (response) => {
        if (response && response.error == false) {
          Alert.success({
            message: "KYB Verification Submitted",
          });
          this.$store.dispatch("setKyb", "Pending Verification");
          this.isLoading = false;
        } else {
          Alert.error({
            message: "An error occured",
          });
        }
        this.isLoading = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.kyb-container {
  margin-top: 30px;
  width: 100%;
}
.btn-area {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding-inline: 6em;
}
button {
  background: #05b050;
  border-radius: 1.5px;
  width: 120px;
  height: 40px;
  border: none;
  color: white;
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  float: right;
  margin-bottom: 50px;
}
// #save-btn,
// #back-btn {
//   display: none;
// }
</style>
