<template>
  <!-- Modal -->
  <div
    id="exampleModal1"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 id="exampleModalLabel" class="modal-title fs-5">
            Change Profile Picture
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <!-- form -->
        <div class="modal-body">
          <div class="profile-picture-cropper">
            <div class="w-100 mb-3">
              <label for="formGroupExampleInput" class="form-label mb-0"
                >Upload Image</label
              >
              <span id="required">*</span>
              <input
                id="formGroupExampleInput"
                type="file"
                class="form-control"
                required
                @change="handleFileUpload"
              />
            </div>
            <cropper
              v-if="imageLoaded"
              ref="cropper"
              :src="imageSrc"
              :guides="false"
              :view-mode="1"
              :drag-mode="cropType"
              :aspect-ratio="aspectRatio"
              :auto-crop-area="1"
              :movable="false"
            />
            <div v-if="imageLoaded" class="buttons">
              <button @click="cropImage">Crop</button>
              <button @click="cancelCrop">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "cropperjs/dist/cropper.css";
import Cropper from "vue-cropperjs";

export default {
  name: "UpadateAvatar",
  components: { Cropper },
  props: {
    aspectRatio: {
      type: Number,
      default: 1,
    },
    cropType: {
      type: String,
      default: "crop",
    },
  },
  data() {
    return {
      imageSrc: "", // This will hold the source of the image to be cropped
      imageLoaded: false, // This will track if the image has been loaded
      cropper: null, // This will hold the reference to the cropper instance
      originalImage: null, // This will hold the original image data
    };
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0]; // Get the selected file from the input
      if (!file.type.match("image.*")) {
        // Check if the selected file is an image
        alert("Please select an image file"); // Alert the user if the selected file is not an image
        return; // Exit the function if the selected file is not an image
      }
      const reader = new FileReader(); // Create a new FileReader instance
      reader.readAsDataURL(file); // Read the contents of the selected file
      reader.onload = () => {
        // Set up a callback for when the file has been read
        this.imageSrc = reader.result; // Set the image source to the read result
        this.imageLoaded = true; // Set the imageLoaded flag to true
      };
    },
    cropImage() {
      if (this.$refs.cropper) {
        // Check if the cropper instance exists
        const croppedCanvas = this.$refs.cropper.getCroppedCanvas(); // Get the cropped canvas from the cropper
        croppedCanvas.toBlob((blob) => {
          // Convert the cropped canvas to a Blob
          this.$emit("imageBlob", blob); // Emit the cropped image Blob using a custom event
          this.$refs.cropper.destroy(); // Destroy the cropper instance
          this.cancelCrop(); // Call the cancelCrop method to reset the state
        });
      }
    },
    cancelCrop() {
      this.imageSrc = ""; // Reset the image source
      this.imageLoaded = false; // Reset the imageLoaded flag
      $("#exampleModal1").modal("toggle"); // Toggle the modal with ID "exampleModal1"
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.modal-footer {
  justify-content: center;
  button {
    color: white;
    background: #008d40;
    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      border-color: #05b050 !important;
      box-shadow: 0 0 !important;
      background: #05b050 !important;
    }
  }
}
// label
div.mb-3 label {
  margin-bottom: 0px;
  @include textStyles(Poppins, 300, 14px, 166.67%);
  color: rgba(45, 55, 72, 0.8);
}
// input, select and textarea states
select,
input {
  font-size: 14px;
  color: #000000;

  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    border-color: #05b050 !important;
    box-shadow: 0 0 !important;
  }
}
#required {
  color: red;
  font-family: "Maven Pro";
  font-weight: bolder;
  font-size: 20px;
}

.profile-picture-cropper {
  text-align: center;
  margin-top: 1rem;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

button {
  margin: 0 0.5rem;
  padding: 0.5rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #eee;
  cursor: pointer;
}

button:hover {
  background-color: #ddd;
}
</style>
