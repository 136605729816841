<template>
  <DefaultNav>
    <div class="big-container">
      <div v-if="mainTab == 1" class="contents">
        <div class="top-left">
          <div
            v-if="wallet"
            id="first"
            :class="['virtual-wallet', walletType == 1 ? 'active-wallet' : '']"
          >
            <div class="cover-div" @click="changeWallet($event, 1)"></div>
            <div class="go-row">
              <input id="checked" type="radio" name="wallet" />
              <h3>My Account</h3>
            </div>
            <h1><span>Balance</span> NGN {{ wallet.balance }}</h1>
            <div class="buttons-area">
              <a class="withdraw-btn" href="/dashboard/withdrawal">Withdraw</a>
            </div>
          </div>
          <div
            v-if="vfdWallet"
            :class="['vfd-wallet', walletType == 2 ? 'active-wallet' : '']"
          >
            <div class="cover-div" @click="changeWallet($event, 2)"></div>
            <div class="go-row">
              <input type="radio" name="wallet" />
              <h3>Vfd Account</h3>
            </div>
            <h1>
              <span>Balance</span> NGN {{ formatAmount(vfdWallet.balance) }}
            </h1>
            <h1><span>Account Number</span> {{ vfdWallet.account_number }}</h1>
          </div>
          <div v-if="!vfdWallet && !isLoading" class="vfd-wallet">
            <div class="cover-div"></div>
            <div class="go-row">
              <h3>Vfd Account</h3>
            </div>
            <a
              href="javascript:void(0)"
              class="create"
              @click="createVWallet()"
            >
              Create VFD Account
            </a>
          </div>
          <div
            v-if="merchantFinance && userData.user.type == 'merchant'"
            id="first"
            :class="['vfd-wallet', walletType == 3 ? 'active-wallet' : '']"
          >
            <div class="cover-div" @click="changeWallet($event, 3)"></div>
            <div class="go-row">
              <input id="checked" type="radio" name="wallet" />
              <h3>Loan Account</h3>
            </div>
            <h1>
              <span>Balance</span> NGN
              {{ formatAmount(merchantFinance.balance) }}
            </h1>
            <a
              class="createloan"
              href="/dashboard/loan/loanrequest"
              @click="createVWallet('merchant')"
              >Request Loan</a
            >
          </div>
          <div
            v-if="!merchantFinance && userData.user.type == 'merchant'"
            id="first"
            :class="[
              'loan-wallet',
              walletType == 3 && !merchantFinance ? 'active-wallet2' : '',
            ]"
          >
            <div class="cover-div" @click="changeWallet($event, 3)"></div>
            <div class="go-row">
              <input id="checked" type="radio" name="wallet" />
              <h3>Loan Account</h3>
            </div>
            <p>You do not have a loan account</p>
            <div class="buttons-area">
              <a
                class="withdraw-btn"
                href="javascript:void(0)"
                @click="createVWallet('merchant')"
              >
                Create Account</a
              >
            </div>
          </div>
        </div>
        <div v-if="walletType == 1" class="virtual-details">
          <div class="tabs-section">
            <button
              :class="['tabs-button', tab == 1 ? 'active' : '']"
              @click="changeTab(1)"
            >
              Recent Transactions
            </button>
            <button
              :class="['tabs-button', tab == 2 ? 'active' : '']"
              @click="changeTab(2)"
            >
              Withdrawal History
            </button>
          </div>
          <div v-if="tab == 1" class="table-wrapper">
            <table class="table-borderless sub-history">
              <tbody class="scrollable">
                <tr>
                  <th>Transaction ID</th>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Amount</th>
                </tr>
                <tr v-for="transaction in transactions" :key="transaction.id">
                  <td>{{ transaction.transaction_id }}</td>
                  <td>{{ transaction.type }}</td>
                  <td>{{ transaction.created_at }}</td>
                  <th>NGN {{ transaction.amount_paid }}</th>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="tab == 2" class="table-wrapper">
            <table class="table-borderless sub-history">
              <tbody class="scrollable">
                <tr>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Status</th>
                </tr>
                <tr v-for="history in withdrawalHistory" :key="history.id">
                  <td>{{ history.created_at }}</td>
                  <th>{{ history.amount }}</th>
                  <td>{{ history.status }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="walletType == 2" class="vfd-details">
          <div v-if="vTab !== 4" class="tabs-section">
            <button
              id="account-summary"
              :class="[vTab == 1 ? 'active' : '']"
              @click="activetab(1)"
            >
              Account Summary
            </button>
            <button
              id="account-details"
              :class="[vTab == 2 ? 'active' : '']"
              @click="activetab(2)"
            >
              Account Details
            </button>
            <button
              id="transfer"
              :class="[vTab == 3 ? 'active' : '']"
              @click="activetab(3)"
            >
              Transfer
            </button>
          </div>
          <div v-if="vTab == 1" class="table-wrapper">
            <table class="table-borderless sub-history">
              <tbody class="scrollable">
                <tr>
                  <td></td>
                  <th>Transaction ID</th>
                  <th>From</th>
                  <th>Date</th>
                  <th>Amount</th>
                </tr>

                <tr v-for="transaction in transactions" :key="transaction.id">
                  <td>
                    <!-- <img src="@/assets/images/vectors/receive-arrow.svg" alt="" /> -->
                  </td>
                  <td>{{ transaction.transaction_id }}</td>
                  <td>Zowasel Loan Service</td>
                  <td>{{ transaction.created_at }}</td>
                  <th>NGN {{ transaction.amount_paid }}</th>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="vTab == 2" class="account-details">
            <div class="top-row">
              <div class="each-card">
                <h4>Account Name</h4>
                <p>
                  {{ userData.user.first_name + " " + userData.user.last_name }}
                </p>
              </div>
              <div class="each-card">
                <h4>Account Number</h4>
                <p>{{ vfdWallet.account_number }}</p>
              </div>
              <div class="each-card">
                <h4>Date Of Creation</h4>
                <p>{{ formatDate(vfdWallet.created_at) }}</p>
              </div>
              <div class="each-card no-border">
                <h4>Transactions</h4>
                <p>0</p>
              </div>
            </div>
            <div class="bottom-row">
              <div class="each-card">
                <h4>Total Deposits</h4>
                <p>NGN 0.00</p>
              </div>
              <div class="each-card inflow">
                <h4>Inflow</h4>
                <p>NGN 0.00</p>
              </div>
              <div class="each-card outflow">
                <h4>Outflow</h4>
                <p>NGN 0.00</p>
              </div>
              <div class="each-card no-border"></div>
            </div>
          </div>

          <div v-if="vTab == 3" class="transfer">
            <form @submit.prevent="switchOTP()">
              <div class="row">
                <div class="form-group col">
                  <label for="inputState">Select Bank</label>
                  <select
                    id="inputState"
                    v-model="bankCode"
                    class="form-control"
                    required
                    @change="handleBeneficiary"
                  >
                    <option value="" selected>Choose...</option>
                    <option
                      v-for="(bank, index) in banks"
                      :key="index"
                      :value="bank.code"
                    >
                      {{ bank.name }}
                    </option>
                  </select>
                </div>
                <div class="form-group col">
                  <label for="inputPassword4">Account Number</label>
                  <input
                    id="inputPassword4"
                    v-model="accountNumber"
                    type="text"
                    class="form-control"
                    placeholder="2445 7265 73"
                    required
                    @keyup="handleBeneficiary"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="inputAddress">Amount</label>
                <input
                  id="inputAddress"
                  v-model="amount"
                  type="text"
                  class="form-control"
                  placeholder="Enter Amount"
                  required
                />
              </div>
              <div class="form-group">
                <label for="inputAddress2">Account Name</label>
                <input
                  id="inputAddress2"
                  v-model="beneficiaryName"
                  type="text"
                  class="form-control"
                  placeholder=""
                  disabled
                />
              </div>
              <button
                id="transferBtn"
                type="submit"
                :disabled="!beneficiary || !amount"
                class="transfer-btn"
              >
                Transfer
              </button>
            </form>
          </div>

          <div v-if="vTab == 4" class="table-wrapper">
            <table class="table-borderless sub-history">
              <tbody class="scrollable">
                <tr>
                  <th>Transaction ID</th>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Amount</th>
                </tr>
              </tbody>
            </table>
            <div class="center-tab">
              <h2 class="text-center">Please verify BVN to activate wallet</h2>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Verify BVN
              </button>
            </div>
          </div>
        </div>
        <div v-if="walletType == 3" class="vfd-details">
          <div v-if="loanTab !== 4" class="tabs-section">
            <button
              id="account-summary"
              :class="[loanTab == 1 ? 'active' : '']"
              @click="loanTab = 1"
            >
              Account Summary
            </button>
            <button
              id="account-details"
              :class="[loanTab == 2 ? 'active' : '']"
              @click="loanTab = 2"
            >
              Account Details
            </button>
            <button
              id="transfer"
              :class="[loanTab == 3 ? 'active' : '']"
              @click="loanTab = 3"
            >
              Transfer
            </button>
          </div>
          <div v-if="loanTab == 1" class="table-wrapper">
            <table class="table-borderless sub-history">
              <tbody class="scrollable">
                <tr>
                  <td></td>
                  <th>Transaction ID</th>
                  <th>From</th>
                  <th>Date</th>
                  <th>Amount</th>
                </tr>

                <tr v-for="transaction in transactions" :key="transaction.id">
                  <td>
                    <!-- <img src="@/assets/images/vectors/receive-arrow.svg" alt="" /> -->
                  </td>
                  <td>{{ transaction.transaction_id }}</td>
                  <td>Zowasel Loan Service</td>
                  <td>{{ transaction.created_at }}</td>
                  <th>NGN {{ transaction.amount_paid }}</th>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="loanTab == 2" class="account-details">
            <div class="top-row">
              <div class="each-card">
                <h4>Account Name</h4>
                <p>
                  {{ userData.user.first_name + " " + userData.user.last_name }}
                </p>
              </div>
              <div class="each-card">
                <h4>Account Number</h4>
                <p>{{ merchantFinance.account_number }}</p>
              </div>
              <div class="each-card">
                <h4>Date Of Creation</h4>
                <p>{{ formatDate(merchantFinance.created_at) }}</p>
              </div>
              <div class="each-card no-border">
                <h4>Transactions</h4>
                <p>0</p>
              </div>
            </div>
            <div v-if="false" class="bottom-row">
              <div class="each-card">
                <h4>Total Deposits</h4>
                <p>NGN 0.00</p>
              </div>
              <div class="each-card inflow">
                <h4>Inflow</h4>
                <p>NGN 0.00</p>
              </div>
              <div class="each-card outflow">
                <h4>Outflow</h4>
                <p>NGN 0.00</p>
              </div>
              <div class="each-card no-border"></div>
            </div>
          </div>

          <div v-if="loanTab == 3" class="transfer">
            <form @submit.prevent="switchOTP()">
              <div class="row">
                <div class="form-group col">
                  <label for="inputState">Select Bank</label>
                  <select
                    id="inputState"
                    v-model="bankCode"
                    class="form-control"
                    required
                    @change="handleBeneficiary"
                  >
                    <option value="" selected>Choose...</option>
                    <option
                      v-for="(bank, index) in banks"
                      :key="index"
                      :value="bank.code"
                    >
                      {{ bank.name }}
                    </option>
                  </select>
                </div>
                <div class="form-group col">
                  <label for="inputPassword4">Account Number</label>
                  <input
                    id="inputPassword4"
                    v-model="accountNumber"
                    type="text"
                    class="form-control"
                    placeholder="2445 7265 73"
                    required
                    @keyup="handleBeneficiary"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="inputAddress">Amount</label>
                <input
                  id="inputAddress"
                  v-model="amount"
                  type="text"
                  class="form-control"
                  placeholder="Enter Amount"
                  required
                />
              </div>
              <div class="form-group">
                <label for="inputAddress2">Account Name</label>
                <input
                  id="inputAddress2"
                  v-model="beneficiaryName"
                  type="text"
                  class="form-control"
                  placeholder=""
                  disabled
                />
              </div>
              <button
                id="transferBtn"
                type="submit"
                :disabled="!beneficiary || !amount"
                class="transfer-btn"
              >
                Transfer
              </button>
            </form>
          </div>

          <div v-if="loanTab == 4" class="table-wrapper">
            <table class="table-borderless sub-history">
              <tbody class="scrollable">
                <tr>
                  <th>Transaction ID</th>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Amount</th>
                </tr>
              </tbody>
            </table>
            <div class="center-tab">
              <h2 class="text-center">Please verify BVN to activate wallet</h2>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Verify BVN
              </button>
            </div>
          </div>
        </div>
      </div>
      <TransferOTP
        v-if="mainTab == 2"
        :transfer-to-account="transferToAccount"
      ></TransferOTP>
    </div>
    <!-- Modal -->
    <div
      id="exampleModal"
      class="modal fade"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">Verify BVN</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form @submit.prevent="proceed">
            <div class="modal-body">
              <input
                v-model="bvn"
                type="text"
                placeholder="Enter BVN to verify"
                class="form-control"
                required
              />
            </div>
            <div class="modal-footer">
              <button
                id="closeModal"
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                @click="releaseWallet()"
              >
                Verify
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import MarketplaceService from "@/services/marketplace";
import TransferOTP from "@/pages/dashboard/components/TransferOTP.vue";
import WalletService from "@/services/wallet";
import Alert from "@/utilities/alert";
import Preloader from "@/layouts/shared/Preloader.vue";
import AlertBox from "@/components/AlertBox.vue";

export default {
  name: "Wallet",
  components: {
    DefaultNav,
    Preloader,
    TransferOTP,
  },
  data() {
    return {
      isLoading: false,
      userData: this.$store.state.user,
      wallet: null,
      transactions: [],
      withdrawalHistory: [],
      tab: 1,
      vTab: 1,
      loanTab: 1,
      walletType: 1,
      banks: [],
      bankCode: "",
      accountNumber: "",
      amount: null,
      vfdWallet: null,
      beneficiaryName: "Beneficiary Name..",
      bvn: "",
      mainTab: 1,
      beneficiary: false,
      merchantFinance: null,
    };
  },
  mounted() {
    this.getWalletBalance();
    this.getWalletTransactions();
    this.getWithdrawalHistory();
    this.getVWallet();
    this.getMerchantFinanceWallet();
    this.getBanks();
    this.setupSocket();
    // document.getElementById("checked").checked = true;
  },

  methods: {
    formatAmount(amount) {
      return eval(amount).toFixed(2);
    },
    switchOTP() {
      this.mainTab = 2;
      MarketplaceService.getWithdrawalOtp((response) => {
        if (response && response.error == false) {
          Alert.success({
            message: "OTP sent successfully",
          });
          // document.getElementById("otp").innerHTML = "Resend Otp";
        }
      });
    },
    getWithdrawalOtp() {},
    getWalletBalance(id) {
      WalletService.getWalletBalance(this.userData.user_id, (response) => {
        if (response && response.error == false) {
          this.wallet = response.data;
        }
      });
    },
    getWalletTransactions(id) {
      WalletService.getWalletTransactions(this.userData.user_id, (response) => {
        if (response && response.error == false) {
          this.transactions = response.data;
        }
      });
    },
    getWithdrawalHistory(id) {
      WalletService.getWithdrawalHistory(this.userData.user_id, (response) => {
        if (response && response.error == false) {
          this.withdrawalHistory = response.data;
        }
      });
    },
    getVWallet() {
      let vm = this;
      this.isLoading = true;
      WalletService.getVwallet((response) => {
        vm.isLoading = false;
        if (response && response.data) {
          vm.vfdWallet = response.data;
          if (response.data.pnd_status) {
            vm.vTab = 4;
          }
        }
      });
    },
    getMerchantFinanceWallet() {
      let vm = this;
      this.isLoading = true;
      WalletService.getMerchantFinanceWallet((response) => {
        vm.isLoading = false;
        if (response && response.data) {
          vm.merchantFinance = response.data;
          if (response.data.pnd_status) {
            vm.loanTab = 4;
          }
        }
      });
    },
    getBanks() {
      let vm = this;
      WalletService.getBanksList((response) => {
        if (response && response.data) {
          vm.banks = response.data.bank;
        }
      });
    },
    createVWallet(type = null) {
      let vm = this;
      WalletService.createVwallet(type, (response) => {
        // {
        //     "error": false,
        //     "message": "Wallet created successfully",
        //     "data": {
        //         "firstname": "zowasel-od-Stephen",
        //         "middlename": "",
        //         "lastname": "Mark",
        //         "bvn": "22222222255",
        //         "phone": "09098766789",
        //         "dob": "02-Dec-1992",
        //         "accountNo": "1001595370"
        //     }
        // }
        if (!response.error) {
          this.getVWallet();
          Alert.success({
            message: "Wallet created Successfully",
          });
        } else
          Alert.error({
            message: response.message,
          });
        // console.log(response);
      });
    },
    transferToAccount() {
      let vm = this;
      this.isLoading = true;
      WalletService.vwalletTransfer(
        {
          user_type: "regular",
          account: this.accountNumber,
          amount: this.amount,
          bank: this.bankCode,
        },
        (response) => {
          vm.isLoading = false;
          if (!response.error) {
            this.mainTab = 1;
            Alert.success({
              message: "Transfer Successful",
            });
            vm.getVWallet();
          } else {
            Alert.error({
              message: "An error occured",
            });
          }
        },
      );
    },
    getBeneficiary() {
      let vm = this;
      this.beneficiary = false;
      WalletService.getBeneficiary(
        {
          account_number: this.accountNumber,
          bank_code: this.bankCode,
        },
        (response) => {
          if (response && response.data) {
            this.beneficiaryName = response.data.name;
            this.beneficiary = true;
          }
        },
      );
    },
    handleBeneficiary() {
      if (
        this.accounNumber != "" &&
        this.accountNumber.length == 10 &&
        this.bankCode != ""
      ) {
        this.getBeneficiary();
      }
    },
    changeTab(tab) {
      this.tab = tab;
    },
    activetab(vTab) {
      this.vTab = vTab;
    },
    changeWallet(e, walletType) {
      var selectedWallet = e.target;
      var inpBox = selectedWallet.nextElementSibling;
      var inp = inpBox.firstChild;
      inp.checked = true;
      this.walletType = walletType;
    },
    formatDate(date) {
      var dateObj = new Date(date);
      var dateString = dateObj.toDateString();
      return dateString.split(" ").slice(1).join(" ");
    },
    checkReleaseStatus() {
      this.isLoading = true;
      WalletService.releaseVWallet(
        {
          account: this.vfdWallet.account_number,
          bvn: this.bvn,
        },
        (response) => {
          if (response.error) {
            Alert.error({
              message: "Consent not given",
            });
          } else {
            Alert.success({
              message: "Consent given",
            });
            this.vfdWallet.pnd_status = 0;
            this.vTab = 1;
          }
          this.isLoading = false;
        },
      );
    },

    releaseWallet() {
      this.isLoading = true;
      WalletService.releaseVWallet(
        {
          account:
            this.walletType == 2
              ? this.vfdWallet.account_number
              : this.merchantFinance.account_number,
          bvn: this.bvn,
        },
        (response) => {
          $("#exampleModal").modal("hide");
          if (response.error) {
            WalletService.consentVWallet(
              {
                bvn: this.bvn,
              },
              (response) => {
                const link = response.data.url;
                if (link) {
                  window.open(link, "_blank");
                  this.isLoading = false;
                  $("#exampleModal").modal("hide");
                } else {
                  this.isLoading = false;
                  $("#exampleModal").modal("hide");
                  Alert.error({
                    message: "Service temporarily not available, try again.",
                  });
                }
              },
            );
          } else {
            this.vfdWallet.pnd_status = 0;
            this.vTab = 1;
            this.isLoading = false;
          }
        },
      );
    },
    setupSocket() {
      let vm = this;
      // Listen for Igree consent notification
      window.AppSocket.on("Igree.Consent", (data) => {
        if (
          data &&
          data.type == "regular" &&
          vm.vfdWallet.account_number == data.number
        ) {
          Alert.success({
            message: "Consent received and account activated",
          });
          vm.vfdWallet.pnd_status = 0;
          vm.vTab = 1;
        }
      });
      //---------------------------------------
      // Listen for Igree consent notification
      window.AppSocket.on("InwardCredit", (data) => {
        if (
          data &&
          data.type == "regular" &&
          vm.vfdWallet.account_number == data.number
        ) {
          Alert.success({
            message: `You just received N${data.credit.amount} in your account : ${data.number}`,
          });
        }
      });
      //---------------------------------------
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/shared/Styles";
.active {
  background: rgba(118, 118, 128, 0.12);
  border-radius: 8.91px;
  color: white;
}

.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: row;
  padding: 2% 5% 2% 3%;
  gap: 30px;
  overflow-y: scroll;
  padding-bottom: 40px;
}

.contents {
  width: 100%;
  margin-top: $default-margin * 1.5;

  .top-left {
    display: flex;
    gap: 25px;
    margin-bottom: $default-margin * 4;

    .virtual-wallet {
      background: #282b3d;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 30px 25px 20px 25px;
      border-radius: 10px;
      width: 33%;
      height: 300px;
      cursor: pointer;
      position: relative;

      .cover-div {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
      }

      h3 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 135%;
      }
      h1 {
        font-family: "Maven Pro";
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 132%;
        margin-left: 30px;
      }
      span {
        font-size: 16px;
      }
      .buttons-area {
        display: flex;
        justify-content: flex-end;
        position: relative;
      }

      .withdraw-btn {
        width: 150px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #c32021;
        border-radius: 5px;
        font-family: "Maven Pro";
        font-style: normal;
        font-weight: 700;
        font-size: 20.6667px;
        color: white;
        text-decoration: none;
        opacity: 0.5;
      }
    }
    .virtual-wallet:hover {
      transform: scale(1.05);
    }
    .loan-wallet {
      background: #a7a7a7;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 30px 25px 20px 25px;
      border-radius: 10px;
      width: 33%;
      height: 300px;
      cursor: pointer;
      position: relative;

      .cover-div {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
      }

      h3 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 135%;
      }
      h1 {
        font-family: "Maven Pro";
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 132%;
        margin-left: 30px;
      }
      span {
        font-size: 16px;
      }
      .buttons-area {
        position: relative;
      }

      .withdraw-btn {
        width: 230px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #05b050;
        border-radius: 5px;
        font-family: "Maven Pro";
        font-style: normal;
        font-weight: 700;
        font-size: 20.6667px;
        color: white;
        text-decoration: none;
        opacity: 0.5;
        z-index: 10000;
      }
    }
    .loan-wallet:hover {
      transform: scale(1.05);
    }
    .vfd-wallet {
      background: #282b3d;
      border-radius: 10px;
      padding: 30px;
      width: 33%;
      height: 300px;
      cursor: pointer;
      position: relative;

      .create {
        padding: 10px;
        background: #05b050;
        position: relative;
        border-radius: 10px;
        margin-top: 30px;
      }

      .cover-div {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
      }

      h3 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 135%;
      }
      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
      }

      h1 {
        font-family: "Maven Pro";
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 132%;
        margin-left: 30px;
      }
      span {
        font-size: 16px;
      }
    }
    .vfd-wallet:hover {
      transform: scale(1.05);
    }
  }
}
.active-wallet {
  background-image: url("@/assets/images/backgrounds/card.svg") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  color: white !important;
  width: 50% !important;

  .withdraw-btn {
    opacity: 1 !important;
  }
}
.active-wallet2 {
  background-image: gray;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  color: white !important;
  width: 50% !important;

  .withdraw-btn {
    opacity: 1 !important;
  }
}
.right-side {
  width: 35%;
  background: $zowasel-white;
  padding: $default-padding * 2;
  margin-top: $default-margin * 1.5;

  h3 {
    margin-top: 30px;
  }
}

.table-wrapper {
  background: $zowasel-white;
  padding: $default-padding * 2;
  margin-top: $default-padding;
}
.sub-history {
  width: 100%;

  h2 {
    margin-bottom: 40px;
  }

  tr {
    padding: 20px 9px;
    gap: 129px;
    height: 66px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  td {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4754;
  }
  .amount {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #4a4754;
  }
}

.chart-section {
  width: 100%;
  padding: 20px;
  background-color: white;

  img {
    width: 100%;
  }

  .texts {
    display: flex;
    gap: 30px;

    .income-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #41be53;
    }

    .expense-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #ffd16a;
    }

    .income,
    .expense {
      display: flex;
      align-items: center;
      gap: 5px;

      p {
        margin: 0px;
      }
    }
  }
}

.tabs-section {
  gap: -10px;
}
.tabs-section button {
  background: rgba(118, 118, 128, 0.12);
  border-radius: 9px;
  height: 50px;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding: 0px 10px;
  color: #262c3f;
  position: relative;
}
.active {
  background: #262c3f !important;
  color: white !important;
  z-index: 10;
}
.flex-row {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-left: 30px;
}

.table-wrapper {
  background: $zowasel-white;
  padding: $default-padding * 2;
  margin-top: $default-padding;
}
.sub-history {
  width: 100%;

  h2 {
    margin-bottom: 40px;
  }

  tr {
    padding: 20px 9px;
    gap: 129px;
    height: 66px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  td {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4754;
  }
  .amount {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #4a4754;
  }
}

.chart-section {
  width: 100%;
  padding: 20px;
  background-color: white;

  img {
    width: 100%;
  }

  .texts {
    display: flex;
    gap: 30px;

    .income-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #41be53;
    }

    .expense-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #ffd16a;
    }

    .income,
    .expense {
      display: flex;
      align-items: center;
      gap: 5px;

      p {
        margin: 0px;
      }
    }
  }
}

h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}
.tabs-section {
  gap: -10px;
  margin-bottom: 30px;
}
.tabs-section button {
  width: 200px;
  background: rgba(118, 118, 128, 0.12);
  border-radius: 9px;
  height: 50px;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding: 0px 10px;
  color: #262c3f;
  position: relative;
}
.active {
  background: #262c3f !important;
  color: white !important;
  z-index: 10;
}

.account-details {
  width: 100%;
  background: #ffffff;
  box-shadow:
    0px 2px 4px rgba(44, 39, 56, 0.08),
    0px 4px 8px rgba(44, 39, 56, 0.08);
  border-radius: 10px;
  padding: 35px;

  .top-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 2px dashed rgba(0, 0, 0, 0.21);

    .each-card {
      width: 25%;
      border-right: 2px dashed rgba(0, 0, 0, 0.21);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 50px;

      h4 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 116%;
        color: #696671;
      }

      p {
        font-family: "Maven Pro";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 147%;
        color: #4a4754;
      }
    }
  }

  .bottom-row {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .each-card {
      width: 25%;
      border-right: 2px dashed rgba(0, 0, 0, 0.21);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 50px;

      h4 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 116%;
        color: #696671;
      }

      p {
        font-family: "Maven Pro";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 147%;
        color: #4a4754;
      }
    }
  }
  .inflow {
    h4 {
      color: #036b31 !important;
    }
    p {
      color: #036b31 !important;
    }
  }
  .outflow {
    h4 {
      color: #891617 !important;
    }
    p {
      color: #891617 !important;
    }
  }

  .no-border {
    border-right: unset !important;
  }
}

.transfer {
  form {
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    padding: 50px;

    button {
      background: #05b050;
      border-radius: 4px;
      width: none;
      border: none;
      width: 100%;
      height: 50px;
      margin-top: 40px;
      color: white;
    }
  }
}
input[type="radio"]:after {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 3px solid white;
}

input[type="radio"]:checked:after {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: relative;
  background-color: #05b050;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 3px solid white;
}
.go-row {
  display: flex;
  gap: 15px;
  align-items: center;

  h3 {
    color: white;
    margin-top: 25px;
  }
}
.center-tab {
  display: flex;
  flex-direction: column;
  margin: 100px 0px;
  align-items: center;

  h2 {
    color: #4a4754;
    font-family: Maven Pro;
    font-size: 36px;
    font-weight: 600;
  }

  button {
    width: 150px;
    height: 48px;
    background: rgba(251, 138, 46, 1);
    color: white;
    border: none;
    border-radius: 15px;
    margin-top: 30px;
  }
}
#transferBtn:disabled {
  background: #e5e5e5;
  color: rgb(70, 70, 70);
}
.createloan {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1000;
  width: 150px !important;
  height: 50px !important;
  background-color: white;
  border: 1px solid #05b050;
  border-radius: 7px;
  color: #05b050;
}
.createloan:hover {
  color: black;
}
</style>
