<template>
  <DefaultNav>
    <div class="big-container">
      <div v-if="product" class="big-content">
        <div v-if="product" class="topic">
          <div class="left">
            <div class="go-row">
              <a href="#"
                ><img
                  src="@/assets/images/vectors/arrowleft.png"
                  alt=""
                  @click="$router.back()"
              /></a>
              <h1>
                {{ product.subcategory.name }}
              </h1>
            </div>
            <p class="price">Quantity: {{ product.stock }}</p>
            <p class="price">
              {{ product.currency }}{{ product.price }}/{{ product.packaging }}
            </p>
            <!-- <p class="farmer">
              {{ product.user.first_name + " " + product.user.last_name }}
              <span>
                <img src="@/assets/images/vectors/verified.svg" alt="" />
                Verified</span
              >
            </p> -->
            <div class="description">
              <h3>Description</h3>
              <p v-html="product.description"></p>
            </div>
          </div>
          <div v-if="product.images.length >= 1" class="small-images">
            <img
              v-for="(image, index) in product.images"
              :key="index"
              :src="image"
              alt=""
            />
          </div>
          <div class="right">
            <!-- for bid -->
            <!-- <div v-if="pr<a
              v-if="productType == 'auction'"
              href="javascript:void()"
              type="button"
              data-bs-target="#exampleModal"
              class="fulfil"
              @click="bid($route.params.id)"
              >View Bids</a
            >oduct && product.images.length" class="image-area">
              <div>
                <img
                  :src="appConfig.PUBLIC_URL + product.images[0]"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div v-if="product.images.length > 1" class="small-images">
                <img
                  v-for="(image, index) in product.images.slice(1)"
                  :key="index"
                  :src="image"
                  alt=""
                />
              </div>
            </div> -->
          </div>
        </div>

        <hr />
        <div class="details">
          <div class="actual-details">
            <div class="left-line">
              <div class="each-detail">
                <h4>Product Category</h4>
                <p>{{ product.category.name }}</p>
              </div>
              <div class="each-detail">
                <h4>Crop Focus</h4>
                <p>{{ product.crop_focus }}</p>
              </div>
              <div class="each-detail">
                <h4>Manufacture Date</h4>
                <p>{{ product.manufacture_date }}</p>
              </div>
              <div class="each-detail">
                <h4>Expiry Date</h4>
                <p>{{ product.expiry_date }}</p>
              </div>
              <div class="each-detail">
                <h4>Manufacture Name</h4>
                <p>{{ product.manufacture_name }}</p>
              </div>
              <div class="each-detail">
                <h4>Manufacture Country</h4>
                <p>{{ product.manufacture_country }}</p>
              </div>
              <div class="each-detail">
                <h4>Usage Instruction</h4>
                <p>{{ product.usage_instruction }}</p>
              </div>
            </div>
            <div class="right-line">
              <div class="each-detail">
                <h4>Product Type</h4>
                <p>{{ product.product_type }}</p>
              </div>
              <div class="each-detail">
                <h4>Delivery Method</h4>
                <p>{{ product.delivery_method }}</p>
              </div>
            </div>
          </div>
          <div class="map">
            <h2>Location</h2>
            <!--Google map-->
            <div
              id="map-container-google-2"
              class="z-depth-1-half map-container"
            >
              <iframe
                class="main-map"
                src="https://maps.google.com/maps?q=chicago&t=&z=13&ie=UTF8&iwloc=&output=embed"
                frameborder="0"
                style="border: 0"
                allowfullscreen
              />
            </div>

            <!--Google Maps-->
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import Preloader from "@/layouts/shared/Preloader.vue";
import MarketPlaceService from "@/services/marketplace";
export default {
  name: "ViewInput",
  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      product: null,
      quantity: "",
      productType: "",
    };
  },

  mounted() {
    this.getProduct();
  },
  methods: {
    getProduct() {
      MarketPlaceService.getInputById(this.$route.params.id, (response) => {
        this.product = response.data;
        console.log(this.product);
        this.product.images = JSON.parse(this.product.images);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.big-content {
  width: 100%;
  padding: 100px;
}

.image-area {
  width: 80%;

  img {
    width: 100%;
  }

  .big-img {
    width: 80%;
    margin-left: 10%;
  }

  .small-images {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    overflow-x: scroll;

    img {
      width: 350px;
      height: 350px;
    }
  }
}

.topic {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;

  .left {
    h1 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      color: #4a4754;
    }

    .price {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #14110c;
      margin-top: 12px;
    }

    .farmer {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.01em;
      color: #14110c;
      margin-top: 20px;

      span {
        font-family: "Maven Pro";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #4a4754;
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 50%;

    .negotiate {
      background: white;
      box-shadow:
        0px 2px 4px rgba(44, 39, 56, 0.08),
        0px 4px 8px rgba(44, 39, 56, 0.08);
      border-radius: 4px;
      height: 50px;
      width: 250px;
      border: none;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      color: #05b050;
      border: 1px solid #05b050;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }
  }
}

.description {
  h3 {
    font-size: 20px;
  }

  margin-top: 40px;
}

.details {
  width: 100%;
  margin-top: 40px;
  display: flex;
}

.actual-details {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .each-detail {
    margin-top: 30px;

    h4 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }
  }
}
.actual-detailss {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;

  .left-lines {
    width: 50%;
  }
  .each-detail {
    margin-top: 30px;

    h4 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }
  }
}

.map {
  padding-left: 100px;
  margin-top: 30px;
  width: 60%;

  h2 {
    font-size: 16px;
  }
}

.map-container-2 {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.map-container-2 iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.main-map {
  width: 100%;
  height: 60vh;
}

.small-images {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow-x: scroll;
  margin-left: 30px;

  img {
    width: 350px;
    height: 350px;
  }
}

.fulfil {
  background: #05b050;
  box-shadow:
    0px 2px 4px rgba(44, 39, 56, 0.08),
    0px 4px 8px rgba(44, 39, 56, 0.08);
  border-radius: 4px;
  height: 50px;
  width: 250px;
  border: none;
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #e6f7ee;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.modal-input {
  width: 100%;
}
.accept-box {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;

  input {
    margin: 0;
  }

  p {
    margin: 0;
  }
}
.go-row {
  display: flex;
  gap: 10px;

  img {
    width: 26px;
  }
}
</style>
