<template>
  <DefaultNav>
    <div class="main-content">
      <div class="right">
        <div
          v-for="(crop, index) in pageList"
          :key="index"
          class="crop_details"
        >
          <span
            class="color_specification"
            :class="[crop.specification.qty < 1 ? 'fad' : '']"
            >{{ crop.subcategory.name }} -
            {{ crop.specification.color }}
            <span
              v-if="crop.specification.qty < 1"
              class="text-danger fw-bold d-block"
            >
              Sold Out
            </span>
          </span>
          <span
            class="delivery_window"
            :class="[crop.specification.qty < 1 ? 'fad' : '']"
            >{{ crop.warehouse_address }}</span
          >
          <span
            class="category"
            :class="[crop.specification.qty < 1 ? 'fad' : '']"
            ><b>{{ crop.category.name }}</b></span
          >
          <span class="user_name">
            <p :class="['m-0', crop.specification.qty < 1 ? 'fad' : '']">
              {{
                userAccount.accountType === "individual"
                  ? product.user.first_name
                  : userAccount.accountType === "company"
                    ? product.user.company.company_name
                    : userAccount.default
              }}
            </p>
          </span>
          <span
            class="price_specification"
            :class="[crop.specification.qty < 1 ? 'fad' : '']"
            ><b
              >{{ crop.currency }} {{ crop.specification.price }}/{{
                crop.specification.test_weight.toUpperCase()
              }}</b
            ></span
          >
          <span
            class="location"
            :class="[crop.specification.qty < 1 ? 'fad' : '']"
            >{{ crop.user.state }}</span
          >
          <span
            :class="[
              'view_crop',
              crop.specification.qty < 1 ? 'bg-danger' : 'green',
            ]"
            @click="
              $router.push({
                name: 'CropDetails',
                params: { id: crop.id },
              })
            "
            >View</span
          >
        </div>
        <!-- Pagination -->
        <nav class="pagination align-items-center gap-2 my-4">
          <button
            :disabled="currentPage === 1"
            class="btn btn-succ fs-small"
            @click="prevPage"
          >
            Previous
          </button>
          <span class="fs-small"
            >Page {{ currentPage }} of {{ totalPages }}</span
          >
          <button
            :disabled="currentPage === totalPages"
            class="btn btn-succ fs-small"
            @click="nextPage"
          >
            Next
          </button>
        </nav>
      </div>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import MarketPlaceService from "@/services/marketplace";
import Preloader from "@/layouts/shared/Preloader.vue";
export default {
  name: "CompanyProduct",
  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      crops: [],
      product: "",
      businessType: "",
      currentPage: 1,
      itemsPerPage: 10, // Number of notifications to show per page
      userAccount: {
        accountType: "",
        default: "N/A",
      },
    };
  },

  computed: {
    pageList() {
      const sortedWanted = this.sortedWantedList();
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return sortedWanted.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.crops.length / this.itemsPerPage);
    },
  },
  created() {
    this.getProduct();
  },

  methods: {
    getProduct() {
      MarketPlaceService.getCropById(this.$route.query.id, (response) => {
        this.product = response.data;
        this.crops = response.data.user.crops; // store all crop for sale and wanted product listings
        this.businessType = response.data.user.type;
        // call the account name function
        this.accountName();
      });
    },
    accountName() {
      // Check if it's an individual account
      if (this.product.user.company == null) {
        this.userAccount.accountType = "individual";
      }

      // Check if it's a company account
      if (this.product.user.company != null) {
        this.userAccount.accountType = "company";
      }
    },
    sortedWantedList() {
      // make a copy of the original wanted array to avoid modifying the original
      return this.crops.slice(0).sort((a, b) => {
        if (a.specification.qty < 1 && b.specification.qty >= 1) {
          return 1; // Move items with qty < 1 to the end
        } else if (a.specification.qty >= 1 && b.specification.qty < 1) {
          return -1; // Move items with qty >= 1 to the beginning
        } else {
          // If both have the same qty or both have qty < 1, maintain their order
          return 0;
        }
      });
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-succ {
  background: #05b050;
  color: white;

  &:hover {
    background: darken(#05b050, 10%);
  }
}

.fs-small {
  font-size: 14px;
}

.fad {
  opacity: 0.5;
}

.main-content {
  background: #f1f1f1;
  width: 100%;
  min-height: 100vh;
  padding: 50px;
}
.crop_details {
  display: flex;
  padding: 10px 25px;
  align-items: center;
  justify-content: space-evenly;
  gap: 26px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
}

.crop_specification,
.delivery_window,
.user_name,
.price_specification,
.category,
.location {
  color: #4a4754;
  font-family: Maven Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 147%;
  text-transform: capitalize;
}

.category,
.location {
  font-weight: 700 !important;
}

.view_crop {
  color: #e6f7ee;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Maven Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  cursor: default;
  width: 100px;
  height: 36px;
  border-radius: 4px;
  background: #05b050;
  box-shadow:
    0px 4px 8px 0px rgba(44, 39, 56, 0.08),
    0px 2px 4px 0px rgba(44, 39, 56, 0.08);
}
.green {
  background: #05b050;
  &:hover {
    background: darken(#05b050, 10%);
  }
}
.bg-danger {
  background: darken(rgb(220, 53, 69), 10%);
}

ul.pagination {
  li {
    a.page-link {
      border: 0px !important;
      color: #4a4754;

      &:not(#carret-icon):hover,
      :active {
        color: #ffffff;
        background: #05b050;
      }

      &:focus {
        box-shadow: 0px 0px !important;
      }

      span {
        color: #05b050;
      }
    }
  }
}

a:hover {
  box-shadow: 2px 2px 5px #888;
  color: white;
  background-color: #05b050;
}
</style>
