<!-- Filter header on temporary  components -->
<template>
  <div class="filter-header px-2">
    <div id="filter-wrapper" class="filter-content-wrapper container">
      <!--  filter header -->
      <div
        class="filter-header-content d-flex position-relative align-items-center"
      >
        <h2 class="filter-header-text-h1">Filter Farmers by</h2>
        <div
          id="carret-icon"
          class="filter-header-icon d-flex justify-content-center align-items-center"
          @click="toggleFilter"
        >
          <span v-if="filter" id="down">&#9662;</span>
          <span v-if="!filter" id="up">&#9652;</span>
        </div>
      </div>
      <hr class="m-0 mb-4" />

      <!-- main Content -->

      <div
        v-if="filter"
        id="main-filter-content"
        class="filter-main-container d-flex flex-column pe-5"
      >
        <div class="top-filter d-flex">
          <div class="filter-item d-flex flex-column row-1">
            <p>Requested Amount</p>
            <div
              class="customRange-conatiner d-flex flex-row justify-content-center align-items-center position-relative mb-2"
            >
              <input
                v-model="min"
                type="range"
                class="form-range min"
                min="0"
                max="50000"
                @input="handleMinRange($event)"
                @change="endRangeMove('min')"
              />
              <input
                v-model="max"
                type="range"
                class="form-range max"
                min="0"
                max="50000"
                @input="handleMaxRange($event)"
                @change="endRangeMove('max')"
              />
              <div
                class="range-thumb min-thumb"
                :style="'left:' + minPosition + '%;'"
                @mouseenter="focusRange('min')"
              ></div>
              <div
                class="range-thumb max-thumb"
                :style="'right:' + maxPosition + '%;'"
                @mouseenter="focusRange('max')"
              ></div>
            </div>
            <div
              class="mt-2 d-flex flex-row justify-content-between align-items-center"
            >
              <div class="form_control_container flex-grow-1">
                <div class="form_control_container__time float-start">Min</div>
                <input
                  id="fromInput"
                  v-model="min"
                  class="form_control_container__time__input"
                  type="number"
                  min="0"
                  :max="max"
                />
              </div>
              <div class="form_control_container flex-grow-1">
                <div class="form_control_container__time float-end">Max</div>
                <input
                  id="toInput"
                  v-model="max"
                  class="form_control_container__time__input"
                  type="number"
                  :min="min"
                  max="50000"
                />
              </div>
            </div>
          </div>
          <div class="filter-item d-flex flex-column row-1">
            <p>Pds Score</p>
            <div
              class="customRange-conatiner d-flex flex-row justify-content-center align-items-center position-relative mb-2"
            >
              <input
                v-model="min"
                type="range"
                class="form-range min"
                min="0"
                max="50000"
                @input="handleMinRange($event)"
                @change="endRangeMove('min')"
              />
              <input
                v-model="max"
                type="range"
                class="form-range max"
                min="0"
                max="50000"
                @input="handleMaxRange($event)"
                @change="endRangeMove('max')"
              />
              <div
                class="range-thumb min-thumb"
                :style="'left:' + minPosition + '%;'"
                @mouseenter="focusRange('min')"
              ></div>
              <div
                class="range-thumb max-thumb"
                :style="'right:' + maxPosition + '%;'"
                @mouseenter="focusRange('max')"
              ></div>
            </div>
            <div
              class="mt-2 d-flex flex-row justify-content-between align-items-center"
            >
              <div class="form_control_container flex-grow-1">
                <div class="form_control_container__time float-start">Min</div>
                <input
                  id="fromInput"
                  v-model="min"
                  class="form_control_container__time__input"
                  type="number"
                  min="0"
                  :max="max"
                />
              </div>
              <div class="form_control_container flex-grow-1">
                <div class="form_control_container__time float-end">Max</div>
                <input
                  id="toInput"
                  v-model="max"
                  class="form_control_container__time__input"
                  type="number"
                  :min="min"
                  max="50000"
                />
              </div>
            </div>
          </div>
          <div class="filter-item d-flex flex-column row-1">
            <p>Dvs Score</p>
            <div
              class="customRange-conatiner d-flex flex-row justify-content-center align-items-center position-relative mb-2"
            >
              <input
                v-model="min"
                type="range"
                class="form-range min"
                min="0"
                max="50000"
                @input="handleMinRange($event)"
                @change="endRangeMove('min')"
              />
              <input
                v-model="max"
                type="range"
                class="form-range max"
                min="0"
                max="50000"
                @input="handleMaxRange($event)"
                @change="endRangeMove('max')"
              />
              <div
                class="range-thumb min-thumb"
                :style="'left:' + minPosition + '%;'"
                @mouseenter="focusRange('min')"
              ></div>
              <div
                class="range-thumb max-thumb"
                :style="'right:' + maxPosition + '%;'"
                @mouseenter="focusRange('max')"
              ></div>
            </div>
            <div
              class="mt-2 d-flex flex-row justify-content-between align-items-center"
            >
              <div class="form_control_container flex-grow-1">
                <div class="form_control_container__time float-start">Min</div>
                <input
                  id="fromInput"
                  v-model="min"
                  class="form_control_container__time__input"
                  type="number"
                  min="0"
                  :max="max"
                />
              </div>
              <div class="form_control_container flex-grow-1">
                <div class="form_control_container__time float-end">Max</div>
                <input
                  id="toInput"
                  v-model="max"
                  class="form_control_container__time__input"
                  type="number"
                  :min="min"
                  max="50000"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="filter-bottom d-flex">
          <!-- row 1 -->
          <div class="filter-item d-flex flex-column row-1">
            <!-- type -->

            <!-- range -->
            <div class="rowz d-flex align-items-center mb-3">
              <div class="ms-3 check-words-price seller-type">Crop Type</div>
            </div>
            <div class="rowz d-flex align-items-center mb-3 position-relative">
              <div class="filter-words d-flex">
                <!-- checkbox component -->
                <Checkbox />
                <div class="ms-3 check-words">Cocoa</div>
              </div>
            </div>
            <div class="rowz d-flex align-items-center mb-3 position-relative">
              <div class="filter-words d-flex">
                <!-- checkbox component -->
                <Checkbox />
                <div class="ms-3 check-words">Maize</div>
              </div>
            </div>
            <div class="rowz d-flex align-items-center mb-3 position-relative">
              <div class="filter-words d-flex">
                <!-- checkbox component -->
                <Checkbox />
                <div class="ms-3 check-words">Corn</div>
              </div>
            </div>
            <div class="rowz d-flex align-items-center mb-3 position-relative">
              <div class="filter-words d-flex">
                <!-- checkbox component -->
                <Checkbox />
                <div class="ms-3 check-words">Other</div>
              </div>
            </div>
            <!-- price -->

            <div class="top-text-right">
              <p>Location</p>
              <select id="" name="">
                <option value="">Kwara</option>
              </select>
            </div>
          </div>
          <!-- row 2 -->
          <div class="filter-item d-flex flex-column row-1">
            <template v-if="marketType.includes('input')">
              <!-- type -->
              <div class="rowz d-flex align-items-center mb-4">
                <h2>Manufacturer</h2>
              </div>
              <div
                class="rowz d-flex align-items-center mb-3 position-relative"
              >
                <div class="filter-words d-flex">
                  <!-- checkbox component -->
                  <Checkbox />
                  <div class="ms-3 check-words">Dangote refinery</div>
                </div>
              </div>
              <div
                class="rowz d-flex align-items-center mb-3 position-relative"
              >
                <div class="filter-words d-flex">
                  <!-- checkbox component -->
                  <Checkbox />
                  <div class="ms-3 check-words">Nasco</div>
                </div>
              </div>
              <div
                class="rowz d-flex align-items-center mb-3 position-relative"
              >
                <div class="filter-words d-flex">
                  <!-- checkbox component -->
                  <Checkbox />
                  <div class="ms-3 check-words">60 Days</div>
                </div>
              </div>
              <div class="rowz d-flex align-items-center position-relative">
                <div class="filter-words d-flex">
                  <!-- checkbox component -->
                  <Checkbox />
                  <div class="ms-3 check-words">Carbury</div>
                </div>
              </div>
            </template>

            <!-- price -->
            <div class="rowz d-flex align-items-center mb-3">
              <div class="ms-3 check-words-price seller-type">Pds Grade</div>
            </div>
            <div class="rowz d-flex align-items-center mb-3 position-relative">
              <div class="filter-words d-flex">
                <!-- checkbox component -->
                <Checkbox />
                <div class="ms-3 check-words">A</div>
              </div>
            </div>
            <div class="rowz d-flex align-items-center mb-3 position-relative">
              <div class="filter-words d-flex">
                <!-- checkbox component -->
                <Checkbox />
                <div class="ms-3 check-words">B</div>
              </div>
            </div>
            <div class="rowz d-flex align-items-center mb-3 position-relative">
              <div class="filter-words d-flex">
                <!-- checkbox component -->
                <Checkbox />
                <div class="ms-3 check-words">C</div>
              </div>
            </div>
            <div class="rowz d-flex align-items-center mb-3 position-relative">
              <div class="filter-words d-flex">
                <!-- checkbox component -->
                <Checkbox />
                <div class="ms-3 check-words">D</div>
              </div>
            </div>
          </div>
          <!-- row 3 -->

          <div class="filter-item d-flex flex-column row-1">
            <!-- type -->
            <div class="rowz d-flex align-items-center mb-3">
              <div class="ms-3 check-words-price seller-type">Dvs Grade</div>
            </div>
            <div class="rowz d-flex align-items-center mb-3 position-relative">
              <div class="filter-words d-flex">
                <!-- checkbox component -->
                <Checkbox />
                <div class="ms-3 check-words">A</div>
              </div>
            </div>
            <div class="rowz d-flex align-items-center mb-3 position-relative">
              <div class="filter-words d-flex">
                <!-- checkbox component -->
                <Checkbox />
                <div class="ms-3 check-words">B</div>
              </div>
            </div>
            <div class="rowz d-flex align-items-center mb-3 position-relative">
              <div class="filter-words d-flex">
                <!-- checkbox component -->
                <Checkbox />
                <div class="ms-3 check-words">C</div>
              </div>
            </div>
            <div class="rowz d-flex align-items-center mb-3 position-relative">
              <div class="filter-words d-flex">
                <!-- checkbox component -->
                <Checkbox />
                <div class="ms-3 check-words">D</div>
              </div>
            </div>
            <button
              type="button"
              class="btn btn-lg mb-4 btn-1 btn-hover-state"
              @click="apply(filters)"
            >
              Apply filters
            </button>
            <button
              type="button"
              class="btn btn-lg mb-4 btn-hover-state"
              @click="reset()"
            >
              Reset filters
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from "@/components/Checkbox.vue";
import MarketplaceService from "@/services/marketplace";
export default {
  components: {
    Checkbox,
  },
  props: {
    marketType: {
      type: String,
      default: "",
    },
    apply: Function,
    reset: Function,
  },
  data() {
    return {
      filter: false,
      min: 0,
      max: 50000,
      categories: [],
      filters: {
        category: "",
        price: {
          min: 0,
          max: 50000,
        },
      },
    };
  },
  computed: {
    minPosition() {
      var percentChange = (this.min / 50000) * 100;
      return percentChange - percentChange * 0.1;
    },
    maxPosition() {
      var percentChange = ((50000 - this.max) / 50000) * 100;
      return percentChange - percentChange * 0.1;
    },
  },
  mounted() {
    if (this.marketType.includes("crop")) {
      this.loadCropCategories();
    } else {
      this.loadInputCategories();
    }
  },
  methods: {
    toggleFilter() {
      this.filter = !this.filter;
      $(document).ready(function () {
        $("#carret-icon").click(function () {
          $(".filter-main-container").css("opacity", 0);
          $(".filter-main-container").animate(
            {
              opacity: 1,
            },
            "slow",
          );
        });
      });
    },
    filterCategory(category, event) {
      this.filters.category = category;
      Array.from(
        document.querySelectorAll(".category-option .checkbox-input"),
      ).forEach((input) => {
        input.checked = false;
      });
      if (event.target.className == "checkbox-input") {
        event.target.checked = true;
      } else {
        event.target.previousElementSibling.checked = true;
      }
    },
    handleMinRange(event) {
      if (event.target.value > this.max - 5000) {
        event.target.value = this.max - 5000;
        this.min = this.max - 5000;
      }
      this.min = event.target.value;
      this.filters.price.min = this.min;
    },
    handleMaxRange(event) {
      if (event.target.value < this.min + 3000) {
        event.target.value = this.min + 3000;
      }
      this.max = event.target.value;
      this.filters.price.max = this.max;
    },
    focusRange(type) {
      if (type == "min") {
        document.querySelector(`.form-range.${type}`).style.zIndex = 3;
        document.querySelector(`.${type}-thumb`).style.pointerEvents = "none";
        document.querySelector(`.max-thumb`).style.pointerEvents = "auto";
        document.querySelector(`.form-range.max`).style.zIndex = 2;
      } else {
        document.querySelector(`.form-range.${type}`).style.zIndex = 3;
        document.querySelector(`.${type}-thumb`).style.pointerEvents = "none";
        document.querySelector(`.min-thumb`).style.pointerEvents = "auto";
        document.querySelector(`.form-range.min`).style.zIndex = 2;
      }
    },
    endRangeMove(type) {
      document.querySelector(`.${type}-thumb`).style.pointerEvents = "auto";
    },
    loadCropCategories() {
      MarketplaceService.getCropCategories((response) => {
        this.categories = response.data;
      });
    },
    loadInputCategories() {
      MarketplaceService.getInputCategories((response) => {
        this.categories = response.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.filter-header {
  background: #ffffff;
  margin-top: 20px;
}

.active {
  #carret-icon span {
    transform: rotate(360deg);
    cursor: default;
  }

  #main-filter-content {
    display: none !important;
  }

  hr {
    display: none !important;
  }
}

.filter-content-wrapper {
  padding: 5px;

  .filter-header-content {
    padding: 10px 0px;
    display: flex;
    align-items: center;
  }

  h2 {
    @include textStyles(Poppins, 700, 16px, 24px);
    letter-spacing: 0.01em;
    color: #4a4754;
    margin-bottom: 0px;
  }

  .filter-header-icon {
    width: 20px;
    height: 20px;
    background: #05b050;
    border-radius: 50%;
    position: absolute;
    right: 5px;
    span {
      padding-top: 0px;
      font-size: 22px;
      display: flex;
      justify-content: center;
      cursor: default;
      align-items: center;
      width: 100%;
      height: 50%;
      color: white;
    }
    #up {
      position: absolute;
      bottom: 7px;
    }
  }

  .filter-main-container {
    margin-left: 15px;
    gap: 8%;
    padding-bottom: 15px;

    .row-1 {
      width: 30%;
    }

    .row-2 {
      width: 30%;
    }

    .row-3 {
      width: 40%;
    }

    .filter-item {
      h2 {
        letter-spacing: 0.01em;
        color: #4a4754;
        @include textStyles(Poppins, 600, 14px, 22px);
      }

      .rowz {
        display: flex !important;

        .filter-words {
          align-items: center;
        }

        .check-words {
          @include textStyles("Maven Pro", 400, 14px, 24px);
          color: #4a4754;
        }

        .check-words-price {
          @include textStyles(Poppins, 600, 14px, 22px);
          letter-spacing: 0.01em;
          color: #4a4754;
        }

        .number-identifier {
          background: #ededee;
          border-radius: 4px;
          padding: 0px 8px;
          width: 70px;
          position: absolute;
          right: 0%;

          span {
            font-size: 14px;
            color: #05b050;
            letter-spacing: 0.04em;
          }
        }

        .form-select {
          background: #f9f9f9 !important;
          border: 1px solid #ededee;
          border-radius: 4px;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;

          color: #4a4754;
        }
      }

      .selct-input {
        border: 1px solid hsl(240, 3%, 93%);
        border-radius: 4px;
        align-items: center;
        padding: 5px 7px;
        gap: 3px;

        select#kilogram {
          width: 45%;
          font-size: 10px;
          border: 0px !important;
          border-radius: 0px !important;
        }

        #one {
          width: 55%;
          border-width: 0px !important;
          border-left-width: 1px !important;
          border-radius: 0px !important;
          margin-top: 0px;
          height: 20px;
        }
      }

      button {
        background: #05b050;
        box-shadow:
          0px 2px 4px rgba(44, 39, 56, 0.08),
          0px 4px 8px rgba(44, 39, 56, 0.08);
        border-radius: 4px;
        color: #ffffff;
        font-size: 14px;
      }
    }
  }

  .form-range.fromSlider::-moz-range-track {
    height: 0rem;
  }

  .form_control_container__time,
  input[type="number"] {
    font-size: 14px;
    color: #8a8383;
  }

  input[type="number"].form_control_container__time__input {
    width: 90%;
    height: 30px;
    border: none;
  }

  .form-range {
    width: 100%;
    margin: 0px;
  }

  input[type="range"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    position: absolute;
    border: none !important;
    padding: 0 !important;
    opacity: 1;
  }

  .range-thumb {
    height: 23px;
    width: 23px;
    border-radius: 15px;
    background-color: #05b050;
    position: absolute;
    border: 1px solid #fff;
    z-index: 3;
  }

  .range-thumb.min-thumb {
    left: 0;
  }

  .range-thumb.max-thumb {
    right: 0;
  }
}
.top-text-right {
  display: flex;
  align-items: center;
  margin-top: 20px;

  p {
    margin-top: 5px;
  }
  select {
    margin-left: 10px;
    margin-top: 0px;
    background-color: white;
    border: 1px solid #05b050 !important;
    height: 50px;
    padding: 0px 0px 0px 20px;
  }
}
.top-filter {
  gap: 30px;
}
.filter-bottom {
  margin-top: 50px;
  gap: 30px;
}
</style>
