import config from "@/config";
import axios from "axios";

const CREATE_FARMER = () => `farmer`;
const CREATE_FARMER_BATCH = () => `farmerbatch`;
const GET_ALL_FARMERS = (start, limit) => `farmerpagination/${limit}/${start}`;
const GET_ALL_MY_FARMERS = (userid) =>
  `farmerfilter/never/never/never/${userid}/never`;
const UPDATE_FARMER = () => `editfarmerbyid`;
const GET_SINGLE_FARMER = (id) => `getbyfarmerid/${id}`;
// const GET_SINGLE_FARMER_BATCH = (id) => ``;
const GET_FARMER_BANK_DATA = (id) => `farmerbank/${id}`;
const UPDATE_FARMER_BANK_DATA = (id) => `farmerbank/${id}`;
const ADD_FARMER_FARM_INFORMATION = () => `farminformation`;
const GET_FARMER_FARM_DATA = (id) => `farminformation/${id}`;
const GET_SINGLE_FARM_INFORMATION = (id) => `farminformation/farmid/${id}`;
const EDIT_FARM_INFORMATION = (id) => `farminformation/${id}`;
const ADD_FARMER_FARM_MECHANIZATION = () => `farmmechanization`;
const GET_FARM_MECHANIZATION_BY_FARMER_ID = (id) =>
  `farmmechanization/farmerid/${id}`;
const GET_SINGLE_FARM_MACHINE = (id) => `farmmechanization/${id}`;
const EDIT_FARM_MACHINE = (id) => `farmmechanization/${id}`;
const ADD_FARM_PRACTICE = () => `farmpractices`;
const GET_FARM_PRACTICE = (id) => `farmpractices/${id}`;
const EDIT_FARM_PRACTICE = (id) => `farmpractices/${id}`;
const ADD_LOAN_HISTORY = () => `loanhistory`;
const GET_SINGLE_HISTORY = (id) => `loanhistory/${id}`;
const UPDATE_LOAN_HISTORY = (id) => `loanhistory/${id}`;
const ADD_FARMER_PITCH = () => `pitch`;
const GET_ALL_PITCHES = () => `pitch`;
const GET_PITCH_BY_FARMER_ID = (id) => `pitch/${id}`;
const UPDATE_FARMER_PITCH = (id) => `pitch/${id}`;
const GET_ALL_FARMER_DATA_COMPLETE = (id) => `farmerfull/${id}`;
// const SCORE_FARMER = () => `creditscore`;
const GET_ALL_CREDIT_SCORES = () => `creditscore`;
const SCORE_MULTIPLE_FARMERS = () => `batchscore`;
/* ------------------------------ new endpoints ----------------------------- */
// const GET_ALL_FARMERS_NEW = () => `getallfarmers`;
/* ----------------------------------- dvs ---------------------------------- */
const GET_DVS_SCORING_DATA = () => "dvs/questions";
const DVS_SCORING_FARMER = () => `dvs/scoredvs`;
const GET_ALL_DVS_SCORES = () => `dvs/scoredvs/getscores`;
const GET_FARMER_CREDIT_SCORE = (id) => `dvs/scoredvs/getrecords/${id}`;

export default {
  /* -------------------------------------------------------------------------- */
  /*                                 FARMER DATA                                */
  /* -------------------------------------------------------------------------- */
  /* ------------------------- create new farmer data ------------------------- */
  createFarmer: function (farmerData, callback) {
    let contentReturned = [];
    axios
      .post(config.CREDITSCORING_URL + CREATE_FARMER(), farmerData)
      .then((response) => {
        contentReturned.push({ error: false });
        contentReturned.push(response.data);
        callback(contentReturned);
      })
      .catch((error) => {
        contentReturned.push({ error: true });
        contentReturned.push(error.response.data);
        callback(contentReturned);
      });
  },
  batchFarmerCreate: function (farmerData, callback) {
    let contentReturned = [];
    axios
      .post(config.CREDITSCORING_URL + CREATE_FARMER_BATCH(), farmerData)
      .then((response) => {
        contentReturned.push({ error: false });
        contentReturned.push(response);
        callback(contentReturned);
      })
      .catch((error) => {
        contentReturned.push({ error: true });
        // contentReturned.push(error.response.data);
        callback(contentReturned);
      });
  },
  //
  // ────────────────────────────────────────────────────────────── I ──────────
  //   :::::: D V S   S C O R I N G : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────────
  //
  getDVSData: function (callback) {
    axios
      .get(config.CREDITSCORING_URL + GET_DVS_SCORING_DATA())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error);
      });
  },
  scoringDVS: function (dvsData, callback) {
    axios
      .post(config.CREDITSCORING_URL + DVS_SCORING_FARMER(), dvsData)
      .then((response) => {
        // console.log(response);
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  },
  getAllDvsScores: function (callback) {
    axios
      .get(config.CREDITSCORING_URL + GET_ALL_DVS_SCORES())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error);
      });
  },
  /* ------------------------- get all farmers created ------------------------ */
  // getAllFarmers: function (callback) {
  //   axios
  //     .get(config.CREDITSCORING_URL + GET_ALL_FARMERS())
  //     .then((response) => {
  //       callback(response.data);
  //     })
  //     .catch((error) => {
  //       callback(error);
  //     });
  // },
  getAllFarmers: function (start, limit, callback) {
    axios
      .get(config.CREDITSCORING_URL + GET_ALL_FARMERS(start, limit))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error);
      });
  },

  getMyFarmers: function (userid, callback) {
    axios
      .get(config.CREDITSCORING_URL + GET_ALL_MY_FARMERS(userid))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error);
      });
  },

  getFarmerid: function (id, callback) {
    axios
      .get(config.CREDITSCORING_URL + `farmerid/${id}`)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error);
        // window.location.href = `/dashboard/farmer/view`;
      });
  },
  /* --------------------------- update farmer data --------------------------- */
  updateFarmer: function (farmerData, callback) {
    let contentReturned = [];
    axios
      .post(config.CREDITSCORING_URL + UPDATE_FARMER(), farmerData)
      .then((response) => {
        console.log(farmerData);
        contentReturned.push({ error: false });
        contentReturned.push(response.data);
        callback(contentReturned);
      })
      .catch((error) => {
        contentReturned.push({ error: true });
        contentReturned.push(error.response.data);
        callback(contentReturned);
      });
  },
  /* -------- get data for a single farmer using the passed in id param ------- */
  getSingleFarmer: function (id, callback) {
    axios
      .get(config.CREDITSCORING_URL + GET_SINGLE_FARMER(id))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error);
        // window.location.href = `/dashboard/farmer/view`;
      });
  },
  /* -------------------------------------------------------------------------- */
  /*                             END OF FARMER DATA                             */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                               FARMER KYF DATA                              */
  /* -------------------------------------------------------------------------- */
  /* --------------------------- add farmer kyf data -------------------------- */
  createFarmerKYF: function (farmerData, callback) {
    let contentReturned = [];
    axios
      .post(config.CREDITSCORING_URL + ADD_FARMER_KYF_DATA(), farmerData)
      .then((response) => {
        contentReturned.push({ error: false });
        contentReturned.push(response.data);
        callback(contentReturned);
      })
      .catch((error) => {
        contentReturned.push({ error: true });
        contentReturned.push(error.response.data);
        callback(contentReturned);
      });
  },
  /* -------------------------------------------------------------------------- */
  /*                           END OF FARMER KYF DATA                           */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              FARMER BANK DATA                              */
  /* -------------------------------------------------------------------------- */
  /* -------------------------- add farmer bank data -------------------------- */
  addFarmerBank: function (farmerData, callback) {
    let contentReturned = [];
    axios
      .post(config.CREDITSCORING_URL + ADD_FARMER_BANK_DATA(), farmerData)
      .then((response) => {
        contentReturned.push({ error: false });
        contentReturned.push(response.data);
        callback(contentReturned);
      })
      .catch((error) => {
        contentReturned.push({ error: true });
        contentReturned.push(error.response.data);
        callback(contentReturned);
      });
  },
  /* -------------------------- get specific kyf data ------------------------- */
  getFarmerBankData: function (id, callback) {
    axios
      .get(config.CREDITSCORING_URL + GET_FARMER_BANK_DATA(id))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error);
      });
  },
  /* ------------------------- update farmer bank data ------------------------- */
  updateFarmerBank: function (id, farmerData, callback) {
    let contentReturned = [];
    axios
      .post(config.CREDITSCORING_URL + UPDATE_FARMER_BANK_DATA(id), farmerData)
      .then((response) => {
        contentReturned.push({ error: false });
        contentReturned.push(response.data);
        callback(contentReturned);
      })
      .catch((error) => {
        contentReturned.push({ error: true });
        contentReturned.push(error.response.data);
        callback(contentReturned);
      });
  },
  /* -------------------------------------------------------------------------- */
  /*                           END OF FARMER BANK DATA                          */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /*                           FARMER FARM INFORMATION                          */
  /* -------------------------------------------------------------------------- */
  /* ----------------------- add farmer farm information ---------------------- */
  addFarmInformation: function (farmerData, callback) {
    let contentReturned = [];
    axios
      .post(
        config.CREDITSCORING_URL + ADD_FARMER_FARM_INFORMATION(),
        farmerData,
      )
      .then((response) => {
        contentReturned.push({ error: false });
        contentReturned.push(response.data);
        callback(contentReturned);
      })
      .catch((error) => {
        contentReturned.push({ error: true });
        contentReturned.push(error.response.data);
        callback(contentReturned);
      });
  },
  /* ------------------------- get all farmers created ------------------------ */
  getFarmerFarmInformation: function (id, callback) {
    axios
      .get(config.CREDITSCORING_URL + GET_FARMER_FARM_DATA(id))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error);
      });
  },
  /* ----------------------- get single farm information ---------------------- */
  getSingleFarmInformation: function (id, callback) {
    axios
      .get(config.CREDITSCORING_URL + GET_SINGLE_FARM_INFORMATION(id))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error);
      });
  },
  /* -------------------------- edit farm information ------------------------- */
  updateFarmInformation: function (id, farmerData, callback) {
    let contentReturned = [];
    axios
      .post(config.CREDITSCORING_URL + EDIT_FARM_INFORMATION(id), farmerData)
      .then((response) => {
        contentReturned.push({ error: false });
        contentReturned.push(response.data);
        callback(contentReturned);
      })
      .catch((error) => {
        contentReturned.push({ error: true });
        contentReturned.push(error.response.data);
        callback(contentReturned);
      });
  },
  /* -------------------------------------------------------------------------- */
  /*                       END OF FARMER FARM INFORMATION                       */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                          FARMER FARM MECHANIZATION                         */
  /* -------------------------------------------------------------------------- */
  /* ------------------------ add farmer farm mechines ------------------------ */
  addFarmMechanization: function (farmerData, callback) {
    let contentReturned = [];
    axios
      .post(
        config.CREDITSCORING_URL + ADD_FARMER_FARM_MECHANIZATION(),
        farmerData,
      )
      .then((response) => {
        contentReturned.push({ error: false });
        contentReturned.push(response.data);
        callback(contentReturned);
      })
      .catch((error) => {
        contentReturned.push({ error: true });
        contentReturned.push(error.response.data);
        callback(contentReturned);
      });
  },
  /* ------------------- get farm mechanization by farmer id ------------------ */
  getFarmMechanization: function (id, callback) {
    axios
      .get(config.CREDITSCORING_URL + GET_FARM_MECHANIZATION_BY_FARMER_ID(id))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error);
      });
  },
  /* ----------------------- get single farm machine ---------------------- */
  getSingleFarmMachine: function (id, callback) {
    axios
      .get(config.CREDITSCORING_URL + GET_SINGLE_FARM_MACHINE(id))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error);
      });
  },
  /* ---------------------------- edit farm machine --------------------------- */
  updateFarmMachine: function (id, farmerData, callback) {
    let contentReturned = [];
    axios
      .post(config.CREDITSCORING_URL + EDIT_FARM_MACHINE(id), farmerData)
      .then((response) => {
        contentReturned.push({ error: false });
        contentReturned.push(response.data);
        callback(contentReturned);
      })
      .catch((error) => {
        contentReturned.push({ error: true });
        contentReturned.push(error.response.data);
        callback(contentReturned);
      });
  },
  /* -------------------------------------------------------------------------- */
  /*                          END OF FARM MECHANIZATION                         */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                                FARM PRACTICE                               */
  /* -------------------------------------------------------------------------- */
  /* ---------------------------- add farm practice --------------------------- */
  addFarmPractice: function (farmerData, callback) {
    let contentReturned = [];
    axios
      .post(config.CREDITSCORING_URL + ADD_FARM_PRACTICE(), farmerData)
      .then((response) => {
        contentReturned.push({ error: false });
        contentReturned.push(response.data);
        callback(contentReturned);
      })
      .catch((error) => {
        contentReturned.push({ error: true });
        contentReturned.push(error.response.data);
        callback(contentReturned);
      });
  },
  /* -------- get data for a single farmer using the passed in id param ------- */
  getFarmPractice: function (id, callback) {
    axios
      .get(config.CREDITSCORING_URL + GET_FARM_PRACTICE(id))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error);
        // window.location.href = `/dashboard/farmer/view`;
      });
  },
  /* -------------------------- update farm practice -------------------------- */
  updateFarmPractice: function (id, farmerData, callback) {
    let contentReturned = [];
    axios
      .post(config.CREDITSCORING_URL + EDIT_FARM_PRACTICE(id), farmerData)
      .then((response) => {
        contentReturned.push({ error: false });
        contentReturned.push(response.data);
        callback(contentReturned);
      })
      .catch((error) => {
        contentReturned.push({ error: true });
        contentReturned.push(error.response.data);
        callback(contentReturned);
      });
  },
  /* -------------------------------------------------------------------------- */
  /*                           END OF FARMER PRACTICE                           */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                                LOAN HISTORY                                */
  /* -------------------------------------------------------------------------- */
  /* ---------------------------- add loan history ---------------------------- */
  addFarmerLoanHistory: function (farmerData, callback) {
    let contentReturned = [];
    axios
      .post(config.CREDITSCORING_URL + ADD_LOAN_HISTORY(), farmerData)
      .then((response) => {
        contentReturned.push({ error: false });
        contentReturned.push(response.data);
        callback(contentReturned);
      })
      .catch((error) => {
        contentReturned.push({ error: true });
        contentReturned.push(error.response.data);
        callback(contentReturned);
      });
  },
  /* ---------------------- get loan history for a farmer --------------------- */
  getLoanHistory: function (id, callback) {
    axios
      .get(config.CREDITSCORING_URL + GET_SINGLE_HISTORY(id))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error);
        // window.location.href = `/dashboard/farmer/view`;
      });
  },
  /* -------------------------- update farm practice -------------------------- */
  updateLoanHistory: function (id, farmerData, callback) {
    let contentReturned = [];
    axios
      .post(config.CREDITSCORING_URL + UPDATE_LOAN_HISTORY(id), farmerData)
      .then((response) => {
        contentReturned.push({ error: false });
        contentReturned.push(response.data);
        callback(contentReturned);
      })
      .catch((error) => {
        contentReturned.push({ error: true });
        contentReturned.push(error.response.data);
        callback(contentReturned);
      });
  },
  /* -------------------------------------------------------------------------- */
  /*                                FARMER PITCH                                */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------- ADD PITCH ------------------------------- */
  addPitch: function (pitchData, callback) {
    let contentReturned = [];
    axios
      .post(config.CREDITSCORING_URL + ADD_FARMER_PITCH(), pitchData)
      .then((response) => {
        contentReturned.push({ error: false });
        contentReturned.push(response.data);
        callback(contentReturned);
      })
      .catch((error) => {
        contentReturned.push({ error: true });
        contentReturned.push(error.response.data);
        callback(contentReturned);
      });
  },
  /* ------------------------------ update pitch ------------------------------ */
  updatePitch: function (id, pitchData, callback) {
    let contentReturned = [];
    axios
      .post(config.CREDITSCORING_URL + UPDATE_FARMER_PITCH(id), pitchData)
      .then((response) => {
        contentReturned.push({ error: false });
        contentReturned.push(response.data);
        callback(contentReturned);
      })
      .catch((error) => {
        contentReturned.push({ error: true });
        contentReturned.push(error.response.data);
        callback(contentReturned);
      });
  },
  /* ------------------------- get pitch by farmer id ------------------------- */
  getPitchFarmerPitch: function (id, callback) {
    axios
      .get(config.CREDITSCORING_URL + GET_PITCH_BY_FARMER_ID(id))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error);
      });
  },
  /* ----------------------------- get all pitches ---------------------------- */
  getAllPitches: function (callback) {
    axios
      .get(config.CREDITSCORING_URL + GET_ALL_PITCHES())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error);
      });
  },
  /* -------------------------------------------------------------------------- */
  /*                              FARMER FULL DATA                              */
  /* -------------------------------------------------------------------------- */
  verifyDataComplete: function (id, callback) {
    axios
      .get(config.CREDITSCORING_URL + GET_ALL_FARMER_DATA_COMPLETE(id))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error);
      });
  },
  /* -------------------------------------------------------------------------- */
  /*                                CREDIT SCORE                                */
  /* -------------------------------------------------------------------------- */
  /* -------- get data for a single farmer using the passed in id param ------- */
  getCreditScore: function (id, callback) {
    let contentReturned = [];
    axios
      .get(config.CREDITSCORING_URL + GET_FARMER_CREDIT_SCORE(id))
      .then((response) => {
        contentReturned.push({ error: false });
        contentReturned.push(response.data);
        callback(contentReturned);
      })
      .catch((error) => {
        if (error.response.status == 404) {
          // Handle 404 error
          contentReturned.push({ error: true });
          callback(contentReturned);
        } else {
          // Handle other errors
          contentReturned.push({ error: true });
          contentReturned.push(error.response.data);
          callback(contentReturned);
        }
      });
  },
  ScoreFarmer: function (data, callback) {
    axios
      .post(config.CREDITSCORING_URL + SCORE_MULTIPLE_FARMERS(), data)
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  },
  /* ------------------------- get all farmers created ------------------------ */
  getAllCreditScores: function (callback) {
    axios
      .get(config.CREDITSCORING_URL + GET_ALL_CREDIT_SCORES())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error);
      });
  },
};
