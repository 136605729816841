<template>
  <router-view v-if="appLoaded" />
  <AlertBox />
  <Preloader v-if="!appLoaded" />
</template>

<script>
import UserService from "@/services/user";
import axios from "axios";
import AlertBox from "@/components/AlertBox.vue";
// import preloader
import Preloader from "@/layouts/shared/Preloader.vue";
export default {
  name: "App",
  components: {
    AlertBox,
    Preloader,
  },
  data() {
    return {
      userData: null,
      appLoaded: false,
    };
  },

  mounted() {
    if (window.innerWidth < 720) {
      window.location.replace("https://mobile.zowasel.com/");
      return 0;
    }

    var tokenValue = this.$store.state.authData;
    if (tokenValue && tokenValue != null) {
      axios.defaults.headers.common = {
        authorization: tokenValue.token,
      };
    } else {
      axios.defaults.headers.common = {};
    }
    if (this.$store.state.authData) {
      UserService.getUser(this.$store.state.authData.key, async (response) => {
        if (!response.error) {
          this.$store.dispatch("setUser", response.data);
          this.userData = this.$store.state.user;
          this.refreshKyc();
          this.appLoaded = true;

          let vm = this;
          setInterval(() => {
            vm.refreshKyc();
          }, 15000);
        } else {
          this.logOut();
        }
      });
    } else {
      this.appLoaded = true;
    }

    // Run extra app functions
    this.addPrototypeHelpers();
  },
  methods: {
    refreshKyc() {
      this.getKycStatus();
      this.getKybStatus();
      this.getKybDetails();
    },
  },
};
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

body.printing #app {
  display: none;
}
</style>
