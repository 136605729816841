<template>
  <DefaultNav>
    <div class="big-container">
      <div class="headings">
        <h2>View Capital</h2>
        <div class="right-btns">
          <a
            href="javascript:void"
            class="btn btn-danger"
            @click="deleteCapital()"
            >Delete Capital</a
          >
        </div>
      </div>

      <div v-if="resultReturned" class="active-subscriptions">
        <div v-if="CapitalData.length > 0 && CapitalData[0].error != true">
          <template v-for="(data, index) in CapitalData" :key="index">
            <div class="row mb-3">
              <div class="col-12 col-sm-6">
                Sources of Income <em>(excl Farming)</em>?
              </div>
              <div class="col-12 col-sm-6">{{ data.otherincomesource }}</div>
            </div>
            <div class="row mb-3">
              <div class="col-12 col-sm-6">Main source of Income?</div>
              <div class="col-12 col-sm-6">{{ data.mainincomesource }}</div>
            </div>
            <div class="row mb-3">
              <div class="col-12 col-sm-6">
                Number of household income earners?
              </div>
              <div class="col-12 col-sm-6">{{ data.noofincomeearners }}</div>
            </div>
            <div class="row mb-3">
              <div class="col-12 col-sm-6">
                Do you have account with any commercial bank?
              </div>
              <div class="col-12 col-sm-6">{{ data.hasbankaccount }}</div>
            </div>
            <div class="row mb-3">
              <div class="col-12 col-sm-6">First option for getting funds?</div>
              <div class="col-12 col-sm-6">{{ data.firstfundingoption }}</div>
            </div>
            <div class="row mb-3">
              <div class="col-12 col-sm-6">
                Prefer to take a loan or use another means to source for funnds?
              </div>
              <div class="col-12 col-sm-6">
                {{ data.needsaloan }}
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12 col-sm-6">
                Number of months needed before payback?
              </div>
              <div class="col-12 col-sm-6">{{ data.paybackmonths }}</div>
            </div>
            <div class="row mb-3">
              <div class="col-12 col-sm-6">
                Did the quantity of harvest change?
              </div>
              <div class="col-12 col-sm-6">{{ data.harvestqtychanged }}</div>
            </div>
            <div class="row mb-3">
              <div class="col-12 col-sm-6">
                Did your expenses cover your pest control?
              </div>
              <div class="col-12 col-sm-6">{{ data.pestexpensechanged }}</div>
            </div>
          </template>
        </div>
        <div v-if="CapitalData[0].error == true">
          <div class="text-center">
            <h4>
              No Capital added yet
              <a href="/dashboard/capital" class="green-link">Add Capital</a>
            </h4>
          </div>
        </div>
      </div>
    </div>
  </DefaultNav>
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import axios from "axios";
import Alert from "@/utilities/alert";
export default {
  name: "CapitalList",
  components: {
    DefaultNav,
  },
  data() {
    return {
      CapitalData: [],
      resultReturned: false,
    };
  },
  mounted() {
    const bvn = JSON.stringify(
      JSON.parse(localStorage.getItem("Zowasel")).user.user_id,
    );
    let dt = {};
    axios
      .get(`http://174.129.139.68//api/capital/bvn=${bvn}`, dt)
      .then((data) => {
        let response = data.data;
        if (response.error == false) {
          this.CapitalData.push(response.data);
          this.resultReturned = true;
        } else {
          this.CapitalData.push(response);
          this.resultReturned = true;
        }
      });
  },
  methods: {
    async deleteCapital() {
      const bvn = JSON.stringify(
        JSON.parse(localStorage.getItem("Zowasel")).user.user_id,
      );
      let dt = {};
      await axios
        .delete(`http://174.129.139.68//api/capital/bvn=${bvn}`, dt)
        .then((data) => {
          let response = data.data;
          if (response.error == false) {
            Alert.success({
              message: "Capital deleted successfully",
            });
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            Alert.error({
              message:
                "Unable to complete your request at the moment, please try again later.",
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 2% 5% 2% 3%;
  overflow-y: scroll;
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}
.active-subscriptions {
  background-color: white;
  padding: 24px 35px;
  margin-top: 40px;
}

/* The slider */

.sub-history {
  margin-top: 50px;
  width: 100%;
  margin-left: 10px;

  h2 {
    margin-bottom: 40px;
  }
  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #4a4754;
  }

  tr {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 9px;
    height: 66px;
  }
  td {
    flex: 1;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4754;
  }
  .active {
    width: 100px;
    height: 26px;
    background: #dcfce7;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .active-dot {
      width: 7.52px;
      height: 8px;
      background: #047b38;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #047b38;
    }
  }
  .expired {
    width: 100px;
    height: 26px;
    background: #fff8e6;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .expired-dot {
      width: 7.52px;
      height: 8px;
      background: #fab900;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #fab900;
    }
  }
  .cancelled {
    width: 100px;
    height: 26px;
    background: rgba(252, 220, 220, 0.93);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .cancelled-dot {
      width: 7.52px;
      height: 8px;
      background: #c52222;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #6b0303;
    }
  }
  a {
    text-decoration: none;
  }
  a:hover td {
    color: #05b050 !important;
  }
}
.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  a {
    text-decoration: none;
  }
}
.green-link {
  color: white;
  padding: 12px 30px;
  margin-left: 15px;
  text-decoration: none;
  background: #05b050;
  border-radius: 5px;
  letter-spacing: 0.02em;
  font-size: 18px;
}
</style>
