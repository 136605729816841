import { createStore } from "vuex";
import VuexPersist from "vuex-persist";

const vuexLocal = new VuexPersist({
  key: "Zowasel",
  storage: window.localStorage,
});

const store = createStore({
  namespaced: true,
  state() {
    return {
      user: null,
      kycstatus: "Checking...",
      kybstatus: "",
      kybDetails: "",
      authData: null,
      documents: null,
      kycDetails: "",
      notifications: [],
      acceptTermsCropID: [],
    };
  },
  mutations: {
    setUser(state, value) {
      state.user = value;
    },
    setKyc(state, value) {
      state.kycstatus = value;
    },
    setKyb(state, value) {
      state.kybstatus = value;
    },
    setKybDetails(state, value) {
      state.kybDetails = value;
    },
    setDocuments(state, value) {
      state.documents = value;
    },
    setAuth(state, value) {
      state.authData = value;
    },
    setKycDetails(state, value) {
      state.kycDetails = value;
    },
    setNotifications(state, value) {
      state.notifications = value;
    },
    clearNotifications(state) {
      state.notifications = [];
    },
    // Mutation to clear a specific notification based on its id
    clearNotificationById(state, id) {
      const index = state.notifications.findIndex(
        (notification) => notification.meta.id === id,
      );
      if (index !== -1) {
        state.notifications.splice(index, 1);
      }
    },
    setAcceptTermsCropID(state, value) {
      state.acceptTermsCropID.push(value);
    },
  },
  actions: {
    setUser({ commit }, payload) {
      commit("setUser", payload);
    },
    setKyc({ commit }, payload) {
      commit("setKyc", payload);
    },
    setKyb({ commit }, payload) {
      commit("setKyb", payload);
    },
    setKybDetails({ commit }, payload) {
      commit("setKybDetails", payload);
    },
    setKycDetails({ commit }, payload) {
      commit("setKycDetails", payload);
    },
    setDocuments({ commit }, payload) {
      commit("setDocuments", payload);
    },
    setAuth({ commit }, payload) {
      commit("setAuth", payload);
    },
    setNotifications({ commit }, payload) {
      commit("setNotifications", payload);
    },
    updateAcceptTermsCropID({ commit }, payload) {
      commit("setAcceptTermsCropID", payload);
    },
  },
  plugins: [vuexLocal.plugin],
});

export default store;
