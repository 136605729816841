<template>
  <DefaultNav>
    <div class="big-container">
      <div class="headings">
        <h2>My Tickets</h2>
        <div class="right-btns">
          <a href="/dashboard/new-ticket" class="green-link">New Ticket</a>
        </div>
      </div>
      <h4>Issued Loans</h4>
      <input type="text" placeholder="search" />

      <div class="row date-region">
        <div class="col">
          <label for="">Date Paid</label>
          <input type="date" class="form-control" placeholder="Date Paid" />
        </div>
        <div class="col">
          <label for="">Date Closed</label>
          <input type="date" class="form-control" placeholder="Date Closed" />
        </div>
        <div class="col">
          <label for="">Select Status</label>
          <select class="form-control">
            <option>Status</option>
            <option>...</option>
          </select>
        </div>
      </div>

      <div class="active-subscriptions">
        <table class="table-borderless sub-history">
          <tr>
            <td class="theader">ID</td>
            <td class="theader go-big">SUBSCRIPTION</td>
            <td class="theader">DATE CREATED</td>
            <td class="theader">DATE CLOSED</td>
            <td class="theader">STATUS</td>
            <td class="theader">ACTION</td>
          </tr>

          <tbody class="scrollable">
            <tr>
              <td>83456</td>
              <td class="go-big">
                Lorem ipsum dolor sit consectetur. consectetur.
              </td>
              <td>09 SEPT, 2022</td>
              <td>15 SEPT 2022</td>
              <td>
                <div class="active">
                  <div class="active-dot" />
                  <p>Closed</p>
                </div>
              </td>
              <td><a href="">View Details</a></td>
            </tr>

            <tr>
              <td>83456</td>
              <td class="go-big">
                Lorem ipsum dolor sit consectetur. consectetur.
              </td>
              <td>09 SEPT, 2022</td>
              <td>15 SEPT 2022</td>
              <td>
                <div class="active">
                  <div class="active-dot" />
                  <p>Closed</p>
                </div>
              </td>
              <td><a href="">View Details</a></td>
            </tr>

            <tr>
              <td>83456</td>
              <td class="go-big">
                Lorem ipsum dolor sit consectetur. consectetur.
              </td>
              <td>09 SEPT, 2022</td>
              <td>15 SEPT 2022</td>
              <td>
                <div class="cancelled">
                  <div class="cancelled-dot" />
                  <p>Open</p>
                </div>
              </td>
              <td><a href="">View Details</a></td>
            </tr>

            <tr>
              <td>83456</td>
              <td class="go-big">
                Lorem ipsum dolor sit consectetur. consectetur.
              </td>
              <td>09 SEPT, 2022</td>
              <td>15 SEPT 2022</td>
              <td>
                <div class="expired">
                  <div class="expired-dot" />
                  <p>Pending</p>
                </div>
              </td>
              <td><a href="">View Details</a></td>
            </tr>

            <tr>
              <td>83456</td>
              <td class="go-big">
                Lorem ipsum dolor sit consectetur. consectetur.
              </td>
              <td>09 SEPT, 2022</td>
              <td>15 SEPT 2022</td>
              <td>
                <div class="active">
                  <div class="active-dot" />
                  <p>Closed</p>
                </div>
              </td>
              <td><a href="">View Details</a></td>
            </tr>

            <tr>
              <td>83456</td>
              <td class="go-big">
                Lorem ipsum dolor sit consectetur. consectetur.
              </td>
              <td>09 SEPT, 2022</td>
              <td>15 SEPT 2022</td>
              <td>
                <div class="active">
                  <div class="active-dot" />
                  <p>Closed</p>
                </div>
              </td>
              <td><a href="">View Details</a></td>
            </tr>

            <tr>
              <td>83456</td>
              <td class="go-big">
                Lorem ipsum dolor sit consectetur. consectetur.
              </td>
              <td>09 SEPT, 2022</td>
              <td>15 SEPT 2022</td>
              <td>
                <div class="cancelled">
                  <div class="cancelled-dot" />
                  <p>Open</p>
                </div>
              </td>
              <td><a href="">View Details</a></td>
            </tr>

            <tr>
              <td>83456</td>
              <td class="go-big">
                Lorem ipsum dolor sit consectetur. consectetur.
              </td>
              <td>09 SEPT, 2022</td>
              <td>15 SEPT 2022</td>
              <td>
                <div class="expired">
                  <div class="expired-dot" />
                  <p>Pending</p>
                </div>
              </td>
              <td><a href="">View Details</a></td>
            </tr>

            <tr>
              <td>83456</td>
              <td class="go-big">
                Lorem ipsum dolor sit consectetur. consectetur.
              </td>
              <td>09 SEPT, 2022</td>
              <td>15 SEPT 2022</td>
              <td>
                <div class="active">
                  <div class="active-dot" />
                  <p>Closed</p>
                </div>
              </td>
              <td><a href="">View Details</a></td>
            </tr>

            <tr>
              <td>83456</td>
              <td class="go-big">
                Lorem ipsum dolor sit consectetur. consectetur.
              </td>
              <td>09 SEPT, 2022</td>
              <td>15 SEPT 2022</td>
              <td>
                <div class="active">
                  <div class="active-dot" />
                  <p>Closed</p>
                </div>
              </td>
              <td><a href="">View Details</a></td>
            </tr>

            <tr>
              <td>83456</td>
              <td class="go-big">
                Lorem ipsum dolor sit consectetur. consectetur.
              </td>
              <td>09 SEPT, 2022</td>
              <td>15 SEPT 2022</td>
              <td>
                <div class="cancelled">
                  <div class="cancelled-dot" />
                  <p>Open</p>
                </div>
              </td>
              <td><a href="">View Details</a></td>
            </tr>

            <tr>
              <td>83456</td>
              <td class="go-big">
                Lorem ipsum dolor sit consectetur. consectetur.
              </td>
              <td>09 SEPT, 2022</td>
              <td>15 SEPT 2022</td>
              <td>
                <div class="expired">
                  <div class="expired-dot" />
                  <p>Pending</p>
                </div>
              </td>
              <td><a href="">View Details</a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </DefaultNav>
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
export default {
  name: "Tickets",
  components: {
    DefaultNav,
  },
};
</script>

<style lang="scss" scoped>
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 2% 5% 2% 3%;

  overflow-y: scroll;
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}
.active-subscriptions {
  background-color: white;
  padding: 24px 35px;
  margin-top: 40px;
}

/* The slider */

.sub-history {
  margin-top: 50px;
  width: 100%;

  h2 {
    margin-bottom: 40px;
  }
  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #4a4754;
  }

  tr {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 66px;
  }
  .go-big {
    flex: 2;
  }
  td {
    flex: 1;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4754;
  }
  .active {
    width: 100px;
    height: 26px;
    background: #dcfce7;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .active-dot {
      width: 7.52px;
      height: 8px;
      background: #047b38;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #047b38;
    }
  }
  .expired {
    width: 100px;
    height: 26px;
    background: #fff8e6;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .expired-dot {
      width: 7.52px;
      height: 8px;
      background: #fab900;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #fab900;
    }
  }

  .cancelled {
    width: 100px;
    height: 26px;
    background: rgba(252, 220, 220, 0.93);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .cancelled-dot {
      width: 7.52px;
      height: 8px;
      background: #c52222;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #6b0303;
    }
  }
  a {
    color: #fab900;
  }
}
.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  a {
    text-decoration: none;
  }
  .right-btns {
    display: flex;
    gap: 20px;
  }
  .green-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 180px;
    height: 40px;
    background: #05b050;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    text-decoration: none;
  }
}
.date-region {
  margin-top: 30px;
}
</style>
