<template>
  <DefaultNav>
    <div class="big-container">
      <form class="mt-4" @submit.prevent="saveData()">
        <div class="headings">
          <h2>Collateral</h2>
          <div class="right-btns">
            <a href="/dashboard/collateral/view" class="yellow-link"
              >View Collateral</a
            >
            <button class="green-link">Save</button>
          </div>
        </div>
        <div class="mb-3">
          <h6>
            <strong>All fields required</strong>
          </h6>
        </div>
        <div class="mb-4">
          <label for="">How many farmlands do you have? </label>
          <input
            v-model="nooffarmlands"
            type="text"
            class="form-control"
            placeholder="Enter your answer"
            required
          />
        </div>
        <div class="row mb-4">
          <div class="col">
            <label for=""
              >Are you the owner of your farms or a caretaker?</label
            >
            <select
              v-model="ownerorcaretaker"
              class="form-select"
              aria-label="Default select example"
              required
            >
              <option selected>Choose...</option>
              <option value="owner">Owner</option>
              <option value="caretaker">Caretaker</option>
            </select>
          </div>
          <div class="col">
            <label for=""
              >If Caretaker, what is the name of the farm owner?</label
            >
            <input
              v-model="farmownername"
              type="text"
              class="form-control"
              placeholder="Enter your answer"
              required
            />
          </div>
        </div>
        <div class="mb-4">
          <label for="">Farm owner's Phone Number </label>
          <input
            v-model="farmownerphoneno"
            type="text"
            class="form-control"
            placeholder="Enter your answer"
            required
          />
        </div>
        <div class="mb-4">
          <label for="">What is your relationship with them? </label>
          <input
            v-model="relationshipwithowner"
            type="text"
            class="form-control"
            placeholder="Enter your answer"
            required
          />
        </div>
        <div class="row mb-4">
          <div class="col">
            <label for=""
              >If Inherited, from whom did you inherit the farmland?</label
            >
            <input
              v-model="inheritedfrom"
              type="text"
              class="form-control"
              placeholder="Enter your answer"
              required
            />
          </div>
          <div class="col">
            <label for="">What is the size of your farm(s)?</label>
            <input
              v-model="sizeoffarm"
              type="text"
              class="form-control"
              placeholder="Enter your answer"
              required
            />
          </div>
        </div>
        <div class="mb-4">
          <label for=""
            >Where is your farm(s) located? (Take coordinates of farm)
          </label>
          <input
            v-model="farmcoordinates"
            type="text"
            class="form-control"
            placeholder="Enter your answer"
            required
          />
        </div>
        <div class="mb-4">
          <label for="">Farm Location/Address </label>
          <input
            v-model="farmaddress"
            type="text"
            class="form-control"
            placeholder="Enter your answer"
            required
          />
        </div>
        <div class="mb-4">
          <label for=""
            >Apart from crop cultivation, do you keep animals?
          </label>
          <select
            v-model="keepsanimals"
            class="form-select"
            aria-label="Default select example"
            required
          >
            <option selected>Choose...</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
        <div class="mb-4">
          <label for="">
            If Yes, what do your animals eat and where do they live?
          </label>
          <input
            v-model="animalsfeedon"
            type="text"
            class="form-control"
            placeholder="Enter your answer"
            required
          />
        </div>
      </form>
    </div>
  </DefaultNav>
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
// Alert box
import Alert from "@/utilities/alert";
import axios from "axios";
export default {
  name: "Collateral",
  components: {
    DefaultNav,
  },
  data() {
    return {
      nooffarmlands: "",
      ownerorcaretaker: "",
      farmownername: "",
      farmownerphoneno: "",
      relationshipwithowner: "",
      inheritedfrom: "",
      sizeoffarm: "",
      farmcoordinates: "",
      farmaddress: "",
      keepsanimals: "",
      animalsfeedon: "",
      bvn: "",
    };
  },
  methods: {
    async saveData() {
      const bvn = JSON.stringify(
        JSON.parse(localStorage.getItem("Zowasel")).user.user_id,
      );
      let vm = this;
      let dt = {
        animalsfeedon: vm.animalsfeedon,
        bvn: bvn,
        farmaddress: vm.farmaddress,
        farmcoordinates: vm.farmcoordinates,
        farmownername: vm.farmownername,
        farmownerphoneno: vm.farmownerphoneno,
        inheritedfrom: vm.inheritedfrom,
        keepsanimals: vm.keepsanimals,
        nooffarmlands: vm.nooffarmlands,
        ownerorcaretaker: vm.ownerorcaretaker,
        relationshipwithowner: vm.relationshipwithowner,
        sizeoffarm: vm.sizeoffarm,
      };
      await axios
        .post("http://174.129.139.68/api/farmland/add", dt)
        .then((data) => {
          let response = data.data;
          if (response.error == false) {
            Alert.success({
              message: "Collateral added successfully",
            });
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            Alert.error({
              message: response.message,
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 2% 5% 2% 3%;
  overflow-y: scroll;
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}
.active-subscriptions {
  background-color: white;
  padding: 24px 65px;
  margin-top: 40px;

  h2 {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #696671;
  }
}

/* The slider */
.sub-history {
  margin-top: 50px;
  width: 100%;
  margin-left: 10px;

  h2 {
    margin-bottom: 40px;
  }
  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #4a4754;
  }
}
.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  a {
    text-decoration: none;
  }
  .right-btns {
    display: flex;
    gap: 20px;
  }
  .green-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 145px;
    height: 40px;
    background: #05b050;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
  }
}
.green-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 145px;
  height: 40px;
  background: #05b050;
  border-radius: 5px;
  letter-spacing: 0.02em;
}
.yellow-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  width: 145px;
  height: 40px;
  background: #fab900 !important;
  border-radius: 5px;
  letter-spacing: 0.02em;
}
a {
  text-decoration: none;
}
.small-font {
  font-size: 11px !important;
}
</style>
