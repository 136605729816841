<template>
  <DefaultNav>
    <!-- header -->
    <div class="big-container">
      <div class="page-header d-flex justify-content-center align-items-center">
        <h1>
          {{
            order
              ? order.payment_status == "UNPAID"
                ? "Payment"
                : "Balance Payment"
              : ""
          }}
        </h1>
      </div>
      <!--body container -->
      <div class="transaction-sumary-container d-flex flex-row">
        <!-- left -->
        <div class="left-container">
          <div class="payment-container"></div>
          <div v-if="false" class="left-container-wrapper">
            <!--card transactions details -->
            <div class="row mb-3">
              <div class="col">
                <label
                  for="exampleInputEmail1"
                  class="form-label margin-btm-sm mt-0"
                  >Card Number</label
                >
                <input
                  id="exampleInputEmail1"
                  type="number"
                  class="form-control mt-0"
                  aria-describedby="emailHelp"
                  placeholder="1234  5678  9101  1121"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-6">
                <label
                  for="exampleInputEmail1"
                  class="form-label margin-btm-sm mt-0"
                  >Expiration Date</label
                >
                <input
                  id="exampleInputEmail1"
                  type="date"
                  class="form-control mt-0"
                  aria-describedby="emailHelp"
                  placeholder="MM/YY"
                />
              </div>
              <div class="col-6">
                <label
                  for="exampleInputEmail1"
                  class="form-label margin-btm-sm mt-0"
                  >CVV</label
                >
                <input
                  id="exampleInputEmail1"
                  type="number"
                  class="form-control mt-0"
                  aria-describedby="emailHelp"
                  placeholder="123"
                />
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-6">
                <label
                  for="exampleInputEmail1"
                  class="form-label margin-btm-sm mt-0"
                  >Pin</label
                >
                <input
                  id="exampleInputEmail1"
                  type="text"
                  class="form-control mt-0"
                  aria-describedby="emailHelp"
                  placeholder="Pin"
                />
              </div>
            </div>
            <div class="d-grid table-btn mt-4">
              <!-- for corporates view -->
              <a
                v-if="activeTab == 'ordersummary'"
                :class="['btn', 'btn-procceed-waybil']"
                type="button"
                @click="changeTab('balancepayment')"
                >Confirm Payment
              </a>
              <a
                v-if="activeTab == 'balancepayment' && false"
                href="/dashboard/marketplace/confirmpayments"
                :class="['btn', 'btn-procceed-waybil']"
                type="button"
                >Confirm Payment
              </a>

              <a
                href="#"
                :class="['btn', 'btn-procceed-waybil']"
                type="button"
                @click="makePayment()"
                >Confirm Payment
              </a>
            </div>
          </div>
        </div>
        <!-- right -->
        <OrderSummary v-if="activeTab == 'ordersummary'" :order="order" />
        <BalancePayment v-if="activeTab == 'balancepayment'" :order="order" />
      </div>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import OrderSummary from "./components/OrderSummary.vue";
import BalancePayment from "./components/BalancePayment.vue";
import MarketPlaceService from "@/services/marketplace";
import TransactionService from "@/services/transaction";
import config from "@/config";
import Preloader from "@/layouts/shared/Preloader.vue";

export default {
  name: "Payments",
  components: {
    DefaultNav,
    OrderSummary,
    BalancePayment,
    Preloader,
  },
  data() {
    return {
      activeTab: "ordersummary",
      step: 1,
      order: null,
    };
  },
  computed: {
    paymentPercent() {
      return window.localStorage.paymentPercent &&
        window.localStorage.paymentPercent != "null"
        ? eval(window.localStorage.paymentPercent)
        : null;
    },
  },
  mounted() {
    this.getOrder(this.$route.params.order);
    this.savePaymentOption(this.$route.params.order);
  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab;
    },
    async makePayment() {
      var vm = this;
      this.isLoading = true;
      var amountToPay = this.order.total;
      if (this.order.dispute) {
        var amountToPay = this.order.dispute.reconciliation_price;
      }
      if (this.order.payment_status == "UNPAID" && this.paymentPercent) {
        var amountToPay = (this.order.total * eval(this.paymentPercent)) / 100;
      }
      if (this.order.payment_status == "PARTIALLY_PAID") {
        var amountToPay = this.order.amount_due;
      }
      await TransactionService.createPayment(
        {
          amount: amountToPay,
          order: this.order.order_hash,
        },
        (response) => {
          if (response) {
            window.location.replace(response.link);
          }
        },
      );
    },
    getOrder(order) {
      MarketPlaceService.getOrder(order, (response) => {
        // console.log(response);
        var order = response.data;
        order.products = JSON.parse(order.products);
        this.order = order;
        if (order.payment_status == "PARTIALLY_PAID") {
          this.changeTab("balancepayment");
        }
        this.makePayment();
      });
    },
    savePaymentOption() {
      const paymentOption = window.localStorage.getItem("paymentOption");
      MarketPlaceService.savePaymentOption(
        { payment_option: paymentOption, order_hash: this.$route.params.order },
        (response) => {},
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  @include breakpoint-between(md, lg) {
    min-width: 60.5%;
  }

  @include breakpoint-between(lg, xl) {
    min-width: 69.5%;
  }

  @include breakpoint-between(xl, xxl) {
    min-width: 76%;
  }
}

.page-header {
  width: 100%;
  padding: 14px 30px;
  background: #262c3f;

  h1 {
    @include textStyles(Poppins, 700, 24px, 120%);
    color: #ffffff;
  }
}

input[type="date"],
label {
  @include textStyles(Poppins, 300, 14px, 23px);
  color: #c8c8c8;
}

input {
  &::placeholder {
    color: #c8c8c8;
    @include textStyles(Poppins, 700, 14px, 120%);
  }
}

.transaction-sumary-container {
  width: 100%;
  flex: 1;

  .left-container {
    background: #ffffff;
    width: 50%;

    .left-container-wrapper {
      margin-inline: 8%;
      margin-top: 65px;
    }

    .payment-container {
      height: 100%;
      // max-width: 400px;
      width: auto;
      margin-left: auto;
      margin-right: auto;
      overflow-y: scroll;
    }

    // button group
    .table-btn {
      margin-bottom: 100px;

      a {
        padding: 15px 20px;
      }

      .btn-procceed-waybil {
        background: #05b050;
        border-radius: 5px;
        @include textStyles(Inter, 600, 16px, 19px);
        color: #ffffff;
      }
    }
  }
}
</style>

<style lang="scss">
// iframe[name="checkout"] {
//   width: 100%;
//   height: 100%;
//   position: relative !important;
//   display: none;
// }
</style>
