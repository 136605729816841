<template>
  <!-- Certification -->
  <div id="certificate" class="certification_wrapper_carousel">
    <div class="certificate_main">
      <h2>Certifications</h2>
      <div class="certificate_wrapper">
        <div
          v-for="(certificate, index) in certificatePaginate"
          :key="index"
          class="certificate_container"
        >
          <div class="certificate_image_container">
            <img :src="certificate.url" alt="Certificate" class="img-fluid" />
          </div>
          <p class="certificate_info">{{ certificate.title }}</p>
        </div>
        <!-- Add Media -->
        <div
          class="add-certificate-container"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          <div class="add-icon">
            <img src="@/assets/images/vectors/add-certificate.svg" alt="" />
            <p>Add Media</p>
          </div>
        </div>
      </div>
    </div>

    <!-- pagination -->
    <nav class="pagination align-items-center gap-2 my-4">
      <button
        :disabled="currentPage === 1"
        class="btn btn-succ fs-small"
        @click="prevPage"
      >
        Previous
      </button>
      <span class="fs-small">Page {{ currentPage }} of {{ totalPages }}</span>
      <button
        :disabled="currentPage === totalPages"
        class="btn btn-succ fs-small"
        @click="nextPage"
      >
        Next
      </button>
    </nav>
  </div>
  <!-- Modal -->

  <div
    id="exampleModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 id="exampleModalLabel" class="modal-title fs-5">
            Upload Certificate
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <!-- form -->
        <div class="modal-body">
          <div class="upload">
            <h3>Certificate Upload</h3>
            <div>
              <div class="w-100 mb-3">
                <label for="formGroupExampleInput" class="form-label mb-0"
                  >Certificate Title</label
                >
                <span id="required">*</span>
                <input
                  id="formGroupExampleInput"
                  v-model="title"
                  type="text"
                  class="form-control"
                  placeholder="Certificate Title"
                  required
                />
              </div>
              <div class="profile-picture-cropper">
                <div class="w-100 mb-3">
                  <label for="formGroupExampleInput" class="form-label mb-0"
                    >Upload Image</label
                  >
                  <span id="required">*</span>
                  <input
                    id="formGroupExampleInput"
                    type="file"
                    class="form-control"
                    required
                    @change="handleFileUpload"
                  />
                </div>
                <cropper
                  v-if="imageLoaded"
                  ref="cropper"
                  :src="imageSrc"
                  :guides="false"
                  :view-mode="1"
                  :drag-mode="cropType"
                  :aspect-ratio="aspectRatio"
                  :auto-crop-area="1"
                  :movable="false"
                />
                <div v-if="imageLoaded" class="buttons">
                  <button @click="cropImage">Crop</button>
                  <button @click="cancelCrop">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isCrop" class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="uploadCertificate"
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
  <Preloader v-if="isLoading" />
</template>
<script>
import "cropperjs/dist/cropper.css";
import Cropper from "vue-cropperjs";
import MarketPlaceService from "@/services/marketplace";
import Alert from "@/utilities/alert";

// import preloader
import Preloader from "@/layouts/shared/Preloader.vue";
// This component is responsible for handling the user's profile certificate. It allows the user to upload, crop, and save a certificate image. It also provides methods for canceling the crop action and uploading the certificate to the server.
export default {
  name: "ProfileCertificate",
  components: { Cropper },
  props: {
    aspectRatio: {
      type: Number,
      default: 1,
    },
    cropType: {
      type: String,
      default: "crop",
    },
  },
  data() {
    return {
      // The source of the image to be cropped
      imageSrc: "",
      // Indicates if the image has been loaded for cropping
      imageLoaded: false,
      // The cropper instance
      cropper: null,
      // The original image data
      originalImage: null,
      // Indicates if the cropper is active
      isCropper: false,
      // The cropped image data
      img: "",
      // The title of the certificate
      title: "",
      certificateList: [],
      // pagination
      currentPage: 1,
      itemsPerPage: 10,
    };
  },
  computed: {
    certificatePaginate() {
      const sortCertificateList = this.certificateList;
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return sortCertificateList.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.certificateList.length / this.itemsPerPage);
    },
  },
  mounted() {
    if (this.userData.user.certifications) {
      this.certificateList = JSON.parse(this.userData.user.certifications);
    }
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0]; // Get the selected file from the input
      if (!file.type.match("image.*")) {
        // Check if the selected file is an image
        alert("Please select an image file"); // Alert the user if the selected file is not an image
        return; // Exit the function if the selected file is not an image
      }
      const reader = new FileReader(); // Create a new FileReader instance
      reader.readAsDataURL(file); // Read the contents of the selected file
      reader.onload = () => {
        // Set up a callback for when the file has been read
        this.imageSrc = reader.result; // Set the image source to the read result
        this.imageLoaded = true; // Set the imageLoaded flag to true
      };
    },
    cropImage() {
      if (this.$refs.cropper) {
        this.isCrop = true;
        // Check if the cropper instance exists
        const croppedCanvas = this.$refs.cropper.getCroppedCanvas(); // Get the cropped canvas from the cropper
        croppedCanvas.toBlob((blob) => {
          // Convert the cropped canvas to a Blob
          this.img = blob; // Emit the cropped image Blob using a custom event
          this.$refs.cropper.destroy(); // Destroy the cropper instance
          this.cancelCrop(); // Call the cancelCrop method to reset the state
        });
      }
    },
    cancelCrop() {
      this.imageSrc = ""; // Reset the image source
      this.imageLoaded = false; // Reset the imageLoaded flag
      $("#exampleModal1").modal("toggle"); // Toggle the modal with ID "exampleModal1"
    },
    // Uploads the cropped certificate image to the server
    uploadCertificate() {
      // send data to the end-point
      this.isLoading = true;
      var certificateData = new FormData();
      if (this.img) {
        const fileExt = this.img.type.split("/")[1];
        certificateData.append("title", this.title);
        certificateData.append("file", this.img, `${this.title}.${fileExt}`);
      }
      // call the end-point
      MarketPlaceService.addCertificate(certificateData, (response) => {
        if (response && response.error === false) {
          Alert.success({
            message: "Certificate uploaded successfully",
          });
          // reload the window
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          // set the preloader to false
          this.isLoading = false;
          Alert.error({
            message: "error please try again later",
          });
        }
        // set the preloader to false
        this.isLoading = false;
      });
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/main.scss";

// certificate session
.upload > h3 {
  color: #4a4754;
  font-family: Maven Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.18px;
}
.certification_wrapper_carousel {
  padding: 33px 32px;
  border-radius: 6px;
  background: #fff;
}
.certificate_container {
  border-radius: 3px;
  border: 1px solid rgba(19, 94, 0, 0.54);
  padding: 10px;
}
.certificate_image_container {
  width: 220px;
  height: 220px;
}
.certificate_image_container img {
  width: 100%;
  height: 100%;
}
.certificate_wrapper {
  display: flex;
  flex-direction: row;
  gap: 45px;
  margin-top: 32px;
  max-width: 100%;
  flex-wrap: wrap;
}

.certificate_main h2 {
  color: #696671;

  /* Headline/24px */
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.24px;
}

p.certificate_info {
  color: #696671;
  font-family: Maven Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 116%;
  transform: translate(10px, 10px);
}
.add-certificate-container {
  border-radius: 3px;
  border: 2px solid #047b38;
  background: #fff;
  width: 220px;
  height: 270px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.add-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    margin-bottom: 25px;
  }
  p {
    color: #047b38;
    font-family: Maven Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }
}
.modal-footer {
  justify-content: center;
  button {
    color: white;
    background: #008d40;
    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      border-color: #05b050 !important;
      box-shadow: 0 0 !important;
      background: #05b050 !important;
    }
  }
}
// label
div.mb-3 label {
  margin-bottom: 0px;
  @include textStyles(Poppins, 300, 14px, 166.67%);
  color: rgba(45, 55, 72, 0.8);
}
// input, select and textarea states
select,
input {
  font-size: 14px;
  color: #000000;

  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    border-color: #05b050 !important;
    box-shadow: 0 0 !important;
  }
}
#required {
  color: red;
  font-family: "Maven Pro";
  font-weight: bolder;
  font-size: 20px;
}

.profile-picture-cropper {
  margin-top: 1rem;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

button {
  margin: 0 0.5rem;
  padding: 0.5rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #eee;
  cursor: pointer;
}

button:hover {
  background-color: #ddd;
}
.btn-succ {
  background: #05b050;
  color: white;

  &:hover {
    background: darken(#05b050, 10%);
  }
}

.fs-small {
  font-size: 14px;
}
</style>
