<template>
  <div v-for="product in pageList" :key="product.id" class="contents">
    <a href="#" class="each-product">
      <div>
        <h3>
          {{ product.subcategory.name }}
        </h3>
        <p>
          Date: <span>{{ product.created_at }}</span>
        </p>
        <p>
          Delivery Window:
          <span>{{
            parseDeliveryWindow(product).from +
            " -- " +
            parseDeliveryWindow(product).to
          }}</span>
        </p>
        <p>
          Status <span>{{ product.active == 1 ? "Active" : "Inactive" }}</span>
        </p>
      </div>
      <div class="main-address">
        <div class="right">
          <h4>
            Amount: <span>{{ product.specification.price }}</span>
          </h4>
          <div class="product-btns">
            <button class="view" @click="edit(product.id)">Edit</button>
            <button class="delete" @click="removeCropWanted(product)">
              Deactivate
            </button>
            <button class="view" @click="view(product.id)">View</button>
          </div>
        </div>
      </div>
    </a>
  </div>
  <!-- Pagination -->
  <nav class="pagination align-items-center gap-2 my-4">
    <button
      :disabled="currentPage === 1"
      class="btn btn-succ fs-small"
      @click="prevPage"
    >
      Previous
    </button>
    <span class="fs-small">Page {{ currentPage }} of {{ totalPages }}</span>
    <button
      :disabled="currentPage === totalPages"
      class="btn btn-succ fs-small"
      @click="nextPage"
    >
      Next
    </button>
  </nav>
</template>

<script>
import MarketPlaceService from "@/services/marketplace";
import Alert from "@/utilities/alert";
export default {
  name: "MyCrop",
  components: {},
  props: {
    listData: {
      type: Object,
      default: null,
    },
    getCrops: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      userData: this.$store.state.user,
      products: [],
      currentPage: 1,
      itemsPerPage: 10, // Number of notifications to show per page
    };
  },
  computed: {
    pageList() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.listData.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.listData.length / this.itemsPerPage);
    },
  },
  methods: {
    removeCropWanted(item) {
      MarketPlaceService.deactivateCropWanted(item.id, (response) => {
        if (response.error == false) {
          this.getCrops();
          Alert.success({
            message: "Item Deactivated Successfully",
          });
        }
      });
    },
    parseDeliveryWindow(product) {
      return JSON.parse(product.crop_request.delivery_window);
    },
    view(id) {
      this.$router.push({ path: `/dashboard/marketplace/crop/${id}/view` });
    },
    edit(id) {
      this.$router.push({ path: `/marketplace/crop/${id}/edit` });
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.btn-succ {
  background: #05b050;
  color: white;
  &:hover {
    background: darken(#05b050, 10%);
  }
}
.each-product {
  width: 100%;
  border: 1px solid #dce3e9;
  padding: 20px 50px;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  text-decoration: none;
  color: black;

  .product-btns {
    display: flex;
    gap: 15px;

    .edit {
      background: #fff8e6;
      border: 1px solid #f59e0b;
      border-radius: 4px;
      padding: 5px 10px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      color: #f59e0b;
    }

    .delete {
      background: #f9e9e9;
      border: 1px solid #c32021;
      border-radius: 4px;
      padding: 5px 10px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      color: #c32021;
    }

    .view {
      border: none;
      padding: 5px 10px;
      background: white;
      box-shadow:
        0px 2px 4px rgba(44, 39, 56, 0.08),
        0px 4px 8px rgba(44, 39, 56, 0.08);
      border-radius: 4px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 147%;
      color: #05b050;
      border: 1px solid #05b050;
    }
  }
}

.contents {
  width: 100%;
  background-color: white;
  padding: 38px;
}

.top-address {
  align-items: center;
  gap: 12px;

  img {
    width: 20px;
    height: 20px;
  }

  h3 {
    margin-top: 5px;
    color: #05b050;
    font-size: 18px;
  }

  p {
    font-size: 15px;
    span {
      font-size: 13.5px;
    }
  }
}
.fs-small {
  font-size: 14px;
}
.main-address {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    font-size: 18px;
    span {
      font-size: 16px;
    }
  }
  button {
    font-size: 14px;
  }
}
</style>
