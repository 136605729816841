<template>
  <DefaultNav>
    <div class="big-container">
      <h2>Credit Score Result</h2>
      <div v-if="usrDVS.length > 0" class="dvs-system">
        <div class="farmers-details-container">
          <div class="farmer-records">
            <div class="farmers-details">
              <div>
                <h3>Name</h3>
                <p>
                  {{ usrDVS[0].farmer[0].first_name }}
                  {{ usrDVS[0].farmer[0].last_name }}
                </p>
              </div>
              <div>
                <h3>Email</h3>
                <p>{{ usrDVS[0].farmer[0].email }}</p>
              </div>
              <div>
                <h3>Grade</h3>
                <p>{{ usrDVS[0].creditscore[0].totalgrade }}</p>
              </div>
              <div>
                <h3>Score</h3>
                <p class="score">
                  {{ roundValues(usrDVS[0].creditscore[0].totalscore) }}%
                </p>
              </div>
            </div>
          </div>
          <div
            v-if="
              usrDVS[0].creditscore[0].totalgrade == 'A' ||
              usrDVS[0].creditscore[0].totalgrade == 'B'
            "
          >
            <button type="btn" class="btn farmers-btn">
              <span>Request For Loan</span>
            </button>
          </div>
        </div>
        <hr />
        <div class="records-container">
          <!-- Records -->
          <table class="table table-hover table-borderless">
            <thead class="table-group-divider">
              <tr>
                <th>Question</th>
                <th>Your Answer</th>
              </tr>
            </thead>
            <tbody>
              <!-- <template v-for="(data, index) in usrDVS[0].records" :key="index"> -->
              <tr
                v-for="(data, index) in usrDVS[0].records[0].submit"
                :key="index"
              >
                <td>{{ usrDVS[0].records[0].submit[index].questiontext }}</td>
                <td>{{ usrDVS[0].records[0].submit[index].optionintext }}</td>
              </tr>
              <!-- </template> -->
            </tbody>
          </table>
        </div>
      </div>
      <div v-else class="dvs-system">
        <h1 class="text-center py-4">DVS Result Not Found</h1>
      </div>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import Preloader from "@/layouts/shared/Preloader.vue";
import MerchantDVSService from "@/services/merchantdvs";
export default {
  name: "FarmerCSR",
  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      DVSFarmerScores: [],
      currentuserid: JSON.parse(localStorage.getItem("Zowasel")).user.user.type,
      uid: JSON.stringify(
        JSON.parse(localStorage.getItem("Zowasel")).user.user_id,
      ),
      usrDVS: [],
    };
  },
  mounted() {
    console.log(this.currentuserid);

    MerchantDVSService.getCreditScore(this.uid, (response) => {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;

        if (response[1].error == false) {
          const filteringList = response[1].data;
          if (filteringList.creditscore.length > 0) {
            this.usrDVS.push(filteringList);
          }
        }
      }, 2000);
    });
  },
  methods: {
    roundValues(x) {
      if (typeof x == "number") {
        return x.toFixed(2);
      } else {
        return x;
      }
    },
    // goto(loc) {
    //   localStorage.setItem("farmerID", loc);
    //   window.location.href = `/dashboard/loan/type/select`;
    // },
  },
};
</script>

<style lang="scss" scoped>
.big-container {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 2% 5% 2% 3%;
  overflow-y: scroll;
  font-family: "Segoe UI", Poppins;
  h2 {
    color: #4a4754;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28.8px;
    margin-bottom: 19px;
  }
}
.farmers-details-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 35px;
}
.farmer-records {
  flex: 1;
}
.farmers-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.farmers-details h3 {
  color: #696671;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 116%; /* 20.88px */
}
.farmers-details p {
  color: #4a4754;
  font-family: Maven Pro;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 147%; /* 27.93px */
}
.farmers-btn {
  border-radius: 5px;
  background: #05b050;
  padding: 10.9px 30.09px 13.07px 30px;
  span {
    color: #fff;
    text-align: center;
    font-family: "Segoe UI", Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px; /* 150% */
    letter-spacing: 0.36px;
  }
}
p.grade {
  color: #fab900;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
p.score {
  color: #0065ff;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
table {
  height: 30rem;
}
th,
td {
  color: #4a4754;
  font-family: Maven Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 116%; /* 16.24px */
}
td {
  font-weight: 400;
}
thead {
  border-bottom: 1px solid #ededee;
}
thead tr {
  height: 50px;
  vertical-align: top;
}
tbody tr {
  vertical-align: bottom;
}
.dvs-system {
  border-radius: 6px;
  background: #fff;
  padding: 39px 46px;
}
</style>
