<template>
  <DefaultNav>
    <div class="big-container">
      <form class="mt-4" @submit.prevent="saveData()">
        <div class="headings">
          <h2>REQUEST FOR LOAN</h2>
          <div class="right-btns">
            <a
              href="javascript:void(0)"
              class="yellow-link"
              @click="() => backtoselecttype()"
              >Go Back</a
            >
            <a href="/dashboard/loanhistory" class="white-link"
              >Loan Request History</a
            >
          </div>
        </div>
        <div class="mb-3">
          <h6>
            <strong>All fields required</strong>
          </h6>
        </div>
        <div class="row mb-4">
          <div class="col">
            <label for="">Loan Type </label>
            <input
              v-model="loantype"
              class="form-control mt-2"
              type="text"
              placeholder="Enter your answer"
              disabled
            />
          </div>
          <div class="col">
            <label for="">Amount Offered </label>
            <input
              v-model="amountrange"
              class="form-control mt-2"
              type="text"
              placeholder="Enter your answer"
              disabled
            />
          </div>
          <div class="col">
            <label for="">Repayment Duration </label>
            <input
              v-model="repaymentDuration"
              class="form-control mt-2"
              type="text"
              placeholder="Enter your answer"
              disabled
            />
          </div>
        </div>
        <div class="col">
          <label for="">Request Amount </label>
          <input
            v-model="amount"
            class="form-control mt-2"
            type="number"
            :min="`${minRange}`"
            :max="`${maxRange}`"
            placeholder="Enter your answer"
            required
          />
        </div>
        <div class="mb-4 row">
          <div class="col">
            <label for="">Why do you need a loan? </label>
            <textarea
              v-model="description"
              class="form-control mt-2"
              rows="5"
              placeholder="Enter your answer"
              required
            ></textarea>
          </div>
        </div>
        <div class="mb-4 ms-1 d-flex justify-content-end">
          <button class="green-link">Send Request</button>
        </div>
      </form>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
// Alert box
import Alert from "@/utilities/alert";
import axios from "axios";
import Preloader from "@/layouts/shared/Preloader.vue";
import LoanService from "@/services/loan";
export default {
  name: "ApplyLoan",
  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      amountrange: "",
      minRange: 0,
      maxRange: 0,
      repaymentDuration: "",
      description: "",
      amount: "",
      isLoading: false,
      requestOptions: [],
      loantype: "",
      loantypeid: "",
    };
  },
  mounted() {
    const thousandsData = (x) => {
      return "₦" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const url = window.location.href;
    this.isLoading = true;

    if (url.split("/").slice(-1)[0] == "add") {
      if (sessionStorage.getItem("loanid")) {
        let lid = sessionStorage.getItem("loanid");
        LoanService.getLoanTypeById(lid, (response) => {
          setTimeout(() => {
            this.isLoading = false;
            if (response.data == null) {
              window.location.href = "/dashboard/loan/type/select";
            } else {
              this.loantype = response.data[0].loantype;
              this.loantypeid = response.data[0].loantypeid;

              /* ----------- get the value to enter into the amount range field ----------- */
              this.amountrange = `${thousandsData(
                response.data[0].minAmount,
              )} - ${thousandsData(response.data[0].maxAmount)}`;
              /* - this is the value to limit the min and max of the request amount field - */
              this.minRange = response.data[0].minAmount;
              this.maxRange = response.data[0].maxAmount;
              /* ---------------- this is for the repayment duration field ---------------- */
              if (response.data[0].minDuration != "") {
                this.repaymentDuration = `From ${response.data[0].minDuration} - ${response.data[0].maxDuration}`;
              } else {
                this.repaymentDuration = `${response.data[0].maxDuration}`;
              }
            }

            this.isLoading = false;
          }, 3000);
        });
      } else {
        sessionStorage.removeItem("loanid");
        window.location.href = "/dashboard/loan/type/select";
      }
    }
  },
  methods: {
    async saveData() {
      let vm = this;
      let dt = {
        loantypeid: vm.loantypeid,
        userid: localStorage.getItem("farmerID"),
        description: vm.description,
        status: "Pending",
        message: "",
        amount: vm.amount,
        date: vm.getDateTime()[0],
        time: vm.getDateTime()[1],
      };
      // console.log(dt);
      // return false;
      this.isLoading = true;

      LoanService.createLoanRequest(dt, (data) => {
        // console
        /* ------------------- check if it returned with an error ------------------- */
        if (data.status == "200") {
          if (data.data.error == false) {
            Alert.success({
              message: data.data.message,
            });
            setTimeout(() => {
              sessionStorage.removeItem("loanid");
              window.location.href = "/dashboard/loanhistory";
            }, 2000);
          } else {
            Alert.error({
              message: "Failed to complete the request at the moment",
            });
          }
        } else {
          Alert.error({
            message: data[1].message,
          });
        }
        this.isLoading = false;
      });
    },
    getDateTime() {
      var now = new Date();
      var year = now.getFullYear();
      var month = now.getMonth() + 1;
      var day = now.getDate();
      var hour = now.getHours();
      var minute = now.getMinutes();
      var second = now.getSeconds();
      if (month.toString().length == 1) {
        month = "0" + month;
      }
      if (day.toString().length == 1) {
        day = "0" + day;
      }
      if (hour.toString().length == 1) {
        hour = "0" + hour;
      }
      if (minute.toString().length == 1) {
        minute = "0" + minute;
      }
      if (second.toString().length == 1) {
        second = "0" + second;
      }
      let date = year + "/" + month + "/" + day;
      let time = hour + ":" + minute + ":" + second;
      return [date, time];
    },
    backtoselecttype() {
      sessionStorage.removeItem("loanid");
      window.location.href = "/dashboard/loan/type/select";
    },
  },
};
</script>

<style lang="scss" scoped>
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 2% 5% 2% 3%;
  overflow-y: scroll;
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}
.active-subscriptions {
  background-color: white;
  padding: 24px 65px;
  margin-top: 40px;

  h2 {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #696671;
  }
}

/* The slider */
.sub-history {
  margin-top: 50px;
  width: 100%;
  margin-left: 10px;

  h2 {
    margin-bottom: 40px;
  }
  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #4a4754;
  }
}
.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  a {
    text-decoration: none;
  }
  .right-btns {
    display: flex;
    gap: 20px;
  }
  .green-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 145px;
    height: 40px;
    background: #05b050;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
  }
}
.green-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 145px;
  height: 40px;
  background: #05b050;
  border-radius: 5px;
  letter-spacing: 0.02em;
}
.yellow-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  width: 185px;
  height: 40px;
  background: #fab900 !important;
  border-radius: 5px;
  letter-spacing: 0.02em;
}
a {
  text-decoration: none;
}
.small-font {
  font-size: 11px !important;
}

.white-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 40px;
  background: #ffffff;
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #05b050;
  border: 2px solid #05b050;
  border-radius: 4px;
}
</style>
