<template>
  <div class="main_container">
    <!-- for edit page -->
    <div v-if="productID" class="edit_crop">
      Update Crop Details By Editing The Input Field With The Updated
      Information. (Images are currently uneditable.)
    </div>
    <!-- new crop  wanted form-->
    <div>
      <div class="crop-wanted-section d-flex flex-row gap-4">
        <div class="form">
          <div class="crop_details">Quantity and Pricing</div>
          <div class="w-100 mb-3">
            <label for="formGroupExampleInput" class="form-label mb-0"
              >Crop quantity</label
            >
            <span id="required">*</span>
            <input
              id="formGroupExampleInput"
              v-model="newCropData.qty"
              type="number"
              class="form-control"
              min="1"
              required
            />
          </div>
          <div class="w-100 mb-3">
            <label for="formGroupExampleInput" class="form-label mb-0"
              >Amount/offer</label
            >
            <span id="required">*</span>
            <input
              id="formGroupExampleInput"
              v-model="newCropData.price"
              type="number"
              class="form-control"
              placeholder="Type your answer"
              required
            />
          </div>
          <div
            v-if="type == 'wanted' || type == 'auction'"
            class="crop_details mb-3 mt-5"
          >
            Delivery Details
          </div>

          <div v-if="type == 'wanted'" class="w-100 mb-3">
            <label for="formGroupExampleInput" class="form-label mb-0"
              >Delivery window</label
            >
            <span id="required">*</span>
            <div class="form-row">
              <div class="col">
                <input
                  id="from-date-input"
                  v-model="newCropData.delivery_window.from"
                  type="date"
                  class="form-control"
                  placeholder="Type your answer"
                  required
                />
              </div>

              <div class="col">
                <input
                  id="to-date-input"
                  v-model="newCropData.delivery_window.to"
                  type="date"
                  class="form-control"
                  placeholder="Type your answer"
                  required
                />
              </div>
            </div>
          </div>
          <div class="w-100 mb-3">
            <label for="exampleInputEmail1" class="form-label mb-0"
              >Country</label
            >
            <span id="required">*</span>
            <select
              v-model="newCropData.country"
              class="form-select"
              aria-label="Default select example"
              required
            >
              <option
                v-for="(country, index) in countries"
                :key="index"
                :value="country.country"
              >
                {{ country.country }}
              </option>
            </select>
          </div>
          <div class="w-100 mb-3">
            <label for="exampleInputEmail1" class="form-label mb-0"
              >State</label
            >
            <span id="required">*</span>
            <select
              v-model="newCropData.state"
              class="form-select"
              aria-label="Default select example"
              required
            >
              <option
                v-for="(state, index) in selectStateByCountry"
                :key="index"
              >
                {{ state }}
              </option>
            </select>
          </div>
          <!-- for Crop Auction -->
          <div v-if="type == 'auction'" class="w-100 mb-3">
            <label for="formGroupExampleInput" class="form-label mb-0"
              >Auction Date start</label
            >
            <span id="required">*</span>
            <div class="form-row">
              <input
                id="formGroupExampleInput"
                v-model="newCropData.start_date"
                type="date"
                class="form-control"
                required
              />
            </div>
          </div>
          <div v-if="type == 'auction'" class="w-100 mb-3">
            <label for="formGroupExampleInput" class="form-label mb-0"
              >Auction Date End</label
            >
            <span id="required">*</span>
            <div class="form-row">
              <input
                id="formGroupExampleInput"
                v-model="newCropData.end_date"
                type="date"
                class="form-control"
                required
              />
            </div>
          </div>
          <div v-if="type == 'auction'" class="w-100 mb-3">
            <label for="formGroupExampleInput" class="form-label mb-0"
              >Minimium Bid</label
            >
            <span id="required">*</span>
            <div class="form-row">
              <input
                id="formGroupExampleInput"
                v-model="newCropData.minimum_bid"
                type="number"
                class="form-control"
                required
              />
            </div>
          </div>
        </div>
        <div class="vertical-line" />
        <!-- form two -->
        <div class="form">
          <div class="crop_details">Product image</div>
          <label for="formGroupExampleInput" class="form-label img-warning mb-0"
            >Add atleast two images to proceed</label
          >
          <div class="dropzone_conatiner my-4">
            <div class="dropzone">
              <img id="preview-image" class="img-fluid mb-2" />
              <img
                id="image_icon"
                src="@/assets/images/vectors/Image.svg"
                alt="image"
              />
              <div id="file-input">
                <input
                  type="file"
                  data-preview="preview-image"
                  data-index="0"
                  accept="image/*"
                  required
                  @change="uploadFile"
                />
                <a
                  v-if="!fileNames[0] || fileNames[0] == ''"
                  class="upload-img"
                  href="javascript:void(0)"
                  @click="openFileDialog"
                >
                  <span>Add Image</span>
                  <span id="required">*</span>
                </a>
                <span v-else>{{ fileName }}</span>
              </div>
            </div>

            <div class="small-images">
              <div v-for="i in 4" :key="i" class="dropzone1">
                <img :id="'preview-image' + i" class="img-fluid mb-2" />
                <img
                  id="image_icon"
                  src="@/assets/images/vectors/Image.svg"
                  alt="image"
                />
                <div id="file-input">
                  <input
                    type="file"
                    :data-preview="'preview-image' + i"
                    :data-index="i"
                    accept="image/*"
                    :required="i == 1 ? true : null"
                    @change="uploadFile"
                  />
                  <a
                    v-if="!fileNames[i] || fileNames[i] == ''"
                    class="upload-img"
                    href="javascript:void(0)"
                    @click="openFileDialog"
                  >
                    <span>Add Image</span>
                    <span id="required">*</span>
                  </a>
                  <span v-else>{{ fileName }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="w-100">
            <label for="formGroupExampleInput" class="form-label mb-0"
              >Enter video URL</label
            >
            <span id="optional">-optional</span>
            <input
              id="formGroupExampleInput"
              v-model="newCropData.video"
              type="text"
              class="form-control"
              placeholder="Type your answer"
            />
          </div>
          <div class="w-100 mb-3">
            <label for="exampleInputEmail1" class="form-label mb-0"
              >Zip code/postal</label
            >
            <span id="required">*</span>
            <input
              id="formGroupExampleInput"
              v-model="newCropData.zip"
              type="number"
              class="form-control"
              placeholder="Type your answer"
              required
            />
          </div>
          <div class="w-100 mb-3">
            <label for="formGroupExampleInput" class="form-label mb-0"
              >Warehouse Address</label
            >
            <span id="required">*</span>
            <input
              v-model="newCropData.warehouse_address"
              type="text"
              class="form-control"
              placeholder=""
              required
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countriesObject from "@/data/countries";
import MarketPlaceService from "@/services/marketplace";

export default {
  name: "QualityProduct",
  props: {
    type: String,
  },
  data() {
    return {
      newCropData: {
        quantity: "",
        price: "",
        warehouse_address: "",
        state: "",
        files: [],
        video: "",
        country: "",
        zip: "",
        ...(this.type == "wanted"
          ? { delivery_window: { from: "", to: "" } }
          : {}),
        ...(this.type == "auction"
          ? { start_date: "", end_date: "", minimum_bid: "" }
          : {}),
      },
      countries: countriesObject.countries,
      fileNames: [],
      productID: false,
    };
  },
  computed: {
    selectStateByCountry: function () {
      return this.countries && this.newCropData.country != ""
        ? this.countries.filter(
            (item) => item.country == this.newCropData.country,
          )[0].states
        : [];
    },
  },
  watch: {
    "newCropData.delivery_window.from"(newValue) {
      // Calculate the maximum date based on the selected "from" date
      const maxDate = new Date(newValue);
      maxDate.setDate(maxDate.getDate() + 14); // Add 14 days (2 weeks)

      // Format the maximum date as yyyy-mm-dd
      const formattedMaxDate = maxDate.toISOString().split("T")[0];

      // Set the maximum attribute of the "to" date input
      document
        .getElementById("to-date-input")
        .setAttribute("max", formattedMaxDate);
    },
  },
  mounted() {
    // // get todays date
    // let now = new Date();
    // const minDate = now.toISOString().split("T")[0];
    // // get and set the min attribute of the 'to' date input
    // document.getElementById("from-date-input").setAttribute("min", minDate);
    // Fetch a specific crop based on its ID
    this.fetchCrop(this.$route.params.id);

    // check if routes contains a product Id as params to hence an edit page
    if (!isNaN(this.$route.params.id)) {
      this.productID = true;
    }
  },
  methods: {
    // Asynchronous function to fetch crop data by ID
    async fetchCrop(id) {
      try {
        // Create a new promise to handle the asynchronous operation
        const response = await new Promise((resolve, reject) => {
          // Call a service (MarketPlaceService) to get crop data by ID
          // The service accepts a callback function which will be called with the response data
          MarketPlaceService.getCropById(id, (response) => {
            // Resolve the promise with the response data
            resolve(response);
          });
        });

        // Once the promise is resolved, assign the received data to 'productData' property of the current object
        this.productData = response.data;
      } catch (error) {
        // If an error occurs during the fetch operation, catch it and log an error message with the specific error
        console.error("Error fetching ", error);
      }
    },
    openFileDialog(event) {
      if (event.target.tagName != "A") {
        event.target.parentElement.previousElementSibling.click();
      } else {
        event.target.previousElementSibling.click();
      }
    },
    addNewCrop() {
      MarketPlaceService.getNewCrops(this.newCropData, (response) => {});
    },
    uploadFile(event) {
      const input = event.target;
      const file = input.files;
      this.newCropData.files.push(file[0]);

      // get the file name
      this.fileNames[eval(input.getAttribute("data-index"))] = file[0].name;

      // preview the image

      // if at least one image is selected proceed to display the image
      if (file[0]) {
        // get the image path
        const imageSrc = URL.createObjectURL(file[0]);
        // select the image preview element
        const imagePreviewElement = document.querySelector(
          `#${input.getAttribute("data-preview")}`,
        );
        // assign the path to the image preview element
        imagePreviewElement.src = imageSrc;
        imagePreviewElement.style.display = "block";
        // hide sibling img element
        imagePreviewElement.nextElementSibling.style.display = "none";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

#hidden_input {
  display: none;
}

.crop-wanted-section {
  background: #ffffff;
  border-radius: 4px;
  padding: 4%;
}
.edit_crop {
  background: #cd4647;
  box-shadow:
    0px 24px 48px 0px rgba(44, 39, 56, 0.08),
    0px 12px 24px 0px rgba(44, 39, 56, 0.04);
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-family: Maven Pro;
  padding: 10px 10px 10px 4%;
  margin-bottom: 30px;
}
.form {
  .crop_details {
    @include textStyles(inherit, 700, 16px, 24px);
    letter-spacing: 0.01em;
    color: #4a4754;
    margin-bottom: 1rem;
  }

  .m_top {
    margin-top: 3.4rem;
  }

  width: 100%;
  padding-bottom: 2rem;

  select,
  input {
    font-size: 14px;
    color: #000000;

    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      border-color: #05b050 !important;
      box-shadow: 0 0 !important;
    }

    &::placeholder {
      @include textStyles("Maven Pro", 700, 14px, 24px);
      color: #000000;
    }
  }

  label {
    font-size: 14px;
    font-weight: 900;
    color: rgba(45, 55, 72, 0.8);
  }
}

div.vertical-line {
  width: 2px;
  padding: 1px;
  background: #f5f5f5;
}

// using drop-zone
.dropzone {
  position: relative;
  width: 100%;
  min-height: 250px;
  border: 2px dashed #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 147%;
  color: #4a4754;
  padding: 10px 10px 15px 10px;

  span {
    font-weight: 700;
    color: #05b050;
    cursor: pointer;

    + span {
      font-size: 14px;
    }
  }
}
.small-images {
  margin-top: 25px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.dropzone1 {
  position: relative;
  width: 22%;
  min-height: 150px;
  border: 2px dashed #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 147%;
  color: #4a4754;
  padding: 10px 10px 15px 10px;

  span {
    font-weight: 700;
    color: #05b050;
    cursor: pointer;

    + span {
      font-size: 14px;
    }
  }
}

#required {
  color: red;
  font-family: "Maven Pro";
  font-weight: bolder;
  font-size: 20px;
}

#optional {
  font-family: "Maven Pro";
  font-weight: bolder;
  font-size: 14px;
  color: rgba(113, 117, 125, 0.83);
  font-style: italic;
}

.zone {
  height: 150px;
  font-size: 12px;
}

#file-input > input {
  display: none;
}
.upload-img {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;

  span {
    margin-top: 55px;
  }
}
.img-warning {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 11px;
  display: flex;
  color: #ff0002;
  text-align: center;
}
</style>
