<template>
  <DefaultNav>
    <div id="redirect-wrapper">
      <div id="redirect-container">
        <h2 class="container">
          Please, complete your KYC to access the platform
          {{
            $store.state.kycstatus.toLowerCase() !== "verified" ? "KYC" : "KYB"
          }}.
        </h2>
        <div id="backTo-dashboard">
          <!-- back to home page button -->
          <router-link to="/dashboard" class="routes"
            >Go Back To Dashboard</router-link
          >
          <router-link
            :to="
              $store.state.kycstatus.toLowerCase() !== 'verified'
                ? '/kycredirect'
                : '/kybredirect'
            "
            class="routes btn-hover-state"
            >Proceed To
            {{
              $store.state.kycstatus.toLowerCase() !== "verified"
                ? "KYC"
                : "KYB"
            }}</router-link
          >
        </div>
      </div>
    </div>
  </DefaultNav>
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";

export default {
  name: "RedirectToKyc",
  components: {
    DefaultNav,
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/shared/Styles";
#redirect-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
#redirect-container {
  width: 80%;
  padding-block: 10em;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  h2 {
    // margin-top: 5em;
    text-align: center;
  }
}
#backTo-dashboard {
  display: flex;
  margin-top: 40px;
  margin-left: 20%;
  height: 40px;
  img {
    height: 25px;
  }
  .routes {
    background: #05b050;
    color: white;
    padding: 10px;
    border-radius: 4px;
    width: 30%;
    text-align: center;
    margin-left: 5%;
    text-decoration: none;
  }
  + div {
    width: 40%;
  }
}
</style>
