<template>
  <DefaultNav>
    <div class="big-container">
      <div class="headings">
        <div class="left-side">
          <!-- <a href="/dashboard/each-loan"
            ><img src="@/assets/images/vectors/arrowleft.png" alt=""
          /></a> -->
          <h2 class="loan-detail">Choose A Loan Offer</h2>
        </div>
      </div>
      <div class="active-subscriptions">
        <table class="table-borderless sub-history">
          <tbody class="scrollable">
            <tr>
              <td class="serial">S/N</td>
              <td class="theader">TITLE</td>
              <td class="theader">AMOUNT RANGE</td>
              <td class="theader">DURATION</td>
              <td class="theader">TERMS AND CONDITION</td>
              <td class="theader"></td>
            </tr>
            <hr />
            <div v-if="loanTypes.length > 0">
              <template v-for="(data, index) in loanTypes" :key="index">
                <tr>
                  <td class="serial">{{ index + 1 }}</td>
                  <td>
                    {{ data.loantype }}
                  </td>
                  <td>
                    <a
                      :href="`#loanAmount${index}`"
                      class="link"
                      data-bs-toggle="modal"
                      >View</a
                    >
                  </td>
                  <td>
                    <a
                      :href="`#loanDuration${index}`"
                      class="link"
                      data-bs-toggle="modal"
                      >View</a
                    >
                  </td>
                  <td>
                    <a
                      :href="`#loanDescription${index}`"
                      class="link"
                      data-bs-toggle="modal"
                      >View</a
                    >
                  </td>
                  <td>
                    <a
                      class="btn btn-success"
                      href="javascript:void(0)"
                      style="font-weight: bold"
                      @click="setLoanType(data.loantypeid)"
                      ><small>Proceed</small></a
                    >
                  </td>
                </tr>
                <!-- Payment Modal -->
                <div
                  :id="`loanAmount${index}`"
                  class="modal fade"
                  tabindex="-1"
                  aria-labelledby="loanAmountLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 id="loanAmountLabel" class="modal-title">
                          Loan Amount
                        </h5>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        <p>
                          Minimum Amount:
                          <strong> {{ thousandsData(data.minAmount) }}</strong>
                          <br />Maximum Amount:
                          <strong> {{ thousandsData(data.maxAmount) }}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Payment Duration -->
                <div
                  :id="`loanDuration${index}`"
                  class="modal fade"
                  tabindex="-1"
                  aria-labelledby="loanDurationLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 id="loanDurationLabel" class="modal-title">
                          Loan Repayment Duration
                        </h5>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        <p>
                          <span v-if="data.minDuration != ''">
                            Minimum Repayment Duration:
                            <strong> {{ data.minDuration }}</strong>
                            <br />
                            Maximum
                          </span>
                          Repayment Duration:
                          <strong> {{ data.maxDuration }}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Description Modal -->
                <div
                  :id="`loanDescription${index}`"
                  class="modal fade"
                  tabindex="-1"
                  aria-labelledby="loanDescriptionLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 id="loanDescriptionLabel" class="modal-title">
                          Loan Type Description
                        </h5>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body" v-html="data.purpose"></div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div v-else>
              <div class="long-card">
                <tr class="text-center">
                  <td colspan="6" center>No Loan Types Added</td>
                </tr>
              </div>
            </div>
          </tbody>
          <!-- pagination -->
          <nav class="d-flex justify-content-center my-4">
            <div id="pagination"></div>
          </nav>
        </table>
      </div>
      <!-- <template v-if="loanTypes.length > 0">
        <div v-for="(data, index) in loanTypes" :key="index" class="long-card">
          <div class="long-card">
            <div class="each-card">
              <div class="top-section">
                <h5>Loan Type</h5>
                <h2 class="green-text">{{ data.loantype }}</h2>
              </div>
            </div>
            <div class="middle-card">
              <div class="each-card">
                <div class="top-section">
                  <h5>Min. Issuable Amount</h5>
                  <p>NGN {{ data.minAmount }}</p>
                </div>
                <div class="bottom-section">
                  <h5>Max. Issuable Amount</h5>
                  <p>NGN {{ data.maxAmount }}</p>
                </div>
              </div>
              <div class="each-card">
                <div class="top-section">
                  <h5>Payment Duration</h5>
                  <p>
                    {{ data.minDuration }}
                    <span v-if="data.minDuration != ''">-</span>
                    {{ data.maxDuration }}
                  </p>
                </div>
                <div class="bottom-section">
                  <h5>Loan Interest</h5>
                  <p>{{ data.interest }}</p>
                </div>
              </div>
              <div class="each-card">
                <div class="top-section">
                  <h5>Repayment Type</h5>
                  <p>{{ data.repaymentType }}</p>
                </div>
                <div class="bottom-section">
                  <h5>Loan Description</h5>
                  <p>
                    <a
                      v-bind:href="`#loanDescription${index}`"
                      class="link"
                      data-bs-toggle="modal"
                      >View Description</a
                    >
                  </p>
                </div>
              </div>
            </div>
            <div
              v-bind:id="`loanDescription${index}`"
              class="modal fade"
              tabindex="-1"
              aria-labelledby="loanDescriptionLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 id="loanDescriptionLabel" class="modal-title">
                      Loan Type Description
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body" v-html="data.purpose"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template> -->
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import Preloader from "@/layouts/shared/Preloader.vue";
import LoanService from "@/services/loan";
import Alert from "@/utilities/alert";

export default {
  name: "SelectLoanType",
  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      loanTypes: [],
      modalContent: "",
    };
  },
  mounted() {
    this.isLoading = true;
    const url = window.location.href;
    const uid = JSON.stringify(
      JSON.parse(localStorage.getItem("Zowasel")).user.user_id,
    );
    LoanService.getAllLoanTypes((response) => {
      console.log(response.data.data);
      this.loanTypes = response.data.data;
      this.isLoading = false;
    });
  },
  methods: {
    thousandsData(x) {
      return "₦" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    setLoanType(id) {
      sessionStorage.setItem("loanid", id);
      sessionStorage.setItem("loanid", id);
      location.assign("/dashboard/loan/add");
    },
  },
};
</script>

<style lang="scss" scoped>
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 4% 5% 10% 3%;
  overflow-y: scroll;
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}
.active-subscriptions {
  background-color: white;
  padding: 24px 35px;
  margin-top: 20px;
}
.prl {
  padding: 24px 35px;
}
.sub-history {
  margin-top: 30px;
  width: 100%;
  margin-left: 10px;

  h2 {
    margin-bottom: 40px;
  }
  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #4a4754;
  }

  .serial {
    max-width: 35px;
  }

  tr {
    display: flex;
    flex-direction: row;
    padding: 20px 9px 0;
    height: 66px;
    th {
      font-weight: bold;
    }
  }
  td {
    flex: 1;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4754;
    max-width: 185px;
    overflow-x: hidden;
    padding-left: 10px;
  }
  .active {
    width: 100px;
    height: 26px;
    background: #dcfce7;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .active-dot {
      width: 7.52px;
      height: 8px;
      background: #047b38;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #047b38;
    }
  }
  .expired {
    width: 100px;
    height: 26px;
    background: #fff8e6;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .expired-dot {
      width: 7.52px;
      height: 8px;
      background: #fab900;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #fab900;
    }
  }
  .cancelled {
    width: 100px;
    height: 26px;
    background: rgba(252, 220, 220, 0.93);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .cancelled-dot {
      width: 7.52px;
      height: 8px;
      background: #c52222;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #6b0303;
    }
  }
  a {
    text-decoration: none;
  }
  a:hover td {
    color: #05b050 !important;
  }
}
.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  a {
    text-decoration: none;
  }
  .right-btns {
    display: flex;
    gap: 20px;
  }
  .green-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 180px;
    height: 40px;
    background: #05b050;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    border-radius: 4px;
  }
  .white-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 40px;
    background: #ffffff;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #05b050;
    border: 2px solid #05b050;
    border-radius: 4px;
  }
  .red-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 180px;
    height: 40px;
    background: #cd4647;
    box-shadow:
      0px 2px 4px rgba(44, 39, 56, 0.08),
      0px 4px 8px rgba(44, 39, 56, 0.08);
    border-radius: 4px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    border-radius: 4px;
  }
}
.date-region {
  margin-top: 30px;
}
.view-btn {
  color: black;
}
.bg-grey {
  background: rgba(0, 0, 0, 0.183);
}
.sectionSelectButton {
  padding: 11px 0;
  border-radius: 5px;
  border: none;
  width: 50%;
  background: rgba(0, 0, 0, 0.183);
}
.sectionSelectButton.active {
  background: #262c3f;
  color: white;
}

.outline-green {
  color: #05b050 !important;
  width: 230px !important;
  text-align: center;
  background: #f5f5f5 !important;
  border: 2px solid #05b050;
}
</style>
