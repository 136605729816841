<template>
  <div class="right-container-wrapper">
    <div id="section-to-download">
      <h1>Goods Receipt Note</h1>
      <hr />
      <div class="zowasel-receipt">
        <div class="receipt-wrapper">
          <div class="company-details">
            <div class="wrapper-one">
              <!-- logo -->
              <div class="logo-wrapper">
                <img
                  class="img-fluid"
                  src="@/assets/images/logos/zowasel-grey-logo.png"
                  alt="zowasel-logo"
                />
              </div>
              <!-- address -->
              <address>
                <p>3B, Abba Sagoe Street, Otedola Estate</p>
                <p>Omole Phase 2, . Lagos. Nigeria</p>
                <p>Phone : 01 342 4403</p>
                <p>Email : sales@zowasel.com</p>
              </address>
            </div>
            <div class="wrapper-two">
              <h4>Good Receipts Note</h4>
              <table class="table">
                <tr>
                  <th>Buyers Name</th>
                  <td>{{ order.buyer.first_name }}</td>
                </tr>
                <tr>
                  <th>Warehouse</th>
                  <td>--</td>
                </tr>
                <tr>
                  <th>Crop Season</th>
                  <td>--</td>
                </tr>
                <tr>
                  <th>Date</th>
                  <td>{{ orderDate.toDateString() }}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="vendor_details d-flex flex-row mb-4">
            <table class="table">
              <tr>
                <th>Vendor Name</th>
                <td>{{ sellerName }}</td>
              </tr>
              <tr>
                <th>Commodity Type</th>
                <td>--</td>
              </tr>
              <tr>
                <th>Crop Origin</th>
                <td>--</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>--</td>
              </tr>
            </table>
            <table class="table">
              <tr>
                <th>Waybill No.</th>
                <td>{{ waybillDetails.waybill_number ?? "--" }}</td>
              </tr>
              <tr>
                <th>LPO Number</th>
                <td>--</td>
              </tr>
              <tr>
                <th>Vehicle Number</th>
                <td>
                  {{ waybillDetails.dispatch_section.truck_number }}
                </td>
              </tr>
              <tr>
                <th>Truck Type</th>
                <td>--</td>
              </tr>
            </table>
          </div>
          <div class="form-wrapper">
            <!-- grid -->
            <table v-if="order" class="table table-bordered">
              <tr>
                <th>Crop Description</th>
                <td>{{ receipt.crop_description }}</td>
              </tr>
              <tr>
                <th>Packaging/Bagging</th>
                <td>{{ receipt.packaging }}</td>
              </tr>
              <tr>
                <th>Number of Bags</th>
                <td>{{ receipt.no_of_bags }}</td>
              </tr>
              <tr>
                <th>Scale Type</th>
                <td>{{ receipt.scale_type }}</td>
              </tr>
              <tr>
                <th>Gross Weight</th>
                <td>{{ receipt.gross_weight }}</td>
              </tr>
              <tr>
                <th>Tare Weight</th>
                <td>{{ receipt.tare_weight }}</td>
              </tr>
              <tr>
                <th>Net Weight</th>
                <td>{{ receipt.net_weight }}</td>
              </tr>
              <tr>
                <th>Rejected Quantity</th>
                <td>{{ receipt.rejected_quantity }}</td>
              </tr>
              <tr>
                <th>Accepted Quantity</th>
                <td>{{ receipt.accepted_quantity }}</td>
              </tr>
              <tr>
                <th>Rate</th>
                <td>{{ receipt.rate }}</td>
              </tr>
              <tr>
                <th>Discount</th>
                <td>{{ receipt.discount }}</td>
              </tr>
              <tr>
                <th>Total Value</th>
                <td>{{ receipt.total_value }}</td>
              </tr>
              <tr>
                <th>Validation Parameters</th>
                <td>
                  Moisture Content(Mc), Foreign Matter(FM), Broken Kernel (BK),
                  Bulk Density(BD), Free Fatty Acid(FFA),DOBI, Refractive index,
                  Colour, Mouldy Beans (CB), Immature Seeds(IS), Weather Damage
                  (WD)
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="position-relatives">
      <div class="btn-group w-100 gap-4">
        <div class="download">
          <a class="btn big-download" type="button" @click="downloadOpt()"
            >Download</a
          >
          <div v-if="downloadFile" class="download_container">
            <button
              href=""
              class="download_invoice as_image green-btn"
              @click="downloadAsImage()"
            >
              image
            </button>

            <button
              class="download_invoice as_pdf green-btn"
              @click="downloadAsPDF()"
            >
              pdf
            </button>
          </div>
        </div>
        <div class="download">
          <a class="btn big-download" type="button" @click="printSection()"
            >Print</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Waybills",
  props: {
    order: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      downloadFile: false,
    };
  },
  computed: {
    receipt() {
      return this.order.receipt_note
        ? JSON.parse(this.order.receipt_note)
        : null;
    },
    orderDate() {
      return new Date(this.order.created_at);
    },
    sellerName() {
      const seller = this.order.seller;
      return seller.account_type == "company"
        ? seller.company.company_name
        : seller.first_name;
    },
    waybillDetails() {
      return this.order.waybill_details
        ? JSON.parse(this.order.waybill_details)
        : {};
    },
  },
  mounted() {
    // this.checkOrder();
    // console.log(this.order.waybill_details);
  },

  methods: {
    downloadOpt() {
      this.downloadFile = !this.downloadFile;
    },
    downloadAsImage() {
      const element = document.getElementById("section-to-download");
      // Create a canvas from the element
      html2canvas(element).then((canvas) => {
        // Convert canvas to image
        const imgData = canvas.toDataURL("image/png");
        // create a temporary link element and trigger download
        const link = document.createElement("a");
        link.href = imgData;
        link.download = "Specification.png";
        link.click();
      });
    },
    downloadAsPDF() {
      const element = document.getElementById("section-to-download");
      // Create a new instance of the html2pdf library
      const opt = {
        margin: 1,
        filename: "section.pdf",
        image: { type: "png", quality: 1 },
        html2canvas: { scale: 2 },
        jsPDF: { format: "a4", orientation: "portrait" },
      };

      html2pdf().set(opt).from(element).save();
    },
    printSection() {
      const sectionToPrint = document.getElementById(
        "section-to-download",
      ).innerHTML;
      const printWindow = window.open("", "_blank");
      printWindow.document.open();
      printWindow.document.write(`
        <html>
          <head>
            <title>Print</title>
          </head>
          <body>
            ${sectionToPrint}
          </body>
        </html>
      `);
      printWindow.document.close();

      printWindow.onload = function () {
        printWindow.print();
        printWindow.onafterprint = function () {
          printWindow.close();
        };
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.right-container-wrapper {
  width: 50%;
  margin-inline: 4%;
  margin-top: 65px;
  padding-bottom: 100px;

  h1 {
    @include textStyles(Inter, 600, 20px, 28px);
    letter-spacing: -0.02em;
    color: #0a0d13;
    margin-bottom: 17px;
    text-align: center;
  }

  hr {
    border: 1px solid rgba(0, 0, 0, 0.18);
    margin-bottom: 23px;
  }

  .zowasel-receipt {
    background: #ffffff;
    padding: 30px;

    .receipt-wrapper {
      color: rgba(0, 0, 0, 0.849);

      .company-details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        h4 {
          font-size: 13px;
        }

        .table > :not(caption) > * > * {
          padding: 0.2rem 0.5rem;
          border-bottom-width: 0px;
        }
        table {
          tr {
            border-style: none !important;
          }

          th,
          td {
            font-size: 13px;
          }
        }

        .logo-wrapper {
          width: 100px;
          margin-bottom: 10px;
        }

        address {
          margin-bottom: 15px;

          p {
            margin-bottom: 0px;
            font-family: Poppins;
            font-size: 13px;
          }
        }

        .wrapper-two {
          h4 {
            font-size: 16px;
          }
        }
      }

      .vendor_details {
        background: #f5f5f5;
        justify-content: space-between;
        font-size: 13px;
        .table > :not(caption) > * > * {
          border-bottom-width: 0px;
        }
      }
      .form-wrapper table tr {
        font-size: 13px;
      }
      button.table-btn {
        margin-top: 33px;
        padding: 15px 20px;
        background: #262c3f;
        border-radius: 5px;
        @include textStyles(Inter, 600, 16px, 19px);
        color: #ffffff;
      }
    }
  }
}
.download_container {
  transition: display 3s;
  position: absolute;
  width: 374px;
  padding: 10px;
  top: -48px;
  display: flex;
  gap: 15px;
  z-index: 1000;
  button {
    background: #05b050;
    flex: 1;
    width: 50%;
    text-align: center;
    border: none;
    height: 35px;
    &:hover {
      background: darken(#05b050, 10%);
    }
  }
}
@media print {
  body * {
    display: none;
  }

  #section-to-download,
  #section-to-download * {
    display: block !important;
  }

  .download,
  .position-relatives,
  .btn-group {
    display: none !important;
  }
}
#section-to-download {
  padding-bottom: 10px;
}
.download {
  width: 100%;
  display: flex;
  gap: 15px;
  height: auto;
  .big-download {
    width: 100%;
    height: 48px;
    background-color: #0a0d13;
    color: white;
  }
}
</style>
