<template>
  <AuthSharedLayout>
    <div class="big-container">
      <img
        class="mobile-logo"
        src="@/assets/images/logos/zowasel-logo.png"
        alt=""
      />
      <div class="left-content">
        <h2 class="beta2">BETA 1.0</h2>
        <h1 class="text-center">Create an Account</h1>
        <p id="emailHelp" class="form-text text-center">
          Get connected with your network of vetted growers
        </p>
        <div class="lines">
          <div class="line" />
          <p>OR</p>
          <div class="line" />
        </div>
        <form
          id="register-form"
          action="javascript:void()"
          @submit="submitForm($event)"
        >
          <div class="form-group">
            <label for="">First Name</label>
            <input
              type="text"
              class="form-control"
              required
              name="first-name"
              placeholder="Enter your first name"
            />
          </div>
          <div class="form-group">
            <label for="">Last Name</label>
            <input
              type="text"
              class="form-control"
              required
              name="last-name"
              placeholder="Enter your last name"
            />
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col">
                <label for="">Email Address</label>
                <input
                  type="text"
                  class="form-control"
                  required
                  name="email"
                  placeholder="Enter email address"
                />
              </div>
              <div class="col">
                <label for="">Phone Number</label>
                <input
                  v-model="phoneNumber"
                  type="tel"
                  minlength="11"
                  maxlength="11"
                  class="form-control"
                  required
                  name="phone"
                  placeholder="08089683533"
                  @input="validateNumericInput"
                />
              </div>
            </div>
            <div v-if="type != 'partner'" class="row radios-container">
              <div class="col-3 radios">
                <input type="radio" name="account-type" value="individual" />
                <label for="">Individual Account</label>
              </div>
              <div class="col-3 radios">
                <input type="radio" name="account-type" value="company" />
                <label for="">Company Account</label>
              </div>
            </div>
          </div>
          <div class="form-group checking">
            <input type="checkbox" name="accept" required />
            <!-- Trigger for the privacy modal -->
            <p class="check-p">
              I agree to all the
              <span data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                Terms and Privacy policy</span
              >
            </p>
          </div>
          <div class="form-group">
            <button
              type="submit"
              class="btn btn-lg btn-block green-btn btn-hover-state"
            >
              Continue
            </button>
          </div>
        </form>
        <div class="last-text">
          <p id="" class="text-center">
            Do you already have an account?
            <a href="/login" class="login-text">Login</a>
          </p>
        </div>
      </div>
      <div class="right-content">
        <img
          v-if="type == 'merchant' || type == 'corporate'"
          class="background-img"
          src="@/assets/images/backgrounds/merchant-background2.png"
          alt=""
        />
        <img
          v-if="type == 'partner' || type == 'agent'"
          class="background-img"
          src="@/assets/images/backgrounds/partner-background2.png"
          alt=""
        />
        <img class="logo" src="@/assets/images/logos/zowasel-logo.png" alt="" />
      </div>
    </div>
  </AuthSharedLayout>
  <!-- MODAL FOR PRIVACY POLICY -->

  <div
    id="staticBackdrop"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header justify-content-center">
          <h1 id="staticBackdropLabel" class="modal-title fs-5">
            Privacy Policy
          </h1>
        </div>
        <div class="modal-body">
          <h4>Our commitment to customer privacy</h4>

          <h6 class="mt-5">A. Commitment to your privacy</h6>
          <p>
            Zowasel Inc. (“we,” “our” or “us”) recognize/s that your privacy is
            very important to you and we are committed to protecting your
            (“your,” “you’) Personal Data.
          </p>
          <p>
            “Personal Data” means any information relating to an identified or
            identifiable natural person; an identifiable natural person can be
            identified, directly or indirectly, in particular by reference to an
            identifier such as a name, an identification number, location data,
            an online identifier or to one or more factors specific to the
            physical, physiological, genetic, mental, economic, cultural or
            social identity of that natural person.
          </p>
          <p>
            Zowasel, which is connecting crop sellers with buyers offers bids to
            growers through Zowasel Marketplace to purchase quantities of the
            crop that meet certain quality specifications, as more fully
            described in your Zowasel Marketplace Agreement or Zowasel Standard
            Quality Crop Specification (“Zowasel Marketplace”).
          </p>
          <p>
            This privacy policy (the “Privacy Policy”) applies to Personal Data
            we receive and handle about participants in connection with:
          </p>
          <ul>
            <li>
              Your use of this website and mobile applications (each, the
              “Site”);
            </li>
            <li>Participation in Zowasel Marketplace;</li>
            <li>Communication related to Zowasel Marketplace;</li>
            <li>
              Participation in the Zowasel services of binding contract offers
              and acceptances, market data, price information, and messaging;
            </li>
            <li>Offers of Zowasel Marketplace of crops;</li>
            <li>Offers of Zowasel Transport of crops;</li>
            <li>Offers of Zowasel Finance of crops;</li>
            <li>Offers of Zowasel Certified Crops;</li>
            <li>Grower offers;</li>
            <li>Use of information to improve the flow of the transaction;</li>
            <li>Market data;</li>
            <li>
              Ability to connect with Zowasel representatives or grain market
              Trustees, Facilitators;
            </li>
            <li>
              Use of the APIs, SMS, USSD, and other platforms through which
              Zowasel makes the Services (as defined below) available.
            </li>
          </ul>
          <p>
            “Systems” are any of the existing and future electronic systems,
            APIs, USSD, SMS platforms, and other platforms through which Zowasel
            and its affiliates make the Services available to you. “Services”
            are any of the existing and future services offered by Zowasel or
            its affiliates and any content including, without limitation, USSD
            and SMS-based preliminary indications, indication acceptances,
            binding contract offers and acceptances, market data, price
            information, connectivity, contract confirmation, and messaging.
          </p>
          <p>
            By participating in the Zowasel Marketplace or accessing our Sites,
            Systems, and/or Services (collectively, the “Marketplace Platform”),
            you agree to be bound by the terms of this Privacy Policy.
          </p>
          <p>
            This policy also includes our credit reporting policy, that is, it
            additionally covers how we manage your Data collected in connection
            with a credit application, such as details relating to your credit
            history, credit standing, credit capacity, and creditworthiness
            (“credit information”). A separate policy applies to the collection
            and use of Personal Data of Zowasel employees, contractors, and
            other staff.
          </p>
          <h6>B. The kinds of information that we process</h6>
          <p>
            The kinds of Personal Data we collect and process, by or on behalf
            of us, may include: Contact Information: such as your name, home or
            business address, email address, telephone number, Personal Details
            Information: such as your name, date of birth, employer, profession,
            and job title. Technical Information: such as technical data
            collected about your interaction with our websites, mobile
            applications, and email communications including IP address; log
            files; unique device identifiers; pages viewed; date and time of
            access; content of request; username; password; usage data; device
            type; browser type and settings; language settings; aggregate
            statistical information; the transferred volume of data; and website
            requesting access. Marketing Data: such as cookie data; browsing
            history; advertising interests; responses to direct marketing and
            opt-outs from direct marketing. Grower Data: such as grower offers;
            transport offers; crop data; agronomic data; land data; satellite
            imagery data, and grower management data. Financial Information:
            such as payment information; bank details; card details; purchase
            interests and purchase history. Credit Information: If applicable,
            based upon your participation in Zowasel Marketplace, we may collect
            credit information. By applying for our services, you agree that we
            may obtain this credit information from you or a credit reporting
            body. This information may include:
          </p>
          <ul>
            <li>Default information;</li>
            <li>Credit infringement information;</li>
            <Li
              >Personal solvency information (e.g. bankruptcy information);
            </Li>
            <li>
              Court proceedings information (e.g. any default judgments entered
              against you); and
            </li>
            <Li>Repayment history information. </Li>
          </ul>
          <p>
            You can choose not to provide your Data to us, but generally, the
            information we request from you is required for us to provide you
            with the goods and services we offer.
          </p>
          <h6>C. How we collect Personal Data</h6>
          <p>
            Generally, we collect Personal Data from: Direct Interactions: such
            as when you contact us, inquire about our product or services,
            provide us with information in connection with our services,
            participate in surveys or questionnaires, Mobile Applications: such
            as when you download or use one of our apps. Some apps may have
            specific privacy statements that include more specific information
            that you should review. Cookies and Automated Technologies: such as
            when you visit or interact with our Site(s) or click on links in
            emails. Public Sources: such as when we need to collect Personal
            Data from publicly available sources including, land registries and
            government agencies. Social Media: such as Personal Data that you
            make available by linking to social media platforms using social
            media plug-ins, or your social media profile(s). Technologies: such
            as when you use or receive any service offerings that include, any
            agronomic, data recording, mapping or satellite technologies, USSD
            SMS, etc. Partners: such as when you provide Personal Data to an
            authorized Zowasel partner, distributor, or reseller. Private
            Third-Party Sources: such as when third parties provide information
            to us.
          </p>
          <h6>D. Cookies</h6>
          <p>
            About Marketplace Platform use, components of our Marketplace
            Platform may use “cookies” (these are files that are implanted in
            your hard drive by some websites to store information about your web
            use patterns) to enhance or personalize our Marketplace Platform.
            Cookies allow for the collection of information such as:
          </p>
          <ul>
            <li>browser type;</li>
            <li>time spent on the Site;</li>
            <li>pages visited;</li>
            <li>language preferences</li>
          </ul>
          <p>
            These cookies may also be used to collect and store information
            about your usage of certain components of our Marketplace Platform.
            We or our service providers may, in any case, collect anonymous
            information that allows us to review information about the date,
            time, and duration of visits to the components of our Marketplace
            Platform without identifying you. <br />
            Pixel tags, web beacons, clear GIFs, or other similar technologies
            may be used in connection with our Marketplace Platform to track the
            way you use our Marketplace Platform and to compile statistics about
            site usage and response rates. This information is generally
            automatically provided to us by your web browser.
          </p>
          <p>
            You may choose to refuse cookies by turning them off in the browser;
            however, if you do not accept them, you may experience some
            difficulty in your use of certain components of our Marketplace
            Platform. <br />
            We may use cookies to:
          </p>
          <ul>
            <li>Improve the functionality of our Site;</li>
            <li>Process your purchase orders;</li>
            <li>Display information more effectively;</li>
            <li>Personalize your experience while using our Site;</li>
            <li>
              Gather information about how our Site is used and compile
              anonymous and aggregate statistics.
            </li>
          </ul>
          <h6>E. How we use Personal Data</h6>
          <p>The primary purpose(s) we collect your Personal Data is to:</p>
          <ul>
            <li>
              Enable the creation of bids and offers in Zowasel Marketplace
            </li>
            <li>
              Enable the availability of purchase orders, prices, and bid offers
            </li>
            <li>
              Enable crop sampling, including crop quality and specifications
            </li>
            <li>Verify your identity</li>
            <li>Respond to your queries</li>
            <li>Maintain our relationship with you</li>
            <li>Provide our products and services to you</li>
            <li>Send you statements and invoices</li>
            <li>Collect payments from you</li>
            <li>Provide payments to you</li>
            <li>
              Provide you with information about our products and services
            </li>
            <li>
              Update you on changes to our Marketplace Platform terms,
              conditions, policies, and other administrative information
            </li>
            <li>Conduct research for our internal purposes</li>
            <li>Request credit information from a credit-reporting body</li>
            <li>
              Send you communications and contact you about our goods and
              services, programs, events, campaigns, functions, or news updates
              that may be relevant or of interest to you
            </li>
            <li>
              Administering, maintaining, and monitoring Site performance and
              usage
            </li>
          </ul>
          <p>You agree that we may use your Data for any of these purposes.</p>
          <h6>F. Legal Basis for Processing Personal Data</h6>
          <p>
            The legal basis for processing your Personal Data in connection with
            the purposes may, depending on the circumstances, rely on any of the
            following legal bases:
          </p>
          <ul>
            <li>
              The processing is necessary for connection with a contract entered
              into with you, or for your benefit
            </li>
            <li>
              We or a partner has obtained your prior written consent to the
              processing
            </li>
            <li>
              The processing is required by all laws, statutes, or regulations
              applicable to the respective Zowasel affiliate(s)
            </li>
            <li>
              We have a legitimate interest in carrying out the processing
              (including our legitimate interest in providing goods and services
              to you, maintaining or improving our products or services,
              operating our business, alerting you to other products or services
              that may be of interest to you) and there is no undue risk to your
              right, freedoms, or interests
            </li>
            <li>
              The processing is necessary to protect the vital interests of any
              individual
            </li>
            <li>Any other lawful basis</li>
          </ul>
          <h6>G. How we use credit information</h6>
          <p>
            To the extent applicable to your participation in Zowasel
            Marketplace, we use the credit information that we obtain from you
            or a credit reporting body and information we derive from such
            information strictly foto assess your credit application. If you do
            not authorize us to obtain credit information about you, we may be
            unable to assess your creditworthiness and therefore your
            suitability for our prepayment under our model agreement. In such
            circumstances, your credit request may be refused.
          </p>
          <p>
            To the extent applicable to your participation in Zowasel
            Marketplace, we may disclose your Data to credit reporting bodies
            where we are permitted to do so by law, for example, in
            circumstances where you fail to meet your payment obligations about
            consumer credit. These credit reporting bodies may include any such
            information in reports provided to other credit providers to assist
            them to assess your creditworthiness.
          </p>
          <h6>H. The circumstances in which we may disclose Personal Data</h6>
          <p>
            So that we may use your information for the purposes identified
            above, we may disclose it to external providers of services, for
            example, entities providing crop analysis, and providers of
            security, marketing, financial credit, and IT services. We may also
            outsource certain functions of our business. We may disclose
            Personal Data to related entities and you may receive communications
            directly from these entities. These communications may include
            information and marketing communications about their products and
            services. We use reasonable endeavours to ensure that these
            companies do not breach any U.S. laws, however, we disclaim all
            liability for any unlawful use of your Peta by an affiliated or
            associated company.
          </p>
          <p>
            We may disclose your Personal Data to the extent that we are
            required to do so by law, including in connection with any legal
            proceedings, anticipated legal proceedings, or regulatory
            administrative matters, or to comply with any legal obligation or to
            establish, exercise or defend our legal rights. We may sell,
            transfer, or otherwise disclose our database of Personal Data to an
            actual or potential successor entity, purchaser, or investor in
            connection with a corporate merger, consolidation, sale of our
            assets or a substantial part of our assets, share sale, investment
            transaction or another corporate rearrangement. <br />
            We may disclose your Personal Data to a prospective seller or buyer
            if we sell, merge, or transfer all or a portion of our business or
            assets. If you make a payment via one of our Sites or one of our
            apps or directly to us or one of our dealers your Personal Data may
            need to be provided to third parties foto process payments. <br />
            You agree that we may disclose your Data in any of these
            circumstances. We disclaim all liability for any privacy breaches by
            third parties to whom we have disclosed your Peta by this policy.
            <br />Zowasel will assure that any third-party service provider that
            Zowasel shares or discloses information with will legally be
            required to provide the same or equal protection of user data as
            provided by Zowasel. <br />You may withdraw your consent to use or
            disclose your Data at any time. To withdraw this consent please
            contact us at the details below. Please note that withdrawing your
            consent may mean that we are unable to provide you with any
            services.
          </p>
          <h6>I. International Transfer of Personal Data</h6>
          <p>
            Personal Data that we collect may be transferred and stored in a
            jurisdiction other than the jurisdiction in which your Personal Data
            was originally collected and transferred between any of the
            countries in which we, our related entities, or our service
            providers or international distributors operate. We do not take any
            responsibility for the actions of overseas third-party recipients of
            personal information. It is not practicable to specify all the
            countries in which overseas recipients may be located. Your Data may
            also be transferred to an entity located overseas.
          </p>
          <p>
            Your Data may be transferred overseas if we sell, transfer, or
            disclose our database of Personal Data to an actual or potential
            successor entity, purchaser, or investor who is located or has
            offices overseas. It is not practicable to specify the likely
            countries in which recipients of information may be located in this
            regard. These transfers of Personal Data are necessary to provide
            our products and services to you effectively and efficiently. The
            Personal Data will be transferred for the operation of any Site(s)
            or in connection with any of the processing purposes outlined in
            this Privacy Policy.
          </p>
          <h6>J. Security of your Personal Data</h6>
          <p>
            Once in our possession, we take all reasonable precautions to
            protect the personal and credit information we hold about you from
            misuse, interference, loss, and unauthorized access, modification,
            or disclosure. Whilst we endeavour to provide a secure online
            environment, there are inherent risks associated with the
            transmission of information via the internet and no data
            transmission over the internet can be guaranteed to be completely
            secure. We, therefore, cannot warrant the security of any
            information you provide to us over the Internet and you do so at
            your own risk.
            <br />We encourage you to play an important role in keeping your
            Data secure, by maintaining the confidentiality of any passwords and
            account details used on our Marketplace Platform. It is your sole
            responsibility to maintain such confidentiality and we will not be
            liable for any damage, loss, or expense suffered due to such
            disclosure. Zowasel processes Personal Data in a manner that ensures
            appropriate security of the personal information, including
            protection against unauthorized or unlawful processing and
            accidental loss, destruction, or damage, using appropriate technical
            or organizational measures. Additionally:
          </p>
          <ul>
            <li>
              Zowasel has imposed disciplinary measures for violations of the
              comprehensive information security program rules;
            </li>
            <li>
              Zowasel performs regular monitoring to determine if the
              information security program is operating in a manner to prevents
              unauthorized access to or unauthorized use of personal
              information.
            </li>
          </ul>
          <h6>K. Third parties</h6>
          <p>
            About your use of certain components of the Marketplace Platform,
            certain components of our Marketplace Platform may contain links to
            third-party websites and social media features that are hosted by a
            third party. Links to other websites do not constitute sponsorship,
            endorsement, or approval of the information found on those websites.
            You should evaluate the accuracy, relevance, and suitability for
            your purposes of any such information. We are not responsible for
            the privacy policies or practices of third-party websites, and your
            interactions with any social media features are governed by the
            privacy policies and practices of the hosting entities.
          </p>
          <h6>L. Access to and correction of your information</h6>
          <p>
            We aim to ensure that your personal and credit information is
            accurate, complete, and up to date. To assist us, please contact us
            via the details below if any of your details provided have changed
            or if you believe that the information we hold is inaccurate. You
            may request us to provide you with access to the personal or credit
            information we hold about you at any time. We will respond to your
            access request as soon as possible, however, before disclosing any
            such information it may be necessary for you to satisfactorily
            verify your identity. There are exceptional circumstances where
            access to or correction of your personal or credit information may
            be refused by us such as where access would be unlawful. We will
            advise you of such circumstances if they arise.
          </p>
          <h6>M. How You Can Manage Your Privacy</h6>
          <p>
            You may limit the collection of certain website information by
            deleting or disabling cookies. Most computers' Internet browsers
            enable you to erase stored cookies, block all cookies, or receive a
            warning before a cookie is stored. Disabling cookies may prevent you
            from using specific features on the Site or other websites, such as
            ordering products or services and maintaining an online account.
            Please note that many opt-outs use browser cookies or device
            controls and are specific to the device, or browser, you are using.
            If you buy a new computer, change web browsers or devices, or delete
            the cookies on your computer, you may need to opt out again.
          </p>
          <h6>N. Privacy of Children</h6>
          <p>
            We do not knowingly intend to collect information from or direct
            Zowasel services to children aged 16 and under. If Zowasel learns
            that information has been received from a child under 16, then
            Zowasel will delete such information.
          </p>
          <h6>O. Retention of Information</h6>
          <p>
            Zowasel Inc. retains Your Data only for the period necessary to
            fulfil the purposes outlined in this Privacy Policy unless a longer
            retention period is required or allowed by law or to otherwise
            fulfil a legal obligation. For business continuity, Zowasel backs up
            your data within the Zowasel infrastructure. As soon as these
            retention periods are complete, the data will be deleted or properly
            anonymized so that you may no longer be identified from it.
          </p>
          <h6>P. Marketing</h6>
          <p>
            Zowasel may contact you by email, text, or via other electronic
            communication to provide You with marketing materials about Zowasel
            products or services that may be of interest to You. If you do not
            wish to receive such communications, you may follow the unsubscribe
            options provided in such marketing communications.
          </p>
          <h6>Q. Privacy Policy Updates</h6>
          <p>
            From time to time, we may change and post revisions to this Privacy
            Policy and such changes to this Privacy Policy will become effective
            upon the posting of the revised Privacy Policy on the Site. Your use
            of the Site following these changes means that You accept the
            revised Privacy Policy.
          </p>
          <h6>R. Contact Us</h6>
          <p>
            If you have any questions or complaints about this Privacy Policy or
            our treatment of your personal or credit information, or if you
            would like to access or amend your personal information, please
            contact us: Email address: legal@zowasel.com This policy will be
            reviewed from time to time to take into account new laws and/or
            changes to our operations. Any information we hold about you will be
            governed by our most current policy. We recommend that you
            periodically review this policy for any changes.
          </p>

          <!--*  Terms and Condition-->
          <h4>Zowasel Terms & Conditions</h4>
          <h6>Updated March 12, 2020</h6>
          <p class="mt-4">
            Acceptance of Terms. These Terms of Use are entered into between You
            and Zowasel, Inc. (“Zowasel,” “we,” or “us”) and relate to the
            Zowasel Marketplace, the crop marketing platform operated and
            administered by Zowasel to connect buyers and sellers of crops that
            meet certain quality specifications(the “Zowasel Marketplace”). The
            following terms and conditions, together with any documents
            incorporated by reference (collectively “Terms of Use”), govern your
            access to, and the Marketplace Platform. The “Marketplace Platform”
            includes any website administered by Zowasel that relates to
            transactions through the Zowasel Marketplace (“Website”) and the
            Systems and Services (defined below), including any content and
            functionality offered through the Website, Systems, and Services. By
            accessing, viewing, or using any page, part or component of the
            Marketplace Platform, you have indicated your acknowledgement and
            acceptance of these Terms of Use.
          </p>

          <ol>
            <li>
              Consent to text messages. By participating in or using the
              Marketplace Platform, you consent to receive text messages sent
              through an automatic telephone dialing system.
            </li>
            <li>
              Systems and Services. "Systems" are any of the existing and future
              electronic systems, applications, APIs, SMS platforms, and other
              platforms through which Zowasel and its affiliates make the
              Services available to you. "Services" are any of the existing and
              future services offered to you by Zowasel or its affiliates and
              any content including, without limitation, SMS-based preliminary
              indications, indication acceptances, binding contract offers and
              acceptances, market data, price information, connectivity,
              contract confirmation, and messaging. By using the Systems and
              Services, Your own message and data rates may apply.
            </li>
            <li>
              Zowasel Marketplace Transactions. The Website, Systems, and
              Services comprise an online platform through which (i) growers and
              distributors may be notified of, and accept, offers to purchase
              crops, (ii) growers may initiate bids to sell crops, (iii) buyers
              may be notified of, and accept, offers to sell crops, and (iv)
              buyers may make bids to purchase crops. Zowasel has no control
              over the conduct of the users of the Website, Systems, or Services
              and disclaims all liability in this regard to the maximum extent
              permitted by law.
            </li>
            <li>
              Terms Applicable to Sellers. The following provisions contained in
              this Section 5 apply to the extent You participate in the Zowasel
              Marketplace as a seller or a potential seller of crops. From time
              to time, Zowasel may present a bid to purchase from You (a "Grower
              Bid"), or You may submit an offer to sell to Zowasel (a "Grower
              Offer"), a certain quantity of a crop of a certain quality. Any
              additional terms or conditions, including without limitation
              price, quality specifications, and additional services, will be
              included In the Grower Bid or Grower Offer, which shall be on the
              applicable Zowasel Marketplace form provided by Zowasel. Each
              Grower Bid will remain open until the time at which Zowasel issues
              a cancellation of the Grower Bid, and each Grower Offer will
              remain open until the time at which You revoke the Grower Offer or
              the Grower Offer expires by its terms. For clarification, any
              Grower Bid can be canceled by Zowasel at any time before Zowasel's
              receipt of Your formal acceptance of a Grower Bid. A Grower Offer
              submitted by You will become irrevocable upon Zowasel's acceptance
              of such Grower Offer. If You successfully accept a Grower Bid or
              Zowasel accepts a Grower Offer, such acceptance will be reflected
              in a binding written confirmation issued by Zowasel. No Zowasel
              Marketplace transaction is binding on Zowasel until such written
              confirmation is issued by Zowasel, which may be issued or not
              issued at Zowasel's sole discretion. For the avoidance of doubt,
              on the Marketplace Platform, a Grower Bid may be referenced to as
              a "Bid" and a Grower Offer may be referenced to as an "Offer" or
              any other terms as may be adopted by Zowasel, and the use of such
              alternative terms will not relieve You of the terms, conditions,
              or obligations relating to such Grower Bid or Grower Offer
              outlined in these Terms and Conditions or any other agreement
              between You and Zowasel.
            </li>
            <li>
              Terms Applicable to Buyers. The following provisions contained in
              this Section 6 apply to the extent You participate in the Zowasel
              Marketplace as a buyer or a potential buyer of crops. From time to
              time, You may submit a bid to purchase from Zowasel (a "Buyer
              Bid"), or Zowasel may present an offer to sell to You (a "Buyer
              Offer"), a certain quantity of a crop of a certain quality. Any
              additional terms or conditions, including without limitation
              price, quality specifications, and additional services, will be
              included In the Buyer Bid or Buyer Offer, which shall be on the
              applicable Zowasel Marketplace form provided by Zowasel. Each
              Buyer Bid will remain open for the earlier of (i) twenty-four (24)
              hours from submission or (ii) thirty (30) minutes following
              Zowasel's receipt of Your cancellation (the "Bid Period"). Once a
              Buyer Bid is placed with Zowasel, You irrevocably commit to
              purchase any quantity of the crop that meets the terms of Your
              Buyer Bid that Zowasel Can commit to filling during the Bid
              Period. For the avoidance of doubt, Zowasel has full and sole
              discretion to either accept or reject a Buyer Bid, or any portion
              thereof. Each Buyer Offer will remain open until the time at which
              Zowasel issues a cancellation of the Buyer Offer. For
              clarification, any Buyer Offer can be canceled by Zowasel at any
              time before Zowasel's receipt of Your formal acceptance of a Buyer
              Offer. If You successfully accept a Buyer Offer or Zowasel accepts
              a Buyer Bid, such acceptance will be reflected in a binding
              written confirmation issued by Zowasel. No Zowasel Marketplace
              transaction is binding on Zowasel until such written confirmation
              is issued by Zowasel, which may be issued or not issued at
              Zowasel's sole discretion. For the avoidance of doubt, on the
              Marketplace Platform, a Buyer Bid may be referenced to as a "Bid"
              and a Buyer Offer may be referenced to as an "Offer" or any other
              terms as may be adopted by Zowasel, and the use of such
              alternative terms will not relieve You of the terms, conditions,
              or obligations relating to such Buyer Bid or Buyer Offer outlined
              in these Terms and Conditions or any other agreement between You
              and Zowasel.
            </li>
            <li>
              Cancellations; Suspensions. To ensure the integrity of the Zowasel
              Marketplace and the Marketplace Platform, Zowasel may, in its sole
              and absolute discretion, based on numerous factors, including,
              without limitation, logistics, past experience and performance,
              creditworthiness, qualifications, or any other relevant
              consideration, cancel or terminate any Grower Offer, Grower Bid,
              Buyer Offer or Buyer Bid, or suspend or terminate the
              participation of any user of the Marketplace Platform or
              participant in the Zowasel Marketplace.
            </li>
            <li>
              Crop Sampling. To the extent You are a seller of crops on the
              Zowasel Marketplace, to ensure the proper functioning of the
              Zowasel Marketplace, You agree to (i) provide, upon Zowasel’s
              request, a crop sample indicative of the crop enrolled in the
              Zowasel Marketplace for quality testing; and (ii) following
              quality testing, use best efforts to keep the portion of Your crop
              that was tested segregated from non-test crops, and notify Zowasel
              if part, or all, of the such crop, is otherwise disposed or
              commingled. You acknowledge that the results of these tests may be
              shared. If you receive from Zowasel information about results from
              Your tests or the tests of other crops, You acknowledge that you
              will not rely upon such information and it is intended for
              indicative purposes only.
            </li>
            <li>
              Updates. Zowasel may in its sole discretion make unscheduled
              deployments of changes, updates, or enhancements to the
              Marketplace Platform at any time, add or remove functionality or
              features, and may discontinue any or all of the Marketplace
              Platform altogether, but Zowasel is under no obligation to update
              the Marketplace Platform.
            </li>
            <li>
              Violations. We may prevent Your use of and access to, the
              Marketplace Platform or may prevent any phone number you use from
              communicating via SMS or any other means, at any time in our sole
              discretion for any or no reason, including if, in our opinion, you
              have violated any provision of these Terms of Use.
            </li>
            <li>
              Your Responsibilities. You are responsible for Your use (and the
              use of Your agents and representatives) of the Marketplace
              Platform and Website and You must: (i) use a secure and
              non-obvious password to access them; (ii) treat the user name, and
              password and any other piece of information you receive as part of
              our security procedures as confidential and not share them with
              any other person; (iii) notify us immediately of any unauthorized
              access to or use of Your user name or password or any other breach
              of security; (iii) periodically change your password; (iv) log out
              after each session; (v) install the most recent security patches
              and updates for Your web browser; and (vi) only use the
              Marketplace Platform or Website for the sole purpose of soliciting
              and entering into bonafide transactions on the Marketplace
              Platform or Website. You are solely responsible for ensuring the
              security of Your account passwords. You will be solely responsible
              for any use of or action taken through the use of such passwords
              on the Marketplace Platform and Website. You agree that You are
              bound by, and Zowasel may rely on, any action taken by any
              individual that is logged in using the correct credentials on Your
              account. You further agree that You will not: (a) alter, corrupt,
              hack, or violate any computer code affecting the security and
              access controls of the Marketplace Platform or Website; (b)
              knowingly submit false, misleading, or deceptive information for
              any purpose; (c) use the Marketplace Platform or the Website for
              fraudulent purposes or to engage in any offense, indecent or
              objectionable conduct; (d) distribute, sell, assign, encumber,
              transfer, rent, lease, loan, sublicense or otherwise exploit the
              Marketplace Platform or Website in any unauthorized manner; (e)
              copy, reproduce, adapt, localize, translate, create any derivative
              works of, port or otherwise modify the Marketplace Platform, the
              Website or any part thereof in any form or manner or by any means;
              (f) harvest or scrape any content or data from the Marketplace
              Platform or the Website; (g) decompile, disassemble, reverse
              compile, reserve assemble, reverse translate or otherwise reverse
              engineer any part of the Marketplace Platform or the Website; (h)
              circumvent any functionality that controls access to or otherwise
              protects the Marketplace Platform or the Website; or (i) permit or
              solicit any third party to engage in any of the foregoing.
            </li>
            <li>
              Privacy. To use the Marketplace Platform or access the Website or
              some of the resources they offer, you may be asked to provide
              certain registration details or other information. You are
              responsible for ensuring that all the information you provide to
              us on the Website is correct, current, and complete. All
              information we collect, including all information you provide to
              register or otherwise, including but not limited to the use of any
              interactive features, is handled by and explained in further
              detail in our Privacy Policy located at https://www.zowasel.com.
              You also may have certain rights about the information we collect
              under applicable law as set out in our Privacy Policy.
            </li>
            <li>
              Other Sites and Resources. If the Website contains links to other
              sites and resources provided by third parties, these links are
              provided for your convenience only including any links contained
              in advertisements, banner advertisements, and sponsored links. We
              have no control over the contents of those sites or resources and
              accept no responsibility for them or for any loss or damage that
              may arise from your use of them. If you decide to access any of
              the third-party websites linked to this Website, you do so
              entirely at your own risk and subject to the terms and conditions
              of use for such websites.
            </li>
            <li>
              Proprietary Rights. The Marketplace Platform, Website and their
              entire contents, data, features, and functionality (including but
              not limited to their "look and feel" and all information,
              software, text, displays, logos, images, video, and audio, and the
              design, selection, and arrangement thereof), and any
              modifications, improvements, enhancements, or updates thereof, are
              owned by Zowasel, its licensors, or other providers of such
              material and are protected by the United States and international
              copyright, trademark, patent, trade secret, and other intellectual
              property or proprietary rights laws. Any suggestions for
              modifications or improvements to the Marketplace Platform or the
              Website ("Feedback") are and will remain the property of Zowasel.
              Zowasel may use any Feedback in any manner and for any purpose
              whatsoever without further notice or compensation to You and
              without any retention by You of any proprietary right or claim to
              the Feedback. You agree to assign and do hereby assign, to Zowasel
              any right, title, and interest You may have in any Feedback.
              Subject to your compliance with these Terms of Use, Zowasel grants
              to you a personal, nonexclusive, non-transferable, revocable,
              non-sublicensable limited license to access and use the
              Marketplace Platform and Website for the sole purpose of
              soliciting and entering into bonafide transactions on the
              Marketplace Platform or Website. Except as expressly authorized by
              Zowasel, you agree not to sell, copy, reproduce, modify,
              translate, publish, broadcast, transmit, distribute, perform
              upload, display, license, sell, create derivative works based on
              the Marketplace Platform or Website, or otherwise exploit for any
              purpose the Marketplace Platform or Website, in whole or in part.
              The limited rights granted to You comprise a limited license and
              do not constitute the sale of any software program or any other
              intellectual property.
            </li>
            <li>
              Disclaimer of Warranties. NEITHER Zowasel NOR ANY AFFILIATE OF
              Zowasel (each a "ZOWASEL INC. ENTITY") NOR ANY OF THEIR RESPECTIVE
              OFFICERS, DIRECTORS, MEMBERS, EMPLOYEES, AGENTS, CONSULTANTS OR
              LICENSORS (each a "RELATED PARTY") MAKES ANY REPRESENTATIONS OR
              WARRANTIES concerning THE MARKETPLACE PLATFORM OR WEBSITE AND EACH
              HEREBY DISCLAIMS AND SHALL HAVE NO LIABILITY FOR ALL
              REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED, INCLUDING
              WITHOUT LIMITATION TO THE MERCHANTABILITY, QUALITY OF THE
              MARKETPLACE PLATFORM OR WEBSITE OR THEIR FITNESS FOR A PARTICULAR
              PURPOSE, UNINTERRUPTED SERVICE OR ERROR-FREE SERVICE, OR THE
              SEQUENCE, TIMELINESS, ACCURACY OR COMPLETENESS OF THE MARKETPLACE
              PLATFORM AND WEBSITE. THE MARKETPLACE PLATFORM AND WEBSITE ARE
              PROVIDED ON AN "AS IS" BASIS AT YOUR SOLE RISK. YOU ACKNOWLEDGE
              THAT: (I) YOU MAY EXPERIENCE INTERRUPTIONS OR ERRORS IN THE
              MARKETPLACE PLATFORM AND WEBSITE; AND (II) THE MARKETPLACE
              PLATFORM AND WEBSITE MAY, FROM TIME TO TIME, BE TEMPORARILY
              UNAVAILABLE.
            </li>
            <li>
              Exclusion of Incidental, Consequential, and Certain Other
              Remedies. TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO
              CIRCUMSTANCES SHALL ANY ZOWASEL INC. OR ANY RELATED PARTIES BE
              LIABLE HEREUNDER TO YOU OR TO OTHERS DIRECTLY OR INDIRECTLY MAKING
              USE OF THE MARKETPLACE PLATFORM OR WEBSITE, FOR ANY LOST PROFITS,
              INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES,
              ARISING UNDER THESE TERMS OF USE, EVEN IF ZOWASEL HAS BEEN ADVISED
              OF THE POSSIBILITY THEREOF AND EVEN IF DUE TO ZOWASELl'S ERROR,
              OMISSION, OR NEGLIGENCE.
            </li>
            <li>
              Limitation of Liability and Remedies. TO THE MAXIMUM EXTENT
              ALLOWABLE BY LAW, IN NO CIRCUMSTANCES SHALL ANY ZOWASEL INC. OR
              ANY RELATED PARTIES BE LIABLE FOR ANY (A) DELAY, INACCURACIES,
              ERRORS, OMISSIONS OR INTERRUPTION OF ANY KIND ABOUT THE
              MARKETPLACE PLATFORM OR WEBSITE OR FOR ANY RESULTING LOSS OR
              DAMAGE; OR (B) LOSS OR DAMAGE ARISING FROM UNAUTHORIZED ACCESS TO
              OR MISUSE OF THE MARKETPLACE PLATFORM OR WEBSITE. TO THE MAXIMUM
              EXTENT PERMITTED BY LAW, IN NO EVENT SHALL THE AGGREGATE LIABILITY
              OF ANY ZOWASEL INC. OR ANY RELATED PARTIES HEREUNDER EXCEED THE
              LESSER OF: (A) THE FEES PAID TO ZOWASEL INC. BY THE LICENSEE
              HEREUNDER IN THE TWELVE (12) MONTHS IMMEDIATELY PRECEDING THE
              EVENT GIVING RISE TO THE CLAIM; OR (B) FIFTY US DOLLARS ($50),
              REGARDLESS OF WHETHER SUCH DAMAGES ARE BASED IN CONTRACT, TORT,
              STRICT LIABILITY, OR OTHERWISE. THIS LIMITATION SHALL SURVIVE THE
              FAILURE OF THE ESSENTIAL PURPOSE OF ANY REMEDIES THAT MAY BE
              PROVIDED IN THIS AGREEMENT.
            </li>
            <li>
              Third-Party Access Rights; Unauthorized Access. Access to and use
              of the Marketplace Platform and Website is restricted to
              authorized users only. If You grant third parties (including any
              of Your directors, officers, employees, consultants, advisors,
              agents or other representatives) access to Your account or take
              any action on Your behalf through Your account, You are solely
              responsible for the validity of such individuals' access to Your
              Account, including the provision of valid and accurate e-mail
              addresses and log-in credentials and the applicable permissions
              for such individuals, and for all actions taken by such
              individuals through Your account. Unauthorized individuals
              attempting to access the Marketplace Platform and Website may be
              subject to prosecution.
            </li>
            <li>
              Notice. Notice to you may be made via email, fax, or certified or
              registered mail, return receipt requested, to your most current
              address on record. Zowasel may also provide notices of changes to
              these Terms of Use or other matters by providing a click-through
              of the screen on the Systems. At all times, You are responsible
              for providing Zowasel with a valid e-mail address to which notices
              may be sent. Any notice sent to the e-mail address on file with
              Zowasel will be deemed to be noticed properly and given to You.
              You may give notice to Zowasel by email at legal@Zowasel.com, or
              by certified or registered mail, return receipt requested to our
              office.
            </li>
            <li>
              Modification of Terms. Zowasel may change these Terms of Use at
              any time by either (i) posting the revised terms on the Website or
              the Marketplace Platform; (ii) sending you an email notification
              to the email address that you provided to Zowasel as part of your
              account registration, or notification via SMS or other messaging
              services, or (iii) presenting the revised Terms of Use to you when
              you log into the Website or the Marketplace Platform. The updated
              Terms of Use will become effective the earlier of (a) when you
              accept them online, (b) ten (10) days after Zowasel posts or
              emails the update, in which case your continued use of any of the
              Marketplace Platform or Website or failure to cancel your account
              will indicate your acceptance of the amended Terms of Use. These
              Terms of Use and any other Zowasel Marketplace agreement You have
              entered into with Zowasel ("Zowasel Agreements") shall supersede
              any other agreements, written or oral, between You and Zowasel
              ("Other Agreements"). To the extent of any conflict between the
              provisions in any Zowasel Agreement and any Other Agreements, the
              terms of the Zowasel Agreement shall control.
            </li>
            <li>
              Opt-Out. You may opt out of receiving text messages from Zowasel's
              shortcode by sending the keyword STOP, STOPALL, UNSUBSCRIBE,
              CANCEL or END to Zowasel's shortcode.
            </li>
            <li>
              Compliance. You represent that it is not engaged in, and agrees
              not to engage in, any unlawful transaction or business, and agrees
              not to use or knowingly permit anyone to use Zowasel Marketplace,
              Systems or Services for (a) any purpose or in any manner not
              authorized by these Terms of Use or (b) for any unlawful purpose
              or in any manner not in compliance with applicable laws, rules, or
              regulations of any federal, state, or local governmental entity of
              the United States or any foreign country, including all United
              States export laws.
            </li>
            <li>
              Customer Support. To contact Zowasel Customer Support, please
              email hello@Zowasel.com
            </li>
            <li>
              General Terms. The Terms of Use are the complete and exclusive
              statement of the agreement between the parties and supersede all
              prior agreements, oral or written, and all other communications
              between the parties concerning the subject matter of this Terms of
              Use. The failure of either party to exercise any right or remedy
              under this Terms of Use or at law shall not prevent any further
              exercise of that right or remedy. You may not assign this Terms of
              Use without the prior written consent of Zowasel. If any part,
              term or provision of this Agreement is held illegal, invalid or
              unenforceable, the validity or enforceability of the remainder of
              the Agreement shall not be affected. These Terms of Use and your
              use of the Marketplace Platform and Website shall be governed by
              the laws of the State of Delaware, without regard to its conflicts
              of laws principles. You agree to the personal jurisdiction of the
              state and federal courts located in the State of Delaware. The
              prevailing party in any litigation between the parties arising out
              of this Terms of Use or any Zowasel Agreements shall be entitled
              to recover, in addition to any other relief awarded or granted,
              its costs and expenses (including reasonable attorneys' fees)
              incurred in any such litigation. Except as otherwise expressly set
              forth herein, the terms and conditions set forth in these Terms of
              Use shall survive the termination of the Marketplace Platform,
              Website or any part thereof. The headings and captions contained
              herein are for convenience only and shall not be used to interpret
              or construe any provision of this Terms of Use.
            </li>
          </ol>
        </div>
        <div class="modal-footer justify-content-center">
          <button type="button" class="btn btn-success" data-bs-dismiss="modal">
            Understood
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthSharedLayout from "@/layouts/shared/AuthSharedLayout.vue";
export default {
  name: "SignUp",
  components: {
    AuthSharedLayout,
  },
  data() {
    return {
      type: this.$route.params.type,
      phoneNumber: "", // The data property to store the phone number value
    };
  },
  methods: {
    validateNumericInput() {
      // Remove any non-numeric characters from the phone number
      this.phoneNumber = this.phoneNumber.replace(/\D/g, "");
    },
    submitForm(e) {
      var form = new FormData(document.querySelector("#register-form"));

      var registerData = {
        first_name: form.get("first-name"),
        last_name: form.get("last-name"),
        email: form.get("email"),
        phone: form.get("phone"),
      };

      window.localStorage.setItem("registerData", JSON.stringify(registerData));

      var accountType = form.get("account-type");

      if (this.type == "partner") {
        accountType = "company";
      }

      accountType == "individual"
        ? this.$router.push(`/${this.type}/verifyaccount`)
        : this.$router.push(`/${this.type}/companydetails`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.radios-container {
  margin-top: 20px;
}
.check-p span {
  color: #047b38;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.33px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    text-decoration-color: #047b38;
  }
}
.modal-content {
  width: 40vw;
}
.radios {
  flex: 1;
  display: flex;
  align-items: center;

  input {
    margin-top: 1px;
  }
}
.radios label {
  margin: 0px 10px;
}

.checking p {
  margin: 0px 10px;
}

.checking input {
  margin-top: 1px;
}
.login-text {
  color: green;
}
</style>
