<template>
  <DefaultNav>
    <div class="big-container pb-5">
      <form class="mt-4" @submit.prevent="saveData()">
        <div class="headings">
          <h2>Add Loan Type</h2>
          <div class="right-btns">
            <a href="/dashboard/loantype" class="yellow-link"
              >View Loan Types</a
            >
            <button class="green-link">Save</button>
          </div>
        </div>
        <div v-if="editing == false" class="mb-3">
          <h6>
            <strong>* All fields are required</strong>
          </h6>
        </div>
        <div class="row">
          <div class="col mb-4">
            <label for="">
              <span v-if="editing == false" class="text-danger">*</span> Loan
              Type Title
            </label>
            <input
              v-model="typename"
              type="text"
              class="form-control"
              placeholder="Enter your answer"
              required
            />
          </div>
          <div class="col">
            <div class="row">
              <div class="col mb-4">
                <label for="">
                  <span v-if="editing == false" class="text-danger">*</span>
                  Loan Amount <em>(Min)</em>
                </label>
                <input
                  v-model="loanamountmin"
                  type="number"
                  class="form-control"
                  placeholder="Enter your answer"
                  min="0"
                  required
                />
              </div>
              <div class="col mb-4">
                <label for="">
                  <span v-if="editing == false" class="text-danger">*</span>
                  Loan Amount <em>(Max)</em>
                </label>
                <input
                  v-model="loanamountmax"
                  type="number"
                  min="0"
                  class="form-control"
                  placeholder="Enter your answer"
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col mb-4">
            <label for="">
              <span v-if="editing == false" class="text-danger">*</span>
              Repayment Type
            </label>
            <select v-model="repaymenttype" class="form-select" required>
              <option value="">Select repayment type</option>
              <option value="once">One Time</option>
              <option value="installments">Installments</option>
            </select>
          </div>
          <div class="col mb-4">
            <div class="row">
              <div class="col">
                <label for="">
                  <span v-if="editing == false" class="text-danger">*</span>
                  Repayment Duration
                  <span v-if="repaymenttype == 'installments'">(Min)</span>
                </label>
                <select v-model="durationmin" class="form-select" required>
                  <option value="">Select payment type</option>
                  <option value="week(s)">Weekly</option>
                  <option value="month(s)">Monthly</option>
                </select>
              </div>
              <div class="col">
                <label for="">
                  <span v-if="editing == false" class="text-danger">*</span>
                  Repayment Type
                  <span v-if="repaymenttype == 'installments'">(Min)</span>
                </label>
                <input
                  v-model="rtmin"
                  type="number"
                  class="form-control"
                  placeholder="0"
                  min="0"
                  max="40000"
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div v-if="repaymenttype == 'installments'" class="col mb-4">
            <div class="row">
              <div class="col">
                <label for="">
                  <span v-if="editing == false" class="text-danger">*</span>
                  Repayment Duration (Max)
                </label>
                <select v-model="durationmax" class="form-select" required>
                  <option value="">Select payment type</option>
                  <option value="week(s)">Weekly</option>
                  <option value="month(s)">Monthly</option>
                </select>
              </div>
              <div class="col">
                <label for="">
                  <span v-if="editing == false" class="text-danger">*</span>
                  Repayment Type (Max)
                </label>
                <input
                  v-model="rtmax"
                  type="number"
                  class="form-control"
                  placeholder="0"
                  min="0"
                  max="40000"
                  required
                />
              </div>
            </div>
          </div>
          <div class="col mb-4">
            <label for="">
              <span v-if="editing == false" class="text-danger">*</span>
              Loan Interest
            </label>
            <input
              v-model="interest"
              type="number"
              class="form-control"
              placeholder="Enter your answer"
              required
            />
          </div>
        </div>
        <div class="row">
          <div class="col mb-4">
            <label for="">
              <span v-if="editing == false" class="text-danger">*</span>
              Loan Fine (Late repayment)
            </label>
            <input
              v-model="fine"
              type="number"
              class="form-control"
              placeholder="Enter your answer"
              required
            />
          </div>
        </div>
        <div class="col mb-4">
          <label for="">
            <span v-if="editing == false" class="text-danger">*</span> Loan Type
            Description
          </label>
          <input
            v-model="description"
            class="d-none ms-5"
            type="text"
            name="description"
          />
          <div ref="editor" @text-change="handleContentChange" />
        </div>
      </form>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";

import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.snow.css";
// Alert box
import Preloader from "@/layouts/shared/Preloader.vue";
import LoanServices from "@/services/loan";
import Alert from "@/utilities/alert";
// import { v4 as uuidv4 } from "uuid";

export default {
  name: "AddLoanType",
  components: {
    DefaultNav,
    Preloader,
  },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      userid: "",
      loantypeid: "",
      typename: "",
      loanamountmin: "",
      loanamountmax: "",
      repaymenttype: "",
      durationmin: "",
      durationmax: "",
      rtmin: "",
      rtmax: "",
      interest: "",
      fine: "",
      description: "",
      editData: [],
      editing: false,
      farmerEditId: "",
      editor: null,
    };
  },
  mounted() {
    var _this = this;

    this.editor = new Quill(this.$refs.editor, {
      modules: {
        toolbar: [
          [
            {
              header: [1, 2, 3, 4, false],
            },
          ],
          ["bold", "italic", "underline", "link"],
        ],
      },
      //theme: 'bubble',
      theme: "snow",
      formats: ["bold", "underline", "header", "italic", "link"],
      placeholder: "Type description!",
    });
    this.editor.root.innerHTML = this.modelValue;
    this.editor.on("text-change", function () {
      _this.handleContentChange();
      return _this.update();
    });
    // const url = window.location.href;
    // this.isLoading = true;

    // if (url.split("/").slice(-1)[0] !== "add") {
    //   this.loantypeid = url.split("/").slice(-2)[0];

    //   LoanServices.getLoanTypeById(this.loantypeid, (response) => {
    //     setTimeout(() => {
    //       this.isLoading = false;
    //       if (response.data == null) {
    //         this.editing = false;

    //         window.location.href = "/dashboard/loantype";
    //       } else {
    //         this.editing = true;
    //         this.editData.push(response.data);

    //         this.typename = this.editData[0].loantypename;
    //         this.typedescription = this.editData[0].loandescription;
    //         this.loanconditions = this.editData[0].loanconditions;
    //         this.loanminamount = this.editData[0].minissueableamount;
    //         this.loanmaxamount = this.editData[0].maxissuableamount;
    //         this.loanduration = this.editData[0].repaymentduration;
    //         this.loaninterest = this.editData[0].interestrateinpercent;
    //       }
    //     }, 3000);
    //   });
    // } else {
    //   this.loantypeid = `LT-${uuidv4()}`;
    //   this.isLoading = false;
    //   this.editing = false;
    // }
  },
  methods: {
    async saveData() {
      const uid = JSON.stringify(
        JSON.parse(localStorage.getItem("Zowasel")).user.user_id,
      );
      this.userid = uid;
      let mdur, mxdur;
      if (this.repaymenttype !== "installments") {
        mxdur = `${this.rtmin} ${this.durationmin}`;
        mdur = "";
      } else {
        mdur = `${this.rtmin} ${this.durationmin}`;
        mxdur = `${this.rtmax} ${this.durationmax}`;
      }

      this.isLoading = true;
      if (this.editing == true) {
        let vm = this;
        const dt = {
          userid: this.userid,
          loantype: this.typename,
          minAmount: this.loanamountmin,
          maxAmount: this.loanamountmax,
          repaymentType: this.repaymenttype,
          minDuration: mdur,
          maxDuration: mxdur,
          interest: this.interest,
          fine: this.fine,
          purpose: this.description,
        };
        LoanServices.updateLoanType(vm.loantypeid, dt, (data) => {
          /* ------------------- check if it returned with an error ------------------- */
          if (data.status == "200") {
            if (data.data.error == "false") {
              Alert.success({
                message: data.data.message,
              });
              setTimeout(() => {
                window.location.href = "/dashboard/loantype";
              }, 2000);
            } else {
              Alert.error({
                message: "Failed to complete the request at the moment",
              });
            }
          } else {
            Alert.error({
              message: data[1].message,
            });
          }
          this.isLoading = false;
        });
      } else {
        let vm = this;
        const dt = {
          userid: this.userid,
          loantype: this.typename,
          minAmount: this.loanamountmin,
          maxAmount: this.loanamountmax,
          repaymentType: this.repaymenttype,
          minDuration: mdur,
          maxDuration: mxdur,
          interest: `${this.interest}%`,
          fine: this.fine,
          purpose: this.description,
        };
        LoanServices.createLoanType(dt, (data) => {
          console.log(data, data.status, data.data);
          /* ------------------- check if it returned with an error ------------------- */
          if (data.status == 200) {
            if (data.data.error == false) {
              Alert.success({
                message: data.data.message,
              });
              setTimeout(() => {
                window.location.href = "/dashboard/loantype";
              }, 2000);
            } else {
              Alert.error({
                message: "Failed to complete the request at the moment",
              });
            }
          } else {
            Alert.error({
              message: data.data.message,
            });
          }
          this.isLoading = false;
        });
      }
    },
    update: function update() {
      this.$emit(
        "update:modelValue",
        this.editor.getText() ? this.editor.root.innerHTML : "",
      );
    },
    handleContentChange() {
      this.description = this.editor.root.innerHTML;
    },
  },
};
</script>

<style lang="scss" scoped>
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 2% 5% 2% 3%;
  overflow-y: scroll;

  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}

.active-subscriptions {
  background-color: white;
  padding: 24px 65px;
  margin-top: 40px;

  h2 {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #696671;
  }
}

/* The slider */
.sub-history {
  margin-top: 50px;
  width: 100%;
  margin-left: 10px;

  h2 {
    margin-bottom: 40px;
  }

  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #4a4754;
  }
}

.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  a {
    text-decoration: none;
  }

  .right-btns {
    display: flex;
    gap: 20px;
  }

  .green-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 145px;
    height: 40px;
    background: #05b050;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
  }
}

.green-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 145px;
  height: 40px;
  background: #05b050;
  border-radius: 5px;
  letter-spacing: 0.02em;
}

.yellow-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  width: 145px;
  height: 40px;
  background: #fab900 !important;
  border-radius: 5px;
  letter-spacing: 0.02em;
}

a {
  text-decoration: none;
}

.small-font {
  font-size: 11px !important;
}

.editor-wrapper {
  padding-bottom: 50px;

  .ql-container {
    min-height: 50vh;
  }
}
</style>
