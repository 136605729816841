<template>
  <DefaultNav>
    <div class="big-container">
      <div class="contents">
        <div class="top-left">
          <div class="main-top">
            <div class="left-main">
              <p>Farmer Wallet</p>
            </div>
            <div class="tabs-section">
              <button
                :class="['tabs-button', tab == 1 ? 'active' : '']"
                @click="changeTab(1)"
              >
                Account Summary
              </button>
              <button
                :class="['tabs-button', tab == 2 ? 'active' : '']"
                @click="changeTab(2)"
              >
                Account Details
              </button>
            </div>
          </div>
          <div
            v-if="acct"
            id="first"
            :class="['virtual-wallet', walletType == 1 ? 'active-wallet' : '']"
          >
            <div class="go-row">
              <h3>Account Balance</h3>
              <h1><span>Balance</span> NGN {{ acct.balance }}</h1>
            </div>
            <div class="acct-num-side">
              <h3>Account Number</h3>
              <h1>{{ acct.account_number }}</h1>
              <p>
                {{
                  farmerData.farmer.firstname + " " + farmerData.farmer.lastname
                }}
              </p>
            </div>
          </div>
        </div>
        <div v-if="walletType == 1" class="virtual-details">
          <div v-if="tab == 1" class="table-wrapper">
            <table class="table-borderless sub-history">
              <tbody class="scrollable">
                <tr>
                  <th>Account</th>
                  <th>Loan Duration</th>
                  <th>Loan Amount</th>
                  <th>Disbursement Date</th>
                </tr>
                <tr v-for="request in loanRequests" :key="request.id">
                  <td>
                    {{ request.account_number }}
                  </td>
                  <td>{{ request.duration }}</td>
                  <td>{{ request.amount }}</td>
                  <td>NGN {{ request.created_at.split("T")[0] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="tab == 2" class="account-details">
            <div class="top-row">
              <div class="each-card">
                <h4>Account Name</h4>
                <p>
                  {{
                    farmerData.farmer.firstname +
                    " " +
                    farmerData.farmer.lastname
                  }}
                </p>
              </div>
              <div class="each-card">
                <h4>Account Number</h4>
                <p>{{ acct.account_number }}</p>
              </div>
              <div class="each-card">
                <h4>Date Of Creation</h4>
                <p>{{ acct.created_at }}</p>
              </div>
              <div class="each-card no-border">
                <h4>Transactions</h4>
                <p>0</p>
              </div>
            </div>
            <div class="bottom-row">
              <div class="each-card">
                <h4>Total Deposits</h4>
                <p>NGN 0.00</p>
              </div>
              <div class="each-card inflow">
                <h4>Inflow</h4>
                <p>NGN 0.00</p>
              </div>
              <div class="each-card outflow">
                <h4>Outflow</h4>
                <p>NGN 0.00</p>
              </div>
              <div class="each-card no-border"></div>
            </div>
          </div>
          <div v-if="tab == 3" class="table-wrapper">
            <table class="table-borderless sub-history">
              <tbody class="scrollable">
                <tr>
                  <th>Transaction ID</th>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Amount</th>
                </tr>
              </tbody>
            </table>
            <div class="center-tab">
              <h2 class="text-center">Please verify BVN to activate wallet</h2>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Verify BVN
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Button trigger modal -->

    <!-- Modal -->
    <div
      id="exampleModal"
      class="modal fade"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">Verify BVN</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form @submit.prevent="proceed">
            <div class="modal-body">
              <input
                v-model="bvn"
                type="text"
                placeholder="Enter BVN to verify"
                class="form-control"
                required
              />
            </div>
            <div class="modal-footer">
              <button
                id="closeModal"
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                @click="releaseWallet()"
              >
                Verify
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import LoanService from "@/services/loan";
import WalletService from "@/services/wallet";
import Preloader from "@/layouts/shared/Preloader.vue";
import Alert from "@/utilities/alert";
export default {
  name: "Wallet",
  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      userData: this.$store.state.user,
      tab: 4,
      vTab: 1,
      walletType: 1,
      vfdWallet: null,
      loanRequests: [],
      farmerData: JSON.parse(localStorage.getItem("activeFarmerData")),
      acct: null,
      bvn: "",
      isLoading: false,
    };
  },
  computed: {
    walletStatus() {
      return this.acct && this.acct.pnd_status == 0;
    },
  },
  mounted() {
    this.getVWallet();
    this.isLoading = true;
    this.setupIgreeSocket();
    if (this.userData.user.type != "partner") {
      const uid = localStorage.getItem("farmerID");
      LoanService.getVloanhistory(uid, (response) => {
        // console.log(response);
        if (response.error == false) {
          this.loanRequests = response.data;
          this.paginate(response.data.reverse(), "loanRequests");
          this.isLoading = false;
        } else {
          this.loanRequests = [];
          this.isLoading = false;
        }
      });
    } else {
      LoanService.getAllLoanRequests((response) => {
        if (response.error == false) {
          console.log(response.data);
          this.loanRequests = response.data;
          this.paginate(this.loanRequests.reverse(), "loanRequests");
          this.isLoading = false;
        } else {
          this.loanRequests = [];
          this.isLoading = false;
        }
      });
    }

    // document.getElementById("checked").checked = true;
  },
  methods: {
    getVWallet() {
      let vm = this;
      const farmerid = localStorage.getItem("farmerID");
      LoanService.getVwallet("farmer", farmerid, (response) => {
        vm.fetchedAcct = true;
        // console.log(response);
        if (response && response.data) {
          vm.acct = response.data.data;
          this.tab = this.walletStatus ? 1 : 3;
          this.getLH();
        }
      });
    },

    checkReleaseStatus() {
      this.isLoading = true;
      WalletService.releaseVWallet(
        {
          account: this.acct.account_number,
          bvn: this.bvn,
        },
        (response) => {
          if (response.error) {
            Alert.error({
              message: "Consent not given",
            });
          } else {
            Alert.success({
              message: "Consent given",
            });
            this.acct.pnd_status = 0;
            this.tab = 1;
          }
          this.isLoading = false;
        },
      );
    },

    releaseWallet() {
      this.isLoading = true;
      WalletService.releaseVWallet(
        {
          account: this.acct.account_number,
          bvn: this.bvn,
        },
        (response) => {
          $("#exampleModal").modal("hide");
          if (response.error) {
            WalletService.consentVWallet(
              {
                bvn: this.bvn,
              },
              (response) => {
                const link = response.data.url;
                if (link) {
                  window.open(link, "_blank");
                  this.isLoading = false;
                  $("#exampleModal").modal("hide");
                } else {
                  this.isLoading = false;
                  $("#exampleModal").modal("hide");
                  Alert.error({
                    message: "Service temporarily not available, try again.",
                  });
                }
              },
            );
          } else {
            this.acct.pnd_status = 0;
            this.tab = 1;
            this.isLoading = false;
          }
        },
      );
    },
    changeTab(tab) {
      if (this.walletStatus) {
        this.tab = tab;
      }
    },
    activetab(vTab) {
      this.vTab = vTab;
    },
    changeWallet(e, walletType) {
      var selectedWallet = e.target;
      var inpBox = selectedWallet.nextElementSibling;
      var inp = inpBox.firstChild;
      inp.checked = true;
      this.walletType = walletType;
    },
    formatDate(date) {
      var dateObj = new Date(date);
      var dateString = dateObj.toDateString();
      return dateString.split(" ").slice(1).join(" ");
    },
    setupIgreeSocket() {
      let vm = this;
      // Listen for Igree consent notification
      window.AppSocket.on("Igree.Consent", (data) => {
        if (
          data &&
          data.type == "farmer" &&
          vm.acct.account_number == data.number
        ) {
          Alert.success({
            message: "Consent received and account activated",
          });
          vm.acct.pnd_status = 0;
          vm.tab = 1;
        }
      });
      //---------------------------------------
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/shared/Styles";
.active {
  background: rgba(118, 118, 128, 0.12);
  border-radius: 8.91px;
  color: white;
}

.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: row;
  padding: 2% 5% 2% 3%;
  gap: 30px;
  overflow-y: scroll;
  padding-bottom: 40px;
}

.contents {
  width: 100%;
  margin-top: $default-margin * 1.5;
  .main-top {
    display: flex;
    justify-content: space-between;
  }

  .top-left {
    display: flex;
    gap: 25px;
    flex-direction: column;
    margin-bottom: $default-margin * 4;

    .virtual-wallet {
      display: flex;
      justify-content: space-between;
      background: #282b3d;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 30px 25px 20px 25px;
      border-radius: 10px;
      width: 100%;
      cursor: pointer;
      position: relative;

      .acct-num-side {
        width: 35%;
        margin-top: 25px;
        border-left: 1px solid white;
        padding-left: 10px;

        h3 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 135%;
        }
        h1 {
          font-family: "Maven Pro";
          font-style: normal;
          font-weight: 700;
          font-size: 40px;
          line-height: 132%;
          margin-left: 30px;
        }
        span {
          font-size: 16px;
        }
      }

      .cover-div {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
      }

      h3 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 135%;
      }
      h1 {
        font-family: "Maven Pro";
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 132%;
        margin-left: 30px;
      }
      span {
        font-size: 16px;
      }
      .buttons-area {
        display: flex;
        justify-content: flex-end;
      }

      .withdraw-btn {
        width: 150px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #c32021;
        border-radius: 5px;
        font-family: "Maven Pro";
        font-style: normal;
        font-weight: 700;
        font-size: 20.6667px;
        color: white;
        text-decoration: none;
        opacity: 0.5;
      }
    }
    .virtual-wallet:hover {
      transform: scale(1.05);
    }
  }
}
.active-wallet {
  background-image: url("@/assets/images/backgrounds/farmerback.png") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  color: white !important;

  .withdraw-btn {
    opacity: 1 !important;
  }
}
.right-side {
  width: 35%;
  background: $zowasel-white;
  padding: $default-padding * 2;
  margin-top: $default-margin * 1.5;

  h3 {
    margin-top: 30px;
  }
}

.table-wrapper {
  background: $zowasel-white;
  padding: $default-padding * 2;
  margin-top: $default-padding;
}
.sub-history {
  width: 100%;

  h2 {
    margin-bottom: 40px;
  }

  tr {
    padding: 20px 9px;
    gap: 129px;
    height: 66px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  td {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4754;
  }
  .amount {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #4a4754;
  }
}

.chart-section {
  width: 100%;
  padding: 20px;
  background-color: white;

  img {
    width: 100%;
  }

  .texts {
    display: flex;
    gap: 30px;

    .income-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #41be53;
    }

    .expense-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #ffd16a;
    }

    .income,
    .expense {
      display: flex;
      align-items: center;
      gap: 5px;

      p {
        margin: 0px;
      }
    }
  }
}

.tabs-section {
  gap: -10px;
}
.tabs-section button {
  background: rgba(118, 118, 128, 0.12);
  border-radius: 9px;
  height: 50px;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding: 0px 10px;
  color: #262c3f;
  position: relative;
}
.active {
  background: #262c3f !important;
  color: white !important;
  z-index: 10;
}
.flex-row {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-left: 30px;
}

.table-wrapper {
  background: $zowasel-white;
  padding: $default-padding * 2;
  margin-top: $default-padding;
}
.sub-history {
  width: 100%;

  h2 {
    margin-bottom: 40px;
  }

  tr {
    padding: 20px 9px;
    gap: 129px;
    height: 66px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  td {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4754;
  }
  .amount {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #4a4754;
  }
}

.chart-section {
  width: 100%;
  padding: 20px;
  background-color: white;

  img {
    width: 100%;
  }

  .texts {
    display: flex;
    gap: 30px;

    .income-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #41be53;
    }

    .expense-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #ffd16a;
    }

    .income,
    .expense {
      display: flex;
      align-items: center;
      gap: 5px;

      p {
        margin: 0px;
      }
    }
  }
}

h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}
.tabs-section {
  gap: -10px;
  margin-bottom: 30px;
}
.tabs-section button {
  width: 200px;
  background: rgba(118, 118, 128, 0.12);
  border-radius: 9px;
  height: 50px;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding: 0px 10px;
  color: #262c3f;
  position: relative;
}
.active {
  background: #262c3f !important;
  color: white !important;
  z-index: 10;
}

.account-details {
  width: 100%;
  background: #ffffff;
  box-shadow:
    0px 2px 4px rgba(44, 39, 56, 0.08),
    0px 4px 8px rgba(44, 39, 56, 0.08);
  border-radius: 10px;
  padding: 35px;

  .top-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 2px dashed rgba(0, 0, 0, 0.21);

    .each-card {
      width: 25%;
      border-right: 2px dashed rgba(0, 0, 0, 0.21);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 50px;

      h4 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 116%;
        color: #696671;
      }

      p {
        font-family: "Maven Pro";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 147%;
        color: #4a4754;
      }
    }
  }

  .bottom-row {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .each-card {
      width: 25%;
      border-right: 2px dashed rgba(0, 0, 0, 0.21);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 50px;

      h4 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 116%;
        color: #696671;
      }

      p {
        font-family: "Maven Pro";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 147%;
        color: #4a4754;
      }
    }
  }
  .inflow {
    h4 {
      color: #036b31 !important;
    }
    p {
      color: #036b31 !important;
    }
  }
  .outflow {
    h4 {
      color: #891617 !important;
    }
    p {
      color: #891617 !important;
    }
  }

  .no-border {
    border-right: unset !important;
  }
}

.transfer {
  form {
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    padding: 50px;

    button {
      background: #05b050;
      border-radius: 4px;
      width: none;
      border: none;
      width: 100%;
      height: 50px;
      margin-top: 40px;
    }
  }
}
input[type="radio"]:after {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 3px solid white;
}

input[type="radio"]:checked:after {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: relative;
  background-color: #05b050;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 3px solid white;
}
.go-row {
  display: flex;
  gap: 15px;
  flex-direction: column;

  h3 {
    color: white;
    margin-top: 25px;
  }
}
.center-tab {
  display: flex;
  flex-direction: column;
  margin: 100px 0px;
  align-items: center;

  h2 {
    color: #4a4754;
    font-family: Maven Pro;
    font-size: 36px;
    font-weight: 600;
  }

  button {
    width: 150px;
    height: 48px;
    background: rgba(251, 138, 46, 1);
    color: white;
    border: none;
    border-radius: 15px;
    margin-top: 30px;
  }
}
</style>
