<template>
  <div class="main-content">
    <div class="right">
      <div class="top-texts">
        <h3 v-if="view == 'cropauction'">Crop For Auction</h3>
      </div>
      <template v-if="view == 'cropsale'">
        <div v-for="(crop, index) in pageList" :key="index" class="each-item">
          <div class="subcategory_crop">
            <p :class="[crop.specification.qty < 1 ? 'fad' : '']">
              {{ crop.subcategory.name }} - {{ crop.specification.color }}
            </p>
            <span
              v-if="crop.specification.qty < 1"
              class="text-danger fw-bold d-block"
            >
              Sold Out
            </span>
          </div>
          <p :class="[crop.specification.qty < 1 ? 'fad' : '']">
            <b>{{ crop.category.name }}</b>
          </p>
          <p :class="[crop.specification.qty < 1 ? 'fad' : '']">
            {{ crop.currency }} {{ crop.specification.price }}/{{
              crop.specification.test_weight
            }}
          </p>
          <p
            v-if="crop.user.account_type === 'individual'"
            :class="[crop.specification.qty < 1 ? 'fad' : '']"
          >
            {{ crop.user.first_name }}
          </p>
          <p
            v-if="crop.user.account_type === 'company'"
            :class="[crop.specification.qty < 1 ? 'fad' : '']"
          >
            {{ crop.user.company.company_name }}
          </p>
          <p :class="[crop.specification.qty < 1 ? 'fad' : '']">
            <b>
              {{ crop.currency }} {{ crop.specification.price }}/{{
                crop.specification.test_weight
              }}
            </b>
          </p>
          <p :class="[crop.specification.qty < 1 ? 'fad' : '']">
            {{ crop.warehouse_address }}
          </p>
          <button
            :class="[
              'green-btn',
              crop.specification.qty < 1 ? 'bg-danger' : 'green',
            ]"
            @click="
              $router.push({ name: 'CropDetails', params: { id: crop.id } })
            "
          >
            View
          </button>
        </div>

        <!-- Pagination -->
        <nav class="pagination align-items-center gap-2 my-4">
          <button
            :disabled="currentPage === 1"
            class="btn btn-succ fs-small"
            @click="prevPage"
          >
            Previous
          </button>
          <span class="fs-small"
            >Page {{ currentPage }} of {{ totalPages }}</span
          >
          <button
            :disabled="currentPage === totalPages"
            class="btn btn-succ fs-small"
            @click="nextPage"
          >
            Next
          </button>
        </nav>
      </template>
      <template v-if="view == 'cropauction'">
        <div
          v-for="(crop, index) in auctionPageList"
          :key="index"
          class="each-item"
        >
          <div class="subcategory_crop">
            <p :class="[crop.specification.qty < 1 ? 'fad' : '']">
              {{ crop.subcategory.name }} - {{ crop.specification.color }}
            </p>
            <span
              v-if="crop.specification.qty < 1"
              class="text-danger fw-bold d-block"
            >
              Sold Out
            </span>
          </div>
          <p :class="[crop.specification.qty < 1 ? 'fad' : '']">
            <b>{{ crop.category.name }}</b>
          </p>
          <p :class="[crop.specification.qty < 1 ? 'fad' : '']">
            {{ crop.currency }} {{ crop.specification.price }}/{{
              crop.specification.test_weight
            }}
          </p>
          <p
            v-if="crop.user.account_type === 'individual'"
            :class="[crop.specification.qty < 1 ? 'fad' : '']"
          >
            {{ crop.user.first_name }}
          </p>
          <p
            v-if="crop.user.account_type === 'company'"
            :class="[crop.specification.qty < 1 ? 'fad' : '']"
          >
            {{ crop.user.company.company_name }}
          </p>
          <p :class="[crop.specification.qty < 1 ? 'fad' : '']">
            <b>
              {{ crop.currency }} {{ crop.specification.price }}/{{
                crop.specification.test_weight
              }}
            </b>
          </p>
          <p :class="[crop.specification.qty < 1 ? 'fad' : '']">
            {{ crop.warehouse_address }}
          </p>
          <button
            :class="[
              'green-btn',
              crop.specification.qty < 1 ? 'bg-danger' : 'green',
            ]"
            @click="
              $router.push({ name: 'CropDetails', params: { id: crop.id } })
            "
          >
            <span v-if="crop.specification.qty < 1">View</span>
            <span v-else>Bid</span>
          </button>
        </div>
        <!-- pagination -->
        <nav class="pagination align-items-center gap-2 my-4">
          <button
            :disabled="currentPage === 1"
            class="btn btn-succ fs-small"
            @click="prevPage"
          >
            Previous
          </button>
          <span class="fs-small"
            >Page {{ currentPage }} of {{ inputTotalPages }}</span
          >
          <button
            :disabled="currentPage === inputTotalPages"
            class="btn btn-succ fs-small"
            @click="inputNextPage"
          >
            Next
          </button>
        </nav>
      </template>
    </div>
  </div>
</template>

<script>
import MarketplaceService from "@/services/marketplace";
export default {
  name: "CorporateMarket",
  components: {},
  props: {
    view: String,
  },
  data() {
    return {
      categories: [],
      sales: [],
      auctions: [],
      filtered: null,
      paginatedList: [], // not in use
      currentPage: 1,
      itemsPerPage: 10, // Number of notifications to show per page
    };
  },
  computed: {
    listToRender() {
      return this.filtered
        ? this.filtered.rows
        : this.view == "cropauction"
          ? this.auctions.rows
          : this.sales.rows;
    },
    pageList() {
      const sortedSales = this.sortedSalesList();
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return sortedSales.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.sales.length / this.itemsPerPage);
    },
    auctionPageList() {
      const sortedAuctions = this.sortedAuctionsList();
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return sortedAuctions.slice(startIndex, endIndex);
    },
    inputTotalPages() {
      return Math.ceil(this.auctions.length / this.itemsPerPage);
    },
  },
  watch: {
    selectedSort(newValue) {
      this.sortData(newValue);
    },
  },
  created() {
    this.getCropCategories();
    this.getCropsForSale();
    this.getCropsForAuction();
  },
  methods: {
    sortedSalesList() {
      // make a copy of the original wanted array to avoid modifying the original
      return this.sales.slice(0).sort((a, b) => {
        if (a.specification.qty < 1 && b.specification.qty >= 1) {
          return 1; // Move items with qty < 1 to the end
        } else if (a.specification.qty >= 1 && b.specification.qty < 1) {
          return -1; // Move items with qty >= 1 to the beginning
        } else {
          // If both have the same qty or both have qty < 1, maintain their order
          return 0;
        }
      });
    },
    sortedAuctionsList() {
      // make a copy of the original wanted array to avoid modifying the original
      return this.auctions.slice(0).sort((a, b) => {
        if (a.specification.qty < 1 && b.specification.qty >= 1) {
          return 1; // Move items with qty < 1 to the end
        } else if (a.specification.qty >= 1 && b.specification.qty < 1) {
          return -1; // Move items with qty >= 1 to the beginning
        } else {
          // If both have the same qty or both have qty < 1, maintain their order
          return 0;
        }
      });
    },
    checked() {
      var box = document.getElementById("checkbox");
      var deColor = box.style.backgroundColor;
      if (deColor == "white") {
        deColor = "green";
      }
    },
    getCropCategories() {
      MarketplaceService.getCropCategories((response) => {
        this.categories = response.data;
      });
    },
    getCropsForSale() {
      MarketplaceService.getCropsForSale((response) => {
        this.sales = response.data.rows;
        // this.paginate(response.data.rows, "paginatedList");
      });
    },
    getCropsForAuction() {
      MarketplaceService.getCropsForAuction((response) => {
        this.auctions = response.data.rows;
        // this.paginate(response.data.rows, "paginatedList");
      });
    },
    applyFilters(filters) {
      let filtered = JSON.parse(
        JSON.stringify(this.view == "cropauction" ? this.auctions : this.sales),
      );
      Object.keys(filters).forEach((filter) => {
        if (filter == "category" && filters[filter] != "") {
          filtered.rows = filtered.rows.filter(
            (item) => item.category.name == filters[filter],
          );
        }
        if (filter == "price") {
          filtered.rows = filtered.rows.filter(
            (item) =>
              eval(item.specification.price) >= eval(filters[filter].min) &&
              eval(item.specification.price) <= eval(filters[filter].max),
          );
        }
      });
      this.filtered = filtered;
      this.paginate(filtered.rows, "paginatedList");
    },
    resetFilters() {
      this.filtered = null;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    inputNextPage() {
      if (this.currentPage < this.inputTotalPages) {
        this.currentPage++;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-succ {
  background: #05b050;
  color: white;
  &:hover {
    background: darken(#05b050, 10%);
  }
}
.fs-small {
  font-size: 14px;
}
.top-texts {
  h3 {
    font-size: 20px;
  }

  select {
    font-size: 14px;
  }

  p {
    font-size: 14px;
  }
}

.top-text {
  width: 100%;
  background: #262c3f;
  color: white;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 68px;
  }
}

.second-bar {
  width: 100%;
  background-color: white;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-area {
  width: 60%;
  display: flex;
  align-items: center;

  input {
    width: 95%;
    height: 60px;
    margin-top: 0px;
    border: 1px solid #05b050 !important;
    border-radius: 5px;
  }

  .icon {
    width: 54px;
    height: 60px;
    background-color: #05b050;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.button-area {
  margin-top: 35px;
  display: flex;
  gap: 16px;
  width: 60%;
  justify-content: space-between;

  button {
    background-color: white;
    height: 40px;
    padding: 0px 35px;
    border: 1px solid #696671;
    border-radius: 55px;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: #696671;
  }

  .green-btns {
    border: none;
    color: white;
  }

  .green {
    background: #05b050;
  }
}
.subcategory_crop {
  flex: 1;
}
.fad {
  opacity: 0.5;
}
.green-btn {
  background: #05b050;
  box-shadow:
    0px 2px 4px rgba(44, 39, 56, 0.08),
    0px 4px 8px rgba(44, 39, 56, 0.08);
  border-radius: 4px;
  width: 100px;
  height: 40px;
  border: none;
  &:hover {
    background: darken(#05b050, 10%);
  }
}

.main-content {
  background: #f1f1f1;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 50px 20px;
  justify-content: center;
  min-height: 100vh;

  .left {
    width: 25%;
    background-color: white;
    padding: 40px 20px;

    h3 {
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }

  .check {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;

    input {
      width: 100%;
      height: 100%;
    }
  }

  #checkbox {
    background-color: white;
  }
}

.each-type {
  display: flex;
  justify-content: space-between;

  .left-type {
    display: flex;

    p {
      margin-left: 10px;
    }
  }

  .green-text {
    padding: 5px;
    background: #ededee;
    border-radius: 4px;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #05b050;
  }
}

.quantity {
  width: 100%;

  select {
    background: #f7f7f7;
    border: 1px solid #ededee;
    border-radius: 4px;
    padding: 10px 15px;
    width: 35%;
  }

  input {
    background: #f7f7f7;
    border: 1px solid #ededee;
    border-radius: 4px;
    width: 65%;
  }
}

.shipping {
  display: flex;
  justify-content: space-around;
}

.each-ship {
  display: flex;

  p {
    margin-left: 10px;
  }
}

.shop-center {
  width: 100%;
  background: #f7f7f7;
  border: 1px solid #ededee;
  border-radius: 4px;
  padding: 10px 15px;
}

.range input {
  width: 100%;
}

.min-max {
  display: flex;
}

.min {
  display: flex;
  flex-direction: column;

  input {
    width: 80%;
  }
}

.apply {
  width: 100%;
  background-color: #05b050;
  color: white;
  height: 50px;
  border: none;
  box-shadow:
    0px 2px 4px rgba(44, 39, 56, 0.08),
    0px 4px 8px rgba(44, 39, 56, 0.08);
  border-radius: 4px;
}

.reset {
  width: 100%;
  background-color: white;
  color: #05b050;
  height: 50px;
  margin-top: 20px;
  border: 1px solid #05b050;
  box-shadow:
    0px 2px 4px rgba(44, 39, 56, 0.08),
    0px 4px 8px rgba(44, 39, 56, 0.08);
  border-radius: 4px;
}

.btns-down {
  margin-top: 100px;
}

.right {
  width: 90%;
  justify-self: center;
  display: flex;
  flex-direction: column;
}

.top-texts {
  display: flex;
  justify-content: space-between;

  .top-text-right {
    display: flex;
    align-items: baseline;

    p {
      margin-top: 5px;
    }

    select {
      margin-left: 10px;
      margin-top: 0px;
      background-color: white;
      border: 1px solid #05b050 !important;
      // height: 50px;
      padding: 0px 0px 0px 20px;
    }
  }
}

.each-item {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  padding: 10px 20px;
  text-decoration: none;
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;

  p {
    margin: 0px;
    flex: 1;
  }

  color: #4a4754;
}

.tags {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
  align-items: baseline;

  h4 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #19191d;
  }

  a {
    padding: 10px 20px;
    background: #ededf0;
    border-radius: 99px;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4b57;
    text-decoration: none;
  }
}

ul.pagination {
  li {
    a.page-link {
      border: 0px !important;
      color: #4a4754;

      &:not(#carret-icon):hover,
      :active {
        color: #ffffff;
        background: #05b050;
      }

      &:focus {
        box-shadow: 0px 0px !important;
      }

      span {
        color: #05b050;
      }
    }
  }
}

a:hover {
  box-shadow: 2px 2px 5px #888;
  color: white;
  background-color: #05b050;
}
</style>
