<template>
  <slot />
</template>

<script>
export default {
  name: "AuthSharedLayout",
};
</script>

<style lang="scss">
@import "@/assets/scss/shared/auth.scss";
</style>
