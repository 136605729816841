import MarketplaceService from "@/services/marketplace";
export default {
  data() {
    return {
      cart: {},
    };
  },
  computed: {},
  methods: {
    addPrototypeHelpers() {
      this.addCapitalizePrototypeMethod();
    },
    addCapitalizePrototypeMethod() {
      String.prototype.capitalize = function () {
        return this.substring(0, 1).toUpperCase() + this.substring(1);
      };
    },
  },
  mounted() {},
};
