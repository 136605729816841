<template>
  <DefaultNav>
    <div class="big-container">
      <div class="headings">
        <h2>Sayed Farms Limited</h2>
        <div class="top-text-right">
          <a href="/dashboard/loanshistory" class="history-btn">Loan History</a>
          <a href="/dashboard/bulkdisburse" class="bulk-btn">Bulk Disburse</a>
        </div>
      </div>
      <input type="text" placeholder="search" />
      <Filter
        :apply="applyFilters"
        :reset="resetFilters"
        :market-type="activeView"
      />
      <div class="active-subscriptions">
        <table class="table table-striped sub-history">
          <tr class="dark-header">
            <td class="theader">SELECT</td>
            <td class="theader">NAME</td>
            <td class="theader">LOCATION</td>
            <td class="theader">AMOUNT</td>
            <td class="theader">Dvs SCORE</td>
            <td class="theader">Dvs GRADE</td>
            <td class="theader">Pds SCORE</td>
            <td class="theader">Pds GRADE</td>
            <td class="theader">ACTION</td>
          </tr>

          <tbody class="scrollable">
            <tr>
              <td class="smaller"><Checkbox /></td>
              <td>John Alexis</td>
              <td>Ondo, Nigeria</td>
              <td>NGN23,998.00</td>
              <td>78.9%</td>
              <td>A</td>
              <td>48.9%</td>
              <td>C</td>
              <td><a href="/dashboard/singledisburse">Disburse</a></td>
            </tr>
            <tr>
              <td class="smaller"><Checkbox /></td>
              <td>John Alexis</td>
              <td>Ondo, Nigeria</td>
              <td>NGN23,998.00</td>
              <td>78.9%</td>
              <td>A</td>
              <td>48.9%</td>
              <td>C</td>
              <td><a href="/dashboard/singledisburse">Disburse</a></td>
            </tr>
            <tr>
              <td class="smaller"><Checkbox /></td>
              <td>John Alexis</td>
              <td>Ondo, Nigeria</td>
              <td>NGN23,998.00</td>
              <td>78.9%</td>
              <td>A</td>
              <td>48.9%</td>
              <td>C</td>
              <td><a href="/dashboard/singledisburse">Disburse</a></td>
            </tr>
            <tr>
              <td class="smaller"><Checkbox /></td>
              <td>John Alexis</td>
              <td>Ondo, Nigeria</td>
              <td>NGN23,998.00</td>
              <td>78.9%</td>
              <td>A</td>
              <td>48.9%</td>
              <td>C</td>
              <td><a href="/dashboard/singledisburse">Disburse</a></td>
            </tr>
            <tr>
              <td class="smaller"><Checkbox /></td>
              <td>John Alexis</td>
              <td>Ondo, Nigeria</td>
              <td>NGN23,998.00</td>
              <td>78.9%</td>
              <td>A</td>
              <td>48.9%</td>
              <td>D</td>
              <td><a href="/dashboard/singledisburse">Disburse</a></td>
            </tr>
            <tr>
              <td class="smaller"><Checkbox /></td>
              <td>John Alexis</td>
              <td>Ondo, Nigeria</td>
              <td>NGN23,998.00</td>
              <td>78.9%</td>
              <td>A</td>
              <td>48.9%</td>
              <td>D</td>
              <td><a href="/dashboard/singledisburse">Disburse</a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </DefaultNav>
</template>

<script>
import Checkbox from "@/components/Checkbox.vue";
import Filter from "@/pages/dashboard/loan/components/FarmerFilter.vue";
import DefaultNav from "@/layouts/DefaultNav.vue";
export default {
  name: "QualifiedFarmer",
  components: {
    DefaultNav,
    Filter,
    Checkbox,
  },
};
</script>

<style lang="scss" scoped>
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 2% 5% 2% 3%;

  overflow-y: scroll;
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}
.active-subscriptions {
  background-color: white;
  margin-top: 40px;
}

/* The slider */

.sub-history {
  width: 100%;

  td {
    padding-left: 15px;
  }

  h2 {
    margin-bottom: 40px;
  }
  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: white;
  }

  tr {
    display: flex;
    height: 66px;
    padding-top: 10px;

    a {
      color: white;
      width: 80px;
      height: 40px;
      background: #05b050;
      border-radius: 5px;
      font-weight: 700;
      font-size: 16px;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }
  }

  td {
    flex: 1;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4754;
  }
}
.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  .top-text-right {
    display: flex;
    gap: 20px;

    .history-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 150px;
      background-color: white;
      height: 48px;
      border-radius: 3px;
      border: 1px;
      font-family: Maven Pro;
      font-size: 16px;
      font-weight: 700;
      color: #05b050;
      border: 1px solid #05b050;
    }
    .bulk-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 150px;
      background-color: #fb8a2e;
      height: 48px;
      border-radius: 3px;
      border: 1px;
      font-family: Maven Pro;
      font-size: 16px;
      font-weight: 700;
      color: white;
    }
  }

  a {
    text-decoration: none;
  }
  .right-btns {
    display: flex;
    gap: 20px;
  }
}
.dark-header {
  background: #121330;
  color: white;
}
.smaller {
  width: 20px;
  position: relative;
}
</style>
