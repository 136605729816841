<template>
  <DefaultNav>
    <div class="big-container">
      <div class="contents">
        <div class="top-left">
          <div
            id="first"
            :class="['virtual-wallet', walletType == 1 ? 'active-wallet' : '']"
          >
            <div class="cover-div" @click="changeWallet($event, 1)"></div>
            <div class="go-row">
              <input id="checked" type="radio" name="wallet" />
              <h3>My Account</h3>
            </div>
            <h1><span>Balance</span> NGN {{ wallet.balance }}</h1>
            <div class="buttons-area">
              <a class="withdraw-btn" href="/dashboard/withdrawal">Withdraw</a>
            </div>
          </div>
          <div
            v-if="vfdWallet"
            :class="['vfd-wallet', walletType == 2 ? 'active-wallet' : '']"
          >
            <div class="cover-div" @click="changeWallet($event, 2)"></div>
            <div class="go-row">
              <input type="radio" name="wallet" />
              <h3>Vfd Account</h3>
            </div>
            <h1><span>Balance</span> NGN {{ vfdWallet.balance }}</h1>
            <h1><span>Account Number</span> {{ vfdWallet.account_number }}</h1>
          </div>
        </div>
        <div v-if="walletType == 1" class="virtual-details">
          <div class="tabs-section">
            <button
              :class="['tabs-button', tab == 1 ? 'active' : '']"
              @click="changeTab(1)"
            >
              Recent Transactions
            </button>
            <button
              :class="['tabs-button', tab == 2 ? 'active' : '']"
              @click="changeTab(2)"
            >
              Withdrawal History
            </button>
          </div>
          <div v-if="tab == 1" class="table-wrapper">
            <table class="table-borderless sub-history">
              <tbody class="scrollable">
                <tr>
                  <th>Transaction ID</th>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Amount</th>
                </tr>
                <tr v-for="transaction in transactions" :key="transaction.id">
                  <td>{{ transaction.transaction_id }}</td>
                  <td>{{ transaction.type }}</td>
                  <td>{{ transaction.created_at }}</td>
                  <th>NGN {{ transaction.amount_paid }}</th>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="tab == 2" class="table-wrapper">
            <table class="table-borderless sub-history">
              <tbody class="scrollable">
                <tr>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Status</th>
                </tr>
                <tr v-for="history in withdrawalHistory" :key="history.id">
                  <td>{{ history.created_at }}</td>
                  <th>{{ history.amount }}</th>
                  <td>{{ history.status }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="walletType == 2" class="vfd-details">
          <div class="tabs-section">
            <button
              id="account-summary"
              :class="[vTab == 1 ? 'active' : '']"
              @click="activetab(1)"
            >
              Account Summary
            </button>
            <button
              id="account-details"
              :class="[vTab == 2 ? 'active' : '']"
              @click="activetab(2)"
            >
              Account Details
            </button>
          </div>
          <div v-if="vTab == 1" class="table-wrapper">
            <table class="table-borderless sub-history">
              <tbody class="scrollable">
                <tr>
                  <td></td>
                  <th>Transaction ID</th>
                  <th>From</th>
                  <th>Date</th>
                  <th>Amount</th>
                </tr>

                <tr v-for="transaction in transactions" :key="transaction.id">
                  <td>
                    <!-- <img src="@/assets/images/vectors/receive-arrow.svg" alt="" /> -->
                  </td>
                  <td>{{ transaction.transaction_id }}</td>
                  <td>Zowasel Loan Service</td>
                  <td>{{ transaction.created_at }}</td>
                  <th>NGN {{ transaction.amount_paid }}</th>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="vTab == 2" class="account-details">
            <div class="top-row">
              <div class="each-card">
                <h4>Account Name</h4>
                <p>
                  {{ userData.user.first_name + " " + userData.user.last_name }}
                </p>
              </div>
              <div class="each-card">
                <h4>Account Number</h4>
                <p>{{ vfdWallet.account_number }}</p>
              </div>
              <div class="each-card">
                <h4>Date Of Creation</h4>
                <p>{{ formatDate(vfdWallet.created_at) }}</p>
              </div>
              <div class="each-card no-border">
                <h4>Transactions</h4>
                <p>0</p>
              </div>
            </div>
            <div class="bottom-row">
              <div class="each-card">
                <h4>Total Deposits</h4>
                <p>NGN 0.00</p>
              </div>
              <div class="each-card inflow">
                <h4>Inflow</h4>
                <p>NGN 0.00</p>
              </div>
              <div class="each-card outflow">
                <h4>Outflow</h4>
                <p>NGN 0.00</p>
              </div>
              <div class="each-card no-border"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DefaultNav>
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import LoanService from "@/services/loan";
import WalletService from "@/services/wallet";
export default {
  name: "Wallet",
  components: {
    DefaultNav,
  },
  data() {
    return {
      userData: this.$store.state.user,
      currentFarmerId: localStorage.getItem("farmerID"),
      wallet: [],
      transactions: [],
      withdrawalHistory: [],
      tab: 1,
      vTab: 1,
      walletType: 1,
      banks: [],
      bankCode: "",
      accountNumber: "",
      vfdWallet: null,
      beneficiaryName: "Beneficiary Name..",
    };
  },
  mounted() {
    this.getWalletBalance();
    this.getWalletTransactions();
    this.getWithdrawalHistory();
    this.getVWallet();
    this.getBanks();
    document.getElementById("checked").checked = true;
  },

  methods: {
    getWalletBalance(id) {
      WalletService.getWalletBalance(this.userData.user_id, (response) => {
        if (response && response.error == false) {
          this.wallet = response.data;
        }
      });
    },
    getWalletTransactions(id) {
      WalletService.getWalletTransactions(this.userData.user_id, (response) => {
        if (response && response.error == false) {
          this.transactions = response.data;
        }
      });
    },
    getWithdrawalHistory(id) {
      WalletService.getWithdrawalHistory(this.userData.user_id, (response) => {
        if (response && response.error == false) {
          this.withdrawalHistory = response.data;
        }
      });
    },
    getVWallet() {
      let vm = this;
      LoanService.getVwallet(
        this.userData.type,
        this.currentFarmerId,
        (response) => {
          console.log(response);
          if (response && response.data) {
            vm.vfdWallet = response.data;
          }
        },
      );
    },
    getBanks() {
      let vm = this;
      WalletService.getBanksList((response) => {
        if (response && response.data) {
          vm.banks = response.data.bank;
        }
      });
    },
    getBeneficiary() {
      let vm = this;
      WalletService.getBeneficiary(
        {
          account_number: this.accountNumber,
          bank_code: this.bankCode,
        },
        (response) => {
          if (response && response.data) {
            this.beneficiaryName = response.data.name;
          }
        },
      );
    },
    handleBeneficiary() {
      if (
        this.accounNumber != "" &&
        this.accountNumber.length == 10 &&
        this.bankCode != ""
      ) {
        this.getBeneficiary();
      }
    },
    changeTab(tab) {
      this.tab = tab;
    },
    activetab(vTab) {
      this.vTab = vTab;
    },
    changeWallet(e, walletType) {
      var selectedWallet = e.target;
      var inpBox = selectedWallet.nextElementSibling;
      var inp = inpBox.firstChild;
      inp.checked = true;
      this.walletType = walletType;
    },
    formatDate(date) {
      var dateObj = new Date(date);
      var dateString = dateObj.toDateString();
      return dateString.split(" ").slice(1).join(" ");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/shared/Styles";
.active {
  background: rgba(118, 118, 128, 0.12);
  border-radius: 8.91px;
  color: white;
}

.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: row;
  padding: 2% 5% 2% 3%;
  gap: 30px;
  overflow-y: scroll;
  padding-bottom: 40px;
}

.contents {
  width: 100%;
  margin-top: $default-margin * 1.5;

  .top-left {
    display: flex;
    gap: 25px;
    margin-bottom: $default-margin * 4;

    .virtual-wallet {
      background: #282b3d;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 30px 25px 20px 25px;
      border-radius: 10px;
      width: 50%;
      cursor: pointer;
      position: relative;

      .cover-div {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
      }

      h3 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 135%;
      }
      h1 {
        font-family: "Maven Pro";
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 132%;
        margin-left: 30px;
      }
      span {
        font-size: 16px;
      }
      .buttons-area {
        display: flex;
        justify-content: flex-end;
      }

      .withdraw-btn {
        width: 150px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #c32021;
        border-radius: 5px;
        font-family: "Maven Pro";
        font-style: normal;
        font-weight: 700;
        font-size: 20.6667px;
        color: white;
        text-decoration: none;
        opacity: 0.5;
      }
    }
    .virtual-wallet:hover {
      transform: scale(1.05);
    }
    .vfd-wallet {
      background: #282b3d;
      border-radius: 10px;
      padding: 30px;
      width: 50%;
      cursor: pointer;
      position: relative;

      .cover-div {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
      }

      h3 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 135%;
      }
      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
      }

      h1 {
        font-family: "Maven Pro";
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 132%;
        margin-left: 30px;
      }
      span {
        font-size: 16px;
      }
    }
    .vfd-wallet:hover {
      transform: scale(1.05);
    }
  }
}
.active-wallet {
  background-image: url("@/assets/images/backgrounds/card.svg") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  color: white !important;

  .withdraw-btn {
    opacity: 1 !important;
  }
}
.right-side {
  width: 35%;
  background: $zowasel-white;
  padding: $default-padding * 2;
  margin-top: $default-margin * 1.5;

  h3 {
    margin-top: 30px;
  }
}

.table-wrapper {
  background: $zowasel-white;
  padding: $default-padding * 2;
  margin-top: $default-padding;
}
.sub-history {
  width: 100%;

  h2 {
    margin-bottom: 40px;
  }

  tr {
    padding: 20px 9px;
    gap: 129px;
    height: 66px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  td {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4754;
  }
  .amount {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #4a4754;
  }
}

.chart-section {
  width: 100%;
  padding: 20px;
  background-color: white;

  img {
    width: 100%;
  }

  .texts {
    display: flex;
    gap: 30px;

    .income-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #41be53;
    }

    .expense-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #ffd16a;
    }

    .income,
    .expense {
      display: flex;
      align-items: center;
      gap: 5px;

      p {
        margin: 0px;
      }
    }
  }
}

.tabs-section {
  gap: -10px;
}
.tabs-section button {
  background: rgba(118, 118, 128, 0.12);
  border-radius: 9px;
  height: 50px;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding: 0px 10px;
  color: #262c3f;
  position: relative;
}
.active {
  background: #262c3f !important;
  color: white !important;
  z-index: 10;
}
.flex-row {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-left: 30px;
}

.table-wrapper {
  background: $zowasel-white;
  padding: $default-padding * 2;
  margin-top: $default-padding;
}
.sub-history {
  width: 100%;

  h2 {
    margin-bottom: 40px;
  }

  tr {
    padding: 20px 9px;
    gap: 129px;
    height: 66px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  td {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4754;
  }
  .amount {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #4a4754;
  }
}

.chart-section {
  width: 100%;
  padding: 20px;
  background-color: white;

  img {
    width: 100%;
  }

  .texts {
    display: flex;
    gap: 30px;

    .income-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #41be53;
    }

    .expense-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #ffd16a;
    }

    .income,
    .expense {
      display: flex;
      align-items: center;
      gap: 5px;

      p {
        margin: 0px;
      }
    }
  }
}

h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}
.tabs-section {
  gap: -10px;
  margin-bottom: 30px;
}
.tabs-section button {
  width: 200px;
  background: rgba(118, 118, 128, 0.12);
  border-radius: 9px;
  height: 50px;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding: 0px 10px;
  color: #262c3f;
  position: relative;
}
.active {
  background: #262c3f !important;
  color: white !important;
  z-index: 10;
}

.account-details {
  width: 100%;
  background: #ffffff;
  box-shadow:
    0px 2px 4px rgba(44, 39, 56, 0.08),
    0px 4px 8px rgba(44, 39, 56, 0.08);
  border-radius: 10px;
  padding: 35px;

  .top-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 2px dashed rgba(0, 0, 0, 0.21);

    .each-card {
      width: 25%;
      border-right: 2px dashed rgba(0, 0, 0, 0.21);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 50px;

      h4 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 116%;
        color: #696671;
      }

      p {
        font-family: "Maven Pro";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 147%;
        color: #4a4754;
      }
    }
  }

  .bottom-row {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .each-card {
      width: 25%;
      border-right: 2px dashed rgba(0, 0, 0, 0.21);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 50px;

      h4 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 116%;
        color: #696671;
      }

      p {
        font-family: "Maven Pro";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 147%;
        color: #4a4754;
      }
    }
  }
  .inflow {
    h4 {
      color: #036b31 !important;
    }
    p {
      color: #036b31 !important;
    }
  }
  .outflow {
    h4 {
      color: #891617 !important;
    }
    p {
      color: #891617 !important;
    }
  }

  .no-border {
    border-right: unset !important;
  }
}

.transfer {
  form {
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    padding: 50px;

    button {
      background: #05b050;
      border-radius: 4px;
      width: none;
      border: none;
      width: 100%;
      height: 50px;
      margin-top: 40px;
    }
  }
}
input[type="radio"]:after {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 3px solid white;
}

input[type="radio"]:checked:after {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: relative;
  background-color: #05b050;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 3px solid white;
}
.go-row {
  display: flex;
  gap: 15px;
  align-items: center;

  h3 {
    color: white;
    margin-top: 25px;
  }
}
</style>
