<template>
  <AuthSharedLayout>
    <div class="big-container">
      <img
        class="mobile-logo"
        src="@/assets/images/logos/zowasel-logo.png"
        alt=""
      />
      <div class="left-content">
        <h2 class="beta2">BETA 1.0</h2>
        <h1 class="text-center">Verify Account</h1>
        <p id="emailHelp" class="form-text text-muted text-center">
          Enter 6-digit code sent to your mail to verify your account
        </p>
        <div class="lines">
          <div class="line" />
          <p>OR</p>
          <div class="line" />
        </div>
        <form>
          <div class="form-group">
            <div class="row">
              <div
                v-for="(input, index) in codeInputs"
                :key="index"
                class="col"
              >
                <input
                  v-model="codeInputs[index]"
                  type="password"
                  maxlength="1"
                  class="form-control code-input"
                  placeholder=""
                  @keyup="moveToNext($event)"
                />
              </div>
            </div>
          </div>
          <div class="last-text">
            <p id="emailHelp" class="form-text text-muted text-center">
              Didn't get the code in your mail?
              <a class="login-text" @click="sendVerification()">Resend Code</a>
            </p>
          </div>

          <div class="form-group">
            <button
              type="button"
              class="btn green-btn btn-lg btn-block btn-hover-state"
              @click="verifyCode()"
            >
              Continue
            </button>
          </div>
        </form>
      </div>
      <div class="right-content">
        <img
          v-if="type == 'merchant' || type == 'corporate'"
          class="background-img"
          src="@/assets/images/backgrounds/merchant-background2.png"
          alt=""
        />
        <img
          v-if="type == 'partner' || type == 'agent'"
          class="background-img"
          src="@/assets/images/backgrounds/partner-background2.png"
          alt=""
        />
        <img class="logo" src="@/assets/images/logos/zowasel-logo.png" alt="" />
      </div>
    </div>
  </AuthSharedLayout>
</template>

<script>
import AuthSharedLayout from "@/layouts/shared/AuthSharedLayout.vue";
import AuthService from "@/services/auth";
import Alert from "@/utilities/alert";
export default {
  name: "VerifyAccount",
  components: {
    AuthSharedLayout,
  },
  data() {
    return {
      type: this.$route.params.type,
      codeInputs: ["", "", "", "", "", ""],
    };
  },
  created() {
    this.sendVerification();
  },
  methods: {
    moveToNext(e) {
      if (e.key == "Backspace") {
        var previousChildTree =
          e.target.parentElement.previousElementSibling?.children;
        if (previousChildTree) {
          previousChildTree[0].focus();
        }
      } else {
        var nextChildTree = e.target.parentElement.nextElementSibling?.children;
        if (nextChildTree) {
          nextChildTree[0].focus();
        }
      }
    },
    sendVerification() {
      var registerData = JSON.parse(
        window.localStorage.getItem("registerData"),
      );
      Alert.success({
        message: "Verification code sent",
      });

      AuthService.sendVerification(registerData.email, () => {});
    },

    verifyCode() {
      let vm = this;
      var registerData = JSON.parse(
        window.localStorage.getItem("registerData"),
      );

      AuthService.confirmVerification(
        registerData.email,
        this.codeInputs.join(""),
        (response) => {
          if (response.status) {
            window.localStorage.setItem("isEmailVerified", "true");
            vm.$router.push(`/${vm.type}/createpassword`);
          }
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/shared/Styles";
.checking {
  display: flex;
  margin-top: 60px;
}

.check-p {
  margin-top: -5px;
  margin-left: 10px;
}

.code-input {
  font-size: 25px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.login-text {
  color: green;
  text-decoration: none;
  cursor: pointer;
}
#emailHelp a:hover {
  text-decoration: underline;
  color: green;
  text-decoration-color: $zowasel-green;
}
</style>
