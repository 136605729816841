<template>
  <DefaultNav>
    <div class="big-container">
      <div class="big-content">
        <div v-if="product" class="topic">
          <div class="left">
            <div class="go-row">
              <a href="#"
                ><img
                  src="@/assets/images/vectors/arrowleft.png"
                  alt=""
                  @click="$router.back()"
              /></a>
              <h1 class="product-name">
                {{ product.subcategory.name }}
              </h1>
            </div>

            <p class="farmer">
              <span v-if="product.user.account_type == 'individual'">{{
                product.user.first_name + " " + product.user.last_name
              }}</span>
              <span v-if="product.user.account_type == 'company'">{{
                product.user.company.company_name
              }}</span>
              <img
                src="@/assets/images/vectors/verified.svg"
                alt=""
                class="ms-1"
              />
              <span class="ms-2">{{ kycStatus }}</span>
              <!-- <span class="ms-2">{{ userData.user.type }}</span> -->
              <span
                :class="['ms-3', isSubscribe ? 'subscribed' : 'subscribe']"
                @click="subscribeUser"
                >{{ isSubscribe ? "Subscribed" : "Subscribe" }}</span
              >
            </p>
            <div class="quantity">
              <p>Quantity</p>
              <div class="quantity-btns">
                <p>{{ product.stock }}</p>
              </div>
            </div>
            <div>
              <span class="crop-category">Crop Category:</span>
              <span class="ms-2 product_subcategory">{{
                product.subcategory.name
              }}</span>
              <span
                :class="[
                  'mt-3',
                  'ms-2',
                  'product_subscription',
                  isProductSubscribe ? 'subscribed' : 'subscribe',
                ]"
                @click="subscribeCategory"
              >
                {{ isProductSubscribe ? "Subscribed" : "Subscribe" }}
              </span>
            </div>
          </div>
          <div class="right">
            <!-- for bid -->
            <p class="price1">{{ product.currency }}{{ product.price }}</p>
            <!-- <p class="ships">Shipping: <span>NGN3050.01</span></p> -->
            <div class="down-btns">
              <a v-if="product.stock > 0" class="cart fulfil">Buy Now</a>
              <a
                v-if="product.stock > 0"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                class="negotiate"
                @click="addToCart()"
                >Add to Cart</a
              >
              <a
                v-if="product.stock < 1"
                href="javascript:void()"
                class="bg-danger p-3 text-center text-white"
                >Out of stock</a
              >
            </div>
          </div>
        </div>
        <div class="image-area">
          <div v-if="product && product.images.length" class="w-100">
            <div class="image-container">
              <img class="img-fluid" :src="product.images[0]" alt="" />
            </div>
            <div v-if="product.images.length > 1" class="small-images">
              <img
                v-for="(image, index) in product.images.slice(1)"
                :key="index"
                :src="image"
                alt=""
              />
            </div>
          </div>
        </div>
        <hr />
        <div id="accordionExample" class="accordion">
          <div class="accordion-item">
            <h2 id="headingOne" class="accordion-header">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Description
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <p v-html="product ? product.description : ''"></p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 id="headingTwo" class="accordion-header">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Specifications
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="specification">
                  <div class="left-detail">
                    <h4>Type: <span>NPK</span></h4>
                    <h4>
                      Other Names: <span>Quick-acting compound fertilizer</span>
                    </h4>
                    <h4>Application: <span>Plant Food</span></h4>
                    <h4>Classification: <span>Compound Fertilizer</span></h4>
                    <h4>Weight: <span>10kg</span></h4>
                  </div>
                  <div class="right-detail">
                    <h4>State: <span>Granular</span></h4>
                    <h4>MF: <span>N-P2O5-K2O</span></h4>
                    <h4>Model Number: <span>Complex</span></h4>
                    <h4>EINECS No.: <span>215-236-1</span></h4>
                    <h4>CAS No.: <span>1314-56-3</span></h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 id="headingThree" class="accordion-header">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Merchant
              </button>
            </h2>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="map">
                  <div
                    id="map-container-google-2"
                    class="z-depth-1-half map-container"
                  >
                    <iframe
                      class="main-map"
                      src="https://maps.google.com/maps?q=chicago&t=&z=13&ie=UTF8&iwloc=&output=embed"
                      frameborder="0"
                      style="border: 0"
                      allowfullscreen
                    />
                  </div>

                  <!--Google Maps-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>

      <div v-if="product" class="topic2">
        <div class="left">
          <div class="go-row">
            <a href="#"
              ><img
                src="@/assets/images/vectors/arrowleft.png"
                alt=""
                @click="$router.back()"
            /></a>
            <h1 class="product-name">
              {{ product.subcategory.name }}
            </h1>
          </div>

          <p class="farmer">
            <span v-if="product.user.account_type == 'individual'">{{
              product.user.first_name + " " + product.user.last_name
            }}</span>
            <span v-if="product.user.account_type == 'company'">{{
              product.user.company.company_name
            }}</span>
            <img
              src="@/assets/images/vectors/verified.svg"
              alt=""
              class="ms-1"
            />
            <span class="ms-2">{{ kycStatus }}</span>
          </p>
          <div class="quantity">
            <p>Quantity</p>
            <div class="quantity-btns">
              <!-- <button @click="decrement()">-</button> -->
              <p>{{ product.stock }}</p>
              <!-- <button @click="increment()">+</button> -->
            </div>
          </div>
          <p class="ships"></p>
        </div>
        <div class="right">
          <!-- for bid -->
          <p class="price1">{{ product.currency }}{{ product.price }}</p>
          <!-- <p class="ships">Shipping: <span>NGN3050.01</span></p> -->
          <div class="down-btns">
            <a v-if="product.stock > 0" class="cart fulfil">Buy Now</a>
            <a
              v-if="product.stock > 0"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              class="negotiate"
              @click="addToCart()"
              >Add to Cart</a
            >
            <a
              v-if="product.stock < 1"
              href="javascript:void()"
              class="bg-danger p-3 text-center text-white"
              >Out of stock</a
            >
          </div>
        </div>
      </div>
    </div>
  </DefaultNav>
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import MarketPlaceService from "@/services/marketplace";
import Alert from "@/utilities/alert";

export default {
  name: "Products",
  components: {
    DefaultNav,
  },
  data() {
    return {
      userData: this.$store.state.user,
      product: null,
      quantity: 1,
      isSubscribe: false,
      isProductSubscribe: false,
    };
  },

  mounted() {
    this.getProduct();
    this.getAllSubscription();
  },
  methods: {
    proceedToPay() {
      MarketPlaceService.saveDeliveryAddress(
        this.delivery_details,
        (response) => {
          if (response && response.error == false) {
            // setTimeout(this.$router.push({ name: "CheckoutPayment" }), 1000);
            window.localStorage.setItem(
              "checkoutData",
              JSON.stringify(response.data),
            );
            this.$router.push(`/dashboard/marketplace/checkout/payment`);
          }
        },
      );
    },
    increment() {
      if (this.quantity < this.product.stock) {
        this.quantity++;
      }
    },
    decrement() {
      if (this.quantity > 1) {
        this.quantity--;
      }
    },
    getProduct() {
      MarketPlaceService.getInputById(this.$route.params.id, (response) => {
        this.product = response.data;
        this.product.images = JSON.parse(this.product.images);
      });
    },
    /**Get all subscription  */
    getAllSubscription() {
      MarketPlaceService.getSubscription((response) => {
        if (response) {
          const data = response.data;
          // Check if the data array is empty
          if (data.length === 0) {
            this.isSubscribe = false;
            this.isProductSubscribe = false;
          } else {
            // Using Array.prototype.some() to check if "user" type exists
            const hasUserType = data.some(
              (item) =>
                item.type === "user" && item.type_id === this.product.user_id,
            );
            // Using Array.prototype.some() to check if "subcategory" type exists
            const hasSubCategoryType = data.some(
              (item) =>
                item.type === "subcategory" &&
                item.type_id === this.product.user_id,
            );

            // Update variables based on type existence
            if (hasUserType) {
              this.isSubscribe = true;
            }
            if (hasSubCategoryType) {
              this.isProductSubscribe = true;
            }
          }
        }
      });
    },

    /**
     * Make a post request with the necessary data by calling the addSubscription marketplace function
     */
    subscribeUser() {
      // set the loader to true
      this.isLoading = true;
      // access and store the request data
      const requestData = {
        user_id: this.userData.user.id,
        type: "user",
        type_id: this.product.user_id,
      };
      if (!this.isSubscribe) {
        MarketPlaceService.addSubscription(requestData, (response) => {
          this.isSubscribe = true;
          Alert.success({
            message: "You have successfully subscribed to this user",
          });

          // set the loader to false
          this.isLoading = false;
        });
      } else {
        MarketPlaceService.removeSubscription(requestData, (response) => {
          this.isSubscribe = false;
          Alert.success({
            message: "You have successfully Unsubscribed to this user",
          });

          // set the loader to false
          this.isLoading = false;
        });
      }
    },

    subscribeCategory() {
      // set the loader to true
      this.isLoading = true;
      // access and store the request data
      const requestData = {
        user_id: this.userData.user.id,
        type: "subcategory",
        type_id: this.product.user_id,
      };
      if (!this.isProductSubscribe) {
        MarketPlaceService.addSubscription(requestData, (response) => {
          this.isProductSubscribe = true;
          // set the loader to false
          Alert.success({
            message:
              "You have successfully subscribed to this Crop SubCategory",
          });

          this.isLoading = false;
        });
      } else {
        MarketPlaceService.removeSubscription(requestData, (response) => {
          this.isProductSubscribe = false;
          Alert.success({
            message:
              "You have successfully Unsubscribed to this Crop SubCategory",
          });

          // set the loader to false
          this.isLoading = false;
        });
      }
    },
    async addToCart() {
      MarketPlaceService.addToCart(
        {
          user_id: this.userData.user_id,
          input_id: this.$route.params.id,
          quantity: this.quantity,
        },
        (response) => {
          if (response.error == false) {
            Alert.success({
              message: "Added to cart",
              primary: true,
              primaryButtonTitle: "Proceed to cart",
              onProceed: () => {
                this.$router.push("/dashboard/marketplace/cart");
                Alert.close();
              },
            });
          }
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.product_subcategory {
  font-weight: 700;
}
.subscribe {
  color: #0065ff !important;
  font-family: Maven Pro;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 132%;
  letter-spacing: 0.17px;
  border-radius: 11px;
  padding: 9px 13px;
  border: 1.6px solid #0047b3;
  cursor: default;
  &:hover {
    background: lighten(#0065ff, 40%);
    color: #0065ff !important;
  }
}
.subscribed {
  font-family: "Maven Pro";
  font-size: 16px !important;
  background: #29333f;
  padding: 5px 10px;
  border-radius: 4px;
  color: white !important;
  background: #0065ff;
  cursor: default;
  &:hover {
    background: darken(#0065ff, 10%);
  }
}
.quantity {
  display: flex;
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  gap: 20px;
  color: #000000;

  p {
    margin: 0px;
  }

  .quantity-btns {
    display: flex;
    gap: 10px;
    align-items: center;

    p {
      margin: 0px;
    }

    button {
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: none;
    }
  }
}
.big-content {
  width: 100%;
  padding: 90px;
  .image-area {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 30px;

    .image-container {
      width: 100%;
      img {
        width: 30%;
      }

      .small-images {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 20px;
        overflow-x: scroll;
        margin-top: 25px;
        img {
          width: 150px;
        }
      }
    }
    .text-area {
      width: 50%;
      padding-left: 50px;
      h1 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        color: #4a4754;
        margin-bottom: 19px;
      }
      .price {
        font-family: "Maven Pro";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #14110c;
      }
      .farmer {
        font-family: "Maven Pro";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #14110c;
      }
      .verified {
        font-family: "Maven Pro";
        font-style: normal;
        font-weight: 400;
        font-size: 19.375px;
        color: #4a4754;
      }
      .ratings {
        width: 250px;
        display: flex;
        flex-direction: row;
        gap: 10px;
      }
      .order {
        margin-left: 20px;
      }
      .quantity {
        display: flex;
        margin-top: 120px;
        font-family: "Maven Pro";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        gap: 20px;
        color: #000000;

        p {
          margin: 0px;
        }

        .quantity-btns {
          display: flex;
          gap: 10px;
          align-items: center;

          p {
            margin: 0px;
          }

          button {
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: none;
          }
        }
      }
      .ships {
        font-family: "Maven Pro";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        text-transform: capitalize;
        color: #4a4754;
        margin-top: 20px;

        span {
          font-family: "Maven Pro";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          color: #4a4754;
        }
      }
      .buttonss {
        margin-top: 70px;
        display: flex;
        flex-direction: column;
        gap: 30px;

        .buy {
          height: 55px;
          border: none;
          background: #05b050;
          box-shadow:
            0px 2px 4px rgba(44, 39, 56, 0.08),
            0px 4px 8px rgba(44, 39, 56, 0.08);
          border-radius: 4px;
          font-family: "Maven Pro";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 147%;
          color: #e6f7ee;
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            background: darken(#05b050, 10%);
          }
        }
      }
      .cart {
        height: 55px;
        border: none;
        background: white;
        box-shadow:
          0px 2px 4px rgba(44, 39, 56, 0.08),
          0px 4px 8px rgba(44, 39, 56, 0.08);
        border-radius: 4px;
        font-family: "Maven Pro";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 147%;
        color: #05b050;
        border: 1px solid #05b050;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background: darken(white, 10%);
        }
      }
    }
    hr {
      margin-bottom: 50px;
      margin-top: 50px;
    }
  }
}
hr {
  margin-bottom: 50px;
  margin-top: 50px;
}
.guide {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
  a {
    color: #05b050;
  }
  button {
    background: #f9e9e9;
    border: 1.50427px solid #c32021;
    border-radius: 6.01709px;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 21.0598px;
    color: #c32021;
    height: 51px;
    width: 200px;
  }
}
.specification {
  display: flex;
  width: 100%;

  .left-detail {
    width: 50%;
  }
  .right-detail {
    width: 50%;
  }
  h4 {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 116%;
    color: #696671;
    margin-top: 20px;
    span {
      margin-left: 20px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 116%;
      color: #696671;
    }
  }
}
.reviews {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  gap: 100px;
  .left-reviews {
    width: 50%;
  }
  .right-reviews {
    width: 50%;
  }
}
.each-review {
  margin-top: 50px;
  h4 {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #191d23;
    margin-bottom: 0px;
  }
}
.texts p {
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #64748b;
}
.head-review {
  display: flex;
  justify-content: space-between;
  .stars {
    display: flex;
    gap: 10px;

    img {
      width: 20px;
      height: 20px;
    }
  }
}
.title {
  display: flex;
}
.img-contain {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  img {
    border-radius: 50%;
  }
}

.other-products {
  margin-top: 50px;
  padding-top: 50px;
  padding: 100px 45px;
  background: #f6f6f6;
}
.may-also {
  padding-top: 50px;
  padding: 100px 45px;
  background: #14b55a;
}
.others {
  display: flex;
  justify-content: space-between;

  a {
    background: #05b050;
    box-shadow:
      0px 2px 4px rgba(44, 39, 56, 0.08),
      0px 4px 8px rgba(44, 39, 56, 0.08);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    color: white;
    text-decoration: none;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 147%;
    color: #e6f7ee;
  }
  h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    color: white;
  }
}
.small-contents {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 32px;
  flex-wrap: wrap;

  .each-product {
    padding: 10px;
    border-radius: 8px;
    background: white;
    position: relative;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    h3 {
      margin-top: 16px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      color: #4a4754;
    }
    p {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #4a4754;
    }
    .badge {
      position: absolute;
      top: 24px;
      left: 22px;
      background: #fab900;
      border-radius: 4px;
      color: white;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 130%;
    }
  }
}
.deep-h {
  color: #696671 !important;
}
.margin-bot {
  margin-bottom: 40px;
}
.topic {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 50px;
  margin-bottom: 50px;

  .left {
    .product-name {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 35px;
      line-height: 116%;
      color: #4a4754;
    }

    .price2 {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 800;
      font-size: 22px;
      line-height: 117%;
      font-feature-settings: "liga" off;
      color: #4a4754;
    }

    .farmer {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.01em;
      color: #14110c;
      margin-top: 20px;

      span {
        font-family: "Maven Pro";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #4a4754;
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .negotiate {
      background: white;
      box-shadow:
        0px 2px 4px rgba(44, 39, 56, 0.08),
        0px 4px 8px rgba(44, 39, 56, 0.08);
      border-radius: 4px;
      height: 50px;
      width: 250px;
      border: none;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      color: #05b050;
      border: 1px solid #05b050;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      &:hover {
        background: darken(white, 10%);
      }
    }
    .price1 {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 900;
      font-size: 35px;
      color: #4a4754;
    }
    .down-btns {
      display: flex;
      gap: 20px;
    }
  }
}
.accordion-item {
  margin-top: 30px;
}
.go-row {
  display: flex;
  gap: 10px;
  align-items: center;

  img {
    width: 26px;
  }
}
.fulfil {
  background: #05b050;
  box-shadow:
    0px 2px 4px rgba(44, 39, 56, 0.08),
    0px 4px 8px rgba(44, 39, 56, 0.08);
  border-radius: 4px;
  height: 50px;
  width: 250px;
  border: none;
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #e6f7ee;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  &:hover {
    background: darken(#05b050, 10%);
    color: white;
  }
}
.negotiate {
  background: white;
  box-shadow:
    0px 2px 4px rgba(44, 39, 56, 0.08),
    0px 4px 8px rgba(44, 39, 56, 0.08);
  border-radius: 4px;
  height: 50px;
  width: 250px;
  border: none;
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #05b050;
  border: 1px solid #05b050;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.map-container-2 {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.map-container-2 iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.main-map {
  width: 100%;
  height: 60vh;
}
.topic2 {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(180deg, #29333f 0%, #1c2131 100%);
  padding: 50px;

  .left {
    .product-name {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 43px;
      line-height: 116%;
      color: white;
    }

    .price2 {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 117%;
      font-feature-settings: "liga" off;
      color: white;
    }
    .farmer {
      color: white;
    }
    .quantity {
      color: white;
    }
    .ships {
      color: white;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .negotiate {
      background: transparent;
      box-shadow:
        0px 2px 4px rgba(44, 39, 56, 0.08),
        0px 4px 8px rgba(44, 39, 56, 0.08);
      border-radius: 4px;
      height: 50px;
      width: 250px;
      border: none;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      color: #05b050;
      border: 1px solid #05b050;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }
    .price1 {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 29px;
      color: white;
    }
    .down-btns {
      display: flex;
      gap: 20px;
    }
    .ships {
      color: white;
    }
  }
}
</style>
