import config from "@/config";
import axios from "axios";

const CREATE_LOAN_TYPE = () => `loantypes/add`;
const GET_ALL_LOAN_TYPES = () => `loantypes/get`;
const GET_LOAN_TYPES_BY_USER = (user) => `loantypes/getbyuserid/${user}`;
const GET_LOAN_TYPE_BY_ID = (loantypeid) =>
  `loantypes/getbyloantypeid/${loantypeid}`;
const DELETE_LOAN_TYPE = (loantypeid) => `loantypes/remove/${loantypeid}`;
const UPDATE_LOAN_TYPE = (loantypeid) => `loantypes/${loantypeid}`;
const CREATE_LOAN_REQUEST = () => "loanrequest/add";
const GET_LOAN_REQUEST_BY_USER = (user) => `loanrequest/getbyuserid/${user}`;
const GET_ALL_LOAN_REQUEST = () => `loanrequest/get`;
const CREATE_FARMER_WALLET = () => `farmer/vwallet/create`;
const GET_VWALLET = (type, id) => `vwallet?user_type=${type}&farmer_id=${id}`;
const GET_LOAN_HISTORY = (acctno) => `vwallet/loans?account=${acctno}`;

export default {
  /* -------------------------------------------------------------------------- */
  /*                                  LOAN TYPE                                 */
  /* -------------------------------------------------------------------------- */
  /* ------------------------------ add loan type ----------------------------- */
  createLoanType: function (loantypeData, callback) {
    let contentReturned = [];
    axios
      .post(config.CREDITSCORING_URL + CREATE_LOAN_TYPE(), loantypeData)
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        console.log(error);
        contentReturned.push({ error: true });
        contentReturned.push(error);
        callback(contentReturned);
      });
  },
  /* --------------------------- get all loan types --------------------------- */
  getAllLoanTypes: function (callback) {
    axios
      .get(config.CREDITSCORING_URL + GET_ALL_LOAN_TYPES())
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  },
  /* ------------------------- get all loan types created by user ------------------------ */
  getLoanTypesByUser: function (user, callback) {
    axios
      .get(config.CREDITSCORING_URL + GET_LOAN_TYPES_BY_USER(user))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error);
      });
  },
  /* ---------------------- get all loan type by loan id ---------------------- */
  getLoanTypeById: function (loantypeid, callback) {
    axios
      .get(config.CREDITSCORING_URL + GET_LOAN_TYPE_BY_ID(loantypeid))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error);
      });
  },
  /* ---------------------------- delete loan type ---------------------------- */
  deleteLoanType: function (loantypeid, callback) {
    axios
      .get(config.CREDITSCORING_URL + DELETE_LOAN_TYPE(loantypeid))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error);
        // window.location.href = `/dashboard/farmer/view`;
      });
  },
  /* ------------------------- update loan type data ------------------------- */
  updateLoanType: function (loantypeid, loantypeData, callback) {
    let contentReturned = [];
    axios
      .post(
        config.CREDITSCORING_URL + UPDATE_LOAN_TYPE(loantypeid),
        loantypeData,
      )
      .then((response) => {
        contentReturned.push({ error: false });
        contentReturned.push(response.data);
        callback(contentReturned);
      })
      .catch((error) => {
        contentReturned.push({ error: true });
        contentReturned.push(error.response.data);
        callback(contentReturned);
      });
  },

  /* -------------------------------------------------------------------------- */
  /*                                  LOAN REQUESTS                             */
  /* -------------------------------------------------------------------------- */
  /* ----------------------------- add loan REQUEST---------------------------- */
  createLoanRequest: function (loanRequestData, callback) {
    let contentReturned = [];
    axios
      .post(config.CREDITSCORING_URL + CREATE_LOAN_REQUEST(), loanRequestData)
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        console.log(error);
        contentReturned.push({ error: true });
        contentReturned.push(error);
        callback(contentReturned);
      });
  },
  /* ------------------------- get all loan types created by user ------------------------ */
  getRequestTypesByUser: function (user, callback) {
    axios
      .get(config.CREDITSCORING_URL + GET_LOAN_REQUEST_BY_USER(user))
      .then((response) => {
        // console.log(response);
        callback(response.data);
      })
      .catch((error) => {
        // console.log(error);
        callback(error);
      });
  },
  /* -------------------------- get all loan requests ------------------------- */
  getAllLoanRequests: function (callback) {
    axios
      .get(config.CREDITSCORING_URL + GET_ALL_LOAN_REQUEST())
      .then((response) => {
        // console.log(response);
        callback(response.data);
      })
      .catch((error) => {
        // console.log(error);
        callback(error);
      });
  },
  /* -------------------------- create farmer wallet -------------------------- */
  createFarmerWallet: function (walletdata, callback) {
    axios
      .post(config.BASE_URL + CREATE_FARMER_WALLET(), walletdata)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        // console.log();
        callback(error.response.data);
      });
  },
  /* -------------------------- get v wallet created -------------------------- */
  getVwallet: function (type, id, callback) {
    axios
      .get(config.BASE_URL + GET_VWALLET("farmer", id))
      .then((response) => {
        console.log(response);
        callback(response);
      })
      .catch((error) => {
        callback(error);
        // if ((error.status = 403)) {
        // }
      });
  },
  /* -------------------------- get vfd loan history -------------------------- */
  getVloanhistory: function (acctno, callback) {
    axios
      .get(config.BASE_URL + GET_LOAN_HISTORY(acctno))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error);
        // if ((error.status = 403)) {
        // }
      });
  },
};
