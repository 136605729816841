<template>
  <DefaultNav>
    <div class="big-container">
      <div class="headings">
        <h2>Farmers</h2>
        <div class="right-btns">
          <!-- <a href="/dashboard/farmer/batch/add" class="green-link"
            >Batch Upload</a
          > -->
          <!-- <a href="javascript:void(0)" class="green-link">Score Farmer(s)</a> -->
          <!-- <a
            href="/dashboard/farmer/batch/score"
            class="green-link bg-warning text-center"
            >View Credit Scoring History</a
          > -->
          <a href="/dashboard/creditscoring" class="green-link outline-green"
            >View PDS history</a
          >
          <a href="/dashboard/farmer/score/dvs/all" class="green-link"
            >View DVS history</a
          >
          <!-- <a href="" class="green-link">Batch Upload</a> -->
          <!-- <a href="" class="red-link">Sent Invoice</a> -->
        </div>
      </div>

      <!-- <input type="text" placeholder="search" />
  
        <div class="row date-region">
          <div class="col">
            <select
              id=""
              v-model="delivery_details.country"
              class="form-select"
              name="country"
            >
              <option value="">Select country</option>
              <option
                v-for="(country, index) in countries"
                :key="index"
                :value="country.country"
              >
                {{ country.country }}
              </option>
            </select>
          </div>
          <div class="col">
            <select
              id=""
              v-model="delivery_details.state"
              name="state"
              class="form-select"
            >
              <option value="">Select state</option>
              <option v-for="(state, index) in selectStateByCountry" :key="index">
                {{ state }}
              </option>
            </select>
          </div>
          <div class="col">
            <select id="" class="form-select" name="">
              <option value="">30-100</option>
              <option value="">100-200</option>
              <option value="">200-500</option>
            </select>
          </div>
        </div> -->

      <div class="prl pt-3 px-0 d-flex justify-content-between">
        <div class="col-3 form-group">
          <input
            v-model="search"
            type="text"
            class="ms-2 form-control"
            placeholder="Search"
          />
        </div>
      </div>
      <div class="active-subscriptions">
        <table class="table-borderless sub-history">
          <tbody class="scrollable">
            <tr>
              <td class="serial">
                <input v-model="allSelected" type="checkbox" />
              </td>
              <td class="serial">S/N</td>
              <td class="theader">NAME</td>
              <td class="theader">EMAIL</td>
              <td class="theader">PHONE</td>
              <td class="theader">VIEW</td>
              <td class="theader">DVS</td>
              <!-- <td class="serial">PDS</td> -->
            </tr>
            <hr />
            <div v-if="farmerData.length > 0">
              <template v-for="(data, index) in searchedFarmer" :key="index">
                <tr>
                  <td class="serial">
                    <input
                      v-model="selected"
                      type="checkbox"
                      name="select"
                      :value="data.id"
                    />
                  </td>
                  <td class="serial">{{ index + 1 }}</td>
                  <td>
                    {{ nullToNA(data.doc.farmer.firstname) }}
                    <span v-if="nullToNA(data.doc.farmer.middlename) != 'N/A'">
                      {{ nullToNA(data.doc.farmer.middlename) }}
                    </span>
                    {{ nullToNA(data.doc.farmer.lastname) }}
                  </td>
                  <td>{{ nullToNA(data.doc.farmer.email) }}</td>
                  <td>{{ nullToNA(data.doc.farmer.mobile) }}</td>
                  <td>
                    <a
                      class="btn btn-success"
                      href="javascript:void(0)"
                      style="font-weight: bold"
                      @click="getSingleFarmerData(`${data.id}`)"
                      ><small>View Farmer</small></a
                    >
                  </td>
                  <td class="ps-3 text-center">
                    <a
                      href="javascript:void(0)"
                      class="btn btn-default border-success text-success"
                      @click="scoreFarmer(`${data.id}`)"
                      ><small>Run DVS</small></a
                    >
                  </td>
                  <td class="serial">
                    <span
                      v-if="data.doc.creditscore.score != 'null'"
                      class="text-center"
                    >
                      {{ data.doc.creditscore.grade }}
                    </span>
                  </td>
                  <!-- <td>
                    <template v-if="!scored">
                      <a
                        class="view-btn"
                        href="javascript:void(0)"
                        @click="scoreFarmer(`${data.farmer.farmerid}`)"
                        >Credit Score</a
                      >
                    </template>
                  </td> -->
                </tr>
              </template>
            </div>
            <div v-else>
              <h5 class="text-center mt-4">No farmers found</h5>
            </div>
          </tbody>
          <!-- pagination -->
          <nav class="d-flex justify-content-center my-4">
            <div id="pagination"></div>
          </nav>
        </table>
      </div>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import Preloader from "@/layouts/shared/Preloader.vue";
import FarmerService from "@/services/farmer";

export default {
  name: "Farmers",
  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      uid: JSON.stringify(
        JSON.parse(localStorage.getItem("Zowasel")).user.user_id,
      ),
      farmerData: [],
      scored: "",
      search: "",
      selected: [],
      allSelected: false,
      showButton: false,
      currentuserid: JSON.parse(localStorage.getItem("Zowasel")).user.user.type,
      start: 0,
      limit: 2000,
    };
  },
  computed: {
    searchedFarmer() {
      return this.farmerData.filter((fd) => {
        return (
          fd.doc.farmer.firstname
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) > -1 ||
          fd.doc.farmer.lastname
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
  },
  watch: {
    selected(newVal) {
      const checked = newVal.filter(
        (value) => value !== false && value !== null && value !== undefined,
      );
      const checkedObjects = checked.map((value) => ({ farmerid: value }));
      if (checkedObjects.length > 0) {
        this.showButton = true;
      } else {
        this.showButton = false;
      }
      console.log("Checked values:", checkedObjects);
    },
    allSelected(newVal) {
      console.log(newVal);
      if (newVal) {
        // check all checkboxes
        this.showButton = true;
        this.selected = this.farmerData.map((f) => f.id);
      } else {
        this.showButton = false;
        // uncheck all checkboxes
        this.selected = [];
      }
    },
  },
  mounted() {
    // getAllFarmers() {
    //let fff = { farmerbatch: this.farmerb };
    //   console.log(data);
    //});
    this.isLoading = true;
    // this.isLoading = false;

    if (this.currentuserid != "partner") {
      FarmerService.getMyFarmers(this.uid, (response) => {
        // console.log(response.data.rows);
        const filteringList = response.data.rows;

        // this.farmerData = filteringList.filter(
        //   (data) => data.doc.farmer.userid == this.uid
        // );
        console.log(filteringList.length, filteringList.length);
        this.paginate(filteringList, "farmerData");
        // if (this.farmerData.length > 0) {
        // }
        this.isLoading = false;
      });
    } else if (this.currentuserid == "partner") {
      FarmerService.getAllFarmers(this.start, this.limit, (response) => {
        // console.log(response.data.rows);
        const filteringList = response.data.rows;

        this.farmerData = filteringList;
        this.paginate(this.farmerData, "farmerData");
        this.isLoading = false;
      });
    }
  },
  methods: {
    getSingleFarmerData(farmerid) {
      localStorage.setItem("farmerID", farmerid);
      window.location.href = `/dashboard/farmer/view/${farmerid}`;
    },
    scoreFarmer(farmerid) {
      localStorage.setItem("farmerID", farmerid);
      window.location.href = `/dashboard/farmer/score/dvs`;
      // this.isLoading = true;
    },
    checkAll() {
      if (this.selectAll) {
        this.selected = this.farmerData.map((f) => f.farmer.farmerid);
      } else {
        this.selected = [];
      }
    },
    // applyforLoan(farmerid, email, gender, address) {
    //   localStorage.setItem(
    //     "creditScoreData",
    //     JSON.stringify({
    //       farmerid: farmerid,
    //       email: email,
    //       gender: gender,
    //       address: address,
    //     })
    //   );
    //   window.location.href = "/dashboard/creditscore/analyse";
    // },
    nullToNA(data) {
      if (data == "null" || data == "") {
        return "N/A";
      }
      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 4% 5% 10% 3%;
  overflow-y: scroll;
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}
.active-subscriptions {
  background-color: white;
  padding: 24px 35px;
  margin-top: 20px;
}
.prl {
  padding: 24px 35px;
}
.sub-history {
  margin-top: 30px;
  width: 100%;
  margin-left: 10px;

  h2 {
    margin-bottom: 40px;
  }
  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #4a4754;
  }

  .serial {
    max-width: 35px;
  }

  tr {
    display: flex;
    flex-direction: row;
    padding: 20px 9px 0;
    height: 66px;
    th {
      font-weight: bold;
    }
  }
  td {
    flex: 1;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4754;
    max-width: 185px;
    overflow-x: hidden;
    padding-left: 10px;
  }
  .active {
    width: 100px;
    height: 26px;
    background: #dcfce7;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .active-dot {
      width: 7.52px;
      height: 8px;
      background: #047b38;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #047b38;
    }
  }
  .expired {
    width: 100px;
    height: 26px;
    background: #fff8e6;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .expired-dot {
      width: 7.52px;
      height: 8px;
      background: #fab900;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #fab900;
    }
  }
  .cancelled {
    width: 100px;
    height: 26px;
    background: rgba(252, 220, 220, 0.93);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .cancelled-dot {
      width: 7.52px;
      height: 8px;
      background: #c52222;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #6b0303;
    }
  }
  a {
    text-decoration: none;
  }
  a:hover td {
    color: #05b050 !important;
  }
}
.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  a {
    text-decoration: none;
  }
  .right-btns {
    display: flex;
    gap: 20px;
  }
  .green-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 180px;
    height: 40px;
    background: #05b050;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    border-radius: 4px;
  }
  .white-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 40px;
    background: #ffffff;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #05b050;
    border: 2px solid #05b050;
    border-radius: 4px;
  }
  .red-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 180px;
    height: 40px;
    background: #cd4647;
    box-shadow:
      0px 2px 4px rgba(44, 39, 56, 0.08),
      0px 4px 8px rgba(44, 39, 56, 0.08);
    border-radius: 4px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    border-radius: 4px;
  }
}
.date-region {
  margin-top: 30px;
}
.view-btn {
  color: black;
}
.bg-grey {
  background: rgba(0, 0, 0, 0.183);
}
.sectionSelectButton {
  padding: 11px 0;
  border-radius: 5px;
  border: none;
  width: 50%;
  background: rgba(0, 0, 0, 0.183);
}
.sectionSelectButton.active {
  background: #262c3f;
  color: white;
}

.outline-green {
  color: #05b050 !important;
  width: 230px !important;
  text-align: center;
  background: #f5f5f5 !important;
  border: 2px solid #05b050;
}
</style>
