<template>
  <DefaultNav>
    <div class="big-container">
      <div class="headings">
        <div class="left-side">
          <!-- <a href="/dashboard/each-loan"
            ><img src="@/assets/images/vectors/arrowleft.png" alt=""
          /></a> -->
          <h2 class="loan-detail">Loan Types</h2>
        </div>
        <a class="add-loan" href="/dashboard/loantype/add">Add New Loan Type</a>
      </div>
      <template v-if="loanTypes.length > 0">
        <div v-for="(data, index) in loanTypes" :key="index" class="long-card">
          <div class="long-card">
            <div class="each-card">
              <div class="top-section">
                <h5>Loan Type</h5>
                <h2 class="green-text">{{ data.loantype }}</h2>
              </div>
            </div>
            <div class="middle-card">
              <div class="each-card">
                <div class="top-section">
                  <h5>Min. Issuable Amount</h5>
                  <p>NGN {{ data.minAmount }}</p>
                </div>
                <div class="bottom-section">
                  <h5>Max. Issuable Amount</h5>
                  <p>NGN {{ data.maxAmount }}</p>
                </div>
              </div>
              <div class="each-card">
                <div class="top-section">
                  <h5>Payment Duration</h5>
                  <p>
                    {{ data.minDuration }}
                    <span v-if="data.minDuration != ''">-</span>
                    {{ data.maxDuration }}
                  </p>
                </div>
                <div class="bottom-section">
                  <h5>Loan Interest</h5>
                  <p>{{ data.interest }}</p>
                </div>
              </div>
              <div class="each-card">
                <div class="top-section">
                  <h5>Repayment Type</h5>
                  <p>{{ data.repaymentType }}</p>
                </div>
                <div class="bottom-section">
                  <h5>Loan Description</h5>
                  <p>
                    <a
                      :href="`#loanDescription${index}`"
                      class="link"
                      data-bs-toggle="modal"
                      >View Description</a
                    >
                  </p>
                </div>
              </div>
            </div>

            <div class="last-btns">
              <!-- <a
                href="javascript:void(0)"
                class="edit-button"
                @click="editLoanType(data.loantypeid)"
                >Edit</a
              > -->
              <a
                href="javascript:void(0)"
                class="delete-button"
                @click="deleteLoanType(data.loantypeid)"
                >Delete</a
              >
            </div>

            <!-- Description Modal -->
            <div
              :id="`loanDescription${index}`"
              class="modal fade"
              tabindex="-1"
              aria-labelledby="loanDescriptionLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 id="loanDescriptionLabel" class="modal-title">
                      Loan Type Description
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body" v-html="data.purpose"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="long-card">
          <h4 class="text-center">No Loan Types Added</h4>
        </div>
      </template>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import Preloader from "@/layouts/shared/Preloader.vue";
import LoanService from "@/services/loan";
import Alert from "@/utilities/alert";

export default {
  name: "LoanType",
  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      loanTypes: [],
    };
  },
  mounted() {
    this.isLoading = true;
    const url = window.location.href;
    const uid = JSON.stringify(
      JSON.parse(localStorage.getItem("Zowasel")).user.user_id,
    );
    LoanService.getLoanTypesByUser(uid, (response) => {
      if (response.data != null) {
        this.loanTypes = response.data;
      }
      this.isLoading = false;
    });
  },
  methods: {
    thousandsData(x) {
      return "₦" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    deleteLoanType(loantypeid) {
      this.isLoading = true;
      LoanService.deleteLoanType(loantypeid, (response) => {
        if (response.error == false) {
          Alert.success({
            message: response.message,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1800);
        }
        this.isLoading = false;
      });
    },
    editLoanType(loantypeid) {
      window.location.href = `/dashboard/loantype/${loantypeid}/edit`;
    },
  },
};
</script>

<style lang="scss" scoped>
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 2% 5% 2% 3%;
  overflow-y: scroll;

  .loan-detail {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}
.headings {
  display: flex;
  justify-content: space-between;

  .add-loan {
    background: #05b050;
    box-shadow:
      0px 2px 4px rgba(44, 39, 56, 0.08),
      0px 4px 8px rgba(44, 39, 56, 0.08);
    border-radius: 4px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #e6f7ee;
  }
}

/* The slider */

.loan-details {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 35px;
}
.left-details {
  width: 50%;
  border-right: 1px solid #4a4754;
  padding-right: 50px;

  .each-detail {
    display: flex;
    justify-content: space-between;

    h4 {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #4a4754;
    }
    p {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #4a4754;
    }
  }
}
.right-details {
  width: 50%;
  padding-left: 50px;

  h3 {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    color: #05b050;
    margin-top: 40px;
    margin-bottom: 15px;
  }

  .each-detail {
    display: flex;
    justify-content: space-between;

    h4 {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #4a4754;
    }
    p {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #4a4754;
    }
  }
}

.left-side {
  display: flex;
  gap: 10px;

  img {
    width: 22px;
    height: 18px;
  }
}
.long-card {
  width: 100%;
  background-color: white;
  padding: 5px 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 30px;

  h5 {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #4a4754;
  }

  .middle-card {
    width: 60%;
    display: flex;
    justify-content: space-around;
  }

  p {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #96949c;
  }
}
.green-text {
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #05b050;
}
.last-btns {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.edit-button {
  background: #96949c;
  box-shadow:
    0px 2px 4px rgba(44, 39, 56, 0.08),
    0px 4px 8px rgba(44, 39, 56, 0.08);
  border-radius: 4px;
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #e6f7ee;
}
.delete-button {
  background: white;
  border: 1px solid #cd4647;
  box-shadow:
    0px 2px 4px rgba(44, 39, 56, 0.08),
    0px 4px 8px rgba(44, 39, 56, 0.08);
  border-radius: 4px;
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #cd4647;
}
</style>
