<template>
  <DefaultNav>
    <div class="big-container pb-5">
      <form class="mt-4" @submit.prevent="saveData()">
        <div class="headings">
          <h2>Farm Mechanization</h2>
          <div class="right-btns">
            <a href="/dashboard/farmer/view" class="yellow-link"
              >View Farmers</a
            >
            <button class="green-link">Save</button>
          </div>
        </div>
        <div v-if="editing == false" class="mb-3">
          <h6>
            <strong>All fields are required</strong>
          </h6>
        </div>
        <div class="row mb-4">
          <div class="col">
            <label for="">
              <span v-if="editing == false" class="text-danger">*</span>
              Type of Machine
            </label>
            <input
              v-model="machinetype"
              type="text"
              class="form-control"
              placeholder="Enter your answer"
            />
          </div>
        </div>
        <div class="mb-4">
          <label for=""
            ><span v-if="editing == false" class="text-danger">*</span> Number
            of above named machine you have?</label
          >
          <input
            v-model="noofmachines"
            type="number"
            class="form-control"
            placeholder="Enter your answer"
          />
        </div>
        <div class="row mb-4">
          <div class="col">
            <label for=""
              ><span v-if="editing == false" class="text-danger">*</span> Number
              of units of the machine actively in use?</label
            >
            <input
              v-model="activemachines"
              type="number"
              class="form-control"
              placeholder="Enter your answer"
            />
          </div>
          <div class="col">
            <label for=""
              ><span v-if="editing == false" class="text-danger">*</span> Number
              of Units of the machine not being used
            </label>
            <input
              v-model="inactivemachines"
              type="number"
              class="form-control"
              placeholder="Enter your answer"
            />
          </div>
        </div>
        <div class="mb-4">
          <label for=""
            ><span v-if="editing == false" class="text-danger">*</span>
            Average Number of years these machines has been in use
          </label>
          <input
            v-model="yearsofusage"
            type="number"
            class="form-control"
            placeholder="Enter your answer"
          />
        </div>
        <div class="mb-4">
          <label for=""
            ><span v-if="editing == false" class="text-danger">*</span>
            Average cost of maintenance for these machines (for both active and
            inactive machines)
          </label>
          <input
            v-model="costofmaintenance"
            type="number"
            class="form-control"
            placeholder="Enter your answer without comma"
          />
        </div>
      </form>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
// Alert box
import Alert from "@/utilities/alert";
import FarmerService from "@/services/farmer";
import { v4 as uuidv4 } from "uuid";
import Preloader from "@/layouts/shared/Preloader.vue";
export default {
  name: "FarmMechanization",
  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      farmerid: localStorage.getItem("farmerID"),
      machineid: "",
      machinetype: "",
      noofmachines: "",
      activemachines: "",
      inactivemachines: "",
      yearsofusage: "",
      costofmaintenance: "",
      editData: [],
      editing: false,
    };
  },
  mounted() {
    const url = window.location.href;
    this.isLoading = true;

    if (url.split("/").slice(-2)[0] == "edit") {
      this.editing = true;
      let farmerData = JSON.parse(localStorage.getItem("farmersdata"));
      this.editData.push(farmerData);

      this.machinetype = farmerData.farmmechanization[0].typeofmachine;
      this.noofmachines =
        farmerData.farmmechanization[0].noofthismachinepossessed;
      this.activemachines = farmerData.farmmechanization[0].noofactiveunits;
      this.inactivemachines =
        farmerData.farmmechanization[0].noofdefectiveunits;
      this.yearsofusage = farmerData.farmmechanization[0].averagenoofyearsused;
      this.costofmaintenance =
        farmerData.farmmechanization[0].averagecostofmaintenanceinnaira;
      this.isLoading = false;
    } else {
      this.isLoading = false;
      this.editing = false;
    }
  },
  methods: {
    async saveData() {
      this.isLoading = true;
      if (this.editing == true) {
        this.editData[0].farmmechanization[0].typeofmachine = this.machinetype;
        this.editData[0].farmmechanization[0].noofthismachinepossessed =
          this.noofmachines;
        this.editData[0].farmmechanization[0].noofactiveunits =
          this.activemachines;
        this.editData[0].farmmechanization[0].noofdefectiveunits =
          this.inactivemachines;
        this.editData[0].farmmechanization[0].averagenoofyearsused =
          this.yearsofusage;
        this.editData[0].farmmechanization[0].averagecostofmaintenanceinnaira =
          this.costofmaintenance;

        let dt = {
          farmerid: this.farmerid,
          farmerjson: this.editData[0],
        };
        FarmerService.updateFarmer(dt, (data) => {
          /* ------------------- check if it returned with an error ------------------- */
          if (data[0].error == false) {
            Alert.success({
              message: data[1].message,
            });
            setTimeout(() => {
              window.location.href = `/dashboard/farmer/view/${this.farmerid}`;
            }, 2000);
          } else {
            Alert.error({
              message: data[1].message,
            });
          }
          this.isLoading = false;
        });
      } else {
        let vm = this;
        let dt = {
          farmerid: vm.farmerid,
          farmmachineid: `FARMMACHINE-${uuidv4()}`,
          typeofmachine: vm.machinetype,
          noofthismachinepossessed: vm.noofmachines.toString(),
          noofactiveunits: vm.activemachines,
          noofdefectiveunits: vm.inactivemachines,
          averagenoofyearsused: vm.yearsofusage,
          averagecostofmaintenanceinnaira: vm.costofmaintenance,
        };
        FarmerService.addFarmMechanization(dt, (data) => {
          /* ------------------- check if it returned with an error ------------------- */
          if (data[0].error == false) {
            Alert.success({
              message: data[1].message,
            });
            setTimeout(() => {
              window.location.href = `/dashboard/farmer/view/${vm.farmerid}`;
            }, 2000);
          } else {
            Alert.error({
              message: data[1].message,
            });
          }
          this.isLoading = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 2% 5% 2% 3%;
  overflow-y: scroll;
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}
.active-subscriptions {
  background-color: white;
  padding: 24px 65px;
  margin-top: 40px;

  h2 {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #696671;
  }
}

/* The slider */
.sub-history {
  margin-top: 50px;
  width: 100%;
  margin-left: 10px;

  h2 {
    margin-bottom: 40px;
  }
  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #4a4754;
  }
}
.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  a {
    text-decoration: none;
  }
  .right-btns {
    display: flex;
    gap: 20px;
  }
  .green-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 145px;
    height: 40px;
    background: #05b050;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
  }
}
.green-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 145px;
  height: 40px;
  background: #05b050;
  border-radius: 5px;
  letter-spacing: 0.02em;
}
.yellow-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  width: 145px;
  height: 40px;
  background: #fab900 !important;
  border-radius: 5px;
  letter-spacing: 0.02em;
}
a {
  text-decoration: none;
}
.small-font {
  font-size: 11px !important;
}
</style>
