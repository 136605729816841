<template>
  <DefaultNav>
    <div class="big-container">
      <div class="big-content">
        <!-- header -->
        <div class="arrow-section">
          <a href="/dashboard/marketplace/myproducts"
            ><img src="@/assets/images/vectors/arrowleft.png" alt=""
          /></a>
          <h1>Add New Input</h1>
        </div>
        <!-- for edit page -->
        <div v-if="productID" class="edit_crop">
          Update Input Details By Editing The Input Field With The Updated
          Information. (Images are editable.)
        </div>
        <form @submit.prevent="saveData()">
          <div class="crop-wanted-section d-flex flex-row gap-4">
            <div class="form">
              <div class="crop_details">Input Details</div>
              <!-- input -->
              <div class="w-100 mb-3">
                <label for="exampleInputEmail1" class="form-label mb-0"
                  >Product Type</label
                >
                <span id="required">*</span>
                <select
                  v-model="inputData.product_type"
                  class="form-select"
                  aria-label="Default seglect example"
                  required
                >
                  <option>Hybrid</option>
                  <option>Normal</option>
                  <option>Organic</option>
                  <option>GMO</option>
                </select>
              </div>
              <!-- input -->
              <div class="w-100 mb-3">
                <label for="exampleInputEmail1" class="form-label mb-0"
                  >Catalog</label
                >
                <span id="required">*</span>
                <select
                  v-model="inputData.category_id"
                  class="form-select"
                  aria-label="Default seglect example"
                  required
                >
                  <option
                    v-for="category in categories"
                    :key="category.id"
                    :value="category.id"
                  >
                    {{ category.name }}
                  </option>
                </select>
              </div>
              <!-- input -->
              <div class="w-100 mb-3">
                <label for="exampleInputEmail1" class="form-label mb-0"
                  >Packaging</label
                >
                <span id="required">*</span>
                <select
                  v-model="inputData.packaging"
                  class="form-select"
                  aria-label="Default select example"
                  required
                >
                  <option value="bag">Bag</option>
                  <option value="bottle">Bottles</option>
                  <option value="sachet">Sachet</option>
                  <option value="carbon">Carbon</option>
                </select>
              </div>
              <!-- input -->
              <div class="w-100 mb-3">
                <label for="exampleInputEmail1" class="form-label mb-0"
                  >Products</label
                >
                <span id="required">*</span>
                <select
                  v-model="inputData.subcategory_id"
                  class="form-select"
                  aria-label="Default select example"
                  required
                >
                  <option
                    v-for="subCategory in subCategoryByCategory"
                    :key="subCategory.id"
                    :value="subCategory.id"
                  >
                    {{ subCategory.name }}
                  </option>
                </select>
              </div>
              <div class="w-100 mb-3">
                <label for="exampleInputEmail1" class="form-label mb-0"
                  >Measured In <span id="required">*</span></label
                >
                <select
                  v-model="inputData.measurement"
                  class="form-select col-3"
                  aria-label="Default select example"
                >
                  <option value="kg">Kg</option>
                  <option value="g">Grams</option>
                  <option value="litres">Litres</option>
                </select>
              </div>
              <div class="w-100 mb-3">
                <label for="exampleInputEmail1" class="form-label mb-0"
                  >Quantity</label
                >
                <span id="required">*</span>
                <input
                  v-model="inputData.stock"
                  type="number"
                  class="form-control"
                  min="1"
                  required
                />
              </div>
              <!-- input -->

              <div class="w-100 mb-3">
                <label for="exampleInputEmail1" class="form-label mb-0"
                  >Currency</label
                >
                <span id="required">*</span>
                <select
                  v-model="inputData.currency"
                  class="form-select"
                  aria-label="Default select example"
                  required
                >
                  <option
                    v-for="currency in currencies"
                    :key="currency"
                    :value="currency"
                  >
                    {{ currency }}
                  </option>
                </select>
              </div>
              <!-- input -->
              <div class="w-100 mb-3">
                <label for="exampleInputEmail1" class="form-label mb-0"
                  >Price</label
                >
                <span id="required">*</span>
                <input
                  v-model="inputData.price"
                  type="number"
                  class="form-control"
                  required
                  placeholder="NGN"
                />
              </div>
              <!-- input -->
              <div class="w-100 mb-3">
                <label for="exampleInputEmail1" class="form-label mb-0"
                  >Crop Focus</label
                >
                <span id="optional">-(optional)</span>
                <select
                  v-model="inputData.crop_focus"
                  class="form-select"
                  aria-label="Default seglect example"
                >
                  <option
                    v-for="category in cropFocus"
                    :key="category.id"
                    :value="category.name"
                  >
                    {{ category.name }}
                  </option>
                </select>
              </div>
              <!-- Editor -->
              <div class="crop_details">
                Product description<span id="required">*</span>
              </div>

              <input
                v-model="inputData.description"
                class="hidden-input ms-5"
                type="text"
                name="description"
                required
              />
              <div class="d-flex flex-row mb-3 gap-4 m_top">
                <div class="w-100 editor-wrapper">
                  <!-- using quill editor tool-->
                  <div ref="editor" @text-change="handleContentChange" />
                </div>
              </div>
              <!-- editor ends -->
            </div>
            <div class="vertical-line" />
            <!-- form two -->
            <div class="form">
              <!-- input -->
              <div class="crop_details">Product image</div>
              <div class="dropzone_conatiner my-4">
                <div id="my-dropzone" class="dropzone">
                  <img id="preview-selected-image" class="img-fluid mb-2" />
                  <img
                    id="image_icon"
                    src="@/assets/images/vectors/Image.svg"
                    alt="image"
                  />
                  <div id="file-input">
                    <input
                      id="hidden_input"
                      ref="input"
                      type="file"
                      multiple
                      accept="image/*"
                      required
                      @change="uploadFile"
                    />
                    <div
                      v-if="fileName == ''"
                      id="file_name"
                      @click="openFileDialog()"
                    >
                      <span>click to browse</span>
                      <span id="required">*</span>
                    </div>
                    <span v-else>{{ fileName }}</span>
                  </div>
                </div>
              </div>
              <!-- input -->
              <div class="w-100 mb-3 m_top">
                <label for="exampleInputEmail1" class="form-label mb-0"
                  >Input Application</label
                >
                <span id="required">*</span>
                <textarea
                  v-model="inputData.usage_instruction"
                  type="text"
                  class="form-control"
                  required
                  placeholder="type your answer"
                ></textarea>
              </div>
              <!-- input -->
              <div class="w-100 mb-3">
                <label for="exampleInputEmail1" class="form-label mb-0"
                  >Video</label
                >
                <span id="optional">-optional</span>
                <input
                  v-model="inputData.video"
                  type="text"
                  class="form-control"
                  placeholder="type your answer"
                />
              </div>

              <!-- input -->
              <div class="w-100 mb-3">
                <label for="exampleInputEmail1" class="form-label mb-0"
                  >Pieces in a carton</label
                >
                <span id="optional">-optional</span>
                <select
                  v-model="inputData.pieces_carton"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option>6</option>
                  <option>10</option>
                  <option>12</option>
                  <option>20</option>
                  <option>24</option>
                  <option>50</option>
                  <option>60</option>
                  <option>80</option>
                  <option>100</option>
                  <option>200</option>
                  <option>400</option>
                </select>
              </div>
              <!-- input -->
              <div class="w-100 mb-3">
                <label for="exampleInputEmail1" class="form-label mb-0"
                  >Manufacture Name</label
                >
                <span id="required">*</span>
                <input
                  v-model="inputData.manufacture_name"
                  type="text"
                  class="form-control"
                  required
                  placeholder="type your answer"
                />
              </div>

              <!-- input -->
              <div class="w-100 mb-3">
                <label for="exampleInputEmail1" class="form-label mb-0"
                  >Manufacture Date</label
                >
                <span id="required">*</span>
                <input
                  v-model="inputData.manufacture_date"
                  type="date"
                  class="form-control"
                  required
                  placeholder="type your answer"
                />
              </div>
              <!-- input -->
              <div class="w-100 mb-3">
                <label for="exampleInputEmail1" class="form-label mb-0"
                  >Expiry Date</label
                >
                <span id="required">*</span>
                <input
                  v-model="inputData.expiry_date"
                  type="date"
                  class="form-control"
                  required
                  placeholder="type your answer"
                />
              </div>
              <!-- input -->
              <div class="w-100 mb-3">
                <label for="exampleInputEmail1" class="form-label mb-0"
                  >Manufacture country</label
                >
                <span id="required">*</span>
                <select
                  v-model="inputData.manufacture_country"
                  class="form-select"
                  aria-label="Default select example"
                  required
                >
                  <option
                    v-for="(country, index) in countries"
                    :key="index"
                    :value="country.country"
                  >
                    {{ country.country }}
                  </option>
                </select>
              </div>
              <!-- input -->
              <div class="w-100 mb-3">
                <label for="exampleInputEmail1" class="form-label mb-0"
                  >Shipping Method</label
                >
                <span id="required">*</span>
                <select
                  v-model="inputData.delivery_method"
                  class="form-select"
                  aria-label="Default select example"
                  required
                >
                  <option value="delivery">Delivery</option>
                  <option value="pick-up">Pick up at Crop Centers</option>
                </select>
              </div>

              <div class="w-100 mb-3">
                <label for="formGroupExampleInput" class="form-label mb-0"
                  >Delivery window</label
                >
                <span id="required">*</span>
                <div class="form-row">
                  <div class="col">
                    <input
                      id="from-date-input"
                      v-model="inputData.delivery_window.from"
                      type="date"
                      class="form-control"
                      required
                    />
                  </div>

                  <div class="col">
                    <input
                      id="to-date-input"
                      v-model="inputData.delivery_window.to"
                      type="date"
                      class="form-control"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a id="save" :class="['btn', 'btn-primary', 'my-4', 'application']">
            Application guideline
          </a>
          <button id="save" :class="['btn', 'btn-primary', 'my-4']">
            {{ isEditing ? "Update" : "save" }}
          </button>
        </form>
      </div>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>
<script>
//   sidebar
import DefaultNav from "@/layouts/DefaultNav.vue";
// Alert box
import Alert from "@/utilities/alert";
// // importing the marketplace service
import MarketPlaceService from "@/services/marketplace";
// import country
import countriesObject from "@/data/countries";
import Preloader from "@/layouts/shared/Preloader.vue";

// quill editor
import Quill from "quill";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  name: "AddCropInput",
  components: {
    DefaultNav,
    Preloader,
  },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      categories: [],
      subCategories: [],
      cropFocus: [],
      inputData: {
        category_id: "",
        price: "",
        crop_focus: "",
        measurement: "",
        delivery_window: { from: "", to: "" },
        usage_instruction: "",
        subcategory_id: "",
        packaging: "",
        description: "",
        currency: "",
        manufacture_name: "",
        manufacture_date: "",
        delivery_method: "",
        expiry_date: "",
        video: "",
        manufacture_country: "",
        product_type: "",
        pieces_carton: "",
        stock: "",
        kilograms: "0",
        liters: "0",
        grams: "",
      },
      countries: countriesObject.countries,
      currencies: ["NGN"],
      fileName: "",
      productID: false,
      isEditing: false,
    };
  },
  computed: {
    subCategoryByCategory() {
      return this.subCategories.filter(
        (item) => item.category_id == this.inputData.category_id,
      );
    },
  },
  watch: {
    "inputData.delivery_window.from"(newValue) {
      // Calculate the maximum date based on the selected "from" date
      const maxDate = new Date(newValue);
      maxDate.setDate(maxDate.getDate() + 14); // Add 14 days (2 weeks)

      // Format the maximum date as yyyy-mm-dd
      const formattedMaxDate = maxDate.toISOString().split("T")[0];

      // Set the maximum attribute of the "to" date input
      document
        .getElementById("to-date-input")
        .setAttribute("max", formattedMaxDate);
    },
  },

  mounted() {
    if (this.$route.params.id) {
      this.isEditing = true;
    }
    // check if routes contains a product Id as params to hence an edit page
    if (!isNaN(this.$route.params.id)) {
      this.productID = true;
    }
    // get todays date
    // let now = new Date();
    // const minDate = now.toISOString().split("T")[0];
    // // get and set the min attribute of the 'to' date input
    // document.getElementById("from-date-input").setAttribute("min", minDate);

    var _this = this;

    this.editor = new Quill(this.$refs.editor, {
      modules: {
        toolbar: [
          [
            {
              header: [1, 2, 3, 4, false],
            },
          ],
          ["bold", "italic", "underline", "link"],
        ],
      },
      //theme: 'bubble',
      theme: "snow",
      formats: ["bold", "underline", "header", "italic", "link"],
      placeholder: "Type description!",
    });
    this.editor.root.innerHTML = this.modelValue;
    this.editor.on("text-change", function () {
      _this.handleContentChange();
      return _this.update();
    });
    this.getCategory();
    console.log(this.categories);
    this.getSubCategory();
    this.getCropFocus();
  },
  methods: {
    update: function update() {
      this.$emit(
        "update:modelValue",
        this.editor.getText() ? this.editor.root.innerHTML : "",
      );
    },
    handleContentChange() {
      this.inputData.description = this.editor.root.innerHTML;
    },
    openFileDialog() {
      document.getElementById("hidden_input").click();
    },
    // fetch input data from End point
    getCategory() {
      MarketPlaceService.getInputCategories((response) => {
        this.categories = response.data;
      });
    },
    getCropFocus() {
      MarketPlaceService.getCropFocusCategories((response) => {
        this.cropFocus = response.data;
      });
    },
    getSubCategory() {
      MarketPlaceService.getSubCategories((response) => {
        this.subCategories = response.data;
      });
    },

    uploadFile() {
      const input = document.querySelector("#hidden_input");
      const file = input.files;
      this.inputData.files = file;

      // get the file name
      this.fileName = file[0].name;

      // preview the image

      // if at least one image is selected proceed to display the image
      if (file[0]) {
        // get the image path
        const imageSrc = URL.createObjectURL(file[0]);
        // select the image preview element
        const imagePreviewElement = document.querySelector(
          "#preview-selected-image",
        );
        // assign the path to the image preview element
        imagePreviewElement.src = imageSrc;
        imagePreviewElement.style.display = "block";
        // hide sibling img element
        document.getElementById("image_icon").style.display = "none";
      }
    },
    async saveData() {
      var data = new FormData();
      var index = 0;
      let vm = this;
      this.isLoading = true;
      while (this.inputData.files[index]) {
        data.append(`image_${index}`, this.inputData.files[index]);
        index++;
      }
      for (var field in this.inputData) {
        if (field != "files") {
          if (typeof this.inputData[field] == "object") {
            this.inputData[field] = JSON.stringify(this.inputData[field]);
          }
          data.append(field, this.inputData[field]);
        }
      }
      if (this.isEditing) {
        data.append("input_id", this.$route.params.id);

        MarketPlaceService.updateInput(data, (response) => {
          if (response && response.error == false) {
            Alert.success({
              message: "Input Updated successfully",
            });
            setTimeout(() => {
              window.location.assign("/dashboard/marketplace/myproducts");
            }, 2000);
          } else {
            Alert.error({
              message: "Failed To Updated Crop",
            });
          }
          vm.isLoading = false;
        });
      } else {
        // send data to the end-point
        MarketPlaceService.saveInput(data, (response) => {
          if (response && response.error == false) {
            Alert.success({
              message: "Input added successfully",
            });
            setTimeout(() => {
              window.location.assign("/dashboard/marketplace/myproducts");
            }, 2000);
          }
          vm.isLoading = false;
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;

  @include breakpoint-between(md, lg) {
    min-width: 60.5%;
  }

  @include breakpoint-between(lg, xl) {
    min-width: 69.5%;
  }

  @include breakpoint-between(xl, xxl) {
    min-width: 76%;
  }
}

.big-content {
  padding: 0px 4rem;
  background: #f5f5f5;

  h1 {
    @include textStyles(Poppins, 700, 24px, 120%);
    color: #4a4754;
    text-align: left;
    margin-block: 3rem;
  }
}
.edit_crop {
  background: #cd4647;
  box-shadow:
    0px 24px 48px 0px rgba(44, 39, 56, 0.08),
    0px 12px 24px 0px rgba(44, 39, 56, 0.04);
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-family: Maven Pro;
  padding: 10px 10px 10px 4%;
  margin-bottom: 30px;
}
a.application {
  background-color: #262c3f;
  color: white;
  border-color: #262c3f !important;

  &:hover {
    background-color: darken(#262c3f, 10%);
  }
}
button {
  float: right;
  background: #05b050;
  border: 1px solid #ededee;
  box-shadow:
    0px 2px 4px rgba(44, 39, 56, 0.0001),
    0px 4px 8px rgba(44, 39, 56, 0.08);
  border-radius: 4px;
  text-align: center;
  color: #e6f7ee;
  padding-inline: 3rem;
  @include textStyles("Maven Pro", 700, 16px, 24px);
  &:hover {
    background: darken(#05b050, 10%);
    border-color: #05b050 !important;
  }
}

.crop-wanted-section {
  background: #ffffff;
  border-radius: 4px;
  padding: 4%;
}

.form {
  .crop_details {
    @include textStyles("Maven Pro", 700, 16px, 24px);
    letter-spacing: 0.01em;
    color: #4a4754;
    margin-bottom: 1rem;
  }

  width: 100%;
  padding-bottom: 2rem;

  select,
  input,
  textarea {
    font-size: 14px;
    color: #000000;

    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      border-color: #05b050 !important;
      box-shadow: 0 0 !important;
    }
  }

  label {
    font-size: 14px;
    font-weight: 900;
    color: rgba(45, 55, 72, 0.8);
  }
}

div.vertical-line {
  width: 2px;
  padding: 1px;
  background: #f5f5f5;
}

.editor-wrapper {
  padding-bottom: 50px;

  .ql-container {
    min-height: 35vh;
  }
}

// using drop-zone
.dropzone {
  width: 100%;
  min-height: 250px;
  border: 2px dashed #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 147%;
  color: #4a4754;
  padding: 10px 10px 15px 10px;

  span {
    font-weight: 700;
    color: #05b050;
    cursor: pointer;

    + span {
      font-size: 14px;
    }
  }
}
#required {
  color: red;
  font-family: "Maven Pro";
  font-weight: bolder;
  font-size: 20px;
}
#optional {
  font-family: "Maven Pro";
  font-weight: bolder;
  font-size: 14px;
  color: rgba(113, 117, 125, 0.83);
  font-style: italic;
}
.zone {
  height: 150px;
  font-size: 12px;
}

#hidden_input {
  display: none;
}
.arrow-section {
  display: flex;
  align-items: center;
  gap: 20px;

  img {
    width: 25px;
    height: 25px;
  }
}
.hidden-input {
  height: 0px;
  width: 0px;
  margin: 0px;
  padding: 0px !important;
}
</style>
