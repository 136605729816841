<template>
  <DefaultNav>
    <div class="big-container">
      <div class="welcome-text">
        <h2>Notifications</h2>
      </div>
      <!-- notification wrapper -->

      <div class="notification_wrapper">
        <!-- back button -->
        <a id="back-btn" @click="goBack()"
          ><img src="@/assets/images/vectors/arrowleft.png" alt="back-btn"
        /></a>
        <!-- Notification body -->

        <a
          v-for="(notification, index) in paginatedNotifications"
          :key="index"
          class="notification_container"
          :href="notification.link"
        >
          <!-- product icon -->
          <div class="notification_icon">
            <!-- product icon -->
            <img
              v-if="notification.type.toLowerCase() === 'product'"
              src="@/assets/images/vectors/croprequest.svg"
              alt="notification icon"
            />
            <!-- offer icon -->
            <img
              v-if="notification.type.toLowerCase() === 'offer'"
              src="@/assets/images/vectors/negotiate.svg"
              alt="notification icon"
            />
            <!-- message icon -->
            <img
              v-if="notification.type.toLowerCase() === 'message'"
              src="@/assets/images/vectors/notificationmessage.svg"
              alt="notification icon"
            />
            <!-- offer accepted icon -->
            <img
              v-if="notification.title === 'Crop offer accepted'"
              src="@/assets/images/vectors/offeraccepted.svg"
              alt="notification icon"
            />
            <!-- offer declined icon -->
            <img
              v-if="notification.title === 'Crop offer declined'"
              src="@/assets/images/vectors/offerdeclined.svg"
              alt="notification icon"
            />
          </div>
          <div class="notification_main">
            <!-- notification_user_timeline -->
            <div class="notification_user_timeline">
              <div class="user">{{ notification.title }}</div>
              <div class="time">
                {{ notificationTimeAgo(notification.dateTime) }}
              </div>
            </div>
            <!-- notification-description -->
            <div class="notification-description-lowerEnd">
              <div class="notification_description">
                {{ notification.content }}.
                <router-link
                  v-if="
                    notification.type.toLowerCase() === 'offer' ||
                    notification.type.toLowerCase() === 'message'
                  "
                  to="/dashboard/negotiations"
                  class="open_negotiation ms-1"
                  >Open Negotiation</router-link
                >
              </div>
              <i
                class="bi bi-trash3-fill"
                @click.prevent="clearNotification(notification.meta.id)"
              ></i>
            </div>
          </div>
        </a>
        <div class="notification_footer">
          <!-- clear notification -->
          <div class="all_notification" @click="clearAllNotifications">
            Clear Notifications
          </div>
          <!-- Pagination controls -->
          <div class="pagination align-items-center gap-2">
            <button
              :disabled="currentPage === 1"
              class="btn btn-succ fs-small"
              @click="prevPage"
            >
              Previous
            </button>
            <span class="fs-small"
              >Page {{ currentPage }} of {{ totalPages }}</span
            >
            <button
              :disabled="currentPage === totalPages"
              class="btn btn-succ fs-small"
              @click="nextPage"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import Preloader from "@/layouts/shared/Preloader.vue";
export default {
  name: "MyProducts",
  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      notifications: [],
      currentPage: 1,
      itemsPerPage: 10, // Number of notifications to show per page
    };
  },
  computed: {
    // pagination
    paginatedNotifications() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.sortedNotification.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.sortedNotification.length / this.itemsPerPage);
    },
    recentNotifications() {
      const notificationList = this.notifications;
      return notificationList.reverse();
    },
    filteredNotifications() {
      return this.recentNotifications.filter(
        (notification) => notification.link,
      );
    },

    sortedNotification() {
      const allNotification = [
        ...this.recentNotifications,
        ...this.filteredNotifications,
      ];
      return allNotification.sort(
        (a, b) => new Date(b.dateTime) - new Date(a.dateTime),
      );
    },
    notificationTimeAgo() {
      return (dateTime) => {
        const currentTime = new Date();
        const notificationTime = new Date(dateTime);
        const timeDifferenceInMilliseconds = currentTime - notificationTime;

        const timeDifferenceInSeconds = Math.floor(
          timeDifferenceInMilliseconds / 1000,
        );
        const timeDifferenceInMinutes = Math.floor(
          timeDifferenceInSeconds / 60,
        );
        const timeDifferenceInHours = Math.floor(timeDifferenceInMinutes / 60);

        if (timeDifferenceInSeconds < 60) {
          return `just now`;
        }

        if (timeDifferenceInMinutes < 60) {
          return `${timeDifferenceInMinutes} minutes ago`;
        }

        if (timeDifferenceInHours < 24) {
          return `${timeDifferenceInHours} hours ago`;
        } else {
          const options = { year: "numeric", month: "long", day: "numeric" };
          return notificationTime.toLocaleString(undefined, options);
        }
      };
    },
  },
  mounted() {
    this.notifications = this.$store.state.notifications;
  },
  methods: {
    goBack() {
      window.history.back();
    },
    clearAllNotifications() {
      this.$store.commit("clearNotifications");
    },
    clearNotification(id) {
      this.$store.commit("clearNotificationById", id);
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
}

.welcome-text {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #262c3f;
  color: white;
  padding: 14px 30px;
  width: 100%;

  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    color: #ffffff;
  }
}
#back-btn img {
  max-width: 100%;
  height: 30px;
}
.btn-succ {
  background: #05b050;
  color: white;
  &:hover {
    background: darken(#05b050, 10%);
  }
}
.fs-small {
  font-size: 14px;
}
.notification_footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.notification_wrapper {
  padding: 60px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.notification_container {
  padding: 20px 50px;
  background: #ffffff;
  border: 1px solid rgba(0, 94, 60, 0.57);
  /* Light Shadow */
  box-shadow: 0px 2px 8px rgba(44, 39, 56, 0.02);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  gap: 30px;
  &:hover {
    background: darken(#ffffff, 10%);
  }
}
.notification_main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.notification_user_timeline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 17px;
}
.notification_user_timeline > div {
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  /* Black/Normal hover */
  color: #696671;
}
.notification_description {
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #0d0d0d;
}
.notification-description-lowerEnd {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: red;
}
i {
  font-size: 16px;
  &:hover {
    color: darken(red, 15%);
  }
}
.open_negotiation {
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  color: #0d0d0d;
  &:hover {
    color: rgba(0, 94, 60, 0.57);
  }
}
.all_notification {
  align-self: flex-end;
  &:hover {
    text-decoration-line: underline;
    text-decoration-color: rgba(0, 94, 60, 0.57);
    font-weight: 500;
    cursor: default;
  }
}
</style>
