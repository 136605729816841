<template>
  <div class="view">
    <TopHeader />
    <div class="layout">
      <SideBar />
      <div class="big-content">
        <slot />
        <!-- <div class="text-area">
          <p class="beta">
            Please note, this is a beta launch, improvements are ongoing
          </p>
        </div> -->

        <!-- <Footer /> -->
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "@/layouts/partials/TopHeader.vue";
import SideBar from "@/layouts/partials/SideBar.vue";
// import Footer from "@/layouts/partials/Footer.vue";
// import Socket.IO client side
import { io } from "socket.io-client";

export default {
  name: "DefaultNav",
  components: {
    TopHeader,
    SideBar,
    // Footer,
  },
  created() {
    if (!this.$store.state.authData) {
      window.location.replace("/login");
    }
    // initialize the Socket.IO client and establish a connection with the server
    const socket = io(process.env.VUE_APP_SOCKET_URL);

    socket.on("connect", () => {
      // console.log(socket.id);

      socket.emit("registerSocket", {
        user_id: this.userData.user.id,
        socket_id: socket.id,
      });
    });
    // Store socket in global window object
    window.AppSocket = socket;
  },
};
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  flex: 1;
  min-height: 0;
}
.big-content {
  flex: 1;
  height: 100%;
  overflow-y: scroll;
  min-height: 0;
}

.view {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

// .text-area {
//   width: 100%;
//   display: flex;
//   justify-content: center;
// }
// .beta {
//   font-family: "Poppins";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 13px;
//   line-height: 11px;
//   display: flex;
//   color: #ff0002;
//   text-align: center;
//   margin-top: 15px;
// }
</style>
