<template>
  <div id="kyb-one">
    <div class="form">
      <div class="dropzone_conatiner my-4">
        <div class="top-texts">
          <div class="mb-3">
            <h3>Business Verification</h3>
            <h4 id="required">All fields are required</h4>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="">Company Name</label>
            <input
              v-model="KybDataOne.name"
              type="text"
              class="form-control"
              required
            />
          </div>
          <div class="col">
            <label for="">Company Address</label>
            <input
              v-model="KybDataOne.address"
              type="text"
              class="form-control"
              required
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="exampleInputEmail1" class="form-label">Country</label>
            <select
              id="exampleInputEmail1"
              v-model="KybDataOne.country"
              type="text"
              class="form-control"
              aria-describedby="emailHelp"
              required
            >
              <option
                v-for="(country, index) in countries"
                :key="index"
                :value="country.country"
              >
                {{ country.country }}
              </option>
            </select>
          </div>
          <div class="col">
            <label for="">State</label>
            <select
              v-model="KybDataOne.state"
              class="form-control"
              name="state"
              required
            >
              <option
                v-for="(state, index) in selectStateByCountry"
                :key="index"
              >
                {{ state }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="">Contact Person</label>
            <input
              v-model="KybDataOne.contact_person"
              type="text"
              class="form-control"
              required
            />
          </div>
          <div class="col">
            <label for="">Company Website</label>
            <input
              v-model="KybDataOne.website"
              type="text"
              class="form-control"
              required
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="">Official Email</label>
            <input
              v-model="KybDataOne.email"
              type="email"
              class="form-control"
              required
            />
          </div>
          <div class="col">
            <label for="">Company Phone</label>
            <input
              v-model="KybDataOne.phone"
              type="tel"
              class="form-control"
              required
            />
          </div>
        </div>
        <div class="w-100">
          <label for="">Tax ID</label>
          <input
            v-model="KybDataOne.tax_id"
            type="number"
            class="form-control"
            required
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import MarketPlaceService from "@/services/marketplace";
import countriesObject from "@/data/countries";
export default {
  name: "KybOne",
  props: ["kybDataOne"],
  data() {
    return {
      KybDataOne: {
        name: "",
        address: "",
        state: "",
        country: "",
        contact_person: "",
        phone: "",
        website: "",
        email: "",
        tax_id: "",
      },
      countries: countriesObject.countries,
    };
  },
  computed: {
    selectStateByCountry: function () {
      return this.countries && this.KybDataOne.country != ""
        ? this.countries.filter(
            (item) => item.country == this.KybDataOne.country,
          )[0].states
        : [];
    },
  },

  mounted() {
    // prefile the user details
    if (this.companyData) {
      this.KybDataOne = {
        name: this.companyData.company_name ?? "",
        address: this.companyData.company_address ?? "",
        state: this.companyData.state ?? "",
        email: this.companyData.company_email ?? "",
        phone: this.companyData.company_phone ?? "",
        country: this.companyData.country ?? "",
        tax_id: this.companyData.tax_id ?? "",
      };
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
#kyb-one {
  padding-inline: 6em;
  margin-top: 30px;
}
.form {
  .crop_details {
    @include textStyles(inherit, 700, 16px, 24px);
    letter-spacing: 0.01em;
    color: #4a4754;
    margin-bottom: 1rem;
  }

  .m_top {
    margin-top: 3.4rem;
  }

  width: 100%;
  padding-bottom: 2rem;

  select,
  input {
    font-size: 14px;
    color: #000000;

    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      border-color: #05b050 !important;
      box-shadow: 0 0 !important;
    }

    &::placeholder {
      @include textStyles("Maven Pro", 700, 14px, 24px);
      color: #000000;
    }
  }

  label {
    font-size: 14px;
    font-weight: 900;
    color: rgba(45, 55, 72, 0.8);
  }
}

div.vertical-line {
  width: 2px;
  padding: 1px;
  background: #f5f5f5;
}

// using drop-zone
.dropzone {
  width: 100%;
  max-height: 450px;
  background: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 147%;
  color: #4a4754;
  padding: 25px 35px 25px 35px;
  margin-top: 10px;

  span {
    font-weight: 700;
    color: #05b050;
    cursor: pointer;

    + span {
      font-size: 14px;
    }
  }
}

#required {
  color: red;
  font-family: "Maven Pro";
  font-weight: bolder;
  font-size: 16px;
}

.zone {
  height: 150px;
  font-size: 12px;
}

.hidden_input {
  display: none;
}
.first-uploads {
  margin-top: 30px;
  margin-bottom: 30px;

  input {
    height: 40px;
    width: 100%;
    background-color: white;
  }
}
.picturess {
  display: flex;
  gap: 40px;
  margin-top: 50px;
}
.for-picture {
  width: 70%;
}

.file-button {
  width: 150px;
  height: 50px;
  border: 2px solid #05b050;
  border-radius: 4px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.kyc {
  width: 100%;
  height: 100%;
}
.kycpassport {
  width: 100%;
  height: 100%;
}

#image_icon {
  width: 100%;
  height: 300px;
}
#image_icon {
  width: 100%;
  height: 300px;
}
.top-texts {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #4a4754;
  }
}
.file-upload {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.resize-img {
  width: 100%;
  max-height: 350px;
}
</style>
