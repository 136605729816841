<template>
  <div class="profile-container">
    <div class="tab-section">
      <div
        class="account-details-tab"
        :class="{ 'active-tab': activeTab === 'accountDetails' }"
        @click="switchTab('accountDetails')"
      >
        <span>Account Details</span>
      </div>
      <div
        v-if="userData.user.account_type == 'company'"
        class="company-details-tab"
        :class="{ 'active-tab': activeTab === 'companyDetails' }"
        @click="switchTab('companyDetails')"
      >
        <span>Company Details</span>
      </div>
      <div
        class="certification-tab"
        :class="{ 'active-tab': activeTab === 'warehouse' }"
        @click="switchTab('warehouse')"
      >
        <span>Warehouse</span>
      </div>
      <div
        class="certification-tab"
        :class="{ 'active-tab': activeTab === 'certificate' }"
        @click="switchTab('certificate')"
      >
        <span>Certifications</span>
      </div>
      <div
        v-if="userData.user.account_type == 'company'"
        class="photos-videos-tab"
        :class="{ 'active-tab': activeTab === 'media' }"
        @click="switchTab('media')"
      >
        <span>Photos and videos</span>
      </div>
    </div>
    <div class="content-section">
      <ProfileAccountDetails v-if="activeTab === 'accountDetails'" />
      <ProfileCompanyDetails v-if="activeTab === 'companyDetails'" />
      <ProfileWarehouse v-if="activeTab === 'warehouse'" />
      <ProfileCertificate v-if="activeTab === 'certificate'" />
      <ProfileMedia v-if="activeTab === 'media'" />
    </div>
  </div>
</template>

<script>
import ProfileAccountDetails from "@/pages/dashboard/settings/ProfileAccountDetails.vue";
import ProfileCompanyDetails from "@/pages/dashboard/settings/ProfileCompanyDetails.vue";
import ProfileCertificate from "@/pages/dashboard/settings/ProfileCertificate.vue";
import ProfileWarehouse from "@/pages/dashboard/settings/ProfileWarehouse.vue";
import ProfileMedia from "@/pages/dashboard/settings/ProfileMedia.vue";

export default {
  name: "Settings",
  components: {
    ProfileAccountDetails,
    ProfileCompanyDetails,
    ProfileCertificate,
    ProfileWarehouse,
    ProfileMedia,
  },
  data() {
    return {
      activeTab: "accountDetails",
    };
  },
  methods: {
    switchTab(tab) {
      this.activeTab = tab;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.profile-container {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: flex-start;
  margin-top: 20px;
}
.tab-section {
  border-radius: 8px;
  background: #fff;
  padding: 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div {
    border-radius: 11px;
    border: 1px solid rgba(0, 0, 0, 0.09);
    background: #fff;
    display: flex;
    padding: 12px 64px;
    justify-content: center;
    align-items: center;
    min-width: 260px;
    cursor: default;
    &:hover {
      background: darken(white, 10%);
    }
    span {
      font-family: Inter;
      color: #34323b;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.14px;
    }
  }
  div.active-tab {
    border-radius: 9px;
    background: #34323b;
    transition: all 0.2s ease-in;
    span {
      color: #ffffff;
    }
  }
}
.content-section {
  border-radius: 8px;
  background: #ffffff;
  width: 100%;
  max-height: 80vh;
  overflow-y: scroll;
}
</style>
