import config from "@/config";
import axios from "axios";

const GET_DVS_SCORING_DATA = () => "merchantdvs/questions";
const DVS_SCORING_FARMER = () => `merchantdvs/scoredvs`;
const GET_ALL_DVS_SCORES = () => `merchantdvs/scoredvs/getscores`;
const GET_FARMER_CREDIT_SCORE = (id) => `merchantdvs/scoredvs/getrecords/${id}`;

export default {
  //
  // ────────────────────────────────────────────────────────────── I ──────────
  //   :::::: D V S   S C O R I N G : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────────
  //
  getDVSData: function (callback) {
    axios
      .get(config.CREDITSCORING_URL + GET_DVS_SCORING_DATA())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error);
      });
  },
  scoringDVS: function (dvsData, callback) {
    axios
      .post(config.CREDITSCORING_URL + DVS_SCORING_FARMER(), dvsData)
      .then((response) => {
        // console.log(response);
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  },
  getAllDvsScores: function (callback) {
    axios
      .get(config.CREDITSCORING_URL + GET_ALL_DVS_SCORES())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error);
      });
  },
  /* ------------------------- get all farmers created ------------------------ */
  // getAllFarmers: function (callback) {
  //   axios
  //     .get(config.CREDITSCORING_URL + GET_ALL_FARMERS())
  //     .then((response) => {
  //       callback(response.data);
  //     })
  //     .catch((error) => {
  //       callback(error);
  //     });
  // },
  /* -------- get data for a single farmer using the passed in id param ------- */
  getCreditScore: function (id, callback) {
    let contentReturned = [];
    axios
      .get(config.CREDITSCORING_URL + GET_FARMER_CREDIT_SCORE(id))
      .then((response) => {
        contentReturned.push({ error: false });
        contentReturned.push(response.data);
        callback(contentReturned);
      })
      .catch((error) => {
        if (error.response.status == 404) {
          // Handle 404 error
          contentReturned.push({ error: true });
          callback(contentReturned);
        } else {
          // Handle other errors
          contentReturned.push({ error: true });
          contentReturned.push(error.response.data);
          callback(contentReturned);
        }
      });
  },
};
