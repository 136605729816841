<template>
  <DefaultNav>
    <div class="big-container">
      <div v-if="product" class="big-content">
        <div v-if="product" class="topic">
          <div class="left">
            <a href="#" class="go-row"
              ><img
                src="@/assets/images/vectors/arrowleft.png"
                alt=""
                @click="$router.back()"
            /></a>
            <div class="product_details_container">
              <div class="product-quantity">
                <h1 class="product-name">{{ product.subcategory.name }}</h1>
                <p class="price2">Quantity: {{ product.specification.qty }}</p>
              </div>
              <div class="farmer">
                <!-- 1 -->
                <div class="product-c">
                  <div class="pd-user-avatar">
                    <img
                      v-if="avatar"
                      :src="avatar"
                      alt="useravatar"
                      class="img-fluid avatar-image"
                    />
                    <img
                      v-if="!avatar"
                      src="@/assets/images/backgrounds/Ellipse11.png"
                      alt="useravatar"
                      class="img-fluid avatar-image"
                    />
                  </div>
                  <div class="pd-details">
                    <div>
                      <span
                        v-if="product.user.account_type == 'individual'"
                        class="user-name"
                        >{{
                          product.user.first_name + " " + product.user.last_name
                        }}</span
                      >
                      <span
                        v-if="product.user.account_type == 'company'"
                        class="user-name"
                        >{{ product.user.company.company_name }}</span
                      >

                      <span class="verified-c">
                        <img
                          src="@/assets/images/vectors/verified.svg"
                          alt=""
                          class="ms-1"
                        />
                        <span class="ms-3">{{ kycStatus }}</span>
                        <!-- <span class="ms-2">{{ product.user.type }}</span> -->
                      </span>
                    </div>
                    <div class="d-flex">
                      <div
                        class="user-btn view-profile text-white"
                        @click="toCompanyProfile"
                      >
                        View profile
                      </div>

                      <span
                        :class="[
                          'ms-3',
                          'user-btn',
                          isSubscribe ? 'subscribed' : 'subscribe',
                        ]"
                        @click="subscribeUser"
                        >{{ isSubscribe ? "Subscribed" : "Subscribe" }}</span
                      >
                    </div>
                  </div>
                </div>
                <!-- 2 -->
                <div
                  class="d-flex flex-column justify-content-around C-name-container"
                >
                  <div class="C-name">
                    <span class="Crop-category">Crop Category:</span>
                    <span class="ms-2 fw-bolder">{{
                      product.subcategory.name
                    }}</span>
                  </div>
                  <div>
                    <span
                      :class="[
                        'mt-3',
                        'ms-2',
                        'user-btn',
                        'product_subscription',
                        isProductSubscribe ? 'subscribed' : 'subscribe',
                      ]"
                      @click="subscribeCategory"
                    >
                      {{ isProductSubscribe ? "Subscribed" : "Subscribe" }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <!-- for bid -->
            <p class="price1">
              {{ product.currency }}{{ product.specification.price }}/{{
                product.specification.test_weight.toUpperCase()
              }}
            </p>
            <div class="down-btns">
              <a
                v-if="productType == 'auction' && !stock"
                href="javascript:void()"
                type="button"
                data-bs-target="#exampleModalToggle"
                data-bs-toggle="modal"
                class="fulfil"
                @click="bid($route.params.id)"
                >Bid</a
              >
              <a
                v-if="productType != 'auction' && !stock"
                href="javascript:void()"
                type="button"
                data-bs-target="#exampleModalToggle"
                data-bs-toggle="modal"
                class="fulfil"
                >Fulfil/Accept Offer</a
              >
              <a
                v-if="productType != 'auction' && isNegotiable && !stock"
                href="#"
                class="negotiate crop"
                @click="
                  $router.push({
                    name: 'CropNegotiation',
                    params: { id: product.id },
                  })
                "
                >Negotiate</a
              >
              <a
                v-if="stock"
                href="javascript:void()"
                class="bg-danger p-3 text-center text-white"
                >Out of stock</a
              >
            </div>
          </div>
        </div>
        <div v-if="product && product.images.length" class="image-area">
          <h1>Product Images</h1>
          <div class="big-img">
            <img class="img-fluid" :src="product.images[i]" alt="" />
          </div>
          <div v-if="product.images.length > 1" class="small-images">
            <img
              v-for="(image, index) in product.images"
              :key="index"
              :src="image"
              alt=""
              @click="changeImage(index)"
            />
          </div>
        </div>
        <div id="accordionExample" class="accordion margin-bot">
          <div class="accordion-item">
            <h2 id="headingOne" class="accordion-header">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Description
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <p v-html="product.description"></p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 id="headingTwo" class="accordion-header">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Specifications
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="details">
                  <div class="actual-details">
                    <div class="left-line">
                      <div class="each-detail">
                        <h4>Product Categories</h4>
                        <p>{{ product.category.name }}</p>
                      </div>
                      <div class="each-detail">
                        <h4>Color</h4>
                        <p>{{ product.specification.color }}</p>
                      </div>
                      <div class="each-detail">
                        <h4>Moisture</h4>
                        <p>{{ product.specification.moisture }}%</p>
                      </div>
                      <div class="each-detail">
                        <h4>Grain size</h4>
                        <p>{{ product.specification.grain_size }}</p>
                      </div>
                    </div>
                    <div class="middle-line">
                      <div class="each-detail">
                        <h4>Foreign Matter (FM)</h4>
                        <p>{{ product.specification.foreign_matter }}%</p>
                      </div>
                      <div class="each-detail">
                        <h4>Broken Grains</h4>
                        <p>{{ product.specification.broken_grains }}%</p>
                      </div>
                      <div class="each-detail">
                        <h4>Weevil</h4>
                        <p>{{ product.specification.weevil }}%</p>
                      </div>
                    </div>
                    <div class="middle-line">
                      <div class="each-detail">
                        <h4>Damaged kernel</h4>
                        <p>{{ product.specification.dk }}%</p>
                      </div>
                      <div class="each-detail">
                        <h4>Rotten Shriveled</h4>
                        <p>{{ product.specification.rotten_shriveled }}%</p>
                      </div>
                      <div class="each-detail">
                        <h4>Test Weight</h4>
                        <p>{{ product.specification.test_weight }}</p>
                      </div>
                    </div>
                    <div class="right-line">
                      <div class="each-detail">
                        <h4>Hardness</h4>
                        <p>{{ product.specification.hardness }}</p>
                      </div>
                      <div class="each-detail">
                        <h4>Split</h4>
                        <p>{{ product.specification.splits }}%</p>
                      </div>
                      <div class="each-detail">
                        <h4>Oil Content</h4>
                        <p>{{ product.specification.oil_content }}%</p>
                      </div>
                      <div class="each-detail">
                        <h4>Infestation</h4>
                        <p>{{ product.specification.infestation }}%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 id="headingThree" class="accordion-header">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Merchant
              </button>
            </h2>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="map">
                  <div
                    id="map-container-google-2"
                    class="z-depth-1-half map-container"
                  >
                    <iframe
                      class="main-map"
                      src="https://maps.google.com/maps?q=chicago&t=&z=13&ie=UTF8&iwloc=&output=embed"
                      frameborder="0"
                      style="border: 0"
                      allowfullscreen
                    />
                  </div>

                  <!--Google Maps-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="productType != 'auction'" class="big-container2">
      <div v-if="product" class="topic2">
        <div class="left">
          <div class="go-row">
            <h1 class="product-name">
              {{ product.subcategory.name }} -
              {{ product.specification.color }}
            </h1>
          </div>
          <p class="price2">Quantity: {{ product.specification.qty }}</p>
        </div>

        <div class="right">
          <!-- for bid -->
          <p class="price1">
            {{ product.currency }}{{ product.specification.price }}/{{
              product.specification.test_weight.toUpperCase()
            }}
          </p>
          <div class="down-btns">
            <a
              v-if="productType == 'auction'"
              href="javascript:void()"
              type="button"
              data-bs-target="#exampleModalToggle"
              data-bs-toggle="modal"
              class="fulfil"
              @click="bid($route.params.id)"
              >Bid</a
            >
            <a
              v-if="productType != 'auction' && !stock"
              href="javascript:void()"
              type="button"
              data-bs-target="#exampleModalToggle"
              data-bs-toggle="modal"
              class="fulfil"
              >Fulfil/Accept Offer</a
            >
            <a
              v-if="productType != 'auction' && isNegotiable && !stock"
              href="#"
              class="negotiate crop"
              @click="
                $router.push({
                  name: 'CropNegotiation',
                  params: { id: product.id },
                })
              "
              >Negotiate</a
            >
            <a
              v-if="stock"
              href="javascript:void()"
              class="bg-danger p-3 text-center text-white"
              >Out of stock</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->

    <!-- Product description modal -->
    <div
      id="exampleModalToggle"
      class="modal fade"
      aria-hidden="true"
      aria-labelledby="exampleModalToggleLabel"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">Accept Offer</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="proceed">
              <div class="modal-body">
                <input
                  v-model="quantity"
                  type="text"
                  class="modal-input"
                  placeholder="Quantity"
                  required
                />
                <div v-if="product" class="actual-detailss">
                  <div class="left-lines">
                    <div class="each-detail">
                      <h4>Product Category</h4>
                      <p>{{ product.category.name }}</p>
                    </div>
                    <div class="each-detail">
                      <h4>Color</h4>
                      <p>{{ product.specification.color }}</p>
                    </div>
                    <div class="each-detail">
                      <h4>Moisture</h4>
                      <p>{{ product.specification.moisture }}%</p>
                    </div>
                    <div class="each-detail">
                      <h4>Foreign Matter (FM)</h4>
                      <p>{{ product.specification.foreign_matter }}%</p>
                    </div>
                    <div class="each-detail">
                      <h4>Broken Grains</h4>
                      <p>{{ product.specification.broken_grains }}%</p>
                    </div>
                    <div class="each-detail">
                      <h4>Weevil</h4>
                      <p>{{ product.specification.weevil }}%</p>
                    </div>
                    <div class="each-detail">
                      <h4>Damaged kernel</h4>
                      <p>{{ product.specification.dk }}%</p>
                    </div>
                    <div class="each-detail">
                      <h4>Rotten Shriveled</h4>
                      <p>{{ product.specification.rotten_shriveled }}%</p>
                    </div>
                  </div>
                  <div class="right-line">
                    <div class="each-detail">
                      <h4>Test Weight</h4>
                      <p>{{ product.specification.test_weight }}</p>
                    </div>
                    <div class="each-detail">
                      <h4>Hardness</h4>
                      <p>{{ product.specification.hardness }}</p>
                    </div>
                    <div class="each-detail">
                      <h4>Split</h4>
                      <p>{{ product.specification.splits }}%</p>
                    </div>
                    <div class="each-detail">
                      <h4>Oil Content</h4>
                      <p>{{ product.specification.oil_content }}%</p>
                    </div>
                    <div class="each-detail">
                      <h4>Infestation</h4>
                      <p>{{ product.specification.infestation }}%</p>
                    </div>
                    <div class="each-detail">
                      <h4>Grain size</h4>
                      <p>{{ product.specification.grain_size }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div class="accept-box">
                  <input type="checkbox" required />
                  <p>I accept the offer and proceed</p>
                </div>
                <div class="btn-row">
                  <span
                    id="modalProceedBtn"
                    type="submit"
                    class="fulfil mb-3"
                    data-bs-target="#exampleModalToggle2"
                    data-bs-toggle="modal"
                  >
                    Negotiation policy
                  </span>

                  <button id="modalProceedBtn" type="submit" class="fulfil">
                    Proceed
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Negotiation policy -->
    <div
      id="exampleModalToggle2"
      class="modal fade"
      aria-hidden="true"
      aria-labelledby="exampleModalToggleLabel2"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h1 id="exampleModalToggleLabel2" class="modal-title fs-5">
              Negotiation policy
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <h1>Negotiation Policy</h1>
            <p>
              To protect your orders, please negotiate and communicate through
              Zowasel’s website and mobile app ONLY.
            </p>
            <p>
              You can use this space to negotiate with Buyers, Sellers or
              Zowasel Agents. To continue, you must first accept the Terms and
              Conditions below.
            </p>

            <p>
              <strong
                >I agree to the Terms and Conditions for posting my supply and
                trading as part of the Zowasel Network</strong
              >
            </p>
            <p>
              <strong>I have legal permission to sign this agreement</strong>
            </p>

            <h2>Marketplace Negotiation Agreement</h2>
            <ol>
              <li>
                <p>
                  <strong>Customer Agreement.</strong> By electronically
                  accepting this Customership Agreement (the “Agreement”), the
                  entity or individual identified as a Customer in the
                  Marketplace by Zowasel Customer Application (the “Customer”)
                  agrees that this Agreement shall be legally binding on the
                  Customer.
                </p>
                <p>
                  A Customer may participate in the Marketplace as a Seller that
                  offers perishable grains or cash crops (“Commodity”) for sale
                  in the Marketplace (a “Seller”), or as a Buyer who purchases
                  Commodities for sale in the Marketplace (a “Buyer”). The
                  Marketplace is a network for Sellers and Buyers to sell,
                  purchase and/or consign Produce through the utilization of the
                  Zowasel platform. A Buyer can also participate in the
                  Marketplace as a Seller, but any Customer who seeks to buy or
                  sell must pay the platform transactional fee described in
                  Section 4 and meet all of the requirements placed on a
                  customer outlined in this Agreement. The term “Buyer” is
                  inclusive of a Customer who acquires Commodity from a Seller
                  on a consignment basis for sale to third parties in exchange
                  for a sales commission.
                </p>
              </li>
              <li>
                <p>
                  <strong>Participation Period:</strong> The participation
                  period for the Marketplace shall be automatic via electronic
                  acceptance of this Agreement. This Agreement shall renew
                  automatically unless Zowasel. (“Company”) or the Customer
                  provides notice that the agreement is terminated.
                </p>
              </li>
              <li>
                <p>
                  <strong>Customer Benefits:</strong> The Marketplace benefits
                  encompass the following:
                </p>
                <ul>
                  <li>Assignment of a Company account representative</li>
                  <li>Zowasel Marketplace profile setup</li>
                  <li>Customer search and performance vetting</li>
                  <li>
                    12 months of access to Insights Pro and Daily Market Report
                  </li>
                  <li>
                    Advisory on market performance throughout the service period
                    term
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  <strong>Diligence and Transaction Fee:</strong> Customer
                  participation in the Marketplace is subject to it passing the
                  Company’s diligence requirements, at the sole discretion of
                  the Company. Any Customer participating as a Seller in the
                  Marketplace must pay the Company a 1.5% Transaction fee
                  (“Marketplace Fee”) upon the execution of this Agreement.
                </p>
              </li>
              <li>
                <p>
                  <strong>Information furnished by the Customer:</strong> The
                  customer is required to furnish the Company with information
                  or material about its entity, business or products/services as
                  part of the registration process for the Marketplace and to
                  establish a Customer account. Customer represents, warrants
                  and agrees that (a) such information and material whether
                  submitted during the registration process or thereafter
                  throughout the continuation of its use of the Zowasel platform
                  is true, accurate, current and complete, and (b) it will
                  maintain and promptly amend all information and material to
                  keep it true, accurate, current and complete.
                </p>
              </li>
              <li>
                <p>
                  <strong>Fulfillment Fee:</strong> As consideration for
                  Customership in the Zowasel Marketplace, a Customer
                  functioning as a Seller hereby agrees to pay the Company a
                  fulfilment fee of 1.5% of the final gross sales value of each
                  shipment, net of documented adjustments allowed to the Buyer,
                  sold to any Buyer on the Marketplace. A “Marketplace Buyer” is
                  a grower matched with a Customer by the Company. Similarly, as
                  consideration for a customer in the Zowasel Marketplace, a
                  Customer functioning as a Buyer hereby agrees to pay the
                  Company a fulfilment fee of 1.5% of the final gross sales
                  value of each shipment, net of documented price adjustments
                  made by Section 8, sourced from any Seller in the Marketplace.
                </p>
              </li>
              <li>
                <p>
                  <strong>Transaction Term Sheets:</strong> Each Customer agrees
                  that for each sale of Commodity conducted with a Customer of
                  the Marketplace, the Seller and the Buyer shall jointly
                  execute and submit a transaction term sheet in the form
                  currently provided by the Company that includes, among other
                  things, the type of Produce, estimated volume, estimated start
                  terms, nature of the transaction, pricing, commission and
                  other material terms. Customer shall immediately notify the
                  Company in writing if there are any changes in any transaction
                  from the terms previously provided to the Company in the
                  transaction term sheet, and Customer shall provide signed
                  writing to the Company executed by all parties to the amended
                  term sheet.
                </p>
              </li>
              <li>
                <p>
                  <strong>Commodity Inspection:</strong> The Company shall have
                  the right at any time and at its own discretion to inspect the
                  shipped Commodity that is offered for sale on the Marketplace
                  upon its arrival into the possession of the Buyer, at the
                  location where the Buyer possesses the Commodity or at a
                  subsequent destination of the Produce. The Company shall pay
                  for the cost of such inspection, provided that if the
                  inspection determines that less than 95% of the Commodity in
                  any one truckload is not according to the grade agreed to in
                  the term sheet, the Seller hereby agrees that it shall pay for
                  such inspection. If the permissible percentage for acceptable
                  grade for the commodity at issue is less than 95% under the
                  applicable standards outlined in the grain and cash crops
                  applied by PO issued and buyers quality specification standard
                  shall apply for determining whether Seller must pay for the
                  inspection. The inspection described herein is at the sole
                  discretion of the Company and utilized solely for the
                  Company’s administration of the Marketplace, and is not a
                  replacement for any inspection or regulatory obligation
                  required by any other government agency.
                </p>
              </li>
              <li>
                <p>
                  <strong>Commodity Acceptance:</strong> A Customer acting as a
                  Buyer hereby agrees that, within one (1) business day of
                  receiving a shipment of the commodity from a Marketplace
                  Seller, it shall indicate its acceptance and the quantity and
                  quality of the produce on the Zowasel platform. The Buyer
                  agrees that it shall only make quality deductions from a
                  shipment from a Marketplace Seller by the following rules:
                </p>
                <ol>
                  <li>
                    The Buyer must provide a timely Good Receipt Note inspection
                    (within 12 hours of receipt for truck shipments and 24 hours
                    of receipt for rail shipments as set forth under the
                    relevant or applicable market prices unless previously
                    agreed upon with the Seller in a fully-executed writing
                    provided to Company.
                  </li>
                  <li>
                    Quality deductions are only permitted if justified by a
                    third-party audit where the auditor is trained to grade to
                    Buyer quality specification standards unless Buyer and
                    Seller waive this requirement in fully-executed writing
                    provided to Company.
                  </li>
                  <li>
                    If the Buyer accepts anything less than shipped volume or
                    adjusts prices below market rates, it must provide written
                    reasoning as to why on the Zowasel platform.
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <strong>Liquidations:</strong> When a Buyer acts as a
                  consignment agent (hereafter a “Consignee Customer”), the
                  Consignee Customer agrees that, for each sale of Produce
                  sourced from a Marketplace Seller, upon such sale, Consignee
                  Customer shall pay to the Company an amount equal to (a) all
                  payments due from Consignee Customer to the Marketplace Seller
                  for that shipment (the “Liquidation Payment”), plus (b) the
                  fulfilment fee owed by Buyer to be calculated by Section 6
                  (“Fulfillment Fee”). When a Seller sells Produce to a Buyer
                  rather than consigning the Produce, the Buyer shall pay to the
                  Company an amount equal to (a) the amount invoiced for the
                  Commodity which shall constitute the “Liquidation Payment” for
                  non-consignment sales, plus (b) the Fulfillment Fee. The
                  Company will pay the Marketplace Seller the applicable
                  Liquidation Payment less all amounts owed by the Marketplace
                  Seller to the Company. Payment and accounting remittance
                  (liquidation files) will be due to the Company as per
                  government regulations within thirty (30) days of receipt from
                  the date the goods are received and accepted by the ultimate
                  receiver of the Commodity from the Consignee Customer for
                  consignment sales. For each shipment, the Consignee Customer
                  shall provide the Company with an itemized liquidation report
                  showing itemized sales prices and all commission fees and
                  other deductions, which shall be provided via the Company’s
                  liquidation email address, uploaded into the Zowasel platform
                  via CSV or PDF files, or sent via ERP integration. This report
                  shall be provided at the same time as the Consignee Customer
                  remits the Liquidation Payment on the Zowasel platform.
                </p>
              </li>
              <li>
                <p>
                  <strong>Restrictions on Advances from Buyer:</strong> A Buyer
                  agrees that in any transaction with a Marketplace Seller,
                  including but not limited to when a Buyer acts as a Consignee
                  Customer, Buyer will not offer to or provide a Marketplace
                  Seller with any loans or advances (including preseason and/or
                  pick & pack advances) without the prior written approval of
                  the Company. The Company shall have the primary right to
                  provide Pick and Pack, See and Buy or Transportation funding
                  for each Marketplace Seller shipment to the Buyer or
                  Marketplace Seller for a separate fee, and/or provide a
                  Marketplace Seller with pre-season funding for a separate fee.
                </p>
              </li>
              <li>
                <p>
                  <strong>Site Inspections:</strong> A Customer shall permit the
                  Company to visit and inspect its farms, warehouses, factories
                  and other facilities involved in the growing, harvesting,
                  packaging, receiving or storing of Commodity, whether such
                  warehouse, farm and other facilities are owned or controlled
                  by the Customer or the Customer’s third-party vendors, as long
                  as the Customer is active, and at any time and the sole
                  discretion of Company. A Customer shall provide written
                  instruction to any third party which owns or controls the
                  facilities and properties referenced herein to provide access
                  to the Company for inspection.
                </p>
              </li>
              <li>
                <p>
                  <strong
                    >Resolution of Trade Disputes through Mandatory
                    Mediation:</strong
                  >
                  When any dispute arises between a Buyer and a Seller involving
                  a transaction of Produce on the Marketplace, the following
                  provisions apply:
                </p>
                <ol>
                  <li>
                    <strong>Submission to Company as Mediator:</strong> In a
                    disputed transaction between a Seller and a Buyer (including
                    but not limited to transactions involving a Consignee
                    Customer), the dispute must first be submitted to Company
                    for the purposes of mediation. No Customer may bring a legal
                    action for breach of a Commodity transaction involving the
                    Marketplace without fully participating in mediation, and
                    Company shall act as the Mediator. A Customer shall initiate
                    mediation by contacting Company in writing via email at
                    legal@zowael.com wherein the Customer shall describe the
                    substance of the dispute and the other Customer(s) involved.
                    Each Customer expressly consents to Company’s role as
                    Mediator. The company shall designate a customer of its
                    in-house legal department to act as the presiding mediator.
                    The presiding mediator will contact each Customer who is a
                    party to the dispute and set forth an expedited calendar for
                    resolving the dispute. The presiding mediator may request a
                    briefing, and documentary support, and may hold at least one
                    online hearing to hear arguments and negotiate a resolution
                    of the dispute. In resolving the terms of any deal, the
                    presiding mediator may only consider communications and
                    agreements between the Buyer and Seller that were
                    contemporaneously provided and/or exchanged through the
                    Company’s platform. All communications provided in
                    conjunction with the mediation shall be treated as
                    confidential settlement communications. All disputes shall
                    be resolved by the presiding mediator within thirty (30)
                    days of submission of the dispute to the Company, provided
                    that there is no substantial delay in locating and notifying
                    every party to the dispute. The company may, in its sole
                    discretion, choose to waive its role to act as Mediator, at
                    which time each Customer shall have the right to file a
                    legal action to resolve the claim.
                  </li>
                  <li>
                    <strong>Decision of Company:</strong> If the parties
                    privately resolve their dispute after submission to the
                    Company for mediation, they shall mutually contact Company
                    to advise that the matter is concluded and the Company shall
                    issue no opinion or take any further action. If the parties
                    do not reach their amicable resolution, the Company shall
                    issue a written decision concerning the Company’s view of
                    the merits of the dispute (the “Decision”). The Decision is
                    not binding and shall not prevent a Customer from bringing
                    litigation concerning the dispute after issuance of the
                    Decision. However, the Company may, in its sole discretion,
                    suspend or terminate a Customer’s Customership in the
                    Marketplace if Company determines that a Customer breached
                    its obligations to another Customer in conjunction with a
                    transaction on the Marketplace. The company may condition
                    further participation in the Marketplace to a Customer’s
                    compliance with the Decision, including any payments that
                    the Company determines in the Decision are owed to another
                    Customer. Company may publish within the Marketplace that a
                    Customer has had one or more unresolved claims against it if
                    a Customer does not comply with a Decision.
                  </li>
                  <li>
                    <strong
                      >Limited Release of Customer Claims Against
                      Company:</strong
                    >
                    Customer agrees that it has voluntarily contracted to
                    designate Company as Mediator, that participation in the
                    Mediation is mandatory but the Decision is not legally
                    binding, and that Customer hereby releases Company and
                    Company’s officers, directors, principals and of any claims
                    relating to Company’s provision of services as Mediator.
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <strong>Data:</strong> Customer hereby grants the Company a
                  non-exclusive, worldwide, perpetual, transferable,
                  royalty-free license to use, copy, transmit, sub-license,
                  index, store, aggregate, and display Transaction Data in
                  connection with the operation and maintenance of the
                  Marketplace, for technical support of the Zowasel platform, to
                  perform the payment liquidations, to otherwise perform
                  obligations to the Customer, to monitor Customer’s suitability
                  for continued inclusion in the Marketplace, to perform account
                  management services, and to publish, display, and distribute
                  de-identified, aggregated information derived from Transaction
                  Data and other information from Customer’s use of the Zowasel
                  platform and participation in the Marketplace, for marketing,
                  research or compliance purposes, for purposes of improving the
                  Company’s products and services or the Zowasel platform, and
                  for purposes of publishing reports on the Marketplace or in
                  academic or trade publications.
                </p>
              </li>
              <li>
                <p>
                  <strong>Customer Commitments:</strong> A Customer hereby
                  commits that it shall not (i) make available any material or
                  information that is false, inconsistent with its identity as a
                  Customer, misleading, infringing on any third party’s rights,
                  unlawful, harmful, threatening, abusive, defamatory, vulgar,
                  obscene or otherwise objectionable, (ii) provide false or
                  fraudulent information to the Company, (iii) use the Company’s
                  services or Zowasel platform to carry out or assist with any
                  illegal or criminal activities, or (iv) use the Company’s
                  services or Zowasel platform in violation of any federal,
                  state or local laws, regulations, or orders.
                </p>
              </li>
              <li>
                <p>
                  <strong>Intellectual Property:</strong> Customer acknowledges
                  and agrees that the Company shall own all right, title, and
                  interest in and to the Marketplace and the Zowasel platform
                  and the Company’s proprietary software, algorithms, databases,
                  and web pages, and all updates, upgrades, and modifications
                  thereto, including any derivative works. Customer shall not
                  reverse engineer, disassemble or decompile any part of the
                  Marketplace, the Zowasel platform or Company’s proprietary
                  software. Customer acknowledges and agrees that any feedback,
                  comments, or suggestions it may provide regarding the
                  Marketplace or the Zowasel platform is entirely voluntary and
                  Company will be free to use such feedback, comments, or
                  suggestions as it sees fit and without any obligation to
                  Customer.
                </p>
              </li>
              <li>
                <p>
                  <strong>Disclaimers:</strong> THE COMPANY DOES NOT GUARANTEE
                  THE AVAILABILITY OF THE MARKETPLACE, THE ZOWASEL PLATFORM, OR
                  THAT THE COMPANY SHALL MAKE ANY SPECIFIC SALES, TRANSACTIONS
                  OR VOLUMES OF COMMODITIES OR CUSTOMERS TO CUSTOMER THROUGH THE
                  MARKETPLACE. THE MARKETPLACE, THE ZOWASEL PLATFORM AND ALL
                  RELATED SERVICES ARE PROVIDED "AS IS," AND “AS AVAILABLE”
                  WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED,
                  INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
                  MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OR THE
                  WARRANTY OF NON-INFRINGEMENT. NO WARRANTY IS MADE REGARDING
                  THE QUALITY OF ANY COMMODITY SOLD BY ANY CUSTOMER, THE
                  SERVICES OF ANY CUSTOMER, OR THE MARKETPLACE. CUSTOMER IS
                  SOLELY RESPONSIBLE FOR ALL OF ITS COMMUNICATIONS AND
                  INTERACTIONS WITH OTHER CUSTOMERS OF THE MARKETPLACE AND WITH
                  OTHER PERSONS WITH WHOM CUSTOMER COMMUNICATES OR INTERACTS AS
                  A RESULT OF ITS USE OF THE MARKETPLACE, INCLUDING BUT NOT
                  LIMITED TO ANY ZOWASEL AGENTS.
                </p>
              </li>
              <li>
                <p>
                  <strong>Liability:</strong> IN NO EVENT SHALL COMPANY BE
                  LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR
                  CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOSS OF PROFITS,
                  REVENUE, DATA OR DATA USE, INCURRED BY CUSTOMER OR ANY THIRD
                  PARTY, WHETHER IN AN ACTION IN CONTRACT OR TORT, ARISING FROM
                  CUSTOMER’S ACCESS TO, OR USE OF, THE MARKETPLACE, THE ZOWASEL
                  PLATFORM OR ANY CONTENT, SERVICES OR OTHER MATERIALS OF OR ON
                  THE MARKETPLACE OR THE ZOWASEL PLATFORM, EVEN IF COMPANY HAS
                  BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                </p>
              </li>
              <li>
                <p>
                  <strong>Indemnification:</strong> Customer shall defend,
                  indemnify and hold harmless Company and its officers,
                  directors, employees and agents from any claims, demands,
                  actions, suits, losses, liabilities, damages, injuries, costs
                  and expenses (including attorneys’ fees and court costs)
                  arising out of or related to (i) Customer’s use of the
                  Marketplace or the Zowasel platform, (ii) Customer’s violation
                  of any term of this Agreement, (iii) Customer’s violation of
                  any third party rights, including without limitation any third
                  party intellectual property rights or privacy right, and/or
                  (iv) any dispute between Customer and any other Customer or
                  third party.
                </p>
              </li>
              <li>
                <p>
                  <strong>Termination:</strong> The Company reserves the right,
                  in its sole discretion, to terminate a Customer’s
                  participation in the Marketplace and/or the Zowasel platform
                  for any reason, including, without limitation, for Customer’s
                  failure to make payment of a fulfilment fee, fulfilment fee
                  for pre-season funding or Pick & Pack fee, for breaches or
                  violations of this Agreement, or for other misconduct by
                  Customer on the Marketplace or on the Zowasel platform. In the
                  event of such termination, Customer shall be responsible for
                  payment of any fulfilment fees due at the time of termination.
                </p>
              </li>
              <li>
                <p>
                  <strong>Amendment:</strong> The Company reserves the right to
                  amend, modify or revise this Agreement at any time by posting
                  the amended Agreement on the Marketplace and on the Zowasel
                  platform. The Customer’s continued use of the Marketplace and
                  the Zowasel platform shall constitute acceptance of the
                  amended Agreement.
                </p>
              </li>
              <li>
                <p>
                  <strong>Governing Law and Venue:</strong> This Agreement shall
                  be governed by and construed in accordance with the laws of
                  the State of Washington, without regard to conflicts of laws
                  principles. The parties agree that the exclusive venue for all
                  actions arising out of this Agreement shall be in the state or
                  federal courts located in the State of Washington.
                </p>
              </li>
            </ol>

            <p>
              This Agreement was last updated on
              <span class="text-success">August 29, 2023</span>.
            </p>

            <p>
              If you have any questions or concerns regarding this Agreement,
              please contact us at
              <a href="mailto:legal@zowasel.com" class="text-success"
                >legal@zowasel.com</a
              >
              .
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import MarketPlaceService from "@/services/marketplace";
import Alert from "@/utilities/alert";
import Preloader from "@/layouts/shared/Preloader.vue";
export default {
  name: "ProductPage",
  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      // Initialize product data
      product: null,
      quantity: "",
      productType: "",
      i: 0,
      isSubscribe: false,
      isProductSubscribe: false,
      isNegotiable: true,
      avatar: "",
    };
  },
  computed: {
    // Check if stock is available
    stock() {
      return this.product.specification.qty < 1;
    },
  },

  created() {
    // Fetch product and subscription data on component creation
    this.getProduct();
    this.getAllSubscription();
  },
  methods: {
    // Change displayed image based on index
    changeImage(index) {
      this.i = index;
    },
    // Fetch product details
    getProduct() {
      MarketPlaceService.getCropById(this.$route.params.id, (response) => {
        this.product = response.data;
        // Set negotiable status
        if (this.product.is_negotiable === 0) {
          this.isNegotiable = false;
        }
        // Set avatar for the product user
        this.setAvatar();
        this.product.images = JSON.parse(this.product.images);
        // Check for auction product type
        if (this.product.type == "auction") {
          this.productType = "auction";
        }
      });
    },
    // Set avatar for the product user
    setAvatar() {
      this.avatar = this.product.user.image;
    },
    // Proceed with the purchase
    proceed() {
      // Display loader
      this.isLoading = true;
      if (this.quantity <= this.product.specification.qty) {
        // Fulfill crop offer
        MarketPlaceService.fulfilCropOffer(
          {
            crop_id: this.product.id,
            quantity: this.quantity,
          },
          (response) => {
            if (!response.data.error) {
              // Redirect to order page
              window.location.replace(
                `/dashboard/marketplace/order/${response.data.order_hash}`,
              );
            }
            this.isLoading = false;
          },
        );
      } else {
        // Display error message for exceeding quantity
        Alert.error({
          message: "Quantity exceeds offer quantity",
        });
        this.quantity = this.product.specification.qty;
        this.isLoading = false;
      }
    },
    // Fetch all subscriptions
    getAllSubscription() {
      MarketPlaceService.getSubscription((response) => {
        if (response) {
          const data = response.data;
          // Check if the data array is empty
          if (data.length === 0) {
            this.isSubscribe = false;
            this.isProductSubscribe = false;
          } else {
            // Check if "user" type and "subcategory" type exist
            const hasUserType = data.some(
              (item) =>
                item.type === "user" && item.type_id === this.product.user_id,
            );
            const hasSubCategoryType = data.some(
              (item) =>
                item.type === "subcategory" &&
                item.type_id === this.product.user_id,
            );

            // Update variables based on type existence
            if (hasUserType) {
              this.isSubscribe = true;
            }
            if (hasSubCategoryType) {
              this.isProductSubscribe = true;
            }
          }
        }
      });
    },

    // Subscribe to user
    subscribeUser() {
      // Display loader
      this.isLoading = true;
      // Access and store the request data
      const requestData = {
        user_id: this.userData.user.id,
        type: "user",
        type_id: this.product.user_id,
      };
      if (!this.isSubscribe) {
        // Add user subscription
        MarketPlaceService.addSubscription(requestData, (response) => {
          this.isSubscribe = true;
          // Display success message
          Alert.success({
            message: "You have successfully subscribed to this user",
          });

          // Hide loader
          this.isLoading = false;
        });
      } else {
        // Remove user subscription
        MarketPlaceService.removeSubscription(requestData, (response) => {
          console.log(response);
          this.isSubscribe = false;
          // Display success message
          Alert.success({
            message: "You have successfully Unsubscribed to this user",
          });

          // Hide loader
          this.isLoading = false;
        });
      }
    },

    // Subscribe to product category
    subscribeCategory() {
      // Display loader
      this.isLoading = true;
      // Access and store the request data
      const requestData = {
        user_id: this.userData.user.id,
        type: "subcategory",
        type_id: this.product.user_id,
      };
      if (!this.isProductSubscribe) {
        // Add product category subscription
        MarketPlaceService.addSubscription(requestData, (response) => {
          this.isProductSubscribe = true;
          // Display success message
          Alert.success({
            message:
              "You have successfully subscribed to this Crop SubCategory",
          });

          // Hide loader
          this.isLoading = false;
        });
      } else {
        // Remove product category subscription
        MarketPlaceService.removeSubscription(requestData, (response) => {
          this.isProductSubscribe = false;
          // Display success message
          Alert.success({
            message:
              "You have successfully Unsubscribed to this Crop SubCategory",
          });

          // Hide loader
          this.isLoading = false;
        });
      }
    },
    // Redirect to bid page
    bid(id) {
      this.$router.push({ path: `/marketplace/crop/${id}/bid` });
    },
    // Redirect to company profile page
    toCompanyProfile() {
      const cropId = this.product.id;
      this.$router.push({
        path: "/companyprofile",
        query: { cropId },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.big-container2 {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.big-content {
  width: 100%;
  padding: 100px;
}

.image-area {
  width: 100%;
  background-color: white;
  padding: 50px;
  padding-top: 24px;
  margin-top: 30px;

  h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 132%;
    color: #696671;
    margin-bottom: 15px;
  }

  .big-img {
    width: 80%;
    display: flex;
    justify-content: center;

    img {
      width: 50%;
    }
  }

  .small-images {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 3%;

    img {
      width: 18%;
      min-height: 200px;
      cursor: pointer;
    }
  }
}

.topic {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 50px;

  .left {
    display: flex;
    gap: 30px;
    flex: 2;

    .product_details_container {
      width: 80%;

      .product-quantity {
        display: flex;
        align-items: center;
        gap: 30px;
      }

      hr {
        background: rgba(0, 0, 0, 0.29);
        width: 100%;
        height: 1px;
      }
    }

    .product-name {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 43px;
      line-height: 116%;
      color: #4a4754;
    }

    .price2 {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 800;
      font-size: 22px;
      line-height: 117%;
      font-feature-settings: "liga" off;
      color: #4a4754;
    }

    .farmer {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.01em;
      color: #14110c;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
    }

    .product-c {
      display: flex;
      width: 60%;
      gap: 30px;
      align-items: center;
      border-radius: 11px;
      background: #fff8e6;
      padding: 15px 10px;
    }

    .pd-user-avatar {
      width: 99px;
      // height: 87px;
      border-radius: 9px;
      background: #d9d9d9;
    }

    .pd-details {
      height: 80%;
      display: flex;
      flex-direction: column;
      align-items: baseline;
      justify-content: space-between;
    }

    span {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #14110c;
    }

    .verified-c span {
      color: #14110c;
      font-family: Maven Pro;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 18.48px;
      letter-spacing: 0.14px;
    }

    .C-name-container {
      border-radius: 11px;
      background: #fff8e6;
      padding: 10px 30px;
      margin-left: 20px;
    }

    .C-name span {
      color: #14110c;
      font-feature-settings: "liga" off;
      font-family: Maven Pro;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 21.12px;
      letter-spacing: 0.16px;
    }
  }

  .subscribe {
    color: #0065ff !important;
    border: 1.6px solid #0047b3;

    &:hover {
      background: lighten(#0065ff, 40%);
      color: #0065ff !important;
    }
  }

  .user-btn {
    font-family: "Maven Pro";
    font-size: 16px !important;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: default;
  }

  .view-profile {
    background: #fb8a2e;

    &:hover {
      background: darken(#fb8a2e, 10%);
      color: white;
    }
  }

  .subscribed {
    background: #0065ff;
    color: white !important;

    &:hover {
      background: darken(#0065ff, 10%);
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 20px;

    .negotiate {
      background: white;
      box-shadow:
        0px 2px 4px rgba(44, 39, 56, 0.08),
        0px 4px 8px rgba(44, 39, 56, 0.08);
      border-radius: 4px;
      height: 50px;
      width: 250px;
      border: none;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      color: #05b050;
      border: 1px solid #05b050;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }

    .price1 {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 900;
      font-size: 35px;
      color: #4a4754;
    }

    .down-btns {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}

.topic2 {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(180deg, #29333f 0%, #1c2131 100%);
  padding: 50px;

  .left {
    .product-name {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 43px;
      line-height: 116%;
      color: white;
    }

    .price2 {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 117%;
      font-feature-settings: "liga" off;
      color: white;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .negotiate {
      background: white;
      box-shadow:
        0px 2px 4px rgba(44, 39, 56, 0.08),
        0px 4px 8px rgba(44, 39, 56, 0.08);
      border-radius: 4px;
      height: 50px;
      width: 250px;
      border: none;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      color: #05b050;
      border: 1px solid #05b050;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }

    .price1 {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 29px;
      color: white;
    }

    .down-btns {
      display: flex;
      gap: 20px;
    }
  }
}

.accordion-item {
  margin-top: 30px;

  .accordion-header {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 132%;
    color: #696671;
  }
}

.accordion-button {
  border: 0.8px solid rgba(5, 176, 80, 0.5);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 132%;
  color: #696671;
}

.description {
  h3 {
    font-size: 20px;
  }

  margin-top: 40px;
}

.details {
  width: 100%;
  margin-top: 40px;
  display: flex;
}

.actual-details {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .each-detail {
    margin-top: 30px;

    h4 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }
  }
}

.actual-detailss {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;

  .left-lines {
    width: 50%;
  }
}

.map-container-2 {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.map-container-2 iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.main-map {
  width: 100%;
  height: 60vh;
}

.fulfil {
  background: #05b050;
  box-shadow:
    0px 2px 4px rgba(44, 39, 56, 0.08),
    0px 4px 8px rgba(44, 39, 56, 0.08);
  border-radius: 4px;
  height: 50px;
  width: 250px;
  border: none;
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #e6f7ee;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  &:hover {
    background: darken(#05b050, 10%);
    color: white;
  }
}

.modal-input {
  width: 100%;
}

.accept-box {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;

  input {
    margin: 0;
  }

  p {
    margin: 0;
  }
}

.go-row {
  img {
    width: 40px;
  }
}

.each-detail {
  margin-top: 30px;

  h4 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 116%;
    color: #696671;
  }

  p {
    font-size: 14px;
  }
}

.guide {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;

  a {
    color: #05b050;
  }

  button {
    background: #f9e9e9;
    border: 1.50427px solid #c32021;
    border-radius: 6.01709px;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 21.0598px;
    color: #c32021;
    height: 51px;
    width: 200px;
  }
}

.reviews {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  gap: 100px;

  .left-reviews {
    width: 50%;
  }

  .right-reviews {
    width: 50%;
  }
}

.each-review {
  margin-top: 50px;

  h4 {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #191d23;
    margin-bottom: 0px;
  }
}

.texts p {
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #64748b;
}

.head-review {
  display: flex;
  justify-content: space-between;

  .stars {
    display: flex;
    gap: 10px;

    img {
      width: 20px;
      height: 20px;
    }
  }
}

.title {
  display: flex;
}

.img-contain {
  width: 50px;
  height: 50px;
  border-radius: 50%;

  img {
    border-radius: 50%;
  }
}

.other-products {
  margin-top: 50px;
  padding-top: 50px;
  padding: 100px 45px;
  background: #f6f6f6;
}

.may-also {
  padding-top: 50px;
  padding: 100px 45px;
  background: #14b55a;
}

.others {
  display: flex;
  justify-content: space-between;

  a {
    background: #05b050;
    box-shadow:
      0px 2px 4px rgba(44, 39, 56, 0.08),
      0px 4px 8px rgba(44, 39, 56, 0.08);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    color: white;
    text-decoration: none;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 147%;
    color: #e6f7ee;
  }

  h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    color: white;
  }
}

.small-contents {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 32px;
  flex-wrap: wrap;

  .each-product {
    padding: 10px;
    border-radius: 8px;
    background: white;
    position: relative;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    h3 {
      margin-top: 16px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      color: #4a4754;
    }

    p {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #4a4754;
    }

    .badge {
      position: absolute;
      top: 24px;
      left: 22px;
      background: #fab900;
      border-radius: 4px;
      color: white;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 130%;
    }
  }
}

.deep-h {
  color: #696671 !important;
}

.margin-bot {
  margin-bottom: 40px;
}

.crop {
  &:hover {
    background: darken(white, 10%) !important;
  }
}

.btn-row {
  display: flex;
  width: 100%;
  gap: 15px;
  margin-top: 10px;
}
</style>
