<template>
  <DefaultNav>
    <div class="big-container">
      <div class="top-text">
        <h1>Payment</h1>
      </div>
      <div class="big-content">
        <div id="payment-container"></div>
      </div>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import TransactionService from "@/services/transaction";

export default {
  name: "CardDetails",
  components: {
    DefaultNav,
  },
  data() {
    return {
      userData: this.$store.state.user,
    };
  },
  created() {},
  mounted() {
    let vm = this;
    this.getCartItems(() => {
      vm.makePayment();
    });
  },
  methods: {
    async makePayment() {
      var vm = this;
      var transactionRef = `TRX-${this.generateRandom(20).toUpperCase()}`;
      var amountToPay = eval(this.cartTotal);
      var orders = JSON.parse(
        window.localStorage.getItem("checkoutData"),
      ).orders;
      this.isLoading = true;
      var orderHashes = [];
      orders.forEach((order) => {
        orderHashes.push(order.order_hash);
      });

      await TransactionService.createPayment(
        {
          amount: amountToPay,
          order: orderHashes,
        },
        (response) => {
          if (response) {
            console.log(response);
            window.location.replace(response.link);
            console.log(response.link);
          }
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.top-text {
  padding: 14px 30px;
  width: 100%;
  background: #262c3f;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;

  h1 {
    font-size: 24px;
  }
}
.big-content {
  padding: 50px 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1f1f1;
  height: 100vh;

  #payment-container {
    height: 50vh;
  }

  form {
    width: 60%;
    padding: 100px 50px;
    background: #ffffff;
    border-radius: 4px;
    h2 {
      font-size: 20px;
    }
    hr {
      margin-bottom: 40px;
    }

    button {
      margin-top: 30px;
    }

    .check-area {
      display: flex;
      align-items: center;

      label {
        margin-left: 10px;
      }
    }
    .last-p {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #b5b4b9;
      margin-top: 23px;
    }
  }
}
</style>
