<template>
  <DefaultNav>
    <div class="big-container">
      <div class="headings">
        <h2>Farmer</h2>
        <div class="right-btns">
          <a href="/dashboard/farmer/batch/add" class="green-link"
            >Batch Upload</a
          >
          <a
            href="/dashboard/farmer/batch/score"
            class="green-link bg-warning text-center"
            >Batch Score</a
          >
          <a href="/dashboard/farmer/add" class="green-link">Add a Farmer</a>
          <!-- <a href="" class="green-link">Batch Upload</a> -->
          <!-- <a href="" class="red-link">Sent Invoice</a> -->
        </div>
      </div>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import Preloader from "@/layouts/shared/Preloader.vue";
import FarmerService from "@/services/farmer";
import Alert from "@/utilities/alert";
import NilData from "@/utilities/returnNil";

export default {
  name: "ScoreBatch",
  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      scored: "",
      selected: null,
      options: ["list", "of", "options"],
    };
  },
  mounted() {
    // getAllFarmers() {
    setTimeout(() => {
      // this.isLoading = true;
      FarmerService.getAllFarmers((response) => {
        console.log(response.data);
        this.farmerData = response.data;
        this.paginate(response.data.reverse(), "farmerData");
        this.isLoading = false;
      });
    }, 2000);
  },
  methods: {
    getSingleFarmerData(farmerid) {
      localStorage.setItem("farmerID", farmerid);
      window.location.href = `/dashboard/farmer/view/${farmerid}`;
    },
    scoreFarmer(farmerid) {
      // this.isLoading = true;
    },
    // applyforLoan(farmerid, email, gender, address) {
    //   localStorage.setItem(
    //     "creditScoreData",
    //     JSON.stringify({
    //       farmerid: farmerid,
    //       email: email,
    //       gender: gender,
    //       address: address,
    //     })
    //   );
    //   window.location.href = "/dashboard/creditscore/analyse";
    // },
  },
};
</script>

<style lang="scss" scoped>
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 4% 5% 10% 3%;
  overflow-y: scroll;

  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}

.active-subscriptions {
  background-color: white;
  padding: 24px 35px;
  margin-top: 20px;
}

/* The slider */

.sub-history {
  margin-top: 30px;
  width: 100%;
  margin-left: 10px;

  h2 {
    margin-bottom: 40px;
  }

  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #4a4754;
  }

  .serial {
    max-width: 50px;
  }

  tr {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 9px;
    height: 66px;
  }

  td {
    flex: 1;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4754;
  }

  .active {
    width: 100px;
    height: 26px;
    background: #dcfce7;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .active-dot {
      width: 7.52px;
      height: 8px;
      background: #047b38;
      border-radius: 50%;
    }

    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #047b38;
    }
  }

  .expired {
    width: 100px;
    height: 26px;
    background: #fff8e6;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .expired-dot {
      width: 7.52px;
      height: 8px;
      background: #fab900;
      border-radius: 50%;
    }

    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #fab900;
    }
  }

  .cancelled {
    width: 100px;
    height: 26px;
    background: rgba(252, 220, 220, 0.93);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .cancelled-dot {
      width: 7.52px;
      height: 8px;
      background: #c52222;
      border-radius: 50%;
    }

    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #6b0303;
    }
  }

  a {
    text-decoration: none;
  }

  a:hover td {
    color: #05b050 !important;
  }
}

.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  a {
    text-decoration: none;
  }

  .right-btns {
    display: flex;
    gap: 20px;
  }

  .green-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 180px;
    height: 40px;
    background: #05b050;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    border-radius: 4px;
  }

  .white-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 40px;
    background: #ffffff;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #05b050;
    border: 2px solid #05b050;
    border-radius: 4px;
  }

  .red-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 180px;
    height: 40px;
    background: #cd4647;
    box-shadow:
      0px 2px 4px rgba(44, 39, 56, 0.08),
      0px 4px 8px rgba(44, 39, 56, 0.08);
    border-radius: 4px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    border-radius: 4px;
  }
}

.date-region {
  margin-top: 30px;
}

.view-btn {
  color: black;
}
</style>
