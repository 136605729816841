<template>
  <DefaultNav>
    <div class="big-container pb-5">
      <form class="mt-4" @submit.prevent="saveData()">
        <div class="headings">
          <h2>Loan History</h2>
          <div class="right-btns">
            <a href="/dashboard/farmer/view" class="yellow-link"
              >View Farmers</a
            >
            <button class="green-link">Save</button>
          </div>
        </div>
        <div v-if="editing == false" class="mb-3">
          <h6>
            <strong>* All fields are required</strong>
          </h6>
        </div>
        <div class="row mb-4">
          <div class="col">
            <label for=""
              ><span v-if="editing == false" class="text-danger">*</span>
              What is the cost of cultivation per planting season
            </label>
            <input
              v-model="costofcultivation"
              type="number"
              class="form-control"
              placeholder="Enter your answer"
              required
            />
          </div>
          <div class="col">
            <label for=""
              ><span v-if="editing == false" class="text-danger">*</span>
              What is the average cost of cultivation per year?
            </label>
            <input
              v-model="avgcostofcultivation"
              type="number"
              class="form-control"
              placeholder="Enter your answer"
              required
            />
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <label for="">
              <span v-if="editing == false" class="text-danger">*</span> Has
              this farmer taken a loan before
            </label>
            <select
              v-model="takenaloan"
              class="form-select"
              aria-label="Default select example"
              required
            >
              <option selected>Choose...</option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
          </div>
          <template v-if="takenaloan == 1">
            <div class="col">
              <label for=""
                ><span v-if="editing == false" class="text-danger">*</span>
                When was this loan taken?
              </label>
              <input
                v-model="loancollectiontime"
                type="date"
                class="form-control"
                placeholder="Enter your answer"
                required
              />
            </div>
          </template>
        </div>
        <template v-if="takenaloan == 1">
          <div class="row mb-4">
            <div class="col">
              <label for=""
                ><span v-if="editing == false" class="text-danger">*</span>
                Amount of the last loan collected</label
              >
              <input
                v-model="pastloanamount"
                type="number"
                class="form-control"
                placeholder="Enter your answer"
                required
              />
            </div>
            <div class="col">
              <label for=""
                ><span v-if="editing == false" class="text-danger">*</span> Has
                the loan been repaid?</label
              >
              <select
                v-model="repaidloan"
                class="form-select"
                aria-label="Default select example"
                required
              >
                <option selected>Choose...</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col">
              <label for=""
                ><span v-if="editing == false" class="text-danger">*</span> How
                did you repay the loan</label
              >
              <input
                v-model="howonloanrepayment"
                type="text"
                class="form-control"
                placeholder="Enter your answer"
                required
              />
            </div>
            <div class="col">
              <label for=""
                ><span v-if="editing == false" class="text-danger">*</span>
                Was there any difficulty encountered when repaying?
              </label>
              <select
                v-model="difficultyrepaying"
                class="form-select"
                aria-label="Default select example"
                required
              >
                <option selected>Choose...</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <div class="headings pt-4 pb-2">
            <button class="green-link">Save</button>
          </div>
        </template>
      </form>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
// Alert box
import Alert from "@/utilities/alert";
import Preloader from "@/layouts/shared/Preloader.vue";
import FarmerService from "@/services/farmer";
import NilData from "@/utilities/returnNil";

export default {
  name: "AddLoanHistory",
  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      farmerid: "",
      takenaloan: "",
      costofcultivation: "",
      avgcostofcultivation: "",
      loancollectiontime: "",
      pastloanamount: "",
      repaidloan: "",
      howonloanrepayment: "",
      difficultyrepaying: "",
      editData: [],
      editing: false,
    };
  },
  mounted() {
    const url = window.location.href;
    this.farmerid = url.split("/").slice(-1)[0];
    this.isLoading = true;
    if (url.split("/").slice(-2)[0] !== "add") {
      this.editing = false;
      FarmerService.getLoanHistory(this.farmerid, (response) => {
        this.editData.push(response.data);
        this.isLoading = false;
        if (response.data == null) {
          window.location.href = "/dashboard/farmer/view";
        } else {
          this.editData.push(response.data);
          this.takenaloan = this.editData[0].iftakenaloanbefore;
          this.costofcultivation =
            this.editData[0].costofcultivationperplantseason;
          this.avgcostofcultivation = this.editData[0].whatiscostofcultivation;
          this.loancollectiontime = NilData.returnEmptyField(
            this.editData[0].loantakenseason,
          );
          this.pastloanamount = this.editData[0].pastloantakenamountnaira;
          this.repaidloan = NilData.returnEmptyField(
            this.editData[0].hasitbeenrepaid,
          );
          this.howonloanrepayment = NilData.returnEmptyField(
            this.editData[0].howdidyourepay,
          );
          this.difficultyrepaying = NilData.returnEmptyField(
            this.editData[0].haddifficultypaying,
          );
          this.editing = true;
        }
      });
    } else {
      this.isLoading = false;
      this.editing = false;
    }
  },
  methods: {
    async saveData() {
      if (this.editing == true) {
        this.isLoading = true;
        let vm = this;
        let dt = {
          farmerid: vm.farmerid,
          costofcultivationperplantseason: vm.costofcultivation,
          iftakenaloanbefore: vm.takenaloan,
          whatiscostofcultivation: vm.avgcostofcultivation,
          loantakenseason: NilData.checkLoan(
            vm.loancollectiontime,
            vm.takenaloan,
          ),
          pastloantakenamountnaira: NilData.checkLoan(
            vm.pastloanamount,
            vm.takenaloan,
            false,
          ),
          hasitbeenrepaid: NilData.checkLoan(
            vm.repaidloan,
            vm.takenaloan,
            false,
          ),
          howdidyourepay: NilData.checkLoan(
            vm.howonloanrepayment,
            vm.takenaloan,
          ),
          haddifficultypaying: NilData.checkLoan(
            vm.difficultyrepaying,
            vm.takenaloan,
          ),
        };

        FarmerService.updateLoanHistory(vm.farmerid, dt, (data) => {
          /* ------------------- check if it returned with an error ------------------- */
          if (data[0].error == false) {
            Alert.success({
              message: data[1].message,
            });
            setTimeout(() => {
              window.location.href = `/dashboard/farmer/view/${vm.farmerid}`;
            }, 2000);
          } else {
            Alert.error({
              message: data[1].message,
            });
          }
          this.isLoading = false;
        });
      } else {
        this.isLoading = true;
        let vm = this;
        let dt = {
          farmerid: vm.farmerid,
          costofcultivationperplantseason: vm.costofcultivation,
          iftakenaloanbefore: vm.takenaloan,
          whatiscostofcultivation: vm.avgcostofcultivation,
          loantakenseason: NilData.checkLoan(
            vm.loancollectiontime,
            vm.takenaloan,
          ),
          pastloantakenamountnaira: NilData.checkLoan(
            vm.pastloanamount,
            vm.takenaloan,
            false,
          ),
          hasitbeenrepaid: NilData.checkLoan(
            vm.repaidloan,
            vm.takenaloan,
            false,
          ),
          howdidyourepay: NilData.checkLoan(
            vm.howonloanrepayment,
            vm.takenaloan,
          ),
          haddifficultypaying: NilData.checkLoan(
            vm.difficultyrepaying,
            vm.takenaloan,
          ),
        };

        FarmerService.addFarmerLoanHistory(dt, (data) => {
          /* ------------------- check if it returned with an error ------------------- */
          if (data[0].error == false) {
            Alert.success({
              message: data[1].message,
            });
            setTimeout(() => {
              window.location.href = `/dashboard/farmer/view/${vm.farmerid}`;
            }, 2000);
          } else {
            Alert.error({
              message: data[1].message,
            });
          }
          this.isLoading = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 2% 5% 2% 3%;
  overflow-y: scroll;
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}
.active-subscriptions {
  background-color: white;
  padding: 24px 65px;
  margin-top: 40px;

  h2 {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #696671;
  }
}

/* The slider */
.sub-history {
  margin-top: 50px;
  width: 100%;
  margin-left: 10px;

  h2 {
    margin-bottom: 40px;
  }
  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #4a4754;
  }
}
.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  a {
    text-decoration: none;
  }
  .right-btns {
    display: flex;
    gap: 20px;
  }
  .green-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 145px;
    height: 40px;
    background: #05b050;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
  }
}
.green-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 145px;
  height: 40px;
  background: #05b050;
  border-radius: 5px;
  letter-spacing: 0.02em;
}
.yellow-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  width: 145px;
  height: 40px;
  background: #fab900 !important;
  border-radius: 5px;
  letter-spacing: 0.02em;
}
a {
  text-decoration: none;
}
.small-font {
  font-size: 11px !important;
}
</style>
