<template>
  <DefaultNav>
    <div class="big-container">
      <!-- header -->
      <form @submit.prevent="saveData()">
        <div class="form">
          <div class="headings">
            <h2>Add Farmer Bank</h2>
            <div class="right-btns">
              <a href="/dashboard/farmer/view" class="yellow-link"
                >View Farmers</a
              >
              <button class="green-link">Save</button>
            </div>
          </div>
          <div class="mb-3">
            <h6>
              <strong v-if="editing == false" class="text-danger"
                >* All fields required</strong
              >
            </h6>
          </div>
          <div class="w-100 mb-3">
            <label for="exampleInputEmail1" class="form-label mb-0"
              ><span v-if="editing == false" class="text-danger">*</span> Bank
              Name</label
            >
            <input
              v-model="bankname"
              type="text"
              class="form-control"
              required
              placeholder="Enter your bank name"
            />
          </div>
          <div class="w-100 mb-3">
            <label for="exampleInputEmail1" class="form-label mb-0"
              ><span v-if="editing == false" class="text-danger">*</span>
              Account Name</label
            >
            <input
              v-model="accountname"
              type="text"
              class="form-control"
              required
              placeholder="Enter your account name"
            />
          </div>
          <div class="w-100 mb-3">
            <label for="exampleInputEmail1" class="form-label mb-0"
              ><span v-if="editing == false" class="text-danger">*</span>
              Account Number</label
            >
            <input
              v-model="accountnumber"
              type="text"
              class="form-control"
              required
              placeholder="Enter your account number"
            />
          </div>
        </div>
      </form>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import Preloader from "@/layouts/shared/Preloader.vue";
import Alert from "@/utilities/alert";
import FarmerService from "@/services/farmer";
export default {
  name: "Kyf",
  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      farmerid: "",
      bankname: "",
      accountname: "",
      accountnumber: "",
      editing: false,
      editData: [],
    };
  },
  mounted() {
    const url = window.location.href;
    this.farmerid = url.split("/").slice(-2)[0];
    this.isLoading = true;
    if (url.split("/").slice(-1)[0] !== "add") {
      FarmerService.getFarmerBankData(this.farmerid, (response) => {
        this.editData.push(response.data);
        this.isLoading = false;
        if (response.data == null) {
          this.editing = false;
          window.location.href = `/dashboard/farmer/view/${this.farmerid}`;
        } else {
          this.editData.push(response.data);
          this.accountnumber = this.editData[0].bankaccountnumber;
          this.bankname = this.editData[0].bankname;
          this.accountname = this.editData[0].bankaccountname;
          this.editing = true;
        }
      });
    } else {
      this.editing = false;
      this.isLoading = false;
    }
  },
  methods: {
    async saveData() {
      /* -------------------------- run edit on the data -------------------------- */
      if (this.editing == true) {
        let vm = this;
        let dt = {
          farmerid: vm.farmerid,
          bankaccountnumber: vm.accountnumber,
          bankname: vm.bankname,
          bankaccountname: vm.accountname,
        };
        this.isLoading = true;
        FarmerService.updateFarmerBank(this.farmerid, dt, (data) => {
          /* ------------------- check if it returned with an error ------------------- */
          if (data[0].error == false) {
            Alert.success({
              message: data[1].message,
            });
            setTimeout(() => {
              window.location.href = `/dashboard/farmer/view/${vm.farmerid}`;
            }, 2000);
          } else {
            Alert.error({
              message: data[1].message,
            });
          }
          this.isLoading = false;
        });
      } else {
        /* --------------------- create new farmer account data --------------------- */
        let vm = this;
        let dt = {
          farmerid: vm.farmerid,
          bankaccountnumber: vm.accountnumber,
          bankname: vm.bankname,
          bankaccountname: vm.accountname,
        };
        this.isLoading = true;
        FarmerService.addFarmerBank(dt, (data) => {
          /* ------------------- check if it returned with an error ------------------- */
          if (data[0].error == false) {
            Alert.success({
              message: "Farmer's bank details added successfully",
            });
            setTimeout(() => {
              window.location.href = `/dashboard/farmer/view/${vm.farmerid}`;
            }, 2000);
          } else {
            Alert.error({
              message: data[1].message,
            });
          }
          this.isLoading = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 4% 5% 8% 3%;
  overflow-y: scroll;
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}
.active-subscriptions {
  background-color: white;
  padding: 24px 65px 50px;
  margin-top: 40px;

  h2 {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #696671;
  }
}

/* The slider */
.sub-history {
  margin-top: 50px;
  width: 100%;
  margin-left: 10px;

  h2 {
    margin-bottom: 40px;
  }
  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #4a4754;
  }
}
.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 70px;

  a {
    text-decoration: none;
  }
  .right-btns {
    display: flex;
    gap: 20px;
  }
  .green-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 145px;
    height: 40px;
    background: #05b050;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
  }
}
.green-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 145px;
  height: 40px;
  background: #05b050;
  border-radius: 5px;
  letter-spacing: 0.02em;
}
.yellow-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  width: 145px;
  height: 40px;
  background: #fab900 !important;
  border-radius: 5px;
  letter-spacing: 0.02em;
}
a {
  text-decoration: none;
}
.small-font {
  font-size: 11px !important;
}
</style>
