import axios from "axios";

import config from "@/config";

const GET_VWALLET = () => `vwallet`;
const GET_BANKS = () => `banks`;
const GET_BENEFICIARY = () => `transfer/beneficiary`;

const GET_WALLET_BALANCE_PATH = () => `/wallet/balance`;
const GET_WALLET_TRANSACTIONS_PATH = () => `/wallet/transactions/recent`;
const GET_WITHDRAWAL_HISTORY_PATH = () => `wallet/withdraw`;
const GET_WITHDRAWAL_OTP_PATH = () => `wallet/withdraw/otp`;
const GET_WITHDRAWAL_PATH = () => `wallet/withdraw`;
const CREATE_VWALLET_PATH = () => `vwallet/create`;
const TRANSFER_VWALLET_PATH = () => `vwallet/transfer`;
const RELEASE_WALLET_PATH = () => `vwallet/release`;
const CONSENT_WALLET_PATH = () => `vwallet/consent`;

export default {
  createVwallet: function (type, callback) {
    axios
      .post(config.BASE_URL + CREATE_VWALLET_PATH(), {
        ...(type ? { user_type: type } : {}),
      })
      .then((response) => {
        console.log(response.data);
        callback(response.data);
      })
      .catch((error) => {
        if ((error.statuse = 403)) {
          callback({ error: true, message: error.response.data.message });
        }
      });
  },
  getVwallet: function (callback) {
    axios
      .get(config.BASE_URL + GET_VWALLET())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        if ((error.statuse = 403)) {
          callback({ error: true });
        }
      });
  },
  getMerchantFinanceWallet: function (callback) {
    axios
      .get(config.BASE_URL + GET_VWALLET() + "?user_type=merchant")
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        if ((error.statuse = 403)) {
          callback({ error: true });
        }
      });
  },
  getBanksList: function (callback) {
    axios
      .get(config.BASE_URL + GET_BANKS())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        if ((error.statuse = 403)) {
          callback({ error: true });
        }
      });
  },

  getBeneficiary: function (data, callback) {
    axios
      .post(config.BASE_URL + GET_BENEFICIARY(), {
        ...data,
      })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        if ((error.statuse = 403)) {
          callback({ error: true });
        }
      });
  },
  getWalletBalance: function (id, callback) {
    axios
      .get(config.BASE_URL + GET_WALLET_BALANCE_PATH(id))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {});
  },
  getWalletTransactions: function (id, callback) {
    axios
      .get(config.BASE_URL + GET_WALLET_TRANSACTIONS_PATH(id))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {});
  },
  getWithdrawalHistory: function (id, callback) {
    axios
      .get(config.BASE_URL + GET_WITHDRAWAL_HISTORY_PATH(id))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.response);
      });
  },
  getWithdrawalOtp: function (id, callback) {
    axios
      .post(config.BASE_URL + GET_WITHDRAWAL_OTP_PATH(id))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {});
  },
  vwalletTransfer: function (data, callback) {
    axios
      .post(config.BASE_URL + TRANSFER_VWALLET_PATH(), data)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        if ((error.statuse = 403)) {
          callback({ error: true });
        }
      });
  },
  sendWithdrawalRequest: function (id, withdrawalDetails, callback) {
    axios
      .post(config.BASE_URL + GET_WITHDRAWAL_PATH(id), {
        amount: withdrawalDetails.amount,
        code: withdrawalDetails.code,
      })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.response.data);
      });
  },
  releaseVWallet: function (data, callback) {
    axios
      .post(config.BASE_URL + RELEASE_WALLET_PATH(), data)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.response.data);
      });
  },
  consentVWallet: function (data, callback) {
    axios
      .post(config.BASE_URL + CONSENT_WALLET_PATH(), data)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.response.data);
      });
  },
};
// avoid dupliccate actions token
