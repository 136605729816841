<template>
  <div class="user-type-main d-flex flex-row">
    <!-- right -->
    <div
      class="user-type-left d-flex justify-content-center align-items-center"
    >
      <div class="login-views d-flex flex-column">
        <div class="Iam">I am a:</div>
        <div class="login-views-1 d-flex flex-row">
          <div class="merchant">
            <span class="d-block">Merchants</span>
            <button
              type="button"
              class="btn d-block btn-hover-state"
              @click="beginRegistration('merchant')"
            >
              Select
            </button>
          </div>
          <div class="corporate">
            <span class="d-block">Corperates</span>
            <button
              type="button"
              class="btn d-block btn-hover-state"
              @click="beginRegistration('corporate')"
            >
              Select
            </button>
          </div>
        </div>
        <!-- !this section is for partners and Agent Account type. (if this feature is irrelevant please remove) -->
        <!-- <div class="login-views-1 d-flex flex-row">
          <div class="partner">
            <span class="d-block">Partner</span>
            <button
              type="button"
              class="btn d-block"
              @click="beginRegistration('partner')"
            >
              Select
            </button>
          </div>
          <div class="agent">
            <span class="d-block">Trustees</span>
            <button
              type="button"
              class="btn d-block"
              @click="beginRegistration('agent')"
            >
              Select
            </button>
          </div>
        </div> -->
      </div>
    </div>
    <!-- left -->
    <div class="about-user-type">
      <div class="logo-container">
        <img
          class="img-fluid"
          src="@/assets/images/logos/zowasel-grey-logo.png"
          alt="logo"
        />
      </div>

      <!-- user select type content -->

      <!-- merchant -->
      <div id="merchant" class="user-about user-select-content">
        <h1 class="about-merchant">Merchants</h1>
        <p class="merchant-info">
          This account is for individuals or businessess involved in selling
          commodities and purchasing agrochemicals.
        </p>
        <div class="type-list">
          <h3>Account Type</h3>
          <ol>
            <li>Farmers</li>
            <li>Cooperatives</li>
            <li>Aggregators</li>
            <li>Agtech Startups</li>
          </ol>
        </div>
      </div>

      <!-- corporate -->
      <div id="corporate" class="user-about user-select-content">
        <h1 class="about-corporate">Corporates</h1>
        <p class="corporate-info">
          This account is for off-takers and industrial processors as well as
          manufacturers of sustainable agrochemicals.
        </p>
        <div class="type-list">
          <h3>Account Type</h3>
          <ol>
            <li>Multinationals</li>
            <li>Nationals</li>
            <li>SMSE</li>
          </ol>
        </div>
      </div>
      <!-- !this section is for partners and Agent Account type. (if this feature is irrelevant please remove) -->

      <!-- Partner
      <div id="partner" class="user-about user-select-content">
        <h1 class="about-merchant">Partner</h1>
        <p class="merchant-info">
          This account is for financial and dev. institutions seeking to
          strengthen the agricultural value through credit facilities
        </p>
        <div class="type-list">
          <h3>Type</h3>
          <ol>
            <li>Dev. Institutions</li>
            <li>Money Banks</li>
            <li>MFBs</li>
            <li>Fintech</li>
          </ol>
        </div>
      </div> -->
      <!-- Agent -->
      <!-- <div id="agent" class="user-about user-select-content">
        <h1 class="about-merchant">Trustees</h1>
        <p class="merchant-info">
          This account is for field agents entrusted with commodities and
          agrochemicals on behalf of smallholder farmers for a commission.
        </p>
        <div class="type-list">
          <h3>Type</h3>
          <ol>
            <li>Agronomist</li>
            <li>Crop Advisors</li>
            <li>Input retailers</li>
            <li>Aggregators</li>
          </ol>
        </div>
      </div>  -->
    </div>
  </div>
</template>

<script>
export default {
  name: "UserType",
  mounted() {
    this.mouseEvent();
  },
  methods: {
    // Function to handle mouse events
    mouseEvent() {
      // Find the parent element with the class "login-views-1"
      const parentElement = document.querySelector(".login-views-1");

      // If the parent element is not found, log an error and return
      if (!parentElement) {
        console.error("Parent element not found.");
        return;
      }

      // Add a "mouseover" event listener to the parent element
      parentElement.addEventListener("mouseover", (event) => {
        // Find the nearest ancestor div element of the event target
        const elem = event.target.closest("div");

        // If no ancestor div is found, return
        if (!elem) return;

        // Find the element with the id matching the class name of the ancestor div
        const targetElement = document.querySelector(`#${elem.className}`);

        // If no matching element is found, return
        if (!targetElement) return;

        // Display the found target element
        targetElement.style.display = "block";

        // Add a class "classActive" to the ancestor div
        elem.classList.add("classActive");

        // Add a "mouseleave" event listener to each div element
        elem.addEventListener("mouseleave", () => {
          // Hide the target element when the mouse leaves the ancestor div
          targetElement.style.display = "none";

          // Remove the "classActive" class from the ancestor div
          elem.classList.remove("classActive");
        });
      });
    },
    beginRegistration(type) {
      this.$router.push(`/${type}/register`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

// main container
.user-type-main {
  width: 100%;
  height: 100vh;

  // user type left-content
  .user-type-left {
    width: 73.47%;
    background: url("@/assets/images/backgrounds/userViews.png");
    @include background;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.86);
      position: absolute;
      top: 0;
      left: 0;
      backdrop-filter: blur(6.5px);
    }
  }

  .about-user-type {
    width: 26.53%;
  }

  .login-views {
    width: 69.37%;
    gap: 55px;

    .Iam {
      @include textStyles(Poppins, 700, 36px, 140%);
      color: #ffffff;
      z-index: 999;
      text-align: center;
    }

    .login-views-1 {
      gap: 55px;

      div {
        width: 46%;
        height: 350px;
        border: 3px solid #ffffff;
        border-radius: 11px;
        z-index: 500;
        position: relative;
        @include background;
        @include textStyles(Poppins, 700, 35px, 140%);

        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.36);
          position: absolute;
          top: 0;
          left: 0;
        }

        span {
          text-align: center;
          color: #ffffff;
          z-index: 1000;
          width: 80%;
          margin: auto;
          margin-top: 90px;
          position: relative;
        }

        button {
          background: #05b050;
          box-shadow:
            0px 2px 4px rgba(44, 39, 56, 0.08),
            0px 4px 8px rgba(44, 39, 56, 0.08);
          border-radius: 6px;
          @include textStyles("Maven Pro", 700, 24px, 147%);
          color: #e6f7ee;
          width: 80%;
          margin: auto;
          margin-top: 42px;
          position: relative;
          z-index: 1000;
        }
      }

      .merchant {
        background: url("@/assets/images/backgrounds/merchant.png");
      }

      .corporate {
        background: url("@/assets/images/backgrounds/corporate.png");
      }

      .partner {
        background: url("@/assets/images/backgrounds/partner.png");
      }

      .agent {
        background: url("@/assets/images/backgrounds/agent.png");
      }
    }
  }

  // user-type-right
  .logo-container {
    width: 172.84px;
    height: 23.66px;
    margin: auto;
    margin-top: 64px;
  }

  // for seleect type: Merchant, corporate
  #merchant,
  #corporate,
  #partner,
  #agent {
    margin-top: 61px;
  }

  .user-select-content {
    padding-inline: 31px;

    .about-merchant {
      margin-top: 35px;
      @include textStyles(Poppins, 700, 24px, 140%);
      text-align: center;
      color: #4a4754;
    }

    .about-corporate {
      @extend .about-merchant;
      @include textStyles(Poppins, 700, 24px, 140%);
    }

    .merchant-info {
      color: #000000;
      @include textStyles(Poppins, 400, 16px, 140%);
    }

    .corporate-info {
      margin-top: 35px;
      color: #000000;
      @include textStyles(Poppins, 400, 16px, 140%);
    }

    .type-list {
      color: #000000;
      @include textStyles(Poppins, 400, 14px, 140%);
      margin-top: 31px;

      h3 {
        font-size: 20px;
        font-weight: 700;
      }
    }
  }

  .user-about {
    display: none;
  }

  .classActive {
    display: block !important;

    &::after {
      background: rgba(0, 0, 0, 0.7) !important;
    }
  }
}
</style>
