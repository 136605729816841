<template>
  <DefaultNav>
    <div class="big-container">
      <div class="headings">
        <h2 class="mt-1">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEwAAABACAYAAACwVZFQAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIvSURBVHgB7dzLccIwEIDhdcY5JQdKoQTTgSkhJaWClIA7wCVQCufAjKMVtsPLtiT0WEn7n8iQA3xjwI+1ADitCghYuasr8QLWHcBK/Nmet00LxAsDtqtX7wXsxKMKbl9M89vBF2ybIxCtBN9dsPbi0fr+KbGl1eK51QlgA0R7A5/NYF1V4UcViOYPTA1LVij8T6j8gGlgYf2PAMncg2li9bVANLdgBlhi62oo7164AzPbsg6fuFtBODf7YYZYHx1sjoT3wTD7YAljYXbBEsfC7IFlgIXZAcsEC3sdLCMs7DWwzLAwc7AMsTAzsEyxMH2wjLEwPbDMsTB1MMaSqYEx1tgyGGPdNA/GWA9NgzHW056DMdZkj2CMNdstmBkWnoj/FufiU8Yaxxj+wUyxMmkYYxhHBcoCfoCxJhvGGOQWJqdoLlsXt9Bwma0CTikJVqT9hW01CSY+nwfglBp/Jcum3hf80ZwNxxjGUYFzB1vgLW0ygdXiGAPvuC6E3+/4lfW44zpkeGh0EodGlGdTbWX14DsHNOund1JHc3ICMWU0Z6eoU0VzehEkRTTnl9lSQ/NyITclNG+jAqmgeR1GSQHN+7hT7GhBBupiRgs2shkrWtCh4BjRgo+dx4ZG4saGmNDI3DoTCxqpm7NiQHO3qkCiaO7ulxRvWr55vQsr6355BrK5vSPXDC3zVQUM0HhVAU20vFcVGNJDa4FofldGUUDLd1WBqXo0hLl/argcD4T7A4yVhVYmnleGAAAAAElFTkSuQmCC"
            alt=""
            class="xs-width"
            @click="goback()"
          />
          Farmer Information
        </h2>
        <div v-if="fetchedAcct == true" class="right-btns">
          <a
            v-if="!acct"
            href=" /dashboard/farmer/createwallet"
            class="green-link outline-green"
            ><small>Create Wallet</small></a
          >
          <!-- <a href=" /dashboard/farmer/view" class="green-link"
            ><small>All Farmers</small></a
          > -->
          <a
            v-if="acct"
            href="javascript:void(0)"
            class="green-link outline-green"
            @click="goto('view')"
            ><small>View Account</small></a
          >
        </div>
      </div>
      <div class="row">
        <template v-for="(data, index) in farmerData" :key="index">
          <!-- farmer credit score -->
          <div class="col-md-12 bg-white p-0">
            <template v-if="data.creditscore.grade !== 'null'">
              <div class="d-flex justify-content-between ps-4 pe-5 pt-4 pb-3">
                <h5>Credit Score</h5>
                <a
                  v-if="
                    data.creditscore.grade == 'A' ||
                    data.creditscore.grade == 'B' ||
                    (data.creditscore.grade == 'C' &&
                      currentuserid != 'partner')
                  "
                  href="javascript:void(0)"
                  class="green-link trimmed outline-green"
                  @click="goto('add')"
                  ><small>Make Loan Request</small></a
                >
                <h5 class="text-success d-flex justify-content-around">
                  Scored
                  <span
                    class="ms-1 cursor-pointer text-dark toggleIcon1"
                    @click="toggleSection('toggleIcon1', '#farmerscore')"
                  >
                    &plus;
                  </span>
                </h5>
              </div>
              <div id="farmerscore">
                <hr class="p-0 m-0" />
                <div class="row py-3 px-5">
                  <div class="col-4">
                    <b>Score (Grade)</b><br />
                    <h4 class="text-capitalize text-warning">
                      {{ roundValues(data.creditscore.score) }} ({{
                        nullToNA(data.creditscore.grade)
                      }})
                    </h4>
                  </div>
                  <div class="col-4">
                    <b class="pe-2">Loan Amount Requested</b><br />{{
                      nullToNA(data.creditscore.amountrequested)
                    }}
                  </div>
                  <div class="col-4">
                    <b class="pe-2">Average Income</b><br />{{
                      nullToNA(data.creditscore.averageincome)
                    }}
                  </div>
                </div>
                <div class="row py-3 px-5">
                  <div class="col-4">
                    <b>Loan Amount Recommendation </b><br />
                    <span class="text-capitalize">
                      {{ nullToNA(data.creditscore.recommendedamount) }}
                    </span>
                  </div>
                  <div class="col-4">
                    <b class="pe-2">Repayment Installment </b><br />{{
                      nullToNA(data.creditscore.installment)
                    }}
                  </div>
                  <div class="col-4">
                    <b class="pe-2">Reason for Recommendation</b><br />{{
                      nullToNA(data.creditscore.reasonforrecommendation)
                    }}
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="d-flex justify-content-between ps-4 pe-5 pt-4 pb-3">
                <h5>Credit Score</h5>
                <h5 class="text-warning">Not Scored</h5>
              </div>
            </template>
          </div>
          <!-- farmer basic data -->
          <div class="col-md-12 bg-white p-0 mt-3">
            <div class="d-flex justify-content-between ps-4 pe-5 pt-4 pb-3">
              <h5>
                Biodata
                <button
                  class="btn btn-info ms-3 text-white fs-bold"
                  @click="editBio(`/dashboard/farmer/edit/${farmerEdited}`)"
                >
                  Edit Biodata
                </button>
              </h5>
              <h5 class="text-success d-flex justify-content-around">
                <span class="me-2"
                  >{{ completedPercent(data.farmer)[0] }}%
                </span>
                Completed
                <span
                  class="ms-1 cursor-pointer text-dark toggleIcon2"
                  @click="toggleSection('toggleIcon2', '#biodata')"
                >
                  &plus;
                </span>
              </h5>
            </div>
            <div id="biodata">
              <hr class="p-0 m-0" />
              <div class="row py-3 px-5">
                <h6 class="col-4">
                  <b>Full Name </b><br />
                  <span class="text-capitalize">
                    {{ nullToNA(data.farmer.firstname, "farmer") }}
                    <span v-if="nullToNA(data.farmer.middlename) != 'null'">
                      {{ nullToNA(data.farmer.middlename, "farmer") }}
                    </span>
                    {{ nullToNA(data.farmer.lastname, "farmer") }}
                  </span>
                </h6>

                <div class="col-4">
                  <b class="pe-2">Phone Number </b><br />{{
                    nullToNA(data.farmer.mobile, "farmer")
                  }}
                </div>
                <div class="col-4">
                  <b class="pe-2">Email </b><br />{{
                    nullToNA(data.farmer.email, "farmer")
                  }}
                </div>
              </div>
              <div class="row py-4 px-5">
                <div class="col-4">
                  <b class="pe-2">Date of Birth: </b><br />{{
                    nullToNA(data.farmer.dateofbirth, "farmer")
                  }}
                </div>
                <div class="col-4">
                  <b class="pe-2">Gender: </b><br />{{
                    nullToNA(data.farmer.gender, "farmer")
                  }}
                </div>
                <div class="col-4">
                  <b class="pe-2">Marital Status: </b><br />{{
                    nullToNA(data.farmer.maritalstatus, "farmer")
                  }}
                </div>
              </div>
              <div class="row py-3 px-5">
                <div class="col-4">
                  <b class="pe-2">Country: </b><br />{{
                    nullToNA(data.farmer.country, "farmer")
                  }}
                </div>
                <div class="col-4">
                  <b class="pe-2">State: </b><br />{{
                    nullToNA(data.farmer.state, "farmer")
                  }}
                </div>
                <div class="col-4">
                  <b class="pe-2">Address: </b><br />{{
                    nullToNA(data.farmer.address, "farmer")
                  }}, {{ nullToNA(data.farmer.city, "farmer") }},
                  {{ nullToNA(data.farmer.state, "farmer") }}
                </div>
              </div>
              <div class="row mb-2 py-3 px-5">
                <div class="col-4">
                  <b class="pe-2">Landmark: </b><br />{{
                    nullToNA(data.farmer.landmark, "farmer")
                  }}
                </div>
                <div class="col-4">
                  <b class="pe-2">Spoken languages: </b><br />{{
                    nullToNA(data.farmer.languagesspoken, "farmer")
                  }}
                </div>
              </div>
            </div>
          </div>
          <!-- farmer kyf -->
          <div class="col-md-12 bg-white p-0 mt-3">
            <template
              v-if="
                data.farmerkyf.meansofidentification !== 'null' &&
                data.farmerkyf.professionalfarmingtraining !== 'null'
              "
            >
              <div class="d-flex justify-content-between ps-4 pe-5 pt-4 pb-3">
                <h5>
                  Farmer KYF
                  <button
                    class="btn btn-info ms-3 text-white fs-bold"
                    @click="
                      editBio(`/dashboard/farmer/kyf/edit/${farmerEdited}`)
                    "
                  >
                    Edit KYF
                  </button>
                </h5>
                <h5 class="text-success d-flex justify-content-around">
                  <span class="me-2"
                    >{{ completedPercent(data.farmerkyf)[0] }}%
                  </span>
                  Completed
                  <span
                    class="ms-1 cursor-pointer text-dark toggleIcon3"
                    @click="toggleSection('toggleIcon3', '#kyf')"
                  >
                    &plus;
                  </span>
                </h5>
              </div>
              <div id="kyf">
                <hr class="p-0 m-0" />
                <div class="row py-3 px-5">
                  <div class="col-4">
                    <b class="pe-2">Level Of Education </b><br />{{
                      nullToNA(data.farmerkyf.levelofeducation)
                    }}
                  </div>
                  <div class="col-4">
                    <b class="pe-2">Professional Farming Training </b><br />{{
                      nullToNA(data.farmerkyf.professionalfarmingtraining)
                    }}
                  </div>
                  <div class="col-4">
                    <b class="pe-2">In Corporative or Group: </b><br />{{
                      nullToNA(data.farmerkyf.corporativemembershipstatus)
                    }}
                  </div>
                </div>
                <div class="row py-4 px-5">
                  <div class="col-4">
                    <b class="pe-2">Corporative Name: </b><br />{{
                      nullToNA(data.farmerkyf.corporatename)
                    }}
                  </div>
                  <div class="col-4">
                    <b class="pe-2">Means Of Identification: </b><br />{{
                      nullToNA(data.farmerkyf.meansofidentification)
                    }}
                  </div>
                  <div class="col-4">
                    <b class="pe-2">ID Number: </b><br />{{
                      nullToNA(data.farmerkyf.identificationno)
                    }}
                  </div>
                </div>
                <div class="row py-3 px-5">
                  <div class="col-4">
                    <b class="pe-2">ID Issuance Date: </b><br />{{
                      nullToNA(data.farmerkyf.issuedate)
                    }}
                  </div>
                  <div class="col-4">
                    <b class="pe-2">ID Expiry Date: </b><br />{{
                      nullToNA(data.farmerkyf.expirydate)
                    }}
                  </div>
                  <div class="col-4">
                    <b class="pe-2">Name of Next Of Kin: </b><br />{{
                      nullToNA(data.farmerkyf.nextofkin.fullname)
                    }}
                  </div>
                </div>
                <div class="row mb-2 py-3 px-5">
                  <div class="col-4">
                    <b class="pe-2">Relationship of Next Of Kin: </b><br />{{
                      nullToNA(data.farmerkyf.nextofkin.relationshipnok)
                    }}
                  </div>
                  <div class="col-4">
                    <b class="pe-2">Mobile Number of Next Of Kin: </b><br />{{
                      nullToNA(data.farmerkyf.nextofkin.telephonenok)
                    }}
                  </div>
                  <div class="col-4">
                    <b class="pe-2">Occupation of Next Of Kin: </b><br />{{
                      nullToNA(data.farmerkyf.nextofkin.occupationnok)
                    }}
                  </div>
                </div>
              </div>
            </template>
          </div>
          <!-- farmer bank -->
          <div class="col-md-12 bg-white p-0 mt-3">
            <template
              v-if="
                data.farmerbank.bankname !== 'null' &&
                data.farmerbank.bankaccountname !== 'null'
              "
            >
              <div class="d-flex justify-content-between ps-4 pe-5 pt-4 pb-3">
                <h5>Bank Data</h5>
                <h5 class="text-success d-flex justify-content-around">
                  <span class="me-2"
                    >{{ completedPercent(data.farmerbank)[0] }}%
                  </span>
                  Completed
                  <span
                    class="ms-1 cursor-pointer text-dark toggleIcon4"
                    @click="toggleSection('toggleIcon4', '#bankinfo')"
                  >
                    &plus;
                  </span>
                </h5>
              </div>
              <div id="bankinfo">
                <hr class="p-0 m-0" />
                <div class="row py-3 px-5">
                  <div class="col-4">
                    <b class="pe-2">Bank Name </b><br />{{
                      nullToNA(data.farmerbank.bankname)
                    }}
                  </div>
                  <div class="col-4">
                    <b class="pe-2">Account Name </b><br />{{
                      nullToNA(data.farmerbank.bankaccountname)
                    }}
                  </div>
                  <div class="col-4">
                    <b class="pe-2">Account Number: </b><br />{{
                      nullToNA(data.farmerbank.bankaccountnumber)
                    }}
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="d-flex justify-content-between ps-4 pe-5 pt-4 pb-3">
                <h5>Bank Data</h5>
                <h5 class="text-warning">Missing</h5>
              </div>
            </template>
          </div>
          <!-- farmer farm(s) information -->
          <div class="col-md-12 bg-white p-0 mt-3">
            <template
              v-if="
                data.farminformation[0].estimatedtotalincomeperyear !== 'null'
              "
            >
              <div class="d-flex justify-content-between ps-4 pe-5 pt-4 pb-3">
                <h5>
                  Farm Information
                  <button
                    class="btn btn-info ms-3 text-white fs-bold"
                    @click="
                      editBio(
                        `/dashboard/farmer/farm/information/edit/${farmerEdited}`,
                      )
                    "
                  >
                    Edit Farm Information
                  </button>
                </h5>
                <h5 class="text-success d-flex justify-content-around">
                  <span class="me-2"
                    >{{ completedPercent(data.farminformation[0])[0] }}%
                  </span>
                  Completed
                  <span
                    class="ms-1 cursor-pointer text-dark toggleIcon5"
                    @click="toggleSection('toggleIcon5', '#farminfo')"
                  >
                    &plus;
                  </span>
                </h5>
              </div>
              <div id="farminfo">
                <hr class="p-0 m-0" />
                <div class="row py-3 px-5">
                  <h6 class="col-4">
                    <b>Farming Type </b><br />
                    <span class="text-capitalize">
                      {{ nullToNA(data.farminformation[0].farmingtype) }}
                    </span>
                  </h6>
                  <div class="col-4">
                    <b class="pe-2">Farm Size Per Sqm</b><br />{{
                      nullToNA(data.farminformation[0].farmsizesqm)
                    }}
                  </div>
                  <div class="col-4">
                    <b class="pe-2">Crop Types </b><br />{{
                      nullToNA(data.farminformation[0].croptypes)
                    }}
                  </div>
                </div>
                <div class="row py-3 px-5">
                  <div class="col-4">
                    <b>Farm Location </b><br />
                    <span class="text-capitalize">
                      {{ nullToNA(data.farminformation[0].farmlocation) }}
                    </span>
                  </div>
                  <div class="col-4">
                    <b class="pe-2">Farm Location Cordinates</b><br />{{
                      nullToNA(data.farminformation[0].farmlocationcoordinates)
                        .latitude
                    }}
                    lat,
                    {{
                      nullToNA(data.farminformation[0].farmlocationcoordinates)
                        .longitude
                    }}
                    long
                  </div>
                  <div class="col-4">
                    <b class="pe-2">Total Income per year: </b><br />{{
                      nullToNA(
                        data.farminformation[0].estimatedtotalincomeperyear,
                      )
                    }}
                  </div>
                </div>
                <div class="row py-4 px-5">
                  <div class="col-4">
                    <b class="pe-2">Farm Ownership: </b><br />{{
                      nullToNA(data.farminformation[0].farmownership)
                    }}
                  </div>
                  <div class="col-4">
                    <b class="pe-2">Name of Farm Owner: </b><br />{{
                      nullToNA(data.farminformation[0].nameofowner)
                    }}
                  </div>
                  <div class="col-4">
                    <b class="pe-2">Mobile Number of Farm Owner: </b><br />{{
                      nullToNA(data.farminformation[0].farmownermobile)
                    }}
                  </div>
                </div>
                <div class="row py-3 px-5">
                  <div class="col-4">
                    <b class="pe-2">Number of Years Leased: </b><br />{{
                      nullToNA(data.farminformation[0].numberofyearsleased)
                    }}
                  </div>
                  <div class="col-4">
                    <b class="pe-2">Number of Animals: </b><br />{{
                      nullToNA(data.farminformation[0].numberofanimals)
                    }}
                  </div>
                  <div class="col-4">
                    <b class="pe-2">Types of Animals Reared: </b><br />{{
                      nullToNA(data.farminformation[0].animaltypes)
                    }}
                  </div>
                </div>
              </div>
            </template>
          </div>
          <!-- farmer farm machines -->
          <div class="col-md-12 bg-white p-0 mt-3">
            <template v-if="data.farmmechanization[0].typeofmachine !== 'null'">
              <div class="d-flex justify-content-between ps-4 pe-5 pt-4 pb-3">
                <h5>
                  Farm Machines

                  <button
                    class="btn btn-info ms-3 text-white fs-bold"
                    @click="
                      editBio(
                        `/dashboard/farmer/farm/mechanization/edit/${farmerEdited}`,
                      )
                    "
                  >
                    Edit Farm Machine
                  </button>
                </h5>
                <h5 class="text-success d-flex justify-content-around">
                  <span class="me-2"
                    >{{ completedPercent(data.farmmechanization[0])[0] }}%
                  </span>
                  Completed
                  <span
                    class="ms-1 cursor-pointer text-dark toggleIcon6"
                    @click="toggleSection('toggleIcon6', '#farmachine')"
                  >
                    &plus;
                  </span>
                </h5>
              </div>
              <div id="farmachine">
                <hr class="p-0 m-0" />
                <div class="row py-3 px-5">
                  <h6 class="col-4">
                    <b>Types of Machines Owned</b><br />
                    <span class="text-capitalize">
                      {{ nullToNA(data.farmmechanization[0].typeofmachine) }}
                    </span>
                  </h6>
                  <div class="col-4">
                    <b class="pe-2">Number of Machines Possessed</b><br />{{
                      nullToNA(
                        data.farmmechanization[0].noofthismachinepossessed,
                      )
                    }}
                  </div>
                  <div class="col-4">
                    <b class="pe-2">Average Number of Years Used </b><br />{{
                      nullToNA(data.farmmechanization[0].averagenoofyearsused)
                    }}
                  </div>
                </div>
                <div class="row py-3 px-5">
                  <div class="col-4">
                    <b>Number of Active Units </b><br />
                    <span class="text-capitalize">
                      {{ nullToNA(data.farmmechanization[0].noofactiveunits) }}
                    </span>
                  </div>
                  <div class="col-4">
                    <b class="pe-2">Number of Defective Units</b><br />{{
                      nullToNA(data.farmmechanization[0].noofdefectiveunits)
                    }}
                  </div>
                  <div class="col-4">
                    <b class="pe-2">Average Cost of Maintenance: </b><br />{{
                      nullToNA(
                        data.farmmechanization[0]
                          .averagecostofmaintenanceinnaira,
                      )
                    }}
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="d-flex justify-content-between ps-4 pe-5 pt-4 pb-3">
                <h5>Farm Machines</h5>
                <h5 class="text-warning">Missing</h5>
              </div>
            </template>
          </div>
          <!-- farmer farming practice -->
          <div class="col-md-12 bg-white p-0 mt-3">
            <template
              v-if="data.farmpractices.totalaverageincomeperharvest > 0"
            >
              <div class="d-flex justify-content-between ps-4 pe-5 pt-4 pb-3">
                <h5>
                  Farm Practice
                  <button
                    class="btn btn-info ms-3 text-white fs-bold"
                    @click="
                      editBio(
                        `/dashboard/farmer/farmpractice/edit/${farmerEdited}`,
                      )
                    "
                  >
                    Edit Farm Practice
                  </button>
                </h5>
                <h5 class="text-success">
                  <span class="me-2"
                    >{{ completedPercent(data.farmpractices)[0] }}%
                  </span>
                  Completed
                  <span
                    class="ms-1 cursor-pointer text-dark toggleIcon7"
                    @click="toggleSection('toggleIcon7', '#fampractice')"
                  >
                    &plus;
                  </span>
                </h5>
              </div>
              <div id="fampractice">
                <hr class="p-0 m-0" />
                <div class="row py-3 px-5">
                  <h6 class="col-4">
                    <b>Crop Rotation</b><br />
                    <span class="text-capitalize">
                      {{ nullToNA(data.farmpractices.croprotationpractice) }}
                    </span>
                  </h6>
                  <div class="col-4">
                    <b class="pe-2">Fertilizers used per crop cycle</b><br />{{
                      nullToNA(data.farmpractices.listfertilizersused)
                    }}
                  </div>
                  <div class="col-4">
                    <b class="pe-2">Fungicides used </b><br />{{
                      nullToNA(data.farmpractices.listoffungicidesused)
                    }}
                  </div>
                </div>
                <div class="row py-3 px-5">
                  <div class="col-4">
                    <b>Number of Sqm you can cultivate </b><br />
                    <span class="text-capitalize">
                      {{
                        nullToNA(data.farmpractices.numberofsqmyoucancultivate)
                      }}
                    </span>
                  </div>
                  <div class="col-4">
                    <b class="pe-2">Fertilizer used per year</b><br />{{
                      nullToNA(data.farmpractices.totalfertilizerusedperseason)
                    }}
                  </div>
                  <div class="col-4">
                    <b class="pe-2">Fertilizer used per crop cycle</b><br />{{
                      nullToNA(data.farmpractices.fertilizerappliedpercropcycle)
                    }}
                  </div>
                </div>
                <div class="row py-3 px-5">
                  <div class="col-4">
                    <b class="pe-2">Weed Control Practices: </b><br />{{
                      nullToNA(data.farmpractices.weedcontrolpractices)
                    }}
                  </div>
                  <div class="col-4">
                    <b>Most Viable Crop </b><br />
                    <span class="text-capitalize">
                      {{ nullToNA(data.farmpractices.yourmostviablecrop) }}
                    </span>
                  </div>
                  <div class="col-4">
                    <b class="pe-2">Intended Crop</b><br />{{
                      nullToNA(data.farmpractices.intendedcrop)
                    }}
                  </div>
                </div>
                <div class="row py-3 px-5">
                  <div class="col-4">
                    <b class="pe-2">Total Income Per Harvest Season: </b
                    ><br />{{
                      nullToNA(data.farmpractices.totalaverageincomeperharvest)
                    }}
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="d-flex justify-content-between ps-4 pe-5 pt-4 pb-3">
                <h5>Farm Practice</h5>
                <h5 class="text-warning">Missing</h5>
              </div>
            </template>
          </div>
          <!-- farmer loan history -->
          <div class="col-md-12 bg-white p-0 mt-3">
            <template v-if="data.loanhistory.iftakenaloanbefore !== 'null'">
              <div class="d-flex justify-content-between ps-4 pe-5 pt-4 pb-3">
                <h5>Loan History</h5>
                <h5 class="text-success">
                  Completed<span
                    class="ms-1 cursor-pointer text-dark toggleIcon8"
                    @click="toggleSection('toggleIcon8', '#lhistory')"
                  >
                    &plus;
                  </span>
                </h5>
              </div>
              <div id="lhistory">
                <hr class="p-0 m-0" />
                <div class="row py-3 px-5">
                  <h6 class="col-4">
                    <b>Cost of Cultivation Per Planting Season</b><br />
                    <span class="text-capitalize">
                      {{
                        nullToNA(
                          data.loanhistory.costofcultivationperplantseason,
                        )
                      }}
                    </span>
                  </h6>
                  <div class="col-4">
                    <b class="pe-2">Cost of Cultivation Per Year</b><br />{{
                      nullToNA(data.loanhistory.whatiscostofcultivation)
                    }}
                  </div>
                  <div class="col-4">
                    <b class="pe-2">Taken a Loan Before </b><br />{{
                      nullToNA(data.loanhistory.iftakenaloanbefore)
                    }}
                  </div>
                </div>
                <div class="row py-3 px-5">
                  <div class="col-4">
                    <b>Has the Loan Been Repaid </b><br />
                    <span class="text-capitalize">
                      {{ nullToNA(data.loanhistory.hasitbeenrepaid) }}
                    </span>
                  </div>
                  <div class="col-4">
                    <b class="pe-2">How did you repay</b><br />{{
                      nullToNA(data.loanhistory.howdidyourepay)
                    }}
                  </div>
                  <div class="col-4">
                    <b class="pe-2">Experienced Difficulty Repaying</b><br />{{
                      nullToNA(data.loanhistory.haddifficultypaying)
                    }}
                  </div>
                </div>
                <div class="row py-3 px-5">
                  <div class="col-4">
                    <b class="pe-2">When Was the Loan Taken: </b><br />{{
                      nullToNA(data.loanhistory.loantakenseason)
                    }}
                  </div>
                  <div class="col-4">
                    <b>Loan Amount </b><br />
                    <span class="text-capitalize">
                      {{ nullToNA(data.loanhistory.pastloantakenamountnaira) }}
                    </span>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="d-flex justify-content-between ps-4 pe-5 pt-4 pb-3">
                <h5>Loan History</h5>
                <h5 class="text-warning">Missing</h5>
              </div>
            </template>
          </div>
          <!-- farmer loan request -->
          <div class="col-md-12 bg-white p-0 mt-3">
            <template
              v-if="
                data.pitch.estimatedcostinginnaira !== 'null' ||
                data.pitch.estimatedcostinginnaira > 0
              "
            >
              <div class="d-flex justify-content-between ps-4 pe-5 pt-4 pb-3">
                <h5>Loan Appeal</h5>
                <h5 class="text-success">
                  Completed
                  <span
                    class="ms-1 cursor-pointer text-dark toggleIcon9"
                    @click="toggleSection('toggleIcon9', '#loanpitch')"
                  >
                    &plus;
                  </span>
                </h5>
              </div>
              <div id="loanpitch">
                <hr class="p-0 m-0" />
                <div class="row py-3 px-5">
                  <h6 class="col-4">
                    <b>Amount Desired</b><br />
                    <span class="text-capitalize">
                      {{ nullToNA(data.pitch.estimatedcostinginnaira) }}
                    </span>
                  </h6>
                  <div class="col-4">
                    <b class="pe-2">Name of the Project</b><br />{{
                      nullToNA(data.pitch.intendedproject)
                    }}
                  </div>
                  <div v-if="data.pitch.projectdescription" class="col-4">
                    <b class="pe-2">Project Description </b><br />{{
                      nullToNA(data.pitch.projectdescription)
                    }}
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="d-flex justify-content-between ps-4 pe-5 pt-4 pb-3">
                <h5>Loan Appeal</h5>
                <h5 class="text-warning">Missing</h5>
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import Preloader from "@/layouts/shared/Preloader.vue";
import FarmerService from "@/services/farmer";
import LoanService from "@/services/loan";
export default {
  name: "SingleFarmer",
  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      farmerData: [],
      farmerKyfData: [],
      farmerBankData: [],
      farmInformation: [],
      farmMechanization: [],
      farmerPractice: [],
      loanHistory: [],
      Pitch: [],
      croprotationpractice: "",
      training: "",
      corporative: "",
      takenloan: "",
      difficultypaying: "",
      beenpaid: "",
      currentuserid: JSON.parse(localStorage.getItem("Zowasel")).user.user.type,
      emptyFields: [0, 0, 0, 0, 0, 0, 0, 0],
      arrayToSectionMatch: "",
      acct: null,
      fetchedAcct: false,
      farmerEdited: localStorage.getItem("farmerID"),
    };
  },
  mounted() {
    console.log(this.currentuserid);
    this.isLoading = true;
    const url = window.location.href;
    const farmerid = url.split("/").slice(-1)[0];
    this.getVWallet();
    FarmerService.getSingleFarmer(farmerid, (response) => {
      // console.log(response.data);
      this.isLoading = false;
      if (response.data == null) {
        window.location.href = `/dashboard/farmer/view`;
      } else {
        localStorage.setItem("farmersdata", JSON.stringify(response.data));
        this.farmerData.push(response.data);
        localStorage.setItem("activeFarmerData", JSON.stringify(response.data));
      }
    });
  },
  methods: {
    thousandsData(x) {
      return "₦" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    roundValues(x) {
      if (x != "" || x != "null") {
        return x.toFixed(2);
      } else {
        return x;
      }
    },
    toggleSection(x, section) {
      document.querySelector(section).classList.toggle("d-none");
      if (document.querySelector(section).classList.contains("d-none")) {
        document.querySelector(`.${x}`).innerHTML = "&times;";
      } else {
        document.querySelector(`.${x}`).innerHTML = "&plus;";
      }
    },
    goto(loc) {
      let URL = window.location.href;
      if (loc == "add") {
        localStorage.setItem("farmerID", URL.split("/").slice(-1)[0]);
        window.location.href = `/dashboard/loan/type/select`;
      } else {
        localStorage.setItem("farmerID", URL.split("/").slice(-1)[0]);
        window.location.href = `/dashboard/farmer/wallet`;
      }
    },
    editBio(loc) {
      window.location.href = loc;
    },
    goback() {
      history.go(-1);
    },
    nullToNA(data, section = "") {
      this.arrayToSectionMatch = [
        "farmer",
        "kyf",
        "bank",
        "info",
        "machine",
        "practice",
        "history",
        "appeal",
      ];
      if (data == "null") {
        if (section !== "") {
          this.emptyFields[this.arrayToSectionMatch.indexOf(section)] += 1;
        }
        return "N/A";
      } else if (data == "") {
        if (section !== "") {
          this.emptyFields[this.arrayToSectionMatch.indexOf(section)] += 1;
        }
        return "N/A";
      }
      return data;
    },
    hasValue(value) {
      return value !== null && value !== undefined && value !== "";
    },
    completedPercent(jsonarray) {
      let filled = 0;
      let empty = 0;
      for (const key in jsonarray) {
        if (Object.hasOwnProperty.call(jsonarray, key)) {
          const value = jsonarray[key];
          if (this.hasValue(value)) {
            filled += 1;
          } else {
            empty += 1;
          }
        }
      }
      console.log(filled, empty);
      return [
        ((filled / (filled + empty)) * 100).toFixed(1),
        ((empty / (filled + empty)) * 100).toFixed(1),
      ];
      // return [filled, empty];
    },
    getVWallet() {
      let vm = this;
      const url = window.location.href;
      const farmerid = url.split("/").slice(-1)[0];
      LoanService.getVwallet("farmer", farmerid, (response) => {
        vm.fetchedAcct = true;
        // console.log(response);
        if (response && response.data) {
          localStorage.setItem("farmeracct", response.data.account_number);
          vm.acct = response.data.data.account_number;
          // console.log(response.data);
          this.getLH();
        }
      });
    },
    getLH() {
      let accountno = localStorage.getItem("farmeracct");
      LoanService.getVloanhistory(accountno, (response) => {
        console.log(response);
        // if (response && response.data) {
        //   localStorage.setItem("farmeracct", response.data.account_number);
        //   vm.acct = response.data.account_number;
        //   vm.fetchedAcct = true;
        //   console.log(response.data);
        // }
      });
    },

    // editFarmerData(farmerid) {
    //   window.location.href = `/dashboard/farmer/edit/${farmerid}`;
    // },
    // runKYF(farmerid) {
    //   window.location.href = `/dashboard/farmer/kyf/${farmerid}`;
    // },
    // editFarmerKYFData(farmerid) {
    //   window.location.href = `/dashboard/farmer/kyf/edit/${farmerid}`;
    // },
    // addFarmerBank(farmerid) {
    //   window.location.href = `/dashboard/farmer/bank/${farmerid}/add`;
    // },
    // editFarmerBankData(farmerid) {
    //   window.location.href = `/dashboard/farmer/bank/${farmerid}/edit`;
    // },
    // addFarmInformation(farmerid) {
    //   window.location.href = `/dashboard/farmer/farm/information/add/${farmerid}`;
    // },
    // editFarmInformation(farmerid, farmid) {
    //   window.location.href = `/dashboard/farmer/farm/information/edit/${farmerid}/${farmid}`;
    // },
    // addFarmMechanization(farmerid) {
    //   window.location.href = `/dashboard/farmer/farm/mechanization/add/${farmerid}`;
    // },
    // addFarmPractice(farmerid) {
    //   window.location.href = `/dashboard/farmer/farmpractice/add/${farmerid}`;
    // },
    // editFarmPractice(farmerid) {
    //   window.location.href = `/dashboard/farmer/farmpractice/edit/${farmerid}`;
    // },
    // addLoanHistory(farmerid) {
    //   window.location.href = `/dashboard/loanhistory/add/${farmerid}`;
    // },
    // editLoanHistory(farmerid) {
    //   window.location.href = `/dashboard/loanhistory/edit/${farmerid}`;
    // },
    // editFarmMachine(farmerid, machineid) {
    //   window.location.href = `/dashboard/farmer/farm/mechanization/edit/${farmerid}/${machineid}`;
    // },
    // addPitch(farmerid) {
    //   window.location.href = `/dashboard/farmer/pitch/add/${farmerid}`;
    // },
    // editPitch(farmerid) {
    //   window.location.href = `/dashboard/farmer/pitch/edit/${farmerid}`;
    // },
  },
};
</script>

<style lang="scss" scoped>
.cursor-pointer:hover {
  cursor: pointer;
}
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 4% 5% 10% 3%;
  overflow-y: scroll;
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}
.active-subscriptions {
  background-color: white;
  padding: 24px 35px;
  margin-top: 20px;
}

/* The slider */

.sub-history {
  margin-top: 30px;
  width: 100%;
  margin-left: 10px;

  h2 {
    margin-bottom: 40px;
  }
  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #4a4754;
  }

  .serial {
    max-width: 50px;
  }

  tr {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 9px;
    height: 66px;
  }
  td {
    flex: 1;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4754;
  }
  .active {
    width: 100px;
    height: 26px;
    background: #dcfce7;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .active-dot {
      width: 7.52px;
      height: 8px;
      background: #047b38;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #047b38;
    }
  }
  .expired {
    width: 100px;
    height: 26px;
    background: #fff8e6;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .expired-dot {
      width: 7.52px;
      height: 8px;
      background: #fab900;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #fab900;
    }
  }
  .cancelled {
    width: 100px;
    height: 26px;
    background: rgba(252, 220, 220, 0.93);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .cancelled-dot {
      width: 7.52px;
      height: 8px;
      background: #c52222;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #6b0303;
    }
  }
  a {
    text-decoration: none;
  }
  a:hover td {
    color: #05b050 !important;
  }
}
.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  a {
    text-decoration: none;
  }
  .right-btns {
    display: flex;
    gap: 20px;
  }
  .green-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 180px;
    height: 40px;
    background: #05b050;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    border-radius: 4px;
  }
  .white-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 40px;
    background: #ffffff;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #05b050;
    border: 2px solid #05b050;
    border-radius: 4px;
  }
  .red-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 180px;
    height: 40px;
    background: #cd4647;
    box-shadow:
      0px 2px 4px rgba(44, 39, 56, 0.08),
      0px 4px 8px rgba(44, 39, 56, 0.08);
    border-radius: 4px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    border-radius: 4px;
  }
}

.green-link {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 180px;
  height: 40px;
  background: #05b050;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.02em;
  border-radius: 4px;
}

.trimmed {
  width: 160px;
  height: 30px;
}

.w100 {
  width: 100%;
}
.date-region {
  margin-top: 30px;
}

.outline-green {
  color: #05b050 !important;
  text-align: center;
  background: #f5f5f5 !important;
  border: 2px solid #05b050;
}

.xs-width {
  width: 20px !important;
  cursor: pointer;
}
</style>
