<template>
  <DefaultNav>
    <div class="big-container">
      <!-- header for oder tracking  -->
      <div class="page-header">
        <h1>Upload Documents</h1>
        <button>Save</button>
      </div>
      <!--body container -->
      <form class="transaction-sumary-container" @submit.prevent="saveData()">
        <!-- left -->
        <!-- right -->
        <div class="right-container">
          <div class="right-container-wrapper">
            <div class="form-group">
              <label for="inputEmail4">Select Document</label>
              <select id="" name="" class="form-control">
                <option>Utility Bill</option>
                <option value="">Equity Contribution</option>
              </select>
            </div>
            <div id="dropContainer" class="drop-container">
              <p>Drag & Drop Images Here</p>
              <input
                id="fileInput"
                type="file"
                multiple
                accept="image/*"
                required
                @change="uploadFile"
              />
            </div>
            <div id="previewContainer" class="preview-container"></div>
          </div>
        </div>
      </form>
    </div>
  </DefaultNav>
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import Alert from "@/utilities/alert.js";
import MarketPlaceService from "@/services/marketplace";

export default {
  name: "OrderTracking",
  components: {
    DefaultNav,
  },
  data() {
    return {
      userData: this.$store.state.user,
      files: [],
      fileNames: [],
    };
  },
  mounted() {
    // setInterval(() => {
    //   if (this.orderProgress < 100) {
    //     this.orderProgress++;
    //   }
    // }, 100);
    // this.getOrder(this.$route.params.order);
    // console.log(this.order);
    const dropContainer = document.getElementById("dropContainer");
    const fileInput = document.getElementById("fileInput");
    const previewContainer = document.getElementById("previewContainer");

    dropContainer.addEventListener("dragover", (e) => {
      e.preventDefault();
      dropContainer.classList.add("highlight");
    });

    dropContainer.addEventListener("dragleave", () => {
      dropContainer.classList.remove("highlight");
    });

    dropContainer.addEventListener("drop", (e) => {
      e.preventDefault();
      dropContainer.classList.remove("highlight");
      const files = e.dataTransfer.files;
      handleFiles(files);
    });

    fileInput.addEventListener("change", () => {
      const files = fileInput.files;
      handleFiles(files);
    });

    function handleFiles(files) {
      for (const file of files) {
        if (file.type.startsWith("image/")) {
          const img = document.createElement("img");
          img.style.width = "150px";
          img.style.height = "150px";
          img.classList.add("preview-image");
          img.src = URL.createObjectURL(file);

          const deleteButton = document.createElement("button");
          deleteButton.style.backgroundColor = "red";
          deleteButton.style.color = "white";
          deleteButton.style.border = "none";
          deleteButton.style.borderRadius = "20px";
          deleteButton.style.width = "25px";
          deleteButton.style.height = "25px";
          deleteButton.style.cursor = "pointer";
          deleteButton.classList.add("delete-button");
          deleteButton.innerText = "X";
          deleteButton.addEventListener("click", () => {
            img.remove();
            deleteButton.remove();
          });

          const previewItem = document.createElement("div");
          previewItem.style.display = "flex";
          previewItem.style.flexDirection = "column";
          previewItem.appendChild(img);
          previewItem.appendChild(deleteButton);
          previewContainer.appendChild(previewItem);
        }
      }
    }
  },
  methods: {
    uploadFile(event) {
      const input = event.target;
      const file = input.files;
      this.files.push(file[0]);

      // get the file name
      this.fileNames[eval(input.getAttribute("data-index"))] = file[0].name;

      // preview the image

      // if at least one image is selected proceed to display the image
      if (file[0]) {
        // get the image path
        const imageSrc = URL.createObjectURL(file[0]);
        // select the image preview element
        // const imagePreviewElement = document.querySelector(
        //   `#${input.getAttribute("data-preview")}`,
        // );
        // // assign the path to the image preview element
        // imagePreviewElement.src = imageSrc;
        // imagePreviewElement.style.display = "block";
        // // hide sibling img element
        // imagePreviewElement.nextElementSibling.style.display = "none";
      }
    },
    async saveData() {
      var data = new FormData();
      var index = 0;
      let vm = this;
      this.isLoading = true;
      while (this.files[index]) {
        data.append(`image_${index}`, this.files[index]);
        index++;
      }
      // for (var field in this.inputData) {
      //   if (field != "files") {
      //     if (typeof this.inputData[field] == "object") {
      //       this.inputData[field] = JSON.stringify(this.inputData[field]);
      //     }
      //     data.append(field, this.inputData[field]);
      //   }
      // }
      // send data to the end-point
      var order_hash = this.$route.params.order;
      console.log(order_hash);
      MarketPlaceService.addDispute(
        order_hash,
        {
          reasons: JSON.stringify(this.reasons),
          details: this.details,
          packaging: this.packaging,
          quantity: this.quantity,
          scale_type: this.scale_type,
          gross_weight: this.gross_weight,
          tare_weight: this.tare_Weight,
          net_weight: this.net_weight,
          rejected_quantity: this.rejected_quantity,
          accepted_quantity: this.accepted_quantity,
        },

        (response) => {
          if (response && response.error == false) {
            Alert.success({
              message: "Reconcilliation request successfully sent",
            });
            setTimeout(() => {
              window.location.assign(
                "/dashboard/marketplace/" + order_hash + "/disputesuccess",
              );
            }, 2000);
          } else if (response && response.error) {
            $router.push({
              name: "DisputeFailed",
              params: { id: order.order_hash },
            });
          }
        },
      );
    },
    getOrder(order) {
      let vm = this;
      MarketPlaceService.getOrder(order, (response) => {
        var order = response.data;
        order.products = JSON.parse(order.products);
        this.order = order;
        console.log(this.order);
        if (order.receipt_note) {
          this.stage = "receipt-uploaded";
        }
        // setTimeout(() => {
        //   vm.calculateOrderProgress();
        // }, 500);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  @include breakpoint-between(md, lg) {
    min-width: 60.5%;
  }

  @include breakpoint-between(lg, xl) {
    min-width: 69.5%;
  }

  @include breakpoint-between(xl, xxl) {
    min-width: 76%;
  }
}
.page-header {
  width: 100%;
  padding: 50px 100px 0px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    @include textStyles(Poppins, 700, 24px, 120%);
  }
  button {
    width: 150px;
    background: #05b050;
    height: 48px;
    color: white;
    border: none;
    border-radius: 10px;
  }
}

.transaction-sumary-container {
  width: 100%;
  display: flex;
  justify-content: center;

  .left-container {
    background: #ffffff;
    width: 55%;
    padding: 20px;

    form {
      padding: 30px;
    }

    // button group
  }

  // Right-container
  .right-container {
    background: #f5f5f5;
    width: 100%;

    .right-container-wrapper {
      margin-inline: 4%;
      margin-top: 65px;

      h1 {
        @include textStyles(Inter, 600, 20px, 28px);
        letter-spacing: -0.02em;
        color: #0a0d13;
        margin-bottom: 17px;
        text-align: center;
      }

      hr {
        border: 1px solid rgba(0, 0, 0, 0.18);
        margin-bottom: 23px;
      }
    }
  }
}
.confirmed-text {
  color: #05b050;
  margin-top: 10px;
}
.option {
  display: flex;
  gap: 10px;

  p {
    margin-top: 20px;
  }
}
.title {
  h5 {
    font-family: Maven Pro;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-top: 35px;
  }

  p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 300;
  }
}

.drop-container {
  border: 2px dashed #ccc;
  padding: 20px;
  cursor: pointer;
}

.preview-container {
  border: 1px solid #05b050;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  height: auto;
  margin-top: 20px;
  padding: 20px 5px;
  box-sizing: border-box;
}
.preview-image {
  width: 150px !important;
  height: 150px !important;

  img {
    width: 200px;
  }
}

.delete-button {
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 5px 8px;
  cursor: pointer;
  position: absolute;
}
.img-con {
  width: 100px !important;

  img {
    width: 100%;
  }
}
.btn-goto-marketplace {
  background: #05b050;
  color: #ffffff;
  width: 100%;
}

.btn-track {
  border: 1px solid #040308;
  color: #040308;
}
</style>
