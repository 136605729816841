<template>
  <DispatchSection v-if="step == 1" :order="order" :proceed="saveDispatch" />

  <!-- <ReportSection
    v-if="step == 2"
    :proceed="saveReceipt"
    :go-back="previousStep"
  /> -->
  <div v-if="false" class="position-relatives">
    <div class="btn-group w-100 gap-4">
      <div class="download">
        <a class="btn" type="button" @click="downloadOpt()">Download</a>
        <div v-if="downloadFile == true" class="download_container">
          <button
            href=""
            class="download_invoice as_image"
            @click="downloadAsImage()"
          >
            image
          </button>

          <button class="download_invoice as_pdf" @click="downloadAsPDF()">
            pdf
          </button>
        </div>
      </div>
      <div class="download">
        <a class="btn" type="button" @click="printSection()">Print</a>
      </div>
    </div>
  </div>
</template>

<script>
import DispatchSection from "@/pages/dashboard/marketPlace/checkout/components/DispatchSection.vue";
// import ReportSection from "@/pages/dashboard/marketPlace/checkout/components/ReportSection.vue";
import OrderService from "@/services/order";

export default {
  name: "WaybillDetails",
  components: {
    DispatchSection,
    // ReportSection,
  },
  props: {
    updateStep: Function,
    order: Object,
  },
  data() {
    return {
      step: 1,
      dispatchData: {},
      // receiptData: {},
    };
  },
  mounted() {},
  methods: {
    saveDispatch(dispatch) {
      let vm = this;
      this.dispatchData = dispatch;
      this.dispatchData.items = this.order.products;
      const waybillNo = 1000000 + this.order.id;
      OrderService.saveWaybillDetails(
        {
          order: this.$route.params.order,
          waybillDetails: {
            waybill_number: waybillNo,
            dispatch_section: this.dispatchData,
            // receipt_section: receipt,
          },
        },
        (response) => {
          vm.$router.push({ name: "OrderTracking" });
        },
      );
      this.nextStep();
    },
    // saveReceipt(receipt) {
    //   let vm = this;
    //   // this.receiptData = receipt;
    //   // receipt.items = this.order.products;
    //   this.dispatchData.items = this.order.products;
    //   OrderService.saveWaybillDetails(
    //     {
    //       order: this.$route.params.order,
    //       waybillDetails: {
    //         dispatch_section: this.dispatchData,
    //         // receipt_section: receipt,
    //       },
    //     },
    //     (response) => {
    //       vm.$router.push({ name: "OrderTracking" });
    //     }
    //   );
    //   this.nextStep();
    // },
    nextStep() {
      this.step++;
      this.updateStep(this.step);
    },
    previousStep() {
      this.step--;
      this.updateStep(this.step);
    },
  },
};
</script>

<style lang="scss" scoped></style>
