<template>
  <div></div>
</template>

<script>
import TransactionService from "@/services/transaction";
export default {
  name: "PaymentStatus",
  mounted() {
    var vm = this;
    var order = this.$route.params.order;
    if (this.$route.query.status == "successful") {
      var txRef = this.$route.query.tx_ref;
      var txId = this.$route.query.transaction_id;

      TransactionService.verifyTransaction(
        {
          transaction_id: txId.toString(),
          transaction_ref: txRef,
          order: order,
        },
        (response) => {
          if (!response.error) {
            vm.$router.replace(
              `/dashboard/marketplace/paymentsconfirmed/${order}`,
            );
          }
        },
      );
    } else {
      this.$router.replace(`/dashboard/marketplace/paymentsfailed/${order}`);
    }
  },
};
</script>
<style lang="scss" scoped></style>
