<template>
  <div class="full-spec-wrapper">
    <div v-if="order" id="section-to-download">
      <div v-if="order" class="purchase-details">
        <img src="@/assets/images/logos/zowasel-grey-logo.png" alt="" />
        <p>
          Order Id: <span>{{ order.order_hash }}</span>
        </p>
      </div>
      <div class="purchase-details give-space">
        <div class="buyer-info">
          <h4>Buyer Info</h4>
          <p class="green-text">
            {{ order.buyer.first_name + " " + order.buyer.last_name }}
          </p>
          <p>{{ order.tracking_details.delivery_location }}</p>
        </div>
        <div class="seller-info">
          <h4>Seller Info</h4>
          <p>
            <span class="green-text">Zowasel</span> <br />
            3b Abba Sagoe Street, Otedola Estate <br />
            Omole Phase 2,. Lagos. Nigeria <br />
            Phone :01 342 4403 <br />
            Email:sales@zowasel.com
          </p>
        </div>
      </div>

      <hr />
      <table class="table table-SQS">
        <tbody>
          <tr>
            <th>Order Id</th>
            <th>Amount</th>
            <th>Quantity</th>
            <th>Total</th>
          </tr>
          <tr>
            <td>{{ order.order_hash }}</td>
            <td>{{ order.products[0].specification.price }}</td>
            <td>{{ order.products[0].specification.qty }}</td>
            <td>{{ order.total }}</td>
          </tr>
        </tbody>
      </table>

      <div v-if="order.payment_status == 'PARTIALLY_PAID'" class="partial">
        <div class="each-partial">
          <p>Advance Payment:</p>
          <p class="yellow-text">{{ order.amount_paid }}</p>
        </div>
        <div class="each-partial">
          <p>Balance Payment:</p>
          <p class="red-text">{{ order.amount_due }}</p>
        </div>
      </div>

      <div class="date">
        <div>
          <p>Date</p>
          <p class="green-text">{{ order.created_at }}</p>
        </div>
      </div>
    </div>

    <div class="position-relatives">
      <div class="btn-group w-100 gap-4">
        <div class="download">
          <a class="btn" type="button" @click="downloadOpt()">Download</a>
          <div v-if="downloadFile == true" class="download_container">
            <button
              href=""
              class="download_invoice as_image"
              @click="downloadAsImage()"
            >
              image
            </button>

            <button class="download_invoice as_pdf" @click="downloadAsPDF()">
              pdf
            </button>
          </div>
        </div>
        <div class="download">
          <a class="btn" type="button" @click="printSection()">Print</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MarketPlaceService from "@/services/marketplace";
export default {
  name: "PaymentReceipt",
  data() {
    return {
      order: null,
      downloadFile: false,
    };
  },
  computed: {
    specification() {
      return this.order.negotiation
        ? this.order.negotiation.specification
        : this.order.products[0].specification;
    },
  },
  mounted() {
    this.getOrder(this.$route.params.order);
  },
  methods: {
    getOrder(order) {
      MarketPlaceService.getOrder(order, (response) => {
        var ordered = response.data;
        ordered.products = JSON.parse(ordered.products);
        this.order = ordered;
        console.log(this.order);
      });
    },
    downloadOpt() {
      this.downloadFile = !this.downloadFile;
    },
    downloadAsImage() {
      const element = document.getElementById("section-to-download");
      // Create a canvas from the element
      html2canvas(element).then((canvas) => {
        // Convert canvas to image
        const imgData = canvas.toDataURL("image/png");
        // create a temporary link element and trigger download
        const link = document.createElement("a");
        link.href = imgData;
        link.download = "Specification.png";
        link.click();
      });
    },
    downloadAsPDF() {
      const element = document.getElementById("section-to-download");
      // Create a new instance of the html2pdf library
      const opt = {
        margin: 1,
        filename: "section.pdf",
        image: { type: "png", quality: 1 },
        html2canvas: { scale: 2 },
        jsPDF: { format: "a4", orientation: "portrait" },
      };

      html2pdf().set(opt).from(element).save();
    },
    printSection() {
      const sectionToPrint = document.getElementById(
        "section-to-download",
      ).innerHTML;
      const printWindow = window.open("", "_blank");
      printWindow.document.open();
      printWindow.document.write(`
        <html>
          <head>
            <title>Print</title>
          </head>
          <body>
            ${sectionToPrint}
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.onload = function () {
        printWindow.print();
        printWindow.onafterprint = function () {
          printWindow.close();
        };
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.purchase-details {
  display: flex;

  justify-content: space-between;
  img {
    width: 250px;
  }
  p {
    margin: 0px !important;
  }
}
.full-spec-wrapper {
  background: #ffffff;

  #section-to-download {
    padding: 30px;
  }
  #SQS {
    font-size: 15px;
    font-weight: 800;
    margin-bottom: 15px;
  }

  table {
    margin-top: 10px;
    font-size: 14px;
    #parameters {
      font-weight: 400;
    }
  }

  .btn-group {
    margin-block: 24px 73px;
    display: flex;
    a,
    button {
      width: 274px;
      height: 47px;
      padding: 10px;
      background: #2d3748;
      border: 0;
      color: white;
      &:hover {
        background: darken(#2d3748, 10%);
        color: white;
      }
    }
    .download_container {
      transition: display 3s;
      position: absolute;
      width: 274px;
      padding: 10px;
      background: white;
      top: -70px;
      display: flex;
      button {
        background: #05b050;
        flex: 1;
        text-align: center;
        &:hover {
          background: darken(#05b050, 10%);
        }
      }
    }
  }
}
.give-space {
  margin-top: 50px;
  margin-bottom: 70px;

  .seller-info {
    text-align: right;
  }
}
.green-text {
  color: #05b050;
}
.date {
  margin-top: 100px;
  display: flex;
  justify-content: flex-end;
}
.partial {
  margin-top: 50px;
}
.each-partial {
  display: flex;
  justify-content: space-between;

  .red-text {
    color: red;
  }
  .yellow-text {
    color: rgb(187, 187, 55);
  }
}
@media print {
  body * {
    display: none;
  }

  #section-to-download,
  #section-to-download * {
    display: block !important;
  }

  .download,
  .position-relatives,
  .btn-group {
    display: none !important;
  }
}
</style>
