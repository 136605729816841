<template>
  <DefaultNav>
    <div class="big-container">
      <!-- header -->
      <form @submit.prevent="saveData()">
        <div class="form">
          <div class="headings">
            <h2>Add Farmer(s)</h2>
            <div class="right-btns">
              <a href="/dashboard/farmer/view" class="yellow-link"
                >View Farmers</a
              >
              <a
                href="https://zowasel.com/batch_upload_format.csv"
                class="green-link"
                download="batch_upload_format.csv"
                >Batch Format</a
              >
            </div>
          </div>
          <div class="border bg-white">
            <div class="p-5">
              <div
                class="file-upload-wrapper mb-2"
                data-text="Select your file!"
              >
                <input type="file" class="file-upload-field" />
              </div>
              <button class="green-link">Upload</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import Preloader from "@/layouts/shared/Preloader.vue";
import FarmerService from "@/services/farmer";
import Alert from "@/utilities/alert";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "BatchFarmer",
  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      farmereditid: "",
      firstname: "",
      middlename: "",
      surname: "",
      telephone: "",
      email: "",
      gender: "",
      maritalstatus: "",
      language: "",
      landmark: "",
      permanentaddress: "",
      stateoforigin: "",
      dateofbirth: "",
      city: "",
      editing: false,
      editData: [],
    };
  },
  mounted() {
    const fileUploadField = document.querySelector(".file-upload-field");
    let fileUploadWrapper = document.querySelector(".file-upload-wrapper");
    fileUploadField.addEventListener("change", function () {
      fileUploadWrapper.setAttribute(
        "data-text",
        this.value.replace(/.*(\/|\\)/, ""),
      );
    });
  },
  methods: {
    async saveData() {
      this.isLoading = true;
      //Method to read csv file and convert it into JSON
      var files = document.querySelector(".file-upload-field").files;
      if (files.length == 0) {
        Alert.error({
          message: "Please upload a csv file",
        });
      }
      var filename = files[0].name;
      var extension = filename
        .substring(filename.lastIndexOf("."))
        .toUpperCase();
      if (extension == ".CSV") {
        this.csvFileToJSON(files[0]);
      } else {
        Alert.error({
          message: "Please select a valid csv file.",
        });
        /* --------------------------------- cflrd} --------------------------------- */
      }

      //Method to read csv file and convert it into JSON
    },

    async csvFileToJSON(file) {
      // console.log(socket);
      try {
        var reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = function (e) {
          var jsonData = [];
          var rows = e.target.result.split("\n");
          var headers = rows[0].split(",");
          for (let i = 1; i < rows.length; i++) {
            let data = rows[i].split(",");
            let obj = {};
            for (let j = 0; j < data.length; j++) {
              obj[headers[j]] = data[j];
            }
            jsonData.push(obj);
          }

          let usid = JSON.stringify(
            JSON.parse(localStorage.getItem("Zowasel")).user.user_id,
          );
          this.isLoading = true;
          let thisfarmerid;
          const convertedData = jsonData.map((item) => {
            thisfarmerid = `ZOWAFARMER-${uuidv4()}`;
            const fd = {
              farmerid: thisfarmerid,
              email: item["email"],
              firstname: item["firstname"],
              middlename: item["middlename"],
              lastname: item["lastname"],
              mobile: item["mobile"],
              gender: item["gender"],
              languagesspoken: item["languagesspoken"],
              dateofbirth: item["dateofbirth"],
              maritalstatus: item["maritalstatus"],
              country: item["country"],
              state: item["state"],
              city: item["city"],
              address: item["address"],
              landmark: item["landmark"],
              userid: usid,
            };

            const fb = {
              bankname: item["bankname"],
              bankaccountnumber: item["bankaccountnumber"],
              bankaccountname: item["accountname"],
            };

            const fkyf = {
              corporativemembershipstatus: item["corporativemembershipstatus"],
              corporatename: item["corporatename"],
              bvn: item["bvn"],
              meansofidentification: item["meansofidentification"],
              identificationno: item["identificationno"],
              issuedate: item["issuedate"],
              expirydate: item["expirydate"],
              nextofkin: {
                fullname: item["nextofkin.fullname"],
                occupationnok: item["nextofkin.occupationnok"],
                relationshipnok: item["nextofkin.relationshipnok"],
                telephonenok: item["nextofkin.telephonenok"],
              },
              yearsoffarmingexperience: item["yearsoffarmingexperience"],
              professionalfarmingtraining: item["professionalfarmingtraining"],
              levelofeducation: item["levelofeducation"],
            };

            const finf = {
              farmid: `USERFARM-${uuidv4()}`,
              farmlocation: item["farmlocation"],
              farmingtype: item["farmingtype"],
              farmsizesqm: item["farmsizesqm"],
              farmlocationcoordinates: {
                latitude: item["farmlocationcoordinates.latitude"],
                longitude: item["farmlocationcoordinates.longitude"],
              },
              farmownership: item["farmownership"],
              nameofowner: item["nameofowner"],
              farmownermobile: item["farmownermobile"],
              numberofyearsleased: item["numberofyearsleased"],
              croptypes: item["croptypes"],
              animaltypes: item["animaltypes"],
              numberofanimals: item["numberofanimals"],
              estimatedtotalincomeperyear: item["estimatedtotalincomeperyear"],
            };

            const fm = {
              farmmachineid: `FARMMACHINE-${uuidv4()}`,
              typeofmachine: item["typeofmachine"],
              noofthismachinepossessed: item["noofthismachinepossessed"],
              noofactiveunits: item["noofactiveunits"],
              noofdefectiveunits: item["noofdefectiveunits"],
              averagenoofyearsused: item["averagenoofyearsused"],
              averagecostofmaintenanceinnaira:
                item["averagecostofmaintenanceinnaira"],
            };

            const fp = {
              croprotationpractice: item["croprotationpractice"],
              numberofsqmyoucancultivate: item["numberofsqmyoucancultivate"],
              fertilizerappliedpercropcycle:
                item["fertilizerappliedpercropcycle"],
              totalfertilizerusedperseason:
                item["totalfertilizerusedperseason"],
              yourmostviablecrop: item["yourmostviablecrop"],
              totalaverageincomeperharvest:
                item["totalaverageincomeperharvest"],
              listoffungicidesused: item["listoffungicidesused"],
              weedcontrolpractices: item["weedcontrolpractices"],
              listfertilizersused: item["listfertilizersused"],
              intendedcrop: item["intendedcrop"],
            };

            const lh = {
              iftakenaloanbefore: item["iftakenaloanbefore"],
              pastloantakenamountnaira: item["pastloantakenamountnaira"],
              loantakenseason: item["loantakenseason"],
              hasitbeenrepaid: item["hasitbeenrepaid"],
              howdidyourepay: item["howdidyourepay"],
              whatiscostofcultivation: item["whatiscostofcultivation"],
              haddifficultypaying: item["haddifficultypaying"],
              costofcultivationperplantseason:
                item["costofcultivationperplantseason"],
            };

            const pt = {
              farmerid: thisfarmerid,
              intendedproject: item["intendedproject"],
              projectdescription: item["projectdescription"],
              estimatedcostinginnaira: item["estimatedcostinginnaira"],
            };

            delete item["accountname"];
            delete item["address"];
            delete item["animaltypes"];
            delete item["averagecostofmaintenanceinnaira"];
            delete item["averagenoofyearsused"];
            delete item["bankaccountnumber"];
            delete item["bankname"];
            delete item["bvn"];
            delete item["city"];
            delete item["corporatename"];
            delete item["corporativemembershipstatus"];
            delete item["costofcultivationperplantseason"];
            delete item["country"];
            delete item["croprotationpractice"];
            delete item["croptypes"];
            delete item["dateofbirth"];
            delete item["email"];
            delete item["estimatedcostinginnaira"];
            delete item["estimatedtotalincomeperyear"];
            delete item["expirydate"];
            delete item["farmingtype"];
            delete item["farmlocation"];
            delete item["farmownermobile"];
            delete item["farmownership"];
            delete item["farmsizesqm"];
            delete item["fertilizerappliedpercropcycle"];
            delete item["firstname"];
            delete item["gender"];
            delete item["haddifficultypaying"];
            delete item["hasitbeenrepaid"];
            delete item["identificationno"];
            delete item["howdidyourepay"];
            delete item["intendedcrop"];
            delete item["iftakenaloanbefore"];
            delete item["howdidyourepay"];
            delete item["intendedproject"];
            delete item["issuedate"];
            delete item["landmark"];
            delete item["languagesspoken"];
            delete item["lastname"];
            delete item["levelofeducation"];
            delete item["listfertilizersused"];
            delete item["listoffungicidesused"];
            delete item["loantakenseason"];
            delete item["maritalstatus"];
            delete item["meansofidentification"];
            delete item["middlename"];
            delete item["mobile"];
            delete item["nameofowner"];
            delete item["noofactiveunits"];
            delete item["noofdefectiveunits"];
            delete item["noofthismachinepossessed"];
            delete item["numberofanimals"];
            delete item["numberofsqmyoucancultivate"];
            delete item["numberofyearsleased"];
            delete item["pastloantakenamountnaira"];
            delete item["nextofkin.fullname"];
            delete item["nextofkin.occupationnok"];
            delete item["nextofkin.relationshipnok"];
            delete item["nextofkin.telephonenok"];
            delete item["farmlocationcoordinates.latitude"];
            delete item["farmlocationcoordinates.longitude"];
            delete item["farmerid"];
            delete item["farmid"];
            delete item["farmmachineid"];
            delete item["professionalfarmingtraining"];
            delete item["projectdescription"];
            delete item["state"];
            delete item["totalaverageincomeperharvest"];
            delete item["totalfertilizerusedperseason"];
            delete item["undefined"];
            delete item["weedcontrolpractices"];
            delete item["yourmostviablecrop"];
            delete item["typeofmachine"];
            delete item["whatiscostofcultivation"];

            return {
              farmer: fd,
              farmerbank: fb,
              farmerkyf: fkyf,
              farminformation: [finf],
              farmmechanization: [fm],
              farmpractices: fp,
              loanhistory: lh,
              pitch: pt,
              creditscore: {
                score: "null",
                grade: "null",
                recommendedamount: "null",
                reasonforrecommendation: "null",
                amountrequested: "null",
                averageincome: "null",
                installment: "null",
              },
            };
          });

          console.log(convertedData);

          for (let i = 1; i < convertedData.length; i++) {
            // console.log(i)
            for (const [key, val] of Object.entries(convertedData[i])) {
              if (val == "" || val == undefined) {
                convertedData[i][key] = "null";
              }
            }
          }
          // convertedData.pop();
          // console.log(JSON.stringify({ farmersdata: convertedData }));
          this.isLoading = true;
          console.log({ farmersdata: convertedData });
          FarmerService.batchFarmerCreate(
            { farmersdata: convertedData },
            (data) => {
              this.isLoading = false;
              /* ------------------- check if it returned with an error ------------------- */
              console.log(data);
              Alert.success({
                message: "Farmer data received and is being processed",
              });
              // if (data[0].error == false) {
              setTimeout(() => {
                this.isLoading = false;
                window.location.href = "/dashboard/farmer/view";
              }, 2000);
              // } else {
              //   Alert.error({
              //     message: data[1].message,
              //   });
              // }
            },
          );
          // // FarmerService.getAllFarmerss((response) => {
          // //   console.log(response);
          // });
          // FarmerService.getFarmerid(farmerid, (response) => {
          //   console.log(response);
          // });
          // socket.emit(
          //   "farmerbatchresponse",
          //   JSON.stringify({ farmer: convertedData })
          // );
        };
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.big-container {
  width: 100%;
  // height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 4% 3% 0 3%;

  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}

.active-subscriptions {
  background-color: white;
  padding: 24px 65px 50px;
  margin-top: 40px;

  h2 {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #696671;
  }
}

/* The slider */
.sub-history {
  margin-top: 50px;
  width: 100%;
  margin-left: 10px;

  h2 {
    margin-bottom: 40px;
  }

  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #4a4754;
  }
}

.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 70px;

  a {
    text-decoration: none;
  }

  .right-btns {
    display: flex;
    gap: 20px;
  }

  .green-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 145px;
    height: 40px;
    background: #05b050;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
  }
}

.green-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 145px;
  height: 40px;
  background: #05b050;
  border-radius: 5px;
  letter-spacing: 0.02em;
}

.yellow-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  width: 145px;
  height: 40px;
  background: #fab900 !important;
  border-radius: 5px;
  letter-spacing: 0.02em;
}

a {
  text-decoration: none;
}

.small-font {
  font-size: 11px !important;
}
.file-upload-wrapper {
  position: relative;
  width: 100%;
  height: 60px;
}
.file-upload-wrapper:after {
  content: attr(data-text);
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 0;
  background: #999;
  padding: 0 15px;
  display: block;
  width: calc(100% - 40px);
  pointer-events: none;
  z-index: 20;
  height: 40px;
  line-height: 40px;
  color: #fff;
  border-radius: 5px 10px 10px 5px;
  font-weight: 300;
}
.file-upload-wrapper:before {
  content: "Upload";
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  height: 40px;
  background: #4daf7c;
  color: #fff;
  font-weight: 700;
  z-index: 25;
  font-size: 14px;
  line-height: 40px;
  padding: 0 15px;
  text-transform: uppercase;
  pointer-events: none;
  border-radius: 0 5px 5px 0;
}
.file-upload-wrapper:hover:before {
  background: #3d8c63;
}
.file-upload-wrapper input {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  height: 40px;
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  width: 100%;
}
</style>
