<template>
  <div class="right-container">
    <div v-if="order" class="left-container-wrapper">
      <!-- order summary -->
      <h2>Order Summary</h2>
      <hr />
      <table class="table">
        <tr>
          <th>Delivery Address</th>
          <td>{{ parse(order.tracking_details).delivery_location }}</td>
        </tr>
        <tr>
          <th>Amount</th>
          <td>{{ order.currency }} {{ order.total }}</td>
        </tr>
        <!-- <tr>
          <th>VAT</th>
          <td>NGN20.00</td>
        </tr> -->
      </table>
      <hr />
      <table class="table">
        <tr>
          <th>Total Cost</th>
          <td>{{ order.currency }} {{ order.total }}</td>
        </tr>
        <tr v-if="paymentPercent">
          <th>Partial Payment</th>
          <td>
            {{ order.currency }} {{ (paymentPercent / 100) * order.total }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderSummary",
  props: {
    order: Object,
  },
  computed: {
    paymentPercent() {
      return window.localStorage.paymentPercent &&
        window.localStorage.paymentPercent != "null"
        ? eval(window.localStorage.paymentPercent)
        : null;
    },
  },
  methods: {
    parse(data) {
      return JSON.parse(data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.right-container {
  background: #f5f5f5;
  width: 50%;

  .left-container-wrapper {
    margin-inline: 13.19% 9.16%;
    margin-top: 65px;

    h2 {
      @include textStyles(Inter, 600, 20px, 28px);
      letter-spacing: -0.02em;
      color: #0a0d13;
    }

    hr {
      border: 1px solid #acacac;
    }

    > table:nth-of-type(1) {
      tr {
        height: 65px;
      }

      th {
        color: rgba(45, 55, 72, 0.6);
        @include textStyles(Poppins, 800, 14px, 27px);
        text-align: left;
      }

      td {
        color: #696671;
        @include textStyles("Maven Pro", 700, 14px, 24px);
        text-align: right;
      }
    }

    > table:nth-of-type(2) {
      th {
        @include textStyles(Inter, 500, 14px, 12px);
        color: #0a0d13;
        text-align: left;
      }

      td {
        @include textStyles(Poppins, 600, 20px, 53px);
        color: rgba(45, 55, 72, 0.6);
        text-align: right;
        transform: translateY(-25px);
      }
    }
  }
}
</style>
