<template>
  <div class="big-container">
    <div class="left-content">
      <h2 class="beta2">BETA 1.0</h2>
      <h1 class="text-center">Reset Password</h1>
      <p id="emailHelp" class="form-text text-muted text-center">
        Choose a new password for your account
      </p>

      <form>
        <div class="form-group password">
          <label for="">New Password</label>
          <input
            id="formGroupExampleInput"
            v-model="password"
            :type="passwordFieldType.newPassword"
            class="form-control"
            placeholder="Enter new password"
          />
          <!-- New password input -->
          <div class="eye_slash" @click="toggleVisibility('newPassword')">
            <i
              v-if="passwordFieldType.newPassword === 'password'"
              class="bi bi-eye"
            ></i>
            <i
              v-if="passwordFieldType.newPassword === 'text'"
              class="bi bi-eye-slash"
            ></i>
          </div>
        </div>
        <div class="form-group password">
          <label for="">Password</label>
          <input
            id="formGroupExampleInput2"
            v-model="confirmPassword"
            :type="passwordFieldType.password"
            class="form-control"
            placeholder="Confirm your new password"
          />
          <!-- New password input -->
          <div class="eye_slash" @click="toggleVisibility('password')">
            <i
              v-if="passwordFieldType.password === 'password'"
              class="bi bi-eye"
            ></i>
            <i
              v-if="passwordFieldType.password === 'text'"
              class="bi bi-eye-slash"
            ></i>
          </div>
        </div>

        <div class="buttons">
          <div class="form-group">
            <button
              type="button"
              class="btn btn-lg btn-block btn-hover-state"
              @click="resetPassword()"
            >
              Reset Password
            </button>
          </div>

          <div class="form-group">
            <button
              type="button"
              class="btn skip btn-lg btn-block blue-btn-state"
              @click="$router.replace('/login')"
            >
              Back to login
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="right-content">
      <img src="@/assets/images/backgrounds/right-side.png" alt="" />
    </div>
  </div>
  <Preloader v-if="isLoading" />
</template>

<script>
import "bootstrap-icons/font/bootstrap-icons.css";
import AuthService from "@/services/auth";
import Preloader from "@/layouts/shared/Preloader.vue";
import Alert from "@/utilities/alert";

export default {
  name: "ResetPassword",
  components: {
    Preloader,
  },
  data() {
    return {
      password: "",
      confirmPassword: "",
      token: this.$route.params.token,
      passwordFieldType: {
        newPassword: "password",
        password: "password",
      },
    };
  },
  computed: {
    hasNumbers() {
      return /[0-9]+/.test(this.password);
    },
    hasSpecial() {
      return /[!@~#$%^.,'"&*\(\)\/]+/.test(this.password);
    },
    hasUppercase() {
      return /[A-Z]+/.test(this.password);
    },
    characterMin() {
      return this.password.length >= 8;
    },
    mustMatch() {
      return this.password == this.confirmPassword && this.password;
    },
    strength() {
      var strength = 0;
      if (this.characterMin) {
        strength += 1;
      }
      if (this.hasNumbers) {
        strength += 1;
      }
      if (this.hasUppercase) {
        strength += 1;
      }
      if (this.hasSpecial) {
        strength += 1;
      }
      return strength;
    },
  },
  mounted() {
    let vm = this;
    AuthService.verifyResetToken(this.token, (response) => {
      if (!response.status) {
        vm.$router.replace("/login");
      }
    });
  },
  methods: {
    toggleVisibility(inputType) {
      if (inputType == "password") {
        this.passwordFieldType.password =
          this.passwordFieldType.password === "password" ? "text" : "password";
      } else if (inputType == "newPassword") {
        this.passwordFieldType.newPassword =
          this.passwordFieldType.newPassword === "password"
            ? "text"
            : "password";
      }
    },
    resetPassword() {
      let vm = this;
      if (this.password == this.confirmPassword) {
        this.isLoading = true;
        AuthService.resetPassword(
          {
            token: vm.token,
            password: vm.password,
          },
          (response) => {
            if (response.status) {
              vm.isLoading = false;
              vm.$router.push("/resetsuccess");
            } else {
              vm.isLoading = false;
              Alert.error({ message: "Could not reset password, try again" });
            }
          },
        );
      } else {
        Alert.error({ message: "Passwords do not match" });
      }
    },
  },
};
</script>

<style scoped>
.big-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.left-content {
  width: 65%;
  height: 100%;
  padding: 10% 15% 0% 15%;

  box-sizing: border-box;
}
.lines {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px 0px 20px 0px;
}
.line {
  border: 1px solid #b0b0b0;
  width: 30%;
  margin: -15px 10px 0px 5px;
}
input {
  padding: 10px;
}

.buttons {
  margin-top: 50px;
}
button {
  background-color: #008d40;
  width: 100%;
  color: white;
}
.skip {
  background: #2d3748;
}
.form-group {
  margin-bottom: 15px;
}

.right-content {
  width: 35%;
  height: 100vh;
}
.right-content img {
  width: 100%;
  height: 100%;
}

.last-text {
  margin-top: 70px;
}
.password {
  position: relative;
}
.eye_slash {
  position: absolute;
  right: calc(0px + 30px);
  bottom: 10px;
  cursor: pointer;
}
</style>
