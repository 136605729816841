<template>
  <!-- Modal -->
  <div
    id="exampleModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 id="exampleModalLabel" class="modal-title fs-5">
            Change password
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <!-- form -->
        <form @submit.prevent="save()">
          <div class="modal-body">
            <div class="w-100 mb-3 password">
              <label for="formGroupExampleInput" class="form-label mb-0"
                >Current Password</label
              >
              <span id="required">*</span>
              <input
                id="formGroupExampleInput"
                v-model="password.current_password"
                class="form-control"
                :type="passwordFieldType.currentPassword"
                required
              />
              <!-- Password input -->
              <div
                class="eye_slash"
                @click="toggleVisibility('currentPassword')"
              >
                <i
                  v-if="passwordFieldType.currentPassword === 'password'"
                  class="bi bi-eye"
                ></i>
                <i
                  v-if="passwordFieldType.currentPassword === 'text'"
                  class="bi bi-eye-slash"
                ></i>
              </div>
            </div>
            <div class="w-100 mb-3 password">
              <label for="formGroupExampleInput" class="form-label mb-0"
                >New Password</label
              >
              <span id="required">*</span>
              <input
                id="formGroupExampleInput"
                v-model="password.new_password"
                :type="passwordFieldType.newPassword"
                class="form-control"
                required
              />
              <!-- Password input -->
              <div class="eye_slash" @click="toggleVisibility('newPassword')">
                <i
                  v-if="passwordFieldType.newPassword === 'password'"
                  class="bi bi-eye"
                ></i>
                <i
                  v-if="passwordFieldType.newPassword === 'text'"
                  class="bi bi-eye-slash"
                ></i>
              </div>
            </div>
            <div class="w-100 mb-3 password">
              <label for="formGroupExampleInput" class="form-label mb-0"
                >Confirm New Password</label
              >
              <span id="required">*</span>
              <input
                id="formGroupExampleInput"
                v-model="password.confirm_password"
                :type="passwordFieldType.confirmPassword"
                class="form-control"
                required
              />
              <!-- Password input -->
              <div
                class="eye_slash"
                @click="toggleVisibility('confirmPassword')"
              >
                <i
                  v-if="passwordFieldType.confirmPassword === 'password'"
                  class="bi bi-eye"
                ></i>
                <i
                  v-if="passwordFieldType.confirmPassword === 'text'"
                  class="bi bi-eye-slash"
                ></i>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn">Save changes</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <Preloader v-if="isLoading" />
</template>

<script>
import "bootstrap-icons/font/bootstrap-icons.css";

import Alert from "@/utilities/alert";
// importing the auth service
import AuthService from "@/services/auth";
// import preloader
import Preloader from "@/layouts/shared/Preloader.vue";
export default {
  name: "ChangePassword",
  components: {
    Preloader,
  },
  data() {
    return {
      password: {
        current_password: "",
        new_password: "",
        confirm_password: "",
      },
      passwordFieldType: {
        currentPassword: "password",
        newPassword: "password",
        confirmPassword: "password",
      },
      isLoading: false,
    };
  },
  methods: {
    toggleVisibility(inputType) {
      if (inputType == "currentPassword") {
        this.passwordFieldType.currentPassword =
          this.passwordFieldType.currentPassword === "password"
            ? "text"
            : "password";
      } else if (inputType == "newPassword") {
        this.passwordFieldType.newPassword =
          this.passwordFieldType.newPassword === "password"
            ? "text"
            : "password";
      } else if (inputType == "confirmPassword") {
        this.passwordFieldType.confirmPassword =
          this.passwordFieldType.confirmPassword === "password"
            ? "text"
            : "password";
      }
    },
    save() {
      if (this.password.new_password === this.password.confirm_password) {
        // send data to the end-point
        this.isLoading = true;
        AuthService.changePassword(this.password, (response) => {
          if (response && response.error === false) {
            Alert.success({
              message: "Password changed successfully",
            });
            setTimeout(() => {
              // reload the page
              window.location.reload();
            }, 2000);
          } else {
            // set the preloader to false
            this.isLoading = false;
            Alert.error({
              message: "error please try again later",
            });
          }
          // set the preloader to false
          this.isLoading = false;
        });
      } else {
        Alert.error({
          message: "Password Do Not Match",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.modal-footer {
  justify-content: center;
  button {
    color: white;
    background: #008d40;
    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      border-color: #05b050 !important;
      box-shadow: 0 0 !important;
      background: #05b050 !important;
    }
  }
}
// label
div.mb-3 label {
  margin-bottom: 0px;
  @include textStyles(Poppins, 300, 14px, 166.67%);
  color: rgba(45, 55, 72, 0.8);
}
// input, select and textarea states
select,
input {
  font-size: 14px;
  color: #000000;

  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    border-color: #05b050 !important;
    box-shadow: 0 0 !important;
  }
}
#required {
  color: red;
  font-family: "Maven Pro";
  font-weight: bolder;
  font-size: 20px;
}
.password {
  position: relative;
}
.eye_slash {
  position: absolute;
  right: calc(0px + 30px);
  bottom: 10px;
  cursor: pointer;
}
</style>
