<template>
  <DefaultNav>
    <div class="big-container">
      <div class="headings">
        <div class="left-side">
          <h2>
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEwAAABACAYAAACwVZFQAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIvSURBVHgB7dzLccIwEIDhdcY5JQdKoQTTgSkhJaWClIA7wCVQCufAjKMVtsPLtiT0WEn7n8iQA3xjwI+1ADitCghYuasr8QLWHcBK/Nmet00LxAsDtqtX7wXsxKMKbl9M89vBF2ybIxCtBN9dsPbi0fr+KbGl1eK51QlgA0R7A5/NYF1V4UcViOYPTA1LVij8T6j8gGlgYf2PAMncg2li9bVANLdgBlhi62oo7164AzPbsg6fuFtBODf7YYZYHx1sjoT3wTD7YAljYXbBEsfC7IFlgIXZAcsEC3sdLCMs7DWwzLAwc7AMsTAzsEyxMH2wjLEwPbDMsTB1MMaSqYEx1tgyGGPdNA/GWA9NgzHW056DMdZkj2CMNdstmBkWnoj/FufiU8Yaxxj+wUyxMmkYYxhHBcoCfoCxJhvGGOQWJqdoLlsXt9Bwma0CTikJVqT9hW01CSY+nwfglBp/Jcum3hf80ZwNxxjGUYFzB1vgLW0ygdXiGAPvuC6E3+/4lfW44zpkeGh0EodGlGdTbWX14DsHNOund1JHc3ICMWU0Z6eoU0VzehEkRTTnl9lSQ/NyITclNG+jAqmgeR1GSQHN+7hT7GhBBupiRgs2shkrWtCh4BjRgo+dx4ZG4saGmNDI3DoTCxqpm7NiQHO3qkCiaO7ulxRvWr55vQsr6355BrK5vSPXDC3zVQUM0HhVAU20vFcVGNJDa4FofldGUUDLd1WBqXo0hLl/argcD4T7A4yVhVYmnleGAAAAAElFTkSuQmCC"
              alt=""
              @click="goback()"
            />
            Loan Request History
          </h2>
        </div>
      </div>

      <div class="active-subscriptions">
        <table class="table-borderless sub-history">
          <thead>
            <tr>
              <th class="theader">Account Number</th>
              <th class="theader">Duration (Months)</th>
              <th class="theader">Amount</th>
              <th class="theader">Date Disbursement</th>
            </tr>
          </thead>
          <tbody class="scrollable">
            <div v-if="loanRequests.length > 0">
              <template v-for="(data, index) in loanRequests" :key="index">
                <tr>
                  <td>
                    {{ data.account_number }}
                  </td>
                  <td>{{ data.duration }}</td>
                  <td>{{ data.amount }}</td>
                  <td>{{ data.created_at.split("T")[0] }}</td>
                </tr>
              </template>
            </div>
            <div v-else>
              {{ loanRequests }}
              <tr>
                <td colspan="4">
                  <div class="text-center">
                    <strong> No loan(s) found </strong>
                  </div>
                </td>
              </tr>
            </div>
          </tbody>
          <!-- pagination -->
          <nav class="d-flex justify-content-center my-4">
            <div id="pagination"></div>
          </nav>
        </table>
      </div>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import Preloader from "@/layouts/shared/Preloader.vue";
import LoanService from "@/services/loan";
export default {
  name: "LoanHistory",
  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      loanRequests: [],
      isLoading: false,
      currentuserid: JSON.parse(localStorage.getItem("Zowasel")).user.user.type,
    };
  },
  mounted() {
    // getAllFarmers() {
    this.isLoading = true;
    if (this.currentuserid != "partner") {
      const uid = localStorage.getItem("farmerID");
      LoanService.getVloanhistory(uid, (response) => {
        // console.log(response);
        if (response.error == false) {
          this.loanRequests = response.data;
          this.paginate(response.data.reverse(), "loanRequests");
          this.isLoading = false;
        } else {
          this.loanRequests = [];
          this.isLoading = false;
        }
      });
    } else {
      LoanService.getAllLoanRequests((response) => {
        if (response.error == false) {
          console.log(response.data);
          this.loanRequests = response.data;
          this.paginate(this.loanRequests.reverse(), "loanRequests");
          this.isLoading = false;
        } else {
          this.loanRequests = [];
          this.isLoading = false;
        }
      });
    }
  },
  methods: {
    goback() {
      history.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 2% 5% 2% 3%;
  overflow-y: scroll;
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}
.active-subscriptions {
  background-color: white;
  padding: 24px 35px;
}

/* The slider */

.sub-history {
  margin-top: 50px;
  width: 100%;
  margin-left: 10px;

  h2 {
    margin-bottom: 40px;
  }
  thead {
    font-weight: bold;
  }
  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #4a4754;
  }

  tr {
    display: flex;
    flex-direction: row;
    padding: 20px 9px;
    height: 70px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  }
  td {
    flex: 1;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4754;

    a {
      color: #fab900;
      text-decoration: underline;
    }
  }

  a {
    text-decoration: none;
  }
  a:hover td {
    color: #05b050 !important;
  }
}
.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  a {
    text-decoration: none;
  }
  .right-btns {
    display: flex;
    gap: 20px;
  }
  .green-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 180px;
    height: 40px;
    background: #05b050;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    border-radius: 4px;
  }
  .white-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 40px;
    background: #ffffff;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #05b050;
    border: 2px solid #05b050;
    border-radius: 4px;
  }
  .red-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 180px;
    height: 40px;
    background: #cd4647;
    box-shadow:
      0px 2px 4px rgba(44, 39, 56, 0.08),
      0px 4px 8px rgba(44, 39, 56, 0.08);
    border-radius: 4px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    border-radius: 4px;
  }
}
.date-region {
  margin-top: 30px;
  margin-bottom: 30px;
}
.red-text {
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: #cd4647;
}
.loan-details {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 35px;
}
.left-details {
  width: 50%;
  border-right: 1px solid #4a4754;
  padding-right: 50px;

  .each-detail {
    display: flex;
    justify-content: space-between;

    h4 {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #4a4754;
    }
    p {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #4a4754;
    }
  }
}
.right-details {
  width: 50%;
  padding-left: 50px;

  h3 {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    color: #05b050;
    margin-top: 40px;
    margin-bottom: 15px;
  }

  .each-detail {
    display: flex;
    justify-content: space-between;

    h4 {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #4a4754;
    }
    p {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #4a4754;
    }
  }
}
.colored-green {
  display: flex;
  height: 35px;
  width: 120px;
  align-items: center;
  justify-content: center;
  background: #e6f7ee;
  border-radius: 40px;

  .green-dot {
    width: 10px;
    height: 10px;
    background-color: #05b050;
    border-radius: 50%;
  }

  p {
    color: #05b050;
    margin-top: 15px;
    margin-left: 5px;
  }
}

.colored-yellow {
  display: flex;
  height: 35px;
  width: 120px;
  justify-content: center;
  align-items: center;
  background: #fff8e6;
  border-radius: 40px;

  .yellow-dot {
    width: 10px;
    height: 10px;
    background-color: #fab900;
    border-radius: 50%;
  }

  p {
    color: #fab900;
    margin-top: 15px;
    margin-left: 5px;
  }
}

.headings2 {
  display: flex;
  margin-top: 60px;
  justify-content: space-between;

  h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #4a4754;
  }

  a {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #05b050;
    text-decoration: none;
  }
}
.left-side {
  display: flex;
  gap: 10px;

  img {
    width: 22px;
    height: 18px;
  }
}
.well {
  color: #fab900;
  background: #f5f5f5;
  padding: 9px 18px;
  font-weight: bold;
  display: inline-block;
  font-size: 18px;
  border-radius: 10px;
}

a {
  text-decoration: none !important;
}
</style>
