import axios from "axios";

import config from "@/config";

const GET_USER_PATH = () => `user`;

export default {
  getUser: function (id, callback) {
    axios
      .get(config.BASE_URL + GET_USER_PATH())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        if ((error.statuse = 403)) {
          callback({ error: true });
        }
      });
  },
};
// avoid dupliccate actions token
