<template>
  <DefaultNav>
    <div class="big-container">
      <div class="headings">
        <h2>Team Members</h2>
        <div class="right-btns">
          <a href="/dashboard/addmember" class="green-link">Add Member</a>
        </div>
      </div>
      <input type="text" placeholder="search" />

      <!-- <div class="row date-region">
        <div class="col">
          <label for="">Date Paid</label>
          <input type="date" class="form-control" placeholder="Date Paid" />
        </div>
        <div class="col">
          <label for="">Date Closed</label>
          <input type="date" class="form-control" placeholder="Date Closed" />
        </div>
        <div class="col">
          <label for="">Select Status</label>
          <select class="form-control">
            <option>Status</option>
            <option>...</option>
          </select>
        </div>
      </div> -->

      <div class="active-subscriptions">
        <table class="table-borderless sub-history">
          <tr>
            <td class="theader">First Name</td>
            <td class="theader">Last Name</td>
            <td class="theader">Email</td>
            <!-- <td class="theader">Phone Number</td> -->
            <td class="theader">Action</td>
          </tr>

          <tbody class="scrollable">
            <tr v-for="member in team" :key="member.id">
              <td>{{ member.first_name }}</td>
              <td>{{ member.last_name }}</td>
              <td>{{ member.email }}</td>
              <!-- <td>{{ member.phone }}</td> -->
              <td class="action-btns">
                <!-- <button class="edit">Edit</button> -->
                <button class="delete" @click="deleteTeamMember(member)">
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </DefaultNav>
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import MarketPlaceService from "@/services/marketplace";
import Alert from "@/utilities/alert";
export default {
  name: "Team",
  components: {
    DefaultNav,
  },
  data() {
    return {
      userData: this.$store.state.user,
      team: [],
    };
  },
  mounted() {
    this.getTeam();
  },
  methods: {
    getTeam(team) {
      MarketPlaceService.getTeam((response) => {
        if (response && response.error == false) {
          this.team = response.data;
        }
      });
    },
    deleteTeamMember(member) {
      MarketPlaceService.deleteTeamMember(member.id, (response) => {
        if (response && response.error == false) {
          Alert.success({
            message: "Team Member Deleted successfully",
          });
        } else if (response && response.error) {
          Alert.error({
            message: response.message,
          });
          this.team = response.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 2% 5% 2% 3%;

  overflow-y: scroll;
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}
.active-subscriptions {
  background-color: white;
  padding: 24px 35px;
  margin-top: 40px;
}

/* The slider */

.sub-history {
  margin-top: 50px;
  width: 100%;

  h2 {
    margin-bottom: 40px;
  }
  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #4a4754;
  }

  tr {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 66px;
  }
  .go-big {
    flex: 2;
  }
  td {
    flex: 1;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4754;
  }
  .active {
    width: 100px;
    height: 26px;
    background: #dcfce7;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .active-dot {
      width: 7.52px;
      height: 8px;
      background: #047b38;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #047b38;
    }
  }
  .expired {
    width: 100px;
    height: 26px;
    background: #fff8e6;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .expired-dot {
      width: 7.52px;
      height: 8px;
      background: #fab900;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #fab900;
    }
  }
}
.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  a {
    text-decoration: none;
  }
  .right-btns {
    display: flex;
    gap: 20px;
  }
  .green-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 180px;
    height: 40px;
    background: #05b050;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    text-decoration: none;
  }
}
.action-btns {
  display: flex;
  gap: 20px;

  .edit {
    padding: 5px 10px;
    background: white;
    box-shadow:
      0px 2px 4px rgba(44, 39, 56, 0.08),
      0px 4px 8px rgba(44, 39, 56, 0.08);
    border-radius: 4px;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 147%;
    color: #05b050;
    border: 1px solid #05b050;
  }
  .delete {
    background: #f9e9e9;
    border: 1px solid #c32021;
    border-radius: 4px;
    padding: 5px 10px;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #c32021;
  }
}
</style>
