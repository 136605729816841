<template>
  <DefaultNav>
    <div class="big-container">
      <div class="headings">
        <h2>Score Point System</h2>
        <!-- <div class="right-btns">
          <a href="" class="white-link">Add a Farmer</a>
          <a href="" class="green-link">Batch Upload</a>
          <a href="" class="red-link">Sent Invoice</a>
        </div> -->
      </div>
      <div class="row big-contents">
        <div class="col">
          <div class="card carded blue-card">
            <h2>No of Farmers</h2>
            <div class="texts">
              <p>Weight: 1</p>
              <p>3.7/100</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card carded red-card">
            <h2>Farm Ownership</h2>
            <div class="texts">
              <p>Weight: 4</p>
              <p>14.8/100</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card carded yellow-card">
            <h2>Farm Size</h2>
            <div class="texts">
              <p>Weight: 4</p>
              <p>14.8/100</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card carded green-card">
            <h2>Animal farming</h2>
            <div class="texts">
              <p>Weight: 4</p>
              <p>14.8/100</p>
            </div>
          </div>
        </div>
      </div>

      <div class="row big-contents">
        <div class="col">
          <div class="card carded blue-card">
            <h2>Number of animals</h2>
            <div class="texts">
              <p>Weight: 4</p>
              <p>14.8/100</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card carded red-card">
            <h2>Estimated Income Per Year ()</h2>
            <div class="texts">
              <p>Weight: 4</p>
              <p>14.8/100</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card carded yellow-card">
            <h2>Farm Address</h2>
            <div class="texts">
              <p>Weight: 4</p>
              <p>14.8/100</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card carded green-card">
            <h2>Age ofarm</h2>
            <div class="texts">
              <p>Weight: 4</p>
              <p>14.8/100</p>
            </div>
          </div>
        </div>
      </div>

      <div class="big-contents row">
        <div class="col">
          <div class="card carded red-card">
            <h2>Crop Type</h2>
            <div class="texts">
              <p>Weight: 4</p>
              <p>14.8/100</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card carded yellow-card">
            <h2>Farm ownership status</h2>
            <div class="texts">
              <p>Weight: 4</p>
              <p>14.8/100</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card carded blue-card">
            <h2>Number of crops grown</h2>
            <div class="texts">
              <p>Weight: 4</p>
              <p>14.8/100</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card carded yellow-card">
            <h2>Cropping Practice</h2>
            <div class="texts">
              <p>Weight: 4</p>
              <p>14.8/100</p>
            </div>
          </div>
        </div>
      </div>
      <div class="big-contents row">
        <div class="col">
          <div class="card carded green-card">
            <h2>Machinery Status</h2>
            <div class="texts">
              <p>Weight: 4</p>
              <p>14.8/100</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card carded blue-card">
            <h2>Monthly Revenue</h2>
            <div class="texts">
              <p>Weight: 1</p>
              <p>14.8/100</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card carded red-card">
            <h2>Cooporate Membership</h2>
            <div class="texts">
              <p>Weight: 4</p>
              <p>14.8/100</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card carded red-card">
            <h2>Inspection Status</h2>
            <div class="texts">
              <p>Weight: 4</p>
              <p>14.8/100</p>
            </div>
          </div>
        </div>
      </div>

      <div class="rating-section">
        <h3>Credit Score Rating</h3>
        <hr />

        <div class="first-row">
          <div class="score">
            <div class="top-text">
              <div class="left-text">
                <div class="dot"></div>
                <h2>Score A</h2>
              </div>
              <p>70%</p>
            </div>
            <input
              type="range"
              min="0"
              max="70"
              value="15"
              class="green-range"
            />
          </div>

          <div class="score">
            <div class="top-text">
              <div class="left-text">
                <div class="dot"></div>
                <h2>Score B</h2>
              </div>
              <p>60%</p>
            </div>
            <input
              type="range"
              min="0"
              max="6"
              value="6"
              class="yellow-range"
            />
          </div>
        </div>

        <div class="second-row">
          <div class="score">
            <div class="top-text">
              <div class="left-text">
                <div class="dot"></div>
                <h2>Score C</h2>
              </div>
              <p>50%</p>
            </div>
            <input
              type="range"
              min="0"
              max="50"
              value="40"
              class="blue-range"
            />
          </div>

          <div class="score">
            <div class="top-text">
              <div class="left-text">
                <div class="dot"></div>
                <h2>Score D</h2>
              </div>
              <p>40%</p>
            </div>
            <input type="range" min="0" max="40" value="35" class="red-range" />
          </div>
        </div>
      </div>
      -->
    </div>
  </DefaultNav>
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";

export default {
  name: "CreditScoring",
  components: {
    DefaultNav,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 1% 5% 2% 5%;
  overflow-y: scroll;
}

.carded {
  width: 100%;
  display: flex;
  height: 120px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 18px 20px 15px;
  margin-top: 28px;

  p {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #96949c;
  }

  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 147%;
    text-transform: capitalize;
    color: #4a4754;
  }
}
.yellow-card {
  border-left: 2px solid #fde296;
}
.green-card {
  border-left: 2px solid #99dfb7;
}
.blue-card {
  border-left: 2px solid #96c0ff;
}
.red-card {
  border-left: 2px solid #e6a4a4;
}
.texts {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.big-contents {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: left;
  gap: 30px;
}
.rating-section {
  padding: 30px 62px;
  background-color: white;
  margin-top: 50px;
  width: 100%;
}
.headings {
  margin-top: 40px;
}

.first-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 50px;

  .score {
    width: 50%;
    display: flex;
    flex-direction: column;

    .top-text {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    h2 {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      display: flex;
      align-items: center;
      letter-spacing: 0.04em;
      color: #151522;
    }

    p {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 130%;
      align-items: center;
      text-align: right;
      color: #151522;
    }
  }
}

.second-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  margin-top: 40px;

  .score {
    width: 50%;
    display: flex;
    flex-direction: column;

    .top-text {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    h2 {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      display: flex;
      align-items: center;
      letter-spacing: 0.04em;
      color: #151522;
    }

    p {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 130%;
      align-items: center;
      text-align: right;
      color: #151522;
    }
  }
  input[type="range"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    cursor: pointer;
    accent-color: #2ecc71;
    filter: hue-rotate(180deg);
  }

  /*Chrome thumb*/

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #e7e7e7;
    border: 1px solid #c5c5c5;
  }

  /*Mozilla thumb*/

  input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    -moz-border-radius: 5px;
    height: 14px;
    width: 14px;
    border-radius: 5px;
    background: #e7e7e7;
    border: 1px solid #c5c5c5;
  }

  /*IE & Edge input*/

  input[type="range"]::-ms-track {
    width: 300px;
    height: 6px;
    /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
    background: transparent;
    /*leave room for the larger thumb to overflow with a transparent border */
    border-color: transparent;
    border-width: 2px 0;
    /*remove default tick marks*/
    color: transparent;
  }

  /*IE & Edge thumb*/

  input[type="range"]::-ms-thumb {
    height: 14px;
    width: 14px;
    border-radius: 5px;
    background: #e7e7e7;
    border: 1px solid #c5c5c5;
  }

  /*IE & Edge left side*/
  input[type="range"]::-ms-fill-lower {
    background: #919e4b;
    border-radius: 2px;
  }

  /*IE & Edge right side*/

  input[type="range"]::-ms-fill-upper {
    background: #c5c5c5;
    border-radius: 2px;
  }

  /*IE disable tooltip*/

  input[type="range"]::-ms-tooltip {
    display: none;
  }

  input[type="text"] {
    border: none;
  }
}
</style>
