<template>
  <!-- top navigation -->
  <nav class="navbar bg-white">
    <div id="nav-container" class="container-fluid">
      <a id="zowasel-nav-brand" class="navbar-brand" href="#">
        <div class="logo-background" />
      </a>
      <h2 class="beta">Beta 1.0</h2>
      <div
        class="search-barr d-flex flex-row justify-content-center align-items-center"
      >
        <div id="search-icon">
          <img
            class="img-fluid"
            src="@/assets/images/vectors/search-icon.svg"
          />
        </div>
        <input
          id="seachID"
          type="search"
          class="form-control"
          aria-describedby="passwordHelpBlock"
          placeholder="Type to search"
        />
      </div>

      <div
        class="navbar-icons d-flex flex-row justify-content-center align-items-center"
      >
        <!-- notification bell -->
        <div class="bell position-relative">
          <img
            class="img-fluid"
            src="@/assets/images/vectors/bell-icon.svg"
            alt="bell"
            @click="toggleNotify"
          />
          <!-- notification dot -->
          <div v-if="newNotification" class="notification_dot"></div>
          <!-- notification card -->
          <div v-if="notify" class="notification_container">
            <i id="caret" class="bi bi-caret-up-fill"></i>
            <div class="notification_header">Notifications</div>
            <div class="notification_body_wrapper">
              <!-- <div class="notification_body">
                <a
                  v-for="(notification, index) in filteredNotifications"
                  :key="index"
                  class="notification_content"
                  :href="notification.link"
                >
                  <div class="user_timeline">
                    <p>{{ notification.title }}</p>
                    <p>{{ shorten(notification.content) }}</p>
                  </div>
                  <div class="notification_description">
                    {{ notificationTimeAgo(notification.dateTime) }}
                  </div>
                </a>
                <div
                  v-for="(notification, index) in recentNotifications"
                  :key="index"
                  class="notification_content"
                >
                  <div class="user_timeline">
                    <p>{{ notification.title }}</p>
                    <p>{{ shorten(notification.content) }}</p>
                  </div>
                  <div class="notification_description">
                    {{ notificationTimeAgo(notification.dateTime) }}
                  </div>
                </div>
              </div> -->
              <div class="notification_body">
                <a
                  v-for="(notification, index) in sortedNotification"
                  :key="index"
                  class="notification_content"
                  :href="notification.link"
                >
                  <div class="user_timeline">
                    <p>{{ notification.title }}</p>
                    <p>{{ shorten(notification.content) }}</p>
                  </div>
                  <div class="notification_description">
                    {{ notificationTimeAgo(notification.dateTime) }}
                  </div>
                </a>
              </div>
            </div>
            <router-link
              id="notification_footer"
              to="/notification"
              @click="hideNotify"
              >See all recent activities</router-link
            >
          </div>
        </div>

        <router-link to="/dashboard/settings">
          <img
            class="img-fluid"
            src="@/assets/images/vectors/GearSix.svg"
            alt="gear"
          />
        </router-link>

        <router-link id="img-container" to="/dashboard/settings">
          <img
            v-if="previewImageUrl"
            :src="previewImageUrl"
            alt="useravatar"
            class="img-fluid avatar-image"
          />
          <img
            v-if="!previewImageUrl"
            src="@/assets/images/backgrounds/Ellipse11.png"
            alt="useravatar"
            class="img-fluid avatar-image"
          />
        </router-link>
      </div>
    </div>
    <audio v-show="false" ref="notifyAudio">
      <source src="@/assets/sounds/notification.mp3" type="audio/mp3" />
    </audio>
  </nav>
</template>

<script>
import "bootstrap-icons/font/bootstrap-icons.css";

import { RouterLink } from "vue-router";
export default {
  name: "TopHeader",
  components: {
    RouterLink,
  },
  data() {
    return {
      notifications: [],
      notify: false,
      newNotification: false,
    };
  },
  computed: {
    recentNotifications() {
      const notificationList = this.notifications;
      notificationList.reverse();
      return notificationList.slice(
        0,
        notificationList.length >= 5 ? 5 : notificationList.length,
      );
    },
    filteredNotifications() {
      return this.recentNotifications.filter(
        (notification) => notification.link,
      );
    },

    sortedNotification() {
      const allNotification = [
        ...this.recentNotifications,
        ...this.filteredNotifications,
      ];
      return allNotification.sort(
        (a, b) => new Date(b.dateTime) - new Date(a.dateTime),
      );
    },

    notificationTimeAgo() {
      return (dateTime) => {
        const currentTime = new Date();
        const notificationTime = new Date(dateTime);
        const timeDifferenceInMilliseconds = currentTime - notificationTime;

        const timeDifferenceInSeconds = Math.floor(
          timeDifferenceInMilliseconds / 1000,
        );
        const timeDifferenceInMinutes = Math.floor(
          timeDifferenceInSeconds / 60,
        );
        const timeDifferenceInHours = Math.floor(timeDifferenceInMinutes / 60);

        if (timeDifferenceInSeconds < 60) {
          return `just now`;
        }

        if (timeDifferenceInMinutes < 60) {
          return `${timeDifferenceInMinutes} minutes ago`;
        }

        if (timeDifferenceInHours < 24) {
          return `${timeDifferenceInHours} hours ago`;
        } else {
          const options = { year: "numeric", month: "long", day: "numeric" };
          return notificationTime.toLocaleString(undefined, options);
        }
      };
    },
  },
  mounted() {
    this.notifications = this.$store.state.notifications;
    window.AppSocket.on("notification", (data) => {
      console.log(data);
      this.notifications.push(data);
      this.$store.dispatch("setNotifications", this.notifications);
      this.newNotification = true;
      this.$refs.notifyAudio.play();
    });
  },
  methods: {
    toggleNotify() {
      this.notify = !this.notify;
      this.newNotification = false;
    },
    shorten(content) {
      return content.length > 40 ? content.substr(0, 40) + "..." : content;
    },
    // hide the notification drop down on the top nav when the route is notification
    hideNotify() {
      if (this.$route.path === "/notification") {
        this.notify = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/navigation";

#img-container {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.avatar-image {
  border-radius: 50%;
}
.beta {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 11px;
  color: #05b050;
}
.notification_container {
  position: absolute;
  width: 380px;
  right: 0px;
  border: 3px solid #fff;
  z-index: 1000;
}
.notification_header {
  width: 100%;
  background: #05b050;
  padding: 20px 10px;
  color: white;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.notification_body {
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  gap: 5px;
}
.notification_body_wrapper {
  max-height: 300px;
  overflow: auto;
}
.notification_content {
  padding: 10px 10px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #13161f;
  &:hover {
    background: darken(#f5f5f5, 10%);
  }
}
#notification_footer {
  display: block;
  width: 100%;
  font-size: 14px;
  color: #047e39;
  background: #f5f5f5;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}
.user_timeline p:first-child {
  font-weight: bold;
  cursor: default;
}
.user_timeline p:last-child {
  color: #a2a2a2;
  cursor: default;
}
#notification_footer:hover {
  text-decoration: underline;
  text-decoration-color: #047e39;
}
.notification_description {
  font-style: italic;
}
#caret {
  position: relative;
  left: 90%;
  color: #047e39;
}
.notification_dot {
  background: red;
  padding: 2px;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  position: absolute;
  right: 0;
  top: 0;
}

.bell {
  img {
    cursor: pointer;
  }
}
</style>
