<template>
  <DefaultNav>
    <div class="big-container">
      <div class="headings">
        <h2>All Credit Scores</h2>
        <!-- <div class="right-btns"> -->
        <!-- <a href="/dashboard/new-ticket" class="green-link">Score Farmers</a> -->
        <!-- <a href="/dashboard/new-ticket" class="white-link"
            >View Credit Scoring History</a
          > -->
        <!-- </div> -->
      </div>

      <div class="active-subscriptions">
        <table class="table table-borderless sub-history">
          <thead>
            <tr>
              <td class="theader">FARMER</td>
              <td class="theader">GRADE</td>
              <td class="theader">SCORE</td>
              <td class="theader">AMT. REQUESTED</td>
              <td class="theader">AMT. SUGGESTED</td>
              <td class="theader">LOAN</td>
              <td class="theader">ACTION</td>
            </tr>
          </thead>
          <tbody class="scrollable">
            <template v-if="scoringData.length > 0">
              <tr v-for="(data, index) in scoringData.reverse()" :key="index">
                <td>
                  {{ data.doc.farmer.firstname }} {{ data.doc.farmer.lastname }}
                </td>
                <td>{{ data.doc.creditscore.grade }}</td>
                <td>{{ roundValues(data.doc.creditscore.score) }}</td>
                <td>
                  {{ thousandsData(data.doc.creditscore.amountrequested) }}
                </td>
                <td>
                  {{ thousandsData(data.doc.creditscore.recommendedamount) }}
                </td>
                <td>
                  <button
                    v-if="
                      data.doc.creditscore.grade == 'A' ||
                      data.doc.creditscore.grade == 'B' ||
                      (data.doc.creditscore.grade == 'C' &&
                        currentuserid != 'partner')
                    "
                    class="btn btn-info text-white"
                    @click="goto(data.id)"
                  >
                    <small>Request</small>
                  </button>
                  <span
                    v-else-if="
                      data.doc.creditscore.grade == 'A' ||
                      data.doc.creditscore.grade == 'B' ||
                      (data.doc.creditscore.grade == 'C' &&
                        currentuserid == 'partner')
                    "
                  >
                    <button
                      class="btn btn-info text-white"
                      @click="showRequestHistory(data.id)"
                    >
                      <small>See History</small>
                    </button>
                  </span>
                  <span v-else> Low Score </span>
                </td>
                <td>
                  <a
                    href="javascript:void(0)"
                    class="text-success"
                    @click="redirectUser(`${data.doc.farmer.farmerid}`)"
                    >View</a
                  >
                </td>
              </tr>
            </template>
            <div v-else>
              <p class="text-center mt-5">No farmers found</p>
            </div>
          </tbody>
          <!-- pagination -->
          <nav class="d-flex justify-content-center my-4">
            <div id="pagination"></div>
          </nav>
        </table>
      </div>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import Preloader from "@/layouts/shared/Preloader.vue";
import FarmerService from "@/services/farmer";

export default {
  name: "CreditScoring",
  components: {
    DefaultNav,
    Preloader,
  },

  data() {
    return {
      scoringData: [],
      scoringData2: [],
      resultReturned: false,
      farmerNames: [],
      uid: JSON.stringify(
        JSON.parse(localStorage.getItem("Zowasel")).user.user_id,
      ),
      start: 0,
      limit: 20,
      // selectGrade: "",
      filter: false,
      min: 0,
      max: 50000,
      categories: [],
      filters: {
        category: "",
        price: {
          min: 0,
          max: 50000,
        },
      },
      currentuserid: JSON.parse(localStorage.getItem("Zowasel")).user.user.type,
    };
  },
  mounted() {
    let vm = this;
    vm.isLoading = true;
    FarmerService.getAllFarmers(this.start, this.limit, (response) => {
      if (response.error == false) {
        // response.data
        console.log(this.scoringData.length);
        const filteringList = response.data.rows;

        if (this.currentuserid != "partner") {
          this.scoringData = filteringList.filter(
            (data) => data.doc.farmer.userid == this.uid,
          );
          vm.isLoading = false;
          console.log(this.scoringData);
          if (this.scoringData.length > 0) {
            this.paginate(this.scoringData, "scoringData");
            vm.isLoading = false;
          }
        } else {
          this.paginate(filteringList, "scoringData");
        }

        vm.isLoading = false;

        // for (let i = 0; i < response.data.rows.length; i++) {
        //   if (response.data[i].creditscore.score !== "null") {
        //     this.paginate(response.data.reverse(), "scoringData");
        //   } else {
        //     this.paginate(response.data.reverse(), "scoringData");
        //   }
        // }
      }
    });
  },
  methods: {
    goto(id) {
      localStorage.setItem("farmerID", id);
      window.location.href = `/dashboard/loan/type/select`;
    },
    toggleFilter() {
      this.filter = !this.filter;
    },
    redirectUser(id) {
      window.location.href = `/dashboard/farmer/view/${id}`;
    },
    showRequestHistory(id) {
      localStorage.setItem("farmerID", id);
      location.assign("/dashboard/loanhistory");
    },
    thousandsData(x = 0) {
      if (typeof x == "number") {
        return "₦" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
    roundValues(x) {
      if (typeof x == "number") {
        return x.toFixed(2);
      } else {
        return x;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 4% 5% 10% 3%;
  overflow-y: scroll;
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}
.active-subscriptions {
  background-color: white;
  padding: 24px 35px;
  margin-top: 20px;
}

/* The slider */

.sub-history {
  margin-top: 30px;
  width: 100%;
  margin-left: 10px;

  h2 {
    margin-bottom: 40px;
  }
  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #4a4754;
  }

  .serial {
    max-width: 50px;
  }

  tr {
    display: flex;
    flex-direction: row;
    padding: 20px 9px;
    height: 66px;
    th {
      font-weight: bold;
    }
  }
  td {
    flex: 1;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4754;
    max-width: 185px;
    // overflow-x: hidden;
    padding-left: 10px;
  }
  .active {
    width: 100px;
    height: 26px;
    background: #dcfce7;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .active-dot {
      width: 7.52px;
      height: 8px;
      background: #047b38;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #047b38;
    }
  }
  .expired {
    width: 100px;
    height: 26px;
    background: #fff8e6;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .expired-dot {
      width: 7.52px;
      height: 8px;
      background: #fab900;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #fab900;
    }
  }
  .cancelled {
    width: 100px;
    height: 26px;
    background: rgba(252, 220, 220, 0.93);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .cancelled-dot {
      width: 7.52px;
      height: 8px;
      background: #c52222;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #6b0303;
    }
  }
  a {
    text-decoration: none;
  }
  a:hover td {
    color: #05b050 !important;
  }
}
.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  a {
    text-decoration: none;
  }
  .right-btns {
    display: flex;
    gap: 20px;
  }
  .green-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 180px;
    height: 40px;
    background: #05b050;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    border-radius: 4px;
  }
  .white-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 40px;
    background: #ffffff;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #05b050;
    border: 2px solid #05b050;
    border-radius: 4px;
  }
  .red-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 180px;
    height: 40px;
    background: #cd4647;
    box-shadow:
      0px 2px 4px rgba(44, 39, 56, 0.08),
      0px 4px 8px rgba(44, 39, 56, 0.08);
    border-radius: 4px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    border-radius: 4px;
  }
}
.date-region {
  margin-top: 30px;
}
.view-btn {
  color: black;
}

.green-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  background: #05b050;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.02em;
  border-radius: 4px;
}
</style>
