import config from "@/config";
import axios from "axios";

import NegotiationService from "@/services/negotiation";

const GET_CROP_CATEGORIES_PATH = () => "category/crop/getall";
const GET_INPUT_CATEGORIES_PATH = () => "category/input/getall";
const GET_CROPS_SALE_PATH = () => "crop/getbycropoffer";
const GET_CROPS_WANTED_PATH = () => "crop/getbycropwanted";
const GET_INPUTS_PATH = () => "input";
const GET_INPUT_PATH = (id) => `input/${id}`;
const GET_CROPS_AUCTION_PATH = () => "crop/getbycropauction";
const GET_CROP_PATH = (id) => `crop/getbyid/${id}`;
const CREATE_CROP_WANTED_PATH = () => `testpost`;
const ADD_DELIVERY_ADDRESS = () => `order/cart/create`;
const GET_ORDER_PATH = (orderHash) => `order/${orderHash}`;
const ADD_TO_CART_PATH = () => `input/cart/add`;
const GET_CART_ITEMS_PATH = (id) => `input/cart/${id}`;
const DELTE_CART_ITEM_PATH = (id) => `input/cart/delete/${id}`;
const DEACTIVATE_CROP_WANTED_PATH = (id) => `crop/${id}/deactivate`;
const DEACTIVATE_INPUT_PATH = (id) => `input/${id}/deactivate`;
const DEACTIVATE_CROP_AUCTION_PATH = (id) => `crop/${id}/deactivate`;
const DEACTIVATE_CROP_SALE_PATH = (id) => `crop/${id}/deactivate`;
const GET_SUB_CATEGORIES_PATH = () => "subcategory/getall";
const ADD_NEW_CROP_PATH = (type) => `crop/${type}/add`;
const GET_ORDERS_PATH = () => `orders`;
const GET_SALES_PATH = () => `sales`;
const ADD_CROP_INPUT_PATH = () => `input/add/`;
const GET_CROPS_BY_USER_PATH = () => `crops`;
const GET_INPUTS_BY_USER_PATH = () => `inputs`;
const FULFIL_CROP_OFFER_PATH = (id) => `crop/${id}/fulfil`;
const ADD_NEW_AUCTION_PATH = (type) => `crop/${type}/add`;
const ADD_BID_PATH = (id) => `crop/${id}/bid`;
const GET_COLOR_PATH = () => `/color/getall`;
const START_KYC_PATH = () => `account/kyc`;
const GET_KYC_STATUS_PATH = () => `account/kycstatus`;
const GET_KYC_TYPE_PATH = () => `account/kyctypes`;
const START_KYB_PATH = () => "account/kyb";
const GET_KYB_STATUS_PATH = () => `account/kybstatus`;
const GET_KYB_DETAILS_PATH = () => "account/kybdocument";
const GET_TEAM_PATH = () => "account/team";
const ADD_TEAM_PATH = () => "account/team";
const GET_TRANSACTIONS_PATH = () => `transactions`;
const DELETE_TEAM_MEMBER = (id) => `account/team/${id}`;
const GET_ANALYTICS_PATH = () => `analytics`;
const GET_CROP_FOCUS_PATH = () => `category/crop_focus/getall`;
const ADD_SUBSCRIPTION_PATH = () => "notification/subscribe";
const REMOVE_SUBSCRIPTION_PATH = () => "notification/unsubscribe";
const GET_SUBSCRIPTION_PATH = () => "/notification/subscriptions";
const ADD_UPDATE_CROP_WANTED_PATH = (type) => `crop/${type}/edit`;
const ADD_UPDATE_CROP_SALE_PATH = (type) => `crop/${type}/edit`;
const ADD_UPDATE_CROP_AUCTION_PATH = (type) => `crop/${type}/edit`;
const ADD_UPDATE_INPUT_PATH = () => `input/product/edit`;
const SAVE_PAYMENT_OPTION_PATH = (orderHash) => `order/${orderHash}`;
const UPDATE_ORDER_PAYMENT_PATH = (orderHash) => `order/${orderHash}/pending`;
const ADD_LOCATION_PATH = () => `account/locations`;
const ADD_DISPUTE_PATH = (orderHash) => `order/${orderHash}/dispute`;
const GET_OTP_WITHDRAW = () => `/wallet/withdraw/otp`;
const VERIFY_OTP_WITHDRAW = () => `/wallet/otp/verify`;
const ADD_LOAN_REQUEST = () => "loans";
const SET_PURCHASE_ORDER_PATH = (orderHash) =>
  `order/${orderHash}/purchaseorderstatus`;
const ADD_CERTIFICATES_PATH = () => `/account/certificates`;
const ADD_MEDIA_PATH = () => `media/upload`;
const REMOVE_MEDIA_PATH = () => `media/remove`;
const GET_MEDIA_PATH = () => `media`;

export default {
  getCropCategories: function (callback) {
    axios
      .get(config.BASE_URL + GET_CROP_CATEGORIES_PATH())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {});
  },
  getInputCategories: function (callback) {
    axios
      .get(config.BASE_URL + GET_INPUT_CATEGORIES_PATH())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {});
  },
  getCropsForSale: function (callback) {
    axios
      .get(config.BASE_URL + GET_CROPS_SALE_PATH())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {});
  },
  getCropsWanted: function (callback) {
    axios
      .get(config.BASE_URL + GET_CROPS_WANTED_PATH())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  getCropsForAuction: function (callback) {
    axios
      .get(config.BASE_URL + GET_CROPS_AUCTION_PATH())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {});
  },
  getCropById: function (id, callback) {
    axios
      .get(config.BASE_URL + GET_CROP_PATH(id))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {});
  },
  getInputs: function (callback) {
    axios
      .get(config.BASE_URL + GET_INPUTS_PATH())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {});
  },
  getInputById: function (id, callback) {
    axios
      .get(config.BASE_URL + GET_INPUT_PATH(id))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {});
  },
  getOrder: function (hash, callback) {
    axios
      .get(config.BASE_URL + GET_ORDER_PATH(hash))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  createCropWanted: function (cropData, callback) {
    axios
      .post(config.BASE_URL + CREATE_CROP_WANTED_PATH(), cropData)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  updateCropWanted: function (requestData, callback) {
    axios
      .post(
        config.BASE_URL + ADD_UPDATE_CROP_WANTED_PATH("wanted"),
        requestData,
      )
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  updateCropSale: function (requestData, callback) {
    axios
      .post(config.BASE_URL + ADD_UPDATE_CROP_SALE_PATH("sale"), requestData)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  addLocation: function (locations, callback) {
    axios
      .put(config.BASE_URL + ADD_LOCATION_PATH(), locations)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  addCertificate: function (certificates, callback) {
    axios
      .post(config.BASE_URL + ADD_CERTIFICATES_PATH(), certificates)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  addMedia: function (media, callback) {
    axios
      .post(config.BASE_URL + ADD_MEDIA_PATH(), media)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  getMedia: function (callback) {
    axios.get(config.BASE_URL + GET_MEDIA_PATH()).then((response) => {
      callback(response.data);
    });
  },
  removeMedia: function (media, callback) {
    axios
      .post(config.BASE_URL + REMOVE_MEDIA_PATH(), media)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  updateCropAuction: function (requestData, callback) {
    axios
      .post(
        config.BASE_URL + ADD_UPDATE_CROP_AUCTION_PATH("auction"),
        requestData,
      )
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  updateInput: function (requestData, callback) {
    axios
      .put(config.BASE_URL + ADD_UPDATE_INPUT_PATH(), requestData)
      .then((response) => {
        callback(response.data);
      })
      .catch(error.data);
  },

  addSubcription: function (data, callback) {
    axios
      .post(config.BASE_URL + ADD_SUBSCRIPTION_PATH(), data)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  remove_Subcription: function (data, callback) {
    axios
      .post(config.BASE_URL + REMOVE_SUBSCRIPTION_PATH(), data)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },

  addToCart: function (data, callback) {
    axios
      .post(config.BASE_URL + ADD_TO_CART_PATH(), data)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },

  getCartItems: function (id, callback) {
    axios
      .get(config.BASE_URL + GET_CART_ITEMS_PATH(id))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  deleteCartItem: function (id, callback) {
    axios
      .delete(config.BASE_URL + DELTE_CART_ITEM_PATH(id))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  deactivateCropWanted: function (id, callback) {
    axios
      .post(config.BASE_URL + DEACTIVATE_CROP_WANTED_PATH(id))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  deactivateInput: function (id, callback) {
    axios
      .post(config.BASE_URL + DEACTIVATE_INPUT_PATH(id))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  deactivateCropAuction: function (id, callback) {
    axios
      .post(config.BASE_URL + DEACTIVATE_CROP_AUCTION_PATH(id))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  deactivateSale: function (id, callback) {
    axios
      .post(config.BASE_URL + DEACTIVATE_CROP_SALE_PATH(id))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  getSubCategories: function (callback) {
    axios
      .get(config.BASE_URL + GET_SUB_CATEGORIES_PATH())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {});
  },
  addCropWanted: function (NewCropData, callback) {
    axios
      .post(config.BASE_URL + ADD_NEW_CROP_PATH("wanted"), NewCropData)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  addCropForSale: function (NewCropData, callback) {
    axios
      .post(config.BASE_URL + ADD_NEW_CROP_PATH("sale"), NewCropData)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  getOrders: function (callback) {
    axios
      .get(config.BASE_URL + GET_ORDERS_PATH())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  getSales: function (callback) {
    axios
      .get(config.BASE_URL + GET_SALES_PATH())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  saveInput: function (NewInputData, callback) {
    axios
      .post(config.BASE_URL + ADD_CROP_INPUT_PATH(), NewInputData)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  saveDeliveryAddress: function (delivery_details, callback) {
    axios
      .post(config.BASE_URL + ADD_DELIVERY_ADDRESS(), {
        delivery_details: delivery_details,
      })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  getUserCrops: function (callback) {
    axios
      .get(config.BASE_URL + GET_CROPS_BY_USER_PATH())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },

  getUserInputs: function (callback) {
    axios
      .get(config.BASE_URL + GET_INPUTS_BY_USER_PATH())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  fulfilCropOffer: function (data, callback) {
    axios
      .post(config.BASE_URL + FULFIL_CROP_OFFER_PATH(data.crop_id), data)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.response.data);
      });
  },
  addCropForAuction: function (newCropData, callback) {
    axios
      .post(config.BASE_URL + ADD_NEW_AUCTION_PATH("auction"), newCropData)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  addBid: function (data, callback) {
    axios
      .post(config.BASE_URL + ADD_BID_PATH(data.cropId), {
        amount: data.amount,
      })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  getBids: function (cropId, callback) {
    axios
      .get(config.BASE_URL + ADD_BID_PATH(cropId))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  getColor: function (callback) {
    axios
      .get(config.BASE_URL + GET_COLOR_PATH())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {});
  },
  sendKycRequest: function (kycdetails, callback) {
    axios
      .post(config.BASE_URL + START_KYC_PATH(), kycdetails)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.response.data);
      });
  },
  getKycStatus: async function (callback) {
    await axios
      .get(config.BASE_URL + GET_KYC_STATUS_PATH())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.response);
      });
  },
  getKybStatus: function (callback) {
    axios
      .get(config.BASE_URL + GET_KYB_STATUS_PATH(), {
        headers: {
          "Cache-Control": "no-cache", // Adding Cache-Control header to the request
        },
      })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.response);
      });
  },
  getKycTypes: function (callback) {
    axios
      .get(config.BASE_URL + GET_KYC_TYPE_PATH())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.response);
      });
  },
  sendKyb: function (kybdetails, callback) {
    axios
      .post(config.BASE_URL + START_KYB_PATH(), kybdetails)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.response);
      });
  },
  getKybDetails: function (callback) {
    axios
      .get(config.BASE_URL + GET_KYB_DETAILS_PATH())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.response);
      });
  },
  getTeam: function (callback) {
    axios
      .get(config.BASE_URL + GET_TEAM_PATH())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.response);
      });
  },
  addMember: function (teamDetails, callback) {
    axios
      .post(config.BASE_URL + ADD_TEAM_PATH(), teamDetails)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.response);
      });
  },
  deleteTeamMember: function (id, callback) {
    axios
      .delete(config.BASE_URL + DELETE_TEAM_MEMBER(id))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  getTransactions: function (callback) {
    axios
      .get(config.BASE_URL + GET_TRANSACTIONS_PATH())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  getAnalytics: function (callback) {
    axios
      .get(config.BASE_URL + GET_ANALYTICS_PATH())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  getCropFocusCategories: function (callback) {
    axios
      .get(config.BASE_URL + GET_CROP_FOCUS_PATH())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {});
  },
  addSubscription: function (data, callback) {
    axios
      .post(config.BASE_URL + ADD_SUBSCRIPTION_PATH(), data)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.response);
      });
  },
  removeSubscription: function (data, callback) {
    axios
      .post(config.BASE_URL + REMOVE_SUBSCRIPTION_PATH(), data)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.response);
      });
  },
  getSubscription: function (callback) {
    axios
      .get(config.BASE_URL + GET_SUBSCRIPTION_PATH())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.response);
      });
  },
  savePaymentOption: function (data, callback) {
    axios
      .put(config.BASE_URL + SAVE_PAYMENT_OPTION_PATH(data.order_hash), {
        payment_option: data.payment_option,
      })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  updateOrderPayment: function (id, callback) {
    axios
      .post(config.BASE_URL + UPDATE_ORDER_PAYMENT_PATH(id))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  addDispute: function (id, data, callback) {
    axios
      .post(config.BASE_URL + ADD_DISPUTE_PATH(id), data)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  addLoanRequest: function (data, callback) {
    axios
      .post(config.BASE_URL + ADD_LOAN_REQUEST(), data)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  setPurchaseOrder: function (id, data, callback) {
    axios
      .post(config.BASE_URL + SET_PURCHASE_ORDER_PATH(id), data)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  getWithdrawalOtp: function (callback) {
    axios
      .post(config.BASE_URL + GET_OTP_WITHDRAW())
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.response);
      });
  },
  verifyOtpWithdraw: function (data, callback) {
    axios
      .post(config.BASE_URL + VERIFY_OTP_WITHDRAW(), data)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.response);
      });
  },
  ...NegotiationService,
};
