<template>
  <div class="full-spec-wrapper">
    <div id="section-to-download">
      <h2 id="SQS">Standard Quality Specification (SQS)</h2>
      <table class="table table-SQS table-bordered">
        <thead>
          <tr>
            <th>Parameters</th>
            <th>
              <span id="parameters" class="d-block">Specification</span
              >(Dropdown Menu)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Color</td>
            <td>
              <div>
                {{ placeString(order.products[0].specification.color, "-") }}
              </div>
              <!-- <div>Red</div>
                        <div>Yellow</div>
                        <div>Cream</div>
                        <div>Brown</div> -->
            </td>
          </tr>
          <tr>
            <td>Moisture</td>
            <td>
              {{
                placeString(order.products[0].specification.moisture, "-", {
                  suffix: "%",
                })
              }}
            </td>
          </tr>
          <tr>
            <td>Foreign Matter (FM)</td>
            <td>
              {{
                placeString(
                  order.products[0].specification.foreign_matter,
                  "-",
                  {
                    suffix: "%",
                  },
                )
              }}
            </td>
          </tr>
          <tr>
            <td>Broken Grains</td>
            <td>
              {{
                placeString(
                  order.products[0].specification.broken_grains,
                  "-",
                  {
                    suffix: "%",
                  },
                )
              }}
            </td>
          </tr>
          <tr>
            <td>Weevil</td>
            <td>
              {{
                placeString(order.products[0].specification.weevil, "-", {
                  suffix: "%",
                })
              }}
            </td>
          </tr>
          <tr>
            <td>Damaged Kernel(DK)</td>
            <td>
              {{
                placeString(order.products[0].specification.dk, "-", {
                  suffix: "%",
                })
              }}
            </td>
          </tr>
          <tr>
            <td>rotten/Shriveled</td>
            <td>
              {{
                placeString(
                  order.products[0].specification.rotten_shriveled,
                  "-",
                  { suffix: "%" },
                )
              }}
            </td>
          </tr>
          <tr>
            <td>Test weight</td>
            <td>
              {{ placeString(order.products[0].specification.unit, "-") }}
            </td>
          </tr>
          <tr>
            <td>Hardness</td>
            <td>
              {{
                placeString(order.products[0].specification.hardness, "-", {
                  suffix: "%",
                })
              }}
            </td>
          </tr>
          <tr>
            <td>Splits</td>
            <td>
              {{
                placeString(order.products[0].specification.splits, "-", {
                  suffix: "%",
                })
              }}
            </td>
          </tr>
          <tr>
            <td>Oil Content</td>
            <td>
              {{
                placeString(order.products[0].specification.oil_content, "-", {
                  suffix: "%",
                })
              }}
            </td>
          </tr>
          <tr>
            <td>Infestation</td>
            <td>
              {{
                placeString(order.products[0].specification.infestation, "-", {
                  suffix: "%",
                })
              }}
            </td>
          </tr>
          <tr>
            <td>Grain Size</td>
            <td>
              <div>
                {{
                  placeString(order.products[0].specification.grain_size, "-")
                }}
              </div>
            </td>
          </tr>
          <tr>
            <td>Hectoliter Test Weight</td>
            <td>
              {{
                placeString(order.products[0].specification.hectoliter, "-", {
                  suffix: "%",
                })
              }}
            </td>
          </tr>
          <tr>
            <td>Total Defects</td>
            <td>
              {{
                placeString(
                  order.products[0].specification.total_defects,
                  "-",
                  {
                    suffix: "%",
                  },
                )
              }}
            </td>
          </tr>
          <tr>
            <td>Dockage</td>
            <td>
              {{
                placeString(order.products[0].specification.dockage, "-", {
                  suffix: "%",
                })
              }}
            </td>
          </tr>
          <tr>
            <td>Ash Content</td>
            <td>
              {{
                placeString(order.products[0].specification.ash_content, "-", {
                  suffix: "%",
                })
              }}
            </td>
          </tr>
          <tr>
            <td>Acid Insoluble ash</td>
            <td>
              {{
                placeString(order.products[0].specification.acid_ash, "-", {
                  suffix: "%",
                })
              }}
            </td>
          </tr>
          <tr>
            <td>Volatile</td>
            <td>
              {{
                placeString(order.products[0].specification.volatile, "-", {
                  suffix: "%",
                })
              }}
            </td>
          </tr>
          <tr>
            <td>Mold % By Weigh</td>
            <td>
              {{
                placeString(order.products[0].specification.mold, "-", {
                  suffix: "%",
                })
              }}
            </td>
          </tr>
          <tr>
            <td>Drying Process</td>
            <td>
              {{
                placeString(order.products[0].specification.drying_process, "-")
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="position-relatives">
      <div class="btn-group w-100 gap-4">
        <div class="download">
          <a class="btn" type="button" @click="downloadOpt()">Download</a>
          <div v-if="downloadFile == true" class="download_container">
            <button
              href=""
              class="download_invoice as_image"
              @click="downloadAsImage()"
            >
              image
            </button>

            <button class="download_invoice as_pdf" @click="downloadAsPDF()">
              pdf
            </button>
          </div>
        </div>
        <div class="download">
          <a class="btn" type="button" @click="printSection()">Print</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FullSpecification",
  props: {
    order: Object,
  },
  data() {
    return {
      downloadFile: false,
    };
  },
  computed: {
    specification() {
      return this.order.negotiation
        ? this.order.negotiation.specification
        : this.order.products[0].specification;
    },
  },
  methods: {
    downloadOpt() {
      this.downloadFile = !this.downloadFile;
    },
    downloadAsImage() {
      const element = document.getElementById("section-to-download");
      // Create a canvas from the element
      html2canvas(element).then((canvas) => {
        // Convert canvas to image
        const imgData = canvas.toDataURL("image/png");
        // create a temporary link element and trigger download
        const link = document.createElement("a");
        link.href = imgData;
        link.download = "Specification.png";
        link.click();
      });
    },
    downloadAsPDF() {
      const element = document.getElementById("section-to-download");
      // Create a new instance of the html2pdf library
      const opt = {
        margin: 1,
        filename: "section.pdf",
        image: { type: "png", quality: 1 },
        html2canvas: { scale: 2 },
        jsPDF: { format: "a4", orientation: "portrait" },
      };

      html2pdf().set(opt).from(element).save();
    },
    printSection() {
      const sectionToPrint = document.getElementById(
        "section-to-download",
      ).innerHTML;
      const printWindow = window.open("", "_blank");
      printWindow.document.open();
      printWindow.document.write(`
    <html>
      <head>
        <title>Print</title>
      </head>
      <body>
        ${sectionToPrint}
      </body>
    </html>
  `);
      printWindow.document.close();

      printWindow.onload = function () {
        printWindow.print();
        printWindow.onafterprint = function () {
          printWindow.close();
        };
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.full-spec-wrapper {
  background: #ffffff;

  #section-to-download {
    padding: 30px;
  }
  #SQS {
    font-size: 15px;
    font-weight: 800;
    margin-bottom: 15px;
  }

  table {
    font-size: 14px;
    #parameters {
      font-weight: 400;
    }
  }

  .btn-group {
    margin-block: 24px 73px;
    display: flex;
    a,
    button {
      width: 274px;
      height: 47px;
      padding: 10px;
      background: #2d3748;
      border: 0;
      color: white;
      &:hover {
        background: darken(#2d3748, 10%);
        color: white;
      }
    }
    .download_container {
      transition: display 3s;
      position: absolute;
      width: 274px;
      padding: 10px;
      background: white;
      top: -70px;
      display: flex;
      button {
        background: #05b050;
        flex: 1;
        text-align: center;
        &:hover {
          background: darken(#05b050, 10%);
        }
      }
    }
  }
}
@media print {
  body * {
    display: none;
  }

  #section-to-download,
  #section-to-download * {
    display: block !important;
  }

  .download,
  .position-relatives,
  .btn-group {
    display: none !important;
  }
}
</style>
