<template>
  <DefaultNav>
    <div class="big-container pb-5">
      <form class="mt-4" @submit.prevent="saveData()">
        <div class="headings">
          <h2>Farm Information</h2>
          <div class="right-btns">
            <a href="/dashboard/farmer/view" class="yellow-link"
              >View Farmers</a
            >
            <button class="green-link">Save</button>
          </div>
        </div>
        <div v-if="editing == false" class="mb-3">
          <h6>
            <strong>All * fields are required</strong>
          </h6>
        </div>
        <div class="row mb-4">
          <div class="col">
            <label for="">
              <span v-if="editing == false" class="text-danger">*</span> Farm
              Location/Address
            </label>
            <input
              v-model="farmaddress"
              type="text"
              class="form-control"
              placeholder="Enter your answer"
            />
          </div>
          <div class="col">
            <label for=""
              ><span v-if="editing == false" class="text-danger">*</span>
              Farming Type
            </label>
            <select
              v-model="farmtype"
              class="form-select"
              aria-label="Default select example"
              required
            >
              <option value="" selected>Choose...</option>
              <option value="Arable">Arable</option>
              <option value="Pastoral">Pastoral</option>
              <option value="Subsistent">Subsistent</option>
              <option value="Mixed">Mixed</option>
              <option value="Sole">Sole</option>
              <option value="Fish">Fish</option>
              <option value="Livestock">Livestock</option>
              <option value="Shifting cultivation">Shifting cultivation</option>
              <option value="Land rotation/Bush fallowing">
                Land rotation/Bush fallowing
              </option>
            </select>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <label for=""
              ><span v-if="editing == false" class="text-danger">*</span> Farm
              Size (per sqm)?</label
            >
            <input
              v-model="farmsize"
              type="text"
              class="form-control"
              placeholder="Enter your answer"
            />
          </div>
          <div class="col">
            <label for=""
              ><span v-if="editing == false" class="text-danger">*</span> Are
              you the owner of this farm?</label
            >
            <select
              v-model="ownerorcaretaker"
              class="form-select"
              aria-label="Default select example"
              required
            >
              <option value="" selected>Choose...</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
        <div class="row mb-4">
          <div v-if="ownerorcaretaker != ''" class="col">
            <label for=""
              ><span
                v-if="editing == false && ownerorcaretaker == 'No'"
                class="text-danger"
                >*</span
              >
              If no, what is the Name of the farmer owner?</label
            >
            <div v-if="ownerorcaretaker == 'No'">
              <input
                v-model="ownername"
                type="text"
                class="form-control"
                placeholder="Enter your answer"
              />
            </div>
            <div v-if="ownerorcaretaker == 'Yes'">
              <input
                v-model="ownername"
                type="text"
                class="form-control"
                placeholder="Enter your answer"
              />
            </div>
          </div>
          <div v-if="ownerorcaretaker != ''" class="col">
            <label for=""
              ><span
                v-if="editing == false && ownerorcaretaker == 'No'"
                class="text-danger"
                >*</span
              >
              If no, what is the mobile number of the farm owner?</label
            >
            <div v-if="ownerorcaretaker == 'No'">
              <input
                v-model="ownerphone"
                type="text"
                class="form-control"
                placeholder="Enter your answer"
              />
            </div>
            <div v-if="ownerorcaretaker == 'Yes'">
              <input
                v-model="ownerphone"
                type="text"
                class="form-control"
                placeholder="Enter your answer"
              />
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div v-if="ownerorcaretaker != ''" class="col">
            <label for=""
              ><span
                v-if="editing == false && ownerorcaretaker == 'No'"
                class="text-danger"
                >*</span
              >
              How long have you had this land on lease for (in years)?</label
            >
            <div v-if="ownerorcaretaker == 'No'">
              <input
                v-model="leaselength"
                type="text"
                class="form-control"
                placeholder="Enter your answer"
                required
              />
            </div>
            <div v-if="ownerorcaretaker == 'Yes'">
              <input
                v-model="leaselength"
                type="number"
                class="form-control"
                placeholder="Enter your answer"
              />
            </div>
          </div>
          <div class="col">
            <label for=""
              ><span v-if="editing == false" class="text-danger">*</span> What
              is your estimated yearly Income?</label
            >
            <input
              v-model="estimatedincome"
              type="text"
              class="form-control"
              placeholder="Enter your answer"
              required
            />
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <label for=""
              ><span v-if="editing == false" class="text-danger">*</span> Farm
              Cordinates (Longitude)
            </label>
            <input
              v-model="longitude"
              type="text"
              class="form-control"
              placeholder="Enter your answer"
            />
          </div>
          <div class="col">
            <label for=""
              ><span v-if="editing == false" class="text-danger">*</span> Farm
              Cordinates (Latitude)
            </label>
            <input
              v-model="latitude"
              type="text"
              class="form-control"
              placeholder="Enter your answer"
            />
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <label for=""
              ><span v-if="editing == false" class="text-danger">*</span>
              Type(s) of crop do you cultivate on this land?
              <em
                >example food crops, ornamental crops, feed crops, fiber crops,
                industrial crops</em
              >
            </label>
            <input
              v-model="croptype"
              type="text"
              class="form-control"
              placeholder="Seperate with a comma(,)"
            />
          </div>
        </div>
        <div class="mb-4">
          <label for="">How many animals do you rear on this land? </label>
          <input
            v-model="noofanimals"
            type="number"
            class="form-control"
            placeholder="Enter your answer"
          />
        </div>
        <div class="mb-4">
          <label for=""
            >What kind of livestock do you rear on this land?
            <em>Seperate with a comma(,)</em></label
          >
          <input
            v-model="animaltype"
            type="text"
            class="form-control"
            placeholder="Enter your answer"
          />
        </div>
      </form>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
// Alert box
import Alert from "@/utilities/alert";
import Preloader from "@/layouts/shared/Preloader.vue";
import FarmerService from "@/services/farmer";
import { v4 as uuidv4 } from "uuid";
import NilData from "@/utilities/returnNil";
export default {
  name: "FarmInformation",
  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      farmerid: "",
      farmid: "",
      farmaddress: "",
      farmtype: "",
      farmsize: "",
      ownername: "",
      ownerorcaretaker: "",
      ownerphone: "",
      leaselength: "",
      estimatedincome: "",
      longitude: "",
      latitude: "",
      croptype: "",
      noofanimals: "0",
      animaltype: "nil",
      editData: [],
      editing: false,
      farmerEdited: localStorage.getItem("farmerID"),
    };
  },
  mounted() {
    const url = window.location.href;
    this.isLoading = true;
    let vm = this;

    if (url.split("/").slice(-2)[0] == "edit") {
      this.editing = true;
      let farmerData = JSON.parse(localStorage.getItem("farmersdata"));

      vm.farmaddress = farmerData.farminformation[0].farmlocation;
      vm.farmtype = farmerData.farminformation[0].farmingtype;
      vm.farmsize = farmerData.farminformation[0].farmsizesqm;
      vm.longitude =
        farmerData.farminformation[0].farmlocationcoordinates.longitude;
      vm.latitude =
        farmerData.farminformation[0].farmlocationcoordinates.latitude;
      vm.ownerorcaretaker = farmerData.farminformation[0].farmownership;
      vm.leaselength = farmerData.farminformation[0].numberofyearsleased;
      vm.ownername = farmerData.farminformation[0].nameofowner;
      vm.ownerphone = farmerData.farminformation[0].farmownermobile;
      vm.croptype = farmerData.farminformation[0].croptypes;
      vm.animaltype = farmerData.farminformation[0].animaltypes;
      vm.noofanimals = farmerData.farminformation[0].numberofanimals;
      vm.estimatedincome =
        farmerData.farminformation[0].estimatedtotalincomeperyear;
      this.editData.push(farmerData);
      this.isLoading = false;
    } else {
      this.isLoading = false;
      this.editing = false;
    }
  },
  methods: {
    async saveData() {
      this.isLoading = true;
      if (this.editing == true) {
        this.editData[0].farminformation[0].farmlocation = this.farmaddress;
        this.editData[0].farminformation[0].farmingtype = this.farmtype;
        this.editData[0].farminformation[0].farmsizesqm = this.farmsize;
        this.editData[0].farminformation[0].farmlocationcoordinates.longitude =
          this.longitude;
        this.editData[0].farminformation[0].farmlocationcoordinates.latitude =
          this.latitude;
        this.editData[0].farminformation[0].farmownership =
          this.ownerorcaretaker;
        this.editData[0].farminformation[0].numberofyearsleased =
          this.leaselength;
        this.editData[0].farminformation[0].nameofowner = this.ownername;
        this.editData[0].farminformation[0].farmownermobile = this.ownerphone;
        this.editData[0].farminformation[0].croptypes = this.croptype;
        this.editData[0].farminformation[0].animaltypes = this.animaltype;
        this.editData[0].farminformation[0].numberofanimals = this.noofanimals;
        this.editData[0].farminformation[0].estimatedtotalincomeperyear =
          this.estimatedincome;

        let dt = {
          farmerid: this.farmerEdited,
          farmerjson: this.editData[0],
        };
        // console.log(dt);
        // return false;

        FarmerService.updateFarmer(dt, (data) => {
          /* ------------------- check if it returned with an error ------------------- */
          if (data[0].error == false) {
            Alert.success({
              message: data[1].message,
            });
            setTimeout(() => {
              window.location.href = `/dashboard/farmer/view/${this.farmerEdited}`;
            }, 2000);
          } else {
            Alert.error({
              message: data[1].message,
            });
          }
          this.isLoading = false;
        });
      } else {
        let vm = this;
        let dt = {
          farmerid: vm.farmerid,
          farmid: `USERFARM-${uuidv4()}`,
          farmlocation: NilData.sendNAtoDB(vm.farmaddress),
          farmingtype: vm.farmtype,
          farmsizesqm: vm.farmsize,
          farmlocationcoordinates: {
            longitude: NilData.sendNAtoDB(vm.longitude),
            latitude: NilData.sendNAtoDB(vm.latitude),
          },
          farmownership: vm.ownerorcaretaker,
          nameofowner: NilData.sendNAtoDB(vm.ownername),
          farmownermobile: NilData.sendNAtoDB(vm.ownerphone),
          numberofyearsleased: NilData.sendNAtoDB(vm.leaselength),
          estimatedtotalincomeperyear: vm.estimatedincome,
          croptypes: vm.croptype,
          numberofanimals: NilData.sendNAtoDB(vm.noofanimals),
          animaltypes: NilData.sendNAtoDB(vm.animaltype),
        };
        FarmerService.addFarmInformation(dt, (data) => {
          /* ------------------- check if it returned with an error ------------------- */
          if (data[0].error == false) {
            Alert.success({
              message: data[1].message,
            });
            setTimeout(() => {
              window.location.href = `/dashboard/farmer/view/${vm.farmerid}`;
            }, 2000);
          } else {
            Alert.error({
              message: data[1].message,
            });
          }
          this.isLoading = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 2% 5% 2% 3%;
  overflow-y: scroll;
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}
.active-subscriptions {
  background-color: white;
  padding: 24px 65px;
  margin-top: 40px;

  h2 {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #696671;
  }
}

/* The slider */
.sub-history {
  margin-top: 50px;
  width: 100%;
  margin-left: 10px;

  h2 {
    margin-bottom: 40px;
  }
  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #4a4754;
  }
}
.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  a {
    text-decoration: none;
  }
  .right-btns {
    display: flex;
    gap: 20px;
  }
  .green-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 145px;
    height: 40px;
    background: #05b050;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
  }
}
.green-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 145px;
  height: 40px;
  background: #05b050;
  border-radius: 5px;
  letter-spacing: 0.02em;
}
.yellow-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  width: 145px;
  height: 40px;
  background: #fab900 !important;
  border-radius: 5px;
  letter-spacing: 0.02em;
}
a {
  text-decoration: none;
}
.small-font {
  font-size: 11px !important;
}
</style>
