<template>
  <DefaultNav>
    <div class="big-container">
      <div class="big-content">
        <!-- ?Company profile header -->
        <div class="company_profile_card">
          <!-- *left -->
          <div class="profile_image">
            <img
              v-if="avatar"
              :src="avatar"
              alt="useravatar"
              class="img-fluid avatar-image"
            />
            <img
              v-else
              src="@/assets/images/backgrounds/Ellipse11.png"
              alt="useravatar"
              class="img-fluid avatar-image"
            />
          </div>
          <!-- *Right -->
          <!-- *display flex and direction column -->
          <div class="profile_details">
            <div class="company_name">
              <span class="user-name"
                >{{
                  userAccount.accountType === "individual"
                    ? product.user.first_name
                    : userAccount.accountType === "company"
                      ? product.user.company.company_name
                      : userAccount.default
                }}
              </span>
            </div>
            <!-- * row containing the first details -->
            <div class="company_details_rowOne">
              <!-- country details -->
              <div class="country">
                <img
                  src="@/assets/images/vectors/countrylogo.svg"
                  class="country_log"
                />
                <span class="country_name ms-2">
                  <span class="user-name">{{
                    userAccount.accountType === "individual"
                      ? product.user.country
                      : userAccount.accountType === "company"
                        ? product.user.company.country
                        : userAccount.default
                  }}</span>
                </span>
              </div>
              <!-- Employees details -->
              <!-- <div class="employees_details">
                <span class="no_Of_employees"> N/A</span>
                <span class="employees ms-2">Employees</span>
              </div> -->
              <!-- verification level -->
              <!-- <div v-if="userData.user.type ==='coperate'" class="verification_level">
                {{
                  product.user.reg_level == null
                    ? product.user.reg_level
                    : "Red-hot"
                }}
              </div> -->
            </div>
            <div class="company_details_rowTwo">
              <!-- verification status -->
              <div class="verification_status_container">
                <img
                  class="verification_logo"
                  src="@/assets/images/vectors/verified.svg"
                  alt="verification logo"
                />
                <span class="ms-2 verification_status">Verified</span>
              </div>
              <!-- crop fucus -->
              <div class="crop_focus"><span>Crop Focus:</span> N/A</div>
            </div>
          </div>
        </div>
        <!-- *Tabs  -->
        <div class="tabs">
          <a href="#description" class="description">
            <span>Profile</span>
          </a>

          <a href="#certificate" class="certification">
            <span>Certificate </span>
          </a>

          <a href="#warehouse" class="warehouse">
            <span>Warehouse</span>
          </a>

          <a href="#product" class="product">
            <span>Product</span>
          </a>

          <a href="#photos" class="photos">
            <span>Photos</span>
          </a>
        </div>
        <!-- Descriptions -->
        <div id="description" class="accordion description_accordion">
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Profile
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <p
                  v-if="userAccount.accountType === 'company'"
                  class="description_content"
                >
                  {{ profileData.description }}
                </p>
                <div class="overvew_container">
                  <h2 class="overview">Overview</h2>
                  <div class="overview_contents_wrapper">
                    <!-- *left -->
                    <div class="overview_left">
                      <div class="business_type">
                        <span>Business Type:</span>
                        <span class="ms-2">{{ profileData.businessType }}</span>
                      </div>
                      <div
                        v-if="userAccount.accountType === 'company'"
                        class="Annual_Sales_Revenue"
                      >
                        <span>Annual Sales Revenue:</span>
                        <span class="ms-2"> NGN{{ profileData.revenue }} </span>
                      </div>
                      <div class="Has_Warehouse">
                        <span>Has Warehouse:</span>
                        <span class="ms-2">
                          {{ profileData.has_warehouse }}
                        </span>
                      </div>
                      <div class="Crop-Focus"><span>Crop Focus:</span> N/A</div>
                    </div>
                    <!-- *right -->
                    <div class="overview_right">
                      <div class="Has_Export_Experience">
                        <span>Has Export Experience:</span>
                        <span class="ms-2"
                          >{{ profileData.export_experience }}
                        </span>
                      </div>
                      <div class="has_Certifications">
                        <span>Certifications:</span>
                        <span class="ms-2"
                          >{{ profileData.certifications }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Certification -->
        <div
          v-if="certificateList != null"
          id="certificate"
          class="certification_wrapper_carousel"
        >
          <div class="certificate_main">
            <h2>Certifications</h2>
            <div class="certificate_wrapper">
              <div
                v-for="(certificate, index) in certificatePaginate"
                :key="index"
                class="certificate_container"
              >
                <div class="certificate_image_conatiner">
                  <img
                    :src="certificate.url"
                    alt="Certificate"
                    class="img-fluid"
                  />
                </div>
                <p class="certifiacte_info">{{ certificate.title }}</p>
              </div>
            </div>
          </div>
          <nav class="pagination align-items-center gap-2 my-4">
            <button
              :disabled="currentPage === 1"
              class="btn btn-succ fs-small"
              @click="prevPage"
            >
              Previous
            </button>
            <span class="fs-small"
              >Page {{ currentPage }} of {{ totalPages }}</span
            >
            <button
              :disabled="currentPage === totalPages"
              class="btn btn-succ fs-small"
              @click="nextPage()"
            >
              Next
            </button>
          </nav>
        </div>
        <!-- warehouse -->
        <div
          v-if="WarehouseDetails != null"
          id="warehouse"
          class="warhouse_wrapper_carousel"
        >
          <div class="warehouse_wrapper">
            <h2>Warehouse</h2>
            <div class="warehouse_container">
              <div
                v-for="(warehouses, index) in warehousePaginate"
                :key="index"
              >
                <div class="warehouse_contents">
                  <p class="warehouse_no">Warehouse {{ index + 1 }}</p>
                  <p><span>Address:</span> {{ warehouses.address }}</p>
                  <p>
                    <span>Location:</span>{{ warehouses.location }},
                    {{ warehouses.state }}
                  </p>

                  <p>
                    <span class="me-3"><strong>Size</strong>:</span
                    >{{ warehouses.size }}
                    <span>SQM</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <nav class="pagination align-items-center gap-2 my-4">
            <button
              :disabled="currentPageWarehouse === 1"
              class="btn btn-succ fs-small"
              @click="prevPageWarehouse"
            >
              Previous
            </button>
            <span class="fs-small"
              >Page {{ currentPageWarehouse }} of
              {{ totalPagesWarehouse }}</span
            >
            <button
              :disabled="c === totalPagesWarehouse"
              class="btn btn-succ fs-small"
              @click="nextPageWarehouse"
            >
              Next
            </button>
          </nav>
        </div>

        <!-- !Products -->
        <div id="product" class="accordion description_accordion">
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Products
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="overvew_container">
                  <!--  *inputs-->
                  <template v-if="profileData.businessType !== 'merchant'">
                    <h2 class="overview">Inputs</h2>
                    <div class="input_main_wrapper">
                      <div class="inputs_wrapper">
                        <!-- *inputs images card -->
                        <div
                          v-for="(input, index) in inputs.slice(0, 6)"
                          :key="index"
                          class="inputs_images_card"
                          :class="[input.stock < 1 ? 'fad' : '']"
                        >
                          <div class="input_image_container">
                            <img
                              :src="JSON.parse(input.images)[0]"
                              alt="Product image"
                              class="img-fluid w-100"
                            />
                          </div>
                          <div class="Input_card_body">
                            <div class="product_title">
                              {{ input.subcategory.name }}
                            </div>
                            <p
                              class="product_content"
                              v-html="input.description"
                            ></p>
                            <p class="input_footer">
                              <span>{{ input.currency }}</span
                              >{{ input.price }}/kg
                            </p>
                          </div>
                        </div>
                      </div>
                      <!-- view all button -->
                      <a class="view_all_btn" @click="viewAllProduct('inputs')"
                        >View All</a
                      >
                      <hr />
                    </div>
                  </template>
                  <!-- *crops -->
                  <h2 class="overview crop_header">Crops</h2>
                  <div class="crop_main_wrapper">
                    <div class="crop_wrapper">
                      <div
                        v-for="(crop, index) in crops.slice(0, 6)"
                        :key="index"
                        class="crop_details"
                      >
                        <span
                          class="color_specification"
                          :class="[crop.specification.qty < 1 ? 'fad' : '']"
                          >{{ crop.subcategory.name }} -
                          {{ crop.specification.color }}
                          <span
                            v-if="crop.specification.qty < 1"
                            class="text-danger fw-bold d-block"
                          >
                            Sold Out
                          </span>
                        </span>
                        <span
                          class="delivery_window"
                          :class="[crop.specification.qty < 1 ? 'fad' : '']"
                          >{{ crop.warehouse_address }}</span
                        >
                        <span
                          class="category"
                          :class="[crop.specification.qty < 1 ? 'fad' : '']"
                          ><b>{{ crop.category.name }}</b></span
                        >
                        <span class="user_name">
                          <p :class="[crop.specification.qty < 1 ? 'fad' : '']">
                            {{
                              userAccount.accountType === "individual"
                                ? product.user.first_name
                                : userAccount.accountType === "company"
                                  ? product.user.company.company_name
                                  : userAccount.default
                            }}
                          </p>
                        </span>
                        <span
                          class="price_specification"
                          :class="[crop.specification.qty < 1 ? 'fad' : '']"
                          ><b
                            >{{ crop.currency }}
                            {{ crop.specification.price }}/{{
                              crop.specification.test_weight.toUpperCase()
                            }}</b
                          ></span
                        >
                        <span
                          class="location"
                          :class="[crop.specification.qty < 1 ? 'fad' : '']"
                          >{{ crop.user.state }}</span
                        >
                        <span
                          :class="[
                            'view_crop',
                            crop.specification.qty < 1 ? 'bg-danger' : 'green',
                          ]"
                          @click="
                            $router.push({
                              name: 'CropDetails',
                              params: { id: crop.id },
                            })
                          "
                          >View</span
                        >
                      </div>
                    </div>
                    <!-- view all button -->
                    <a class="view_all_btn" @click="viewAllProduct('crops')"
                      >View All</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- !Photos and Videos using warehouse class names-->

        <div id="photos" class="warhouse_wrapper_carousel">
          <div class="warehouse_wrapper">
            <h2>Photos & Videos</h2>
            <!-- !using inputs images card class names -->

            <div class="inputs_wrapper">
              <div
                v-for="(mediaData, index) in mediaPaginate"
                :key="index"
                class="inputs_images_card"
              >
                <div class="input_image_container">
                  <img
                    v-if="mediaData.type === 'image'"
                    :src="mediaData.url"
                    alt="Product image"
                    class="img-fluid"
                  />
                  <video
                    v-if="mediaData.type === 'video'"
                    :src="mediaData.url"
                    controls
                    autoplay
                    class="video-fit-cover"
                  ></video>
                </div>
                <div class="Input_card_body">
                  <div class="product_title">{{ mediaData.title }}</div>
                  <p class="product_content">{{ mediaData.description }}</p>
                </div>
              </div>
            </div>
            <nav class="pagination align-items-center gap-2 my-4">
              <button
                :disabled="currentPageMedia === 1"
                class="btn btn-succ fs-small"
                @click="prevPageMedia"
              >
                Previous
              </button>
              <span class="fs-small"
                >Page {{ currentPageMedia }} of {{ totalPagesMedia }}</span
              >
              <button
                :disabled="currentPageMedia === totalPagesMedia"
                class="btn btn-succ fs-small"
                @click="nextPageMedia"
              >
                Next
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import MarketPlaceService from "@/services/marketplace";
import Preloader from "@/layouts/shared/Preloader.vue";
export default {
  name: "CompanyProfile",
  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      crops: [],
      inputs: [],
      avatar: "",
      product: "",
      userAccount: {
        accountType: "",
        default: "User",
      },
      // Data for profile sections
      profileData: {
        description: "",
        businessType: "",
        has_warehouse: "",
        revenue: "",
        export_experience: "",
        certifications: "",
      },
      isFixed: false,
      WarehouseDetails: [],
      certificateList: [],
      // pagination
      currentPage: 1,
      currentPageMedia: 1,
      currentPageWarehouse: 1,
      itemsPerPage: 8,
      allMedia: [],
    };
  },
  computed: {
    certificatePaginate() {
      const sortCertificateList = this.certificateList;
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return sortCertificateList.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.certificateList.length / this.itemsPerPage);
    },
    warehousePaginate() {
      const sortWarehouseList = this.WarehouseDetails;
      const startIndex = (this.currentPageWarehouse - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return sortWarehouseList.slice(startIndex, endIndex);
    },
    totalPagesWarehouse() {
      return Math.ceil(this.WarehouseDetails.length / this.itemsPerPage);
    },
    mediaPaginate() {
      const sortMediaList = this.allMedia;
      const startIndex = (this.currentPageMedia - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return sortMediaList.slice(startIndex, endIndex);
    },
    totalPagesMedia() {
      return Math.ceil(this.allMedia.length / this.itemsPerPage);
    },
  },
  created() {
    this.getProduct();
  },
  methods: {
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPageMedia() {
      if (this.currentPageMedia > 1) {
        this.currentPageMedia--;
      }
    },
    nextPageMedia() {
      if (this.currentPageMedia < this.totalPagesMedia) {
        this.currentPageMedia++;
      }
    },
    prevPageWarehouse() {
      if (this.currentPageWarehouse > 1) {
        this.currentPageWarehouse--;
      }
    },
    nextPageWarehouse() {
      if (this.currentPageWarehouse < this.totalPagesWarehouse) {
        this.currentPageWarehouse++;
      }
    },
    getProduct() {
      MarketPlaceService.getCropById(this.$route.query.cropId, (response) => {
        this.product = response.data;
        if (this.product.user) {
          this.crops = this.product.user.crops; // store all crop for sale and wanted product listings
          this.inputs = this.product.user.inputs; // store all inputs product listings
          this.allMedia = this.product.user.media; // fetch all media
          // check for auction product
          if (this.product.user.type == "auction") {
            this.productType = "auction";
          }
          // fetch all  warehouse details
          this.WarehouseDetails = JSON.parse(this.product.user.locations);
          this.certificateList = JSON.parse(this.product.user.certifications);
          // call the account name function
          this.accountName();
          // call the update Data method
          this.updateData();
        } else {
          return;
        }
      });
    },
    /**
     * Determines and returns the user or company name based on the user account type.
     * If the account type is identified as 'individual,' the user's name is returned.
     * If the account type is identified as 'company,' the company name is returned.
     * If no account type is identified, the default account name is returned.
     *
     * @returns {string} The user or company name based on the account type.
     */
    accountName() {
      // Check if it's an individual account
      if (this.product.user.company == null) {
        this.userAccount.accountType = "individual";
      }

      // Check if it's a company account
      if (this.product.user.company != null) {
        this.userAccount.accountType = "company";
      }
    },
    /**
     * Updates the profile data with optimized values .
     */
    updateData() {
      /* ------------------------------ profile image ----------------------------- */
      this.avatar = this.product.user.image || false;
      /* --------------------------- Profile description -------------------------- */
      const descElement = this.product.user.company.company_description;
      const vElement = document.createElement("div");
      vElement.innerHTML = descElement;
      if (vElement.innerText == "") {
        // Set account type to individual if description is empty
        this.userAccount.accountType = "individual";
      } else {
        // Set profile description if not empty
        this.profileData.description = vElement.innerText;
      }
      /* ------------------------- OTHER PROFILE OVERVIEW ------------------------- */
      // Set business type
      this.profileData.businessType = this.product.user.type;
      // Assign revenue with fallback to "N/A"
      this.profileData.revenue =
        this.product.user.company.annual_sales_revenue || "N/A";
      // Determine warehouse status
      this.profileData.has_warehouse =
        this.product.user.locations !== null ? "Yes" : "No";
      // Determine export experience
      this.profileData.export_experience =
        this.product.user.company.export_experience !== null ? "Yes" : "No";
      // Determine certification status
      this.profileData.certifications =
        this.product.user.certification !== null ? "Yes" : "No";
    },
    viewAllProduct(view) {
      if (view === "crops") {
        this.$router.push({
          name: "CompanyProductCrops",
          query: { id: this.product.id },
        });
      } else {
        this.$router.push({
          name: "CompanyProductInputs",
          query: { id: this.product.id },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.fad-opacity {
  opacity: 0.5;
}
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.big-container2 {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.big-content {
  width: 100%;
  padding: 100px;
  position: relative;
  overflow: auto;
}

.profile_image {
  width: 184px;
  height: 172px;

  img {
    border-radius: 10px;
  }
}

.company_profile_card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  border-radius: 6px;
  padding: 17px 26px;
}
.profile_image {
  background: #fff;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  img {
    border-radius: 20px;
  }
}
// right
.profile_details {
  flex: 1;
  gap: 15px;
  display: flex;
  flex-direction: column;
  align-self: baseline;
  background: #fff;
  padding: 15px 25px;
  border-radius: 15px;
}

.company_name span {
  color: #4a4754;
  font-family: Poppins;
  font-size: 33px;
  font-style: normal;
  font-weight: 700;
}

.company_details_rowOne {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 37px;
}
.country {
  display: flex;
  justify-content: center;
  align-items: center;
}
.country_name {
  color: #14110c;
  font-family: Maven Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.2px;
}

.no_Of_employees {
  font-weight: 700;
}

.employees {
  color: #14110c;
  font-family: Maven Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.2px;
}

.verification_level {
  padding: 10px;
  border-radius: 13px;
  background: #0065ff;
  // for the text
  color: #fff;
  font-family: Maven Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.company_details_rowTwo {
  @extend .company_details_rowOne;
}

.verification_status {
  color: #14110c;
  font-family: Maven Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.2px;
}

.crop_focus {
  color: #14110c;
  font-family: Maven Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.2px;

  span {
    font-weight: 700;
  }
}

.tabs {
  margin-top: 80px;
  display: flex;
  justify-content: flex-start;
  width: fit-content;
  gap: 30px;
  border-radius: 8.91px;
  background: #ffff;
  padding: 14px 35px;
  align-items: center;
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  z-index: 100; /* adjust this value based on your layout */

  span {
    text-align: center;
    color: #262c3f6e;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
}
.tabs > a {
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;
  span {
    flex: 1;
    &:hover {
      color: darken(#262c3f6e, 40%);
    }
  }
}
.description span {
  color: #696671;
}
.description_accordion {
  margin-top: 40px;

  button {
    color: #696671;

    /* Headline/24px */
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0.24px;
  }
}

.description_content {
  color: #4a4754;
  text-align: justify;
  font-family: Maven Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.16px;
}

.overview {
  color: #696671;

  /* Headline/24px */
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.24px;
}

.overview_contents_wrapper {
  display: flex;
  padding: 10px 20px;
  align-items: flex-start;
  gap: 100px;
  background: #f5f5f5;

  > div {
    color: #696671;
    font-family: Maven Pro;
    font-size: 16px;
    font-style: normal;

    span {
      font-weight: 800;
    }
  }
}

.overview_right > div,
.overview_left > div {
  margin-bottom: 33px;
}

// certificate session
.certification_wrapper_carousel {
  padding: 33px 32px;
  border-radius: 6px;
  background: #fff;
  margin-top: 80px;
}

.certificate_wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 32px;
  gap: 32px;
  flex: wrap;
}

.certificate_main h2 {
  color: #696671;

  /* Headline/24px */
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.24px;
}

.certificate_image_conatiner {
  width: 220px;
  height: 220px;
}
.certificate_image_conatiner img {
  width: 100%;
  height: 100%;
}
.certificate_container {
  border-radius: 3px;
  border: 1px solid rgba(19, 94, 0, 0.54);
  padding: 10px;
}
p.certifiacte_info {
  color: #696671;
  font-family: Maven Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 116%;
  transform: translate(10px, 10px);
}

.warhouse_wrapper_carousel {
  padding: 33px 32px;
  border-radius: 6px;
  background: #fff;
  margin-top: 80px;
}

.warehouse_wrapper h2 {
  color: #696671;

  /* Headline/24px */
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.24px;
}

.warehouse_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 32px;
  margin-top: 32px;
}

.warehouse_contents {
  border-radius: 4px;
  border: 1px solid #6ed19a;
  padding: 11px 14px;
}

.warehouse_no {
  color: #047b38;
  font-family: Maven Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 116%;
}

.warehouse_contents p:not(:nth-of-type(1)) {
  color: #696671;
  font-family: Maven Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 116%;

  span {
    font-weight: 800;
  }
}

.inputs_images_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 9px 4px;
  border-radius: 10px;
  border: 1px solid #fff;
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.15);
  width: 280px;

  > div {
    width: 90%;
  }
}
.input_image_container {
  max-height: 200px;
  img,
  video {
    height: 100%;
    width: 100%;
  }
}
.input_card_body > * {
  margin-bottom: 5px;
}

.inputs_wrapper {
  display: flex;
  padding: 10px 20px;
  // justify-content: center;
  margin-top: 20px;
  gap: 20px;
  flex-wrap: wrap;
}

.product_title {
  color: #4a4754;

  /* Headline/18px */
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.18px;
}

.product_content {
  color: #4a4754;

  /* Body/regular/12px */
  font-family: Maven Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.48px;
}

.input_footer {
  color: #4a4754;
  font-family: Maven Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.16px;
}

.view_all_btn {
  margin: 20px auto 45px auto;
  display: flex;
  width: 246px;
  height: 45px;
  justify-content: center;
  align-items: center;
  color: #e6f7ee;
  text-align: center;

  /* Body/bold/16px */
  font-family: Maven Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  border-radius: 4px;
  background: #05b050;
  box-shadow:
    0px 4px 8px 0px rgba(44, 39, 56, 0.08),
    0px 2px 4px 0px rgba(44, 39, 56, 0.08);
  &:hover {
    background-color: darken(#05b050, 10%);
    color: white;
  }
}

hr {
  width: 90%;
  height: 2px;
  background: rgba(0, 0, 0, 0.3);
  margin-inline: auto;
}

.crop_wrapper {
  display: flex;
  flex-direction: column;
  gap: 27px;
}

.crop_header {
  margin-block: 30px 20px;
}

.crop_details {
  display: flex;
  padding: 10px 25px;
  align-items: center;
  justify-content: space-evenly;
  gap: 26px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.15);
}

.crop_specification,
.delivery_window,
.user_name,
.price_specification,
.category,
.location {
  color: #4a4754;
  font-family: Maven Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 147%;
  text-transform: capitalize;
}

.category,
.location {
  font-weight: 700 !important;
}

.view_crop {
  color: #e6f7ee;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Maven Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  cursor: default;
  width: 100px;
  height: 36px;
  border-radius: 4px;
  background: #05b050;
  box-shadow:
    0px 4px 8px 0px rgba(44, 39, 56, 0.08),
    0px 2px 4px 0px rgba(44, 39, 56, 0.08);
}
.green {
  background: #05b050;
  &:hover {
    background: darken(#05b050, 10%);
  }
}
.bg-danger {
  background: darken(rgb(220, 53, 69), 10%);
}
.btn-succ {
  background: #05b050;
  color: white;

  &:hover {
    background: darken(#05b050, 10%);
  }
}

.fs-small {
  font-size: 14px;
}
.video-fit-cover {
  max-width: 100%;
  height: auto;
}
</style>
