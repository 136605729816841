<template>
  <DefaultNav>
    <div class="big-container">
      <div class="welcome-text">
        <h2>Loan Request</h2>
      </div>

      <div class="contents">
        <form action="">
          <div class="form-group">
            <label>Request Amount</label>
            <input
              v-model="amount"
              type="text"
              class="form-control"
              placeholder="Enter Loan amount"
            />
          </div>
          <div class="row">
            <div class="form-group col-md-6">
              <label for="inputEmail4">Loan Term</label>
              <select id="" v-model="duration" name="" class="form-control">
                <option>1 year</option>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label for="inputPassword4">Monthly Repayment</label>
              <input
                v-model="monthly_repayment"
                type="text"
                class="form-control green-inp"
                placeholder="NGN 30000"
                disabled
              />
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6">
              <label for="inputEmail4">Interest Rate</label>
              <input
                v-model="interest_rate"
                type="text"
                class="form-control green-inp"
                placeholder="Interest Rate"
                disabled
              />
            </div>
            <div class="form-group col-md-6">
              <label for="inputPassword4">Repayment Penalty</label>
              <input
                v-model="repayment_penalty"
                type="text"
                class="form-control green-inp"
                placeholder="Repayment Penalty"
                disabled
              />
            </div>
          </div>
          <div class="form-group">
            <label for="inputAddress2">Total Payable</label>
            <input
              v-model="total_payable"
              type="text"
              class="form-control green-inp"
              placeholder="Total Payable"
              disabled
            />
          </div>
          <button type="button" class="btn" @click="addLoanRequest()">
            Request For Loan
          </button>
        </form>
      </div>
    </div>
  </DefaultNav>
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import MarketplaceService from "@/services/marketplace";
import Alert from "@/utilities/alert.js";

export default {
  name: "MyProducts",
  components: {
    DefaultNav,
  },
  data() {
    return {
      amount: "",
      duration: "",
    };
  },
  mounted() {
    this.isLoading = true;
  },
  methods: {
    addLoanRequest() {
      MarketplaceService.addLoanRequest(
        {
          amount: this.amount,
          duration: this.duration,
        },
        (response) => {
          if (response && response.error == false) {
            Alert.success({
              message: "Loan Request submitted successfully",
            });
          } else if (response && response.error) {
            Alert.error({
              message: response.message,
            });
          }
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
}

.welcome-text {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #262c3f;
  color: white;
  padding: 14px 30px;
  width: 100%;

  h2 {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: white;
  }

  p {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4754;
  }

  span {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #05b050;
  }
}

.contents {
  width: 100%;
  padding: 38px;
  display: flex;
  justify-content: center;

  form {
    margin-top: 50px;
    background-color: white;
    width: 70%;
    padding: 50px;

    label {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 300;
      color: #1e2228e5;
    }
    .green-inp {
      background: #a3cf73;
    }
    .green-inp::placeholder {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      color: black !important;
    }
    button {
      width: 100%;
      color: white;
      background-color: #05b050;
      height: 50px;
      margin-top: 20px;
      font-family: Inter;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
</style>
