<template>
  <DefaultNav>
    <div class="big-container">
      <div class="big-content">
        <div class="big-box">
          <div class="details">
            <div class="top-details">
              <div class="each-detail">
                <h5>Name</h5>
                <p>Sayed Faroun Koun</p>
              </div>
              <div class="each-detail">
                <h5>Email</h5>
                <p>sayedkoun@gmail.com</p>
              </div>
              <div class="each-detail">
                <h5>Dvs Score</h5>
                <p class="blue-score">91%</p>
              </div>
            </div>
            <div class="bottom-details">
              <div class="each-detail">
                <h5>Account Number</h5>
                <p>5576455343</p>
              </div>
              <div class="each-detail">
                <h5>Pds Score</h5>
                <p class="yellow-score">91%</p>
              </div>
              <div class="each-detail">
                <h5>Aggregate</h5>
                <p>45.8%</p>
              </div>
            </div>
          </div>
          <form>
            <div class="form-group">
              <label for="">Amount</label>
              <input
                id="formGroupExampleInput"
                v-model="amount"
                type="text"
                class="form-control"
                required
                placeholder="300,000"
              />
            </div>
            <div class="row">
              <div class="col">
                <label for="">Loan Term</label>
                <select id="" name="" class="form-control">
                  <option value="">5years</option>
                </select>
              </div>
              <div class="col">
                <label for="">Monthly Repayment</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="NGN 30,000"
                />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label for="">Interest rate</label>
                <select id="" name="" class="form-control">
                  <option value="">4%</option>
                </select>
              </div>
              <div class="col">
                <label for="">Repayment Penalty</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="NGN 30,000"
                />
              </div>
            </div>

            <div class="buttons">
              <div class="form-group">
                <a
                  href="/dashboard/disbursesuccessful"
                  type="button"
                  class="btn btn-lg green-btn btn-block btn-hover-state"
                >
                  Push Disburse Request
                </a>
              </div>
            </div>
            <!-- <p class="last-p">Your personal data will be used to process your order, support your experience throughout
                       this website, and for other purposes described in our privacy policy.</p> -->
          </form>
        </div>
      </div>
    </div>
  </DefaultNav>
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import MarketplaceService from "@/services/marketplace";
import Alert from "@/utilities/alert";

export default {
  name: "SingleDisburse",
  components: {
    DefaultNav,
  },
  data() {
    return {
      userData: this.$store.state.user,
      amount: "",
      code: "",
    };
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  @include breakpoint-between(md, lg) {
    width: 60.5%;
  }

  @include breakpoint-between(lg, xl) {
    width: 69.5%;
  }

  @include breakpoint-between(xl, xxl) {
    width: 76%;
  }
}

.big-content {
  padding: 100px 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f1f1f1;

  .big-box {
    width: 80%;
    padding: 35px 50px;
    background: #ffffff;
    border-radius: 4px;

    .details {
      width: 100%;
      background-color: rgba(251, 138, 46, 0.1);
      padding: 30px;

      .blue-score {
        font-family: Segoe UI;
        font-size: 28px;
        font-weight: 700;
        color: rgba(0, 101, 255, 1);
      }

      .yellow-score {
        font-family: Segoe UI;
        font-size: 28px;
        font-weight: 700;
        color: rgba(250, 185, 0, 1);
      }

      .top-details {
        display: flex;

        .each-detail {
          width: 33%;

          h5 {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 700;
          }
          p {
            font-family: Maven Pro;
            font-size: 19px;
            font-weight: 400;
          }
        }
      }
      .bottom-details {
        display: flex;
        margin-top: 30px;

        .each-detail {
          width: 33%;

          h5 {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 700;
          }
          p {
            font-family: Maven Pro;
            font-size: 19px;
            font-weight: 400;
          }
        }
      }
    }
  }

  form {
    hr {
      margin-bottom: 40px;
    }

    a {
      margin-top: 30px;
    }

    .check-area {
      display: flex;
      align-items: center;

      label {
        margin-left: 10px;
      }
    }
    .last-p {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #b5b4b9;
      margin-top: 23px;
    }
  }

  .payment-type {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 50px;
  }
  .each-radio {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}
</style>
