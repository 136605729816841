<template>
  <DefaultNav>
    <div class="big-container">
      <!-- header for oder tracking  -->
      <div class="page-header d-flex justify-content-center align-items-center">
        <h1>Order Tracking</h1>
      </div>
      <!--body container -->
      <div class="transaction-sumary-container d-flex flex-row">
        <!-- left -->
        <div class="left-container">
          <div v-if="order" class="left-container-wrapper position-relative">
            <h2>Order Number: #{{ order.order_hash }}</h2>
            <!-- progress bar -->
            <div
              v-if="trackingDetails"
              class="progress-bar-wrapper d-flex flex-column w-100 position-relative"
            >
              <div class="progress progress-outer">
                <div
                  class="progress progress-inner"
                  :style="'width : ' + orderProgress + '%'"
                />
              </div>
              <div class="circle d-flex w-100 position-absolute">
                <div class="circle-1 circle-main">
                  <span />
                </div>
                <div
                  v-for="(tracking, index) in trackingDetails.transit"
                  :key="index"
                  class="circle-2 circle-main"
                >
                  <span />
                </div>
                <div class="circle-4 circle-main">
                  <span />
                </div>
              </div>
              <!--levels-->
              <div class="progress-level-container d-flex">
                <!-- levels -->
                <div class="levels level-1">
                  <h3>Pickup location</h3>
                  <p>{{ trackingDetails.pickup_location }}</p>
                </div>
                <div
                  v-for="(tracking, index) in trackingDetails.transit"
                  :key="index"
                  class="levels level-2"
                >
                  <h3>{{ tracking.status }}</h3>
                  <p>{{ tracking.location }}</p>
                </div>
                <div class="levels level-3">
                  <h3>Delivered</h3>
                  <p>
                    {{ trackingDetails.delivery_location }}
                  </p>
                </div>
              </div>
            </div>

            <!-- table -->
            <h4>Tracking Details</h4>
            <table v-if="trackingDetails" class="table table-borderless">
              <thead>
                <tr>
                  <th class="text-left">Date</th>
                  <th class="text-center">Location</th>
                  <th class="text-right">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(tracking, index) in trackingDetails.transit"
                  :key="index"
                >
                  <td class="text-left">
                    {{ tracking.date }}
                  </td>
                  <td class="text-center">
                    {{ tracking.location }}
                  </td>
                  <td class="text-right">
                    {{ tracking.status }}
                  </td>
                </tr>
              </tbody>
            </table>
            <template v-if="order">
              <a v-if="orderProgress != 100" class="btn payment-status"
                >Payment Status:
                {{
                  order.payment_status == "UNPAID"
                    ? "Pending"
                    : order.payment_status == "PARTIALLY_PAID"
                      ? "Partial"
                      : order.payment_status == "PENDING"
                        ? "awaiting confirmation"
                        : "Paid"
                }}</a
              >
              <a
                v-if="orderProgress == 100 && order.payment_status == 'PAID'"
                id="payment-state"
                class="btn payment-status"
                >Payment Status: <strong>Completed</strong></a
              >
              <a
                v-if="isSeller"
                href="/dashboard/wallet"
                class="btn payment-status wallet d-inline-block position-absolute"
                >Go to wallet</a
              >
              <!-- for corporates view -->
              <p
                v-if="
                  isBuyer &&
                  order.waybill_details &&
                  !order.receipt_note &&
                  !order.dispute
                "
                class="dispute-text"
              >
                If you have received the goods and you are ok with what you
                received, please confirm delivery below and close out on the
                transaction, click Confirm Delivery
              </p>
              <a
                v-if="
                  isBuyer &&
                  order.waybill_details &&
                  !order.receipt_note &&
                  !order.dispute
                "
                class="btn payment-status wallet d-block w-100"
                @click="goodsReceipt()"
                >Confirm Delivery</a
              >
              <p
                v-if="isBuyer && order.receipt_note && order.waybill_details"
                class="confirmed-text"
              >
                Order Confirmed
              </p>

              <p v-if="order.payment_status == 'PENDING'">
                Your payment request has been submitted and now awaits
                verification from the Zowasel team.
              </p>
              <a
                v-if="
                  isBuyer &&
                  (order.payment_status == 'PARTIALLY_PAID' ||
                    (order.payment_option == 'after_delivery' &&
                      order.payment_status == 'UNPAID' &&
                      order.receipt_note))
                "
                id="paymentBtn"
                class="btn payment-status wallet d-block w-100"
                @click="showPayBtn()"
              >
                Payment Options
              </a>
              <a
                v-if="
                  isBuyer &&
                  payBtn == true &&
                  (order.payment_status == 'PARTIALLY_PAID' ||
                    (order.payment_option == 'after_delivery' &&
                      order.payment_status == 'UNPAID' &&
                      order.receipt_note))
                "
                :href="'/dashboard/marketplace/payments/' + order.order_hash"
                class="btn payment-status wallet d-block w-100"
              >
                Make Payment Now
              </a>
              <a
                v-if="
                  isBuyer &&
                  payBtn == true &&
                  (order.payment_status == 'PARTIALLY_PAID' ||
                    (order.payment_option == 'after_delivery' &&
                      order.payment_status == 'UNPAID' &&
                      order.receipt_note))
                "
                class="btn payment-status wallet d-block w-100"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Paid already
              </a>
              <p
                v-if="
                  isBuyer &&
                  order.waybill_details &&
                  !order.dispute &&
                  !order.receipt_note
                "
                class="dispute-text go-downn"
              >
                If specification differs from delivery,click the button below to
                review price
              </p>
              <a
                v-if="
                  isBuyer &&
                  waybillDetails &&
                  !order.dispute &&
                  !order.receipt_note
                "
                v-show="showDispute"
                id="dispute-btn"
                class="btn red-btn wallet d-block w-100"
                @click="
                  $router.push({
                    name: 'Dispute',
                    params: { id: order.order_hash },
                  })
                "
                >Open Price Reconciliation</a
              >
              <p
                v-if="
                  isSeller &&
                  order.dispute &&
                  !order.dispute.reconciliation_price
                "
                class="dispute-text"
              >
                The Buyer has filed a complaint based on the goods received, and
                has requested a price reconcilliation.
              </p>
              <p
                v-if="
                  isBuyer &&
                  order.dispute &&
                  !order.dispute.reconciliation_price
                "
                class="dispute-text"
              >
                your reconcilliation request is awaiting final decision from the
                Zowasel Team
              </p>
              <p
                v-if="
                  isBuyer &&
                  order.dispute &&
                  order.dispute.reconciliation_price &&
                  order.payment_status == 'UNPAID'
                "
                class="dispute-text"
              >
                Your reconcilliation request has been attended to and the
                updated price for payment is
                <strong>NGN{{ order.dispute.reconciliation_price }}</strong
                >. <br />
                Click Button below to proceed
              </p>
              <a
                v-if="
                  isBuyer &&
                  order.dispute &&
                  order.dispute.reconciliation_price &&
                  order.payment_status == 'UNPAID'
                "
                :href="'/dashboard/marketplace/payments/' + order.order_hash"
                class="btn payment-status wallet d-block w-100"
              >
                Proceed to Pay
              </a>
              <p
                v-if="
                  order.dispute &&
                  order.dispute.reconciliation_price &&
                  order.payment_status == 'PAID'
                "
                class="dispute-text"
              >
                Your reconcilliation request has been attended to and the
                updated price is
                <strong>NGN{{ order.dispute.reconciliation_price }}</strong
                >. <br />
                You will have a refund credited to your account shortly
              </p>
              <a
                v-if="isSeller && order.shipping_status !== 'DELIVERED'"
                class="btn payment-status wallet d-block w-100"
                @click="updateShipping()"
                >Update Tracking</a
              >
            </template>
          </div>
        </div>
        <!-- right -->
        <div v-if="stage == 'confirm'" class="right-container">
          <div class="right-container-wrapper">
            <div id="section-to-download">
              <h1>Waybill details</h1>
              <hr />
              <div v-if="!waybillDetails" class="container text-center">
                <p>No waybill details available yet</p>
              </div>
              <div v-if="waybillDetails" class="zowasel-receipt">
                <div class="receipt-wrapper">
                  <!-- logo -->
                  <div class="logo-wrapper">
                    <img
                      class="img-fluid"
                      src="@/assets/images/logos/zowasel-grey-logo.png"
                      alt="zowasel-logo"
                    />
                  </div>
                  <!-- address -->
                  <address>
                    <p>3B, Abba Sagoe Street, Otedola Estate</p>
                    <p>Omole Phase 2, . Lagos. Nigeria</p>
                    <p>Tel: 01 342 4403</p>
                    <p>Waybil Number:</p>
                  </address>
                  <div v-if="order" class="form-wrapper">
                    <!-- grid -->
                    <div v-if="waybillDetails" class="container text-center">
                      <div class="row">
                        <div class="col row-item-1">DISPATCH SECTION</div>
                      </div>
                      <div class="row row-main">
                        <div class="col col-item-1">
                          FROM: {{ waybillDetails.dispatch_section.from }}
                        </div>
                        <div class="col-6 col-item-2">
                          To: {{ waybillDetails.dispatch_section.to }}
                        </div>
                      </div>
                      <div class="row row-main">
                        <div class="col-4 col-item-1">
                          DATE: {{ waybillDetails.dispatch_section.date }}
                        </div>
                        <div class="col-4 col-item-2">
                          COSIGNEE:
                          {{ waybillDetails.dispatch_section.cosignee }}
                        </div>
                        <div class="col-4 col-item-2">
                          Truck No./Trailer No:
                          {{ waybillDetails.dispatch_section.truck_number }}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4 row-item-general">S/No.</div>
                        <div class="col-4 row-item-general">DESCRIPTION</div>
                        <div class="col-4 row-item-general">QUANTITY</div>
                      </div>
                      <div
                        v-for="(item, index) in waybillDetails.dispatch_section
                          .items"
                        :key="index"
                        class="row"
                      >
                        <div class="col-4 row-item-general">
                          {{ index + 1 }}
                        </div>
                        <div class="col-4 row-item-general">
                          {{ item.subcategory.name }}
                        </div>
                        <div class="col-4 row-item-general">
                          {{ item.specification.qty }}
                        </div>
                      </div>
                      <div class="row row-main">
                        <div class="col col-item-1">
                          REMARKS: {{ waybillDetails.dispatch_section.remarks }}
                        </div>
                      </div>
                      <div class="row row-main">
                        <div class="col col-item-1">
                          Driver's Name:
                          {{
                            waybillDetails.dispatch_section.drivers_data
                              .drivers_name
                          }}
                        </div>
                        <div class="col-6 col-item-2">
                          Driver's Number:
                          {{
                            waybillDetails.dispatch_section.drivers_data
                              .drivers_number
                          }}
                        </div>
                      </div>
                      <div class="row row-main">
                        <div class="col col-item-1">
                          Seller's Name:
                          {{
                            waybillDetails.dispatch_section.sellers_data
                              .sellers_representative
                          }}
                        </div>
                        <div class="col-6 col-item-2">
                          Seller's Representative No:
                          {{
                            waybillDetails.dispatch_section.sellers_data
                              .sellers_representative_number
                          }}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 row-item-general">
                          Driving License/Permit #:
                          {{
                            waybillDetails.dispatch_section.drivers_data
                              .driving_license
                          }}
                        </div>
                        <div class="col-6 row-item-general">
                          Title:
                          {{
                            waybillDetails.dispatch_section.sellers_data.title
                          }}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 row-item-general">
                          Signature and Date:
                          {{
                            formatDate(
                              waybillDetails.dispatch_section.drivers_data.date,
                            )
                          }}
                        </div>
                        <div class="col-6 row-item-general">
                          Signature and Date:
                          {{
                            formatDate(
                              waybillDetails.dispatch_section.sellers_data.date,
                            )
                          }}
                        </div>
                      </div>
                      <!-- <div class="row">
                      <div class="col row-item-1">RECEIPT SECTION</div>
                    </div>
                    <div class="row">
                      <div class="col-4 row-item-general">S/No.</div>
                      <div class="col-4 row-item-general">DESCRIPTION</div>
                      <div class="col-4 row-item-general">QUANTITY</div>
                    </div>
                    <div
                      v-for="(item, index) in waybillDetails.dispatch_section
                        .items"
                      :key="index"
                      class="row"
                    >
                      <div class="col-4 row-item-general">
                        {{ index + 1 }}
                      </div>
                      <div class="col-4 row-item-general">
                        {{ item.title }}
                      </div>
                      <div class="col-4 row-item-general">
                        {{ item.specification.qty }}
                      </div>
                    </div>
                    <div class="row row-main">
                      <div class="col col-item-1">
                        REMARKS: {{ waybillDetails.receipt_section.remarks }}
                      </div>
                    </div>
                    <div class="row row-main">
                      <div class="col-6 col-item-2">
                        Seller's Representative:
                        {{
                          waybillDetails.receipt_section.sellers_data
                            .sellers_representative
                        }}
                      </div>
                      <div class="col col-item-1">
                        Received by:{{
                          waybillDetails.receipt_section.recipient_data
                            .received_by
                        }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 row-item-general">
                        Title:
                        {{ waybillDetails.receipt_section.sellers_data.title }}
                      </div>
                      <div class="col-6 row-item-general">
                        Title:
                        {{
                          waybillDetails.receipt_section.recipient_data.title
                        }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 row-item-general">
                        Signature and Date:
                        {{
                          formatDate(
                            waybillDetails.receipt_section.sellers_data.date,
                          )
                        }}
                      </div>
                      <div class="col-6 row-item-general">
                        Signature and Date:
                        {{
                          formatDate(
                            waybillDetails.receipt_section.recipient_data.date,
                          )
                        }}
                      </div>
                    </div> -->
                      <p class="way-bill mt-5 mb-4 container">
                        The <strong>way bill</strong> puts the person in charge
                        of the conveyance carrying the consignment of said
                        amount of goods to the stated destination. It is a
                        mandatory document that is generated from zowasel portal
                        by registered sellers or transporters who undertake
                        movement of commodities and must be presented at point
                        of delivery in the corporate.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="waybillDetails" class="position-relatives">
              <div class="btn-group w-100 gap-4">
                <div class="download">
                  <a
                    class="btn big-download"
                    type="button"
                    @click="downloadOpt()"
                    >Download</a
                  >
                  <div v-if="downloadFile == true" class="download_container">
                    <button
                      class="download_invoice as_image green-btn"
                      @click="downloadAsImage()"
                    >
                      Image
                    </button>
                    <button
                      class="download_invoice as_pdf green-btn"
                      @click="downloadAsPDF()"
                    >
                      Pdf
                    </button>
                  </div>
                </div>
                <div class="download">
                  <a
                    class="btn big-download"
                    type="button"
                    @click="printSection()"
                    >Print</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <GoodsNotes
          v-if="stage == 'upload-receipt'"
          :save-goods-receipt="saveGoodsReceipt"
          :order="order"
        />
        <GoodsReceipt v-if="stage == 'receipt-uploaded'" :order="order" />
      </div>
    </div>

    <!-- Modal start -->
    <div
      id="exampleModal"
      class="modal fade"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <form
            action=""
            @submit.prevent="updateOrderPayment(order.order_hash)"
          >
            <div class="modal-header">
              <h5 id="exampleModalLabel" class="modal-title">Paid Already</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="confirm-pay">
                <input value="Payment Made" type="checkbox" required />
                <p>I have already made this payment</p>
              </div>
              <h4>Attach supporting document (optional)</h4>
              <input type="file" class="form-control" />
            </div>
            <div class="modal-footer">
              <button
                type="submit"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" class="btn btn-primary">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Modal End -->
  </DefaultNav>
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import Alert from "@/utilities/alert.js";
import MarketPlaceService from "@/services/marketplace";
import GoodsNotes from "@/pages/dashboard/marketPlace/checkout/components/GoodsNotes.vue";
import GoodsReceipt from "@/pages/dashboard/marketPlace/checkout/components/GoodReceipt.vue";
import OrderService from "@/services/order";

export default {
  name: "OrderTracking",
  components: {
    DefaultNav,
    GoodsNotes,
    GoodsReceipt,
  },
  data() {
    return {
      userData: this.$store.state.user,
      orderProgress: 0,
      order: null,
      stage: "confirm",
      payBtn: false,
      downloadFile: false,
    };
  },
  computed: {
    waybillDetails() {
      return this.order ? JSON.parse(this.order.waybill_details) : null;
    },
    trackingDetails() {
      return this.order ? JSON.parse(this.order.tracking_details) : null;
    },
  },
  mounted() {
    this.getOrder(this.$route.params.order);
  },
  methods: {
    showPayBtn() {
      this.payBtn = true;
      // document.getElementById("paymentBtn").style.display = "none";
      document.getElementById("paymentBtn").style.visibility = "hidden";
    },
    downloadOpt() {
      this.downloadFile = !this.downloadFile;
    },
    downloadAsImage() {
      const element = document.getElementById("section-to-download");
      // Create a canvas from the element
      html2canvas(element).then((canvas) => {
        // Convert canvas to image
        const imgData = canvas.toDataURL("image/png");
        // create a temporary link element and trigger download
        const link = document.createElement("a");
        link.href = imgData;
        link.download = "Specification.png";
        link.click();
      });
    },
    downloadAsPDF() {
      const element = document.getElementById("section-to-download");
      // Create a new instance of the html2pdf library
      const opt = {
        margin: 1,
        filename: "section.pdf",
        image: { type: "png", quality: 1 },
        html2canvas: { scale: 2 },
        jsPDF: { format: "a4", orientation: "portrait" },
      };

      html2pdf().set(opt).from(element).save();
    },
    printSection() {
      const sectionToPrint = document.getElementById(
        "section-to-download",
      ).innerHTML;
      const printWindow = window.open("", "_blank");
      printWindow.document.open();
      printWindow.document.write(`
    <html>
      <head>
        <title>Print</title>
      </head>
      <body>
        ${sectionToPrint}
      </body>
    </html>
  `);
      printWindow.document.close();

      printWindow.onload = function () {
        printWindow.print();
        printWindow.onafterprint = function () {
          printWindow.close();
        };
      };
    },
    formatDate(date) {
      var dateObject = new Date(date);
      var date =
        dateObject.getDate() < 10
          ? "0" + dateObject.getDate()
          : dateObject.getDate();
      var month =
        dateObject.getMonth() + 1 < 10
          ? "0" + (dateObject.getMonth() + 1)
          : dateObject.getMonth() + 1;
      return `${date}/${month}/${dateObject.getFullYear()}`;
    },
    getOrder(order) {
      let vm = this;
      MarketPlaceService.getOrder(order, (response) => {
        var order = response.data;
        order.products = JSON.parse(order.products);
        this.order = order;
        this.orderUpdateEvents();
        console.log(order);
        if (order.receipt_note) {
          this.stage = "receipt-uploaded";
        }
        setTimeout(() => {
          vm.calculateOrderProgress();
        }, 500);
      });
    },
    updateOrderPayment(id) {
      MarketPlaceService.updateOrderPayment(id, (response) => {
        if (response && response.error == false) {
          Alert.success({
            message: "Update Order sent successfully",
          });
          setTimeout(location.reload(), 2000);
        } else if (response && response.error) {
          Alert.error({
            message: response.message,
          });
        }
      });
    },
    goodsReceipt() {
      this.stage = "upload-receipt";
      this.showDispute = false;
      // document.getElementById("dispute-text").style.display = "none";
      console.log("ello");
    },
    calculateOrderProgress() {
      if (
        this.trackingDetails.delivered ||
        this.order.shipping_status == "DELIVERED"
      ) {
        this.orderProgress = 100;
        return 0;
      }
      var transitElements = document.querySelectorAll(".circle .circle-main");
      var lastTransitElement = transitElements[transitElements.length - 2];
      var totalProgressLength = document.querySelector(".circle").offsetWidth;
      this.orderProgress =
        (lastTransitElement.offsetLeft / totalProgressLength) * 100;
    },
    saveGoodsReceipt(data) {
      let vm = this;
      OrderService.saveGoodsReceiptNote(
        {
          order: this.order.order_hash,
          goodsReceiptNote: data,
        },
        (response) => {
          if (!response.error) {
            this.order = {
              ...this.order,
              receipt_note: response.data,
            };
            vm.stage = "receipt-uploaded";
          }
        },
      );
    },
    updateShipping() {
      this.$router.push({
        name: "UpdateShipping",
        params: { order: this.$route.params.order },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  @include breakpoint-between(md, lg) {
    min-width: 60.5%;
  }

  @include breakpoint-between(lg, xl) {
    min-width: 69.5%;
  }

  @include breakpoint-between(xl, xxl) {
    min-width: 76%;
  }
}

.page-header {
  width: 100%;
  padding: 14px 30px;
  background: #262c3f;

  h1 {
    @include textStyles(Poppins, 700, 24px, 120%);
    color: #ffffff;
  }
}

.transaction-sumary-container {
  width: 100%;

  .left-container {
    background: #ffffff;
    width: 50%;
    padding-bottom: 50px;

    .left-container-wrapper {
      margin-inline: 8%;
      margin-top: 65px;

      h2 {
        @include textStyles(Poppins, 800, 20px, 32px);
        letter-spacing: 0.01em;
        font-feature-settings: "liga" off;
        color: #0a0d13;
        margin-bottom: 60px;
      }

      .progress-bar-wrapper {
        gap: 15px;

        .progress {
          height: 0.4rem;
        }

        .progress-outer {
          background: #b5b4b9;

          .progress-inner {
            width: 100%;
            background: #05b050;
          }
        }

        .circle {
          justify-content: space-around;
          // margin-left: 4%;
          top: -8px;

          .circle-main {
            width: 20px;
            height: 20px;
            background: #e6f7ee;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              width: 70%;
              height: 70%;
              background: #05b050;
              border-radius: 50%;
            }
          }
        }

        .progress-level-container {
          display: flex;
          justify-content: space-around;

          .levels {
            width: 15%;

            h3 {
              @include textStyles(Poppins, 800, 14.5px, 27px);
              color: rgba(45, 55, 72, 0.6);
              text-align: center;
            }

            p {
              @include textStyles("Maven Pro", 400, 14px, 24px);
              text-align: center;
              color: rgba(45, 55, 72, 0.6);
            }
          }
        }
      }

      h4 {
        @include textStyles("Maven Pro", 800, 18px, 32px);
        text-align: center;
        color: #000000;
        margin: 50px auto 0;
        padding: 7px 10px;
        border-bottom: 2px solid;
        width: 205px;
        height: 49px;
      }

      table.table-borderless {
        margin-top: 30px;

        tr {
          height: 50px;
        }

        th {
          vertical-align: top;
          @include textStyles("Maven Pro", 800, 15.5px, 29px);
          color: #696671;
        }

        td {
          @include textStyles("Maven Pro", 600, 14.3px, 24px);
          color: #696671;
        }
      }

      a.payment-status {
        padding: 12px 17px;
        height: 48px;
        border-radius: 7px;
        margin-top: 30px;
        background: #fde296;
        letter-spacing: 0.01em;
        font-feature-settings: "liga" off;
        color: #0a0d13;
        text-align: center;
        @include textStyles("Maven Pro", 600, 16px, 24px);
      }

      a.wallet {
        color: #ffffff;
        background: #05b050;
        right: 0;
        width: 40%;
      }

      .red-btn {
        background: #c32021 !important;
        margin-top: 0px !important;
      }

      a.confirm-delivery {
        color: #34323b;
        text-align: center;

        background: #e6f7ee;

        /* Black/Light */
        border: 1px solid #ededee;
        box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.08);
        border-radius: 4px;
        mix-blend-mode: normal;
        opacity: 0.24;
      }

      #payment-state {
        background: #e6f7ee;
      }
    }

    // button group
    .table-btn {
      gap: 25px;
      margin-bottom: 233px;

      button {
        padding: 15px 20px;
      }

      .btn-purchase-order {
        border: 1px solid #05b050;
        border-radius: 5px;
        background: #ffffff;
        @include textStyles(Inter, 600, 16px, 19px);
        color: #05b050;
      }

      .btn-procceed-waybil {
        background: #05b050;
        border-radius: 5px;
        @include textStyles(Inter, 600, 16px, 19px);
        color: #ffffff;
      }
    }
  }

  // Right-container
  .right-container {
    background: #f5f5f5;
    width: 50%;

    .right-container-wrapper {
      margin-inline: 4%;
      margin-top: 65px;

      h1 {
        @include textStyles(Inter, 600, 20px, 28px);
        letter-spacing: -0.02em;
        color: #0a0d13;
        margin-bottom: 17px;
        text-align: center;
      }

      hr {
        border: 1px solid rgba(0, 0, 0, 0.18);
        margin-bottom: 23px;
      }

      .zowasel-receipt {
        background: #ffffff;
        padding: 30px;
        padding-bottom: 100px;

        .receipt-wrapper {
          color: rgba(0, 0, 0, 0.849);

          .logo-wrapper {
            width: 100px;
            margin-left: 25px;
            margin-bottom: 15px;
          }

          address {
            margin-left: 25px;
            margin-bottom: 15px;

            p {
              margin-bottom: 0px;
              font-family: Poppins;
              font-size: 12px;
            }
          }

          .form-wrapper,
          .row {
            border: 0.5px solid rgba(0, 0, 0, 0.18);

            p {
              font-size: 13px;
            }
          }

          .row {
            div {
              border: 0.6px solid rgba(0, 0, 0, 0.18);
              height: 50px;
            }

            .row-item-1 {
              height: 25px;
              font-weight: bold;
              font-size: 12px;
            }

            .row-item-general {
              height: auto;
              font-size: 12.2px;
              text-align: left;
            }

            .col-item-1,
            .col-item-2 {
              font-size: 12.2px;
              text-align: left;
            }
          }

          button.table-btn {
            margin-top: 33px;
            padding: 15px 20px;
            background: #262c3f;
            border-radius: 5px;
            @include textStyles(Inter, 600, 16px, 19px);
            color: #ffffff;
          }
        }
      }
    }
  }
}

.confirmed-text {
  color: #05b050;
  margin-top: 10px;
}

.dispute-text {
  font-family: Maven Pro;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 15px;
}
.go-downn {
  margin-top: 20px;
}
.download_container {
  transition: display 3s;
  position: absolute;
  width: 374px;
  padding: 10px;
  top: -50px;
  display: flex;
  gap: 15px;

  button {
    background: #05b050;
    flex: 1;
    text-align: center;
    border: none;
    height: 35px;

    &:hover {
      background: darken(#05b050, 10%);
    }
  }
}

@media print {
  body * {
    display: none;
  }

  #section-to-download,
  #section-to-download * {
    display: block !important;
  }

  .download,
  .position-relatives,
  .btn-group {
    display: none !important;
  }
}

#section-to-download {
  padding-bottom: 10px;
}

.download {
  width: 100%;
  display: flex;
  gap: 15px;

  .big-download {
    width: 100%;
    height: 48px;
    background-color: #0a0d13;
    color: white;
  }
}
.confirm-pay {
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    margin-top: 20px;
  }
}
.position-relatives {
  margin-bottom: 30px;
}
</style>
