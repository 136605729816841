<template>
  <div class="w-100">
    <div class="form">
      <!-- <h1>KYC Verification</h1> -->
      <div class="dropzone_conatiner my-4">
        <div class="top-texts">
          <h2 class="verification-status">
            <img src="@/assets/images/vectors/verified.svg" />
            {{ kycStatus }}
          </h2>
        </div>
        <div
          v-show="step == 1"
          v-if="kycStatus == 'Unverified'"
          class="big-box"
        >
          <div class="user-account-details d-flex flex-column w-100">
            <!-- account setting -->
            <h1>Account Details</h1>
            <h4 class="required">All fields are required</h4>
            <div class="form-wrapper d-flex flex-row">
              <!-- left hand  -->
              <form class="company-form" @submit.prevent="step += 1">
                <!-- row -->
                <div
                  class="input-row-container d-flex flex-row align-items-center"
                >
                  <div class="mb-3 w-100">
                    <label for="exampleInputEmail1" class="form-label"
                      >First name</label
                    >
                    <input
                      id="exampleInputEmail1"
                      v-model="account.first_name"
                      type="text"
                      class="form-control"
                      aria-describedby="emailHelp"
                      required
                    />
                  </div>

                  <div class="mb-3 w-100">
                    <label for="exampleInputEmail1" class="form-label"
                      >Last name</label
                    >
                    <input
                      id="exampleInputEmail1"
                      v-model="account.last_name"
                      type="text"
                      class="form-control"
                      aria-describedby="emailHelp"
                      placeholder="Promise"
                      required
                    />
                  </div>
                </div>

                <div
                  class="input-row-container d-flex flex-row align-items-center"
                >
                  <div class="mb-3 w-100">
                    <label for="exampleInputPassword1" class="form-label"
                      >Phone number</label
                    >
                    <input
                      id="exampleInputPassword1"
                      v-model="account.phone"
                      type="tel"
                      class="form-control"
                      placeholder="02929209220902"
                      required
                      maxlength="11"
                    />
                  </div>
                  <div class="mb-3 w-100">
                    <label for="exampleInputEmail1" class="form-label"
                      >Country</label
                    >
                    <select
                      id="exampleInputEmail1"
                      v-model="account.country"
                      type="text"
                      class="form-control"
                      aria-describedby="emailHelp"
                      required
                    >
                      <option
                        v-for="(country, index) in countries"
                        :key="index"
                        :value="country.country"
                      >
                        {{ country.country }}
                      </option>
                    </select>
                  </div>
                </div>

                <div
                  class="input-row-container d-flex flex-row align-items-center"
                >
                  <div class="mb-3 w-100">
                    <label for="exampleInputPassword1" class="form-label"
                      >Gender</label
                    >
                    <select
                      id="exampleInputEmail1"
                      v-model="account.gender"
                      class="form-control"
                      aria-describedby="emailHelps"
                      required
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                  <div class="mb-3 w-100">
                    <label for="exampleInputEmail1" class="form-label"
                      >Date of Birth</label
                    >
                    <input
                      id="exampleInputPassword1"
                      v-model="account.dob"
                      type="Date"
                      class="form-control"
                      required
                      :max="maxDate"
                    />
                  </div>
                </div>

                <div
                  class="input-row-container d-flex flex-row align-items-center"
                >
                  <div class="mb-3 w-100">
                    <label for="exampleInputEmail1" class="form-label"
                      >State</label
                    >
                    <select
                      id="exampleInputEmail1"
                      v-model="account.state"
                      type="text"
                      class="form-control"
                      aria-describedby="emailHelp"
                      required
                    >
                      <option
                        v-for="(state, index) in selectStateByCountry"
                        :key="index"
                      >
                        {{ state }}
                      </option>
                    </select>
                  </div>
                  <div class="mb-3 w-100">
                    <label for="exampleInputPassword1" class="form-label"
                      >City</label
                    >
                    <input
                      id="exampleInputPassword1"
                      v-model="account.city"
                      type="text"
                      class="form-control"
                      placeholder="Uyo"
                      required
                    />
                  </div>
                </div>

                <div
                  class="input-row-container d-flex flex-row align-items-center"
                >
                  <div class="mb-3 w-100">
                    <label for="exampleInputPassword1" class="form-label"
                      >Address</label
                    >
                    <input
                      id="exampleInputPassword1"
                      v-model="account.address"
                      type="text"
                      class="form-control"
                      placeholder="No 10 Plazy Place Uyo"
                      required
                    />
                  </div>
                </div>
                <div
                  v-if="userData.user.account_type == 'individual'"
                  class="input-row-container mt-3 mb-2 corperate"
                >
                  <span class="text-coperate-level"
                    >Corporate Level (Current)</span
                  >
                  <span
                    :class="[userLevel == 'red-hot' ? 'red-hot' : 'blue-chip']"
                    >{{ userData.type }}</span
                  >
                </div>
                <button class="btn upadte-btn w-100">NEXT</button>
                <!-- Button trigger modal -->
              </form>
            </div>
          </div>
        </div>

        <div
          v-show="step == 2"
          v-if="kycStatus == 'Unverified'"
          class="big-box2"
        >
          <div class="left-side">
            <a href="javascript:void(0)" @click="step -= 1"
              ><img src="@/assets/images/vectors/arrowleft.png" alt=""
            /></a>
            <h3>Identity Verification</h3>
          </div>
          <form action="" @submit.prevent="step += 1">
            <h4 class="required">All fields are required</h4>
            <label for="">ID Type</label>
            <select
              id=""
              v-model="kycdetails.id_type"
              class="form-control"
              name=""
              required
            >
              <option value="" selected>Select ID Type</option>
              <option
                v-for="idType in kycTypes"
                :key="idType"
                class="document-option"
                :value="idType"
              >
                {{ idType }}
              </option>
            </select>
            <label for="">ID Number</label>
            <input
              v-model="kycdetails.id_number"
              type="text"
              placeholder="Id Number"
              class="form-control"
              required
            />

            <div class="picturess">
              <!-- <div class="for-picture">
            <div id="my-dropzone" class="dropzone">
              <label for="">Passport</label>
              <img
                id="preview-selected-passport"
                class="resize-imgimg-fluid mb-2"
              />
              <img
                v-if="kycStatus == 'Unverified'"
                id="image_icon"
                src="@/assets/images/vectors/Image.svg"
                alt="image"
              />
            </div>
            <div class="file-upload">
              <div class="upload-btn">
                <div
                  v-if="kycStatus == 'Unverified'"
                  class="file-button"
                  @click="openFileDialog('passport')"
                >
                  Upload
                </div>
                <div id="file-input">
                  <input
                    id="passport"
                    ref="input"
                    class="hidden_input"
                    data-preview="preview-selected-passport"
                    type="file"
                    multiple
                    accept="image/*"
                    required
                    @change="uploadFile"
                  />
                </div>
              </div>
            </div>
          </div> -->
              <div class="for-picture">
                <div id="my-dropzone" class="dropzone">
                  <label for="">ID Front</label>
                  <img
                    id="preview-selected-front"
                    class="resize-img img-fluid mb-2"
                  />
                  <img
                    v-if="kycStatus == 'Unverified'"
                    id="image_icon"
                    src="@/assets/images/vectors/Image.svg"
                    alt="image"
                  />
                </div>
                <div class="file-upload">
                  <div
                    v-if="kycStatus == 'Unverified'"
                    class="file-button"
                    @click="openFileDialog('front')"
                  >
                    Upload
                  </div>
                  <div id="file-input">
                    <input
                      id="front"
                      ref="input"
                      class="hidden_input"
                      data-preview="preview-selected-front"
                      type="file"
                      multiple
                      accept="image/*"
                      required
                      @change="uploadFile"
                    />
                  </div>
                </div>
              </div>
              <div class="for-picture">
                <div id="my-dropzone" class="dropzone">
                  <label for="">ID Back</label>
                  <img
                    id="preview-selected-back"
                    class="resize-img img-fluid mb-2"
                  />
                  <img
                    v-if="kycStatus == 'Unverified'"
                    id="image_icon"
                    src="@/assets/images/vectors/Image.svg"
                    alt="image"
                  />
                </div>
                <div class="file-upload">
                  <div
                    v-if="kycStatus == 'Unverified'"
                    class="file-button"
                    @click="openFileDialog('back')"
                  >
                    Upload
                  </div>
                  <div id="file-input">
                    <input
                      id="back"
                      ref="input"
                      class="hidden_input"
                      data-preview="preview-selected-back"
                      type="file"
                      multiple
                      accept="image/*"
                      required
                      @change="uploadFile"
                    />
                  </div>
                </div>
              </div>
            </div>
            <label for="">BVN</label>
            <input
              v-model="kycdetails.bvn"
              type="text"
              placeholder="BVN"
              class="form-control"
              maxlength="11"
            />
            <button class="btn upadte-btn preview-button w-100">PREVIEW</button>
          </form>
        </div>
        <div v-show="step == 3" class="preview">
          <div class="user-account-details d-flex flex-column w-100">
            <!-- account setting -->
            <div class="left-side">
              <a
                v-if="kycStatus.toLowerCase() == 'unverified'"
                href="javascript:void(0)"
                @click="step -= 1"
                ><img src="@/assets/images/vectors/arrowleft.png" alt=""
              /></a>
              <h3>Preview KYC</h3>
            </div>
            <div class="form-wrapper d-flex flex-row">
              <!-- left hand  -->
              <div class="company-form">
                <!-- row -->
                <div
                  class="input-row-container d-flex flex-row align-items-center"
                >
                  <div class="mb-3 w-100">
                    <label for="exampleInputEmail1" class="form-label"
                      >Name :
                      {{ account.last_name + " " + account.first_name }}</label
                    >
                  </div>
                  <div class="mb-3 w-100">
                    <label for="exampleInputPassword1" class="form-label"
                      >Gender : {{ account.gender }}</label
                    >
                  </div>
                </div>

                <div
                  class="input-row-container d-flex flex-row align-items-center"
                >
                  <div class="mb-3 w-100">
                    <label for="exampleInputEmail1" class="form-label"
                      >Country: {{ account.country }}</label
                    >
                  </div>

                  <div class="mb-3 w-100">
                    <label for="exampleInputEmail1" class="form-label"
                      >Address :{{ account.address }}</label
                    >
                  </div>
                </div>

                <div
                  class="input-row-container d-flex flex-row align-items-center"
                >
                  <div class="mb-3 w-100">
                    <label for="exampleInputPassword1" class="form-label"
                      >Phone number : {{ account.phone }}</label
                    >
                  </div>
                  <div class="mb-3 w-100">
                    <label for="exampleInputEmail1" class="form-label"
                      >Date of Birth: {{ account.dob }}</label
                    >
                  </div>
                </div>

                <div
                  class="input-row-container d-flex flex-row align-items-center"
                >
                  <div class="mb-3 w-100">
                    <label for="exampleInputEmail1" class="form-label"
                      >State: {{ account.state }}</label
                    >
                  </div>
                  <div class="mb-3 w-100">
                    <label for="exampleInputPassword1" class="form-label"
                      >City: {{ account.city }}</label
                    >
                  </div>
                </div>
                <hr />
                <h3>KYC Details</h3>
                <div
                  class="input-row-container d-flex flex-row align-items-center"
                >
                  <div class="mb-3 w-100">
                    <label
                      id="document-option1"
                      for="exampleInputEmail1"
                      class="form-label"
                      >Id Type: {{ documentType }}</label
                    >
                  </div>
                  <div class="mb-3 w-100">
                    <label for="exampleInputPassword1" class="form-label"
                      >Id Number: {{ kycdetails.id_number }}</label
                    >
                  </div>
                </div>

                <div class="picturess">
                  <div class="for-picture">
                    <div id="my-dropzone" class="dropzone">
                      <label for="">ID Front</label>
                      <img
                        id="preview-selected-front1"
                        class="resize-img img-fluid mb-2"
                      />
                      <!-- <img
                        v-if="kycStatus == 'Unverified'"
                        id="image_icon"
                        src="@/assets/images/vectors/Image.svg"
                        alt="image"
                      /> -->
                    </div>
                  </div>
                  <div class="for-picture">
                    <div id="my-dropzone" class="dropzone">
                      <label for="">ID Back</label>
                      <img
                        id="preview-selected-back1"
                        class="resize-img img-fluid mb-2"
                      />
                      <!-- <img
                        v-if="kycStatus == 'Unverified'"
                        id="image_icon"
                        src="@/assets/images/vectors/Image.svg"
                        alt="image"
                      /> -->
                    </div>
                  </div>
                </div>
                <label for="">BVN: {{ kycdetails.bvn }}</label>

                <div
                  v-if="userData.user.account_type == 'individual'"
                  class="input-row-container mt-3 mb-2 corperate"
                >
                  <span class="text-coperate-level"
                    >Corporate Level (Current):</span
                  >
                  <span
                    :class="[
                      userLevel == 'red-hot' ? 'red-hot' : 'blue-chip',
                      'ms-2',
                    ]"
                    >{{ userData.type }}</span
                  >
                </div>
                <div v-if="kycStatus == 'Unverified'" class="btn-area">
                  <button :disabled="canSubmit" @click="submitKYC">Save</button>
                </div>
                <!-- Button trigger modal -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Preloader v-if="isLoading" />
</template>

<script>
import Preloader from "@/layouts/shared/Preloader.vue";
import countriesObject from "@/data/countries";
import MarketPlaceService from "@/services/marketplace";
import Alert from "@/utilities/alert";
export default {
  name: "KycVerification",
  components: {
    Preloader,
  },
  data() {
    return {
      kycdetails: {
        id_type: "",
        id_number: "",
        files: {},
        bvn: "",
      },
      kycTypes: [],
      countries: countriesObject.countries,
      account: {
        first_name: "",
        last_name: "",
        phone: "",
        country: "",
        gender: "",
        dob: "",
        state: "",
        city: "",
        address: "",
      },
      step: 1,
    };
  },
  computed: {
    canSubmit: function () {
      return this.kycdetails.id_type &&
        this.kycdetails.id_number &&
        this.kycdetails.files.front &&
        this.kycdetails.files.back &&
        this.kycdetails.bvn
        ? null
        : true;
    },
    selectStateByCountry: function () {
      return this.countries && this.account.country != ""
        ? this.countries.filter(
            (item) => item.country == this.account.country,
          )[0].states
        : [];
    },
    documentType: function () {
      if (this.kycdetails.id_type != "") {
        return this.kycdetails.id_type.replace("_", " ").capitalize();
      }
      return "";
    },
  },
  mounted() {
    this.getKycTypes();
    if (this.kycStatus.toLowerCase() == "pending verification") {
      $(".verification-status").addClass("pending");
    } else {
      $(".verification-status").addClass(this.kycStatus.toLowerCase());
    }
    if (this.documents) {
      var front = this.documents.filter((d) => d.side == "front")[0];
      var back = this.documents.filter((d) => d.side == "front")[0];
      // this.handlePreviewShow("preview-selected-front", front);
      // this.handlePreviewShow("preview-selected-back", back);
      this.handlePreviewShow("preview-selected-front1", front);
      this.handlePreviewShow("preview-selected-back1", back);
    }
    this.account = {
      first_name: this.userData.user.first_name ?? "",
      last_name: this.userData.user.last_name ?? "",
      phone: this.userData.user.phone ?? "",
      country: this.userData.user.country ?? "",
      state: this.userData.user.state ?? "",
      city: this.userData.user.city ?? "",
      address: this.userData.user.primary_address ?? "",
      dob: this.userData.user.dob ?? "",
      gender: this.userData.user.gender ?? "",
    };
    if (this.kycStatus != "Unverified") {
      this.step = 3;
      this.kycdetails = {
        ...this.kycdetails,
        id_type: this.kycDetails.idNumbers[0].type,
        id_number: this.kycDetails.idNumbers[0].value,
        bvn: this.kycDetails.bvn,
      };
    }
  },

  methods: {
    openFileDialog(input_id) {
      document.getElementById(input_id).click();
    },
    handlePreviewShow(id, image) {
      document.querySelector(`#${id}`).style.display = "block";
      document.querySelector(`#${id}`).src = image.base64;
    },
    uploadFile($event) {
      const input = $event.target;
      const file = input.files;
      // this.newCropData.files = file;

      // get the file name
      this.fileName = file[0].name;

      // preview the image

      // if at least one image is selected proceed to display the image
      if (file[0]) {
        //Set kycdetails files
        this.kycdetails.files[input.id] = file[0];

        // get the image path
        const imageSrc = URL.createObjectURL(file[0]);
        // select the image preview element
        const imagePreviewElement = document.getElementById(
          input.getAttribute("data-preview"),
        );
        const finalPreview = document.getElementById(
          input.getAttribute("data-preview") + "1",
        );

        // assign the path to the image preview element
        imagePreviewElement.src = imageSrc;
        imagePreviewElement.style.display = "block";
        finalPreview.src = imageSrc;
        finalPreview.style.display = "block";
        // hide sibling img element
        imagePreviewElement.nextElementSibling.style.display = "none";
      }
    },
    formatKycType() {
      var allOptions = document.getElementsByClassName("document-option");
      Array.from(allOptions).forEach((optionElement) => {
        optionElement.innerHTML = optionElement.innerHTML.replace("_", " ");
        optionElement.innerHTML =
          optionElement.innerHTML.substring(0, 1).toUpperCase() +
          optionElement.innerHTML.substring(1);
      });
    },
    submitKYC() {
      var formData = new FormData();
      if (this.kycdetails.files.front && this.kycdetails.files.back) {
        var front = this.kycdetails.files.front,
          back = this.kycdetails.files.back;
        formData.append("front", front, front.name);
        formData.append("back", back, back.name);
      }
      formData.append("id_type", this.kycdetails.id_type);
      formData.append("id_number", this.kycdetails.id_number);
      formData.append("bvn", this.kycdetails.bvn);
      formData.append("first_name", this.account.first_name);
      formData.append("last_name", this.account.last_name);
      formData.append("phone_number", this.account.phone);
      formData.append("country", this.account.country);
      formData.append("gender", this.account.gender);
      formData.append("dob", this.account.dob);
      formData.append("state", this.account.state);
      formData.append("city", this.account.city);
      formData.append("address", this.account.address);

      this.isLoading = true;
      MarketPlaceService.sendKycRequest(formData, (response) => {
        if (response && !response.error) {
          console.log(response);
          var data = response.data;
          Alert.success({
            message: "KYC Verification Submitted",
          });
          if (data.response && data.response.status == "in_progress") {
            this.kycStatus = "Pending Verification";
            $(".verification-status").addClass("pending");
            $(".verification-status").removeClass("unverified");
          }
          if (data.response && data.response.status == "complete") {
            this.kycStatus = "Verified";
            $(".verification-status").addClass("verified");
          }
          this.$store.dispatch("setKyc", "Pending Verification");
          this.isLoading = false;
        } else {
          Alert.error({
            message: "An error occurred",
          });
        }
      });
    },
    getKycTypes() {
      MarketPlaceService.getKycTypes((response) => {
        if (response && response.error == false) {
          this.kycTypes = response.types;
          setTimeout(() => {
            this.formatKycType();
          }, 500);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.user-account-details {
  h1 {
    margin-top: 22px;
    @include textStyles("Maven Pro", 700, 18px, 135%);
    letter-spacing: 0.01em;
    color: #4a4754;
  }

  form.company-form {
    width: 100%;
  }

  form {
    .corperate {
      display: flex;
      align-items: first baseline;
      justify-content: space-between;
    }

    .input-row-container {
      gap: 30px;

      .text-coperate-level {
        letter-spacing: 0.01em;
        @include textStyles(Poppins, 700, 18px, 135%);
      }
      select.form-select {
        @include textStyles(Poppins, 300, 14px, 156.7%);
        color: rgba(45, 55, 72, 0.8) !important;

        > option {
          color: rgba(45, 55, 72, 0.8) !important;
        }
      }
    }

    button.upadte-btn {
      margin-top: 45px;
      background: #008d40;
      color: #ffffff;
      @include textStyles(Poppins, 300, 16px, 166.67%);
    }

    div.mb-3 label {
      margin-bottom: 0px;
      @include textStyles(Poppins, 300, 14px, 166.67%);
      color: rgba(45, 55, 72, 0.8);
    }

    input {
      &::placeholder {
        @include textStyles(Poppins, 300, 14px, 156.7%);
      }
    }

    button.btn.change-password {
      @include textStyles(Poppins, 300, 16px, 166.67%);
      background: #ffffff;
      border: 1px solid #96949c;
      color: #000000;

      &:hover,
      &:active,
      &:focus,
      &:focus-visible {
        color: white;
      }
    }
  }
}

.crop-wanted-section {
  background: #ffffff;
  border-radius: 4px;
  padding: 4%;
}

.form {
  background-color: white;
  padding-block: 30px;
  padding-inline: 6em;
  margin-top: 30px;
  .crop_details {
    @include textStyles(inherit, 700, 16px, 24px);
    letter-spacing: 0.01em;
    color: #4a4754;
    margin-bottom: 1rem;
  }

  .m_top {
    margin-top: 3.4rem;
  }

  width: 100%;
  padding-bottom: 2rem;

  select,
  input {
    font-size: 14px;
    color: #000000;

    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      border-color: #05b050 !important;
      box-shadow: 0 0 !important;
    }

    &::placeholder {
      @include textStyles("Maven Pro", 700, 14px, 24px);
      color: #000000;
    }
  }

  label {
    font-size: 14px;
    font-weight: 900;
    color: rgba(45, 55, 72, 0.8);
  }
}

div.vertical-line {
  width: 2px;
  padding: 1px;
  background: #f5f5f5;
}

// using drop-zone
.dropzone {
  width: 100%;
  max-height: 450px;
  background: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 147%;
  color: #4a4754;
  padding: 25px 35px 25px 35px;
  margin-top: 10px;

  span {
    font-weight: 700;
    color: #05b050;
    cursor: pointer;

    + span {
      font-size: 14px;
    }
  }
}

#required {
  color: red;
  font-family: "Maven Pro";
  font-weight: bolder;
  font-size: 20px;
}

#optional {
  font-family: "Maven Pro";
  font-weight: bolder;
  font-size: 14px;
  color: rgba(113, 117, 125, 0.83);
  font-style: italic;
}

.zone {
  height: 150px;
  font-size: 12px;
}

.hidden_input {
  display: none;
}
.first-uploads {
  margin-top: 30px;
  width: 100%;

  .each-input {
    display: flex;
    flex-direction: column;

    select {
      height: 40px;
      background-color: white;
    }

    input {
      height: 40px;
      width: 100%;
      background-color: white;
    }
  }
}
.picturess {
  display: flex;
  gap: 40px;
  margin-top: 50px;
  margin-bottom: 30px;
}
.for-picture {
  width: 100%;
}

.file-button {
  width: 150px;
  height: 50px;
  border: 2px solid #05b050;
  border-radius: 4px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.kyc {
  width: 100%;
  height: 100%;
}
.kycpassport {
  width: 100%;
  height: 100%;
}

#image_icon {
  width: 100%;
  height: 300px;
}
#image_icon {
  width: 100%;
  height: 300px;
}
.btn-area {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;

  button {
    background: #05b050;
    border-radius: 1.5px;
    width: 120px;
    height: 40px;
    border: none;
    color: white;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
  }

  button:disabled {
    background: #f5f5f5;
    color: #c3c3c3;
  }
}
.top-texts {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  h3 {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #4a4754;
  }
  .verification-status {
    padding: 5px 15px;
    background: #f5f5f5;
    border-radius: 14px;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    color: #b5b4b9;
    font-size: 22px;
    font-size: 20px;
    line-height: 132%;

    img {
      display: none;
    }
  }

  .verification-status.verified {
    color: #05b050;
  }

  .verification-status.verified img {
    margin-right: 2px;
    height: 20px;
    display: inline;
  }

  .verification-status.pending {
    color: #fab900;
  }

  .verification-status.unverified {
    color: #b5b4b9;
  }
}
.file-upload {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.resize-img {
  width: 100%;
  max-height: 350px;
}
#backTo-dashboard {
  width: 15em;
  display: flex;
  padding: 10px;
  border-radius: 4px;
  background: #05b050;
  align-items: center;
  gap: 10px;
  img {
    height: 25px;
  }
}
.go-down {
  margin-top: 70px;
  margin-bottom: 70px;
}
.preview-button {
  margin-top: 45px;
  background: #008d40;
  color: #ffffff;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 166.67%;
  margin-bottom: 50px;
}
.left-side {
  display: flex;
  gap: 15px;

  img {
    width: 22px;
    height: 18px;
  }
}
.company-form {
  width: 100%;
}
.required {
  color: red;
  font-family: "Maven Pro";
  font-weight: bolder;
  font-size: 16px;
}
</style>
