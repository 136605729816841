<template>
  <DefaultNav>
    <div class="big-container">
      <form class="mt-4" @submit.prevent="saveData()">
        <div class="headings">
          <h2>Capital</h2>
          <div class="right-btns">
            <a href="/dashboard/capital/view" class="yellow-link"
              >View Capital</a
            >
            <button class="green-link">Save</button>
          </div>
        </div>
        <div class="mb-3">
          <h6>
            <strong>All fields required</strong>
          </h6>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for=""
              >What are your sources of income other than farming?</label
            >
            <input
              v-model="otherincomesource"
              type="text"
              class="form-control"
              placeholder="Enter your answer"
              required
            />
          </div>
          <div class="col">
            <label for="">What is the main source of income?</label>
            <input
              v-model="mainincomesource"
              type="text"
              class="form-control"
              placeholder="Enter your answer"
              required
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for=""
              >What is the number of people providing regular income to the
              household?</label
            >
            <input
              v-model="noofincomeearners"
              type="text"
              class="form-control"
              placeholder="Enter your answer"
              required
            />
          </div>
          <div class="col">
            <label for=""
              >Do you have an account with any commercial bank?</label
            >
            <select
              v-model="hasbankaccount"
              class="form-select"
              aria-label="Default select example"
              required
            >
              <option selected>Choose...</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for=""
              >What is your first option for getting the necessary money?</label
            >
            <input
              v-model="firstfundingoption"
              type="text"
              class="form-control"
              placeholder="Enter your answer"
              required
            />
          </div>
          <div class="col">
            <label for="">Would you prefer to find money or need a loan?</label>
            <select
              v-model="needsaloan"
              class="form-select"
              aria-label="Default select example"
              required
            >
              <option selected>Choose...</option>
              <option value="loan">Need A loan</option>
              <option value="otheroptions">Find another source</option>
            </select>
          </div>
        </div>
        <div class="mt-4">
          <label for=""> How many months would you need to pay back? </label>
          <input
            v-model="paybackmonths"
            type="number"
            class="form-control"
            placeholder="Enter your answer"
            required
            min="0"
          />
        </div>
        <div class="row mt-4 mb-4">
          <div class="col">
            <label for="">Did the quantity of your harvest change?</label>
            <select
              v-model="harvestqtychanged"
              class="form-select"
              aria-label="Default select example"
              required
            >
              <option selected>Choose...</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <div class="col">
            <label for="">Did your expense in pest control change?</label>
            <select
              v-model="pestexpensechanged"
              class="form-select"
              aria-label="Default select example"
              required
            >
              <option selected>Choose...</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
        </div>
        <!-- <div class="mt-5 mb-3">
          <h5>
            <strong>Credit Access</strong>
          </h5>
        </div> -->
        <!-- <div class="mt-4">
          <label for=""
            >Have you served as a treasurer in your farmer group or any other
            group in the past or presently? Specify group please
          </label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter your answer"
          />
        </div>
        <div class="row mb-4">
          <div class="col">
            <label for=""
              >If yes, how long have served in this capacity?
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter your answer"
            />
          </div>
          <div class="col">
            <label for="">Do you do saving in your farmer group monthly?</label>
            <select class="form-select" aria-label="Default select example">
              <option selected>Choose...</option>
              <option>...</option>
            </select>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <label for="">If yes, how much do you set aside monthly?</label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter your answer"
            />
          </div>
          <div class="col">
            <label for=""
              >Have you had difficulty to pay up loans on time in the past 4
              years?</label
            >
            <select class="form-select" aria-label="Default select example">
              <option selected>Choose...</option>
              <option>...</option>
            </select>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <label for="">If yes, how much? </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter your answer"
            />
          </div>
          <div class="col">
            <label for="">If yes, why?</label>
            <select class="form-select" aria-label="Default select example">
              <option selected>Choose...</option>
              <option>...</option>
            </select>
          </div>
        </div>
        <div class="mb-4">
          <label for=""
            >How many loans have you had difficulty to pay back in the past 4
            years?
          </label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter your answer"
          />
        </div>
        <div class="row mb-4">
          <div class="col">
            <label for=""
              >How many loans have you have you paid back in the past 4
              years?</label
            >
            <input
              type="text"
              class="form-control"
              placeholder="Enter your answer"
            />
          </div>
          <div class="col">
            <label for="">How many have you paid on or before due date?</label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter your answer"
            />
          </div>
          <div class="mb-4">
            <label for=""
              >What is the estimate of your monthly income after harvest and
              sales of your farm produce?
            </label>
            <select class="form-select" aria-label="Default select example">
              <option selected>Choose...</option>
              <option>...</option>
            </select>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <label for=""
              >What is your cost of cultivation in this planting season?
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter your answer"
            />
          </div>
          <div class="col">
            <label for=""
              >Have you exchanged your farm produce to pay up for loans taken in
              the past?</label
            >
            <select class="form-select" aria-label="Default select example">
              <option selected>Choose...</option>
              <option>...</option>
            </select>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <label for="">If yes, how many times in the past 4 years?</label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter your answer"
            />
          </div>
          <div class="col">
            <label for=""
              >If no, what other collateral do you use or loans in the past 4
              years?</label
            >
            <input
              type="text"
              class="form-control"
              placeholder="Enter your answer"
            />
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <label for="">How much do you want for a loan?</label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter your answer"
            />
          </div>
          <div class="col">
            <label for=""
              >How many years have you being cultivating this crop?</label
            >
            <input
              type="text"
              class="form-control"
              placeholder="Enter your answer"
            />
          </div>
        </div>
        <div class="mb-3">
          <label for="">What is your turnovear for a year? </label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter your answer"
          />
        </div> -->
      </form>
    </div>
  </DefaultNav>
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import Alert from "@/utilities/alert";
import axios from "axios";
export default {
  name: "Capital",
  components: {
    DefaultNav,
  },
  data() {
    return {
      bvn: "",
      firstfundingoption: "",
      harvestqtychanged: "",
      hasbankaccount: "",
      mainincomesource: "",
      needsaloan: "",
      noofincomeearners: "",
      otherincomesource: "",
      paybackmonths: "",
      pestexpensechanged: "",
    };
  },
  methods: {
    async saveData() {
      const bvn = JSON.stringify(
        JSON.parse(localStorage.getItem("Zowasel")).user.user_id,
      );
      let vm = this;
      let dt = {
        firstfundingoption: vm.firstfundingoption,
        bvn: bvn,
        harvestqtychanged: vm.harvestqtychanged,
        hasbankaccount: vm.hasbankaccount,
        mainincomesource: vm.mainincomesource,
        needsaloan: vm.needsaloan,
        noofincomeearners: vm.noofincomeearners,
        otherincomesource: vm.otherincomesource,
        paybackmonths: vm.paybackmonths,
        pestexpensechanged: vm.pestexpensechanged,
      };
      axios.post("http://174.129.139.68//api/capital/add", dt).then((data) => {
        let response = data.data;
        if (response.error == false) {
          Alert.success({
            message: "Capital added successfully",
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          Alert.error({
            message: response.message,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 2% 5% 2% 3%;
  overflow-y: scroll;
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}
.active-subscriptions {
  background-color: white;
  padding: 24px 65px;
  margin-top: 40px;

  h2 {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #696671;
  }
}

/* The slider */
.sub-history {
  margin-top: 50px;
  width: 100%;
  margin-left: 10px;

  h2 {
    margin-bottom: 40px;
  }
  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #4a4754;
  }
}
.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  a {
    text-decoration: none;
  }
  .right-btns {
    display: flex;
    gap: 20px;
  }
  .green-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 145px;
    height: 40px;
    background: #05b050;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
  }
}
.green-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 145px;
  height: 40px;
  background: #05b050;
  border-radius: 5px;
  letter-spacing: 0.02em;
}
.yellow-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  width: 145px;
  height: 40px;
  background: #fab900 !important;
  border-radius: 5px;
  letter-spacing: 0.02em;
}
a {
  text-decoration: none;
}
.small-font {
  font-size: 11px !important;
}
</style>
