<template>
  <DefaultNav>
    <div class="big-container pb-5">
      <form class="mt-4" @submit.prevent="saveData()">
        <div class="headings">
          <h2>Farm Practice</h2>
          <div class="right-btns">
            <a href="/dashboard/farmer/view" class="yellow-link"
              >View Farmers</a
            >
            <button class="green-link">Save</button>
          </div>
        </div>
        <div v-if="editing == false" class="mb-3">
          <h6>
            <strong>* All fields are required</strong>
          </h6>
        </div>
        <div class="row mb-4">
          <div class="col">
            <label for="">
              <span v-if="editing == false" class="text-danger">*</span> Do you
              practice crop rotation
            </label>
            <select
              v-model="croprotation"
              class="form-select"
              aria-label="Default select example"
            >
              <option selected>Choose...</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div class="col">
            <label for=""
              ><span v-if="editing == false" class="text-danger">*</span>
              What portion of land per sqm can you cultivate
            </label>
            <input
              v-model="cultivatepersqm"
              type="number"
              class="form-control"
              placeholder="Enter your answer"
            />
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <label for=""
              ><span v-if="editing == false" class="text-danger">*</span> What
              Is the size of fertilizer per KG you use per season?</label
            >
            <input
              v-model="fertilizersizeperseason"
              type="number"
              class="form-control"
              placeholder="Enter your answer"
            />
          </div>
          <div class="col">
            <label for=""
              ><span v-if="editing == false" class="text-danger">*</span> What
              is your most productive crop?</label
            >
            <input
              v-model="viablecrop"
              type="text"
              class="form-control"
              placeholder="Enter your answer"
            />
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <label for=""
              ><span v-if="editing == false" class="text-danger">*</span> What
              is your total average income per harvest season</label
            >
            <input
              v-model="incomeperharvestseason"
              type="number"
              class="form-control"
              placeholder="Enter your answer"
            />
          </div>
          <div class="col">
            <label for=""
              ><span v-if="editing == false" class="text-danger">*</span>
              Fertilizer per kg applied per crop cycle
            </label>
            <input
              v-model="fertilizerpercropcycle"
              type="number"
              class="form-control"
              placeholder="Enter your answer"
            />
          </div>
        </div>
        <div class="mb-4">
          <label for=""
            ><span v-if="editing == false" class="text-danger">*</span>List of
            fungicides used <em>Seperate with a comma(,)</em></label
          >
          <input
            v-model="fungicideused"
            type="text"
            class="form-control"
            placeholder="Enter your answer"
          />
        </div>
        <div class="mb-4">
          <label for=""
            ><span v-if="editing == false" class="text-danger">*</span>Weed
            control practice used <em>Seperate with a comma(,)</em></label
          >
          <input
            v-model="weedcontrol"
            type="text"
            class="form-control"
            placeholder="Enter your answer"
          />
        </div>
        <div class="mb-4">
          <label for=""
            ><span v-if="editing == false" class="text-danger">*</span>List of
            fertilizers used <em>Seperate with a comma(,)</em></label
          >
          <input
            v-model="fertilizercontrol"
            type="text"
            class="form-control"
            placeholder="Enter your answer"
          />
        </div>
        <div class="mb-4">
          <label for=""
            ><span v-if="editing == false" class="text-danger">*</span>Intended
            crop</label
          >
          <input
            v-model="intendedcrop"
            type="text"
            class="form-control"
            placeholder="Enter your answer"
          />
        </div>
      </form>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
// Alert box
import Alert from "@/utilities/alert";
import Preloader from "@/layouts/shared/Preloader.vue";
import FarmerService from "@/services/farmer";

export default {
  name: "FarmPractice",
  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      farmerid: "",
      croprotation: "",
      cultivatepersqm: "",
      fertilizersizeperseason: "",
      viablecrop: "",
      incomeperharvestseason: "",
      fungicideused: "",
      weedcontrol: "",
      fertilizercontrol: "",
      intendedcrop: "",
      fertilizerpercropcycle: "",
      editData: [],
      editing: false,
    };
  },
  mounted() {
    const url = window.location.href;
    this.farmerid = url.split("/").slice(-1)[0];
    this.isLoading = true;

    if (url.split("/").slice(-1)[0] == "edit") {
      this.editing = false;
      FarmerService.getFarmPractice(this.farmerid, (response) => {
        this.editData.push(response.data);
        setTimeout(() => {
          this.isLoading = false;
          if (response.data == null) {
            //   console.log(response.data);
            window.location.href = "/dashboard/farmer/view";
          } else {
            this.croprotation = this.editData[0].croprotationpractice;
            this.cultivatepersqm = this.editData[0].numberofsqmyoucancultivate;
            this.fertilizersizeperseason =
              this.editData[0].totalfertilizerusedperseason;
            this.viablecrop = this.editData[0].yourmostviablecrop;
            this.incomeperharvestseason =
              this.editData[0].totalaverageincomeperharvest;
            this.fungicideused = this.editData[0].listoffungicidesused;
            this.weedcontrol = this.editData[0].weedcontrolpractices;
            this.fertilizercontrol = this.editData[0].listfertilizersused;
            this.intendedcrop = this.editData[0].intendedcrop;
            this.fertilizerpercropcycle =
              this.editData[0].fertilizerappliedpercropcycle;
            this.editing = true;
          }
        }, 3000);
      });
    } else {
      this.isLoading = false;
      this.editing = false;
    }
  },
  methods: {
    async saveData() {
      if (this.editing == true) {
        let vm = this;
        this.isLoading = true;
        let dt = {
          farmerid: vm.farmerid,
          croprotationpractice: vm.croprotation,
          numberofsqmyoucancultivate: vm.cultivatepersqm,
          fertilizerappliedpercropcycle: vm.fertilizerpercropcycle,
          totalfertilizerusedperseason: vm.fertilizersizeperseason,
          yourmostviablecrop: vm.viablecrop,
          listoffungicidesused: vm.fungicideused,
          totalaverageincomeperharvest: vm.incomeperharvestseason,
          weedcontrolpractices: vm.weedcontrol,
          listfertilizersused: vm.fertilizercontrol,
          intendedcrop: vm.intendedcrop,
        };
        FarmerService.updateFarmPractice(vm.farmerid, dt, (data) => {
          /* ------------------- check if it returned with an error ------------------- */
          if (data[0].error == false) {
            Alert.success({
              message: data[1].message,
            });
            setTimeout(() => {
              window.location.href = `/dashboard/farmer/view/${vm.farmerid}`;
            }, 2000);
          } else {
            Alert.error({
              message: data[1].message,
            });
          }
          this.isLoading = false;
        });
      } else {
        let vm = this;
        this.isLoading = true;
        let dt = {
          farmerid: vm.farmerid,
          croprotationpractice: vm.croprotation,
          numberofsqmyoucancultivate: vm.cultivatepersqm,
          fertilizerappliedpercropcycle: vm.fertilizerpercropcycle,
          totalfertilizerusedperseason: vm.fertilizersizeperseason,
          yourmostviablecrop: vm.viablecrop,
          listoffungicidesused: vm.fungicideused,
          totalaverageincomeperharvest: vm.incomeperharvestseason,
          weedcontrolpractices: vm.weedcontrol,
          listfertilizersused: vm.fertilizercontrol,
          intendedcrop: vm.intendedcrop,
        };
        FarmerService.addFarmPractice(dt, (data) => {
          /* ------------------- check if it returned with an error ------------------- */
          if (data[0].error == false) {
            Alert.success({
              message: data[1].message,
            });
            setTimeout(() => {
              window.location.href = `/dashboard/farmer/view/${vm.farmerid}`;
            }, 2000);
          } else {
            Alert.error({
              message: data[1].message,
            });
          }
          this.isLoading = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 2% 5% 2% 3%;
  overflow-y: scroll;
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}
.active-subscriptions {
  background-color: white;
  padding: 24px 65px;
  margin-top: 40px;

  h2 {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #696671;
  }
}

/* The slider */
.sub-history {
  margin-top: 50px;
  width: 100%;
  margin-left: 10px;

  h2 {
    margin-bottom: 40px;
  }
  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #4a4754;
  }
}
.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  a {
    text-decoration: none;
  }
  .right-btns {
    display: flex;
    gap: 20px;
  }
  .green-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 145px;
    height: 40px;
    background: #05b050;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
  }
}
.green-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 145px;
  height: 40px;
  background: #05b050;
  border-radius: 5px;
  letter-spacing: 0.02em;
}
.yellow-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  width: 145px;
  height: 40px;
  background: #fab900 !important;
  border-radius: 5px;
  letter-spacing: 0.02em;
}
a {
  text-decoration: none;
}
.small-font {
  font-size: 11px !important;
}
</style>
