<template>
  <div id="photos" class="warhouse_wrapper_carousel">
    <div class="warehouse_wrapper">
      <h2>Photos & Videos</h2>
      <!-- !using inputs images card class names -->

      <div class="inputs_wrapper">
        <div
          v-for="(mediaData, index) in allMedia"
          :key="index"
          class="inputs_images_card"
        >
          <div class="input_image_container">
            <img
              v-if="mediaData.type === 'image'"
              :src="mediaData.url"
              alt="Product image"
              class="img-fluid"
            />
            <video
              v-if="mediaData.type === 'video'"
              :src="mediaData.url"
              controls
              autoplay
              class="video-fit-cover"
            ></video>
          </div>
          <div class="Input_card_body">
            <div class="product_title">{{ mediaData.title }}</div>
            <p class="product_content">{{ mediaData.description }}</p>
          </div>
        </div>
        <!-- Add certificate -->
        <div
          class="add-certificate-container"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          <div class="add-icon">
            <img src="@/assets/images/vectors/add-certificate.svg" alt="" />
            <p>Add certificate</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->

  <div
    id="exampleModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 id="exampleModalLabel" class="modal-title fs-5">Upload Media</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <!-- form -->
        <div class="modal-body">
          <div class="upload">
            <h3>Media Upload</h3>
            <div class="media-upload">
              <div class="w-100 mb-3">
                <label for="formGroupExampleInput" class="form-label mb-0"
                  >Media Title</label
                >
                <span id="required">*</span>
                <input
                  id="formGroupExampleInput"
                  v-model="media.title"
                  type="text"
                  class="form-control"
                  placeholder="Media Title"
                  required
                />
              </div>
              <div class="w-100 mb-3">
                <label for="formGroupExampleInput" class="form-label mb-0"
                  >Media Description</label
                >
                <span id="required">*</span>
                <input
                  id="formGroupExampleInput"
                  v-model="media.description"
                  type="text"
                  class="form-control"
                  placeholder="Media Title"
                  required
                />
              </div>
              <div class="profile-picture-cropper">
                <div class="w-100 mb-3">
                  <label for="formGroupExampleInput" class="form-label mb-0"
                    >Upload Image/Video (video files should be less than
                    4mb)</label
                  >
                  <span id="required">*</span>
                  <input
                    id="formGroupExampleInput"
                    type="file"
                    class="form-control"
                    required
                    @change="handleFileUpload"
                  />
                </div>
                <cropper
                  v-if="imageLoaded"
                  ref="cropper"
                  :src="imageSrc"
                  :guides="false"
                  :view-mode="1"
                  :drag-mode="cropType"
                  :aspect-ratio="aspectRatio"
                  :auto-crop-area="1"
                  :movable="false"
                />
                <div v-if="imageLoaded" class="buttons">
                  <button @click="cropImage">Crop</button>
                  <button @click="cancelCrop">Cancel</button>
                </div>
              </div>
              <!-- Media Preview -->
              <div
                v-show="media.type == 'video'"
                ref="mediaContainer"
                class="media-preview"
              >
                <h6>Preview Video</h6>
                <video id="V-preview" ref="videoPreview" controls />
              </div>
            </div>
          </div>
        </div>
        <div v-if="isCrop" class="modal-footer">
          <button type="button" class="btn btn-primary" @click="uploadMedia()">
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
  <Preloader v-if="isLoading" />
</template>
<script>
import "cropperjs/dist/cropper.css";
import Cropper from "vue-cropperjs";
import MarketPlaceService from "@/services/marketplace";
import Alert from "@/utilities/alert";
export default {
  name: "UpadateAvatar",
  components: { Cropper },
  props: {
    aspectRatio: {
      type: Number,
      default: 1,
    },
    cropType: {
      type: String,
      default: "crop",
    },
  },
  data() {
    return {
      imageSrc: "",
      imageLoaded: false,
      cropper: null,
      originalImage: null,
      isCropper: false,
      media: {
        title: "",
        description: "",
        file: "",
        type: "",
      },
      isCrop: false,
      allMedia: [],
    };
  },

  created() {
    this.getMedia();
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file.type.match("image.*")) {
        // set the media type to video
        this.media.type = "image";
        // create new fileReader instance
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.imageSrc = reader.result;
          this.imageLoaded = true;
        };
      }
      // Check if the selected file is a video
      else if (file.type.match("video.*")) {
        if (file.size < 4200000) {
          // set the media type to video
          this.media.type = "video";
          // Create a new FileReader instance for the video file
          const videoFileReader = new FileReader();
          // Read the video file as binary format
          const videoFileReaderBlob = file;
          // set the the media data object
          this.media.file = videoFileReaderBlob;
          // Read the video file as a data URL
          videoFileReader.readAsDataURL(file);
          // When the video file has been loaded
          videoFileReader.addEventListener("load", () => {
            // Use $nextTick to ensure that the DOM has been updated before accessing $refs
            this.$nextTick(() => {
              this.imageLoaded = false;
              // Set the src of the video preview element to the result of reading the video file
              this.$refs.videoPreview.src = videoFileReader.result;
              // show save changes button
              this.isCrop = true;
            });
          });
        } else {
          alert("video file is greater than 4mb");
        }
      } else {
        // If the selected file is not a video, show an alert and return
        alert("Please select an Image or a video file");
        return;
      }
    },
    cropImage() {
      if (this.$refs.cropper) {
        this.isCrop = true;
        const croppedCanvas = this.$refs.cropper.getCroppedCanvas();
        croppedCanvas.toBlob((blob) => {
          this.media.file = blob;
          this.$refs.cropper.destroy();
          this.cancelCrop();
        });
      }
    },
    cancelCrop() {
      this.imageSrc = "";
      this.imageLoaded = false;
      $("#exampleModal1").modal("toggle");
    },
    // upload media
    uploadMedia() {
      // send data to the end point
      this.isLoading = true;
      var mediaData = new FormData();
      if (this.media.file) {
        mediaData.append("title", this.media.title);
        mediaData.append("description", this.media.description);
        mediaData.append("type", this.media.type);
        const fileExt = this.media.file.type.split("/")[1];
        mediaData.append(
          "file",
          this.media.file,
          `${this.media.title}.${fileExt}`,
        );
      }
      // call the add media end-point
      MarketPlaceService.addMedia(mediaData, (response) => {
        if (response && response.error === false) {
          Alert.success({
            message: `${this.media.type} uploaded successfully`,
          });
          // reload the window to update changes
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          // set the loader flag to false
          this.isLoading = false;
          Alert.error({
            message: "error please try again later",
          });
        }
        // set the loader flag to false
        this.isLoading = false;
      });
    },
    getMedia() {
      MarketPlaceService.getMedia((response) => {
        this.allMedia = response.data;
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/main.scss";

// certificate session
.upload > h3 {
  color: #4a4754;
  font-family: Maven Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.18px;
}
.warhouse_wrapper_carousel {
  padding: 33px 32px;
  border-radius: 6px;
  background: #fff;
}

.warehouse_wrapper h2 {
  color: #696671;

  /* Headline/24px */
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.24px;
}

.warehouse_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 32px;
}

.warehouse_contents {
  border-radius: 4px;
  border: 1px solid #6ed19a;
  padding: 11px 14px;
}

.warehouse_no {
  color: #047b38;
  font-family: Maven Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 116%;
}

.warehouse_contents p:not(:nth-of-type(1)) {
  color: #696671;
  font-family: Maven Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 116%;

  span {
    font-weight: 800;
  }
}

.inputs_images_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 9px 4px;
  border-radius: 10px;
  border: 1px solid #fff;
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.15);
  width: 280px;

  > div {
    width: 90%;
  }
}

.input_card_body > * {
  margin-bottom: 5px;
}
.input_image_container {
  img,
  video {
    height: 100%;
    width: 100%;
  }
}
.inputs_wrapper {
  display: flex;
  padding: 10px 20px;
  gap: 20px;
  flex-wrap: wrap;
}

.product_title {
  color: #4a4754;

  /* Headline/18px */
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.18px;
}

.product_content {
  color: #4a4754;

  /* Body/regular/12px */
  font-family: Maven Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.48px;
}

.input_footer {
  color: #4a4754;
  font-family: Maven Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.16px;
}

.add-certificate-container {
  border-radius: 3px;
  border: 2px solid #047b38;
  background: #fff;
  width: 250px;
  height: 330px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.add-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    margin-bottom: 25px;
  }
  p {
    color: #047b38;
    font-family: Maven Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }
}
.modal-footer {
  justify-content: center;
  button {
    color: white;
    background: #008d40;
    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      border-color: #05b050 !important;
      box-shadow: 0 0 !important;
      background: #05b050 !important;
    }
  }
}
// label
div.mb-3 label {
  margin-bottom: 0px;
  @include textStyles(Poppins, 300, 14px, 166.67%);
  color: rgba(45, 55, 72, 0.8);
}
// input, select and textarea states
select,
input {
  font-size: 14px;
  color: #000000;

  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    border-color: #05b050 !important;
    box-shadow: 0 0 !important;
  }
}
#required {
  color: red;
  font-family: "Maven Pro";
  font-weight: bolder;
  font-size: 20px;
}

.profile-picture-cropper {
  margin-top: 1rem;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

button {
  margin: 0 0.5rem;
  padding: 0.5rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #eee;
  cursor: pointer;
}

button:hover {
  background-color: #ddd;
}
// video
.media-preview {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  line-height: 166.67%;
  color: rgba(45, 55, 72, 0.8);
}
#V-preview {
  width: 300px;
  height: 230px;
}
.video-fit-cover {
  max-width: 100%;
  height: auto;
}
</style>
