<template>
  <DefaultNav>
    <div class="big-container">
      <div class="headings">
        <h2>Credit Score Result</h2>
      </div>

      <div class="active-subscriptions">
        <template v-for="(data, index) in scoreCard" :key="index">
          <div class="d-flex justify-content-between">
            <div class="d-flex justify-content-between gap-3">
              <div>
                <strong>Name</strong>
                <p class="mt-2">
                  {{ data.farmer[0].farmer.firstname }}
                  {{ data.farmer[0].farmer.lastname }}
                </p>
              </div>
              <div>
                <strong>Email</strong>
                <p class="mt-2">
                  {{ data.farmer[0].farmer.email }}
                </p>
              </div>
              <div>
                <strong>Language</strong>
                <p class="mt-2">
                  {{ data.farmer[0].farmer.languagesspoken }}
                </p>
              </div>
              <div>
                <strong>Phone Number</strong>
                <p class="mt-2">
                  {{ data.farmer[0].farmer.mobile }}
                </p>
              </div>
            </div>
            <button class="green-link check" @click="viewProfile()">
              View Farmer Profile
            </button>
          </div>
          <table class="table-borderless sub-history">
            <tbody class="scrollable">
              <tr>
                <td class="serial">Question</td>
                <td class="theader">Answer</td>
                <td class="theader">Answer Weight</td>
                <td class="theader">Percentage</td>
              </tr>
              <hr />
              <template
                v-for="(dvs, index) in data.records[0].submit"
                :key="index"
              >
                <tr>
                  <td class="serial">{{ dvs.questiontext }}</td>
                  <td class="serial">{{ dvs.optionintext }}</td>
                  <td class="serial">{{ dvs.weightofanswer }}</td>
                  <td class="serial">{{ dvs.weightofanswer / 10 }}%</td>
                </tr>
              </template>
            </tbody>
            <nav class="d-flex justify-content-center my-4">
              <div id="pagination"></div>
            </nav>
          </table>
        </template>
      </div>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import Preloader from "@/layouts/shared/Preloader.vue";
import FarmerService from "@/services/farmer";

export default {
  name: "FarmerCreditScore",
  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      scoreCard: [],
      resultReturned: false,
      response: "",
      farmerid: "",
    };
  },
  mounted() {
    this.isLoading = true;
    const url = window.location.href;
    this.farmerid = url.split("/").slice(-1)[0];

    FarmerService.getCreditScore(this.farmerid, (data) => {
      /* ------------------- check if it returned with an error ------------------- */
      if (data[0].error == false) {
        this.resultReturned = true;
        this.scoreCard = { data: data[1].data };
        console.log(this.scoreCard, { data: data[1].data }, data[1].data);
      } else {
        this.resultReturned = false;
      }
      this.isLoading = false;
    });
  },
  methods: {
    viewProfile() {
      window.location.href = `/dashboard/farmer/view/${this.farmerid}`;
    },
    thousandsData(x) {
      return (
        "₦" +
        Math.round(x)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 2% 5% 2% 3%;
  overflow-y: scroll;
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}
.active-subscriptions {
  background-color: white;
  padding: 24px 35px;
  margin-top: 25px;
  margin-bottom: 50px;
}

/* The slider */

.sub-history {
  margin-top: 50px;
  width: 100%;
  margin-left: 10px;

  h2 {
    margin-bottom: 40px;
  }
  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #4a4754;
  }

  tr {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 9px;
    height: 66px;
  }
  td {
    flex: 1;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4754;
  }
  .active {
    width: 100px;
    height: 26px;
    background: #dcfce7;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .active-dot {
      width: 7.52px;
      height: 8px;
      background: #047b38;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #047b38;
    }
  }
  .expired {
    width: 100px;
    height: 26px;
    background: #fff8e6;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .expired-dot {
      width: 7.52px;
      height: 8px;
      background: #fab900;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #fab900;
    }
  }
  .cancelled {
    width: 100px;
    height: 26px;
    background: rgba(252, 220, 220, 0.93);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .cancelled-dot {
      width: 7.52px;
      height: 8px;
      background: #c52222;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #6b0303;
    }
  }
  a {
    text-decoration: none;
  }
  a:hover td {
    color: #05b050 !important;
  }
}
.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  a {
    text-decoration: none;
  }
}
.green-link {
  color: white;
  padding: 12px 30px;
  margin-left: 15px;
  text-decoration: none;
  background: #05b050;
  border-radius: 5px;
  letter-spacing: 0.02em;
  font-size: 18px;
  border: unset;
  .check {
    font-size: 16px !important;
    padding: 10px 25px !important;
  }
}
</style>
