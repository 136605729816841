import axios from "axios";

import config from "@/config";

const REGISTER_USER_PATH = (type) => (type ? `register/${type}` : "register");
const LOGIN_USER_PATH = () => "login";
const SEND_VERIFICATION_PATH = () => "register/verify";
const VERIFY_CODE_PATH = () => "register/confirm";
const SEND_RESET_EMAIL_PATH = () => "password/email";
const VERIFY_RESET_TOKEN_PATH = () => "password/verify";
const RESET_PASSWORD_PATH = () => "password/reset";
const SAVE_ACCOUNT_DETAILS_PATH = () => "account";
const SAVE_COMPANY_DETAILS_PATH = () => "company";
const CHANGE_PASSWORD_PATH = () => "account/password";

export default {
  loginUser: function (loginData, successCallback, errorCallback) {
    axios
      .post(config.BASE_URL + LOGIN_USER_PATH(), loginData)
      .then((response) => {
        successCallback(response.data);
      })
      .catch((response) => {
        errorCallback(response);
      });
  },

  registerUser: function (registerData, callback) {
    axios
      .post(
        config.BASE_URL + REGISTER_USER_PATH(registerData.type),
        registerData,
      )
      .then((response) => {
        callback(response.data);
      })
      .catch((response) => {
        callback(response.data);
      });
  },

  sendVerification: function (email) {
    axios
      .post(config.BASE_URL + SEND_VERIFICATION_PATH(), { email: email })
      .then((response) => {})
      .catch((error) => {});
  },

  confirmVerification: function (email, code, callback) {
    axios
      .post(config.BASE_URL + VERIFY_CODE_PATH(), { email: email, code: code })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {});
  },

  sendResetEmail: function (email, callback) {
    axios
      .post(config.BASE_URL + SEND_RESET_EMAIL_PATH(), { email: email })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {});
  },

  resetPassword: function (data, callback) {
    axios
      .post(config.BASE_URL + RESET_PASSWORD_PATH(), data)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {});
  },

  verifyResetToken: function (token, callback) {
    axios
      .post(config.BASE_URL + VERIFY_RESET_TOKEN_PATH(), { token: token })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {});
  },
  // change password
  changePassword: function (password, callback) {
    axios
      .post(config.BASE_URL + CHANGE_PASSWORD_PATH(), password)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(error.data);
      });
  },
  saveAccountDetails: function (accountDetais, callback) {
    axios
      .post(config.BASE_URL + SAVE_ACCOUNT_DETAILS_PATH(), accountDetais)
      .then((response) => {
        callback(response.data);
      })
      .catch((response) => {
        callback(response);
      });
  },
  saveCompanyDetails: function (companyDetais, callback) {
    axios
      .post(config.BASE_URL + SAVE_COMPANY_DETAILS_PATH(), companyDetais)
      .then((response) => {
        callback(response.data);
      })
      .catch((response) => {
        callback(response);
      });
  },
};
