<template>
  <div class="main-contents">
    <h1 class="text-center">Verify OTP</h1>
    <form action="" @submit.prevent="verifyOtpWithdraw()">
      <p>A one time password has been sent to your email</p>
      <h5>MAtghh@gmail.com</h5>
      <p>Please input password below to continue</p>
      <div class="form-group">
        <label for="exampleInputEmail1">OTP</label>
        <input
          id="exampleInputEmail1"
          v-model="code"
          type="text"
          class="form-control"
          aria-describedby="emailHelp"
          placeholder="Enter OTP"
          required
        />
      </div>
      <div class="btns-area">
        <button type="submit" class="verify">Verify</button>
        <button class="resend">Resend OTP</button>
      </div>
    </form>
  </div>
</template>

<script>
import MarketPlaceService from "@/services/marketplace";
import Alert from "@/utilities/alert";

export default {
  name: "TransferOTP",
  props: {
    transferToAccount: Function,
  },
  data() {
    return {
      code: null,
    };
  },
  methods: {
    verifyOtpWithdraw() {
      MarketPlaceService.verifyOtpWithdraw(
        {
          code: this.code,
        },
        (response) => {
          console.log(response);
          if (response && response.error == false) {
            this.transferToAccount();
          } else if (response && response.data.error) {
            Alert.error({
              message: response.data.message,
            });
          }
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.main-contents {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  form {
    background: white;
    padding: 50px;
    width: 70% !important;
    display: flex;
    flex-direction: column;
    text-align: center;

    h5 {
      font-family: Poppins;
      font-size: 24px;
      font-weight: 700;
      color: #05b050;
    }
    div {
      margin-top: 25px;
    }
    .btns-area {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      .verify {
        margin-top: 15px;
        height: 50px;
        border: none;
        background: #05b050;
        color: white;
        border-radius: 7px;
      }
      .resend {
        margin-top: 15px;
        height: 50px;
        border: 1px solid #05b050;
        background: white;
        color: #05b050;
        border-radius: 7px;
      }
    }
  }
}
h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #4a4754;
}
</style>
