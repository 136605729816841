<template>
  <DefaultNav>
    <div class="big-container">
      <div class="big-content">
        <h1>Withdraw Funds</h1>
        <form>
          <div class="form-group">
            <label for="">Amount</label>
            <input
              id="formGroupExampleInput"
              v-model="amount"
              type="text"
              class="form-control"
              required
              placeholder="300,000"
            />
          </div>
          <div class="form-group">
            <label for="">OTP</label>
            <input
              id="formGroupExampleInput"
              v-model="code"
              type="text"
              class="form-control"
              required
              placeholder="Enter 6 digit OTP sent to mail"
            />
            <a id="otp" href="#" class="get-otp" @click="getWithdrawalOtp()"
              >Click here to get OTP</a
            >
          </div>

          <!-- <div class="form-group">
            <label for="">Bank</label>
            <select id="" name="" class="form-control">
              <option value="">Select Bank</option>
              <option value="">UBA</option>
              <option value="">UBA</option>
            </select>
          </div> -->

          <!-- <div class="form-group check-area">
                    <input type="checkbox"   required>
                    <label for="">Save Card Details</label>
                </div> -->

          <div class="buttons">
            <div class="form-group">
              <button
                type="button"
                class="btn btn-lg green-btn btn-block btn-hover-state"
                @click="withdraw()"
              >
                Withdraw
              </button>
            </div>
          </div>
          <!-- <p class="last-p">Your personal data will be used to process your order, support your experience throughout
                     this website, and for other purposes described in our privacy policy.</p> -->
        </form>
      </div>
    </div>
  </DefaultNav>
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import MarketplaceService from "@/services/marketplace";
import Alert from "@/utilities/alert";

export default {
  name: "WithdrawWallet",
  components: {
    DefaultNav,
  },
  data() {
    return {
      userData: this.$store.state.user,
      amount: "",
      code: "",
    };
  },

  methods: {
    getWithdrawalOtp() {
      MarketplaceService.getWithdrawalOtp(this.userData.user_id, (response) => {
        if (response && response.error == false) {
          Alert.success({
            message: "OTP sent successfully",
          });
          document.getElementById("otp").innerHTML = "Resend Otp";
        }
      });
    },
    withdraw() {
      MarketplaceService.sendWithdrawalRequest(
        this.userData.user_id,
        {
          amount: this.amount,
          code: this.code,
        },
        (response) => {
          if (response && response.error == false) {
            Alert.success({
              message: "Withdrawal Request sent successfully",
            });
          } else if (response && response.error) {
            Alert.error({
              message: response.message,
            });
          }
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  @include breakpoint-between(md, lg) {
    width: 60.5%;
  }

  @include breakpoint-between(lg, xl) {
    width: 69.5%;
  }

  @include breakpoint-between(xl, xxl) {
    width: 76%;
  }
}

.big-content {
  padding: 100px 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f1f1f1;

  h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 132%;
    margin-bottom: 30px;
    color: #4a4754;
  }

  form {
    width: 60%;
    padding: 35px 50px;
    background: #ffffff;
    border-radius: 4px;

    hr {
      margin-bottom: 40px;
    }

    button {
      margin-top: 30px;
    }

    .check-area {
      display: flex;
      align-items: center;

      label {
        margin-left: 10px;
      }
    }
    .last-p {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #b5b4b9;
      margin-top: 23px;
    }
  }

  .payment-type {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 50px;
  }
  .each-radio {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}
.get-otp {
  color: #008d40;
  margin-top: 5px;
}
</style>
