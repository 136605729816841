<template>
  <DefaultNav>
    <div class="big-container">
      <form class="mt-4" @submit.prevent="saveData()">
        <div class="headings">
          <h2>Credit Scoring</h2>
          <div class="right-btns">
            <!-- <a href="/dashboard/collateral/view" class="yellow-link"
              >View Results</a
            > -->
            <button class="green-link">Save</button>
          </div>
        </div>
        <div class="mb-3">
          <h6>
            <strong>All fields required</strong>
          </h6>
        </div>
        <div class="mb-4">
          <label for="">What kind of crops do you farm? </label>
          <input
            v-model="crop"
            type="text"
            class="form-control"
            placeholder="Enter your answer"
            required
          />
        </div>
        <div class="mb-4">
          <label for="">What is your monthly estimated income?</label>
          <input
            v-model="estimate_monthly_income"
            type="text"
            class="form-control"
            placeholder="Enter your answer"
            required
          />
        </div>
        <div class="row mb-4">
          <div class="col">
            <label for="">Do you intercrop? </label>
            <select
              v-model="intercropping"
              class="form-select"
              aria-label="Default select example"
              required
            >
              <option selected>Choose...</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <div class="col">
            <label for="">Are you in a cooperative? </label>
            <select
              v-model="is_in_a_cooperative"
              class="form-select"
              aria-label="Default select example"
              required
            >
              <option selected>Choose...</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
        </div>
        <div class="mb-4">
          <label for="">How many machines do you have </label>
          <input
            v-model="machines"
            type="number"
            min="0"
            class="form-control"
            placeholder="Enter your answer"
            required
          />
        </div>
        <div class="mb-4">
          <label for=""
            >How many times do you request for agronomist visit in a year?
          </label>
          <input
            v-model="no_of_agronomist_visits"
            type="number"
            min="0"
            class="form-control"
            placeholder="Enter your answer"
            required
          />
        </div>
        <div class="row mb-4">
          <div class="col">
            <label for="">How many crops do you cultivate?</label>
            <input
              v-model="number_of_crops"
              type="number"
              min="0"
              class="form-control"
              placeholder="Enter your answer"
              required
            />
          </div>
          <div class="col">
            <label for="">How many lands do you have?</label>
            <input
              v-model="number_of_land"
              type="number"
              min="0"
              class="form-control"
              placeholder="Enter your answer"
              required
            />
          </div>
        </div>
        <div class="mb-4">
          <label for="">Are you the owner or caretaker? </label>
          <select
            v-model="owner_caretaker"
            class="form-select"
            aria-label="Default select example"
            required
          >
            <option selected>Choose...</option>
            <option value="owner">Owner</option>
            <option value="caretaker">Caretaker</option>
          </select>
        </div>
        <div class="mb-4">
          <label for="">Do you own a bank account? </label>
          <select
            v-model="owns_a_bank_account"
            class="form-select"
            aria-label="Default select example"
            required
          >
            <option selected>Choose...</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
        <div class="mb-4">
          <label for="">What is the size of your farm (in acres)? </label>
          <input
            v-model="size_of_farm"
            type="number"
            min="0"
            class="form-control"
            placeholder="Enter your answer"
            required
          />
        </div>
        <div class="mb-4">
          <label for=""> How many years have you been cultivating for? </label>
          <input
            v-model="years_cultivating"
            type="number"
            min="0"
            class="form-control"
            placeholder="Enter your answer"
            required
          />
        </div>
      </form>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
// Alert box
import Alert from "@/utilities/alert";
import Preloader from "@/layouts/shared/Preloader.vue";
import axios from "axios";
export default {
  name: "CreditScoreAnalysis",
  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      age: "",
      gender: "",
      address: "",
      intercropping: "",
      estimate_monthly_income: "",
      crop: "",
      is_in_a_cooperative: "",
      machines: "",
      no_of_agronomist_visits: "",
      number_of_crops: "",
      number_of_land: "",
      owner_caretaker: "",
      owns_a_bank_account: "",
      size_of_farm: "",
      years_cultivating: "",
    };
  },
  methods: {
    async saveData() {
      let vm = this;
      const csData = JSON.parse(localStorage.getItem("creditScoreData"));
      let dt = {
        bvn: csData.bvn,
        age: csData.age,
        gender: csData.gender,
        address: csData.address,
        intercropping: vm.intercropping,
        estimate_monthly_income: vm.estimate_monthly_income,
        crop: vm.crop,
        is_in_a_cooperative: vm.is_in_a_cooperative,
        machines: vm.machines,
        no_of_agronomist_visits: vm.no_of_agronomist_visits,
        number_of_crops: vm.number_of_crops,
        number_of_land: vm.number_of_land,
        owner_caretaker: vm.owner_caretaker,
        owns_a_bank_account: vm.owns_a_bank_account,
        size_of_farm: vm.size_of_farm,
        years_cultivating: vm.years_cultivating,
      };
      this.isLoading = true;
      await axios
        .post("http://174.129.139.68/api/scorecard/add", dt)
        .then((data) => {
          let response = data.data;
          if (response.error == false) {
            Alert.success({
              message: "Score card generated successfully",
            });
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            Alert.error({
              message: response.message,
            });
          }
          vm.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 2% 5% 2% 3%;
  overflow-y: scroll;
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}
.active-subscriptions {
  background-color: white;
  padding: 24px 65px;
  margin-top: 40px;

  h2 {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #696671;
  }
}

/* The slider */
.sub-history {
  margin-top: 50px;
  width: 100%;
  margin-left: 10px;

  h2 {
    margin-bottom: 40px;
  }
  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #4a4754;
  }
}
.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  a {
    text-decoration: none;
  }
  .right-btns {
    display: flex;
    gap: 20px;
  }
  .green-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 145px;
    height: 40px;
    background: #05b050;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
  }
}
.green-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 145px;
  height: 40px;
  background: #05b050;
  border-radius: 5px;
  letter-spacing: 0.02em;
}
.yellow-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  width: 170px;
  height: 40px;
  background: #fab900 !important;
  border-radius: 5px;
  letter-spacing: 0.02em;
}
a {
  text-decoration: none;
}
.small-font {
  font-size: 11px !important;
}
</style>
