<template>
  <DefaultNav>
    <div class="big-container">
      <!-- product preview -->
      <div v-if="auction" class="auction_product_preview">
        <div class="go-row">
          <a href="javascript:void(0)"
            ><img
              class="small-arrow"
              src="@/assets/images/vectors/arrowleft.png"
              alt=""
              @click="$router.back()"
          /></a>
          <h2>
            <span class="name">{{ auction.subcategory.name }}</span> -
            <span
              class="name"
              :style="{ color: auction.specification.color }"
              >{{ auction.specification.color }}</span
            >
          </h2>
        </div>
        <!-- left -->
        <div class="bid-details">
          <div class="each-detail">
            <p>Status:</p>
            <p>{{ status }}</p>
          </div>
          <div class="each-detail">
            <p>Bid Date Start:</p>
            <p>{{ auction.auction.start_date }}</p>
          </div>

          <div class="each-detail">
            <p>Bid Date End:</p>
            <p>{{ auction.auction.end_date }}</p>
          </div>
          <div v-if="status != 'Inactive'" class="each-detail">
            <p>Minimum Bid:</p>
            <p>{{ auction.currency }}{{ auction.auction.minimum_bid }}</p>
          </div>
          <div v-if="status != 'Inactive'" class="each-detail">
            <p>Days Remaining:</p>
            <p>{{ durations }}</p>
          </div>
        </div>
        <form v-if="!isMerchant && auctionIsActive" @submit.prevent="addBid()">
          <!-- input -->
          <div class="column-flex">
            <label for="formGroupExampleInput" class="form-label mb-0"
              >Enter Bid Amount</label
            >
            <input
              id="formGroupExampleInput"
              v-model="amount"
              type="number"
              class="form-control"
              placeholder="&#8358;"
              required
            />
          </div>
          <!-- button for highest bidder -->
          <button
            :id="['next_btn']"
            :class="['btn', 'btn-primary']"
            type="submit"
          >
            Start BId
          </button>
        </form>
        <div v-if="status != 'Inactive'" class="right">
          <!-- form -->

          <h3 v-if="!auctionIsActive">Auction has ended</h3>
          <!-- button -->
          <button
            v-if="!auctionIsActive && specialAccess"
            :id="['next_btn']"
            :class="['btn', 'btn-primary', 'my-4', 'd-block']"
            href="javascript:void(0)"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            Proceed To Order
          </button>
        </div>
        <!-- if inactive -->
        <div>
          <div v-if="status == 'Inactive'" id="inactive_auction" class="right">
            <p>{{ message }}</p>
          </div>
        </div>
      </div>
      <div class="row table-area">
        <div class="col-12 big-table">
          <div class="theading">
            <h4>Bids</h4>
          </div>
          <table class="table table-borderless">
            <thead>
              <th>Date</th>
              <th>Bidder ID</th>
              <th>Amount</th>
            </thead>

            <tbody>
              <tr v-for="bid in paginatedList" :key="bid.id">
                <td>{{ bid.created_at }}</td>
                <td>{{ bid.user_id }}</td>
                <td>{{ bid.amount }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <nav class="d-flex justify-content-center my-4">
        <div id="pagination"></div>
      </nav>
    </div>

    <!-- Modal -->
    <div
      v-if="auction"
      id="exampleModal"
      class="modal fade"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">Accept Offer</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <h4 class="text-center">Product Category</h4>
            <div class="actual-detailss">
              <div class="left-lines">
                <div class="each-detail">
                  <div class="each-detail">
                    <h4>Quantity</h4>
                    <p>{{ auction.specification.qty }}</p>
                  </div>
                  <div class="each-detail">
                    <h4>Price</h4>
                    <p>{{ auction.currency }}{{ price }}</p>
                  </div>
                  <h4>Product Category</h4>
                  <p>{{ auction.category.name }}</p>
                </div>
                <div class="each-detail">
                  <h4>Color</h4>
                  <p>{{ auction.specification.color }}</p>
                </div>
                <div class="each-detail">
                  <h4>Moisture</h4>
                  <p>{{ auction.specification.moisture }}%</p>
                </div>
                <div class="each-detail">
                  <h4>Foreign Matter (FM)</h4>
                  <p>{{ auction.specification.foreign_matter }}%</p>
                </div>
                <div class="each-detail">
                  <h4>Broken Grains</h4>
                  <p>{{ auction.specification.broken_grains }}%</p>
                </div>
                <div class="each-detail">
                  <h4>Weevil</h4>
                  <p>{{ auction.specification.weevil }}%</p>
                </div>
              </div>
              <div class="right-line">
                <div class="each-detail">
                  <h4>Damaged kernel</h4>
                  <p>{{ auction.specification.dk }}%</p>
                </div>
                <div class="each-detail">
                  <h4>Rotten Shriveled</h4>
                  <p>{{ auction.specification.rotten_shriveled }}%</p>
                </div>
                <div class="each-detail">
                  <h4>Test Weight</h4>
                  <p>{{ auction.specification.test_weight }}</p>
                </div>
                <div class="each-detail">
                  <h4>Hardness</h4>
                  <p>{{ auction.specification.hardness }}</p>
                </div>
                <div class="each-detail">
                  <h4>Split</h4>
                  <p>{{ auction.specification.splits }}%</p>
                </div>
                <div class="each-detail">
                  <h4>Oil Content</h4>
                  <p>{{ auction.specification.oil_content }}%</p>
                </div>
                <div class="each-detail">
                  <h4>Infestation</h4>
                  <p>{{ auction.specification.infestation }}%</p>
                </div>
                <div class="each-detail">
                  <h4>Grain size</h4>
                  <p>{{ auction.specification.grain_size }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <form class="d-flex flex-column w-100" @submit.prevent="proceed">
              <div class="accept-box mb-4">
                <input type="checkbox" required class="mt-0" />
                <p class="mb-0">I accept the offer and proceed to payment</p>
              </div>

              <button id="modalProceedBtn" type="submit" class="fulfil">
                Proceed
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import MarketPlaceService from "@/services/marketplace";
import Preloader from "@/layouts/shared/Preloader.vue";
import Alert from "@/utilities/alert";

export default {
  name: "Bid",

  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      auction: null,
      amount: "",
      status: "",
      message: "",
      bids: "",
      paginatedList: [],
      specialAccess: false,
      isLoading: false,
      quantity: "",
      price: "",
    };
  },
  computed: {
    durations() {
      let diff = this.getDateDifference();
      let diffInSeconds = diff / 1000;
      let days = Math.floor(diffInSeconds / (3600 * 24));
      return `${days} days`;
    },

    auctionIsActive() {
      let diff = this.getDateDifference();
      let diffInSeconds = diff / 1000;
      let minutes = Math.floor(diffInSeconds / 60);
      return minutes > 1;
    },
    bidIsHigher() {
      var maxBid = 0;
      this.bids.forEach((bid) => {
        maxBid = eval(bid.amount) > maxBid ? eval(bid.amount) : maxBid;
      });
      return this.amount > maxBid;
    },
  },
  mounted() {
    this.getAuction();
    this.getBids();
  },

  methods: {
    getDateDifference() {
      let start = new Date(this.auction.auction.start_date);
      let today = new Date();
      let end = new Date(this.auction.auction.end_date);
      let diff = end - today;
      return diff;
    },
    getAuction() {
      MarketPlaceService.getCropById(this.$route.params.id, (response) => {
        if (response && response.error == false) {
          this.auction = response.data;
          // set quantity
          this.quantity = this.auction.specification.qty;
          this.checkStatus();
        }
      });
    },
    // fetch all bids
    getBids() {
      MarketPlaceService.getBids(this.$route.params.id, (response) => {
        if (response && response.error == false) {
          this.bids = response.data;
          // sort bids by the highest amount
          this.bids.sort((a, b) => b.amount - a.amount);
          // find the highest bidder
          const highestBidder = this.bids[0];
          // allow special access for the highest bidder
          if (
            highestBidder.user_id == this.userData.user.id &&
            !this.auctionIsActive
          ) {
            this.specialAccess = true;
            this.price = highestBidder.amount;
          }
          this.paginate(response.data, "paginatedList");
        }
      });
    },
    // check for current maximium bid
    addBid() {
      if (this.amount >= this.auction.auction.minimum_bid) {
        let vm = this;
        // send data to the end-point
        if (this.bidIsHigher) {
          this.isLoading = true;

          MarketPlaceService.addBid(
            {
              amount: this.amount,
              cropId: this.$route.params.id,
            },
            (response) => {
              if (response && response.error == false) {
                Alert.success({
                  message: "Bid added successfully",
                });
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              } else {
                Alert.error({
                  message: response.message,
                });
              }
              vm.isLoading = false;
            },
          );
        } else {
          Alert.error({
            message: "Bid must be higher than the latest bid",
          });
        }
      } else {
        Alert.error({
          message: "Your bid is lower than the minimum bid",
        });
      }
    },

    jsonStuff(eachSale) {
      if (eachSale.tracking_details) {
        return JSON.parse(eachSale.tracking_details);
      } else {
        return {};
      }
    },
    checkStatus() {
      if (this.auctionIsActive) {
        this.status = "Active";
      } else {
        this.status = "Inactive";
        this.message = "Auction for this product is currently inactive";
      }
    },

    proceed() {
      // get the proceed btn on the modal
      this.isLoading = true;

      MarketPlaceService.fulfilCropOffer(
        {
          crop_id: this.auction.id,
          quantity: this.quantity,
        },
        (response) => {
          if (!response.data.error) {
            window.location.replace(
              `/dashboard/marketplace/order/${response.data.order_hash}`,
            );
          }
          this.isLoading = false;
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 1% 5% 2% 5%;

  @include breakpoint-between(md, lg) {
    min-width: 60.5%;
  }

  @include breakpoint-between(lg, xl) {
    min-width: 69.5%;
  }

  @include breakpoint-between(xl, xxl) {
    min-width: 76%;
  }
}

// Product preview for auction crop
.auction_product_preview {
  background: white;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  border-radius: 4px;
  padding: 30px 50px;
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  .bid-details {
    display: flex;
    justify-content: space-around;
    margin-left: 30px;
  }
  button {
    margin-top: 30px;
    background: #05b050;
    border: 1px solid #ededee;
    box-shadow:
      0px 2px 4px rgba(44, 39, 56, 0.0001),
      0px 4px 8px rgba(44, 39, 56, 0.08);
    border-radius: 4px;
    text-align: center;
    color: #e6f7ee;
    padding-inline: 3rem;
    @include textStyles("Maven Pro", 700, 16px, 24px);

    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      border-color: #05b050 !important;
      box-shadow: 0 0 !important;
      background: #05b050;
    }
  }
}
.right {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.welcome-text {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #262c3f;
  color: white;
  height: 90px;

  h2 {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    color: white;
  }
}

.table-area {
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
}

.big-table {
  background-color: white;
  padding: 30px;

  th {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #4a4754;
    border-bottom: 0.7px solid #ededee;
    padding: 20px 25px;
  }

  td {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #696671;
    padding: 20px 25px;
  }
}
.column-flex {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
}
.theading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;

  h4 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 132%;
    color: #05b050;
  }
}

form {
  margin-left: 70px;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 50px;
  input {
    width: 350px;
  }
}
// pagination

nav {
  align-self: center;
  margin-top: 2rem;

  a {
    background: #2c3e50;
  }
}

select,
input {
  font-size: 14px;
  color: #000000;

  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    border-color: #05b050 !important;
    box-shadow: 0 0 !important;
  }

  &::placeholder {
    @include textStyles("Maven Pro", 700, 14px, 24px);
    color: #000000;
  }
}

label {
  font-size: 14px;
  font-weight: 900;
  color: rgba(45, 55, 72, 0.8);
}

p {
  @include textStyles("Maven Pro", 700, 16px, 24px);
}

span:not(.name) {
  font-size: 14px;
  font-weight: 500;
}

div#inactive_auction {
  display: flex;
  align-items: center;
  color: red;
}

.small-arrow {
  width: 26px;
}

.go-row {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  align-items: center;

  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 43px;
    color: #4a4754;
    margin: 0px;
  }
}

.actual-detailss {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;

  .left-lines {
    width: 50%;
  }

  .each-detail {
    margin-top: 30px;

    h4 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }
  }
}
.accept-box {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
}
.fulfil {
  height: 50px;
  width: 50%;
  align-self: center;
  background: #05b050;
  border: 1px solid #ededee;
  box-shadow:
    0px 2px 4px rgba(44, 39, 56, 0.0001),
    0px 4px 8px rgba(44, 39, 56, 0.08);
  border-radius: 4px;
  text-align: center;
  color: #e6f7ee;
  padding-inline: 3rem;
  @include textStyles("Maven Pro", 700, 16px, 24px);

  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    border-color: #05b050 !important;
    box-shadow: 0 0 !important;
    background: #05b050;
  }
}
</style>
