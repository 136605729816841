<template>
  <DefaultNav>
    <div class="big-container">
      <!-- header -->
      <form @submit.prevent="saveData()">
        <div class="form">
          <div class="headings">
            <h2>Add Farmer</h2>
            <div class="right-btns">
              <a
                href="/dashboard/farmer/batch/add"
                class="green-link text-center"
                >Batch Upload</a
              >
              <a href="/dashboard/farmer/view" class="yellow-link"
                >View Farmers</a
              >
              <button class="green-link">Save</button>
            </div>
          </div>
          <div class="mb-3">
            <h6>
              <strong v-if="editing == false" class="text-danger"
                >* All fields required</strong
              >
            </h6>
          </div>
          <div class="d-flex gap-3">
            <!-- input -->
            <div class="w-100 mb-3">
              <label for="exampleInputEmail1" class="form-label mb-0"
                ><span v-if="editing == false" class="text-danger">*</span>
                First Name</label
              >
              <input
                v-model="firstname"
                type="text"
                class="form-control"
                required
                placeholder="Enter your first name"
              />
            </div>
            <!-- input -->
            <div class="w-100 mb-3">
              <label for="exampleInputEmail1" class="form-label mb-0"
                ><span v-if="editing == false" class="text-danger">*</span>
                Middle Name</label
              >
              <input
                v-model="middlename"
                type="text"
                class="form-control"
                placeholder="Enter your Middle name"
              />
            </div>
          </div>
          <div class="d-flex gap-3">
            <!-- input -->
            <div class="w-100 mb-3">
              <label for="exampleInputEmail1" class="form-label mb-0"
                ><span v-if="editing == false" class="text-danger">*</span>
                Surname</label
              >
              <input
                v-model="surname"
                type="text"
                class="form-control"
                required
                placeholder="Enter your first surname"
              />
            </div>
            <!-- input -->
            <div class="w-100 mb-3">
              <label for="exampleInputEmail1" class="form-label mb-0"
                ><span v-if="editing == false" class="text-danger">*</span>
                Telephone</label
              >
              <input
                v-model="telephone"
                type="tel"
                class="form-control"
                required
                placeholder="Enter your Phone number"
              />
            </div>
          </div>
          <div class="d-flex gap-3">
            <!-- input -->
            <div class="w-100 mb-3">
              <label for="exampleInputEmail1" class="form-label mb-0"
                ><span v-if="editing == false" class="text-danger">*</span>
                Email address</label
              >
              <input
                v-model="email"
                type="email"
                class="form-control"
                placeholder="Enter your email address"
              />
            </div>
            <!-- input -->

            <div class="w-100 mb-3">
              <label for="exampleInputEmail1" class="form-label mb-0"
                ><span v-if="editing == false" class="text-danger">*</span>
                Gender</label
              >
              <select
                v-model="gender"
                class="form-select"
                aria-label="Default select example"
                required
              >
                <option value="" selected>Select option</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
          </div>
          <div class="d-flex gap-3">
            <!-- input -->
            <div class="w-100 mb-3">
              <label for="exampleInputEmail1" class="form-label mb-0">
                <span v-if="editing == false" class="text-danger">*</span>
                Marital status</label
              >
              <select
                v-model="maritalstatus"
                class="form-select"
                aria-label="Default select example"
                required
              >
                <option value="" selected>Select option</option>
                <option value="single">Single</option>
                <option value="married">Married</option>
              </select>
            </div>
            <!-- input -->
            <div class="w-100 mb-3">
              <label for="exampleInputEmail1" class="form-label mb-0"
                ><span v-if="editing == false" class="text-danger">*</span> Date
                of Birth</label
              >
              <input
                v-model="dateofbirth"
                type="date"
                class="form-control"
                required
                placeholder="What is your date of birth"
              />
            </div>
          </div>
          <!-- input -->
          <div class="w-100 mb-3">
            <label for="exampleInputEmail1" class="form-label mb-0">
              <span v-if="editing == false" class="text-danger">*</span>
              languages you can speak
              <em>(Please separate each language by comma[,])</em></label
            >
            <input
              v-model="language"
              type="text"
              class="form-control"
              placeholder="Enter your language"
            />
          </div>
        </div>
        <!-- input -->
        <div class="d-flex gap-3">
          <!-- input -->
          <div class="w-100 mb-3">
            <label for="exampleInputEmail1" class="form-label mb-0">
              <span v-if="editing == false" class="text-danger">*</span>
              State of origin</label
            >
            <select
              v-model="stateoforigin"
              class="form-select"
              aria-label="Default select example"
              required
            >
              <option value="" selected="selected">Select State</option>
              <option value="Abia">Abia</option>
              <option value="Abuja Federal Capital Territory">
                Abuja Federal Capital Territory
              </option>
              <option value="Adamawa">Adamawa</option>
              <option value="Akwa Ibom">Akwa Ibom</option>
              <option value="Anambra">Anambra</option>
              <option value="Bauchi">Bauchi</option>
              <option value="Bayelsa">Bayelsa</option>
              <option value="Benue">Benue</option>
              <option value="Borno">Borno</option>
              <option value="Cross River">Cross River</option>
              <option value="Delta">Delta</option>
              <option value="Ebonyi">Ebonyi</option>
              <option value="Edo">Edo</option>
              <option value="Ekiti">Ekiti</option>
              <option value="Enugu">Enugu</option>
              <option value="Gombe">Gombe</option>
              <option value="Imo">Imo</option>
              <option value="Jigawa">Jigawa</option>
              <option value="Kaduna">Kaduna</option>
              <option value="Kano">Kano</option>
              <option value="Katsina">Katsina</option>
              <option value="Kebbi">Kebbi</option>
              <option value="Kogi">Kogi</option>
              <option value="Kwara">Kwara</option>
              <option value="Lagos">Lagos</option>
              <option value="Nasarawa">Nasarawa</option>
              <option value="Niger">Niger</option>
              <option value="Ogun">Ogun</option>
              <option value="Ondo">Ondo</option>
              <option value="Osun">Osun</option>
              <option value="Oyo">Oyo</option>
              <option value="Plateau">Plateau</option>
              <option value="Rivers">Rivers</option>
              <option value="Sokoto">Sokoto</option>
              <option value="Taraba">Taraba</option>
              <option value="Yobe">Yobe</option>
              <option value="Zamfara">Zamfara</option>
            </select>
          </div>
          <div class="w-100 mb-3">
            <label class="form-label mb-0"
              ><span v-if="editing == false" class="text-danger">*</span>
              City</label
            >
            <input
              v-model="city"
              type="text"
              class="form-control"
              required
              placeholder="Enter your permanent address"
            />
          </div>
        </div>
        <!-- input -->
        <div class="w-100 mb-3">
          <label for="exampleInputEmail1" class="form-label mb-0"
            ><span v-if="editing == false" class="text-danger">*</span>
            Permanent address</label
          >
          <input
            v-model="permanentaddress"
            type="text"
            class="form-control"
            required
            placeholder="Enter your permanent address"
          />
        </div>
        <!-- input -->
        <div class="w-100 mb-3">
          <label for="exampleInputEmail1" class="form-label mb-0"
            ><span v-if="editing == false" class="text-danger">*</span>
            Landmark</label
          >
          <input
            v-model="landmark"
            type="text"
            class="form-control"
            placeholder="Enter your permanent address"
          />
        </div>
      </form>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import Preloader from "@/layouts/shared/Preloader.vue";
import FarmerService from "@/services/farmer";
import Alert from "@/utilities/alert";
import NilData from "@/utilities/returnNil";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "Kyf",
  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      farmereditid: "",
      firstname: "",
      middlename: "",
      surname: "",
      telephone: "",
      email: "",
      gender: "",
      maritalstatus: "",
      language: "",
      landmark: "",
      permanentaddress: "",
      stateoforigin: "",
      dateofbirth: "",
      city: "",
      editing: false,
      editData: [],
    };
  },
  mounted() {
    const url = window.location.href;
    this.farmereditid = url.split("/").slice(-1)[0];
    this.isLoading = true;
    console.log(this.farmereditid);
    if (url.split("/").slice(-2)[0] == "edit") {
      this.editing = true;
      let farmerData = JSON.parse(localStorage.getItem("farmersdata"));

      this.editData.push(farmerData);
      this.firstname = farmerData.farmer.firstname;
      this.middlename = NilData.returnEmptyField(farmerData.farmer.middlename);
      this.surname = farmerData.farmer.lastname;
      this.telephone = farmerData.farmer.mobile;
      this.email = NilData.returnEmptyField(farmerData.farmer.email);
      this.gender = farmerData.farmer.gender.toLowerCase();
      this.maritalstatus = farmerData.farmer.maritalstatus.toLowerCase();
      this.dateofbirth = farmerData.farmer.dateofbirth;
      this.language = farmerData.farmer.languagesspoken;
      this.stateoforigin = farmerData.farmer.state;
      this.city = farmerData.farmer.city;
      this.country = farmerData.farmer.country;
      this.permanentaddress = farmerData.farmer.address;
      this.landmark = farmerData.farmer.landmark;
      this.isLoading = false;
    } else {
      this.isLoading = false;
      this.editing = false;
    }
  },
  methods: {
    async saveData() {
      // this.isLoading = true;
      /* -------------------------- run edit on the data -------------------------- */
      if (this.editing == true) {
        let vm = this;
        console.log(this.editData);
        this.editData[0].farmer.firstname = vm.firstname;
        this.editData[0].farmer.lastname = vm.surname;
        this.editData[0].farmer.middlename = vm.middlename;
        this.editData[0].farmer.email = vm.email;
        this.editData[0].farmer.mobile = vm.telephone;
        this.editData[0].farmer.gender = vm.gender;
        this.editData[0].farmer.maritalstatus = vm.maritalstatus;
        this.editData[0].farmer.languagesspoken = vm.language;
        this.editData[0].farmer.dateofbirth = vm.dateofbirth;
        this.editData[0].farmer.state = vm.stateoforigin;
        this.editData[0].farmer.city = vm.city;
        this.editData[0].farmer.address = vm.permanentaddress;
        this.editData[0].farmer.landmark = vm.landmark;

        let dt = {
          farmerid: vm.farmereditid,
          farmerjson: this.editData[0],
        };
        console.log(dt);
        // return false;

        FarmerService.updateFarmer(dt, (data) => {
          /* ------------------- check if it returned with an error ------------------- */
          if (data[0].error == false) {
            Alert.success({
              message: data[1].message,
            });
            setTimeout(() => {
              window.location.href = `/dashboard/farmer/view/${vm.farmereditid}`;
            }, 2000);
          } else {
            Alert.error({
              message: data[1].message,
            });
          }
          this.isLoading = false;
        });
      } else {
        /* --------------------- create new farmer account data --------------------- */
        let vm = this;
        let dt = {
          farmerid: `ZOWAFARMER-${uuidv4()}`,
          email: NilData.sendNAtoDB(vm.email),
          firstname: vm.firstname,
          middlename: NilData.sendNAtoDB(vm.middlename),
          lastname: vm.surname,
          mobile: vm.telephone,
          gender: vm.gender,
          languagesspoken: vm.language,
          dateofbirth: vm.dateofbirth,
          maritalstatus: vm.maritalstatus,
          country: "Nigeria",
          city: vm.city,
          address: vm.permanentaddress,
          state: vm.stateoforigin,
          landmark: vm.landmark,
        };

        FarmerService.createFarmer(dt, (data) => {
          /* ------------------- check if it returned with an error ------------------- */
          if (data[0].error == false) {
            Alert.success({
              message: data[1].message,
            });
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            Alert.error({
              message: data[1].message,
            });
          }
          this.isLoading = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 4% 5% 8% 3%;
  overflow-y: scroll;

  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}

.active-subscriptions {
  background-color: white;
  padding: 24px 65px 50px;
  margin-top: 40px;

  h2 {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #696671;
  }
}

/* The slider */
.sub-history {
  margin-top: 50px;
  width: 100%;
  margin-left: 10px;

  h2 {
    margin-bottom: 40px;
  }

  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #4a4754;
  }
}

.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 70px;

  a {
    text-decoration: none;
  }

  .right-btns {
    display: flex;
    gap: 20px;
  }

  .green-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 145px;
    height: 40px;
    background: #05b050;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
  }
}

.green-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 145px;
  height: 40px;
  background: #05b050;
  border-radius: 5px;
  letter-spacing: 0.02em;
}

.yellow-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  width: 145px;
  height: 40px;
  background: #fab900 !important;
  border-radius: 5px;
  letter-spacing: 0.02em;
}

a {
  text-decoration: none;
}

.small-font {
  font-size: 11px !important;
}
</style>
