import MarketPlaceService from "@/services/marketplace";
import config from "../config";
import Cart from "./cart";
import Helpers from "./helpers";
import Preloader from "@/layouts/shared/Preloader.vue";
import { NavigationFailureType, isNavigationFailure } from "vue-router";

export default {
  components: {
    Preloader,
  },
  data() {
    return {
      userData: this.$store.state.user,
      companyData: this.$store.state.user?.user?.company,
      isLoading: false,
      appConfig: config,
      kybStatus: this.$store.state.kybstatus,
      kycStatus: this.$store.state.kycstatus,
      kycDetails: this.$store.state.kycDetails,
      documents: this.$store.state.documents,
    };
  },
  mixins: [Cart, Helpers],
  computed: {
    isMerchant() {
      return this.userData.user.type == "merchant";
    },
    isCorporate() {
      return this.userData.user.type == "corporate";
    },
    isSeller() {
      // Assuming that order data is available
      return this.order.seller_id
        ? this.order.seller_id == this.userData.user_id
        : this.order.products[0].user_id == this.userData.user_id;
    },
    isBuyer() {
      return this.order.buyer_id == this.userData.user_id;
    },
    maxDate: function () {
      var now = new Date();
      now.setFullYear(now.getFullYear() - 18);
      return now.toISOString().split("T")[0];
    },
    userLevel: function () {
      return this.userData.type;
    },
    // user profile image
    previewImageUrl: function () {
      if (this.imageBlob) {
        return URL.createObjectURL(this.imageBlob);
      }

      if (this.userData.user.image && this.userData.user.image != "") {
        return this.userData.user.image;
      }

      return false;
    },
  },
  mounted() {},
  methods: {
    addScript(src) {
      //Helper to put script in page
      var script = document.createElement("script");
      script.src = src;
      document.body.appendChild(script);
    },
    generateRandom(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength),
        );
      }
      return result;
    },
    paginate(dataSource, listToUpdate) {
      let vm = this;
      $("#pagination").pagination({
        dataSource: dataSource,
        pageSize: 10,
        callback: function (data, pagination) {
          vm[listToUpdate] = data;
        },
      });
    },
    placeString(string, defaultValue, data = {}) {
      let extra = {
        prefix: "",
        suffix: "",
        ...data,
      };
      return string && string != ""
        ? `${extra.prefix}${string}${extra.suffix}`
        : defaultValue;
    },
    async logOut() {
      if (this.$store.state.authData.token) {
        this.$store.dispatch("setAuth", null);
        this.$store.dispatch("setUser", null);

        // This is to handle navigation failure
        const failure = await this.$router.push("/login");
        if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
          // Reload the page if there is a navigation failure
          window.location.reload();
        }
      }
    },

    // for the Kyc
    getKycStatus() {
      // Assuming MarketPlaceService.getKycStatus accepts a callback function
      MarketPlaceService.getKycStatus((response) => {
        if (response && response.error === false) {
          const { applicant, status, documents } = response.data;

          this.$store.dispatch("setKycDetails", applicant);
          this.$store.dispatch("setKyc", status);
          this.$store.dispatch("setDocuments", documents);

          this.checkKyc = status;
        }
      });
    },

    // for the Kyc
    getKybStatus() {
      // send data to the end-point
      MarketPlaceService.getKybStatus((response) => {
        if (response && response.error == false) {
          this.$store.dispatch("setKyb", response.data.status);
        }
      });
    },
    getKybDetails() {
      MarketPlaceService.getKybDetails((response) => {
        if (response && response.error == false) {
          // assign the retrieved kyb data to their respect data variables
          this.$store.dispatch("setKybDetails", response.data);
        }
      });
    },
  },
};
