<template>
  <div class="profile-body">
    <h2>Account Details</h2>
    <!-- profile avatar -->
    <div class="user-avatar">
      <div class="avatar-container position-relative">
        <!-- user avatar -->
        <img
          v-if="previewImageUrl"
          :src="previewImageUrl"
          alt="useravatar"
          class="img-fluid avatar-image"
        />
        <img
          v-if="!previewImageUrl"
          src="@/assets/images/vectors/avatar.png"
          alt="useravatar"
          class="img-fluid avatar-image"
        />
        <!-- display absolute at top right -->
        <div
          class="edit-icon-container position-absolute d-flex flex-row justify-content-center align-items-center"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal1"
        >
          <!-- edit icon -->
          <a href="javascript:void(0)">
            <img
              src="@/assets/images/vectors/Edit.svg"
              alt=""
              class="img-fluid"
            />
          </a>
        </div>
      </div>
      <!-- verification -->
      <div
        class="verification-container d-flex flex-row justify-content-start align-items-center"
      >
        <div class="check-mark-box">
          <img
            v-if="kycStatus.toLowerCase() == 'verified'"
            src="@/assets/images/vectors/verified.svg"
            alt=""
            class="img-fluid"
          />
        </div>
        <div class="verified">{{ kycStatus }}</div>
      </div>
    </div>
    <!-- account details -->
    <div class="user-account-details d-flex flex-column w-100">
      <!-- account setting -->
      <div class="form-wrapper d-flex flex-row">
        <!-- left hand  -->
        <form class="company-form" @submit.prevent="saveAccountDetails()">
          <!-- row -->
          <div class="input-row-container d-flex flex-row align-items-center">
            <div class="mb-3 w-100">
              <label for="exampleInputEmail1" class="form-label"
                >First name</label
              >
              <input
                id="exampleInputEmail1"
                v-model="account.first_name"
                type="text"
                class="form-control"
                aria-describedby="emailHelp"
                required
              />
            </div>

            <div class="mb-3 w-100">
              <label for="exampleInputEmail1" class="form-label"
                >Last name</label
              >
              <input
                id="exampleInputEmail1"
                v-model="account.last_name"
                type="text"
                class="form-control"
                aria-describedby="emailHelp"
                placeholder="Promise"
                required
              />
            </div>
          </div>

          <div class="input-row-container d-flex flex-row align-items-center">
            <div class="mb-3 w-100">
              <label for="exampleInputPassword1" class="form-label"
                >Phone number</label
              >
              <input
                id="exampleInputPassword1"
                v-model="account.phone"
                type="tel"
                class="form-control"
                placeholder="02929209220902"
                required
              />
            </div>
            <div class="mb-3 w-100">
              <label for="exampleInputEmail1" class="form-label">Country</label>
              <select
                id="exampleInputEmail1"
                v-model="account.country"
                class="form-control"
                aria-describedby="emailHelp"
                required
              >
                <option
                  v-for="(country, index) in countries"
                  :key="index"
                  :value="country.country"
                >
                  {{ country.country }}
                </option>
              </select>
            </div>
          </div>

          <div class="input-row-container d-flex flex-row align-items-center">
            <div class="mb-3 w-100">
              <label for="exampleInputEmail1" class="form-label">State</label>
              <select
                id="exampleInputEmail1"
                v-model="account.state"
                class="form-control"
                aria-describedby="emailHelp"
                required
              >
                <option
                  v-for="(state, index) in selectStateByCountry"
                  :key="index"
                >
                  {{ state }}
                </option>
              </select>
            </div>
            <div class="mb-3 w-100">
              <label for="exampleInputPassword1" class="form-label">City</label>
              <input
                id="exampleInputPassword1"
                v-model="account.city"
                type="text"
                class="form-control"
                placeholder="Uyo"
                required
              />
            </div>
          </div>

          <div class="input-row-container d-flex flex-row align-items-center">
            <div class="mb-3 w-100">
              <label for="exampleInputPassword1" class="form-label"
                >Address</label
              >
              <input
                id="exampleInputPassword1"
                v-model="account.address"
                type="text"
                class="form-control"
                placeholder="No 10 Plazy Place Uyo"
                required
              />
            </div>
          </div>
          <div
            v-if="userData.user.account_type == 'individual'"
            class="input-row-container mt-3 mb-2 corperate"
          >
            <span class="text-coperate-level">Corporate Level (Current)</span>
            <span :class="[userLevel == 'red-hot' ? 'red-hot' : 'blue-chip']">{{
              userData.type
            }}</span>
          </div>
          <button type="submit" class="btn upadte-btn w-100">
            Update Account Details
          </button>
          <!-- Button trigger modal -->
          <button
            type="button"
            class="btn upadte-btn w-100 change-password"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            Change Password
          </button>
        </form>
      </div>
    </div>
  </div>

  <!-- update avatar -->
  <update-avatar @imageBlob="updateFormObject"></update-avatar>
  <!-- change password modal component -->
  <change-password></change-password>
  <Preloader v-if="isLoading" />
</template>

<script>
// update avatar modal
import UpdateAvatarModal from "./components/modals/update-avatar.vue";
// change password modal
import ChangePasswordModal from "./components/modals/change-password.vue";

import Alert from "@/utilities/alert";
// importing the auth service
import AuthService from "@/services/auth";
// import preloader
import Preloader from "@/layouts/shared/Preloader.vue";
// import country object
import countriesObject from "@/data/countries";

import config from "@/config";

export default {
  name: "Profile",
  components: {
    "update-avatar": UpdateAvatarModal,
    "change-password": ChangePasswordModal,
    Preloader,
  },
  data() {
    return {
      imageBlob: "",
      account: {
        first_name: "",
        last_name: "",
        phone: "",
        country: "",
        state: "",
        city: "",
        address: "",
      },
      countries: countriesObject.countries,
    };
  },
  computed: {
    selectStateByCountry: function () {
      return this.countries && this.account.country != ""
        ? this.countries.filter(
            (item) => item.country == this.account.country,
          )[0].states
        : [];
    },
  },
  mounted() {
    this.account = {
      first_name: this.userData.user.first_name ?? "",
      last_name: this.userData.user.last_name ?? "",
      phone: this.userData.user.phone ?? "",
      country: this.userData.user.country ?? "",
      state: this.userData.user.state ?? "",
      city: this.userData.user.city ?? "",
      address: this.userData.user.primary_address ?? "",
    };
  },
  methods: {
    // This method is responsible for saving the account details by sending the data to the endpoint
    saveAccountDetails() {
      // Set isLoading to true to indicate that the data is being processed
      this.isLoading = true;
      // Create a new FormData object to hold the data to be sent
      var data = new FormData();
      // If there is an imageBlob, append it to the FormData object with the key "avatar"
      if (this.imageBlob) {
        data.append("avatar", this.imageBlob, "avatar.png");
      }
      // Loop through the account object and append each field and its value to the FormData object
      for (var field in this.account) {
        data.append(field, this.account[field]);
      }
      // Call the AuthService's saveAccountDetails method with the FormData object and a callback function
      AuthService.saveAccountDetails(data, (response) => {
        // Check if the response is successful
        if (response && response.error === false) {
          // Display a success message using the Alert component
          Alert.success({
            message: "Account Details changed successfully",
          });
          // Reload the window after 2 seconds
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          // If there is an error, set isLoading to false and display an error message
          this.isLoading = false;
          Alert.error({
            message: "error please try again later",
          });
        }
        // Set isLoading to false after processing the response
        this.isLoading = false;
      });
    },
    updateFormObject(event) {
      this.imageBlob = event;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
@import "@/assets/scss/shared/profile.scss";
</style>
