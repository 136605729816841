<template>
  <DefaultNav>
    <div class="big-container">
      <div class="headings">
        <h2>Merchant DVS Questionnaire</h2>
        <div class="right-btns">
          <!-- <a href="javascript:void(0)" class="green-link">Grant Loan</a> -->
        </div>
      </div>
      <!-- <div class="col-md-12 bg-white p-0 my-3">
        <div class="d-flex justify-content-between px-5 py-4">
          <div>
            <div class="text-secondary">Amount requested</div>
            <strong class="text-danger fs-4">NGN 5,673,239 </strong>
          </div>
          <div>
            <div class="text-secondary">Credit Score</div>
            <strong class="text-primary fs-4">94.5% </strong>
          </div>
          <div>
            <div class="text-secondary">Status</div>
            <strong class="text-success fs-4">Eligible </strong>
          </div>
        </div> -->
      <!-- </div> -->
      <div v-if="dvsData.length > 0" class="active-subscriptions p-3">
        <div>
          <h3 class="p-3 mb-4">Please answer the following questions</h3>
          <form class="p-3 row mt-4" @submit.prevent="saveData()">
            <template v-for="(data, index) in dvsData" :key="index">
              <div class="mb-3 col-6">
                <div class="form-group">
                  <label>{{ data.question }}</label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    required
                    @change="getData"
                  >
                    <option disabled selected>
                      No loan type found for this user
                    </option>
                    <template v-for="(opts, i) in data.options" :key="i">
                      <option
                        :value="`${opts.id}|${opts.optionweight}|${data.id}|${i}|${data.isthisacreditvariable}|${data.question}|${opts.option}`"
                      >
                        {{ opts.option }}
                      </option>
                    </template>
                  </select>
                </div>
              </div>
            </template>
            <div class="headings">
              <button class="green-link">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import Preloader from "@/layouts/shared/Preloader.vue";
import MerchantDVSService from "@/services/merchantdvs";
import Alert from "@/utilities/alert";

export default {
  name: "DvsScore",
  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      dvsData: [],
      answeredquestions: [],
      uid: JSON.stringify(
        JSON.parse(localStorage.getItem("Zowasel")).user.user_id,
      ),
    };
  },
  mounted() {
    this.isLoading = true;
    MerchantDVSService.getDVSData((response) => {
      this.dvsData = response.data;
      this.isLoading = false;
    });
  },
  methods: {
    submitAnswers() {
      let formatdataforsubmission = {
        farmerid: this.uid,
        submit: answeredquestions,
      };

      console.log(
        formatdataforsubmission,
        JSON.stringify(formatdataforsubmission),
      );
    },
    getData(event) {
      // return false;
      let questionparts = event.target.value.split("|");
      let singleanswerobject = {
        question: questionparts[2],
        answer: questionparts[0],
        weightofanswer: questionparts[1],
        isthisacreditvariable: questionparts[4],
        questiontext: questionparts[5],
        optionintext: questionparts[6],
      };
      console.log(singleanswerobject);
      //get the index out of the question splice
      let index = parseInt(this.answeredquestions.length);

      /* ------------- first find out if the value to be pushed exists ------------ */
      let valExists = this.answeredquestions.findIndex(
        (e) => e.question === questionparts[2],
      );

      /* ----------------------- when it exists, replace it ----------------------- */
      if (valExists !== -1) {
        this.answeredquestions[valExists] = singleanswerobject;
      } else {
        /* ------------------------------- else add it ------------------------------ */
        this.answeredquestions.push(singleanswerobject);
      }
    },
    async saveData() {
      let vm = this;
      let dt = {
        farmerid: this.uid,
        submit: JSON.parse(JSON.stringify(vm.answeredquestions)),
      };
      console.log(vm.dvsData.length, vm.answeredquestions.length);
      // return false;
      this.isLoading = true;

      if (vm.dvsData.length == vm.answeredquestions.length) {
        MerchantDVSService.scoringDVS(dt, (data) => {
          // console.log(data);
          // return false;
          /* ------------------- check if it returned with an error ------------------- */
          if (data.status == "200") {
            if (data.data.error == false) {
              Alert.success({
                message: data.data.message,
              });
              setTimeout(() => {
                window.location.href = "/dashboard/merchant/dvs/score";
              }, 2000);
            } else {
              Alert.error({
                message: "Failed to complete the request at the moment",
              });
            }
          } else {
            Alert.error({
              message: data[1].message,
            });
          }
          this.isLoading = false;
        });
      } else {
        Alert.error({
          message: "Please answer all questions to proceed",
        });
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 4% 5% 10% 3%;
  overflow-y: scroll;
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}
.active-subscriptions {
  background-color: white;
  padding: 24px 35px;
  margin-top: 20px;
}
.prl {
  padding: 24px 35px;
}
.sub-history {
  margin-top: 30px;
  width: 100%;
  margin-left: 10px;

  h2 {
    margin-bottom: 40px;
  }
  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #4a4754;
  }

  .serial {
    max-width: 35px;
  }

  tr {
    display: flex;
    flex-direction: row;
    padding: 20px 9px 0;
    height: 66px;
    th {
      font-weight: bold;
    }
  }
  td {
    flex: 1;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4754;
    max-width: 185px;
    overflow-x: hidden;
    padding-left: 10px;
  }
  .active {
    width: 100px;
    height: 26px;
    background: #dcfce7;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .active-dot {
      width: 7.52px;
      height: 8px;
      background: #047b38;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #047b38;
    }
  }
  .expired {
    width: 100px;
    height: 26px;
    background: #fff8e6;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .expired-dot {
      width: 7.52px;
      height: 8px;
      background: #fab900;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #fab900;
    }
  }
  .cancelled {
    width: 100px;
    height: 26px;
    background: rgba(252, 220, 220, 0.93);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .cancelled-dot {
      width: 7.52px;
      height: 8px;
      background: #c52222;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #6b0303;
    }
  }
  a {
    text-decoration: none;
  }
  a:hover td {
    color: #05b050 !important;
  }
}
.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  a {
    text-decoration: none;
  }
  .right-btns {
    display: flex;
    gap: 20px;
  }
  .green-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 180px;
    height: 40px;
    background: #05b050;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    border-radius: 4px;
  }
  .white-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 40px;
    background: #ffffff;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #05b050;
    border: 2px solid #05b050;
    border-radius: 4px;
  }
  .red-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 180px;
    height: 40px;
    background: #cd4647;
    box-shadow:
      0px 2px 4px rgba(44, 39, 56, 0.08),
      0px 4px 8px rgba(44, 39, 56, 0.08);
    border-radius: 4px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    border-radius: 4px;
  }
}
.date-region {
  margin-top: 30px;
}
.view-btn {
  color: black;
}
.bg-grey {
  background: rgba(0, 0, 0, 0.183);
}
.sectionSelectButton {
  padding: 11px 0;
  border-radius: 5px;
  border: none;
  width: 50%;
  background: rgba(0, 0, 0, 0.183);
}
.sectionSelectButton.active {
  background: #262c3f;
  color: white;
}

.outline-green {
  color: #05b050 !important;
  width: 230px !important;
  text-align: center;
  background: #f5f5f5 !important;
  border: 2px solid #05b050;
}
</style>
