<template>
  <DefaultNav>
    <div class="big-container">
      <!-- header -->
      <form @submit.prevent="saveData()">
        <div class="form">
          <div class="headings">
            <h2>Add KYF</h2>
            <div class="right-btns">
              <a href="/dashboard/farmer/view" class="yellow-link"
                >View Farmers</a
              >
              <button class="green-link">Save</button>
            </div>
          </div>
          <div v-if="editable == false" class="mb-3">
            <h6>
              <strong>* fields required</strong>
            </h6>
          </div>
          <div class="d-flex gap-3">
            <!-- input -->
            <div class="w-100 mb-3">
              <label for="exampleInputEmail1" class="form-label mb-0"
                ><span v-if="editable == false" class="text-danger">*</span>
                Level of education</label
              >
              <select
                v-model="educationLevel"
                class="form-select"
                required
                aria-label="Default select example"
              >
                <option selected>Select option</option>
                <option value="illiterate">Illiterate</option>
                <option value="primary">Primary School</option>
                <option value="secondary">Secondary School Graduate</option>
                <option value="college">College Graduate</option>
                <option value="university">University Graduate</option>
                <option value="masters">Masters</option>
                <option value="doctorate">Doctorate</option>
              </select>
            </div>
            <div class="w-100 mb-3">
              <label for="exampleInputEmail1" class="form-label mb-0"
                ><span v-if="editable == false" class="text-danger">*</span>
                Have you gone for any professional farming training?</label
              >
              <select
                v-model="farmingtraining"
                class="form-select"
                required
                aria-label="Default select example"
              >
                <option selected>Select option</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>
          <div class="d-flex gap-3">
            <!-- input -->
            <div class="w-100 mb-3">
              <label for="exampleInputEmail1" class="form-label mb-0"
                ><span v-if="editable == false" class="text-danger">*</span> Are
                you in a farmer group/Cooperative?</label
              >
              <select
                v-model="isinagroup"
                class="form-select"
                required
                aria-label="Default select example"
              >
                <option selected>Select option</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <div class="w-100 mb-3">
              <label for="exampleInputEmail1" class="form-label mb-0"
                ><span v-if="editable == false" class="text-danger">*</span>
                BVN</label
              >
              <input
                v-model="bvn"
                type="text"
                class="form-control"
                placeholder="Enter Identification number"
              />
            </div>
          </div>
          <div class="d-flex gap-3">
            <div class="w-100 mb-3">
              <label for="exampleInputEmail1" class="form-label mb-0">
                Name of farmer group/Cooperative?</label
              >
              <input
                v-model="group"
                type="text"
                class="form-control"
                placeholder="Enter Name of farmer group/Cooperative?"
              />
            </div>
          </div>
          <div class="d-flex gap-3">
            <!-- input -->
            <div class="w-100 mb-3">
              <label for="exampleInputEmail1" class="form-label mb-0"
                ><span v-if="editable == false" class="text-danger">*</span>
                Means of identification</label
              >
              <select
                v-model="meansofid"
                class="form-select"
                aria-label="Default select example"
                required
              >
                <option selected>Select option</option>
                <option value="national id">National ID</option>
                <option value="driver licence">Driver's Lincence</option>
                <option value="voter's card">Voters Card</option>
                <option value="international passport">
                  International Passport
                </option>
              </select>
            </div>
            <!-- input -->
            <div class="w-100 mb-3">
              <label for="exampleInputEmail1" class="form-label mb-0"
                ><span v-if="editable == false" class="text-danger">*</span>
                Identification number</label
              >
              <input
                v-model="idno"
                type="text"
                class="form-control"
                placeholder="Enter Identification number"
              />
            </div>
          </div>
          <div class="d-flex gap-3">
            <!-- input -->
            <div class="w-100 mb-3">
              <label for="exampleInputEmail1" class="form-label mb-0">
                Issue date</label
              >
              <input v-model="issuedate" type="date" class="form-control" />
            </div>
            <!-- input -->
            <div class="w-100 mb-3">
              <label for="exampleInputEmail1" class="form-label mb-0">
                Expiry date</label
              >
              <input v-model="expirydate" type="date" class="form-control" />
            </div>
          </div>
          <div class="d-flex gap-3">
            <!-- input -->
            <div class="w-100 mb-3">
              <label for="exampleInputEmail1" class="form-label mb-0">
                Next of Kin Fullname</label
              >
              <input
                v-model="fullname"
                type="text"
                class="form-control"
                placeholder="Enter your first name"
              />
            </div>
            <!-- input -->
            <div class="w-100 mb-3">
              <label for="exampleInputEmail1" class="form-label mb-0">
                Relationship with next of kin</label
              >
              <input
                v-model="relationshipnok"
                type="text"
                class="form-control"
                placeholder="Enter realtionship"
              />
            </div>
          </div>
          <div class="d-flex gap-3">
            <div class="w-100 mb-3">
              <label for="exampleInputEmail1" class="form-label mb-0">
                Occupation of Next of Kin</label
              >
              <input
                v-model="occupationnok"
                type="text"
                class="form-control"
                placeholder="Enter your next of kin’s occupation"
              />
            </div>
            <div class="w-100 mb-3">
              <label for="exampleInputEmail1" class="form-label mb-0">
                Next of Kin Phone Number</label
              >
              <input
                v-model="telephonenok"
                type="tel"
                class="form-control"
                placeholder="Enter your next of kin’s phone number"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import Preloader from "@/layouts/shared/Preloader.vue";
import Alert from "@/utilities/alert";
import FarmerService from "@/services/farmer";
import NilData from "@/utilities/returnNil";
export default {
  name: "Kyf",
  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      farmerid: "",
      educationLevel: "",
      farmingtraining: "",
      farmingexperience: 0,
      isinagroup: "",
      reasonnogroup: "",
      group: "",
      meansofid: "",
      idno: "",
      issuedate: "",
      expirydate: "",
      fullname: "",
      occupationnok: "",
      relationshipnok: "",
      telephonenok: "",
      bvn: "",
      editable: false,
      isLoading: false,
      editData: [],
    };
  },
  mounted() {
    this.isLoading = true;
    const url = window.location.href;
    this.farmerid = url.split("/").slice(-1)[0];
    /* -------------- check the page if it would be create or edit -------------- */
    if (url.split("/").slice(-2)[0] == "edit") {
      let farmerData = JSON.parse(localStorage.getItem("farmersdata"));
      this.editData.push(farmerData);

      this.group = farmerData.farmerkyf.corporatename;
      this.isinagroup = farmerData.farmerkyf.corporativemembershipstatus;
      this.bvn = farmerData.farmerkyf.bvn;
      this.meansofid = farmerData.farmerkyf.meansofidentification.toLowerCase();
      this.idno = farmerData.farmerkyf.identificationno;
      this.issuedate = farmerData.farmerkyf.issuedate;
      this.expirydate = farmerData.farmerkyf.expirydate;
      this.farmingtraining = farmerData.farmerkyf.professionalfarmingtraining;
      this.educationLevel = farmerData.farmerkyf.levelofeducation;
      this.fullname = farmerData.farmerkyf.nextofkin.fullname;
      this.telephonenok = farmerData.farmerkyf.nextofkin.telephonenok;
      this.occupationnok = farmerData.farmerkyf.nextofkin.occupationnok;
      this.relationshipnok = farmerData.farmerkyf.nextofkin.relationshipnok;
      this.editable = true;
      this.isLoading = false;
    } else {
      this.editable = false;
      this.isLoading = false;
    }
  },
  methods: {
    async saveData() {
      let vm = this;
      /* ------------------------------ edit kyf data ----------------------------- */
      if (vm.editable == true) {
        this.editData[0].farmerkyf.corporatename = vm.group;
        this.editData[0].farmerkyf.corporativemembershipstatus = vm.isinagroup;
        this.editData[0].farmerkyf.bvn = vm.bvn;
        this.editData[0].farmerkyf.meansofidentification = vm.meansofid;
        this.editData[0].farmerkyf.identificationno = vm.idno;
        this.editData[0].farmerkyf.issuedate = vm.issuedate;
        this.editData[0].farmerkyf.expirydate = vm.expirydate;
        this.editData[0].farmerkyf.professionalfarmingtraining =
          vm.farmingtraining;
        this.editData[0].farmerkyf.levelofeducation = vm.educationLevel;
        this.editData[0].farmerkyf.nextofkin.fullname = vm.fullname;
        this.editData[0].farmerkyf.nextofkin.telephonenok = vm.telephonenok;
        this.editData[0].farmerkyf.nextofkin.occupationnok = vm.occupationnok;
        this.editData[0].farmerkyf.nextofkin.relationshipnok =
          vm.relationshipnok;
        let dt = {
          farmerid: vm.farmerid,
          farmerjson: this.editData[0],
        };
        // console.log(dt);
        // return false;
        this.isLoading = true;
        FarmerService.updateFarmer(dt, (data) => {
          /* ------------------- check if it returned with an error ------------------- */
          if (data[0].error == false) {
            Alert.success({
              message: data[1].message,
            });
            setTimeout(() => {
              window.location.href = `/dashboard/farmer/view/${vm.farmerid}`;
            }, 2000);
          } else {
            Alert.error({
              message: data[1].message,
            });
          }
          this.isLoading = false;
        });
      } else {
        /* ------------------------------ add kyf data ------------------------------ */
        let dt = {
          farmerid: vm.farmerid,
          levelofeducation: vm.educationLevel,
          professionalfarmingtraining: vm.farmingtraining.toString(),
          corporativemembershipstatus: vm.isinagroup.toString(),
          reasonnogroup: NilData.sendNAtoDB(vm.reasonnogroup),
          corporatename: NilData.sendNAtoDB(vm.group),
          meansofidentification: NilData.sendNAtoDB(vm.meansofid),
          identificationno: NilData.sendNAtoDB(vm.idno),
          issuedate: NilData.sendNAtoDB(vm.issuedate),
          expirydate: NilData.sendNAtoDB(vm.expirydate),
          nextofkin: {
            fullname: NilData.sendNAtoDB(vm.fullname),
            occupationnok: NilData.sendNAtoDB(vm.occupationnok),
            relationshipnok: NilData.sendNAtoDB(vm.relationshipnok),
            telephonenok: NilData.sendNAtoDB(vm.telephonenok),
          },
          bvn: NilData.sendNAtoDB(vm.bvn),
        };
        this.isLoading = true;
        FarmerService.createFarmerKYF(dt, (data) => {
          /* ------------------- check if it returned with an error ------------------- */
          if (data[0].error == false) {
            Alert.success({
              message: data[1].message,
            });
            setTimeout(() => {
              window.location.href = `/dashboard/farmer/view/${vm.farmerid}`;
            }, 2000);
          } else {
            Alert.error({
              message: data[1].message,
            });
          }
          this.isLoading = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 4% 5% 8% 3%;
  overflow-y: scroll;
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}
.active-subscriptions {
  background-color: white;
  padding: 24px 65px 50px;
  margin-top: 40px;

  h2 {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #696671;
  }
}

/* The slider */
.sub-history {
  margin-top: 50px;
  width: 100%;
  margin-left: 10px;

  h2 {
    margin-bottom: 40px;
  }
  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #4a4754;
  }
}
.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 70px;

  a {
    text-decoration: none;
  }
  .right-btns {
    display: flex;
    gap: 20px;
  }
  .green-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 145px;
    height: 40px;
    background: #05b050;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
  }
}
.green-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 145px;
  height: 40px;
  background: #05b050;
  border-radius: 5px;
  letter-spacing: 0.02em;
}
.yellow-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  width: 145px;
  height: 40px;
  background: #fab900 !important;
  border-radius: 5px;
  letter-spacing: 0.02em;
}
a {
  text-decoration: none;
}
.small-font {
  font-size: 11px !important;
}
</style>
