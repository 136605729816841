<template>
  <div id="kyb-two">
    <div class="form">
      <div class="dropzone_conatiner my-4">
        <div class="top-texts">
          <h3>Business Verification</h3>
        </div>
        <div id="required">All fields are required</div>
        <!-- DOcuments -->
        <div class="row">
          <div class="col">
            <div class="for-picture">
              <div id="my-dropzone" class="dropzone">
                <label for="">CAC Upload</label>
                <img id="preview-cac" class="img-fluid resize-img mb-2" />
                <img
                  id="image_icon"
                  src="@/assets/images/vectors/attachment.svg"
                  alt="image"
                />
              </div>
              <div class="file-upload">
                <div class="file-button" @click="openFileDialog('cac')">
                  Upload
                </div>
                <div id="file-input">
                  <input
                    id="cac"
                    ref="input"
                    class="hidden_input"
                    data-preview="preview-cac"
                    type="file"
                    multiple
                    accept="image/*"
                    required
                    name="cac"
                    @change="uploadFile"
                  />
                  <div
                    v-if="fileName == ''"
                    id="file_name"
                    @click="openFileDialog()"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="for-picture">
              <div id="my-dropzone" class="dropzone">
                <label for=""> Upload Financial Statement</label>
                <img id="preview-fs" class="img-fluid resize-img mb-2" />
                <img
                  id="image_icon"
                  src="@/assets/images/vectors/attachment.svg"
                  alt="image"
                />
              </div>
              <div class="file-upload">
                <div class="file-button" @click="openFileDialog('fs')">
                  Upload
                </div>
                <div id="file-input">
                  <input
                    id="fs"
                    ref="input"
                    class="hidden_input"
                    data-preview="preview-fs"
                    type="file"
                    multiple
                    accept="image/*"
                    required
                    name="financial_statement"
                    @change="uploadFile"
                  />
                  <div
                    v-if="fileName == ''"
                    id="file_name"
                    @click="openFileDialog()"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- last pictures -->
        <div class="for-picture">
          <div id="mou-doc">
            <div id="my-dropzone" class="dropzone">
              <label for="">Upload MOU</label>
              <img id="preview-mou" class="img-fluid resize-img mb-2" />
              <img
                id="image_icon"
                src="@/assets/images/vectors/attachment.svg"
                alt="image"
              />
            </div>
            <div class="file-upload">
              <div class="file-button" @click="openFileDialog('mou')">
                Upload
              </div>
              <div id="file-input">
                <input
                  id="mou"
                  ref="input"
                  class="hidden_input"
                  data-preview="preview-mou"
                  type="file"
                  multiple
                  accept="image/*"
                  required
                  name="mou"
                  @change="uploadFile"
                />
                <div
                  v-if="fileName == ''"
                  id="file_name"
                  @click="openFileDialog()"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "KybTwo",
  data() {
    return {
      files: {},
    };
  },
  methods: {
    openFileDialog(input_id) {
      document.getElementById(input_id).click();
    },
    uploadFile($event) {
      const input = $event.target;
      const file = input.files;
      // check for file type
      if (!file[0].type.includes("image")) {
        alert("Please upload an image file");
        return;
      }

      // get the file name
      this.fileName = file[0].name;

      // preview the image

      // if at least one image is selected proceed to display the image
      if (file[0]) {
        // get the image path
        const imageSrc = URL.createObjectURL(file[0]);
        // select the image preview element
        const imagePreviewElement = document.getElementById(
          input.getAttribute("data-preview"),
        );

        // assign the path to the image preview element
        imagePreviewElement.src = imageSrc;
        imagePreviewElement.style.display = "block";
        // hide sibling img element
        imagePreviewElement.nextElementSibling.style.display = "none";

        // push the uploaded file to the files array
        this.files[input.name] = file[0];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
#kyb-two {
  padding-inline: 6em;
  margin-top: 30px;
}
.form {
  .crop_details {
    @include textStyles(inherit, 700, 16px, 24px);
    letter-spacing: 0.01em;
    color: #4a4754;
    margin-bottom: 1rem;
  }

  .m_top {
    margin-top: 3.4rem;
  }

  width: 100%;
  padding-bottom: 2rem;

  select,
  input {
    font-size: 14px;
    color: #000000;

    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      border-color: #05b050 !important;
      box-shadow: 0 0 !important;
    }

    &::placeholder {
      @include textStyles("Maven Pro", 700, 14px, 24px);
      color: #000000;
    }
  }

  label {
    font-size: 14px;
    font-weight: 900;
    color: rgba(45, 55, 72, 0.8);
  }
}

div.vertical-line {
  width: 2px;
  padding: 1px;
  background: #f5f5f5;
}

// using drop-zone
.dropzone {
  width: 100%;
  max-height: 450px;
  background: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 147%;
  color: #4a4754;
  padding: 25px 35px 25px 35px;
  margin-top: 10px;

  span {
    font-weight: 700;
    color: #05b050;
    cursor: pointer;

    + span {
      font-size: 14px;
    }
  }
}

#required {
  color: red;
  font-family: "Maven Pro";
  font-weight: bolder;
  font-size: 16px;
  margin-bottom: 15px;
}

#optional {
  font-family: "Maven Pro";
  font-weight: bolder;
  font-size: 14px;
  color: rgba(113, 117, 125, 0.83);
  font-style: italic;
}

.zone {
  height: 150px;
  font-size: 12px;
}

.hidden_input {
  display: none;
}

.first-uploads {
  margin-top: 30px;
  margin-bottom: 30px;

  input {
    height: 40px;
    width: 100%;
    background-color: white;
  }
}

.file-button {
  width: 150px;
  height: 50px;
  border: 2px solid #05b050;
  border-radius: 4px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.kyc {
  width: 100%;
  height: 100%;
}

.kycpassport {
  width: 100%;
  height: 100%;
}

#image_icon {
  width: 100%;
  height: 300px;
}

#image_icon {
  width: 100%;
  height: 300px;
}

.top-texts {
  width: 100%;

  h3 {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #4a4754;
  }

  .isVerified {
    padding: 5px 15px;
    background: #f5f5f5;
    border-radius: 14px;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    color: #b5b4b9;
    font-size: 24px;
    line-height: 132%;
  }
}

.file-upload {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.resize-img {
  width: 100%;
  max-height: 350px;
}
.for-picture {
  margin-top: 2em;
}
#mou-doc {
  margin-left: 25%;
  width: 50%;
}
</style>
