<template>
  <DefaultNav>
    <div class="big-container">
      <div class="headings">
        <h2>Loan History</h2>
        <div class="right-btns">
          <!-- <a
              href="/dashboard/farmer/batch/score"
              class="green-link bg-warning text-center"
              >Batch Score</a
            > -->
          <!-- <a href="/dashboard/farmer/add" class="green-link">Add a Farmer</a> -->
          <!-- <a href="" class="green-link">Batch Upload</a> -->
          <!-- <a href="" class="red-link">Sent Invoice</a> -->
        </div>
      </div>
      <div class="active-subscriptions">
        <table class="table-borderless sub-history">
          <tbody class="scrollable">
            <tr>
              <td class="theader">Amount</td>
              <td class="theader">Installment Paid</td>
              <td class="theader">Installments Left</td>
              <td class="theader">Amount Remaining</td>
              <td class="theader">Installment Duration</td>
              <td class="theader">Duration</td>
              <td class="theader">Status</td>
            </tr>
            <hr />
            <div>
              <tr>
                <td>
                  {{ thousandsData(63677889) }}
                </td>
                <td>3 Installments</td>
                <td>6 Installments</td>
                <td>{{ thousandsData(8677889) }}</td>
                <td class="ps-3">3 Months</td>
                <td class="ps-3">1 Year</td>
                <td>
                  <button class="btn btn-bg-orange px-3 border-2 btn-sm">
                    Paying
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  {{ thousandsData(63677889) }}
                </td>
                <td>-</td>
                <td>-</td>
                <td>{{ thousandsData(8677889) }}</td>
                <td class="ps-3">3 Months</td>
                <td class="ps-3">1 Year</td>
                <td>
                  <button
                    class="btn success-btnbg px-3 border-1 btn-sm success-border border-2"
                  >
                    Paid Off
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  {{ thousandsData(63677889) }}
                </td>
                <td>-</td>
                <td>-</td>
                <td>{{ thousandsData(8677889) }}</td>
                <td class="ps-3">3 Months</td>
                <td class="ps-3">1 Year</td>
                <td>
                  <button
                    class="btn success-btnbg px-3 border-1 btn-sm success-border border-2"
                  >
                    Paid Off
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  {{ thousandsData(63677889) }}
                </td>
                <td>-</td>
                <td>-</td>
                <td>{{ thousandsData(8677889) }}</td>
                <td class="ps-3">3 Months</td>
                <td class="ps-3">1 Year</td>
                <td>
                  <button
                    class="btn success-btnbg px-3 border-1 btn-sm success-border border-2"
                  >
                    Paid Off
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  {{ thousandsData(63677889) }}
                </td>
                <td>-</td>
                <td>-</td>
                <td>{{ thousandsData(8677889) }}</td>
                <td class="ps-3">3 Months</td>
                <td class="ps-3">1 Year</td>
                <td>
                  <button
                    class="btn success-btnbg px-3 border-1 btn-sm success-border border-2"
                  >
                    Paid Off
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  {{ thousandsData(63677889) }}
                </td>
                <td>-</td>
                <td>-</td>
                <td>{{ thousandsData(8677889) }}</td>
                <td class="ps-3">3 Months</td>
                <td class="ps-3">1 Year</td>
                <td>
                  <button
                    class="btn success-btnbg px-3 border-1 btn-sm success-border border-2"
                  >
                    Paid Off
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  {{ thousandsData(63677889) }}
                </td>
                <td>-</td>
                <td>-</td>
                <td>{{ thousandsData(8677889) }}</td>
                <td class="ps-3">3 Months</td>
                <td class="ps-3">1 Year</td>
                <td>
                  <button
                    class="btn success-btnbg px-3 border-1 btn-sm success-border border-2"
                  >
                    Paid Off
                  </button>
                </td>
              </tr>
            </div>
          </tbody>
          <!-- pagination -->
          <!-- <nav class="d-flex justify-content-center my-4"> -->
          <!-- <div id="pagination"></div> -->
          <!-- </nav> -->
        </table>
      </div>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import Preloader from "@/layouts/shared/Preloader.vue";
import FarmerService from "@/services/farmer";
import Alert from "@/utilities/alert";
import NilData from "@/utilities/returnNil";

export default {
  name: "Loan",
  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      farmerData: [],
      scored: "",
      search: "",
      selected: [],
      allSelected: false,
      showButton: false,
    };
  },
  mounted() {},
  methods: {
    thousandsData(x) {
      return "₦" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

<style lang="scss" scoped>
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 4% 5% 10% 3%;
  overflow-y: scroll;
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}
.active-subscriptions {
  background-color: white;
  padding: 24px 35px;
  margin-top: 20px;
}
.prl {
  padding: 24px 35px;
}
.sub-history {
  margin-top: 30px;
  width: 100%;
  margin-left: 10px;

  h2 {
    margin-bottom: 40px;
  }
  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #4a4754;
  }

  .serial {
    max-width: 35px;
  }

  tr {
    display: flex;
    flex-direction: row;
    padding: 20px 9px 0;
    height: 66px;
    th {
      font-weight: bold;
    }
  }
  td {
    flex: 1;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4754;
    max-width: 185px;
    overflow-x: hidden;
    padding-left: 10px;
  }
  .active {
    width: 100px;
    height: 26px;
    background: #dcfce7;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .active-dot {
      width: 7.52px;
      height: 8px;
      background: #047b38;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #047b38;
    }
  }
  .expired {
    width: 100px;
    height: 26px;
    background: #fff8e6;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .expired-dot {
      width: 7.52px;
      height: 8px;
      background: #fab900;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #fab900;
    }
  }
  .cancelled {
    width: 100px;
    height: 26px;
    background: rgba(252, 220, 220, 0.93);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .cancelled-dot {
      width: 7.52px;
      height: 8px;
      background: #c52222;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #6b0303;
    }
  }
  a {
    text-decoration: none;
  }
  a:hover td {
    color: #05b050 !important;
  }
}
.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  a {
    text-decoration: none;
  }
  .right-btns {
    display: flex;
    gap: 20px;
  }
  .green-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 180px;
    height: 40px;
    background: #05b050;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    border-radius: 4px;
  }
  .white-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 40px;
    background: #ffffff;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #05b050;
    border: 2px solid #05b050;
    border-radius: 4px;
  }
  .red-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 180px;
    height: 40px;
    background: #cd4647;
    box-shadow:
      0px 2px 4px rgba(44, 39, 56, 0.08),
      0px 4px 8px rgba(44, 39, 56, 0.08);
    border-radius: 4px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    border-radius: 4px;
  }
}
.date-region {
  margin-top: 30px;
}
.view-btn {
  color: black;
}
.btn-bg-orange {
  background: #fbc52b;
  border: 1px solid black;
}
.success-border {
  border-color: #00b087;
}
.success-btnbg {
  background: #16c098b4 !important;
}
</style>
