<template>
  <!-- warehouse -->
  <div id="warehouse" class="warhouse_wrapper_carousel">
    <div class="warehouse_wrapper">
      <h2>Warehouse</h2>
      <div class="warehouse_container">
        <div
          v-for="(warehouses, index) in paginateWarehouse"
          :key="index"
          class="warehouse_contents"
        >
          <p class="warehouse_no">Warehouse {{ index + 1 }}</p>
          <div class="warehouse-details-container">
            <p>
              <span class="me-3"><strong>Address</strong>:</span
              >{{ warehouses.address }}
            </p>
            <p>
              <span class="me-3"> <strong>Location</strong>:</span
              >{{ warehouses.location }},
              {{ warehouses.state }}
            </p>
            <div>
              <p>
                <span class="me-3"><strong>Size</strong>:</span
                >{{ warehouses.size }}
                <span>SQM</span>
              </p>
            </div>

            <div
              class="edit-btn"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              @click="updateWarehouse(index)"
            >
              <span>Edit</span>
            </div>
          </div>
        </div>
      </div>

      <div
        class="add-warehouse"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        @click="reverseWarehouseTitle"
      >
        Add Warehouse
      </div>
    </div>
    <nav class="pagination align-items-center gap-2 my-4">
      <button
        :disabled="currentPage === 1"
        class="btn btn-succ fs-small"
        @click="prevPage"
      >
        Previous
      </button>
      <span class="fs-small">Page {{ currentPage }} of {{ totalPages }}</span>
      <button
        :disabled="currentPage === totalPages"
        class="btn btn-succ fs-small"
        @click="nextPage"
      >
        Next
      </button>
    </nav>
  </div>

  <!-- Modal -->
  <div
    id="exampleModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1
            id="exampleModalLabel"
            class="modal-title fs-5"
            :class="{ edit: edit }"
          >
            {{ warehouseTitle }} Warehouse
          </h1>
        </div>
        <form>
          <div class="modal-body">
            <div class="mb-3 w-100">
              <label for="exampleInputPassword1" class="form-label"
                >Address</label
              >
              <input
                id="exampleInputPassword1"
                v-model="warehouse.address"
                type="text"
                class="form-control"
                placeholder="Address"
                required
              />
            </div>
            <div class="mb-3 w-100">
              <label for="exampleInputPassword1" class="form-label"
                >Location</label
              >
              <select
                id="exampleInputEmail1"
                v-model="warehouse.location"
                class="form-control"
                aria-describedby="emailHelp"
                required
              >
                <option
                  v-for="(country, index) in countries"
                  :key="index"
                  :value="country.country"
                >
                  {{ country.country }}
                </option>
              </select>
            </div>
            <div class="mb-3 w-100">
              <label for="exampleInputPassword1" class="form-label">Size</label>
              <input
                id="exampleInputPassword1"
                v-model="warehouse.size"
                type="number"
                class="form-control"
                placeholder="Size"
                required
              />
            </div>
            <div class="mb-3 w-100">
              <label for="exampleInputEmail1" class="form-label"
                >Warehouse state</label
              >
              <select
                id="exampleInputEmail1"
                v-model="warehouse.state"
                class="form-control"
                aria-describedby="emailHelp"
                required
              >
                <option
                  v-for="(state, index) in selectCompanyStateByCountry"
                  :key="index"
                >
                  {{ state }}
                </option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="reverseWarehouseTitle"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="addWarehouse()"
            >
              Save changes
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <Preloader v-if="isLoading" />
</template>
<script>
import Alert from "@/utilities/alert";
// import preloader
import Preloader from "@/layouts/shared/Preloader.vue";
// import country object
import countriesObject from "@/data/countries";
import MarketPlaceService from "@/services/marketplace";
import store from "@/store";
export default {
  name: "Warehouse",
  data() {
    return {
      // add or edit warehouse
      warehouse: {
        address: "",
        location: "",
        size: "",
        state: "",
      },
      warehouseDetails: [],
      // pagination
      currentPage: 1,
      itemsPerPage: 10,
      edit: false,
      warehouseTitle: "Add",
      countries: countriesObject.countries,
    };
  },
  computed: {
    selectCompanyStateByCountry: function () {
      return this.countries && this.warehouse.location != ""
        ? this.countries.filter(
            (item) => item.country == this.warehouse.location,
          )[0].states
        : [];
    },
    // pagination
    paginateWarehouse() {
      const sortWarehouseList = this.warehouseDetails;
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return sortWarehouseList.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.warehouseDetails.length / this.itemsPerPage);
    },
  },
  mounted() {
    if (this.userData.user.locations) {
      this.warehouseDetails = JSON.parse(this.userData.user.locations);
      console.log(this.warehouseDetails);
    }
  },
  methods: {
    editWarehouse() {
      this.edit = true;
      this.edit
        ? (this.warehouseTitle = "Edit")
        : (this.warehouseTitle = "Add");
    },
    reverseWarehouseTitle() {
      this.edit = false;
      this.edit
        ? (this.warehouseTitle = "Edit")
        : (this.warehouseTitle = "Add");
    },
    addWarehouse() {
      // send data to the end point
      this.isLoading = true;
      // check if fields are not empty
      if (this.warehouse != null) {
        var warehouseList = JSON.parse(this.userData.user.locations) ?? [];
        const warehouse = { ...this.warehouse };
        // push the array of the warehouse object
        warehouseList.push(warehouse);
        // call the marketplace function to put the request
        MarketPlaceService.addLocation(
          {
            locations: warehouseList,
          },
          (response) => {
            if (response && response.error == false) {
              // set the loader flag to false
              this.isLoading = false;
              Alert.success({
                message: "Warehouse added successfully",
              });
              setTimeout(() => {
                location.reload();
              }, 2000);
            } else {
              // set the loader flag to false
              this.isLoading = false;
              Alert.error({
                message: "Failed To add Warehouse location",
              });
            }
            // set the loader flag to false
            this.isLoading = false;
          },
        );
        this.reverseWarehouseTitle();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/main.scss";
.warhouse_wrapper_carousel {
  padding: 33px 32px;
  border-radius: 6px;
  background: #fff;
  margin-top: 40px;
}

.warehouse_wrapper h2 {
  color: #696671;

  /* Headline/24px */
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.24px;
}

.warehouse_contents {
  border-radius: 4px;
  border: 1px solid #6ed19a;
  padding: 11px 14px;
  margin-bottom: 18px;
}

.warehouse_no {
  color: #047b38;
  font-family: Maven Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 116%;
}

.warehouse_contents p:not(:nth-of-type(1)) {
  color: #696671;
  font-family: Maven Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 116%;

  span {
    font-weight: 800;
  }
}
.warehouse-details-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.edit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #ffffff;
  box-shadow:
    0px 4px 8px 0px rgba(44, 39, 56, 0.08),
    0px 2px 4px 0px rgba(44, 39, 56, 0.08);
  height: 36px;
  width: 100px;
  border: 1px solid #05b050;
  cursor: default;
  &:hover {
    background: darken(white, 10%);
  }

  span {
    color: #05b050;
    text-align: center;
    font-family: Maven Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }
}
.add-warehouse {
  width: 306px;
  padding: 10px;
  text-align: center;
  border: 1px solid #30bd6e;
  background: #fff;
  color: #05b050;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  margin-top: 22px;
  margin-inline: auto;
  cursor: default;
  &:hover {
    background: darken(white, 10%);
  }
}
.edit {
  color: red;
}
.btn-succ {
  background: #05b050;
  color: white;

  &:hover {
    background: darken(#05b050, 10%);
  }
}

.fs-small {
  font-size: 14px;
}
</style>
