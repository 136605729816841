<template>
  <DefaultNav>
    <div class="big-container">
      <div class="headings">
        <div class="left-btns">
          <a href="/dashboard/team"
            ><img src="@/assets/images/vectors/arrowleft.png" alt=""
          /></a>
          <h2>Manage Subscriptions</h2>
        </div>
      </div>
      <div class="container">
        <div v-if="userData.user.type == 'merchant'" class="left merch-height">
          <button
            type="button"
            :class="[tab == 1 ? 'active' : '']"
            @click="changeTab(1)"
          >
            Marketplace <span class="inactive-p">Inactive</span>
          </button>
          <button
            :class="[tab == 2 ? 'active' : '']"
            type="button"
            @click="changeTab(2)"
          >
            Market Report
          </button>
          <button
            :class="[tab == 3 ? 'active' : '']"
            type="button"
            @click="changeTab(3)"
          >
            ACES Monthly Fee
          </button>
        </div>
        <div v-if="userData.user.type == 'corporate'" class="left">
          <button
            type="button"
            :class="[tab == 1 ? 'active' : '']"
            @click="changeTab(1)"
          >
            Marketplace
          </button>
          <button
            :class="[tab == 2 ? 'active' : '']"
            type="button"
            @click="changeTab(2)"
          >
            Market Report
          </button>
          <button
            :class="[tab == 3 ? 'active' : '']"
            type="button"
            @click="changeTab(3)"
          >
            ACES Monthly Fee
          </button>
          <button
            :class="[tab == 4 ? 'active' : '']"
            type="button"
            @click="changeTab(4)"
          >
            Traceability Fee
          </button>
        </div>
        <div v-if="userData.user.type == 'partner'" class="left merch-height">
          <button
            type="button"
            :class="[tab == 3 ? 'active' : '']"
            @click="changeTab(3)"
          >
            ACES Dashboard
          </button>
          <button
            :class="[tab == 5 ? 'active' : '']"
            type="button"
            @click="changeTab(5)"
          >
            API Calls
          </button>
          <button
            :class="[tab == 0 ? 'active' : '']"
            type="button"
            @click="changeTab(0)"
          >
            USSD/VR
          </button>
        </div>
        <div class="right">
          <div v-if="tab == 1" class="link-invite">
            <h2>Marketplace Subscription Plans</h2>
            <div class="cards">
              <div class="head-texts">
                <h4>Standard</h4>
                <p><span>1.5%</span>/transaction</p>
              </div>
              <div class="main-sub">
                <div class="left-text">
                  <p>Access to standard transactions to save time and money</p>
                </div>
                <div class="features">
                  <h3>Features</h3>
                  <ul>
                    <li>Create Offers</li>
                    <li>Quality Assurance</li>
                    <li>Price discovery</li>
                    <li>Products Listings</li>
                    <li>Add 1 team member</li>
                  </ul>
                </div>
                <div class="button-area">
                  <button>Subscribe</button>
                </div>
              </div>
            </div>
            <div class="cards">
              <div class="head-texts">
                <h4>Elite Bandage</h4>
                <p><span>20%</span> one off</p>
              </div>
              <div class="main-sub">
                <div class="left-text">
                  <p>
                    Access to a global network of verified buyers and sellers
                    elite.
                  </p>
                </div>
                <div class="features">
                  <h3>Features</h3>
                  <ul>
                    <li>Negotiation Room</li>
                    <li>Market Reports</li>
                    <li>Verified Merchants</li>
                    <li>Ratings</li>
                    <li>Unlimited Orders</li>
                    <li>Price Recommendations</li>
                    <li>Dedicated Sourcing Manager</li>
                    <li>Add 3 team members</li>
                  </ul>
                </div>
                <div class="button-area">
                  <button>Subscribe</button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="tab == 2" class="link-invite">
            <h2>Market Report Subscription Plans</h2>

            <div class="cards colored-card">
              <div class="head-texts">
                <h4>Basic Market Report</h4>
                <p><span>Free</span></p>
              </div>
              <div class="main-sub">
                <div class="left-text">
                  <p>Access to basic price discovery</p>
                  <p class="yellow-text">This is your plan</p>
                  <div class="auto-renew">
                    <h4 class="testi">Auto Renew</h4>
                    <label class="switch">
                      <input type="checkbox" />
                      <span class="slider round" />
                    </label>
                  </div>
                </div>
                <div class="features">
                  <h3>Features</h3>
                  <ul>
                    <li>Price discovery</li>
                    <li>Weekly Market Report</li>
                  </ul>
                </div>
                <div class="button-area">
                  <button>Cancel</button>
                </div>
              </div>
            </div>
            <div class="cards">
              <div class="head-texts">
                <h4>Standard Market Report</h4>
                <p><span>$50</span>per Month</p>
              </div>
              <div class="main-sub">
                <div class="left-text">
                  <p>
                    Access to market reporting, and real-time price offers to
                    dynamically power your supply chain
                  </p>
                </div>
                <div class="features">
                  <h3>Features</h3>
                  <ul>
                    <li>Real-time Price Discovery</li>
                    <li>Farm-gate Prices</li>
                    <li>Sellers Price Offers</li>
                    <li>Buyers Price Offers</li>
                  </ul>
                </div>
                <div class="button-area">
                  <button>Subscribe</button>
                </div>
              </div>
            </div>
            <div class="cards">
              <div class="head-texts">
                <h4>Advanced Market Report</h4>
                <p><span>$100</span> per Month</p>
              </div>
              <div class="main-sub">
                <div class="left-text">
                  <p>
                    Access to advanced market intelligence to dynamically power
                    your supply chain
                  </p>
                </div>
                <div class="features">
                  <h3>Features</h3>
                  <ul>
                    <li>Market Intelligence</li>
                    <li>Cumulative Analytics</li>
                    <li>Predictive Analytics</li>
                    <li>Competition Price Offers</li>
                  </ul>
                </div>
                <div class="button-area">
                  <button>Subscribe</button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="tab == 3" class="link-invite group-invite">
            <h2>ACES Monthly Subscription Plans</h2>

            <div class="cards colored-card">
              <div class="head-texts">
                <h4>BoP Basic</h4>
                <p><span>$275</span> per Month</p>
              </div>
              <div class="main-sub">
                <div class="left-text">
                  <p>
                    Access to basic details for tracing and authenticating
                    identification Number of farmers: 0- 200
                  </p>
                  <p class="yellow-text">This is your plan</p>
                </div>
                <div class="features">
                  <h3>Features</h3>
                  <ul>
                    <li>KYC Verification</li>
                    <li>Credit Scores</li>
                    <li>Grade Points</li>
                  </ul>
                </div>
                <div class="button-area">
                  <button class="cancel">Cancel</button>
                  <button class="upgrade">Upgrade</button>
                </div>
              </div>
            </div>
            <div class="cards">
              <div class="head-texts">
                <h4>BoP Standard</h4>
                <p><span>$500</span>per Month</p>
              </div>
              <div class="main-sub">
                <div class="left-text">
                  <p>
                    Access to standard credit score of BoP in centralized data
                    points. Number of farmers: 200 - 500
                  </p>
                </div>
                <div class="features">
                  <h3>Features</h3>
                  <ul>
                    <li>Demographic Details</li>
                    <li>Brief Credit History</li>
                    <li>Outstanding Facility (if any)</li>
                    <li>Type of facility</li>
                    <li>Name of Lender</li>
                    <li>Next of kin</li>
                    <li>Guarantor Details</li>
                  </ul>
                </div>
                <div class="button-area">
                  <button>Subscribe</button>
                </div>
              </div>
            </div>
            <div class="cards">
              <div class="head-texts">
                <h4>BoP Advance</h4>
                <p><span>$1000</span> per Month</p>
              </div>
              <div class="main-sub">
                <div class="left-text">
                  <p>
                    Access to advanced reporting with data editing and analytics
                    to varying degrees. Number farmers: 500 - 1,000
                  </p>
                </div>
                <div class="features">
                  <h3>Features</h3>
                  <ul>
                    <li>5C of Credit data</li>
                    <li>Expert scoring opinion</li>
                    <li>Farmland Cordinates</li>
                    <li>Crop Sale Records</li>
                    <li>Total transaction history</li>
                    <li>Analysis and Recommendation</li>
                  </ul>
                </div>
                <div class="button-area">
                  <button>Subscribe</button>
                </div>
              </div>
            </div>
            <div class="cards">
              <div class="head-texts">
                <h4>Enterprise</h4>
                <p><span>$1000</span> per Month</p>
              </div>
              <div class="main-sub">
                <div class="left-text">
                  <p>Above 1k farmers</p>
                </div>
                <div class="features">
                  <h3>Features</h3>
                  <ul>
                    <li>Contact sales for a custom solution</li>
                  </ul>
                </div>
                <div class="button-area">
                  <button>Contact</button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="tab == 4" class="link-invite direct-invite">
            <h2>Traceability Subscription Plans</h2>

            <div class="cards colored-card">
              <div class="head-texts">
                <h4>Basic Traceability</h4>
                <p><span>$100</span>/PMT</p>
              </div>
              <div class="main-sub">
                <div class="left-text">
                  <p>Access to basic supply chain</p>
                  <p class="yellow-text">This is your plan</p>
                </div>
                <div class="features">
                  <h3>Features</h3>
                  <ul>
                    <li>Crop Origin</li>
                    <li>Cultivation Details</li>
                  </ul>
                </div>
                <div class="button-area">
                  <button class="cancel">Cancel</button>
                </div>
              </div>
            </div>
            <div class="cards">
              <div class="head-texts">
                <h4>Standard Traceability</h4>
                <p><span>$250</span>/PMT</p>
              </div>
              <div class="main-sub">
                <div class="left-text">
                  <p>
                    Access to standard traceability footprint across the
                    community, people, and the planet.
                  </p>
                </div>
                <div class="features">
                  <h3>Features</h3>
                  <ul>
                    <li>Quality Specifications</li>
                    <li>Warehousing Details</li>
                    <li>Shipment from farm to Factory</li>
                  </ul>
                </div>
                <div class="button-area">
                  <button>Upgrade</button>
                </div>
              </div>
            </div>
            <div class="cards">
              <div class="head-texts">
                <h4>Sustainability</h4>
                <p><span>$500</span>/PMT</p>
              </div>
              <div class="main-sub">
                <div class="left-text">
                  <p>Access to footprints and livelihood analysis.</p>
                </div>
                <div class="features">
                  <h3>Features</h3>
                  <ul>
                    <li>Footprint reporting</li>
                    <li>Import Tracking</li>
                    <li>Tree replacement</li>
                    <li>Tracking work conditions</li>
                    <li>School enrollment</li>
                    <li>Climate mitigation</li>
                  </ul>
                </div>
                <div class="button-area">
                  <button>Upgrade</button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="tab == 5" class="link-invite">
            <h2>API Calls Subscription</h2>
            <div class="main-box">
              <div class="api-container">
                <div class="each-api">
                  <div class="left-api">
                    <h5>Report Kyc</h5>
                    <h5>$200</h5>
                  </div>
                  <label class="switch">
                    <input type="checkbox" />
                    <span class="slider round" />
                  </label>
                </div>
                <div class="each-api">
                  <div class="left-api">
                    <h5>Report Kyc</h5>
                    <h5>$200</h5>
                  </div>
                  <label class="switch">
                    <input type="checkbox" />
                    <span class="slider round" />
                  </label>
                </div>
                <div class="each-api">
                  <div class="left-api">
                    <h5>Report - Kyc</h5>
                    <h5>$200</h5>
                  </div>
                  <label class="switch">
                    <input type="checkbox" />
                    <span class="slider round" />
                  </label>
                </div>
                <div class="each-api">
                  <div class="left-api">
                    <h5>Report - Credit Scoring and Grades</h5>
                    <h5>$80</h5>
                  </div>
                  <label class="switch">
                    <input type="checkbox" />
                    <span class="slider round" />
                  </label>
                </div>
                <div class="each-api">
                  <div class="left-api">
                    <h5>Report - Guarantor Details</h5>
                    <h5>$100</h5>
                  </div>
                  <label class="switch">
                    <input type="checkbox" />
                    <span class="slider round" />
                  </label>
                </div>
                <div class="each-api">
                  <div class="left-api">
                    <h5>Report Kyc</h5>
                    <h5>$200</h5>
                  </div>
                  <label class="switch">
                    <input type="checkbox" />
                    <span class="slider round" />
                  </label>
                </div>
                <div class="each-api">
                  <div class="left-api">
                    <h5>Report Kyc</h5>
                    <h5>$200</h5>
                  </div>
                  <label class="switch">
                    <input type="checkbox" />
                    <span class="slider round" />
                  </label>
                </div>
              </div>
              <div class="total-api">
                <h3>Total API Call Cost</h3>
                <h2>$1130</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DefaultNav>
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import MarketplaceService from "@/services/marketplace";
import Alert from "@/utilities/alert";
export default {
  name: "AddMember",
  components: {
    DefaultNav,
  },
  data() {
    return {
      team: {
        first_name: "",
        last_name: "",
        email: "",
        // phone: "",
      },
      emailListInput: "",
      validEmails: [],
      tab: 1,
    };
  },
  methods: {
    addMember() {
      MarketplaceService.addMember(
        {
          first_name: this.team.first_name,
          last_name: this.team.last_name,
          email: this.team.email,
          // phone: this.team.phone,
        },
        (response) => {
          if (response && response.error == false) {
            Alert.success({
              message: "Team Member Added successfully",
            });
          } else if (response && response.error) {
            Alert.error({
              message: response.message,
            });
          }
        },
      );
    },
    addNewUser() {
      var className = "new-class";
      var count = 0;
      var field = "";
      var maxFields = 50;
      var className = document.getElementsByClassName("new-line");
      function totalFields() {
        return className.length;
      }
      count = totalFields() + 1;
      field = $("#new-line-1").clone();
      field.attr("id", "new-line" + count);
      field.children("label").text("Field " + count);
      field.find("input").val("");
      // $(className + ":last").after($(field));
    },
    changeTab(tab) {
      this.tab = tab;
    },
    parseEmails() {
      // Split the entered text into an array of email addresses using commas as the separator
      const emailArray = this.emailListInput
        .split(",")
        .map((email) => email.trim()); // Remove leading/trailing spaces

      // Validate each email address using a regular expression
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const validEmails = emailArray.filter((email) => emailRegex.test(email));

      // Update the validEmails data property with the valid email addresses
      this.validEmails = validEmails;
    },
  },
};
</script>

<style lang="scss" scoped>
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding-bottom: 3%;
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}

.headings {
  background-color: #262c3f;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  color: white;
  padding: 10px 0px 10px 0px;

  .left-btns {
    display: flex;
    gap: 10px;

    h2 {
      color: white;
    }
    img {
      width: 22px;
      height: 18px;
      margin-left: 20px;
    }
  }
  .right-btns {
    display: flex;
    gap: 20px;
  }
}
.green-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 180px;
  height: 40px;
  background: #05b050;
  border-radius: 5px;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.02em;
  text-decoration: none;
  border: none;
}

.date-region {
  margin-top: 30px;
}
.container {
  width: 100%;
  display: flex;
  gap: 30px;
}
.left {
  background-color: white;
  width: 25%;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 25px;

  button {
    width: 200px;
    height: 40px;
    background-color: white;
    border: 1px solid #6ed19a;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    text-decoration: none;
    color: black;
  }
}
.right {
  width: 75%;

  .link-invite {
    width: 100%;

    h3 {
      font-family: Poppins;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 40px;
    }
    .main-link {
      display: flex;
      gap: 30px;
      align-items: center;

      input {
        width: 100%;
        height: 48px;
        border-radius: 10px;
      }

      button {
        width: 100px;
        height: 48px;
        padding: 12px 14px 12px 14px;
        border-radius: 10px;
        background-color: #05b050;
        color: white;
        border: none;
      }
    }
  }
}

.bulk {
  display: flex;
  flex-direction: column;

  textarea {
    width: 100%;
  }
}
.check-area {
  display: flex;
  margin-top: 10px;
  gap: 10px;
  align-items: center;

  input {
    width: 20px;
    height: 20px;
  }
  p {
    margin: 0px;
  }
}
.active {
  height: 48px !important;
  padding: 12px 14px 12px 14px !important;
  border-radius: 10px !important;
  background-color: #05b050 !important;
  color: white !important;
  border: none !important;
}
.button-right {
  width: 100%;
  display: flex;
  justify-content: right;
  margin-top: 30px;

  button {
    width: 100px;
    height: 48px;
    padding: 12px 14px 12px 14px;
    border-radius: 10px;
    background-color: #05b050;
    color: white;
    border: none;
    font-family: Maven Pro;
    font-size: 16px;
    font-weight: 700;
  }
}
.add-row {
  padding: 12px 12px;
  height: 42px;
  border: 1px solid #05b050;
  border-radius: 10px;
  color: #05b050;
  font-family: Maven Pro;
  font-size: 16px;
  font-weight: 700;
}
.direct-invite {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.direct {
  background-color: white;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 25px;
  border: 0.5px solid rgb(235, 231, 231);

  button {
    width: 80%;
    height: 40px;
    background-color: white;
    border: 1px solid #6ed19a;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    text-decoration: none;
    color: black;
  }
  button:hover {
    height: 48px !important;
    padding: 12px 14px 12px 14px !important;
    border-radius: 10px !important;
    background-color: #05b050 !important;
    color: white !important;
    border: none !important;
  }
}
$theme: #05b050;
$dark-text: #5f6982;

// Upload Demo
.image-side {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-bottom: 50px;
  height: auto;
  align-items: center;
}
.sel-btn {
  color: #05b050 !important;
  border: 1px solid #05b050 !important;
  background: none !important;
}
.cards {
  background-color: white;
  width: 100%;
  padding: 20px 25px;
  margin-top: 30px;
  border-radius: 15px;
  .head-texts {
    display: flex;
    justify-content: space-between;
    p {
      span {
        font-family: Inter;
        font-size: 18px;
        font-weight: 700;
        color: #05b050;
      }
    }
  }
  .main-sub {
    display: flex;
    width: 100%;
    gap: 15px;
    .left-text {
      width: 35%;
      .yellow-text {
        color: #ff7200;
        margin-top: 55px;
      }
    }

    p {
      width: 100%;
    }
    .features {
      width: 40%;
      padding: 15px;
      background: #f2f2f2;
    }
    .button-area {
      display: flex;
      flex-direction: column;
      gap: 15px;
      justify-content: flex-end;
    }

    button {
      height: 48px;
      padding: 12px 17px 12px 17px;
      border-radius: 10px;
      background-color: #05b050;
      color: white;
      border: none;
    }
  }
}
.merch-height {
  height: 200px;
}
.colored-card {
  background: #fff8e6;
  border: 1px solid #fb8a2e;
  .features {
    background: white !important;
  }
  .cancel {
    background-color: #f9e9e9 !important;
    color: #c32021 !important;
  }
  .upgrade {
    background: #e6f7ee !important;
    color: #6ed19a !important;
  }
}
.auto-renew {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  h4 {
    margin-top: 10px;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #05b050;
}

input:focus + .slider {
  box-shadow: 0 0 1px #05b050;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.sub-history {
  margin-top: 50px;
  width: 100%;
  margin-left: 10px;

  h2 {
    margin-bottom: 40px;
  }
  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #4a4754;
  }

  tr {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 9px;
    gap: 129px;
    height: 66px;
  }
  td {
    flex: 1;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4754;
  }
  .active {
    width: 100px;
    height: 26px;
    background: rgba(221, 252, 220, 0.93);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .active-dot {
      width: 7.52px;
      height: 8px;
      background: #27cd24;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #056b03;
    }
  }
  .expired {
    width: 100px;
    height: 26px;
    background: rgba(252, 237, 220, 0.93);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .expired-dot {
      width: 7.52px;
      height: 8px;
      background: #cd7524;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #6b4103;
    }
  }
  .cancelled {
    width: 100px;
    height: 26px;
    background: rgba(252, 220, 220, 0.93);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .cancelled-dot {
      width: 7.52px;
      height: 8px;
      background: #c52222;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #6b0303;
    }
  }
}
.inactive-p {
  background-color: white;
  color: red;
  border-radius: 5px;
}
.main-box {
  display: flex;
  gap: 15px;
}
.api-container {
  padding: 25px;
  border-radius: 10px;
  border: 1px solid #0000001a;
  background: white;
  width: 75%;
  .each-api {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #0000002e;
    padding: 10px 0px;
  }
}
.total-api {
  padding: 25px;
  border-radius: 10px;
  border: 1px solid #0000001a;
  background: white;
  width: 25%;
  height: 130px;
  h2 {
    color: #05b050;
  }
}
</style>
