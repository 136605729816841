<template>
  <div class="kyb-details-conatainer">
    <h1>KYB Details</h1>
    <h2
      v-if="formatStatus.toLowerCase() === 'pending verification'"
      id="isVerified"
      class="pending"
    >
      {{ formatStatus }}
    </h2>
    <h2 v-else id="isVerified" :class="formatStatus">{{ formatStatus }}</h2>

    <div class="details-wrapper">
      <!-- input details -->
      <div class="items">
        <span class="label">Company Name:</span>
        <span class="values ms-4">{{ name }}</span>
      </div>
      <div class="items mt-3">
        <span class="label">Company Address:</span>
        <span class="values ms-4">{{ address }}</span>
      </div>
      <div class="items mt-3">
        <span class="label">Country:</span>
        <span class="values ms-4">{{ country }}</span>
      </div>
      <div class="items mt-3">
        <span class="label">State:</span>
        <span class="values ms-4">{{ state }}</span>
      </div>
      <div class="items mt-3">
        <span class="label">Contact Person:</span>
        <span class="values ms-4">{{ contact_person }}</span>
      </div>
      <div class="items mt-3">
        <span class="label">Company Website:</span>
        <span class="values ms-4">{{ website }}</span>
      </div>
      <div class="items mt-3">
        <span class="label">Official Email:</span>
        <span class="values ms-4">{{ email }}</span>
      </div>
      <div class="items mt-3">
        <span class="label">Company Phone:</span>
        <span class="values ms-4">{{ phone }}</span>
      </div>
      <div class="items mt-3">
        <span class="label">Tax ID:</span>
        <span class="values ms-4">{{ tax_id }}</span>
      </div>
      <!-- Images -->
      <div id="image-container" class="mt-5">
        <div class="image-size">
          <img :src="cac" alt="" class="img-fluid" />
          <h3>CAC</h3>
        </div>
        <div class="image-size">
          <img :src="mou" alt="" class="img-fluid" />
          <h3>MOU</h3>
        </div>
        <div class="image-size">
          <img :src="fs" alt="" class="img-fluid" />
          <h3>Financial Statement</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config";
export default {
  name: "Prefilled",
  props: {
    previewData: Object,
  },
  data() {
    return {
      cac: "",
      mou: "",
      fs: "",
      tax_id: "",
      contact_person: "",
      website: "",
      name: "",
      address: "",
      country: "",
      state: "",
      phone: "",
      email: "",
    };
  },
  computed: {
    formatStatus() {
      return this.$store.state.kybstatus;
    },
  },

  mounted() {
    if (this.formatStatus.toLowerCase() != "unverified") {
      this.name = this.companyData.company_name;
      this.address = this.companyData.company_address;
      this.state = this.companyData.state;
      this.email = this.companyData.company_email;
      this.phone = this.companyData.company_phone;
      this.country = this.companyData.country;
      this.tax_id = this.$store.state.kybDetails.tax_id;
      this.contact_person = this.$store.state.kybDetails.contact_person;
      this.website = this.$store.state.kybDetails.website;
      this.cac = this.$store.state.kybDetails.cac;
      this.mou = this.$store.state.kybDetails.mou;
      this.fs = this.$store.state.kybDetails.financial_statement;
    } else {
      this.cac = this.toUrl(this.previewData.get("cac"));
      this.mou = this.toUrl(this.previewData.get("mou"));
      this.fs = this.toUrl(this.previewData.get("financial_statement"));
      this.tax_id = this.previewData.get("tax_id");
      this.contact_person = this.previewData.get("contact_person");
      this.website = this.previewData.get("website");
      this.name = this.previewData.get("name");
      this.address = this.previewData.get("address");
      this.country = this.previewData.get("country");
      this.state = this.previewData.get("state");
      this.phone = this.previewData.get("phone");
      this.email = this.previewData.get("email");
    }
  },
  methods: {
    toUrl(file) {
      return URL.createObjectURL(file);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.kyb-details-conatainer {
  padding: 0px 4rem;

  h1 {
    @include textStyles(Poppins, 700, 24px, 120%);
    color: #4a4754;
    text-align: left;
    margin-block: 2rem;
  }
}

.details-wrapper {
  padding-bottom: 50px;
}

.label {
  font-size: 17px;
}

.values {
  font-size: 18px;
  font-weight: 700;
}

#image-container {
  gap: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.items {
  display: flex;
}

#isVerified {
  padding: 5px;
  background: #f5f5f5;
  border-radius: 5px;
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 132%;
  margin-bottom: 2rem;
}

.Unverified {
  color: #b5b4b9;
}

.pending {
  color: orange;
}

.Verified {
  color: green;
}

.image-size {
  width: 50%;

  h3 {
    margin-top: 5px;
  }
}
</style>
