<template>
  <DefaultNav>
    <div class="big-container">
      <div class="headings">
        <h2>Corporatives</h2>
      </div>
      <input type="text" placeholder="search" />
      <Filter
        :apply="applyFilters"
        :reset="resetFilters"
        :market-type="activeView"
      />
      <div class="active-subscriptions">
        <table class="table table-striped sub-history">
          <tr class="dark-header">
            <td class="theader">NAME</td>
            <td class="theader">LOCATION</td>
            <td class="theader">ALL FARMERS</td>
            <td class="theader">QUALIFIED FARMERS</td>
            <td class="theader">ACTION</td>
          </tr>

          <tbody class="scrollable">
            <tr>
              <td>Sayed Farms Limited</td>
              <td>Ondo, Nigeria</td>
              <td>13000</td>
              <td>1000</td>
              <td><a href="/dashboard/qualifiedfarmer">View</a></td>
            </tr>
            <tr>
              <td>Sayed Farms Limited</td>
              <td>Ondo, Nigeria</td>
              <td>13000</td>
              <td>1000</td>
              <td><a href="/dashboard/qualifiedfarmer">View</a></td>
            </tr>
            <tr>
              <td>Sayed Farms Limited</td>
              <td>Ondo, Nigeria</td>
              <td>13000</td>
              <td>1000</td>
              <td><a href="/dashboard/qualifiedfarmer">View</a></td>
            </tr>
            <tr>
              <td>Sayed Farms Limited</td>
              <td>Ondo, Nigeria</td>
              <td>13000</td>
              <td>1000</td>
              <td><a href="dashboard/qualifiedfarmer">View</a></td>
            </tr>
            <tr>
              <td>Sayed Farms Limited</td>
              <td>Ondo, Nigeria</td>
              <td>13000</td>
              <td>1000</td>
              <td><a href="/dashboard/qualifiedfarmer">View</a></td>
            </tr>
            <tr>
              <td>Sayed Farms Limited</td>
              <td>Ondo, Nigeria</td>
              <td>13000</td>
              <td>1000</td>
              <td><a href="/dashboard/qualifiedfarmer">View</a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </DefaultNav>
</template>

<script>
import Filter from "@/pages/dashboard/loan/components/LoanFilter.vue";
import DefaultNav from "@/layouts/DefaultNav.vue";
export default {
  name: "CorporateLoan",
  components: {
    DefaultNav,
    Filter,
  },
};
</script>

<style lang="scss" scoped>
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 2% 5% 2% 3%;

  overflow-y: scroll;
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}
.active-subscriptions {
  background-color: white;
  margin-top: 40px;
}

/* The slider */

.sub-history {
  width: 100%;

  td {
    padding-left: 15px;
  }

  h2 {
    margin-bottom: 40px;
  }
  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: white;
  }

  tr {
    display: flex;
    height: 66px;
    padding-top: 10px;

    a {
      color: white;
      width: 80px;
      height: 40px;
      background: #05b050;
      border-radius: 5px;
      font-weight: 700;
      font-size: 16px;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }
  }

  td {
    flex: 1;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4754;
  }
}
.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  a {
    text-decoration: none;
  }
  .right-btns {
    display: flex;
    gap: 20px;
  }
}
.dark-header {
  background: #121330;
  color: white;
}
</style>
