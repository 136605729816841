<template>
  <DefaultNav>
    <div class="main-content">
      <div class="small-contents">
        <div
          v-for="(input, index) in inputPageList"
          :key="index"
          class="inputs_images_card"
          :class="[input.stock < 1 ? 'fad' : '']"
        >
          <div class="input_image_container">
            <img
              :src="JSON.parse(input.images)[0]"
              alt="Product image"
              class="img-fluid w-100"
            />
          </div>
          <div class="Input_card_body">
            <div class="product_title">{{ input.subcategory.name }}</div>
            <p class="product_content" v-html="input.description"></p>
            <p class="input_footer">
              <span>{{ input.currency }}</span
              >{{ input.price }}/kg
            </p>
          </div>
        </div>
      </div>

      <!-- pagination -->
      <nav class="pagination align-items-center gap-2 my-4">
        <button
          :disabled="currentPage === 1"
          class="btn btn-succ fs-small"
          @click="prevPage"
        >
          Previous
        </button>
        <span class="fs-small"
          >Page {{ currentPage }} of {{ inputTotalPages }}</span
        >
        <button
          :disabled="currentPage === inputTotalPages"
          class="btn btn-succ fs-small"
          @click="inputNextPage"
        >
          Next
        </button>
      </nav>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import MarketPlaceService from "@/services/marketplace";
import Preloader from "@/layouts/shared/Preloader.vue";
export default {
  name: "CompanyProduct",
  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      inputs: [],
      product: "",
      businessType: "",
      currentPage: 1,
      itemsPerPage: 10, // Number of notifications to show per page
      userAccount: {
        accountType: "",
        default: "N/A",
      },
    };
  },

  computed: {
    inputPageList() {
      const sortedInputs = this.sortedInputList();
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return sortedInputs.slice(startIndex, endIndex);
    },
    inputTotalPages() {
      return Math.ceil(this.inputs.length / this.itemsPerPage);
    },
  },
  created() {
    this.getProduct();
  },

  methods: {
    getProduct() {
      MarketPlaceService.getCropById(this.$route.query.id, (response) => {
        this.product = response.data;
        this.inputs = response.data.user.inputs; // store all inputs product listings
        this.businessType = response.data.user.type;
        // call the account name function
        this.accountName();
      });
    },
    accountName() {
      // Check if it's an individual account
      if (this.product.user.company == null) {
        this.userAccount.accountType = "individual";
      }

      // Check if it's a company account
      if (this.product.user.company != null) {
        this.userAccount.accountType = "company";
      }
    },
    sortedInputList() {
      // make a copy of the original input array to avoid modifying the original
      return this.inputs.slice(0).sort((a, b) => {
        if (a.stock < 1 && b.stock >= 1) {
          return 1;
        } else if (a.stock >= 1 && b.stock < 1) {
          return -1;
        } else {
          return 0;
        }
      });
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    inputNextPage() {
      if (this.currentPage < this.inputTotalPages) {
        this.currentPage++;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-succ {
  background: #05b050;
  color: white;

  &:hover {
    background: darken(#05b050, 10%);
  }
}

.fs-small {
  font-size: 14px;
}

.fad {
  opacity: 0.5;
  border: 1px solid red;
}

.main-content {
  background: #f1f1f1;
  width: 100%;
  min-height: 100vh;
  padding: 50px;
}
.small-contents {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}
.inputs_images_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 9px 4px;
  border-radius: 10px;
  border: 1px solid #fff;
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.15);
  width: 280px;

  > div {
    width: 90%;
  }
}
.input_image_container {
  max-height: 200px;
  img {
    height: 100%;
  }
}
.input_card_body > * {
  margin-bottom: 5px;
}

.inputs_wrapper {
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
  flex-wrap: wrap;
}

.product_title {
  color: #4a4754;

  /* Headline/18px */
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.18px;
}

.product_content {
  color: #4a4754;

  /* Body/regular/12px */
  font-family: Maven Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.48px;
}

.input_footer {
  color: #4a4754;
  font-family: Maven Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.16px;
}

.bg-danger {
  background: darken(rgb(220, 53, 69), 10%);
}

ul.pagination {
  li {
    a.page-link {
      border: 0px !important;
      color: #4a4754;

      &:not(#carret-icon):hover,
      :active {
        color: #ffffff;
        background: #05b050;
      }

      &:focus {
        box-shadow: 0px 0px !important;
      }

      span {
        color: #05b050;
      }
    }
  }
}

a:hover {
  box-shadow: 2px 2px 5px #888;
  color: white;
  background-color: #05b050;
}
</style>
