import MarketplaceService from "@/services/marketplace";
export default {
  data() {
    return {
      cart: {},
    };
  },
  computed: {
    cartTotal() {
      var total = 0;
      this.cart.forEach((item) => {
        total += eval(item.price) * eval(item.quantity);
      });
      return total;
    },
  },
  methods: {
    removeCartItem(item) {
      MarketplaceService.deleteCartItem(item.id, (response) => {
        if (response.error === false) {
          this.getCartItems();
        }
      });
    },

    getCartItems(callback) {
      MarketplaceService.getCartItems(this.userData.user_id, (response) => {
        if (response) {
          response.data.forEach((item) => {
            if (item.input == null) {
              this.removeCartItem(item);
            }
          });
          this.cart = response.data;
          console.log(this.cart);
          if (callback) {
            callback();
          }
        }
      });
    },
  },
  mounted() {},
};
