<template>
  <DefaultNav>
    <div class="big-container">
      <div class="headings">
        <h2>All DVS Scores</h2>
        <div class="right-btns">
          <!-- <a href="/dashboard/farmer/batch/add" class="green-link"
            >Batch Upload</a
          > -->
          <!-- <a href="javascript:void(0)" class="green-link">Score Farmer(s)</a> -->
          <!-- <a
            href="/dashboard/farmer/batch/score"
            class="green-link bg-warning text-center"
            >View Credit Scoring History</a
          > -->
          <!-- <a href="" class="green-link">Batch Upload</a> -->
          <!-- <a href="" class="red-link">Sent Invoice</a> -->
        </div>
      </div>
      <div class="active-subscriptions">
        <table class="table-borderless sub-history">
          <tbody class="scrollable">
            <tr>
              <td class="serial">S/N</td>
              <td class="theader">NAME</td>
              <td class="theader">PHONE</td>
              <td class="theader">DVS SCORE</td>
              <td class="theader">PDS SCORE</td>
              <td class="theader">AVE SCORE</td>
              <td class="theader">ACTION</td>
            </tr>
            <hr />
            <div v-if="DVSFarmerScores.length > 0">
              <template v-for="(data, index) in DVSFarmerScores" :key="index">
                <tr>
                  <td class="serial">{{ index + 1 }}</td>
                  <td>
                    {{ data.farmer.farmer.firstname }}
                    <span v-if="data.farmer.farmer.middlename != '-'">
                      {{ data.farmer.farmer.middlename }}
                    </span>
                    {{ data.farmer.farmer.lastname }}
                  </td>
                  <td>{{ data.farmer.farmer.mobile }}</td>
                  <td>
                    {{ roundValues(data.totalscore) }} {{ data.totalgrade }}
                  </td>
                  <td>
                    {{ roundValues(data.farmer.creditscore.score) }}
                    {{ data.farmer.creditscore.grade }}
                  </td>
                  <td>
                    {{
                      roundValues(
                        (data.farmer.creditscore.score + data.totalscore) / 2,
                      )
                    }}
                  </td>
                  <td>
                    <a
                      :href="`/dashboard/creditscore/dvs/${data.farmerid}`"
                      class="btn btn-success"
                      style="font-weight: bold"
                      ><small>View</small></a
                    ><br />
                    <a
                      v-if="
                        data.totalgrade == 'A' ||
                        data.totalgrade == 'B' ||
                        (data.totalgrade == 'C' && currentuserid != 'partner')
                      "
                      href="javascript:void(0)"
                      class="btn btn-success ms-2"
                      @click="goto(`${data.farmer.farmer.farmerid}`)"
                      ><small>Request</small></a
                    >
                  </td>
                </tr>
              </template>
            </div>
            <div v-else>
              <h5 class="text-center mt-4">No Scores found</h5>
            </div>
          </tbody>
          <!-- pagination -->
          <nav class="d-flex justify-content-center my-4">
            <div id="pagination"></div>
          </nav>
        </table>
      </div>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import Preloader from "@/layouts/shared/Preloader.vue";
import FarmerService from "@/services/farmer";
// import Alert from "@/utilities/alert";
// import NilData from "@/utilities/returnNil";

export default {
  name: "Loan",
  components: {
    DefaultNav,
    Preloader,
  },
  data() {
    return {
      DVSFarmerScores: [],
      currentuserid: JSON.parse(localStorage.getItem("Zowasel")).user.user.type,
      uid: JSON.stringify(
        JSON.parse(localStorage.getItem("Zowasel")).user.user_id,
      ),
    };
  },
  mounted() {
    console.log(this.currentuserid);

    FarmerService.getAllDvsScores((response) => {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        if (this.currentuserid != "partner") {
          const filteringList = response.data;
          this.DVSFarmerScores = filteringList.filter(
            (data) => data.farmer.farmer.userid == this.uid,
          );
          if (this.DVSFarmerScores.length > 0) {
            this.paginate(filteringList, "farmerData");
          }
        } else {
          this.DVSFarmerScores = response.data;
          this.paginate(response.data.reverse(), "DVSFarmerScores");
          console.log(this.DVSFarmerScores);
        }
      }, 2000);
    });
  },
  methods: {
    roundValues(x) {
      if (typeof x == "number") {
        return x.toFixed(2);
      } else {
        return x;
      }
    },
    goto(loc) {
      localStorage.setItem("farmerID", loc);
      window.location.href = `/dashboard/loan/type/select`;
    },
  },
};
</script>

<style lang="scss" scoped>
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 4% 5% 10% 3%;
  overflow-y: scroll;
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}
.active-subscriptions {
  background-color: white;
  padding: 24px 35px;
  margin-top: 20px;
}
.prl {
  padding: 24px 35px;
}
.sub-history {
  margin-top: 30px;
  width: 100%;
  margin-left: 10px;

  h2 {
    margin-bottom: 40px;
  }
  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #4a4754;
  }

  .serial {
    max-width: 35px;
  }

  tr {
    display: flex;
    flex-direction: row;
    padding: 20px 9px 0;
    height: 66px;
    th {
      font-weight: bold;
    }
  }
  td {
    flex: 1;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4754;
    max-width: 185px;
    overflow-y: auto;
    padding-left: 10px;
  }
  .active {
    width: 100px;
    height: 26px;
    background: #dcfce7;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .active-dot {
      width: 7.52px;
      height: 8px;
      background: #047b38;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #047b38;
    }
  }
  .expired {
    width: 100px;
    height: 26px;
    background: #fff8e6;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .expired-dot {
      width: 7.52px;
      height: 8px;
      background: #fab900;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #fab900;
    }
  }
  .cancelled {
    width: 100px;
    height: 26px;
    background: rgba(252, 220, 220, 0.93);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .cancelled-dot {
      width: 7.52px;
      height: 8px;
      background: #c52222;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #6b0303;
    }
  }
  a {
    text-decoration: none;
  }
  a:hover td {
    color: #05b050 !important;
  }
}
.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  a {
    text-decoration: none;
  }
  .right-btns {
    display: flex;
    gap: 20px;
  }
  .green-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 180px;
    height: 40px;
    background: #05b050;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    border-radius: 4px;
  }
  .white-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 40px;
    background: #ffffff;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #05b050;
    border: 2px solid #05b050;
    border-radius: 4px;
  }
  .red-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 180px;
    height: 40px;
    background: #cd4647;
    box-shadow:
      0px 2px 4px rgba(44, 39, 56, 0.08),
      0px 4px 8px rgba(44, 39, 56, 0.08);
    border-radius: 4px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    border-radius: 4px;
  }
}
.date-region {
  margin-top: 30px;
}
.view-btn {
  color: black;
}
.bg-grey {
  background: rgba(0, 0, 0, 0.183);
}
.sectionSelectButton {
  padding: 11px 0;
  border-radius: 5px;
  border: none;
  width: 50%;
  background: rgba(0, 0, 0, 0.183);
}
.sectionSelectButton.active {
  background: #262c3f;
  color: white;
}

.outline-green {
  color: #05b050 !important;
  width: 230px !important;
  text-align: center;
  background: #f5f5f5 !important;
  border: 2px solid #05b050;
}
</style>
