<template>
  <div class="main-content">
    <div class="right">
      <template v-if="view == 'cropwanted'">
        <div v-for="(crop, index) in pageList" :key="index" class="each-item">
          <div>
            <p :class="[crop.specification.qty < 1 ? 'fad' : '']">
              {{ crop.subcategory.name }} - {{ crop.specification.color }}
            </p>
            <span
              v-if="crop.specification.qty < 1"
              class="text-danger fw-bold d-block"
            >
              Sold Out
            </span>
          </div>
          <p :class="[crop.specification.qty < 1 ? 'fad' : '']">
            {{ JSON.parse(crop.crop_request.delivery_window).from }} -
            {{ JSON.parse(crop.crop_request.delivery_window).to }}
          </p>
          <p :class="[crop.specification.qty < 1 ? 'fad' : '']">
            <b>{{ crop.category.name }}</b>
          </p>

          <p
            v-if="crop.user.account_type === 'individual'"
            :class="[crop.specification.qty < 1 ? 'fad' : '']"
          >
            {{ crop.user ? crop.user.first_name : "N/A" }}
          </p>
          <p
            v-if="crop.user.account_type === 'company'"
            :class="[crop.specification.qty < 1 ? 'fad' : '']"
          >
            {{ crop.user.company ? crop.user.company.company_name : "N/A" }}
          </p>
          <p :class="[crop.specification.qty < 1 ? 'fad' : '']">
            <b
              >{{ crop.currency }} {{ crop.specification.price }}/{{
                crop.specification.test_weight.toUpperCase()
              }}</b
            >
          </p>
          <p :class="[crop.specification.qty < 1 ? 'fad' : '']">
            {{ crop.crop_request.state }}
          </p>
          <button
            :class="[
              'green-btn',
              crop.specification.qty < 1 ? 'bg-danger' : 'green',
            ]"
            @click="
              $router.push({ name: 'CropDetails', params: { id: crop.id } })
            "
          >
            View
          </button>
        </div>

        <!-- Pagination -->
        <nav class="pagination align-items-center gap-2 my-4">
          <button
            :disabled="currentPage === 1"
            class="btn btn-succ fs-small"
            @click="prevPage"
          >
            Previous
          </button>
          <span class="fs-small"
            >Page {{ currentPage }} of {{ totalPages }}</span
          >
          <button
            :disabled="currentPage === totalPages"
            class="btn btn-succ fs-small"
            @click="nextPage"
          >
            Next
          </button>
        </nav>
      </template>

      <template v-if="view == 'inputs'">
        <div class="big-contents">
          <div class="small-contents">
            <a
              v-for="(input, index) in inputPageList"
              :key="index"
              :href="'/dashboard/marketplace/product/' + input.id"
              :class="['each-product', input.stock < 1 ? 'fad-opacity' : '']"
            >
              <!-- <p class="badge">-36%</p> -->
              <div class="input_img">
                <img
                  class="img-fluid"
                  :src="generateImageUrl(input.images[0])"
                  alt=""
                />
              </div>
              <h3>{{ input.subcategory.name }}</h3>
              <p v-html="truncateDescription(input.description, 50)"></p>
              <h3>
                <span
                  >{{ input.currency }}{{ input.price }}
                  <span
                    v-if="input.stock < 1"
                    class="bg-danger text-white float-end p-2"
                  >
                    Out of stock
                  </span></span
                >
              </h3>
            </a>
          </div>
        </div>

        <!-- pagination -->
        <nav class="pagination align-items-center gap-2 my-4">
          <button
            :disabled="currentPage === 1"
            class="btn btn-succ fs-small"
            @click="prevPage"
          >
            Previous
          </button>
          <span class="fs-small"
            >Page {{ currentPage }} of {{ inputTotalPages }}</span
          >
          <button
            :disabled="currentPage === inputTotalPages"
            class="btn btn-succ fs-small"
            @click="inputNextPage"
          >
            Next
          </button>
        </nav>
      </template>
    </div>
  </div>
</template>

<script>
import MarketplaceService from "@/services/marketplace";
import { A } from "caniuse-lite/data/agents";
export default {
  name: "MerchantMarket",
  components: {},
  props: {
    view: String,
  },
  data() {
    return {
      categories: [],
      wanted: [],
      inputs: [],
      filtered: null,
      paginatedList: [],
      selectedSort: "",
      deliveryWindow: "",
      currentPage: 1,
      itemsPerPage: 10, // Number of notifications to show per page
    };
  },

  computed: {
    listToRender() {
      return this.filtered
        ? this.filtered.rows ?? this.filtered
        : this.view == "inputs"
          ? this.inputs
          : this.wanted.rows;
    },

    pageList() {
      const sortedWanted = this.sortedWantedList();
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return sortedWanted.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.wanted.length / this.itemsPerPage);
    },
    inputPageList() {
      const sortedInputs = this.sortedInputList();
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return sortedInputs.slice(startIndex, endIndex);
    },
    inputTotalPages() {
      return Math.ceil(this.inputs.length / this.itemsPerPage);
    },
  },
  watch: {
    selectedSort(newValue) {
      this.sortData(newValue);
    },
  },
  created() {
    this.getCropCategories();
    this.getInputs();
    this.getCropsWanted();
  },
  methods: {
    sortedWantedList() {
      // make a copy of the original wanted array to avoid modifying the original
      return this.wanted.slice(0).sort((a, b) => {
        if (a.specification.qty < 1 && b.specification.qty >= 1) {
          return 1; // Move items with qty < 1 to the end
        } else if (a.specification.qty >= 1 && b.specification.qty < 1) {
          return -1; // Move items with qty >= 1 to the beginning
        } else {
          // If both have the same qty or both have qty < 1, maintain their order
          return 0;
        }
      });
    },
    sortedInputList() {
      // make a copy of the original input array to avoid modifying the original
      return this.inputs.slice(0).sort((a, b) => {
        if (a.stock < 1 && b.stock >= 1) {
          return 1;
        } else if (a.stock >= 1 && b.stock < 1) {
          return -1;
        } else {
          return 0;
        }
      });
    },

    truncateDescription(value, maxLength) {
      if (value && value.length > maxLength) {
        value = value.substring(0, maxLength - 3) + "...";
      }
      return value;
    },
    generateImageUrl(url) {
      return url.includes("http") ? url : this.appConfig.PUBLIC_URL + url;
    },
    checked() {
      var box = document.getElementById("checkbox");
      var deColor = box.style.backgroundColor;
      if (deColor == "white") {
        deColor = "green";
      }
    },
    getCropCategories() {
      MarketplaceService.getCropCategories((response) => {
        this.categories = response.data;
      });
    },
    getCropsWanted() {
      MarketplaceService.getCropsWanted((response) => {
        this.wanted = response.data.rows;
      });
    },
    getInputs() {
      MarketplaceService.getInputs((response) => {
        for (var i = 0; i < response.data.length; i++) {
          response.data[i].images = JSON.parse(response.data[i].images);
        }
        this.inputs = response.data;
        // this.paginate(response.data, "paginatedList");
      });
    },
    applyFilters(filters) {
      let filtered = JSON.parse(
        JSON.stringify(this.view == "inputs" ? this.inputs : this.wanted.rows),
      );
      Object.keys(filters).forEach((filter) => {
        if (filter == "category" && filters[filter] != "") {
          filtered.rows = filtered.rows.filter(
            (item) => item.category.name == filters[filter],
          );
        }
        if (filter == "price") {
          filtered.rows = filtered.rows.filter(
            (item) =>
              eval(item.specification.price ?? item.price) >=
                eval(filters[filter].min) &&
              eval(item.specification.price ?? item.price) <=
                eval(filters[filter].max),
          );
        }
      });
      this.filtered = filtered;
      this.paginate(filtered.rows ?? filtered, "paginatedList");
    },
    resetFilters() {
      this.filtered = null;
    },
    sortData(sortBy) {
      if (sortBy === "best_price") {
        this.wanted.rows.sort((a, b) => {
          return a.specification.price - b.specification.price;
        });
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    inputNextPage() {
      if (this.currentPage < this.inputTotalPages) {
        this.currentPage++;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-succ {
  background: #05b050;
  color: white;
  &:hover {
    background: darken(#05b050, 10%);
  }
}
.fs-small {
  font-size: 14px;
}
.top-texts {
  h3 {
    font-size: 20px;
  }

  select {
    font-size: 14px;
  }

  p {
    font-size: 14px;
  }
}

.top-text {
  width: 100%;
  background: #262c3f;
  color: white;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 68px;
  }
}

.second-bar {
  width: 100%;
  background-color: white;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-area {
  width: 60%;
  display: flex;
  align-items: center;

  input {
    width: 95%;
    height: 60px;
    margin-top: 0px;
    border: 1px solid #05b050 !important;
    border-radius: 5px;
  }

  .icon {
    width: 54px;
    height: 60px;
    background-color: #05b050;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.button-area {
  margin-top: 35px;
  display: flex;
  gap: 16px;
  width: 60%;
  justify-content: space-between;

  button {
    background-color: white;
    height: 40px;
    padding: 0px 35px;
    border: 1px solid #696671;
    border-radius: 55px;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: #696671;
  }

  .green-btns {
    border: none;
    color: white;
  }
  .green {
    background-color: #05b050;
  }
}
.fad {
  opacity: 0.5;
}
.main-content {
  background: #f1f1f1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 100vh;

  .left {
    width: 25%;
    background-color: white;
    padding: 40px 20px;

    h3 {
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }

  .check {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;

    input {
      width: 100%;
      height: 100%;
    }
  }

  #checkbox {
    background-color: white;
  }
}

.each-type {
  display: flex;
  justify-content: space-between;

  .left-type {
    display: flex;

    p {
      margin-left: 10px;
    }
  }

  .green-text {
    padding: 5px;
    background: #ededee;
    border-radius: 4px;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #05b050;
  }
}

.quantity {
  width: 100%;

  select {
    background: #f7f7f7;
    border: 1px solid #ededee;
    border-radius: 4px;
    padding: 10px 15px;
    width: 35%;
  }

  input {
    background: #f7f7f7;
    border: 1px solid #ededee;
    border-radius: 4px;
    width: 65%;
  }
}

.shipping {
  display: flex;
  justify-content: space-around;
}

.each-ship {
  display: flex;

  p {
    margin-left: 10px;
  }
}

.shop-center {
  width: 100%;
  background: #f7f7f7;
  border: 1px solid #ededee;
  border-radius: 4px;
  padding: 10px 15px;
}

.range input {
  width: 100%;
}

.min-max {
  display: flex;
}

.min {
  display: flex;
  flex-direction: column;

  input {
    width: 80%;
  }
}

.apply {
  width: 100%;
  background-color: #05b050;
  color: white;
  height: 50px;
  border: none;
  box-shadow:
    0px 2px 4px rgba(44, 39, 56, 0.08),
    0px 4px 8px rgba(44, 39, 56, 0.08);
  border-radius: 4px;
}

.reset {
  width: 100%;
  background-color: white;
  color: #05b050;
  height: 50px;
  margin-top: 20px;
  border: 1px solid #05b050;
  box-shadow:
    0px 2px 4px rgba(44, 39, 56, 0.08),
    0px 4px 8px rgba(44, 39, 56, 0.08);
  border-radius: 4px;
}

.btns-down {
  margin-top: 100px;
}

.fad-opacity {
  opacity: 0.5;
}
.small-contents {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 400px));
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 15px;
  padding-bottom: 5rem;

  .each-product {
    background-color: white;
    padding: 10px;
    position: relative;
    text-decoration: none;
    border-radius: 8px;
    margin-top: 15px;

    h3 {
      margin-top: 5px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      color: #4a4754;
    }
    p {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #4a4754;
      margin: 0px !important;
    }
    .badge {
      position: absolute;
      top: 24px;
      left: 22px;
      background: #fab900;
      border-radius: 4px;
      color: white;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 130%;
    }
    .input_img {
      height: 15em;
      display: flex;
      img {
        width: 100%;
      }
    }
  }
}
.right {
  width: 90%;
  justify-self: center;
  display: flex;
  flex-direction: column;
}

.top-texts {
  display: flex;
  justify-content: space-between;

  .top-text-right {
    display: flex;
    align-items: baseline;

    p {
      margin-top: 5px;
    }

    select {
      margin-left: 10px;
      margin-top: 0px;
      background-color: white;
      border: 1px solid #05b050 !important;
      // height: 50px;
      padding: 0px 0px 0px 20px;
    }
  }
}

.each-item {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  padding: 10px 20px;
  text-decoration: none;
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
  color: #4a4754;
  gap: 20px;

  p {
    margin: 0px;
    text-align: left;
    width: 150px;

    // flex: 1;
  }

  color: #4a4754;
}

.tags {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
  align-items: baseline;

  h4 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #19191d;
  }

  a {
    padding: 10px 20px;
    background: #ededf0;
    border-radius: 99px;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4b57;
    text-decoration: none;
  }
}

ul.pagination {
  li {
    a.page-link {
      border: 0px !important;
      color: #4a4754;

      &:not(#carret-icon):hover,
      :active {
        color: #ffffff;
        background: #05b050;
      }

      &:focus {
        box-shadow: 0px 0px !important;
      }

      span {
        color: #05b050;
      }
    }
  }
}

a:hover {
  box-shadow: 2px 2px 5px #888;
  color: white;
  background-color: #05b050;
}

.green-btn {
  background: #05b050;
  box-shadow:
    0px 2px 4px rgba(44, 39, 56, 0.08),
    0px 4px 8px rgba(44, 39, 56, 0.08);
  border-radius: 4px;
  width: 100px;
  height: 40px;
  border: none;
  &:hover {
    background: darken(#05b050, 10%);
  }
}
</style>
