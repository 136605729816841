<template>
  <DefaultNav>
    <div class="big-container">
      <div class="top-text">
        <h1>Input Orders</h1>
      </div>
      <div class="big-content">
        <div class="left-side">
          <div class="each-item">
            <div class="image">
              <img alt="" />
            </div>
            <div class="first">
              <h4>Item:</h4>
              <h4>Seller:</h4>
              <h4>Unit Cost:</h4>
            </div>
            <div class="second">
              <p>starboy</p>
              <p>Naziri Farms</p>
              <p>anyhow</p>
            </div>
            <div class="third">
              <h4>Quantity</h4>
              <h4>Sub Total:</h4>
            </div>
            <div class="fourth">
              <div class="btnss">
                <a
                  href="javascript:void(0)"
                  class="p-btn"
                  @click="decrement(index)"
                  >-</a
                >
                <p>55</p>
                <a
                  href="javascript:void(0)"
                  class="p-btn"
                  @click="increment(index)"
                  >+</a
                >
              </div>
              <p>NGN 5500</p>
              <button class="remove-btn" @click="removeCartItem(item)">
                Remove
              </button>
            </div>
          </div>
          <hr />
          <div class="each-item">
            <div class="image">
              <img alt="" />
            </div>
            <div class="first">
              <h4>Item:</h4>
              <h4>Seller:</h4>
              <h4>Unit Cost:</h4>
            </div>
            <div class="second">
              <p>starboy</p>
              <p>Naziri Farms</p>
              <p>anyhow</p>
            </div>
            <div class="third">
              <h4>Quantity</h4>
              <h4>Sub Total:</h4>
            </div>
            <div class="fourth">
              <div class="btnss">
                <a
                  href="javascript:void(0)"
                  class="p-btn"
                  @click="decrement(index)"
                  >-</a
                >
                <p>55</p>
                <a
                  href="javascript:void(0)"
                  class="p-btn"
                  @click="increment(index)"
                  >+</a
                >
              </div>
              <p>NGN 5500</p>
              <button class="remove-btn" @click="removeCartItem(item)">
                Remove
              </button>
            </div>
          </div>
          <hr />
          <div class="each-item">
            <div class="image">
              <img alt="" />
            </div>
            <div class="first">
              <h4>Item:</h4>
              <h4>Seller:</h4>
              <h4>Unit Cost:</h4>
            </div>
            <div class="second">
              <p>starboy</p>
              <p>Naziri Farms</p>
              <p>anyhow</p>
            </div>
            <div class="third">
              <h4>Quantity</h4>
              <h4>Sub Total:</h4>
            </div>
            <div class="fourth">
              <div class="btnss">
                <a
                  href="javascript:void(0)"
                  class="p-btn"
                  @click="decrement(index)"
                  >-</a
                >
                <p>55</p>
                <a
                  href="javascript:void(0)"
                  class="p-btn"
                  @click="increment(index)"
                  >+</a
                >
              </div>
              <p>NGN 5500</p>
              <button class="remove-btn" @click="removeCartItem(item)">
                Remove
              </button>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>
  </DefaultNav>
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import Alert from "@/utilities/alert";
import MarketPlaceService from "@/services/marketplace";

export default {
  name: "InputOrders",
  components: {
    DefaultNav,
  },
  data() {
    return {
      userData: this.$store.state.user,
      cart: [],
    };
  },
  computed: {},
  mounted() {
    this.getCartItems();
  },
  methods: {
    parse(data) {
      return JSON.parse(data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.top-text {
  padding: 14px 30px;
  width: 100%;
  background: #262c3f;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 50px;
}
.big-content {
  width: 100%;
  padding: 50px 35px;
  background: #f1f1f1;
  display: flex;
  flex-direction: row;
  gap: 20px;

  .left-side {
    width: 100%;
    background-color: white;
    padding: 38px;

    .each-item {
      display: flex;
      justify-content: space-between;
      gap: 13px;

      .image {
        width: 150px;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .btnss {
        display: flex;
        gap: 10px;
        height: 35px;
        align-items: center;
      }
      h4 {
        margin-top: 20px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        color: #2d2b33;
        margin-bottom: 0px;
      }
      p {
        margin-top: 15px;
        font-family: "Maven Pro";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: rgba(45, 55, 72, 0.6);
      }
    }
    .p-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      width: 20px;
      height: 20px;
      background: #ededee;
      border-radius: 50%;
      color: black;
    }
    .remove-btn {
      background: #f9e9e9;
      border: 1px solid #c32021;
      border-radius: 4px;
      height: 35px;
      width: 115px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      color: #c32021;
    }
  }
}
</style>
