<template>
  <DefaultNav>
    <div class="big-container">
      <div class="headings">
        <h2>Invoice</h2>
        <div class="tabs-section">
          <button id="recent-btn" class="active-tab" @click="activetab1">
            Received Invoice
          </button>
          <button id="history-btn" @click="activetab2">Sent Invoice</button>
        </div>
      </div>

      <div class="row date-region">
        <div class="col">
          <input type="text" class="form-control" placeholder="search" />
        </div>
        <div class="col">
          <input type="date" class="form-control" placeholder="Date Paid" />
        </div>
        <div class="col">
          <select class="form-control">
            <option selected>Status</option>
            <option>Pending</option>
            <option value="">Paid</option>
          </select>
        </div>
      </div>

      <div class="active-subscriptions">
        <table class="table-borderless sub-history">
          <tr>
            <td class="theader">INVOICE</td>
            <td class="theader">BILLER</td>
            <td class="theader">DESCRIPTION</td>
            <td class="theader">AMOUNT</td>
            <td class="theader">DATE PAID</td>
            <td class="theader">STATUS</td>
          </tr>

          <tbody class="scrollable">
            <a href="/dashboard/each-invoice">
              <tr>
                <td>INV-001-123456</td>
                <td>Naziri Farms</td>
                <td>500 units of dish washers</td>
                <td>NGN76,564</td>
                <td>09 SEP 2022</td>
                <td>
                  <div class="active">
                    <div class="active-dot" />
                    <p>Active</p>
                  </div>
                </td>
              </tr>
            </a>

            <a href="">
              <tr>
                <td>INV-001-123456</td>
                <td>Naziri Farms</td>
                <td>500 units of dish washers</td>
                <td>NGN76,564</td>
                <td>09 SEP 2022</td>
                <td>
                  <div class="active">
                    <div class="active-dot" />
                    <p>Active</p>
                  </div>
                </td>
              </tr>
            </a>
            <a href="">
              <tr>
                <td>INV-001-123456</td>
                <td>Naziri Farms</td>
                <td>500 units of dish washers</td>
                <td>NGN76,564</td>
                <td>09 SEP 2022</td>
                <td>
                  <div class="active">
                    <div class="active-dot" />
                    <p>Active</p>
                  </div>
                </td>
              </tr>
            </a>
            <a href="#">
              <tr>
                <td>INV-001-123456</td>
                <td>Naziri Farms</td>
                <td>500 units of dish washers</td>
                <td>NGN76,564</td>
                <td>---</td>
                <td>
                  <div class="expired">
                    <div class="expired-dot" />
                    <p>Pending</p>
                  </div>
                </td>
              </tr>
            </a>

            <a href="">
              <tr>
                <td>INV-001-123456</td>
                <td>Naziri Farms</td>
                <td>500 units of dish washers</td>
                <td>NGN76,564</td>
                <td>---</td>
                <td>
                  <div class="expired">
                    <div class="expired-dot" />
                    <p>Pending</p>
                  </div>
                </td>
              </tr>
            </a>
            <a href="">
              <tr>
                <td>INV-001-123456</td>
                <td>Naziri Farms</td>
                <td>500 units of dish washers</td>
                <td>NGN76,564</td>
                <td>09 SEP 2022</td>
                <td>
                  <div class="active">
                    <div class="active-dot" />
                    <p>Active</p>
                  </div>
                </td>
              </tr>
            </a>

            <a href="">
              <tr>
                <td>INV-001-123456</td>
                <td>Naziri Farms</td>
                <td>500 units of dish washers</td>
                <td>NGN76,564</td>
                <td>---</td>
                <td>
                  <div class="expired">
                    <div class="expired-dot" />
                    <p>Pending</p>
                  </div>
                </td>
              </tr>
            </a>
          </tbody>
        </table>
      </div>
    </div>
  </DefaultNav>
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
export default {
  name: "Invoice",
  components: {
    DefaultNav,
  },
};
</script>

<style lang="scss" scoped>
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 2% 5% 2% 3%;
  overflow-y: scroll;
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}
.active-subscriptions {
  background-color: white;
  padding: 24px 35px;
  margin-top: 40px;
}

/* The slider */

.sub-history {
  margin-top: 50px;
  width: 100%;
  margin-left: 10px;

  h2 {
    margin-bottom: 40px;
  }
  .theader {
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #4a4754;
  }

  tr {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 9px;
    gap: 129px;
    height: 66px;
  }
  td {
    flex: 1;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4754;
  }
  .active {
    width: 100px;
    height: 26px;
    background: #dcfce7;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .active-dot {
      width: 7.52px;
      height: 8px;
      background: #047b38;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #047b38;
    }
  }
  .expired {
    width: 100px;
    height: 26px;
    background: #fff8e6;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .expired-dot {
      width: 7.52px;
      height: 8px;
      background: #fab900;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #fab900;
    }
  }
  .cancelled {
    width: 100px;
    height: 26px;
    background: rgba(252, 220, 220, 0.93);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .cancelled-dot {
      width: 7.52px;
      height: 8px;
      background: #c52222;
      border-radius: 50%;
    }
    p {
      margin-top: 15px;
      margin-left: 8px;
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #6b0303;
    }
  }
  a {
    text-decoration: none;
  }
}
.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  a {
    text-decoration: none;
  }
  .right-btns {
    display: flex;
    gap: 20px;
  }
  .green-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 180px;
    height: 40px;
    background: #05b050;
    border-radius: 55px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
  }
  .white-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 40px;
    background: #ffffff;
    border: 1.11212px solid #696671;
    border-radius: 55px;
    font-family: "Maven Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #696671;
  }
}
.date-region {
  margin-top: 30px;
}
.tabs-section button {
  background: rgba(118, 118, 128, 0.12);
  border-radius: 9px;
  height: 50px;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding: 0px 20px;
  color: #262c3f;
  position: relative;
}
.active-tab {
  background: #262c3f !important;
  color: white !important;
  z-index: 10;
}
</style>
