// checks the fields and if the field should be empty, send nil else leave it empty
const NilData = {
  returnNil: (fieldContent, dependentField = "", isString = true) => {
    let dataToBeReturned;

    if (dependentField == "1") {
      if (isString) {
        dataToBeReturned = "N/A";
      } else {
        dataToBeReturned = 0;
      }
    } else {
      dataToBeReturned = fieldContent;
    }
    return dataToBeReturned;
  },
  /* ------ if the field has a N/A value, omit, leave that field as empty ----- */
  returnEmptyField: (fieldContent) => {
    if (fieldContent == "N/A" || fieldContent == "nil") {
      return "";
    } else {
      return fieldContent;
    }
  },
  /* ----------------- check response and return valid respose ---------------- */
  checkLoan: (fieldContent, dependentField = "", isString = true) => {
    let dataToBeReturned;

    if (dependentField == "0") {
      if (isString) {
        dataToBeReturned = "N/A";
      } else {
        dataToBeReturned = 0;
      }
    } else {
      dataToBeReturned = fieldContent;
    }
    return dataToBeReturned;
  },
  /* ------------------------ send a N/A as data to DB ------------------------ */
  sendNAtoDB: (data, isString = true) => {
    if (data == "") {
      if (isString) {
        return "N/A";
      } else {
        return 0;
      }
    } else {
      return data;
    }
  },
};

export default NilData;
