<template>
  <div class="main_container">
    <!-- for edit page -->
    <div v-if="productID" class="edit_crop">
      Update Crop Details By Editing The Input Field With The Updated
      Information.
    </div>
    <!-- for all pages  -->
    <!-- new crop  wanted form-->
    <div>
      <div class="crop-wanted-section d-flex flex-row gap-4">
        <div class="form">
          <div class="crop_details">Crop Details</div>
          <div class="w-100 mb-3">
            <label for="exampleInputEmail1" class="form-label mb-0"
              >Crop Category</label
            >
            <span id="required">*</span>
            <select
              v-model="cropData.category_id"
              class="form-select"
              aria-label="Default select example"
              required
            >
              <option
                v-for="category in categories"
                :key="category.id"
                :value="category.id"
              >
                {{ category.name }}
              </option>
            </select>
          </div>
          <div class="w-100 mb-3">
            <label for="exampleInputEmail1" class="form-label mb-0"
              >Product sub category</label
            >
            <span id="required">*</span>
            <select
              v-model="cropData.subcategory_id"
              class="form-select"
              aria-label="Default select example"
              required
            >
              <option
                v-for="subCategory in subCategoryByCategory"
                :key="subCategory.id"
                :value="subCategory.id"
              >
                {{ subCategory.name }}
              </option>
            </select>
          </div>
          <div class="w-100 mb-3">
            <label for="exampleInputEmail1" class="form-label mb-0"
              >Product Color</label
            >
            <span id="required">*</span>
            <select
              v-model="cropData.color"
              class="form-select"
              aria-label="Default select example"
              required
            >
              <option
                v-for="color in colors"
                :key="color.id"
                :value="color.name"
              >
                {{ color.name }}
              </option>
            </select>
          </div>
          <div class="w-100 mb-3">
            <label for="exampleInputEmail1" class="form-label mb-0"
              >Moisture content (MC)</label
            >
            <span id="required">*</span>
            <select
              v-model="cropData.moisture_content"
              class="form-select"
              aria-label="Default select example"
              required
            >
              <option v-for="item in 100" :key="item" :value="item">
                {{ item }}%
              </option>
            </select>
          </div>
          <div class="w-100 mb-3">
            <label for="exampleInputEmail1" class="form-label mb-0"
              >Forieign Matter (FM)</label
            >
            <span id="required">*</span>
            <select
              v-model="cropData.foreign_matter"
              class="form-select"
              aria-label="Default select example"
              required
            >
              <option v-for="item in 100" :key="item" :value="item">
                {{ item }}%
              </option>
            </select>
          </div>
          <div class="w-100 mb-3">
            <label for="exampleInputEmail1" class="form-label mb-0"
              >Test weight</label
            >
            <span id="required">*</span>
            <select
              v-model="cropData.test_weight"
              class="form-select"
              aria-label="Default select example"
              required
            >
              <option value="kg">KG</option>
              <option value="mt">MT</option>
            </select>
          </div>
          <div class="w-100 mb-3">
            <label for="exampleInputEmail1" class="form-label mb-0"
              >Negotiable</label
            >
            <span id="required">*</span>
            <select
              v-model="cropData.is_negotiable"
              class="form-select"
              aria-label="Default select example"
              required
            >
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
          </div>
        </div>
        <div class="vertical-line" />
        <!-- form two -->
        <div class="form">
          <!-- Editor -->
          <div class="crop_details">
            Quality description <span id="required">*</span>
          </div>
          <input
            v-model="cropData.description"
            class="hidden-input ms-5"
            type="text"
            name="description"
            required
          />
          <div class="d-flex flex-row mb-3 gap-4">
            <div class="w-100 editor-wrapper">
              <!-- using quill editor tool-->
              <div ref="editor" @text-change="handleContentChange" />
            </div>
          </div>
          <!-- editor ends -->
          <div class="w-100 mb-3">
            <label for="exampleInputEmail1" class="form-label mb-0"
              >Currency</label
            >
            <span id="required">*</span>
            <select
              v-model="cropData.currency"
              class="form-select"
              aria-label="Default select example"
              required
            >
              <option
                v-for="currency in currencies"
                :key="currency"
                :value="currency"
              >
                {{ currency }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.snow.css";
import MarketPlaceService from "@/services/marketplace";

export default {
  name: "CropDetails",
  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      editor: null,
      categories: [],
      subCategories: [],
      cropData: {
        category_id: "",
        subcategory_id: "",
        color: "",
        foreign_matter: "",
        moisture_content: "",
        test_weight: "",
        is_negotiable: "",
        currency: "",
        description: "",
      },
      colors: "",
      currencies: ["NGN"],
      productID: false,
      productData: "",
    };
  },
  computed: {
    subCategoryByCategory() {
      return this.subCategories.filter(
        (item) => item.category_id == this.cropData.category_id,
      );
    },
  },
  mounted() {
    // Fetch a specific crop based on its ID
    this.fetchCrop(this.$route.params.id);

    // check if routes contains a product Id as params to hence an edit page
    if (!isNaN(this.$route.params.id)) {
      this.productID = true;
    }

    var _this = this;

    this.editor = new Quill(this.$refs.editor, {
      modules: {
        toolbar: [
          [
            {
              header: [1, 2, 3, 4, false],
            },
          ],
          ["bold", "italic", "underline", "link"],
        ],
      },
      //theme: 'bubble',
      theme: "snow",
      formats: ["bold", "underline", "header", "italic", "link"],
      placeholder: "Type description!",
    });
    this.editor.root.innerHTML = this.modelValue;
    this.editor.on("text-change", function () {
      _this.handleContentChange();
      return _this.update();
    });
    this.getCategory();
    this.getSubCategory();
    this.getColor();

    // prefill the crop data field on edit screen,
    if (this.productData) {
      this.cropData = {
        currency: this.productData.specification.foreign_matter ?? "",
      };
    }
  },

  methods: {
    // Asynchronous function to fetch crop data by ID
    async fetchCrop(id) {
      try {
        // Create a new promise to handle the asynchronous operation
        const response = await new Promise((resolve, reject) => {
          // Call a service (MarketPlaceService) to get crop data by ID
          // The service accepts a callback function which will be called with the response data
          MarketPlaceService.getCropById(id, (response) => {
            // Resolve the promise with the response data
            resolve(response);
          });
        });

        // Once the promise is resolved, assign the received data to 'productData' property of the current object
        this.productData = response.data;
        console.log(this.productData);
      } catch (error) {
        // If an error occurs during the fetch operation, catch it and log an error message with the specific error
        console.error("Error fetching ", error);
      }
    },

    update: function update() {
      this.$emit(
        "update:modelValue",
        this.editor.getText() ? this.editor.root.innerHTML : "",
      );
    },
    getCategory() {
      MarketPlaceService.getCropCategories((response) => {
        this.categories = response.data;
      });
    },
    getSubCategory() {
      MarketPlaceService.getSubCategories((response) => {
        this.subCategories = response.data;
      });
    },
    getColor() {
      MarketPlaceService.getColor((response) => {
        this.colors = response.data;
      });
    },
    handleContentChange() {
      this.cropData.description = this.editor.root.innerHTML;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.crop-wanted-section {
  background: #ffffff;
  border-radius: 4px;
  padding: 4%;
}
.edit_crop {
  background: #cd4647;
  box-shadow:
    0px 24px 48px 0px rgba(44, 39, 56, 0.08),
    0px 12px 24px 0px rgba(44, 39, 56, 0.04);
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-family: Maven Pro;
  padding: 10px 10px 10px 4%;
  margin-bottom: 30px;
}
.form {
  .crop_details {
    @include textStyles("Maven Pro", 700, 16px, 24px);
    letter-spacing: 0.01em;
    color: #4a4754;
    margin-bottom: 1rem;
  }

  .m_top {
    margin-top: 3.4rem;
  }

  width: 100%;
  padding-bottom: 2rem;
}

div.vertical-line {
  width: 2px;
  padding: 1px;
  background: #f5f5f5;
}

.editor-wrapper {
  padding-bottom: 50px;

  .ql-container {
    min-height: 50vh;
  }
}
.hidden-input {
  height: 0px;
  width: 0px;
  margin: 0px;
  padding: 0px !important;
}
</style>
