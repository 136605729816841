<template>
  <div>
    <div class="container">
      <div class="image-container">
        <img
          class="try"
          src="@/assets/images/backgrounds/error.jpg"
          alt="error-image"
        />
      </div>
      <h1>Sorry - Page Not Found</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.image-container {
  width: 500px;
  height: 50vh;
  margin: 0 auto;
}
.try {
  width: 500px;
}
</style>
