<template>
  <DefaultNav>
    <!-- main Content -->
    <div v-if="order" class="big-container">
      <div class="page-header d-flex justify-content-center align-items-center">
        <h1 v-if="step == 1">Order Summary</h1>
        <!-- for waybill details -->
        <div v-if="step == 2" :class="['waybills', 'w-100']">
          <a class="arrow-left-img" @click="previouStep()">
            <img
              class="img-fluid"
              src="@/assets/images/vectors/ArrowLeft.svg"
            />
          </a>
          <h1>Waybill Details</h1>
          <div v-if="wayBillStep" class="pages">{{ wayBillStep }} of 2</div>
        </div>
      </div>
      <!--body container -->
      <div class="transaction-sumary-container d-flex flex-row">
        <!-- left -->
        <div class="left-container">
          <div class="left-container-wrapper">
            <!-- table for transactions details -->
            <div class="transactin-details d-flex flex-column">
              <div class="table-rows table-row-first">
                <div>Crop Type:</div>
                <div v-if="order.products[0].input">
                  {{ order.products[0].subcategory.name }}
                </div>
                <div v-else>{{ order.products[0].subcategory.name }}</div>
              </div>
              <!-- <div class="table-rows">
                <div class="quality-spec">Quality Specs</div>
              </div> -->
              <div class="table-rows">
                <div>Offer Type:</div>
                <div>Basic</div>
              </div>
              <div class="table-rows">
                <div>Crop Quantity:</div>
                <div>{{ specification.qty }} MT</div>
              </div>
              <div class="table-rows">
                <div>Buyer Details:</div>
                <div>
                  {{ order.buyer.first_name + " " + order.buyer.last_name }}
                </div>
              </div>
              <div class="table-rows">
                <div>Accepted Date:</div>
                <div>{{ orderDate.toDateString() }}</div>
              </div>
              <div class="table-rows">
                <div>Seller Details:</div>
                <div>
                  {{ order.seller.first_name + " " + order.seller.last_name }}
                </div>
              </div>
              <div v-if="isSeller" class="table-rows">
                <div>Payment Option:</div>
                <div>
                  {{
                    order.payment_option
                      ? paymentOptionlabels[order.payment_option]
                      : "Not selected"
                  }}
                </div>
              </div>
              <div v-if="false" class="table-rows">
                <div>Transaction ID:</div>
                <div>2SCM88S</div>
              </div>
              <div v-if="false" id="tlast" class="table-rows tlast">
                <div>Agreement ID:</div>
                <div>MVWD83BC38L</div>
              </div>
            </div>
            <div class="d-grid table-btn">
              <button v-if="false" class="btn btn-purchase-order" type="button">
                View purchase order
              </button>
              <!-- transaction flow buyers view -->
              <button
                v-if="false"
                :class="['btn', 'btn-procceed-waybil', 'btn-hover-state']"
                type="button"
                @click="nextStep()"
              >
                Click to proceed
              </button>

              <!-- end -->
              <p
                v-if="
                  (isSeller &&
                    purchaseBtns == true &&
                    order.purchase_order_status == 'PENDING' &&
                    !order.waybill_details) ||
                  (isSeller &&
                    purchaseBtns == true &&
                    order.purchase_order_status == 'PENDING' &&
                    !order.waybill_details)
                "
              >
                I have seen, accepted or downloaded the purchase Order, click
                button below
              </p>
              <button
                v-if="
                  (isSeller &&
                    purchaseBtns == true &&
                    order.purchase_order_status == 'PENDING' &&
                    !order.waybill_details) ||
                  (isSeller &&
                    purchaseBtns == true &&
                    order.purchase_order_status == 'PENDING' &&
                    !order.waybill_details)
                "
                id="accept-order-btn"
                :class="[
                  'btn',
                  'btn-procceed-waybil',
                  step == 2 ? 'active-display-none' : 'active-display-block',
                ]"
                type="button"
                @click="setPurchaseOrder(order.order_hash)"
              >
                Accept Purchase Order
              </button>
              <button
                v-if="
                  (isSeller &&
                    purchaseBtns == true &&
                    order.purchase_order_status == 'PENDING' &&
                    !order.waybill_details) ||
                  (isSeller &&
                    purchaseBtns == true &&
                    !order.waybill_details &&
                    order.purchase_order_status == 'PENDING') ||
                  (isSeller &&
                    purchaseBtns == true &&
                    !order.waybill_details &&
                    order.purchase_order_status == 'PENDING')
                "
                id="decline-order-btn"
                :class="[
                  'btn',
                  'red-btn',
                  step == 2 ? 'active-display-none' : 'active-display-block',
                ]"
                type="button"
                @click="declinePurchaseOrder(order.order_hash)"
              >
                Decline Purchase Order
              </button>
              <button
                v-if="
                  (isSeller &&
                    !order.waybill_details &&
                    order.payment_option == 'after_delivery' &&
                    order.purchase_order_status == 'ACCEPTED') ||
                  (isSeller &&
                    !order.waybill_details &&
                    order.payment_option == 'advance' &&
                    order.payment_status == 'PARTIALLY_PAID' &&
                    order.purchase_order_status == 'ACCEPTED') ||
                  (isSeller &&
                    !order.waybill_details &&
                    order.payment_option == 'full' &&
                    order.purchase_order_status == 'ACCEPTED' &&
                    acceptPurchaseOrder == true)
                "
                :class="[
                  'btn',
                  'btn-procceed-waybil',
                  'mb-5',
                  step == 2 ? 'active-display-none' : 'active-display-block',
                ]"
                type="button"
                @click="nextStep()"
              >
                Proceed to waybill
              </button>
              <button
                v-if="isSeller && order.waybill_details"
                :class="[
                  'btn',
                  'btn-procceed-waybil',
                  'mb-5',
                  'btn-hover-state',
                  step == 2 ? 'active-display-none' : 'active-display-block',
                ]"
                type="button"
                @click="
                  $router.push({
                    name: 'OrderTracking',
                    params: { order: order.order_hash },
                  })
                "
              >
                View waybill details
              </button>
              <!-- for corporates view -->
              <a
                v-if="
                  isBuyer &&
                  order.payment_status == 'UNPAID' &&
                  delivery_location
                "
                id="view-pricing"
                :class="[
                  'btn',
                  'coperate-btn',
                  'btn-procceed-waybil',
                  'btn-hover-state',
                  step == 2 ? 'active-display-none' : 'active-display-block',
                ]"
                type="button"
                @click="viewPricing()"
                >View Pricing Details
              </a>
              <a
                v-if="
                  isBuyer &&
                  order.payment_status == 'UNPAID' &&
                  delivery_location &&
                  payment != 'after_delivery' &&
                  pricingDetails == 1
                "
                id="pay-now"
                :href="'/dashboard/marketplace/payments/' + $route.params.order"
                :class="[
                  'btn',
                  'coperate-btn',
                  'btn-procceed-waybil',
                  'btn-hover-state',
                  step == 2 ? 'active-display-none' : 'active-display-block',
                ]"
                type="button"
                >Pay Now
              </a>

              <a
                v-if="
                  isBuyer &&
                  order.payment_status == 'UNPAID' &&
                  payment == 'after_delivery' &&
                  delivery_location
                "
                href="javascript:void(0)"
                :class="[
                  'btn',
                  'coperate-btn',
                  'btn-procceed-waybil',
                  'btn-hover-state',
                  step == 2 ? 'active-display-none' : 'active-display-block',
                ]"
                type="button"
                @click="placeAfterDeliveryOrder()"
                >Place Order
              </a>
              <a
                v-if="
                  isBuyer &&
                  order.payment_status != 'UNPAID' &&
                  order.waybill_details
                "
                href="javascript:void(0)"
                :class="[
                  'btn',
                  'coperate-btn',
                  'btn-procceed-waybil',
                  'btn-hover-state',
                  'mb-4',
                  step == 2 ? 'active-display-none' : 'active-display-block',
                ]"
                type="button"
                @click="
                  $router.push({
                    name: 'OrderTracking',
                    params: { order: order.order_hash },
                  })
                "
                >Track Order
              </a>
              <a
                v-if="isBuyer && order.amount_due && order.amount_due != 0"
                :href="'/dashboard/marketplace/payments/' + $route.params.order"
                :class="[
                  'btn',
                  'coperate-btn',
                  'btn-procceed-waybil',
                  'btn-hover-state',
                  step == 2 ? 'active-display-none' : 'active-display-block',
                ]"
                type="button"
              >
                Complete Payment
              </a>

              <!-- update delivery details Button trigger modal -->
              <p
                v-if="
                  !order.waybill_details &&
                  isBuyer &&
                  !delivery_location &&
                  order.purchase_order_status == 'PENDING'
                "
                class="wait-text"
              >
                Order created, wait for the seller to accept it
              </p>
              <p
                v-if="
                  !order.waybill_details &&
                  isSeller &&
                  !delivery_location &&
                  order.purchase_order_status == 'ACCEPTED' &&
                  order.payment_option == null
                "
                class="wait-text"
              >
                Wait for Buyer to make payment
              </p>
              <p
                v-if="
                  !order.waybill_details &&
                  isBuyer &&
                  !delivery_location &&
                  order.purchase_order_status == 'REJECTED'
                "
              >
                Sorry, the purchase order was rejected by the merchant
              </p>
              <a
                v-if="
                  isBuyer &&
                  !order.waybill_details &&
                  !delivery_location &&
                  order.purchase_order_status == 'REJECTED'
                "
                href="/dashboard/marketplace/cropsale"
                :class="[
                  'btn',
                  'coperate-btn',
                  'btn-procceed-waybil',
                  'my-4',
                  'btn-hover-state',
                ]"
                type="button"
              >
                Go to Marketplace
              </a>
              <a
                v-if="
                  !order.waybill_details &&
                  isBuyer &&
                  !delivery_location &&
                  order.purchase_order_status == 'ACCEPTED'
                "
                :class="[
                  'btn',
                  'coperate-btn',
                  'btn-procceed-waybil',
                  'my-4',
                  'btn-hover-state',
                ]"
                type="button"
                @click="showAddressBtn"
              >
                Select or Add Shipping Address
              </a>
              <div v-if="showAddress == true" class="address-btns">
                <a
                  v-if="!order.waybill_details && isBuyer"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal1"
                  :class="[
                    'btn',
                    'coperate-btn',
                    'btn-procceed-waybil',
                    'my-4',
                    'btn-hover-state',
                  ]"
                  type="button"
                >
                  Add Shipping Address
                </a>
                <a
                  v-if="!order.waybill_details && isBuyer"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal2"
                  :class="[
                    'btn',
                    'coperate-btn',
                    'btn-procceed-waybil',
                    'my-4',
                    'btn-hover-state',
                  ]"
                  type="button"
                >
                  Select Shipping Address
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- right -->
        <div class="right-container d-flex justify-content-center">
          <div class="left-container-wrapper">
            <!-- header tabs -->
            <div
              :class="[
                'right-header',
                'g-0',
                step != 1 ? 'active-display-none' : 'active-display-block',
              ]"
            >
              <div
                :class="[
                  'purchase-order',
                  activeTab == 'purchaseorder' ? 'active-Tab' : '',
                ]"
                @click="changeTab('purchaseorder')"
              >
                Purchase Order
              </div>
              <div
                :class="[
                  'price-details',
                  activeTab == 'pricingdetails' ? 'active-Tab' : '',
                ]"
                @click="changeTab('pricingdetails')"
              >
                Pricing Details
              </div>
              <div
                :class="[
                  'full-spec',
                  activeTab == 'fullspec' ? 'active-Tab' : '',
                ]"
                @click="changeTab('fullspec')"
              >
                Full specification
              </div>
            </div>
            <!-- pricing Details -->
            <PurchaseOrder
              v-if="activeTab == 'purchaseorder' && step == 1"
              :order="order"
            />
            <PricingDetails
              v-if="activeTab == 'pricingdetails' && step == 1"
              :order="order"
              :set-payment-mode="setPayment"
              :set-payment-percent="setPaymentPercent"
            />
            <FullSpecification
              v-if="activeTab == 'fullspec' && step == 1"
              :order="order"
            />
            <WaybillDetails
              v-if="step == 2 && isMerchant"
              ref="wayBill"
              :order="order"
              :update-step="updateWaybill"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      id="exampleModal1"
      class="modal fade"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 id="exampleModalLabel" class="modal-title fs-5">
              Update Delivery Details
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form @submit.prevent="saveChanges">
            <div class="modal-body">
              <div class="w-100 mb-3">
                <label for="formGroupExampleInput" class="form-label mb-0"
                  >Address</label
                >
                <span id="required">*</span>
                <input
                  id="formGroupExampleInput"
                  v-model="trackOrder.address"
                  type="text"
                  class="form-control"
                  placeholder="Type your answer"
                  required
                />
              </div>
              <div class="w-100 mb-3">
                <label for="exampleInputEmail1" class="form-label mb-0"
                  >Country</label
                >
                <span id="required">*</span>
                <select
                  v-model="trackOrder.country"
                  class="form-select"
                  aria-label="Default select example"
                  required
                >
                  <option
                    v-for="(country, index) in countries"
                    :key="index"
                    :value="country.country"
                  >
                    {{ country.country }}
                  </option>
                </select>
              </div>
              <div class="w-100 mb-3">
                <label for="exampleInputEmail1" class="form-label mb-0"
                  >State</label
                >
                <span id="required">*</span>
                <select
                  v-model="trackOrder.state"
                  class="form-select"
                  aria-label="Default select example"
                  required
                >
                  <option
                    v-for="(state, index) in selectStateByCountry"
                    :key="index"
                  >
                    {{ state }}
                  </option>
                </select>
              </div>
              <div class="w-100 mb-3">
                <label for="formGroupExampleInput" class="form-label mb-0"
                  >City</label
                >
                <span id="required">*</span>
                <input
                  id="formGroupExampleInput"
                  v-model="trackOrder.city"
                  type="text"
                  class="form-control"
                  placeholder="Type your answer"
                  required
                />
              </div>
              <div class="w-100 mb-3">
                <label for="formGroupExampleInput" class="form-label mb-0"
                  >Zip</label
                >
                <span id="required">*</span>
                <input
                  id="formGroupExampleInput"
                  v-model="trackOrder.zip"
                  type="number"
                  class="form-control"
                  placeholder="Type your answer"
                  required
                />
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn primary-color btn-hover-state"
                @click="showProceedBtn"
              >
                Save changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      id="exampleModal2"
      class="modal fade"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 id="exampleModalLabel" class="modal-title fs-5">
              Update Delivery Details
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form @submit.prevent="saveChanges">
            <div class="modal-body">
              <div class="w-100 mb-3">
                <label for="formGroupExampleInput" class="form-label mb-0"
                  >Select Existing Address</label
                >
                <span id="required">*</span>
                <select id="" class="form-control" name="">
                  <option value="">Address One</option>
                </select>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn primary-color btn-hover-state"
                @click="showProceedBtn"
              >
                Save changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </DefaultNav>
  <Preloader v-if="isLoading" />
</template>

<script>
import PricingDetails from "@/pages/dashboard/marketPlace/checkout/components/PricingDetails.vue";
import FullSpecification from "@/pages/dashboard/marketPlace/checkout/components/FullSpecification.vue";
import PurchaseOrder from "@/pages/dashboard/marketPlace/checkout/components/PurchaseOrder.vue";
import WaybillDetails from "@/pages/dashboard/marketPlace/checkout/components/WaybillDetails.vue";
import DefaultNav from "@/layouts/DefaultNav.vue";
import MarketPlaceService from "@/services/marketplace";
import OrderService from "@/services/order";
import { windowWhen } from "rxjs";
import countriesObject from "@/data/countries";
import Preloader from "@/layouts/shared/Preloader.vue";
import Alert from "@/utilities/alert";

export default {
  name: "CardDetails",
  components: {
    DefaultNav,
    PricingDetails,
    FullSpecification,
    PurchaseOrder,
    WaybillDetails,
    Preloader,
  },
  data() {
    return {
      pricingDetails: 0,
      activeTab: "purchaseorder",
      step: 1,
      wayBillStep: 1,
      order: null,
      payment: "full",
      paymentPercent: null,
      trackOrder: {
        address: "",
        country: "",
        state: "",
        city: "",
        zip: "",
      },
      countries: countriesObject.countries,
      isLoading: false,
      delivery_location: null,
      paymentOptionlabels: {
        full: "FULL PAYMENT",
        advance: "PARTIAL PAYMENT",
        after_delivery: "PAYMENT AFTER DELIVERY",
      },
      showAddress: null,
      acceptPurchaseOrder: false,
      purchaseBtns: true,
    };
  },
  computed: {
    wayBill() {
      return this.$refs.wayBill;
    },
    specification() {
      return this.order.negotiation
        ? this.order.negotiation.specification
        : this.order.products[0].specification;
    },
    orderDate() {
      return new Date(this.order.created_at);
    },
    selectStateByCountry: function () {
      return this.countries && this.trackOrder.country != ""
        ? this.countries.filter(
            (item) => item.country == this.trackOrder.country,
          )[0].states
        : [];
    },
  },
  mounted() {
    this.getOrder(this.$route.params.order);
    window.localStorage.setItem("paymentOption", "full");
    window.localStorage.setItem("paymentPercent", "null");
  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab;
    },
    viewPricing() {
      this.activeTab = "pricingdetails";
      this.pricingDetails = 1;
      document.getElementById("view-pricing").style.display = "none";
    },
    nextStep() {
      this.step++;
    },
    previouStep() {
      if (this.step == 2) {
        if (this.$refs.wayBill.step == 2) {
          this.$refs.wayBill.previousStep();
          return;
        }
      }
      this.step--;
    },
    updateWaybill(step) {
      this.wayBillStep = step;
    },
    placeAfterDeliveryOrder() {
      this.isLoading = true;
      const redirect = `/dashboard/marketplace/${this.order.order_hash}/tracking`;
      this.savePaymentOption("after_delivery");
      setTimeout(() => {
        window.location.replace(redirect);
      }, 2000);
    },
    setPayment(type) {
      this.payment = type;
      window.localStorage.setItem("paymentOption", type);
      if (this.payment != "advance") {
        this.paymentPercent = null;
        window.localStorage.setItem("paymentPercent", "null");
      } else {
        this.paymentPercent = 50;
        window.localStorage.setItem("paymentPercent", 50);
      }
    },
    setPaymentPercent(percent) {
      this.paymentPercent = percent;
      window.localStorage.setItem("paymentPercent", percent);
    },
    getOrder(order) {
      MarketPlaceService.getOrder(order, (response) => {
        var order = response.data;
        order.products = JSON.parse(order.products);
        this.order = order;
        console.log(order);
        var delivery = JSON.parse(order.tracking_details);
        this.delivery_location = delivery.delivery_location;
      });
    },
    setPurchaseOrder(order) {
      MarketPlaceService.setPurchaseOrder(
        order,
        {
          status: true,
        },
        (response) => {
          if (response && response.error == false) {
            Alert.success({
              message: "Purchase Order Accepted",
            });
          } else if (response && response.error) {
            Alert.error({
              message: response.message,
            });
          }
        },
      );
      this.acceptPurchaseOrder = true;
      this.purchaseBtns = false;
    },
    declinePurchaseOrder(order) {
      MarketPlaceService.setPurchaseOrder(
        order,
        {
          status: false,
        },
        (response) => {
          if (response && response.error == false) {
            Alert.success({
              message: "Purchase Order Declined",
            });
          } else if (response && response.error) {
            Alert.error({
              message: response.message,
            });
          }
        },
      );
      this.acceptPurchaseOrder = false;
      this.purchaseBtns = false;
    },
    savePaymentOption(paymentOption) {
      MarketPlaceService.savePaymentOption(
        { payment_option: paymentOption, order_hash: this.order.order_hash },
        (response) => {},
      );
    },
    saveChanges() {
      // call the preloader
      this.isLoading = true;
      var self = this;
      OrderService.updateDeliveryDetails(
        {
          order: self.order.order_hash,
          deliveryDetails: self.trackOrder,
        },
        function (response) {
          if (response && response.error === false) {
            Alert.success({
              message: "Details updated successfully",
            });
            location.reload();
            $("#exampleModal").modal("toggle");
          } else {
            Alert.error({
              message: "Error In Updating Details",
            });
          }
        },
      );
      // set the preloader to false
      self.isLoading = false;
    },
    showProceedBtn() {},
    showAddressBtn() {
      this.showAddress = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  @include breakpoint-between(md, lg) {
    min-width: 60.5%;
  }

  @include breakpoint-between(lg, xl) {
    min-width: 69.5%;
  }

  @include breakpoint-between(xl, xxl) {
    min-width: 76%;
  }
}

.active-display-none {
  display: none !important;
}

.active-display-block {
  display: block;
}

// modal
select,
input {
  font-size: 14px;
  color: #000000;

  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    border-color: #05b050 !important;
    box-shadow: 0 0 !important;
  }

  &::placeholder {
    @include textStyles("Maven Pro", 700, 14px, 24px);
    color: #000000;
  }
}

label {
  font-size: 14px;
  font-weight: 900;
  color: rgba(45, 55, 72, 0.8);
}
.primary-color {
  background: #05b050;
  color: white;
}
#required {
  color: red;
  font-family: "Maven Pro";
  font-weight: bolder;
  font-size: 20px;
}
.page-header {
  width: 100%;
  padding: 14px 30px;
  background: #262c3f;

  h1 {
    @include textStyles(Poppins, 700, 24px, 120%);
    color: #ffffff;
  }

  .waybills {
    margin-inline: 6.595% 4.58%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-size: 18px;
    }

    .arrow-left-img {
      width: 22px;
      height: 18px;
    }

    .pages {
      cursor: pointer;
      margin-right: 6%;
      text-transform: capitalize;
      color: #ffffff;
      @include textStyles(Poppins, 700, 18px, 28px);
    }
  }
}

.transaction-sumary-container {
  width: 100%;

  .left-container {
    background: #ffffff;
    width: 50%;

    .left-container-wrapper {
      margin-inline: 8%;
      margin-top: 65px;
    }

    div.transactin-details {
      div#tlast.table-rows {
        margin-bottom: 57px;
      }

      .table-rows {
        position: relative;
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;

        > div {
          &:last-child:not(.quality-spec) {
            position: absolute;
            right: 0;
          }

          &:nth-of-type(1) {
            @include textStyles(Poppins, 800, 14px, 33px);
            color: rgba(45, 55, 72, 0.6);
          }

          &:nth-of-type(2) {
            @include textStyles(Poppins, 500, 14px, 29px);
            color: #696671;
          }
        }
      }
    }

    // button group
    .table-btn {
      gap: 25px;
      // margin-bottom: 100px;

      button {
        padding: 15px 20px;
      }

      .btn-purchase-order {
        border: 1px solid #05b050;
        border-radius: 5px;
        background: #ffffff;
        @include textStyles(Inter, 600, 16px, 19px);
        color: #05b050;
      }

      .btn-procceed-waybil {
        background: #05b050;
        border-radius: 5px;
        @include textStyles(Inter, 600, 16px, 19px);
        color: #ffffff;
        &:hover {
          background: darken(#05b050, 10%);
        }
      }

      // toggle
      .active-display-none {
        display: none;
      }

      .active-display-block {
        display: block;
      }

      .coperate-btn {
        padding: 15px 20px;
      }
    }
  }

  .right-container {
    background: #f5f5f5;
    width: 50%;

    .left-container-wrapper {
      margin-inline: 8%;
      margin-top: 65px;
      width: 80%;

      .right-header {
        display: flex;
        border-radius: 8.91px;
        background: rgba(118, 118, 128, 0.17);
        padding: 1.5px;

        div {
          @include flexed-Container;
          width: 33.3%;
          height: 44px;
          cursor: pointer !important;
        }

        // toggle
        .active-Tab {
          border: 0.5px solid rgba(0, 0, 0, 0.04);
          box-shadow:
            0px 3px 8px rgba(0, 0, 0, 0.12),
            0px 3px 1px rgba(0, 0, 0, 0.04);
          border-radius: 6.93px;
          background: #262c3f;
          color: #ffffff !important;
        }

        .price-details {
          color: #262c3f;
          @include textStyles(Inter, 600, 14.5px, 19px);
        }

        .full-spec {
          color: #262c3f;
          @include textStyles(Inter, 600, 14.5px, 19px);
        }

        .purchase-order {
          color: #262c3f;
          @include textStyles(Inter, 600, 14.5px, 19px);
        }
      }
    }
  }
}
.address-btns {
  display: flex;
  justify-content: space-between;

  a {
    width: 48%;
  }
}
#order-text {
  margin: 0px;
  margin-top: 35px;
}
#order-btn {
  gap: 0px !important;
}
.red-btn {
  background: red;
  color: white;
}
.wait-text {
  font-family: Maven Pro;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 15px;
}
</style>
