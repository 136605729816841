<template>
  <form @submit.prevent="saveData()">
    <div class="crop-wanted-section d-flex flex-row gap-4">
      <div class="form">
        <!-- input -->
        <div class="w-100 mb-3">
          <label for="exampleInputEmail1" class="form-label mb-0"
            >Have you taken a loan before?</label
          >
          <select
            v-model="hastakenloanbefore"
            class="form-select"
            aria-label="Default select example"
            required
          >
            <option selected>Select option</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
        <!-- input -->
        <div class="d-flex gap-3">
          <!-- input -->
          <div class="w-100 mb-3">
            <label for="exampleInputEmail1" class="form-label mb-0"
              >Where did you get it?</label
            >
            <input
              v-model="sourceofloan"
              type="text"
              class="form-control"
              required
              placeholder="Enter your answer"
            />
          </div>
          <!-- input -->
          <div class="w-100 mb-3">
            <label for="exampleInputEmail1" class="form-label mb-0"
              >What was the amount?</label
            >
            <input
              v-model="pastloanamount"
              type="text"
              class="form-control"
              required
              placeholder="Enter your answer"
            />
          </div>
        </div>
        <!-- input -->
        <div class="w-100 mb-3">
          <label for="exampleInputEmail1" class="form-label mb-0"
            >How did you manage to pay back/reimburse?</label
          >
          <input
            v-model="howloanwasrepaid"
            type="text"
            class="form-control"
            required
            placeholder="Enter your answer"
          />
        </div>
        <!-- input -->
        <div class="w-100 mb-3">
          <label for="exampleInputEmail1" class="form-label mb-0"
            >Would you be ready to pay any interest if given a loan?</label
          >
          <select
            v-model="isreadytopayinterest"
            class="form-select"
            aria-label="Default select example"
            required
          >
            <option selected>Select option</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>

        <!-- input -->
        <div class="w-100 mb-3">
          <label for="exampleInputEmail1" class="form-label mb-0"
            >Would you be ready and able, to provide any collateral?</label
          >
          <select
            v-model="canprovidecollateral"
            class="form-select"
            aria-label="Default select example"
            required
          >
            <option selected>Select option</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
        <!-- input -->
        <div class="w-100 mb-3">
          <label for="exampleInputEmail1" class="form-label mb-0"
            >If not, why?</label
          >
          <input
            v-model="whynocollateral"
            type="text"
            class="form-control"
            required
            placeholder="Enter your answer"
          />
        </div>
        <div class="col-lg-2 col-5 col-sm-3 my-4">
          <button :class="['btn', 'btn-success', 'col-12']">save</button>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import Alert from "@/utilities/alert";
import axios from "axios";
export default {
  name: "CreditHistory",
  data() {
    return {
      canprovidecollateral: "",
      hastakenloanbefore: "",
      howloanwasrepaid: "",
      isreadytopayinterest: "",
      pastloanamount: "",
      sourceofloan: "",
      whynocollateral: "",
      bvn: "",
    };
  },
  methods: {
    async saveData() {
      const bvn = JSON.stringify(
        JSON.parse(localStorage.getItem("Zowasel")).user.user_id,
      );
      let vm = this;
      let dt = {
        canprovidecollateral: vm.canprovidecollateral,
        bvn: bvn,
        hastakenloanbefore: vm.hastakenloanbefore,
        howloanwasrepaid: vm.howloanwasrepaid,
        isreadytopayinterest: vm.isreadytopayinterest,
        pastloanamount: vm.pastloanamount,
        sourceofloan: vm.sourceofloan,
        whynocollateral: vm.whynocollateral,
      };
      await axios
        .post("http://174.129.139.68/api/credithistory/add", dt)
        .then((data) => {
          let response = data.data;
          if (response.error == false) {
            Alert.success({
              message: "Credit History added successfully",
            });
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            Alert.error({
              message: response.message,
            });
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.crop-wanted-section {
  background: #ffffff;
  border-radius: 4px;
  padding: 4%;
}

.form {
  .crop_details {
    @include textStyles("Poppins", 700, 18px, 24px);
    letter-spacing: 0.01em;
    color: #4a4754;
    margin-bottom: 1rem;
  }

  .m_top {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

  width: 100%;
  padding-bottom: 2rem;

  select,
  input {
    font-size: 14px;
    color: #000000;

    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      border-color: #05b050 !important;
      box-shadow: 0 0 !important;
    }
  }

  label {
    font-size: 14px;
    font-weight: 900;
    color: rgba(45, 55, 72, 0.8);
  }
}
</style>
