<template>
  <DefaultNav>
    <div class="big-container">
      <div class="headings">
        <div class="left-btns">
          <a href="/dashboard/team"
            ><img src="@/assets/images/vectors/arrowleft.png" alt=""
          /></a>
          <h2>Add Team Member</h2>
        </div>
      </div>
      <div class="container">
        <div class="left">
          <button
            type="button"
            :class="[tab == 1 ? 'active' : '']"
            @click="changeTab(1)"
          >
            Link Invite
          </button>
          <button
            :class="[tab == 2 ? 'active' : '']"
            type="button"
            @click="changeTab(2)"
          >
            Bulk Invite
          </button>
          <button
            :class="[tab == 3 ? 'active' : '']"
            type="button"
            @click="changeTab(3)"
          >
            Group Invite
          </button>
          <button
            :class="[tab == 4 ? 'active' : '']"
            type="button"
            @click="changeTab(4)"
          >
            Direct Addition
          </button>
          <button
            :class="[tab == 5 ? 'active' : '']"
            type="button"
            @click="changeTab(5)"
          >
            Bulk Upload
          </button>
        </div>
        <div class="right">
          <div v-if="tab == 1" class="link-invite">
            <h3>Link Invite</h3>

            <p>Quick Verification Link</p>
            <div class="main-link">
              <input
                type="text"
                class="form-control"
                placeholder="https://zowasel.com/invite"
              />
              <button>
                <img src="@/assets/images/vectors/copy.svg" alt="" /> Copy
              </button>
            </div>
            <div class="check-area">
              <input type="checkbox" />
              <p>Request Permission to join</p>
            </div>
          </div>

          <div v-if="tab == 2" class="link-invite">
            <h3>Bulk Invite</h3>

            <p>
              Enter email addresses of people you want to invite, press space to
              separate emails or phone number
            </p>
            <div class="main-link bulk">
              <div>
                <label for="emailInput">Enter Email Addresses:</label>
                <textarea
                  id="emailInput"
                  v-model="emailListInput"
                  rows="4"
                  cols="50"
                  placeholder="Enter email addresses separated by commas"
                  @input="parseEmails"
                ></textarea>
                <div>
                  <p>Valid Email Addresses:</p>
                  <ul>
                    <li v-for="(email, index) in validEmails" :key="index">
                      {{ email }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="button-right">
                <button>Invite</button>
              </div>
            </div>
          </div>
          <div v-if="tab == 3" class="link-invite group-invite">
            <h3>Group Invite</h3>

            <form action="">
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="inputEmail4">Email</label>
                  <select id="" name="" class="form-control">
                    <option value="">Select Role</option>
                  </select>
                </div>
              </div>
              <div id="new-line-1" class="row new-line">
                <div class="form-group col-md-4">
                  <label for="">First Name</label>
                  <input
                    type="First Name"
                    class="form-control"
                    placeholder="First Name"
                  />
                </div>
                <div class="form-group col-md-4">
                  <label for="">Last Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Last Name"
                  />
                </div>
                <div class="form-group col-md-4">
                  <label for="">Email</label>
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Email"
                  />
                </div>
              </div>
              <button class="add-row" type="button" @click="addNewUser()">
                Add Another User
              </button>
              <div class="button-right">
                <button>Invite</button>
              </div>
            </form>
          </div>

          <div v-if="tab == 4" class="link-invite direct-invite">
            <h3 class="text-center">Select Team Member Type</h3>
            <div class="direct">
              <button type="button" @click="changeTab(6)">Merchant</button>
              <button type="button" @click="changeab(2)">Corporate</button>
              <button type="button" @click="changeab(3)">
                Financial Partner
              </button>
              <button type="button" @click="changeab(4)">Agent</button>
              <button type="button" @click="changeab(5)">Other</button>
            </div>
          </div>

          <div v-if="tab == 5" class="link-invite">
            <h3>Bulk Upload</h3>

            <div class="image-side uploader">
              <input
                id="file-upload"
                type="file"
                name="fileUpload"
                accept=".jpeg, .doc, .docx, .xls, .xlsx, .txt, .jpg, .png, .gif"
                @change="uploadFile"
              />
              <label id="file-drag" for="file-upload" class="image-label">
                <img
                  id="file-image"
                  src="@/assets/images/vectors/upload-icon.svg"
                  alt="Preview"
                  class="hidden"
                />
                <div id="start">
                  <div>Select a file or drag here</div>
                  <div id="notimage" class="hidden">Please select an image</div>
                  <span id="file-upload-btn" class="btn sel-btn"
                    >Select a file</span
                  >
                </div>
                <div id="response" class="hidden">
                  <div id="messages"></div>
                  <!-- <progress class="progress" id="file-progress" value="0">
                        <span>0</span>%
                      </progress> -->
                </div>
              </label>
            </div>
          </div>
          <div v-if="tab == 6" class="link-invite">
            <h3>Bulk Upload</h3>
            <form action="">
              <input id="" type="text" name="" />
            </form>
          </div>
        </div>
      </div>
    </div>
  </DefaultNav>
</template>

<script>
import DefaultNav from "@/layouts/DefaultNav.vue";
import MarketplaceService from "@/services/marketplace";
import Alert from "@/utilities/alert";
export default {
  name: "AddMember",
  components: {
    DefaultNav,
  },
  data() {
    return {
      team: {
        first_name: "",
        last_name: "",
        email: "",
        // phone: "",
      },
      emailListInput: "",
      validEmails: [],
      tab: 5,
    };
  },
  mounted() {
    // const dropzone = new Dropzone("div#myId", { url: "/file/post" });
    function ekUpload() {
      function Init() {
        console.log("Upload Initialised");
        var fileSelect = document.getElementById("file-upload"),
          fileDrag = document.getElementById("file-drag"),
          submitButton = document.getElementById("submit-button");
        fileSelect.addEventListener("change", fileSelectHandler, false);
        // Is XHR2 available?
        var xhr = new XMLHttpRequest();
        if (xhr.upload) {
          // File Drop
          fileDrag.addEventListener("dragover", fileDragHover, false);
          fileDrag.addEventListener("dragleave", fileDragHover, false);
          fileDrag.addEventListener("drop", fileSelectHandler, false);
        }
      }
      function fileDragHover(e) {
        var fileDrag = document.getElementById("file-drag");
        e.stopPropagation();
        e.preventDefault();
        fileDrag.className =
          e.type === "dragover" ? "hover" : "modal-body file-upload";
      }
      function fileSelectHandler(e) {
        // Fetch FileList object
        var files = e.target.files || e.dataTransfer.files;
        // Cancel event and hover styling
        fileDragHover(e);
        // Process all File objects
        for (var i = 0, f; (f = files[i]); i++) {
          parseFile(f);
          uploadFile(f);
        }
      }
      // Output
      function output(msg) {
        // Response
        var m = document.getElementById("messages");
        m.innerHTML = msg;
      }
      function parseFile(file) {
        console.log(file.name);
        output("<strong>" + encodeURI(file.name) + "</strong>");
        // var fileType = file.type;
        // console.log(fileType);
        var imageName = file.name;
        var isGood = /\.(?=gif|jpg|png|jpeg)/gi.test(imageName);
        if (isGood) {
          document.getElementById("start").classList.add("hidden");
          document.getElementById("response").classList.remove("hidden");
          document.getElementById("notimage").classList.add("hidden");
          // Thumbnail Preview
          document.getElementById("file-image").classList.remove("hidden");
          document.getElementById("file-image").src = URL.createObjectURL(file);
        } else {
          document.getElementById("file-image").classList.add("hidden");
          document.getElementById("notimage").classList.remove("hidden");
          document.getElementById("start").classList.remove("hidden");
          document.getElementById("response").classList.add("hidden");
          document.getElementById("file-upload-form").reset();
        }
      }
      function setProgressMaxValue(e) {
        var pBar = document.getElementById("file-progress");
        if (e.lengthComputable) {
          pBar.max = e.total;
        }
      }
      function updateFileProgress(e) {
        var pBar = document.getElementById("file-progress");
        if (e.lengthComputable) {
          pBar.value = e.loaded;
        }
      }
      function uploadFile(file) {
        var xhr = new XMLHttpRequest(),
          fileInput = document.getElementById("class-roster-file"),
          pBar = document.getElementById("file-progress"),
          fileSizeLimit = 1024; // In MB
        if (xhr.upload) {
          // Check if file is less than x MB
          if (file.size <= fileSizeLimit * 1024 * 1024) {
            // Progress bar
            pBar.style.display = "inline";
            xhr.upload.addEventListener(
              "loadstart",
              setProgressMaxValue,
              false,
            );
            xhr.upload.addEventListener("progress", updateFileProgress, false);
            // File received / failed
            xhr.onreadystatechange = function (e) {
              if (xhr.readyState == 4) {
                // Everything is good!
                // progress.className = (xhr.status == 200 ? "success" : "failure");
                // document.location.reload(true);
              }
            };
            // Start upload
            xhr.open(
              "POST",
              document.getElementById("file-upload-form").action,
              true,
            );
            xhr.setRequestHeader("X-File-Name", file.name);
            xhr.setRequestHeader("X-File-Size", file.size);
            xhr.setRequestHeader("Content-Type", "multipart/form-data");
            xhr.send(file);
          } else {
            output(
              "Please upload a smaller file (< " + fileSizeLimit + " MB).",
            );
          }
        }
      }
      // Check for the various File API support.
      if (window.File && window.FileList && window.FileReader) {
        Init();
      } else {
        document.getElementById("file-drag").style.display = "none";
      }
    }
    ekUpload();
  },
  methods: {
    addMember() {
      MarketplaceService.addMember(
        {
          first_name: this.team.first_name,
          last_name: this.team.last_name,
          email: this.team.email,
          // phone: this.team.phone,
        },
        (response) => {
          if (response && response.error == false) {
            Alert.success({
              message: "Team Member Added successfully",
            });
          } else if (response && response.error) {
            Alert.error({
              message: response.message,
            });
          }
        },
      );
    },
    addNewUser() {
      var className = "new-class";
      var count = 0;
      var field = "";
      var maxFields = 50;
      var className = document.getElementsByClassName("new-line");
      function totalFields() {
        return className.length;
      }
      count = totalFields() + 1;
      field = $("#new-line-1").clone();
      field.attr("id", "new-line" + count);
      field.children("label").text("Field " + count);
      field.find("input").val("");
      // $(className + ":last").after($(field));
    },
    changeTab(tab) {
      this.tab = tab;
    },
    parseEmails() {
      // Split the entered text into an array of email addresses using commas as the separator
      const emailArray = this.emailListInput
        .split(",")
        .map((email) => email.trim()); // Remove leading/trailing spaces

      // Validate each email address using a regular expression
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const validEmails = emailArray.filter((email) => emailRegex.test(email));

      // Update the validEmails data property with the valid email addresses
      this.validEmails = validEmails;
    },
  },
};
</script>

<style lang="scss" scoped>
.big-container {
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 2% 5% 2% 3%;
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4a4754;
  }
}

.headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  .left-btns {
    display: flex;
    gap: 10px;

    img {
      width: 22px;
      height: 18px;
    }
  }
  .right-btns {
    display: flex;
    gap: 20px;
  }
}
.green-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 180px;
  height: 40px;
  background: #05b050;
  border-radius: 5px;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.02em;
  text-decoration: none;
  border: none;
}

.date-region {
  margin-top: 30px;
}
.container {
  width: 100%;
  display: flex;
  gap: 30px;
}
.left {
  background-color: white;
  width: 25%;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 25px;

  button {
    width: 200px;
    height: 40px;
    background-color: white;
    border: 1px solid #6ed19a;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    text-decoration: none;
    color: black;
  }
}
.right {
  width: 75%;
  background-color: white;
  padding: 30px;

  .link-invite {
    width: 100%;

    h3 {
      font-family: Poppins;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 40px;
    }
    .main-link {
      display: flex;
      gap: 30px;
      align-items: center;

      input {
        width: 100%;
        height: 48px;
        border-radius: 10px;
      }

      button {
        width: 100px;
        height: 48px;
        padding: 12px 14px 12px 14px;
        border-radius: 10px;
        background-color: #05b050;
        color: white;
        border: none;
      }
    }
  }
}

.bulk {
  display: flex;
  flex-direction: column;

  textarea {
    width: 100%;
  }
}
.check-area {
  display: flex;
  margin-top: 10px;
  gap: 10px;
  align-items: center;

  input {
    width: 20px;
    height: 20px;
  }
  p {
    margin: 0px;
  }
}
.active {
  height: 48px !important;
  padding: 12px 14px 12px 14px !important;
  border-radius: 10px !important;
  background-color: #05b050 !important;
  color: white !important;
  border: none !important;
}
.button-right {
  width: 100%;
  display: flex;
  justify-content: right;
  margin-top: 30px;

  button {
    width: 100px;
    height: 48px;
    padding: 12px 14px 12px 14px;
    border-radius: 10px;
    background-color: #05b050;
    color: white;
    border: none;
    font-family: Maven Pro;
    font-size: 16px;
    font-weight: 700;
  }
}
.add-row {
  padding: 12px 12px;
  height: 42px;
  border: 1px solid #05b050;
  border-radius: 10px;
  color: #05b050;
  font-family: Maven Pro;
  font-size: 16px;
  font-weight: 700;
}
.direct-invite {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.direct {
  background-color: white;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 25px;
  border: 0.5px solid rgb(235, 231, 231);

  button {
    width: 80%;
    height: 40px;
    background-color: white;
    border: 1px solid #6ed19a;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    text-decoration: none;
    color: black;
  }
  button:hover {
    height: 48px !important;
    padding: 12px 14px 12px 14px !important;
    border-radius: 10px !important;
    background-color: #05b050 !important;
    color: white !important;
    border: none !important;
  }
}
$theme: #05b050;
$dark-text: #5f6982;

// Upload Demo
//
.uploader {
  display: block;
  clear: both;
  margin: 0 auto;
  width: 100%;

  .image-label {
    float: left;
    display: flex;
    clear: both;
    width: 70%;
    padding: 2rem 1.5rem;
    text-align: center;
    background: #fff;
    border-radius: 7px;
    border: 3px solid #eee;
    transition: all 0.2s ease;
    user-select: none;

    &:hover {
      border-color: $theme;
    }
    &.hover {
      border: 3px solid $theme;
      box-shadow: inset 0 0 0 6px #eee;

      #start {
        i.fa {
          transform: scale(0.8);
          opacity: 0.3;
        }
      }
    }
  }

  #start {
    float: left;
    clear: both;
    width: 100%;
    &.hidden {
      display: none;
    }
  }
  #response {
    float: left;
    clear: both;
    width: 100%;
    &.hidden {
      display: none;
    }
    #messages {
      margin-bottom: 0.5rem;
    }
  }

  #file-image {
    display: inline;
    margin: 0 auto 0.5rem auto;
    max-height: 300px;
    width: 100%;
    &.hidden {
      display: none;
    }
  }

  #notimage {
    display: block;
    float: left;
    clear: both;
    width: 100%;
    &.hidden {
      display: none;
    }
  }

  progress,
  .progress {
    // appearance: none;
    display: inline;
    clear: both;
    margin: 0 auto;
    width: 100%;
    max-width: 180px;
    height: 8px;
    border: 0;
    border-radius: 4px;
    background-color: #eee;
    overflow: hidden;
  }

  .progress[value]::-webkit-progress-bar {
    border-radius: 4px;
    background-color: #eee;
  }

  .progress[value]::-webkit-progress-value {
    background: linear-gradient(to right, darken($theme, 8%) 0%, $theme 50%);
    border-radius: 4px;
  }
  .progress[value]::-moz-progress-bar {
    background: linear-gradient(to right, darken($theme, 8%) 0%, $theme 50%);
    border-radius: 4px;
  }

  input[type="file"] {
    display: none;
  }

  div {
    margin: 0 0 0.5rem 0;
    color: $dark-text;
  }
  .btn {
    display: inline-block;
    margin: 0.5rem 0.5rem 1rem 0.5rem;
    clear: both;
    font-family: inherit;
    font-weight: 700;
    font-size: 14px;
    text-decoration: none;
    text-transform: initial;
    border: none;
    border-radius: 0.2rem;
    outline: none;
    padding: 0 1rem;
    height: 36px;
    line-height: 36px;
    color: #fff;
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;
    cursor: pointer;
  }
}
.image-side {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-bottom: 50px;
  height: auto;
  align-items: center;
}
.sel-btn {
  color: #05b050 !important;
  border: 1px solid #05b050 !important;
  background: none !important;
}
</style>
